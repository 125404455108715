<div class="card border-0">
  <div class="card-header pt-3">
    <h2 class="card-title float-start" (click)="triggerCollapse()">
      {{ kpiName }}
      <i class="bi bi-caret-{{ isCollapsed ? 'down' : 'up' }}-fill"></i>
    </h2>
  </div>
  <div
    class="card-body text-center pt-0"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed">
    <div class="col calculated-value positive-color fw-800">
      {{ kpiValue }}
    </div>
  </div>
</div>
