import { VideoDTO } from 'src/app/common/dtos/video.dto';
import {
  FileUploadItemResponse,
  FileUploadResponse,
  MicrocredentialBadgeUploadItemResponse,
  ResourceUploadResponse,
} from 'src/app/common/types/responses/responses';

import { FileDTO } from '../../dtos/file.dto';
import { FileIcon } from '../../enums/file-icons.enum';

export const fileUploadToFileDTO = (
  fileUpload: FileUploadResponse
): FileDTO => ({
  id: fileUpload.item.id,
  name: fileUpload.item.title,
  iconClass: FileIcon[fileUpload.item.file_type as keyof typeof FileIcon],
  location: fileUpload.location || fileUpload.item.file_path,
});

export const videoDTOToFileDTO = (video: VideoDTO): FileDTO => ({
  id: video.id,
  name: video.title || '',
  iconClass: FileIcon.video,
  location: video.vimeoLink || video.esuiteLink,
  videoData: video,
});

export const resourceUploadToFileDTO = (
  resourceUpload: ResourceUploadResponse
): FileDTO => ({
  id: resourceUpload.item.id,
  name: resourceUpload.item.title,
  iconClass: FileIcon[resourceUpload.item.file_type as keyof typeof FileIcon],
  location: resourceUpload.item.file_path,
});

export const microUploadToFileDTO = (
  microUpload: MicrocredentialBadgeUploadItemResponse
): FileDTO => ({
  id: microUpload.id,
  name: microUpload.title,
  iconClass: FileIcon.img,
  location: microUpload.image,
});

export const fileUploadItemResponseToFileDTO = (
  fileUploadItem: FileUploadItemResponse
): FileDTO => ({
  id: fileUploadItem.id,
  name: fileUploadItem.title,
  iconClass: FileIcon[fileUploadItem.file_type as keyof typeof FileIcon],
  location: fileUploadItem.file_path,
});
