import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import {
  NotificationDTO,
  NotificationType,
} from '../../../dtos/notifications.dto';
import { CelebrationsService } from '../../../services/celebrations/celebrations.service';
import { ConfettiService } from '../../../services/confetti/confetti.service';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent implements OnChanges {
  @ViewChild('notificationsModal') notificationsModal: ModalComponent;

  @Input() notifications: NotificationDTO[];

  currentCelebrationId: number;

  apiUrl: string;

  constructor(
    private celebrationService: CelebrationsService,
    private confettiService: ConfettiService,
    private notificationsService: NotificationsService,
    private router: Router
  ) {
    this.apiUrl = EnvironmentService.apiUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'notifications':
          this.getNotificationDetails();
          break;
        default:
      }
    });
  }

  closeModal() {
    this.notificationsModal.close();
  }

  openModal() {
    if (
      this.notifications[0].notification_type === NotificationType.CELEBRATION
    ) {
      this.confettiService.showConfetti('top');
    }
    this.markNotificationAsRead(this.notifications[0].id, 0);
    this.notificationsModal.open();
  }

  getNotificationDetails() {
    if (this.notifications?.length > 0) {
      this.notifications.forEach((notification, index) => {
        if (notification.notification_type === NotificationType.CELEBRATION) {
          this.celebrationService
            .getCelebration(notification.notification_ref_id)
            .subscribe((res) => {
              if (res) {
                this.notifications[index].notificationDetails = res;
                if (index === this.notifications.length - 1) {
                  this.openModal();
                }
              }
            });
        }
      });
    }
  }

  handleNextNotification(event: NgbSlideEvent) {
    const currentSlide = parseInt(event.current.slice(-1));
    this.markNotificationAsRead(
      this.notifications[currentSlide].id,
      currentSlide
    );
  }

  navigateToFullCelebration(id: number): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/celebrations/${id}`])
    );
    window.open(url, '_blank');
  }

  markNotificationAsRead(id: number, index: number) {
    if (!this.notifications[index].markedRead) {
      this.notificationsService
        .markNotificationAsRead([id])
        .subscribe((res) => {
          if (res) {
            this.notifications[index].markedRead = true;
          }
        });
      if (
        this.notifications[index].notification_type ===
        NotificationType.CELEBRATION
      ) {
        this.confettiService.showConfetti('top');
      }
    }
  }
}
