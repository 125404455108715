<div class="row align-items-center coachee-list-card-item">
  <ng-container *ngIf="coachee">
    <div class="col-lg-3 col-3 g-0 d-flex justify-content-end">
      <app-avatar [profile]="coachee.profile" [avatarSize]="'medium'">
      </app-avatar>
    </div>
    <div class="col-lg-9 col-9">
      <div class="row">
        <div class="col coachee-name">
          {{ coachee.profile.first_name + " " + coachee.profile.last_name }}
        </div>
      </div>
      <div class="row">
        <div class="col coachee-info">
          <span class="coachee-info-number">{{
            coachee.celebrationsCount
          }}</span>
          Celebrations |
          <span class="coachee-info-number">{{ coachee.badgeCount }}</span>
          Badges
        </div>
      </div>
    </div>
  </ng-container>
</div>
