<div class="row">
  <div class="col-12 evidence-progress-bar">
    <ngb-progressbar
      class="position-relative"
      type="info"
      [value]="progressData.percent"
      height="20px">
      <div
        class="d-flex justify-content-between px-2 fw-800 fs-13 position-absolute w-100">
        <div class="evidence-progress-title">Evidence of Growth</div>
        <div class="evidence-progress-count">
          {{ progressData.numerator }}/{{ progressData.denominator }}
        </div>
      </div>
    </ngb-progressbar>
  </div>
</div>
