<div class="div my-3">
  <div class="d-flex flex-nowrap align-items-center">
    <ng-container *ngIf="user?.profile">
      <app-avatar
        [profile]="user.profile"
        [avatarSize]="avatarSize || 'medium'">
      </app-avatar>
      <div class="username ms-2">
        <div>
          {{ user.profile.first_name }}
          {{ user.profile.last_name }}
        </div>
      </div>
      <div class="tools">
        <button
          class="btn-delete"
          (click)="deleteShadower()"
          role="button"
          title="Remove Shadower">
          <i class="bi bi-x-circle-fill"></i>
        </button>
      </div>
    </ng-container>
  </div>
</div>
