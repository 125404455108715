import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { BadgeDataDTO } from '../../dtos/badge.dto';
import { BadgeService } from '../../services/badge/badge.service';

@Component({
  selector: 'app-badge-graphic',
  templateUrl: './badge-graphic.component.html',
  styleUrls: ['./badge-graphic.component.scss'],
})
export class BadgeGraphicComponent implements OnInit {
  @ViewChild('downloadBadgeTooltip') downloadBadgeTooltip: NgbTooltip;

  @Input() badge: BadgeDataDTO;

  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'lg';

  @Input() showDownloadButton = false;

  badgeImageExists = false;

  apiUrl: string;

  errorMessage: string;

  constructor(private badgeService: BadgeService) {
    this.apiUrl = EnvironmentService.apiUrl();
  }

  ngOnInit() {
    if (this.badge?.image && this.badge?.image !== '/img/e2L.Logo.webp') {
      this.badgeImageExists = true;
    }
  }

  /**
   * @param badgeId
   *
   * Downloads badge graphic to user's system
   *
   */
  downloadBadge(badgeId: number) {
    this.downloadBadgeTooltip.close();
    window.open(
      `${EnvironmentService.apiUrl()}/egrowe/badges/${badgeId}/download`
    );
  }
}
