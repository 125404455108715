export enum BooleanNumber {
  FALSE,
  TRUE,
}

export const booleanFromBooleanNumber = (value: number): boolean =>
  !(value === BooleanNumber.FALSE);

export const booleanNumberFromBoolean = (value: boolean): number =>
  !value ? BooleanNumber.FALSE : BooleanNumber.TRUE;
