import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { thoughtResponseItemToThought } from '../../helpers/translators/thought-dto.translator';
import {
  CreateThoughtDTO,
  ThoughtApiResponse,
  ThoughtDTO,
} from '../../state/thought/Thought.dto';

@Injectable({ providedIn: 'root' })
export class ThoughtsService {
  thoughtsLoaded = false;

  thoughtsListOutput: BehaviorSubject<ThoughtDTO[]> = new BehaviorSubject<
    ThoughtDTO[]
  >([]);

  user: User;

  constructor(private apiService: APICoreService, private store: Store) {
    this.user = store.selectSnapshot(UserState.getUser) as User;
  }

  loadThoughts() {
    this.thoughtsLoaded = false;

    return (
      this.apiService
        .getRequest(`growelab/thoughts-ideas/for-user/${this.user.id}`)
        .subscribe((res) => {
          if (res?.items) {
            const thoughtItems = res.items.map((thought: ThoughtApiResponse) =>
              thoughtResponseItemToThought(thought)
            );
            this.thoughtsListOutput.next(thoughtItems);
          }
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  addThought(thoughtPayload: CreateThoughtDTO) {
    const payload = {
      thought: thoughtPayload.content,
      user_id: this.user.id,
    };

    this.apiService.postRequest('growelab/thoughts-ideas', payload).subscribe(
      (res) => {
        if (res?.item) {
          const newThought = thoughtResponseItemToThought(res.item);

          const getThoughtsList = this.thoughtsListOutput.value;
          getThoughtsList.push(newThought);

          this.thoughtsListOutput.next(getThoughtsList);
        }
      },
      catchError((err) => {
        throw err;
      })
    );
  }

  removeThought(removeId: string) {
    this.apiService
      .deleteRequest(`growelab/thoughts-ideas/${removeId}`)
      .subscribe(
        (res) => {
          if (res) {
            let getThoughtsList = this.thoughtsListOutput.value;
            getThoughtsList = getThoughtsList.filter((t) => t.id !== removeId);
            this.thoughtsListOutput.next(getThoughtsList);
          }
        },
        catchError((err) => {
          throw err;
        })
      );
  }

  updateThought(thoughtPayload: ThoughtDTO) {
    const thought = thoughtPayload.content;
    const payload = {
      thought,
    };

    this.apiService
      .putRequest(`growelab/thoughts-ideas/${thoughtPayload.id}`, payload)
      .subscribe(
        (res) => {
          if (res.item) {
            let getThoughtsList = this.thoughtsListOutput.value;
            if (getThoughtsList?.length) {
              getThoughtsList = getThoughtsList.map((t) => {
                if (t.id === thoughtPayload.id) {
                  return { ...t, content: res.item.thought };
                }
                return t;
              });
              this.thoughtsListOutput.next(getThoughtsList);
            }
          }
        },
        catchError((err) => {
          throw err;
        })
      );
  }
}
