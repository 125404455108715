export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function arrayFromEnum(enumObj: object) {
  return enumKeys(enumObj).map((key) => enumObj[key]);
}

export function selectOptionsFromEnum(enumObj: object) {
  return arrayFromEnum(enumObj).map((value) => ({
    key: value,
    value: value as string,
  }));
}
