import { DateTime } from 'luxon';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';

import { NgbTimeStructFromDateTime } from '../../../../common/utilities/date-helpers';

export enum TimePickerShift {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

export const datepickerDisplayTime = (hour: number, minute: string): string =>
  `${hour > 12 && hour % 12 > 0 ? hour - 12 : hour}:${minute} ${
    hour < 12 ? 'AM' : 'PM'
  }`;

export const getValidTimepickerValue = (
  unixTimestamp: number,
  tz?: TimezoneLinkBack
): string => {
  const timeStruct = tz
    ? NgbTimeStructFromDateTime(
        DateTime.fromMillis(unixTimestamp * 1000, { zone: tz })
      )
    : NgbTimeStructFromDateTime(DateTime.fromMillis(unixTimestamp * 1000));

  const minute =
    timeStruct.minute.toString().length === 1
      ? `0${timeStruct.minute}`
      : timeStruct.minute;
  return `${timeStruct.hour}:${minute}`;
};
