import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DateTime } from 'luxon';
import { take } from 'rxjs';
import { ReportService } from '../../services/report/report.service';
import { FetchLastUpdatedDate } from './reporting.actions';

export interface ReportingStateModel {
  lastUpdatedDate: string;
  dateObject?: DateTime;
}

@State<ReportingStateModel>({
  name: 'reporting',
  defaults: {
    dateObject: undefined,
    lastUpdatedDate: '',
  },
})
@Injectable({
  providedIn: 'root',
})
export class ReportingState {
  constructor(private reportingService: ReportService) {}

  @Selector()
  static getUpdatedDate(state: ReportingStateModel) {
    return state.lastUpdatedDate;
  }

  @Action(FetchLastUpdatedDate)
  fetchLastUpdatedDate(ctx: StateContext<ReportingStateModel>) {
    const currentState = ctx.getState();
    if (
      !currentState.dateObject ||
      currentState.dateObject.toSeconds() >
        DateTime.now().minus({ minutes: 30 }).toSeconds()
    ) {
      this.reportingService.getLastUpdateDate().subscribe((response) => {
        take(1);
        const updatedTime = DateTime.fromJSDate(
          new Date(response.item.sync_started)
        );
        ctx.patchState({
          lastUpdatedDate: updatedTime.toFormat('MM/dd h:mm a'),
          dateObject: updatedTime,
        });
      });
    }
  }
}
