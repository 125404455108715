import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import { canAccessByFeatureFlag } from '../../utilities/check-route';

export const FeatureGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: RouterStateSnapshot
): boolean => {
  const router = inject(Router);

  const featureFlagService = inject(UnleashService);

  if (!canAccessByFeatureFlag(next, featureFlagService)) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
