import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

type NavRoute = [string[], { relativeTo?: ActivatedRoute }];
@Injectable({
  providedIn: 'root',
})
export class FormsSaveService {
  private navigationHolds: number[] = [];

  private navigationQueue: { [key: number]: NavRoute } = {};

  public promptUnsavedModal: Subject<number> = new Subject();

  constructor(private router: Router) {}

  navigateFromQueue(sessionId: number): void {
    if (this.navigationQueue[sessionId]) {
      this.router.navigate(...this.navigationQueue[sessionId]);
    }
  }

  addToNavigationQueue(sessionId: number, route: NavRoute): void {
    this.navigationQueue[sessionId] = route;
  }

  clearNavigationQueue(sessionId: number) {
    delete this.navigationQueue[sessionId];
  }

  canNavigateAway(sessionId: number): boolean {
    return !this.navigationHolds.includes(sessionId);
  }

  clearNavigationHold(sessionId: number): void {
    this.navigationHolds = this.navigationHolds.filter(
      (id) => id !== sessionId
    );
  }

  addNavigationHold(sessionId: number): void {
    this.navigationHolds.push(sessionId);
  }
}
