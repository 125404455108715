<div class="container mt-5">
  <ng-container *ngIf="courseList; else loading">
    <ng-container *ngFor="let course of courseList">
      <app-online-course [course]="course"></app-online-course>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="w-100 text-center" style="height: 60vh">
      <app-loader />
    </div>
  </ng-template>
</div>
