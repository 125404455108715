import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements AfterViewInit {
  @ViewChild('modal') modal: ModalComponent;

  @Input() video: VideoDTO;

  @Input() showVideoInfo = true;

  @Input() autoOpen = false;

  @Output() readonly videoClosing: EventEmitter<boolean> = new EventEmitter(
    false
  );

  shouldRestart = false;

  isPlaying = false;

  ngAfterViewInit() {
    if (this.autoOpen) {
      this.open();
    }
    this.modal.isDismissing.subscribe(() => {
      this.videoClosing.emit(true);
    });
  }

  open() {
    this.modal.open();
    this.isPlaying = true;
  }

  resetVideo() {
    this.isPlaying = false;
    this.shouldRestart = true;
  }
}
