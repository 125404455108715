<ul class="breadcrumb">
  <li>
    <i class="bi bi-book me-2"></i>
    @if(sharedFolder && folderOwner){
    <a routerLink="/resources/shared/{{ folderOwner.id }}">
      {{ folderOwner.profile.first_name }}
      {{ folderOwner.profile.last_name }}'s Resources
    </a>
    } @else {
    <a routerLink="/resources/my">My Resources </a>
    }
  </li>
  <ng-container *ngFor="let breadcrumb of breadcrumbs">
    @if(breadcrumb.show_link) {
    <li>
      @if(sharedFolder){
      <a
        routerLink="/resources/shared/{{ folderOwner.id }}/folder/{{
          breadcrumb.id
        }}"
        >{{ breadcrumb.title }}</a
      >
      } @else {
      <a routerLink="/resources/my/folder/{{ breadcrumb.id }}">{{
        breadcrumb.title
      }}</a>
      }
    </li>
    } @else {
    <li>{{ breadcrumb.title }}</li>
    }
  </ng-container>
</ul>
