<div class="container pb-5 pt-3">
  <a href="login" class="btn btn-sm btn-primary px-4">Login</a>
  <div class="text-center">
    <a href="/">
      <img
        id="groweLabLogo"
        src="assets/logo_dark.png"
        class="mt-2 mb-5"
        width="240"
        alt="GroweLab Logo" />
    </a>

    <h1 class="fw-800">The Most Versatile Instructional Coaching Platform</h1>
    <div class="fs-19 mx-auto mt-3 mb-5" style="max-width: 860px">
      Transform your traditional professional development into an integrated,
      thriving talent development system with features specifically designed to
      meet the needs of public school educators.
    </div>
  </div>
  <hr class="mb-5" />
  <div class="row">
    <div class="col-md-6 col-lg-4">
      <div class="welcome-card">
        <h2>Professional Development for Teachers</h2>
        <div class="welcome-card-body">
          GroweLab was designed to keep teachers growing in the skills and
          competencies proven to reduce teacher burnout and improve student
          outcomes.
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="welcome-card">
        <h2>Coaching</h2>
        <div class="welcome-card-body">
          GroweLab is filled with time-saving coaching features to help expand
          the capacity of your coaches to go deeper with even more teachers.
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="welcome-card">
        <h2>Microcredentials & Badging</h2>
        <div class="welcome-card-body">
          Develop a portfolio of job-embedded evidence of practice that can be
          reviewed by your coach to earn performance-based microcredentials and
          badges.
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="welcome-card">
        <h2>Classroom Observation</h2>
        <div class="welcome-card-body">
          Quantify and measure teacher behaviors with walkthrough forms that can
          be customized to align to your school or district's priorities.
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="welcome-card">
        <h2>Video Evidence & Feedback</h2>
        <div class="welcome-card-body">
          Upload video evidence of classroom implementation that can be reviewed
          and commented on by instructional coaches.
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="welcome-card">
        <h2>Education Analytics & Reporting</h2>
        <div class="welcome-card-body">
          Go beyond PD attendance and completion tracking with easy-to-read
          reports that turn all of your coaching and growth data into powerful,
          data-informed insights.
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-8">
      <div class="text-center my-5">
        <h2 class="fw-800">
          Ready to bring the most powerful instructional coaching platform to
          your school or district?
        </h2>
        <a href="https://engage2learn.org/" class="btn btn-lg btn-primary my-5"
          >Explore Features</a
        >
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
