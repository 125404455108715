import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadType } from '../../enums/upload-type.enum';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class StandardIconFileService extends FileService {
  override uploadType = UploadType.STANDARD_ICON;

  standardId: number | null = null;

  setStandardId(id: number | null) {
    this.standardId = id;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  override uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFormRequest(
      `egrowe/rubric-builder/standard-icon/${this.standardId}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }
}
