<div class="form-group mb-3">
  <label class="form-label mb-2" [for]="uuid">{{ label }}</label>
  <input
    [disabled]="disabled"
    type="text"
    class="form-control"
    [id]="uuid"
    [(ngModel)]="value"
    (ngModelChange)="outputValue()" />
</div>
