/* eslint-disable no-bitwise */
export const getRgbFromHex = (color: string): number[] => {
  const hex = color.replace('#', '');
  const bigint = parseInt(hex, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;
  return [red, green, blue];
};

const getLuminance = (rgb: number[]): number => {
  const alpha = rgb.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return 0.2126 * alpha[0] + 0.7152 * alpha[1] + 0.0722 * alpha[2];
};

export const getContrastRatio = (
  foregroundColor: string,
  backgroundColor: string
): number => {
  const rgb1 = getRgbFromHex(foregroundColor);
  const rgb2 = getRgbFromHex(backgroundColor);
  const l1 = getLuminance(rgb1);
  const l2 = getLuminance(rgb2);
  return (
    Math.round(((Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05)) * 100) /
    100
  );
};

export const getTextColor = (backgroundColor: string): string => {
  const contrastRatio = getContrastRatio('#000000', backgroundColor);
  return contrastRatio > 4.5 ? 'black' : 'white';
};
