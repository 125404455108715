import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { videoDTOFromAPIResponse } from '../../helpers/translators/video.translators';
import { IntroSessionService } from '../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-intro-your-why',
  templateUrl: './intro-your-why.component.html',
  styleUrls: ['./intro-your-why.component.scss'],
})
export class IntroYourWhyComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  whyVideo: VideoDTO;

  user: User;

  initialWhy: string;

  whyComplete: boolean;

  constructor(
    public introSessionService: IntroSessionService,
    private userService: UserService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.introSessionService.getWhyVideo().subscribe((res) => {
      if (res.item) {
        this.whyVideo = videoDTOFromAPIResponse(res.item);
      }
    });

    this.setUserDetails();

    this.subs.push(
      this.introSessionService.whyComplete.subscribe((bool) => {
        this.whyComplete = bool;
      })
    );
  }

  setUserDetails() {
    if (this.user?.profile.why) {
      if (!this.whyComplete) {
        this.completeWhy();
      }
      this.initialWhy = this.user?.profile.why;
    } else {
      this.initialWhy = '';
    }
  }

  saveUserProfileWhy() {
    if (this.initialWhy == null || this.initialWhy.length < 1) {
      return;
    }
    if (this.user) {
      this.user.profile.why = this.initialWhy;
      const payload = {
        profile: {
          why: this.user.profile.why,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {
        if (!this.whyComplete) {
          this.completeWhy();
        }
      });
    }
  }

  completeWhy() {
    this.introSessionService.setWhyComplete(true, true);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }
}
