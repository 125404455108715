import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { CreateEditCelebrationModalComponent } from '../../shared/components/modals/create-edit-celebration-modal/create-edit-celebration-modal.component';
import { CelebrationDTO } from '../../shared/dtos/celebrations.dto';
import { CelebrationsService } from '../../shared/services/celebrations/celebrations.service';

@Component({
  selector: 'app-single-celebration-page',
  templateUrl: './single-celebration-page.component.html',
  styleUrls: ['./single-celebration-page.component.scss'],
})
export class SingleCelebrationPageComponent implements OnInit {
  @ViewChild('celebrationModal')
  celebrationModal: CreateEditCelebrationModalComponent;

  user: User | null = null;

  celebration: CelebrationDTO | undefined = undefined;

  celebrationIdParam: number;

  isLoading = false;

  errorMessage: string;

  deletedMessage: string;

  editCelebration: CelebrationDTO | null = null;

  constructor(
    private store: Store,
    private celebrationsService: CelebrationsService,
    private route: ActivatedRoute
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.celebrationIdParam = parseInt(params['celebrationId']);
    });
    this.getCelebration(this.celebrationIdParam);
  }

  getCelebration(id: number) {
    this.isLoading = true;
    this.celebration = undefined;
    this.celebrationsService.getCelebration(id).subscribe(
      (res) => {
        this.celebration = res;
      },
      (err) => {
        this.errorMessage = err.error.message;
      }
    );
    this.isLoading = false;
  }

  openEditModal(celebration: CelebrationDTO) {
    this.editCelebration = celebration;
    this.celebrationModal.openModal();
  }

  handleEditCelebrationEvent() {
    this.getCelebration(this.celebrationIdParam);
  }

  deleteCelebration(celebrationId: number) {
    this.celebrationsService
      .deleteCelebration(celebrationId)
      .subscribe((res) => {
        if (res) {
          this.celebration = undefined;
          this.deletedMessage = 'Celebration has been deleted.';
        }
      });
  }
}
