import {
  Component,
  ElementRef,
  ErrorHandler,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AlertService } from '../../services/alert/alert.service';
import { RosterUploadService } from '../../services/rosters/roster-upload.service';
import { JobProcessChangesAPIResponse } from '../../types/responses/roster-upload.responses';
import { ModalComponent } from '../modals/modal/modal.component';

interface UploadingItemDTO {
  id: string;
  name: string;
  progress: number;
}

@Component({
  selector: 'app-growelab-user-csv-upload-modal',
  templateUrl: './growelab-user-csv-upload-modal.component.html',
  styleUrls: ['./growelab-user-csv-upload-modal.component.scss'],
})
export class GroweLabUserCsvUploadModalComponent implements OnDestroy {
  @Input() districtId: number;

  @ViewChild('modal') public modal: ModalComponent;

  @ViewChild('fileUpload') public fileUpload: ElementRef<HTMLInputElement>;

  isUploading = false;

  dropzoneActive = false;

  uploadingItems: { [id: string]: UploadingItemDTO } = {};

  displayError = false;

  errorMessages: string[] = [];

  // 1. 'fileUpload'
  // 2. 'reviewChanges'
  // 3. 'applyChanges'
  currentScreen = 'fileUpload';

  jobId: number;

  percentProcessed = 0;

  percentApplied = 0;

  changes: JobProcessChangesAPIResponse[] = [];

  usersCreated = 0;

  usersUpdated = 0;

  submitLoading = false;

  subs: Subscription[] = [];

  constructor(
    private rosterUploadService: RosterUploadService,
    private errorHandlerService: ErrorHandler,
    private alertService: AlertService
  ) {}

  toggleDropZone(status: boolean) {
    this.dropzoneActive = status;
  }

  onUploadButtonClick() {
    this.closeErrorMsg();
    this.fileUpload.nativeElement.click();
  }

  handleDrop(event: DragEvent) {
    this.closeErrorMsg();
    this.toggleDropZone(false);
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.uploadFile(event.dataTransfer.files[0]);
    }
  }

  closeErrorMsg() {
    this.errorMessages = [];
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      Array.from(input.files).forEach((file: File) => {
        this.uploadFile(file);
      });
      this.fileUpload.nativeElement.value = '';
    }
  }

  uploadFile(file: File) {
    this.isUploading = true;

    const fileName = file.name;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);

    if (ext === 'csv') {
      const id = uuid();
      const uploadingItem: UploadingItemDTO = {
        id,
        name: file.name,
        progress: 0,
      };
      this.uploadingItems[id] = uploadingItem;

      this.subs.push(
        this.rosterUploadService
          .uploadGroweLabUserCsvFile(file, this.districtId)
          .subscribe({
            error: (error) => {
              this.errorMessages = [error.error.message];
              this.errorHandlerService.handleError(this.errorMessages);
              this.isUploading = false;
            },
            next: (res) => {
              if (res.item) {
                this.jobId = res.item.id;
                this.processFile(this.jobId);
              }
            },
          })
      );
    } else {
      setTimeout(() => {
        this.errorMessages = ['Make sure you are uploading a .CSV file type.'];
        this.errorHandlerService.handleError(this.errorMessages);
        this.isUploading = false;
      }, 1000);
    }
  }

  processFile(jobId: number) {
    this.subs.push(
      this.rosterUploadService
        .processUserImportJob({ user_import_job_id: jobId })
        .subscribe({
          error: (error) => {
            this.errorMessages = [error.error.message];
            this.errorHandlerService.handleError(this.errorMessages);
            this.isUploading = false;
          },
          next: (importJobResponse) => {
            if (importJobResponse.errors.length > 0) {
              this.errorMessages = this.errorMessages.concat(
                importJobResponse.errors
              );
              this.errorHandlerService.handleError(this.errorMessages);
            }
            if (importJobResponse.percent_processed === 100) {
              this.percentProcessed = importJobResponse.percent_processed;
              this.changes = this.changes.concat(importJobResponse.changes);
              this.changes.forEach((change) => {
                if (change.user_info.is_new) {
                  this.usersCreated += 1;
                } else if (this.hasAdditionalFields(change)) {
                  this.usersUpdated += 1;
                }
              });
              this.errorHandlerService.handleError(this.errorMessages);
              this.isUploading = false;
              if (
                this.errorMessages.length !==
                importJobResponse.job.total_records
              ) {
                this.currentScreen = 'reviewChanges';
              }
            } else {
              this.changes = this.changes.concat(importJobResponse.changes);
              this.percentProcessed = importJobResponse.percent_processed;
              this.processFile(jobId);
            }
          },
        })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  hasAdditionalFields(change: JobProcessChangesAPIResponse): boolean {
    const requiredFields = ['user_info', 'email'];
    return Object.keys(change).some((key) => !requiredFields.includes(key));
  }

  applyChanges() {
    this.currentScreen = 'applyChanges';
    this.submitLoading = true;
    this.subs.push(
      this.rosterUploadService
        .applyChanges({ user_import_job_id: this.jobId })
        .subscribe({
          error: (error) => {
            this.errorMessages = [error.error.message];
            this.errorHandlerService.handleError(this.errorMessages);
            this.isUploading = false;
          },
          next: (res) => {
            if (res) {
              this.submitLoading = false;
              if (res.errors.length > 0) {
                this.errorMessages = res.errors;
                this.errorHandlerService.handleError(this.errorMessages);
              } else {
                this.percentApplied = res.percent_processed;
                if (res.percent_processed === 100) {
                  this.modal.close();
                  this.alertService.showAlert('Successfully Uploaded Users');
                  this.reset();
                } else {
                  this.applyChanges();
                }
              }
            }
          },
        })
    );
  }

  cancel() {
    this.modal.close();
    this.reset();
  }

  reset() {
    this.currentScreen = 'fileUpload';
    this.changes = [];
    this.usersCreated = 0;
    this.usersUpdated = 0;
    this.percentProcessed = 0;
    this.percentApplied = 0;
    this.errorMessages = [];
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
