import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription, take } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { Form, FormStatus } from 'src/app/private/shared/dtos/forms.dto';

import { ChartType } from '../../shared/enums/chart-type.enum';
import { FormsService } from '../../shared/services/forms/forms.service';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { FetchLastUpdatedDate } from '../../shared/state/reporting/reporting.actions';
import { ReportingState } from '../../shared/state/reporting/reporting.state';

@Component({
  selector: 'app-reporting-observations',
  templateUrl: './reporting-observations.component.html',
  styleUrls: ['./reporting-observations.component.scss'],
})
export class ReportingObservationsComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  user: User;

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictSimpleDto | null;

  isE2L = false;

  formsList: Form[];

  observationFormDistrictIds: number[];

  selectedForm: Form | null = null;

  lookForsRequestParams: ReportRequestParams;

  formHasLookFors = false;

  calibrationRequestParams: ReportRequestParams;

  formHasCalibration = false;

  chartTypes = ChartType;

  observedAnswersParams: ReportRequestParams[];

  questionTitles: string[];

  loading = false;

  textAnswersParams: ReportRequestParams[];

  textQuestionIds: string[];

  textQuestionTitles: string[];

  filters: KpiReportFilter[];

  canFilterByDistrict = false;

  districtDisplayName = '';

  disableDownload = true;

  lastUpdatedDate: string;

  constructor(
    private formsService: FormsService,
    private store: Store,
    private reportFiltersService: ReportPageFiltersService
  ) {
    const updatedDate$ = this.store.select(ReportingState.getUpdatedDate);
    this.subs.push(
      updatedDate$.subscribe((date) => {
        this.lastUpdatedDate = date;
      })
    );
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canFilterByDistrict = checkIfE2L(this.user) || checkIfB2b(this.user);
  }

  ngOnInit(): void {
    if (this.user?.district) {
      this.observationFormDistrictIds = [this.user.district.id];
      this.getForms();
    }

    this.subs.push(
      this.reportFiltersService.filters.subscribe((filters) => {
        if (filters.formFilter && !this.selectedForm) {
          this.selectedForm = filters.formFilter;
          this.disableDownload = false;
        }
        if (filters.schoolYearFilter) {
          this.filters = translateKpiReportFilterFromFilterDTO(filters);
          this.loadReports();
        }
        if (filters.districtFilter) {
          this.observationFormDistrictIds = [];
          this.districtDisplayName = '';
          const lastIndex = filters.districtFilter.length - 1;
          filters.districtFilter.forEach((district, index) => {
            this.observationFormDistrictIds.push(district.id);
            this.districtDisplayName += district.title;
            if (index !== lastIndex) {
              this.districtDisplayName += ', ';
            }
          });
          this.getForms();
        }
      })
    );
  }

  getForms() {
    this.store.dispatch(new FetchLastUpdatedDate());
    this.loading = true;
    let totalCount = 0;
    let loadCount = 0;
    const tempForms: Form[] = [];
    this.formsList = [];
    this.observationFormDistrictIds.forEach((districtId) => {
      totalCount += 1;
      this.formsService
        .loadForms(districtId, 1000, 1, undefined, FormStatus.PUBLISHED)
        .subscribe((response) => {
          take(1);
          loadCount += 1;
          tempForms.push(...response[0]);
          if (loadCount === totalCount) {
            tempForms.sort((a, b) => (a.title > b.title ? 0 : 1));
            tempForms.forEach((form) => {
              if (
                !this.formsList.length ||
                this.formsList.filter((f) => f.id === form.id).length === 0
              ) {
                this.formsList.push(form);
              }
            });
            if (
              this.selectedForm &&
              this.formsList.findIndex(
                (form) => form.id === this.selectedForm?.id
              ) < 0
            ) {
              this.selectedForm = this.formsList[0];
              this.disableDownload = false;
            }
            this.loading = false;
          }
        });
    });
  }

  updateFormSelect(form: Form) {
    if (form) {
      this.reportFiltersService.setSelectedForm(form);
      this.disableDownload = false;
    } else {
      this.reportFiltersService.removeFilter('formFilter');
      this.disableDownload = true;
    }
  }

  loadReports() {
    if (this.reportFiltersService.currentFilters.formFilter) {
      this.store.dispatch(new FetchLastUpdatedDate());
      this.getLookForsReport();
      this.getCalibrationReport();
      this.getObservedAnswersReports();
    }
  }

  getLookForsReport() {
    this.formHasLookFors = false;
    if (this.selectedForm) {
      this.selectedForm.fields.forEach((field) => {
        if (field.type === 'competency') {
          this.formHasLookFors = true;
        }
      });

      this.lookForsRequestParams = {
        dataset: 'fct_form_observed_level',
        dimension: 'standard_name',
        group: 'indicator_level',
        measure: {
          type: 'count',
          field: 'indicator_level',
        },
        filters: [...this.filters],
      };
    }
  }

  getCalibrationReport() {
    this.formHasCalibration = false;
    if (this.selectedForm) {
      if (this.selectedForm.type === 'coaching-calibration') {
        this.formHasCalibration = true;
      }

      this.calibrationRequestParams = {
        dataset: '',
        filters: [...this.filters],
        page: 0,
        per_page: 10,
      };
    }
  }

  getObservedAnswersReports() {
    if (this.selectedForm) {
      this.observedAnswersParams = [];
      this.questionTitles = [];
      this.textAnswersParams = [];
      this.textQuestionIds = [];
      this.textQuestionTitles = [];
      this.selectedForm.fields.forEach((field) => {
        if (this.selectedForm) {
          if (
            field.type === 'checkbox' ||
            field.type === 'radio' ||
            field.type === 'select'
          ) {
            this.questionTitles.push(field.label);
            this.observedAnswersParams.push({
              dimension: 'field_option_label',
              dataset: 'fct_form_item_response',
              measure: {
                type: 'count',
                field: 'field_option_id',
              },
              filters: [
                ...this.filters,
                {
                  codename: 'field_guid',
                  value: [field.id],
                },
              ],
            });
          } else if (field.type === 'text' || field.type === 'textarea') {
            this.textQuestionTitles.push(field.label || 'Text Question');
            this.textQuestionIds.push(field.id);
            this.textAnswersParams.push({
              dataset: 'fct_form_item_opentext',
              measure: {
                type: 'count',
                field: '*',
              },
              filters: [
                ...this.filters,
                {
                  codename: 'field_guid',
                  value: [field.id],
                },
              ],
              page: 0,
              per_page: 10,
            });
          }
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  formatter(params: any, response: any): string {
    let percentage = 0;
    let yAxisIndex = -1;
    if (params.length) {
      const groupId = params[0].data.groupId;
      percentage = params[0].data.value * 100;
      let labelCount = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.labels.forEach((eachLabel: any) => {
        if (groupId === eachLabel) {
          yAxisIndex = labelCount;
        }
        labelCount += 1;
      });
    }
    let numerator = 0;
    if (response.datasets[0].dataNumerator) {
      numerator = response.datasets[0].dataNumerator[yAxisIndex];
    }
    let denominator = 0;
    if (response.datasets[0].dataDenominator) {
      denominator = response.datasets[0].dataDenominator[yAxisIndex];
    }
    return `
    <strong>
      ${response.labels[yAxisIndex]}:
    </strong>
    <br/>
    ${numerator}/${denominator} Answer Observed, ${percentage.toFixed(1)}%
    `;
  }

  // TEMPORARY - THIS PROCESS WILL BE HANDLED BY FILTER IN UPCOMING TICKET
  removeSelectedForm() {
    this.selectedForm = null;
    this.disableDownload = true;
  }

  downloadCSV(): void {
    this.disableDownload = true;
    this.reportFiltersService.queueReportDownload('observation_reports');
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
