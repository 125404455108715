import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  LibraryShelfItemModelDTO,
  ResourceFiltersDTO,
  ResourceFolderModelDTO,
  ResourceListItemDTO,
} from '../../../dtos/resources.dto';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent implements OnInit, OnDestroy {
  user: User;

  folderId: number;

  createdByMeFolderId: string;

  @Input() folder = {} as ResourceFolderModelDTO | null;

  @Input() multiFolders: boolean;

  folderItems: ResourceListItemDTO[] | null = null;

  newFolder = {} as ResourceFolderModelDTO;

  resourceFilters = {} as ResourceFiltersDTO;

  folderListMeta: PaginationDTO;

  subs: Subscription[] = [];

  newFolderItem = {} as LibraryShelfItemModelDTO;

  selectedResource: ResourceListItemDTO;

  myStarredChildFolders: ResourceFolderModelDTO[] = [];

  myChildFolders: ResourceFolderModelDTO[] = [];

  isCreatedByMe = false;

  hasChildren = false;

  shareUrl: string;

  tempTitle = '';

  tempDescription = '';

  @ViewChild('copiedAlert', { static: false }) copiedAlert: NgbAlert;

  copiedStatus = false;

  showAddButton = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resourceService: ResourcesBookmarkService,
    private clipboard: Clipboard,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
  }

  ngOnInit() {
    this.newFolder.title = '';
    this.route.params.subscribe((url) => {
      if (url['folderId'] === 'created-by-you') {
        this.isCreatedByMe = true;
        this.createdByMeFolderId = 'created-by-me';
      } else {
        this.folderId = parseInt(url['folderId']);
      }
      this.getFolder();
    });
  }

  getFolder() {
    this.folderItems = null;
    const payloadId: number | string = this.createdByMeFolderId
      ? this.createdByMeFolderId
      : this.folderId;
    this.resourceService.getFolder(payloadId).subscribe((response) => {
      if (response.item) {
        this.folder = response.item;

        if (this.folder) {
          if (this.folder.childShelves) {
            this.myStarredChildFolders = this.folder.childShelves.filter(
              (shelf) => shelf.usersFavorite === 1
            );
            this.myChildFolders = this.folder.childShelves.filter(
              (shelf) => shelf.usersFavorite === 0
            );
          }

          this.newFolder.parent_shelf_id = this.folder.id;
          if (this.folder.childShelves && this.folder.resources) {
            if (
              this.folder.childShelves.length > 0 ||
              this.folder.resources.length > 0
            ) {
              this.hasChildren = true;
            } else {
              this.hasChildren = false;
            }
          }
          if (response.item.id) {
            this.showAddButton = true;
          } else {
            this.showAddButton = false;
          }

          if (this.folder.title) {
            this.tempTitle = this.folder.title;
          }

          if (this.folder.description) {
            this.tempDescription = this.folder.description;
          }
          this.getFolderItems();
        }
      }
    });
  }

  getFolderItems() {
    this.folderItems = null;
    const payloadId: number | string = this.createdByMeFolderId
      ? this.createdByMeFolderId
      : this.folderId;
    this.resourceService
      .getFolderItems(payloadId, this.resourceFilters)
      .subscribe((result) => {
        if (result.items) {
          this.folderItems = result.items;
          this.folderListMeta = result._meta;
        } else {
          this.folderItems = [];
        }
      });
  }

  paginationChange() {
    if (this.resourceFilters) {
      this.resourceFilters.page = this.folderListMeta.currentPage;
      this.resourceFilters.per_page = this.folderListMeta.perPage;
      this.getFolderItems();
    }
  }

  saveTitleDescription() {
    this.resourceService
      .updateFolderInfo(this.folderId, this.tempTitle, this.tempDescription)
      .subscribe(() => {
        this.getFolder();
      });
  }

  deleteFolder() {
    this.resourceService.deleteFolder(this.folderId).subscribe(() => {
      this.router.navigateByUrl('/resources/my');
    });
  }

  resourceSelected(selectedResource: ResourceListItemDTO) {
    this.selectedResource = selectedResource;
    this.newFolderItem.elearn_favorite_shelf_id = this.folderId;
    this.newFolderItem.ref_table = 'elearn_resource';
    this.newFolderItem.ref_table_id = selectedResource.id;
  }

  copyToClipboard(folderId: number) {
    if (folderId) {
      this.clipboard.copy(
        `${window.location.origin}/resources/shared/${this.user.id}/folder/${folderId}`
      );
    } else {
      this.clipboard.copy(window.location.href);
    }
    this.copiedStatus = true;
    setTimeout(() => {
      this.copiedStatus = false;
    }, 3000);
  }

  resetFolder() {
    this.folder = null;
    this.tempDescription = '';
  }

  createFolder() {
    this.resourceService.createFolder(this.newFolder).subscribe((response) => {
      if (response.item) {
        this.myChildFolders.push(response.item);
      }
      this.newFolder.title = '';
      this.newFolder.description = '';
    });
  }

  starSelect(id: number, favorite: number, folder: ResourceFolderModelDTO[]) {
    if (favorite) {
      const starSelected = folder.find(
        (item: ResourceFolderModelDTO) => item.id === id
      );
      if (starSelected) starSelected.isLoading = true;
      this.resourceService.deleteStarFolder(id).subscribe((response) => {
        if (response) {
          this.updateLocalFolder(id, 0);
        }
      });
    } else {
      const selected = folder.find(
        (item: ResourceFolderModelDTO) => item.id === id
      );
      if (selected) selected.isLoading = true;
      this.resourceService.postStarFolder(id).subscribe((response) => {
        if (response) {
          this.updateLocalFolder(id, 1);
        }
      });
    }
  }

  updateLocalFolder(id: number, value: number) {
    if (this.folder?.childShelves) {
      this.folder.childShelves.forEach((item) => {
        if (item.id === id) {
          item.usersFavorite = value;
          item.isLoading = false;
        }
      });
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
