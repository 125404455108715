import { Injectable } from '@angular/core';
import { PresenceChannel } from 'pusher-js';
import { Subject } from 'rxjs';
import { AvatarDTO } from '../../components/avatar/avatar.dto';
import { avatarDTOFromPusher } from '../../components/avatar/avatar.translators';
import { PusherGuide } from '../pusher/pusher-guide.service';
import { PusherService } from '../pusher/pusher.service';

@Injectable({
  providedIn: 'root',
})
export class CoachingLogAttendancePusherService extends PusherGuide {
  public attendeeRosters: {
    [channelId: string]: { avatar: AvatarDTO; id: string }[];
  } = {};

  public attendeeRostersStream = new Subject<{
    [channelId: string]: { avatar: AvatarDTO; id: string }[];
  }>();

  public attendeeRostersNotifier = this.attendeeRostersStream.asObservable();

  constructor(pusherService: PusherService) {
    super(pusherService, 'presence');
  }

  updateAttendeeRoster(channelId: string) {
    const channel = this.getChannel(channelId) as PresenceChannel;
    const logId = channelId.replace('coachlog-v2-', '');
    this.attendeeRosters[logId] = Object.keys(channel.members.members).map(
      (key) => ({
        avatar: avatarDTOFromPusher(channel.members.members[key]),
        id: key,
      })
    );
    this.attendeeRostersStream.next(this.attendeeRosters);
  }

  protected override addDefaultBindings(channelId: string): void {
    const channel = this.getChannel(channelId);
    this.updateAttendeeRoster(channelId);
    if (channel) {
      channel.bind('pusher:subscription_succeeded', () => {
        this.updateAttendeeRoster(channelId);
      });

      channel.bind('pusher:member_added', () => {
        this.updateAttendeeRoster(channelId);
      });

      channel.bind('pusher:member_removed', () => {
        this.updateAttendeeRoster(channelId);
      });
    }
  }
}
