<div class="card border-0">
  <ng-container *ngIf="!isLoading; else placeHolder">
    <div class="card-header p-3">
      <div class="card-title" (click)="triggerCollapse()">
        mySmartCoach
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 px-5 overflow-auto msc-card-body text-center fs-16">
        <ng-container *ngIf="currentCoach; else noCoach">
          <div class="d-flex justify-content-center mt-2">
            <app-avatar avatarSize="x-large" [profile]="currentCoach.profile" />
          </div>
          <div class="fw-800 fs-16 mt-1">
            SmartCoach {{ currentCoach.profile.first_name }}
          </div>
          <ng-container *ngIf="upcomingSession; else noSession">
            <div class="fw-200 mt-3">Your next session is scheduled for</div>
            <div class="fw-800 mb-3">
              {{
                upcomingSession.startDatetime
                  | showDateTimezone : "MMM d, yyyy" : undefined : false
              }}
            </div>
            <button
              (click)="goToNextScheduledSession()"
              class="btn btn-primary fw-800">
              Start Session <i class="bi bi-arrow-right"></i>
            </button>
          </ng-container>
          <ng-template #noSession>
            <div class="fw-200 my-3">
              Keep that growth going and schedule your next mySmartCoach
              session!
            </div>
            <div class="d-flex justify-content-center">
              <button routerLink="/calendar" class="btn btn-primary fw-800">
                Schedule a Session <i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #noCoach>
          <div class="d-flex justify-content-around mt-2">
            <app-avatar
              *ngFor="let coach of coachOptions"
              avatarSize="x-large"
              [profile]="coach.profile" />
          </div>
          <div class="fw-200 my-3">
            Start the Intro Session now to unlock your growth potential!
          </div>
          <div class="d-flex justify-content-center">
            <button
              routerLink="/smart-coach/intro-session"
              class="btn btn-primary fw-800">
              Choose Your Coach <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
