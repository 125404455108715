import {
  ThoughtDTO,
  ThoughtApiResponse,
} from '../../state/thought/Thought.dto';

export const thoughtResponseItemToThought = (
  thoughtItem: ThoughtApiResponse
): ThoughtDTO => ({
  id: thoughtItem.id.toString(),
  content: thoughtItem.thought,
});
