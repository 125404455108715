<div class="container all-celebrations-container">
  <app-general-header title="Celebration"></app-general-header>
  <div class="card border-0 p-3">
    <div class="row">
      <a
        routerLink="/celebrations"
        class="text-decoration-none text-body-secondary">
        < Back to All Celebrations
      </a>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <h1 class="fw-800">Celebration</h1>
      </div>
    </div>
    <div class="purple-border my-3"></div>
    <app-create-edit-celebration-modal
      (createCelebrationEvent)="handleEditCelebrationEvent()"
      [editCelebration]="editCelebration"
      [user]="user"
      #celebrationModal />
    <ng-container *ngIf="isLoading; else celebrationTemplate">
      <div class="w-100" style="background-color: #fff; height: 400px">
        <app-loader></app-loader>
      </div>
    </ng-container>
    <ng-template #celebrationTemplate>
      <ng-container *ngIf="celebration">
        <div class="card-body p-5">
          <app-celebration-item
            [user]="user"
            (editCelebrationEvent)="openEditModal($event)"
            (deleteCelebrationEvent)="deleteCelebration($event)"
            [celebration]="celebration"></app-celebration-item>
        </div>
      </ng-container>
      <ng-container *ngIf="errorMessage">
        <p class="text-danger fw-light text-center my-5">
          {{ errorMessage }}
        </p>
      </ng-container>
      <ng-container *ngIf="deletedMessage">
        <p class="text-body-secondary fw-light text-center my-5">
          {{ deletedMessage }}
        </p>
      </ng-container>
    </ng-template>
  </div>
</div>
