import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CreateSchoolPayload } from '../types/payloads/rosters.payloads';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  constructor(private apiService: APICoreService) {}

  createSchool(payload: CreateSchoolPayload) {
    return this.apiService.postRequest('schools', payload);
  }

  deleteSchool(schoolId: number) {
    return this.apiService.deleteRequest(`schools/${schoolId}`);
  }

  updateSchool(schoolId: number, payload: CreateSchoolPayload) {
    return this.apiService.putRequest(`schools/${schoolId}`, payload);
  }
}
