<div class="form-group my-1 choices">
  <h3 class="fs-6 form-label">Options</h3>
  <div class="choice mb-1" *ngFor="let choice of data">
    <label [for]="id" class="visually-hidden">Enter option</label>
    <div class="d-flex">
      <input
        [id]="id"
        class="form-control fs-15 border-tertiary"
        [(ngModel)]="choice.label"
        (keyup)="choice.value = slugify(choice.label); dataChange.emit(data)"
        type="text" />
      <button (click)="deleteChoice(choice.value)" class="btn px-1">
        <span class="visually-hidden">Delete option</span>
        <i class="bi bi-x-circle fs-13 color-danger"></i>
      </button>
    </div>
  </div>
  <button class="btn btn-link mt-1 btn-sm" (click)="addChoice()">
    <i class="bi bi-plus-circle"></i> Add Option
  </button>
</div>
