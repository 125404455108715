<label class="visually-hidden" [id]="modalLabelId"
  >{{ modalLabel
  }}<label>
    <ng-template #modal>
      <button
        type="button"
        class="btn-close position-absolute top-0 start-100 translate-middle rounded-circle bg-white border border-primary"
        aria-label="Close"
        ngbTooltip="Close"
        *ngIf="config.xCloseButton"
        style="z-index: 2000"
        (click)="dismiss()"></button>
      <div [ngClass]="config.headerClass">
        <h5 class="modal-title">{{ config.titleText }}</h5>
        <ng-content select="[headerContent]"></ng-content>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer" *ngIf="config.showFooter">
        <ng-container *ngIf="config.customFooter">
          <div class="w-100 text-end">
            <ng-content select="[footerContent]"></ng-content>
          </div>
        </ng-container>
        <ng-container *ngIf="!config.customFooter">
          <button
            type="button"
            [ngClass]="config.dismissButton?.classes"
            (click)="dismiss()"
            [disabled]="config.closeButtonDisabled"
            *ngIf="config.showDismissButton">
            {{ config.dismissButton?.label }}
          </button>
          <button
            type="button"
            [ngClass]="config.closeButton.classes"
            (click)="close()"
            *ngIf="!config.hideCloseButton">
            {{ config.closeButton.label }}
          </button>
        </ng-container>
      </div>
    </ng-template>
  </label></label
>
