<app-modal
  #celebrationModal
  [modalConfig]="{
    titleText: 'Celebration',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="container">
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center">
        <div
          class="icon"
          [ngStyle]="{
            'background-image': 'url(/assets/celebrations.svg)'
          }"></div>
        <div class="fw-800 fs-2 ms-3">Celebrate Your Team!</div>
      </div>
    </div>
    <div *ngIf="!editCelebration" class="row">
      <div class="col-12">
        <label class="form-label">Add User(s)</label>
        <app-user-select
          (selectedUser)="addChosenUser($event)"
          [defaultUsers]="users"
          [currentUser]="user"
          [includeE2l]="true"
          [clearAfterSelection]="true" />
      </div>
      <ng-container *ngFor="let user of users">
        <app-new-coaching-attendee
          [user]="user"
          (removedAttendee)="deleteChosenUser(user)" />
      </ng-container>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label">Select Competency:</label>
        <app-competency-select
          #competencySelect
          [defaultCompetency]="
            competencyList.length > 0 ? competencyList[0] : null
          "
          [clearAfterSelection]="false"
          (selectedCompetency)="updateCompetency($event)"
          [districtIds]="districtIds" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label">Comments:</label>
        <app-wysiwyg-editor #wysiwyg version="mini" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-label">Attach File:</label>
        <app-file-upload
          #uploadComponent
          [style]="uploadStyle"
          [multiple]="true"
          (outputFiles)="updateAttachments($event)" />
        <app-file-list
          *ngIf="attachments.length > 0"
          [fileList]="attachments"
          (deletedFile)="deleteAttachment($event)" />
      </div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="!editCelebration; else edit">
        <button (click)="clearFields()" class="btn btn-reset">Clear All</button>
        <button
          [disabled]="
            users.length < 1 || !editor.editorContent || isUserIncluded
          "
          (click)="createCelebration()"
          class="btn btn-primary">
          Create
        </button>
      </ng-container>
      <ng-template #edit>
        <button type="button" class="btn" (click)="closeModal()">Cancel</button>
        <button
          [disabled]="
            users.length < 1 || !editor.editorContent || isUserIncluded
          "
          (click)="editCelebrationHandler()"
          class="btn btn-primary ms-auto">
          Save
        </button>
      </ng-template>
    </div>
  </div>
</app-modal>
