import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TopicBuilderService } from 'src/app/private/shared/services/topic-builder/topic-builder.service';
import { CompetencyBuilderIndicatorGroupDTO } from 'src/app/private/shared/types/responses/competency-builder.responses';

@Component({
  selector: 'app-builder-leveled-competency-strand',
  templateUrl: './builder-leveled-competency-strand.component.html',
  styleUrls: ['./builder-leveled-competency-strand.component.scss'],
})
export class BuilderLeveledCompetencyStrandComponent implements OnInit {
  @Input() strand: CompetencyBuilderIndicatorGroupDTO;

  @Input() level1name: string;

  @Input() level2name: string;

  @Input() level3name: string;

  @Input() level4name: string;

  @Output() readonly deleteStrandEvent = new EventEmitter<number>();

  @Output() readonly badgeGraphicUploadedEvent = new EventEmitter();

  public isCollapsed = false;

  deleteStrandLoading = false;

  constructor(private topicBuilderService: TopicBuilderService) {}

  ngOnInit(): void {
    if (this.strand) {
      this.strand.strandBadges.sort((a, b) => a.level - b.level);
    }
    this.topicBuilderService.deleteStrandCompleted.subscribe(() => {
      this.deleteStrandLoading = false;
    });
  }

  updateTitle() {
    if (this.strand) {
      this.topicBuilderService
        .updateIndicatorGroup(this.strand.id, { title: this.strand.title })
        .subscribe();
    }
  }
}
