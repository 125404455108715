import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Subject, take } from 'rxjs';
import { CohortDTO } from 'src/app/common/dtos/cohort.dto';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { UserDTO, UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { UserDtoApiResponse } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  SortEvent,
  SortableHeader,
} from '../../directives/sortable-header.directive';
import { CohortService } from '../../services/cohort/cohort.service';
import { UserSearchService } from '../../services/user-search/user-search.service';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-cohort-users',
  templateUrl: './cohort-users.component.html',
  styleUrls: ['./cohort-users.component.scss'],
  providers: [UserSearchService],
})
export class CohortUsersComponent implements OnInit {
  @Input() cohort: CohortDTO | null;

  @Input() district: DistrictDTO;

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('deleteCohortModal') deleteCohortModal: ModalComponent;

  @Output() readonly deleteCohortEvent = new EventEmitter();

  searchTermUpdate = new Subject<string>();

  isLoading = true;

  loadingIcons = true;

  districtCode: string;

  userList: UserDTO[];

  usersToRemove: number[] = [];

  usersToAdd: number[] = [];

  userCount = 0;

  searchTerm = '';

  numPerPage = 20;

  listMeta: PaginationDTO;

  response: UserDtoApiResponse;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    name: 'gray',
    email: 'gray',
    school: 'gray',
    cohort: 'gray',
  };

  sortBy = '';

  sortByDesc = false;

  column = '';

  direction = '';

  loading = false;

  constructor(
    private apiService: APICoreService,
    private cohortService: CohortService
  ) {}

  ngOnInit(): void {
    if (this.district && this.cohort) {
      this.getUsers('', 1, this.cohort.tag);
      if (this.cohort.userCount) {
        this.userCount = this.cohort.userCount;
      }
    }
  }

  getUsers(term = '', currentPage = 1, userCohortTag = '') {
    const districtIds: number[] = [];
    if (this.district) {
      districtIds.push(this.district.id);
    }

    const params = {
      search: term,
      district_ids: districtIds,
      user_cohort_tag: userCohortTag,
      per_page: this.numPerPage,
      page: currentPage,
      sort_by: this.sortBy,
      sort_by_desc: this.sortByDesc,
    };

    this.apiService.getRequest('users', params).subscribe((results) => {
      take(1);
      this.userList = results.items.map((item: UserLiteDTO) => ({
        ...item,
        isSelected: false,
        grades: [
          {
            id: 1,
            title: '1st',
          },
          {
            id: 2,
            title: '2nd',
          },
        ],
      }));
      this.listMeta = results._meta;

      this.isLoading = false;
      setTimeout(() => {
        this.updateColumns();
      }, 350);
    });
  }

  updateSelectedUsers() {
    this.usersToRemove = [];
    this.userList.forEach((user) => {
      if (user.isSelected) {
        this.usersToRemove.push(user.id);
      }
    });
  }

  removeUsersFromCohort() {
    if (this.userList && this.cohort) {
      if (this.usersToRemove.length) {
        this.cohortService
          .removeUsersFromCohort(this.cohort.tag, this.usersToRemove)
          .subscribe(() => {
            this.getUsers('', 1, this.cohort?.tag);
            this.userCount -= 1;
          });
      }
    }
  }

  addUserToCohort(user: UserLiteDTO | null) {
    if (user && this.cohort) {
      this.usersToAdd.push(user.id);
      if (this.usersToAdd.length) {
        this.cohortService
          .addUsersToCohort(this.cohort?.tag, this.usersToAdd)
          .subscribe(() => {
            this.getUsers('', 1, this.cohort?.tag);
            this.userCount += 1;
          });
      }
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.sortBy = column;
    this.sortByDesc = false;
    if (direction === 'asc') {
      this.sortByDesc = true;
    }

    this.column = column;
    this.direction = direction;

    this.loadingIcons = true;
    this.getUsers('', 1, this.cohort?.tag);
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (this.direction === '' || this.column === '') {
      this.sortIconTemp[this.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  paginationChange() {
    this.getUsers('', this.listMeta.currentPage, this.cohort?.tag);
  }
}
