<app-modal
  #createSessionModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'New Session',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <label class="form-label mt-0" for="coachlog-type">Session Type</label>
    <app-log-type-select
      #logTypeSelect
      id="coachlog-type"
      [forLogCreation]="true"
      [clearAfterSelection]="false"
      [defaultLogTypes]="
        coachlogItemsPayload.logType ? [coachlogItemsPayload.logType] : null
      "
      [fieldInvalid]="formSubmitted && !coachlogItemsPayload.logType"
      (selectedLogType)="updateSessionLogType($event)" />
  </div>
  <ng-container
    *ngIf="
      coachlogItemsPayload.logType && !coachlogItemsPayload.logType.isSmart
    ">
    <div>
      <label class="form-label" for="coachlog-title">Title</label>
      <input
        type="text"
        class="form-control"
        id="coachlog-title"
        aria-label="Session Title"
        placeholder="Session Title"
        [(ngModel)]="coachlogItemsPayload.title"
        [style.border]="
          formSubmitted && !coachlogItemsPayload.title ? '1px solid red' : ''
        "
        [style.display]="'inline-block'" />
    </div>
    <div>
      <label class="form-label" for="session-datetime">Date & Time</label>
      <app-datepicker
        id="session-datetime"
        (validTime)="isTimeValid = $event"
        (dateSelectedUnixTimestamp)="updateDateTime($event)"
        [initialDateUnixTimestamp]="currentDateTime"
        [defaultStartTime]="startTime ? startTime : '9:00'"
        [defaultEndTime]="endTime ? endTime : '9:30'"
        [timepicker]="true" />
    </div>

    <div class="container px-0 mt-1">
      <div *ngIf="maxAttendeesReached" class="row mt-3 mb-2">
        <div class="col-12 text-center fw-800">
          Maximum number of attendees reached
        </div>
      </div>
      <div class="row">
        <div class="col-3 form-label mt-1">Coachee(s):</div>
        <div class="col py-0 mt-0">
          <ng-container *ngFor="let attendee of attendeeUserProfiles">
            <app-new-coaching-attendee
              [user]="attendee"
              (removedAttendee)="deleteAttendee(attendee)" />
          </ng-container>
          <ng-container
            *ngIf="
              coachlogItemsPayload.logType.title !==
                'Observation and Feedback' ||
              (coachlogItemsPayload.logType.title ===
                'Observation and Feedback' &&
                attendeeUserProfiles &&
                attendeeUserProfiles.length < 1)
            ">
            <ng-container *ngIf="!maxAttendeesReached">
              <div style="margin-top: 0.5rem">Add coachee</div>
              <app-user-select
                [districtId]="districtId"
                [includeAllB2bDistricts]="includeAllB2bDistricts"
                [clearAfterSelection]="true"
                (selectedUser)="addSessionCoachee($event)" />
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-3 form-label mt-1">Shadower(s):</div>
        <div class="col">
          <ng-container *ngFor="let shadower of coachlogItemsPayload.shadowers">
            <app-new-coaching-shadower
              [user]="shadower"
              (removedShadower)="deleteShadower(shadower)" />
          </ng-container>
          <ng-container *ngIf="!maxAttendeesReached && !maxShadowersReached">
            <div class="fs-6" style="margin-top: 0.5rem">Add shadower</div>
            <app-user-select
              [clearAfterSelection]="true"
              [includeE2l]="true"
              (selectedUser)="addSessionShadower($event)" />
          </ng-container>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-12">
          <div class="form-check">
            <input
              class="form-check-input"
              (change)="onCheckboxChange($event)"
              [(ngModel)]="isPrivate"
              [ngModelOptions]="{ standalone: true }"
              type="checkbox"
              id="is-private" />
            <label class="form-check-label" for="is-private">
              <div class="fw-600">Private Session</div>
              <div class="form-text">
                Private sessions can only be viewed by the coach, coachee(s),
                and shadower of the session.
              </div></label
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      coachlogItemsPayload.logType && coachlogItemsPayload.logType.isSmart
    ">
    <div>
      <label class="form-label" for="session-datetime">Date & Time</label>
      <app-datepicker
        id="session-datetime"
        (dateSelectedUnixTimestamp)="updateDateTime($event)"
        [initialDateUnixTimestamp]="currentDateTime"
        [timepicker]="false" />
    </div>
    <div class="row py-3">
      <div class="col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            (change)="onCheckboxChange($event)"
            [(ngModel)]="isPrivate"
            [ngModelOptions]="{ standalone: true }"
            type="checkbox"
            id="is-private" />
          <label class="form-check-label" for="is-private">
            <div class="fw-600">Private Session</div>
            <div class="form-text">
              Private sessions can only be viewed by the coachee
            </div></label
          >
        </div>
      </div>
    </div>
  </ng-container>
  <div footerContent>
    <ng-container *ngIf="serverError.length > 0">
      <ngb-alert [dismissible]="false" [type]="'danger'">
        <strong>Error</strong> {{ serverError }}
      </ngb-alert>
    </ng-container>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="closeModal()">Cancel</button>
      <button
        [disabled]="isLoading || !isTimeValid"
        type="button"
        class="btn btn-md btn-primary"
        (click)="createCoachingLog()">
        <ng-container *ngIf="isLoading">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        Schedule
      </button>
    </div>
  </div>
</app-modal>
