import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StatusType } from '../../../types/responses/plan.responses';

@Component({
  selector: 'app-plans-status-select',
  templateUrl: './plans-status-select.component.html',
  styleUrl: './plans-status-select.component.scss',
})
export class PlansStatusSelectComponent {
  @Input() status: string;

  @Input() viewOnly = false;

  @Output() readonly selectedStatus: EventEmitter<StatusType> =
    new EventEmitter<StatusType>();

  StatusType = StatusType;

  statusTypes = [
    StatusType.NOT_STARTED,
    StatusType.ON_TRACK,
    StatusType.COMPLETE,
    StatusType.AT_RISK,
    StatusType.OFF_TRACK,
    StatusType.CANCELED,
  ];

  statusSelected(status: StatusType) {
    this.selectedStatus.emit(status);
  }
}
