import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/common/services/auth/auth.service';

@Component({
  selector: 'app-clever-login',
  templateUrl: './clever-login.component.html',
  styleUrls: ['./clever-login.component.scss'],
})
export class CleverLoginComponent implements OnInit {
  private code: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      if (this.code) {
        this.authService.cleverLogin(this.code).subscribe({
          error: (err) => {
            if (err.error.message) {
              window.localStorage.setItem('loginError', err.error.message);
            }
            this.router.navigate(['/login']);
          },
        });
      }
    });
  }
}
