<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Session Time Out',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    closeOnBgClick: false,
  }">
  <div class="container text-center">
    <div class="row">
      <div class="col">
        <img
          src="/assets/clock.svg"
          class="session-clock mt-1 mb-4"
          alt="clock"
          role="img" />
        <div class="fs-14 fw-800">Are you still there?</div>
        <div class="my-3">
          For security reasons, your session will time out in
        </div>
      </div>
      <div class="fw-800 fs-2 mb-4">{{ timeRemaining }} seconds</div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button (click)="logout()" class="btn">Log Out</button>
      <button (click)="close()" class="btn btn-primary">
        Yes, I'm still here
      </button>
    </div>
  </div>
</app-modal>
