import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CoachingSessionTypeDTO } from '../../dtos/coaching-session.dto';
import { coachingSessionTypeDTOFromAPIResponse } from '../../helpers/translators/coaching-session-dto.translator';
import { CoachingSessionTypeAPIResponse } from '../../types/responses/coaching-log.responses';

@Injectable({ providedIn: 'root' })
export class LogTypeSearchService {
  logTypes: {
    [id: number]: CoachingSessionTypeDTO[];
  } = {};

  constructor(private apiService: APICoreService) {}

  getLogType(logId: number): Observable<CoachingSessionTypeDTO> {
    return this.apiService
      .getRequest(`egrowe/coachlogs/types/${logId}`)
      .pipe(
        map((response) => coachingSessionTypeDTOFromAPIResponse(response.item))
      );
  }

  getLogTypes(districtId: number): Observable<CoachingSessionTypeDTO[]> {
    if (!this.logTypes[districtId]) {
      return this.apiService
        .getRequest(
          `egrowe/coachlogs/types?per_page=100&district_id=${districtId}`
        )
        .pipe(
          map((response) => {
            this.logTypes[districtId] = response.items.map(
              (each: CoachingSessionTypeAPIResponse) =>
                coachingSessionTypeDTOFromAPIResponse(each)
            );
            return this.logTypes[districtId];
          })
        );
    }
    return of(this.logTypes[districtId]);
  }
}
