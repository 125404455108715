import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoachingLogEvidenceService {
  public evidenceId: BehaviorSubject<number> = new BehaviorSubject(0);

  setEvidenceId(id = 0) {
    this.evidenceId.next(id);
  }
}
