import {
  EChartsOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import merge from 'src/app/common/utilities/deep-merge';
import { ChartType } from '../../../enums/chart-type.enum';
import { CURRENT_SCHOOL_YEAR } from '../../../services/school-year/school-year.utilities';

/**
 *
 * @param type
 * @param options
 * @returns {object}
 * returns eCharts chartOptions object
 *
 * Standardizes eChartOptions for title, tooltip, legend, and x and y-axis
 * properties
 *
 * These standards are merged with the passed in options, augmenting the
 * original object.
 */
export const chartOptionsHelper = (type: ChartType, options: EChartsOption) => {
  const barChartDefaults: EChartsOption = {
    title: {
      textStyle: {
        fontWeight: 'bolder',
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    legend: {},
    // toolbox: {
    //   // Various predefined bells 'n whistles that can be added to the chart. See https://echarts.apache.org/en/option.html#toolbox
    //   show: true,
    //   feature: {
    //     // saveAsImage: {},
    //     // dataView: { readOnly: true }, // Toggle between graph and table view
    //     magicType: { type: ['line', 'bar'] }, // Toggle between line and bar graph
    //   },
    // },
    grid: {
      containLabel: true,
    },
    yAxis: {
      nameLocation: 'middle',
      axisLine: {
        onZero: false,
      },
      type: 'value',
      axisLabel: { margin: 0 },
    },
    xAxis: {
      nameLocation: 'middle',
      type: 'category',
    },
  };

  switch (type) {
    case ChartType.HorizontalBar:
    case ChartType.HorizontalStackedBar:
      /* eslint-disable-next-line */
      const optionsMeld = merge(barChartDefaults, options);
      /* eslint-disable-next-line */
      const returnOptions: EChartsOption = {
        ...optionsMeld,
        xAxis: optionsMeld['yAxis'] as XAXisComponentOption,
        yAxis: optionsMeld['xAxis'] as YAXisComponentOption,
      };
      return returnOptions;
    case ChartType.Bar:
    default:
      return merge(barChartDefaults, options);
  }
};

export interface ChartGrid {
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
  containLabel?: boolean;
  width?: string | number;
  height?: string | number;
}

/**
 *
 * @param options
 * @param modifiedOptions
 * @param initialFilter
 * @param drilldownId
 * @returns {ReportRequestParams}
 *
 * Adds the next filter object for drilldowns.
 */
export const addNecessaryFilters = (
  options: ReportRequestParams,
  modifiedOptions: ReportRequestParams,
  initialFilter: string,
  drilldownId: string
): ReportRequestParams => {
  if (options.dimension && options.dimension === 'school_name') {
    if (!initialFilter) {
      modifiedOptions.filters.push({
        codename: 'school',
        value: [drilldownId],
        title: 'School',
        displayName: 'School',
      });
    }
  }

  if (options.dimension && options.dimension === 'standard_name') {
    if (!initialFilter) {
      modifiedOptions.filters.push({
        codename: 'competency',
        value: [drilldownId],
      });
    }
  }

  if (options.dimension && options.dimension === 'user_name') {
    if (!initialFilter) {
      modifiedOptions.filters.push({
        codename: 'user',
        value: [drilldownId],
      });
    }
  }
  return modifiedOptions;
};

/**
 * Supports default drilldown - pass in the current dimension, get back what the next drilldown would be
 * Mostly used for multi-level drilldowns (since we can only set one level in the controller)
 * @param type current drilldown
 * @returns The next drilldown in the sequence
 */
export const drilldownDefinition = (type: string): string => {
  switch (type) {
    case 'month_of_school_year':
      return 'session_date';
    case 'school_name':
      return 'user_name';
    default:
      return '';
  }
};

/**
 *
 * @param initialFilter the filter we started with
 * @param chartTitle current chart title
 * @param type drilldown we're adding the filter for
 * @param options current report request options
 * @param drilldownId Id values for the drilldown filter
 * @param drilldownLabel New chart title
 * @returns [modified report parameters, new chartTitle, nextDrilldown if any]
 * Returns augmented ReportRequestParams
 *
 * Adds a label and filter to the drilldown settings to be used later
 */
export const chartDrilldownHelper = (
  initialFilter: string,
  type: string,
  options: ReportRequestParams,
  drilldownId: string,
  drilldownLabel: string
): [ReportRequestParams, string] => {
  let modifiedOptions: ReportRequestParams = deepCopy(options);
  let chartTitle = '';
  if (initialFilter) {
    modifiedOptions.filters.push({
      codename: initialFilter,
      value: [drilldownId],
    });
  }
  // Session date can be added to necessary filter and swith case refactored
  /* eslint-disable-next-line default-case */
  switch (type) {
    case 'session_date':
      chartTitle = `${drilldownLabel}`;
      if (!initialFilter) {
        modifiedOptions.filters.push({
          codename: 'month_of_school_year',
          value: [drilldownId],
        });
      }
      modifiedOptions.dimension = 'session_date';
      break;
    case 'user_name':
      chartTitle = `${drilldownLabel}`;
      // Will add user ID filter to all user_names
      modifiedOptions = addNecessaryFilters(
        options,
        modifiedOptions,
        initialFilter,
        drilldownId
      );
      modifiedOptions.dimension = 'user_name';
      break;
    case 'teacher_name':
      chartTitle = `${drilldownLabel}`;
      modifiedOptions.dimension = 'teacher_name';
      modifiedOptions = addNecessaryFilters(
        options,
        modifiedOptions,
        initialFilter,
        drilldownId
      );
      break;
    case 'school_name':
      chartTitle = `${drilldownLabel}`;
      modifiedOptions.dimension = 'school_name';
      modifiedOptions = addNecessaryFilters(
        options,
        modifiedOptions,
        initialFilter,
        drilldownId
      );
      break;
    case 'standard_name':
      chartTitle = `${drilldownLabel}`;
      modifiedOptions.dimension = 'standard_name';
      modifiedOptions = addNecessaryFilters(
        options,
        modifiedOptions,
        initialFilter,
        drilldownId
      );
      break;
    default:
      chartTitle = `${drilldownLabel}`;
      modifiedOptions.dimension = type;
      break;
  }
  return [{ ...modifiedOptions, filters: modifiedOptions.filters }, chartTitle];
};

/**
 *
 * @param params
 * @param user
 * @returns
 * returns ReportRequestParams or undefined
 *
 * Makes sure the users 'district' or 'school_year filter
 * is set.
 */
export const checkDefaultChartFilters = (
  params: ReportRequestParams,
  user: User
) => {
  if (typeof params === 'undefined') {
    return params;
  }

  const districtFilter = params.filters.find(
    (filter: KpiReportFilter) => filter.codename === 'district'
  );
  if (!districtFilter) {
    params.filters.push({
      codename: 'district',
      title: 'District',
      value: [(user.district as DistrictSimpleDto).id.toString()],
      displayName: 'District',
    });
  }

  const schoolYearFilter = params.filters.find(
    (filter: KpiReportFilter) => filter.codename === 'school_year'
  );
  if (!schoolYearFilter) {
    params.filters.push({
      codename: 'school_year',
      title: 'School Year',
      value: [CURRENT_SCHOOL_YEAR.id.toString()],
      displayName: 'School Year',
    });
  }

  if (!EnvironmentService.isProduction()) {
    params.debug = true;
  }

  return params;
};

/**
 *
 * @param dimensions
 * @returns {EChartsOption}
 * returns EchartOptions for an empyt chart
 */
export const getBlankChartOptions = (dimensions: ChartGrid): EChartsOption => ({
  title: {
    show: true,
    textStyle: {
      color: 'grey',
      fontSize: 20,
      fontWeight: 'lighter',
    },
    left: 'center',
    top: 'middle',
    text: 'No Data',
  },
  grid: dimensions,
  xAxis: {
    show: false,
  },
  yAxis: {
    show: false,
  },
  series: [],
});
