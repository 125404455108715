<div class="field">
  <label class="form-label" [for]="'type' + id">Choice Type</label>
  <ng-select
    [labelForId]="'type' + id"
    [items]="multipleChoiceOptions"
    [multiple]="false"
    [closeOnSelect]="true"
    [clearable]="false"
    [searchable]="false"
    [disabled]="!canChange"
    [ngModel]="data.type"
    (change)="typeChange($event)"
    bindLabel="label"
    bindValue="value"
    class="growelab"
    placeholder="Select a type"></ng-select>
  <app-form-edit-field-label
    #label
    [(data)]="data.label"></app-form-edit-field-label>
  <app-form-edit-field-options
    #options
    [(data)]="data.choices"></app-form-edit-field-options>
</div>
