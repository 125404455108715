import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import {
  CoachingSessionTypeDTO,
  NewCoachingSessionItemsPayloadDTO,
  NewCoachingSessionUserDto,
} from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { FEATURE_FLAGS } from '../../services/unleash/unleash.helpers';
import { UnleashService } from '../../services/unleash/unleash.service';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../datepicker/datepicker.component';
import { LogTypeSelectComponent } from '../log-type-select/log-type-select.component';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-create-session-modal',
  templateUrl: './create-session-modal.component.html',
  styleUrls: ['./create-session-modal.component.scss'],
})
export class CreateSessionModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('datePicker') datePicker: DatepickerComponent;

  @ViewChild('createSessionModal')
  createSessionModal: ModalComponent;

  @ViewChild('logTypeSelect') logTypeSelect: LogTypeSelectComponent;

  @Input() attendeeUserProfiles: NewCoachingSessionUserDto[] | null;

  @Input() retainAttendees = false;

  @Input() selectedStartDate: number | null;

  @Input() selectedStartTime: string | null;

  @Input() selectedEndTime: string | null;

  maxAttendeesReached = false;

  maxShadowersReached = false;

  startTime: string;

  endTime: string;

  isTimeValid = true;

  isPrivate? = false;

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO = {
    title: '',
    logType: null,
    startDatetime: 0,
    endDatetime: 0,
    timezone: TimezoneLinkBack.Central,
    coachUser: null,
    creatorUserId: 0,
    coachees: [],
    shadowers: [],
    is_private: false,
  };

  user: User | null = null;

  formSubmitted = false;

  formValid = true;

  currentDateTime = new Date().getTime() / 1000;

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  subscriptions: Subscription[] = [];

  isLoading = false;

  serverError = '';

  mscEnabled = false;

  districtId: number | null = null;

  includeAllB2bDistricts = false;

  constructor(
    private coachingLogService: CoachingLogService,
    private store: Store,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
  }

  ngOnInit(): void {
    if (!this.attendeeUserProfiles) {
      this.attendeeUserProfiles = [];
    }

    // if user is not an e2L employee, filter user select options to only users in their district
    if (this.user) {
      if (
        !this.user.roles.includes(UserRole.E2L_EMPLOYEE) &&
        this.user.district
      ) {
        this.districtId = this.user.district.id;
        if (this.user.district.isB2b === 1) {
          this.includeAllB2bDistricts = true;
        }
      }

      this.isPrivate = this.user.district?.defaultPrivateLogs;

      this.initializeNewCoachlogPayload();
      if (this.attendeeUserProfiles) {
        this.coachlogItemsPayload.coachees = this.attendeeUserProfiles;
      }
    }

    this.subscriptions.push(
      this.selectedTimezone.subscribe((timezone) => {
        this.coachlogItemsPayload.timezone = timezone;
      })
    );

    this.subscriptions.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.closeModal();
      })
    );

    this.subscriptions.push(
      this.coachingLogService.coachlogError.subscribe((error) => {
        this.isLoading = false;
        this.serverError = error;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((keyName) => {
      switch (keyName) {
        case 'attendeeUserProfiles':
          this.coachlogItemsPayload.coachees = this.attendeeUserProfiles;
          break;

        case 'selectedStartDate':
          if (this.selectedStartDate) {
            this.coachlogItemsPayload.startDatetime = this.selectedStartDate;
            this.currentDateTime = this.selectedStartDate;
          }
          break;

        case 'selectedStartTime':
          if (this.selectedStartTime) {
            this.startTime = this.selectedStartTime;
          }
          break;

        case 'selectedEndTime':
          if (this.selectedEndTime) {
            this.endTime = this.selectedEndTime;
          }
          break;

        default:
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  initializeNewCoachlogPayload() {
    let userId = 0;
    if (this.user) {
      userId = this.user.id;
    }

    this.coachlogItemsPayload = {
      title: '',
      logType: null,
      startDatetime: 0,
      endDatetime: 0,
      coachUser: null,
      timezone: TimezoneLinkBack.Central,
      creatorUserId: userId,
      coachees: [],
      shadowers: [],
      is_private: false,
    };
    this.coachlogItemsPayload.coachUser = this.user;
  }

  clearOutNewCoachlogPayload() {
    this.coachlogItemsPayload.title = '';
    this.coachlogItemsPayload.logType = null;
    this.coachlogItemsPayload.is_private = false;
    if (!this.retainAttendees) {
      this.attendeeUserProfiles = [];
      this.coachlogItemsPayload.coachees = this.attendeeUserProfiles;
    }
    this.coachlogItemsPayload.shadowers = [];
  }

  updateDateTime(times: DatepickerOutput[]) {
    this.coachlogItemsPayload.startDatetime = times[0].time / 1000;
    if (times.length > 1) {
      this.coachlogItemsPayload.endDatetime = times[1].time / 1000;
    }
    this.coachlogItemsPayload.timezone = times[0].timezone;
  }

  closeModal() {
    this.clearOutNewCoachlogPayload();
    this.formSubmitted = false;
    this.isLoading = false;
    this.serverError = '';
    this.logTypeSelect?.reset();
    this.currentDateTime = new Date().getTime() / 1000;
    this.selectedStartTime = null;
    this.selectedEndTime = null;
    this.selectedStartDate = null;
    this.maxAttendeesReached = false;
    this.maxShadowersReached = false;
    this.isPrivate = this.user?.district?.defaultPrivateLogs
      ? this.user?.district?.defaultPrivateLogs
      : false;
    if (this.createSessionModal) {
      // Custom modal control is breaking this, so check if it's still instantiated before closing
      // There is an additional check inside modal component in case modal is never opened.
      this.createSessionModal.close();
    }
  }

  openModal() {
    this.createSessionModal.open();
  }

  updateSessionLogType(logType: CoachingSessionTypeDTO | null) {
    this.coachlogItemsPayload.logType = logType;
  }

  addSessionCoachee(coachee: UserLiteDTO | null) {
    if (coachee) {
      const user: NewCoachingSessionUserDto = {
        id: coachee.id,
        profile: coachee.profile,
      };
      if (!this.alreadyAdded(user)) {
        this.coachlogItemsPayload.coachees?.push(user);
        this.checkAttendeeCount();
      }
    }
  }

  addSessionShadower(shadower: UserLiteDTO | null) {
    if (shadower) {
      const user: NewCoachingSessionUserDto = {
        id: shadower.id,
        profile: shadower.profile,
      };
      if (!this.alreadyAdded(user)) {
        this.coachlogItemsPayload.shadowers?.push(user);
        this.checkAttendeeCount();
      }
    }
  }

  checkAttendeeCount() {
    const coachees: number = this.coachlogItemsPayload?.coachees
      ? this.coachlogItemsPayload?.coachees.length
      : 0;

    const shadowers: number = this.coachlogItemsPayload.shadowers
      ? this.coachlogItemsPayload.shadowers.length
      : 0;

    this.maxAttendeesReached = coachees + shadowers > 8;

    this.maxShadowersReached = shadowers > 7;
  }

  alreadyAdded(user: NewCoachingSessionUserDto) {
    return (
      this.attendeeUserProfiles?.filter((e) => user.id === e.id).length ||
      this.coachlogItemsPayload.coachees?.filter((e) => user.id === e.id)
        .length ||
      this.coachlogItemsPayload.shadowers?.filter((e) => user.id === e.id)
        .length
    );
  }

  validateForm() {
    this.formValid = true;
    if (!this.coachlogItemsPayload.logType) {
      this.formValid = false;
    }
    if (
      !this.coachlogItemsPayload.logType?.isSmart &&
      !this.coachlogItemsPayload.title
    ) {
      this.formValid = false;
    }
  }

  createCoachingLog() {
    this.formSubmitted = true;
    this.validateForm();
    if (this.formValid) {
      this.isLoading = true;
      if (this.mscEnabled) {
        if (this.coachlogItemsPayload.logType?.isSmart === true) {
          if (this.user) {
            const user: NewCoachingSessionUserDto = {
              id: this.user.id,
              profile: this.user.profile,
            };
            if (!this.alreadyAdded(user)) {
              this.coachlogItemsPayload.coachees?.push(user);
            }
          }
          const newPayload = deepCopy(this.coachlogItemsPayload);
          if (this.isPrivate) newPayload.is_private = true;
          this.coachingLogService.createSmartlog(newPayload);
        } else {
          if (this.isPrivate)
            this.coachlogItemsPayload.is_private = this.isPrivate;
          this.coachingLogService.createCoachinglog(this.coachlogItemsPayload);
        }
      } else {
        if (this.isPrivate)
          this.coachlogItemsPayload.is_private = this.isPrivate;
        this.coachingLogService.createCoachinglog(this.coachlogItemsPayload);
      }
      return true;
    }
    return false;
  }

  deleteAttendee(user: NewCoachingSessionUserDto) {
    if (this.coachlogItemsPayload.coachees) {
      this.coachlogItemsPayload.coachees =
        this.coachlogItemsPayload.coachees?.filter(
          (coachee: NewCoachingSessionUserDto) => user.id !== coachee.id
        );
      this.attendeeUserProfiles = this.coachlogItemsPayload.coachees;
      this.checkAttendeeCount();
    }
  }

  deleteShadower(user: NewCoachingSessionUserDto) {
    if (this.coachlogItemsPayload.shadowers) {
      this.coachlogItemsPayload.shadowers =
        this.coachlogItemsPayload.shadowers?.filter(
          (shadower: NewCoachingSessionUserDto) => shadower !== user
        );
      this.checkAttendeeCount();
    }
  }

  onCheckboxChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    if (target) {
      this.coachlogItemsPayload.is_private = target.checked;
    }
  }
}
