import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-is-reviewed-select',
  templateUrl: './is-reviewed-select.component.html',
  styleUrls: ['./is-reviewed-select.component.scss'],
})
export class IsReviewedSelectComponent implements OnInit {
  @Output() readonly emittedOption = new EventEmitter<
    'Reviewed' | 'Not Reviewed' | null
  >();

  @Input() defaultOption: boolean | null;

  selectedIsReviewed: 'Reviewed' | 'Not Reviewed' | null;

  labelId = uuidv4();

  ngOnInit(): void {
    if (this.defaultOption !== null && this.defaultOption !== undefined) {
      switch (this.defaultOption) {
        case true:
          this.selectedIsReviewed = 'Reviewed';
          break;
        case false:
          this.selectedIsReviewed = 'Not Reviewed';
          break;
        default:
          this.selectedIsReviewed = null;
          break;
      }
    }
  }
}
