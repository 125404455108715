import { GLEnvironment, GrowelabEnv } from 'src/app/common/types/env';
import { environment as defaultEnv } from './environment.default';

export const environment: GrowelabEnv = {
  ...defaultEnv,
  environment: GLEnvironment.PRODUCTION,
  production: true,
  apiUrl: 'https://api.engage2learn.org',
  pusher: {
    key: '93c330587b3cc316c630',
    cluster: 'us2',
  },
  googleAnalyticsID: 'G-1KG6HW8SPK',
  googleOAuthClientId:
    '655769197706-e0sottrdhhe70afclmlele29lv7op1ju.apps.googleusercontent.com',
  SENTRY_ORG: 'engage2learn',
  SENTRY_PROJECT: 'growelab-web',
  SENTRY_KEY:
    '519b763d3b944feb82e5349b62abae25d6c7b66c6c8e41db87275eaa884d75dc',
  cleverClientId: 'bbb0654f1a928e5baa80',
  cleverRedirectURI: 'https://www.growelab.app/clever-auth',
  unleash: {
    url: 'https://us.app.unleash-hosted.com/usgg0020/api/frontend',
    clientKey:
      'default:production.976f79c3842e3ae625991c773c93ac887217ee020c619cb5cb611375',
  },
  getstream_appid: '1292588',
  getstream_key: 'dhjvudk4c63t',
};
