<ng-container *ngIf="competency">
  <div class="row justify-content-center mt-3">
    <div class="col-lg-8">
      <h2 class="text-center competency-title">
        {{ competency.standard.title }}
      </h2>
      <div class="text-center">
        <app-safehtml [html]="competency.standard.content" />
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <ng-container *ngFor="let group of competency.indicatorSet.indicatorGroups">
    <app-preview-leveled-competency-strand
      [strand]="group"
      [level1name]="competency.indicatorSet.level1_name"
      [level2name]="competency.indicatorSet.level2_name"
      [level3name]="competency.indicatorSet.level3_name"
      [level4name]="
        competency.indicatorSet.level4_name
      "></app-preview-leveled-competency-strand>
  </ng-container>
</ng-container>
