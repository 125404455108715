export enum ResourceType {
  RESOURCE = 'resource',
  COURSE = 'course',
  VIDEO = 'video',
}

export const getResourceIcon = (type: ResourceType): string => {
  switch (type) {
    case ResourceType.RESOURCE:
      return 'bi-tools';
    case ResourceType.COURSE:
      return 'bi-mortarboard-fill';
    case ResourceType.VIDEO:
      return 'bi-play-btn';
    default:
      return '';
  }
};
