<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <ng-select
    [labelForId]="labelId"
    (change)="schoolSelected($event); select.blur()"
    [items]="schoolList"
    bindLabel="title"
    [loading]="searchLoading"
    [searchable]="!simpleSelect"
    [(ngModel)]="internalSelectedSchool"
    [placeholder]="placeholder"
    #select>
  </ng-select>
  <ng-container
    *ngIf="internalSelectedSchoolList && internalSelectedSchoolList.length">
    <div>
      <ng-container *ngFor="let eachSchool of internalSelectedSchoolList">
        <span
          class="badge badge-item mt-2"
          (click)="removeSchoolFromList(eachSchool)"
          >{{ eachSchool.title }}<i class="bi bi-x mx-2"></i
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
