<ng-container>
  <h3 class="competencies-title">Select Competencies</h3>
  <div *ngIf="topics && topics.length > 1" class="topics-wrapper my-3">
    <app-topic-select
      [label]="'Topic'"
      [topics]="topics"
      (selectedTopic)="filterTopic($event)"></app-topic-select>
  </div>
  <div class="container">
    <div class="row">
      <ng-container *ngIf="!listIsLoading; else loadingTemplate">
        <ng-container
          *ngIf="filteredCompetencyList.length > 0; else noCompetencies">
          <div
            *ngFor="let competency of filteredCompetencyList"
            class="col-12 col-sm-6 col-md-4 col-xl-3 mt-4">
            <app-select-competency
              (competencyEvent)="handleCompetencyEvent($event)"
              [isSelected]="competency.selected"
              [isSelectable]="canEdit"
              [logId]="logId"
              [competency]="competency"
              [topicTitle]="competency.rubricTitle"></app-select-competency>
          </div>
        </ng-container>
        <ng-template #noCompetencies>
          <div
            class="d-flex flex-column align-items-center justify-content-center w-100"
            style="min-height: 400px">
            <h2>No Competencies Found</h2>
            <p>No competencies are enabled for your district.</p>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader></app-loader>
      </ng-template>
    </div>
  </div>
</ng-container>
