import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldWithChoices } from 'src/app/private/shared/dtos/forms.dto';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-form-view-checkbox',
  templateUrl: './view-checkbox.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
})
export class FormViewCheckboxComponent implements OnInit {
  @Input() fieldDefinition: FieldWithChoices;

  @Input() inputDisabled = false;

  @Input() readOnly = false;

  @Output() readonly value = new EventEmitter<string[]>();

  id = uuidv4();

  selectionArray: string[] = [];

  @Input() initialValue: string[] | null;

  changeSelection(choiceValue: string) {
    if (!this.inputDisabled) {
      if (this.selectionArray.includes(choiceValue)) {
        this.selectionArray = this.selectionArray.filter(
          (value) => value !== choiceValue
        );
      } else {
        this.selectionArray.push(choiceValue);
      }
      this.value.emit(this.selectionArray);
    }
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.selectionArray = this.initialValue;
    }
  }
}
