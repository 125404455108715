<div class="container">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card cohort-container border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <ng-container *ngIf="cohortList && !selectedCohort">
      <div class="row mt-3 border-2">
        <div class="col-3">
          <div class="input-group">
            <input
              aria-label="search-cohorts"
              placeholder="Search Cohorts"
              [(ngModel)]="searchTerm"
              type="text"
              (keydown.enter)="searchCohorts()"
              class="form-control" />
            <button (click)="searchCohorts()" class="btn btn-secondary">
              Search
            </button>
          </div>
        </div>
        <div class="col text-end">
          <button (click)="newCohortModal.open()" class="btn btn-primary">
            <i class="bi bi-plus"></i> Create a Cohort
          </button>
        </div>
      </div>
      <hr class="border border-primary border-2" />
      <div class="row p-2">
        <ng-container *ngFor="let cohort of cohortList">
          <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-2">
            <app-cohort-preview-card
              (clickedCohort)="didSelectCohort($event)"
              [cohort]="cohort"></app-cohort-preview-card>
          </div>
        </ng-container>
      </div>
      <div *ngIf="listMeta.totalCount > 12" class="row mt-4">
        <div class="d-flex justify-content-center">
          <ngb-pagination
            [(page)]="listMeta.currentPage"
            [pageSize]="listMeta.perPage"
            [collectionSize]="listMeta.totalCount"
            (pageChange)="paginationChange()"
            [maxSize]="5"
            [ellipses]="true"></ngb-pagination>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedCohort">
      <div class="float-start">
        <button (click)="backToCohorts()" class="btn btn-link p-0 mt-2">
          <i class="bi bi-arrow-left-circle me-1"></i>Back to all Cohorts
        </button>
      </div>
      <h2 class="fw-bold align-self-center">{{ selectedCohort.tag }}</h2>
      <app-cohort-users
        (deleteCohortEvent)="deleteCohort()"
        [cohort]="selectedCohort"
        [district]="district" />
    </ng-container>

    <ng-container *ngIf="!cohortList">
      <app-loader></app-loader>
    </ng-container>
  </div>
</div>

<app-modal
  #newCohortModal
  [modalConfig]="{
    titleText: 'Create Cohort',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'custom-class',
    customFooter: true,
  }"
  (isClosing)="resetForm()"
  (isDismissing)="resetForm()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="newCohortModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <form [formGroup]="form">
    <label for="cohort-title" class="form-label">Title</label>
    <div class="input-group">
      <input
        formControlName="title"
        type="text"
        class="form-control"
        id="cohort-title"
        aria-label="Cohort Title"
        [ngClass]="{
          'is-invalid': createFormSubmitted && f['title'].errors
        }" />
    </div>
    <ng-container *ngIf="errorMessage">
      <div class="alert alert-danger text-start mt-3 mb-0" role="alert">
        {{ errorMessage }}
      </div>
    </ng-container>
  </form>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="newCohortModal.close()">
        Cancel
      </button>
      <button
        (click)="createCohort()"
        [disabled]="submitLoading"
        type="submit"
        class="btn btn-md btn-primary">
        <span
          *ngIf="submitLoading"
          class="spinner-border spinner-border-sm me-1"></span>
        Create
      </button>
    </div>
  </div>
</app-modal>
