import { DragDrop, DragRef } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { convertRemToPixels } from 'src/app/common/utilities/display-helpers';
import { select } from 'src/app/common/utilities/ngxs-utils';

import { StrandDTO } from '../../../dtos/attendee-rubric.dto';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { DisplayService } from '../../../services/display/display.service';
import { CoachingLogState } from '../../../state/coaching-log/coaching-log.state';
import { SetGoalPayload } from '../../../types/payloads/set-goal.payload';

@Component({
  selector: 'app-goal-slider',
  templateUrl: './goal-slider.component.html',
  styleUrls: ['./goal-slider.component.scss'],
})
/* eslint-disable class-methods-use-this */
export class GoalSliderComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() strandId: number;

  @Input() portfolioStrand: StrandDTO;

  @Input() competencyId: number;

  @Input() userId: number;

  @Input() coachlogId: number;

  @Input() canEdit = true;

  @ViewChild('backfill', { static: false }) backfill: ElementRef;

  @ViewChild('avatar', { static: false }) avatar: ElementRef;

  @ViewChild('track') track: ElementRef;

  strand$ = select(CoachingLogState.getStrand);

  strand: StrandDTO;

  userData: ProfileDTO;

  completedLevel = 0;

  sessionData$ = select(CoachingLogState.getSessionData);

  user: User;

  alive = true;

  goalLevel = 0;

  trackWidth: number;

  dragReference: DragRef;

  constructor(
    private store: Store,
    private coachingLogService: CoachingLogService,
    private dragDropService: DragDrop,
    private displayService: DisplayService,
    private userService: UserService
  ) {}

  ngOnInit() {
    // portfolio page doesn't pass strand id, and it will not have a coaching log state
    if (this.strandId) {
      this.sessionData$
        .pipe(
          map((filterFn) => filterFn(this.coachlogId)),
          map((sessionData) => {
            if (sessionData) {
              const attendeeData = sessionData.attendees.find(
                (attendee) => attendee.userId === this.userId
              );
              if (attendeeData) {
                this.userData = attendeeData.user.profile;
              }
            }
          })
        )
        .subscribe();
    } else if (this.portfolioStrand) {
      this.userService.fetchUserById(this.userId).subscribe(
        (res) => {
          if (res) {
            this.userData = res.profile;
          }
        },
        (error) => {
          if (error.status === 404) {
            this.user = this.store.selectSnapshot(
              (state) => state.user.user
            ) as User;
            this.userData = this.user.profile;
          }
        }
      );
    }
  }

  ngOnChanges() {
    if (this.dragReference) {
      this.setLevels();
    }
  }

  ngAfterViewInit() {
    if (this.strandId) {
      this.strand$
        .pipe(map((filterFn) => filterFn(this.userId, this.strandId)))
        .subscribe((data) => {
          this.strand = data;
          this.setLevels();
          this.setPosition();
        });
    } else if (this.portfolioStrand) {
      this.strand = this.portfolioStrand;
    }
    this.setLevels();
    this.createDrag();
    this.displayService.debouncedResizeEvent.subscribe(() => {
      this.setPosition();
    });
  }

  calculateWidth() {
    this.trackWidth = this.backfill.nativeElement.offsetWidth;
  }

  constructPayload(level: number): SetGoalPayload {
    return {
      userId: this.userId,
      competencyId: this.competencyId,
      strandId: this.strandId ?? this.strand.id,
      coachlogId: this.coachlogId ?? 0,
      goalLevel: level,
    };
  }

  createDrag(): void {
    this.dragReference = this.dragDropService.createDrag(this.avatar);

    this.dragReference.lockAxis = 'x';
    if (!this.canEdit) {
      this.dragReference.disabled = true;
    }
    this.dragReference
      .withBoundaryElement(this.track.nativeElement)
      .ended.subscribe(() => {
        this.onDrop();
      });
    // nonexistent delay allows for draw time
    setTimeout(() => this.setPosition(), 0);
  }

  updateStep(step: number): void {
    if (!this.canEdit) return;
    this.setPosition(step);
    this.setGoal(step);
  }

  setPosition(step?: number) {
    const newStep = step || this.goalLevel;
    if (this.dragReference) {
      this.dragReference.setFreeDragPosition({
        x: this.stepToTransformX(newStep),
        y: 0,
      });
      this.avatar.nativeElement.style.opacity = 1;
    }
  }

  setLevels() {
    if (this.strand) {
      this.goalLevel = this.strand.goalLevel;
      this.completedLevel = this.strand.completedLevel;
      this.track.nativeElement.setAttribute('data-goalLevel', this.goalLevel);
      this.track.nativeElement.setAttribute(
        'data-completedLevel',
        this.completedLevel
      );
    }
  }

  onDrop(): void {
    // cdkDrag just modifies the transform property, so we need to parse it
    const dragPosition =
      this.avatar.nativeElement.style.transform.match(/\((\d*)/);
    if (this.trackWidth && dragPosition && dragPosition.length > 1) {
      const pct = parseInt(dragPosition[1]) / this.trackWidth;
      const step = Math.round(pct * 4) || 1;
      this.setPosition(step);
      if (step !== this.goalLevel) {
        this.setGoal(step);
      }
    }
  }

  setGoal(level: number) {
    this.coachingLogService.setGoal(this.constructPayload(level));
  }

  stepToTransformX(step: number): number {
    this.calculateWidth();
    if (step === 0) return 10;
    if (step === 4) return this.trackWidth - 21;
    return (
      (this.trackWidth - convertRemToPixels(7.2)) * (step * 0.25) +
      convertRemToPixels((2 * step - 1) * 1.2) -
      21
    );
  }
}
