import { ResourceType } from '../../../enums/resource-type.enum';
import { ResourcePreviewDTO } from './resource-preview.dto';

export const MOCK_RESOURCE_LIST: ResourcePreviewDTO[] = [
  {
    id: 1,
    type: ResourceType.RESOURCE,
    title: 'TI-Nspire CX: How to Upload Images for Mathematical Modeling',
    description:
      'Harness the power of the TI-Nspire CX calculator to make mathematics relevant to students through applying mathematical modeling to images.',
    isOnUsersShelf: 0,
  },
  {
    id: 2,
    type: ResourceType.VIDEO,
    title:
      'Station Rotation: Differentiating Instruction to Reach All Students',
    description:
      'Thinking about implementing station rotation into your classroom? Check out this resource to watch a video on station rotation, self-assess on your current reality for implementation, and set a goal to facilitate station rotation within your classroom.',
    isOnUsersShelf: 0,
  },
  {
    id: 3,
    type: ResourceType.RESOURCE,
    title: 'STAAR Math standards/skill tracker.',
    description:
      'This is a tracking tool that is for students to track the skills or standards on staar formatted questions. It also has a learning plan for each skill.    ',
    isOnUsersShelf: 0,
  },
  {
    id: 4,
    type: ResourceType.COURSE,
    title: 'Design a Buerk Rubric',
    description:
      'A Buerk Rubric is a formative tracker for growth towards mastery of standards that builds student ownership of learning. Learn more about this powerful tool in this course!',
    isOnUsersShelf: 0,
  },
  {
    id: 5,
    type: ResourceType.VIDEO,
    title: 'Collaborating With Students on Assessments',
    description:
      'Example of how teachers can leverage student-designed assessments or even activities to support learning.',
    isOnUsersShelf: 0,
  },
];
