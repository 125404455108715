import { GLEnvironment, GrowelabEnv } from 'src/app/common/types/env';

export const environment: GrowelabEnv = {
  apiUrl: 'https://api.estudio.local:8010', // URL of API backend
  environment: GLEnvironment.LOCAL, // one of enumerated environment values
  production: false, // whether this is a production build and deploy of the app
  sessionTimeoutMinutes: 30, // the number of minutes before the user session should expire

  // vendor-specific settings below
  codoxApiKey: '16807ae8-ebb0-4b4a-84f3-14aca118134b', // Codox collaborative doc authoring API key (https://www.codox.io/)
  cleverClientId: 'b7f08b4e62eb3a698369', // Clever (https://clever.com/) client ID
  cleverRedirectURI: 'http://localhost:4200/clever-auth',
  froalaKey:
    'Ja2A4wA1C2A1G2A4C3D3oCd2ZSb1XHi1Cb2a1KIWCWMJHXCLSwG1G1B2C2B1D7E6E1F4H4==', // Froala WYSIWYG editor key (https://www.froala.com/wysiwyg-editor)
  googleAnalyticsID: '', // Google Analytics datasource ID
  googleOAuthClientId:
    '605621249953-8uj4k1t6a7046de2mhqu8bqbnhhgvule.apps.googleusercontent.com', // Google Auth Client ID
  intercomId: 'zygq10l8', // Intercom chatbot ID
  pusher: {
    key: 'a4e041dfa30f25ef9d4e',
    cluster: 'us2',
  }, // Pusher (https://pusher.com/) configuration
  unleash: {
    url: 'https://us.app.unleash-hosted.com/usgg0020/api/frontend',
    clientKey:
      'default:development.e015f4519cbc879fa7f66e9385d6df6782d126dbbf6fbfd32a04e397',
  }, // configuration for Unleash (https://www.getunleash.io/) feature flag service
  getstream_appid: '1294595',
  getstream_key: '6nf5nva4bktq',
};
