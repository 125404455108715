<div
  *ngIf="avatarData"
  [ngStyle]="{ 'background-color': avatarData.color }"
  [ngbTooltip]="
    !hideTooltip ? avatarData.firstName + ' ' + avatarData.lastName : null
  "
  #element
  class="avatar {{ avatarSize }} {{
    profileLinkActive ? 'cursor-pointer' : ''
  }}"
  [class.grayscale]="grayScale"
  (click)="navigateToPortfolio()">
  <ng-container
    *ngIf="avatarData.photoUrl; then image; else noImage"></ng-container>
  <ng-template #noImage>
    <div class="avatar-initials text-{{ initialsColor }}">{{ initials }}</div>
  </ng-template>
  <ng-template #image>
    <div
      class="avatar-image"
      [ngStyle]="{ 'background-image': 'url(' + avatarData.photoUrl + ')' }"
      alt="no image"></div>
  </ng-template>
</div>
