import { Injectable } from '@angular/core';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralLogService {
  constructor(private apiService: APICoreService) {}

  updateCoachingLog(logId: number, changes: string) {
    return this.apiService.putRequest(`egrowe/coachlogs-v2/${logId}`, {
      content: changes,
    });
  }

  // New endpoint enables update of content by coach and coachees
  updateCoachingLogContent(logId: number, changes: string) {
    return this.apiService.putRequest(
      `egrowe/coachlogs-v2/${logId}/update-content`,
      {
        content: changes,
      }
    );
  }
}
