<ng-container>
  <ng-select
    class="is-reviewed-select"
    [labelForId]="labelId"
    [placeholder]="'Search for data with review status...'"
    [items]="['Reviewed', 'Not Reviewed']"
    [searchable]="false"
    [(ngModel)]="selectedIsReviewed"
    (change)="emittedOption.emit(selectedIsReviewed); isReviewedSelect.blur()"
    #isReviewedSelect>
  </ng-select>
</ng-container>
