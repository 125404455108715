import { Injectable } from '@angular/core';
import { Observable, debounceTime, fromEvent, takeWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  alive: boolean;

  debouncedResizeEvent: Observable<Event>;

  constructor() {
    this.alive = true;
    this.debouncedResizeEvent = fromEvent(window, 'resize').pipe(
      takeWhile(() => this.alive),
      debounceTime(500)
    );
  }
}
