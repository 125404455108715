<div class="card border-0 mb-3">
  <div class="card-header pt-3">
    <h2 class="card-title float-start" (click)="triggerCollapse()">
      Recent Badges
      <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
    </h2>
    <div class="card-tools float-end">
      <a
        routerLink="/portfolio/{{ userId }}/badges"
        ngbTooltip="See all badges"
        class="text-primary">
        See All
        <i class="ps-1 bi bi-box-arrow-up-right"></i>
      </a>
    </div>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="card-body d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center">
        <ng-container
          *ngIf="
            badges && badges.length && badges.length > 0;
            else noBadgesFound
          ">
          <div class="row row-cols-auto">
            <ng-container *ngFor="let badge of badges">
              <ng-container *ngIf="badge && badge.type">
                <div class="col mb-2">
                  <app-badge-graphic [badge]="badge" [size]="'sm'" />
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noBadgesFound>
          <div class="d-flex text-body-secondary text-center pb-3">
            Great job on your coaching journey! You haven't earned any badges
            yet, but when you do you'll see your recent ones here. Keep up the
            amazing work and watch your badge collection grow!
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
