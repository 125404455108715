<div
  class="card-body bg-white dashboard-card px-0 px-sm-4"
  style="min-height: 300px">
  <ng-container *ngIf="dashboardInfo; else placeHolder">
    <div class="row">
      <div class="col-12 col-lg-5 pe-0">
        <div
          echarts
          id="plan-progress-chart"
          role="figure"
          title="Plan Status"
          class="dashboard-chart"
          [ngStyle]="{
            height: '270px'
          }"
          [options]="chartOptionsPlan"
          [loading]="false"
          (chartInit)="onChartInitPlan($event)"></div>
      </div>
      <div class="col-12 col-lg-7 ps-0">
        <div
          echarts
          id="phase-status-chart"
          role="figure"
          title="{{ dashboardInfo.phase_displayname }} Status"
          class="dashboard-chart"
          [ngStyle]="{ height: '270px' }"
          [options]="chartOptionsPhase"
          [loading]="false"
          (chartInit)="onChartInitPhase($event)"></div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #placeHolder>
  <div class="card-header pt-3">
    <div class="card-title placeholder-glow">
      <span class="placeholder col-5"></span>
    </div>
  </div>
  <div class="card-body pt-0" style="height: 18rem">
    <div class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
    </div>
    <div class="card-text placeholder-glow mt-2">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
    </div>
  </div>
</ng-template>
