import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';

export interface PlanSearchParams {
  title?: string;
  page: number;
  sort_by: string;
  sort_by_desc: string | boolean;
  expand: string;
}

export const checkIfPlanEditor = (user: User) =>
  user.roles.includes(UserRole.PLAN_EDITOR) ||
  user.roles.includes(UserRole.E2L_EMPLOYEE) ||
  user.roles.includes(UserRole.OPERATOR);
