/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    dataLayer: any[];
    gtag: any;
  }
}

export function gtag(...args: any[]): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
}
