<label class="visually-hidden" [attr.for]="">{{ label }}</label>
<ng-container *ngIf="answers && answers.length > 0; else noAnswers">
  <ng-select
    [labelForId]="labelId"
    (change)="answerSelected($event); select.blur()"
    [items]="answers"
    bindLabel="title"
    [searchable]="true"
    [placeholder]="'Select answer(s) to apply filters'"
    [(ngModel)]="model"
    [groupBy]="'question'"
    #select>
  </ng-select>
  <ng-container
    *ngIf="internalSelectedAnswerList && internalSelectedAnswerList.length">
    <div>
      <ng-container *ngFor="let eachAnswer of internalSelectedAnswerList">
        <span
          class="badge badge-item mt-2"
          (click)="removeAnswerFromList(eachAnswer)"
          >{{ eachAnswer.title }}<i class="bi bi-x mx-2"></i
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<ng-template #noAnswers>
  <p *ngIf="searchLoading" class="text-body-secondary">
    Loading form answers ...
  </p>
  <p *ngIf="!searchLoading" class="text-body-secondary">
    This form has no answers to filter by.
  </p>
</ng-template>
