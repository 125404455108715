import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { SchoolDTO, SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { ModalComponent } from 'src/app/private/shared/components/modals/modal/modal.component';
import { TopicDTO } from 'src/app/private/shared/components/topic-select/topic-select.component';
import { CoachingSessionTypeDTO } from 'src/app/private/shared/dtos/coaching-session.dto';
import { Form } from 'src/app/private/shared/dtos/forms.dto';
import { ReportPageFiltersService } from 'src/app/private/shared/services/report/report-page-filters.service';
import { schoolYearList } from 'src/app/private/shared/services/school-year/school-year.utilities';
import { DWCohort } from 'src/app/private/shared/types/responses/cohort.responses';
import {
  ObservationFormAnswerDTO,
  ReportAnswerDTO,
  ReportFiltersDTO,
} from '../report-filters.dto';

@Component({
  selector: 'app-report-filter-modal',
  templateUrl: './report-filter-modal.component.html',
  styleUrls: ['./report-filter-modal.component.scss'],
})
export class ReportFilterModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() canFilterByDistrict: boolean;

  @Output() readonly modalClosing = new EventEmitter<boolean>();

  @Input() showModal = false;

  @Input() reportName = 'report_dashboard';

  @ViewChild('modal') modal: ModalComponent;

  currentForm?: Form;

  editedFilters: ReportFiltersDTO;

  initialFilters: ReportFiltersDTO;

  districtsComponentValue: number[] = [];

  coacheesComponentValue: number[] = [];

  schoolYearComponentValue: number;

  schoolYearsComponentValue: number[];

  dateRange: [NgbDate | null, NgbDate | null] = [null, null];

  schoolYearStartTimestamp: number;

  schoolYearEndTimestamp: number;

  formAnswersModel: ReportAnswerDTO[] = [];

  districtDependentVisible = true;

  topics: TopicDTO[] = [];

  Roles = UserRole;

  user: User | null = null;

  canSeeAllSchools = false;

  hideDatepicker = false;

  _sub: Subscription;

  applyFilters(): void {
    this.filterService.currentFilters = deepCopy(this.editedFilters);
    this.filterService.outputFilterChange();
    this.modal.close();
  }

  resetFilters(): void {
    this.editedFilters = { ...this.initialFilters };
    this.updateDistricts(
      this.editedFilters.districtFilter as DistrictSimpleDto[]
    );
  }

  setInitialFilters(filters: ReportFiltersDTO): void {
    this.initialFilters = deepCopy(filters);
    this.resetFilters();
    this.setDateRange();
  }

  constructor(
    private store: Store,
    private filterService: ReportPageFiltersService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.canSeeAllSchools = compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.E2L_EMPLOYEE],
      this.user.roles
    );
  }

  ngOnInit() {
    this.filterService.setReportName(this.reportName);
    this._sub = this.filterService.filters.subscribe((filters) => {
      this.setInitialFilters(filters);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reportName']) {
      this.filterService.setReportName(this.reportName);
    }
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'showModal':
          if (changes[propName].currentValue === true) {
            this.resetFilters();
          }
          break;
        default:
      }
    });
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  getDistrictTopics(districts: DistrictSimpleDto[]) {
    const districtIds = districts.map((district) => district.id);
    this.filterService.getDistrictTopics(districtIds).subscribe((res) => {
      this.topics = res;
      this.topics.sort((a: TopicDTO, b: TopicDTO) => {
        const aTitle = a.title.toLowerCase();
        const bTitle = b.title.toLowerCase();

        if (aTitle < bTitle) {
          return -1;
        }
        if (aTitle < bTitle) {
          return 1;
        }
        return 0;
      });
    });
  }

  setDateRange() {
    // Set min and max school years for datepicker
    if (
      this.editedFilters.schoolYearFilter &&
      !this.editedFilters.schoolYearsFilter
    ) {
      // Set to user's selected school year (single)
      this.schoolYearStartTimestamp = DateTime.fromFormat(
        `${this.editedFilters.schoolYearFilter.start_date}`,
        'yyyy-MM-dd'
      ).toSeconds();
      this.schoolYearEndTimestamp = DateTime.fromFormat(
        `${this.editedFilters.schoolYearFilter.end_date}`,
        'yyyy-MM-dd'
      ).toSeconds();
    } else if (this.editedFilters.schoolYearsFilter && !this.hideDatepicker) {
      // Set to user's selected school years (multiple)
      this.schoolYearStartTimestamp = DateTime.fromFormat(
        `${this.editedFilters.schoolYearsFilter[0].start_date}`,
        'yyyy-MM-dd'
      ).toSeconds();
      this.schoolYearEndTimestamp = DateTime.fromFormat(
        `${
          this.editedFilters.schoolYearsFilter[
            this.editedFilters.schoolYearsFilter.length - 1
          ].end_date
        }`,
        'yyyy-MM-dd'
      ).toSeconds();
    } else {
      // Set to current school year if no input
      this.schoolYearStartTimestamp = DateTime.fromFormat(
        schoolYearList[0].start_date,
        'yyyy-MM-dd'
      ).toSeconds();
      this.schoolYearEndTimestamp = DateTime.fromFormat(
        schoolYearList[schoolYearList.length - 1].end_date,
        'yyyy-MM-dd'
      ).toSeconds();
    }
  }

  updateDistricts(districts: DistrictSimpleDto[] | null) {
    if (districts) {
      this.editedFilters.districtFilter = districts as DistrictSimpleDto[];
      this.districtsComponentValue = [...districts.map((d) => d.id)];
      if (districts.length > 0) {
        this.getDistrictTopics(districts);
      }
    }
  }

  updateTopic(topic: TopicDTO | null) {
    if (topic) {
      this.editedFilters.topicFilter = topic;
    } else if (this.editedFilters.topicFilter) {
      delete this.editedFilters.topicFilter;
    }
  }

  updateSchools(school: SchoolDTO[] | null) {
    if (school && school.length > 0) {
      this.editedFilters.schoolFilter = school;
    } else {
      delete this.editedFilters.schoolFilter;
    }
  }

  updateSchoolYear(schoolYear: SchoolYearDTO | null) {
    if (schoolYear) {
      this.editedFilters.schoolYearFilter = schoolYear;
      this.schoolYearComponentValue = schoolYear.id;
      this.setDateRange();
    }
  }

  updateSchoolYears(schoolYears: SchoolYearDTO[] | null) {
    if (schoolYears) {
      // Always show datepicker when only one school year is selected
      if (schoolYears.length === 1) {
        this.hideDatepicker = false;
        this.editedFilters.schoolYearsFilter = schoolYears;
      } else {
        // Hide datepicker if non-contiguous years are selected
        const sortedYears = schoolYears.sort((a, b) => a.id - b.id);

        this.editedFilters.schoolYearsFilter = sortedYears;

        let isContiguous = true;

        for (let i = 0; i < sortedYears.length - 1; i += 1) {
          if (sortedYears[i].id + 1 !== sortedYears[i + 1].id) {
            isContiguous = false;
            if (this.editedFilters.dateRangeFilter) {
              delete this.editedFilters.dateRangeFilter;
            }
            break;
          }
        }

        this.hideDatepicker = !isContiguous;
      }

      this.schoolYearsComponentValue = schoolYears.map((year) => year.id);
      this.setDateRange();
    }
  }

  updateCoach(coach: UserLiteDTO | null) {
    if (coach) {
      this.editedFilters.assignedCoachFilter = coach;
    } else if (this.editedFilters.assignedCoachFilter) {
      delete this.editedFilters.assignedCoachFilter;
    }
  }

  updateCoachees(coachees: UserLiteDTO[] | null) {
    if (coachees) {
      this.editedFilters.coacheesFilter = coachees;
    } else if (this.editedFilters.coacheesFilter) {
      delete this.editedFilters.coacheesFilter;
    }
  }

  updateLogTypes(logTypes: CoachingSessionTypeDTO[] | null) {
    if (logTypes) {
      this.editedFilters.logTypeFilter = logTypes;
    } else if (this.editedFilters.logTypeFilter) {
      delete this.editedFilters.logTypeFilter;
    }
  }

  updateCohort(cohort: DWCohort | null) {
    if (cohort) {
      this.editedFilters.cohortFilter = cohort;
    } else if (this.editedFilters.cohortFilter) {
      delete this.editedFilters.cohortFilter;
    }
  }

  updateFormAnswers(formAnswers: ObservationFormAnswerDTO[]) {
    if (formAnswers) {
      this.editedFilters.formAnswersFilter = formAnswers;
    } else if (this.editedFilters.formAnswersFilter) {
      delete this.editedFilters.formAnswersFilter;
    }
  }

  updateDateRange(valueType: 'to' | 'from', date: NgbDate | null) {
    if (date) {
      if (valueType === 'from') {
        this.dateRange[0] = date;
      } else {
        this.dateRange[1] = date;
      }
      if (this.dateRange[0] && this.dateRange[1]) {
        this.editedFilters.dateRangeFilter = [
          this.dateRange[0] as NgbDate,
          this.dateRange[1] as NgbDate,
        ];
      }
    } else {
      if (valueType === 'from') {
        this.dateRange[0] = null;
      } else {
        this.dateRange[1] = null;
      }
      if (this.editedFilters.dateRangeFilter) {
        delete this.editedFilters.dateRangeFilter;
      }
    }
    this.setDateRange();
  }

  updateHasOption(hasOption: string | null) {
    switch (hasOption) {
      case 'Yes':
        this.editedFilters.hasOption = true;
        break;
      case 'No':
        this.editedFilters.hasOption = false;
        break;
      default:
        if ('hasOption' in this.editedFilters) {
          delete this.editedFilters.hasOption;
        }
        break;
    }
  }

  updateIsReviewed(isReviewed: string | null) {
    switch (isReviewed) {
      case 'Reviewed':
        this.editedFilters.isReviewed = true;
        break;
      case 'Not Reviewed':
        this.editedFilters.isReviewed = false;
        break;
      default:
        if ('isReviewed' in this.editedFilters) {
          delete this.editedFilters.isReviewed;
        }
        break;
    }
  }
}
