<div class="container">
  <app-general-header
    [title]="
      'Manage ' + ((selectedDistrict && selectedDistrict.title) || 'District')
    " />
  <div class="card border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <ng-container *ngIf="!isPageLoading; else loadingContent">
      <form [formGroup]="configurationForm" (ngSubmit)="onSubmit()">
        <div class="container mt-3">
          <h4>Reporting Goals</h4>
          <div class="row mt-3 d-flex align-items-end">
            <div class="col-lg-4">
              <label for="sessionsPerCoacheeGoal" class="form-label"
                >Coaching Sessions per Coachee per Year:</label
              >
              <div class="input-group">
                <input
                  formControlName="sessionsPerCoacheeGoal"
                  value="{{ sessionsPerCoacheeGoal }}"
                  type="text"
                  class="form-control"
                  id="sessionsPerCoacheeGoal"
                  aria-label="Sessions Per Coachee Goal"
                  [ngClass]="{
                    'is-invalid': f['sessionsPerCoacheeGoal'].errors
                  }" />
              </div>
              <div class="errors-row my-2">
                <div
                  class="text-danger fs-14"
                  *ngIf="configurationForm.get('sessionsPerCoacheeGoal')">
                  <div
                    *ngIf="
                      configurationForm
                        .get('sessionsPerCoacheeGoal')
                        ?.hasError('outOfRange')
                    ">
                    Value cannot be negative
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('sessionsPerCoacheeGoal')
                        ?.hasError('pattern')
                    ">
                    Value must be a valid number
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('sessionsPerCoacheeGoal')
                        ?.hasError('required')
                    ">
                    Must have value of 0 or greater to continue
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <label for="attendancePercentageGoal" class="form-label"
                >Percentage of Attendees Present:</label
              >
              <div class="input-group">
                <input
                  formControlName="attendancePercentageGoal"
                  value="{{ attendancePercentageGoal }}"
                  type="text"
                  class="form-control"
                  id="attendancePercentageGoal"
                  aria-label="Attendance Percentage Goal"
                  [ngClass]="{
                    'is-invalid': f['attendancePercentageGoal'].errors
                  }" />
              </div>
              <div class="errors-row my-2">
                <div
                  class="text-danger fs-14"
                  *ngIf="configurationForm.get('attendancePercentageGoal')">
                  <div
                    *ngIf="
                      configurationForm
                        .get('attendancePercentageGoal')
                        ?.hasError('outOfRange')
                    ">
                    Should be a percentage value between 0 and 100
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('attendancePercentageGoal')
                        ?.hasError('pattern')
                    ">
                    Value must be a valid number
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('attendancePercentageGoal')
                        ?.hasError('required')
                    ">
                    Should be a percentage value between 0 and 100
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <label for="observationsPerCoacheeGoal" class="form-label"
                >Observations per Coachee per Year:</label
              >
              <div class="input-group">
                <input
                  formControlName="observationsPerCoacheeGoal"
                  value="{{ observationsPerCoacheeGoal }}"
                  type="text"
                  class="form-control"
                  id="observationsPerCoacheeGoal"
                  aria-label="Observations Per Coachee Goal"
                  [ngClass]="{
                    'is-invalid': f['observationsPerCoacheeGoal'].errors
                  }" />
              </div>
              <div class="errors-row my-2">
                <div
                  class="text-danger fs-14"
                  *ngIf="configurationForm.get('observationsPerCoacheeGoal')">
                  <div
                    *ngIf="
                      configurationForm
                        .get('observationsPerCoacheeGoal')
                        ?.hasError('outOfRange')
                    ">
                    Value cannot be negative
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('observationsPerCoacheeGoal')
                        ?.hasError('pattern')
                    ">
                    Value must be a valid number
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('observationsPerCoacheeGoal')
                        ?.hasError('required')
                    ">
                    Must have value of 0 or greater to continue
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 d-flex align-items-end">
            <div class="col-lg-4">
              <label for="celebrationsPerUserGoal" class="form-label"
                >Celebrations per User per Year:</label
              >
              <div class="input-group">
                <input
                  formControlName="celebrationsPerUserGoal"
                  value="{{ celebrationsPerUserGoal }}"
                  type="text"
                  class="form-control"
                  id="celebrationsPerUserGoal"
                  aria-label="Celebrations Per User Goal"
                  [ngClass]="{
                    'is-invalid': f['celebrationsPerUserGoal'].errors
                  }" />
              </div>
              <div class="errors-row my-2">
                <div
                  class="text-danger fs-14"
                  *ngIf="configurationForm.get('celebrationsPerUserGoal')">
                  <div
                    *ngIf="
                      configurationForm
                        .get('celebrationsPerUserGoal')
                        ?.hasError('outOfRange')
                    ">
                    Value cannot be negative
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('celebrationsPerUserGoal')
                        ?.hasError('pattern')
                    ">
                    Value must be a valid number
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('celebrationsPerUserGoal')
                        ?.hasError('required')
                    ">
                    Must have value of 0 or greater to continue
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <label for="badgesPerCoacheePerSessionGoal" class="form-label"
                >Badges per Coachee per Session:</label
              >
              <div class="input-group">
                <input
                  formControlName="badgesPerCoacheePerSessionGoal"
                  value="{{ badgesPerCoacheePerSessionGoal }}"
                  type="text"
                  class="form-control"
                  id="badgesPerCoacheePerSessionGoal"
                  aria-label="Badges Per Coachee Per Session Goal"
                  [ngClass]="{
                    'is-invalid': f['badgesPerCoacheePerSessionGoal'].errors
                  }" />
              </div>
              <div class="errors-row my-2">
                <div
                  class="text-danger fs-14"
                  *ngIf="
                    configurationForm.get('badgesPerCoacheePerSessionGoal')
                  ">
                  <div
                    *ngIf="
                      configurationForm
                        .get('badgesPerCoacheePerSessionGoal')
                        ?.hasError('outOfRange')
                    ">
                    Value cannot be negative
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('badgesPerCoacheePerSessionGoal')
                        ?.hasError('pattern')
                    ">
                    Value must be a vaild number
                  </div>
                  <div
                    *ngIf="
                      configurationForm
                        .get('badgesPerCoacheePerSessionGoal')
                        ?.hasError('required')
                    ">
                    Must have value of 0 or greater to continue
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <label for="competencyBadgesPercentageGoal" class="form-label"
                >Percentage of Coachees that Earned 3 Microcredentials in
                Current School Year:</label
              >
              <div class="input-group">
                <input
                  formControlName="competencyBadgesPercentageGoal"
                  value="{{ competencyBadgesPercentageGoal }}"
                  type="text"
                  class="form-control"
                  id="competencyBadgesPercentageGoal"
                  aria-label="Competency Badges Percentage Goal"
                  [ngClass]="{
                    'is-invalid': f['competencyBadgesPercentageGoal'].errors
                  }" />
              </div>
              <div class="errors-row my-2">
                <div
                  class="text-danger fs-14"
                  *ngIf="
                    configurationForm.get('competencyBadgesPercentageGoal')
                  ">
                  <div
                    *ngIf="
                      configurationForm
                        .get('competencyBadgesPercentageGoal')
                        ?.hasError('outOfRange')
                    ">
                    Should be a percentage value between 0 and 100
                  </div>

                  <div
                    *ngIf="
                      configurationForm
                        .get('competencyBadgesPercentageGoal')
                        ?.hasError('pattern')
                    ">
                    Value must be a valid number
                  </div>

                  <div
                    *ngIf="
                      configurationForm
                        .get('competencyBadgesPercentageGoal')
                        ?.hasError('required')
                    ">
                    Should be a percentage value between 0 and 100
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <h4>Reporting Year Default</h4>
          <div class="row d-flex align-items-end">
            <div class="col-lg-4">
              <label for="reportedSchoolYear" class="form-label"
                >School Year:</label
              >
              <app-year-select
                (selectedYear)="onYearSelected($event)"
                [defaultYear]="
                  this.selectedDistrict?.reportingSchoolYear || null
                " />
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <h4>Session Privacy</h4>
          <div class="row d-flex align-items-end">
            <div class="col-lg-12">
              <div class="form-check d-flex align-items-center">
                <input
                  class="form-check-input mt-2"
                  type="checkbox"
                  formControlName="selectedDefaultPrivateLogs" />
                <label for="isPrivateSession" class="form-label ms-2"
                  >Private Sessions</label
                >
              </div>
              <div class="form-check-label ms-4">
                By default, sessions will be private. This means that the
                session can only be viewed by the coach, coachee(s), and
                shadower of the session.
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="emailSettingsEnabled">
          <div class="container mt-5">
            <h4>Email Notifications</h4>
            <div class="row d-flex align-items-end">
              <p>
                Select which email notifications will be sent to users in the
                entire district.
              </p>
            </div>
            <div class="row d-flex align-items-start">
              <div class="col-lg-10">
                <div class="row">
                  <div
                    class="col-12 col-sm-6 col-lg-4 mb-2"
                    *ngFor="let email of emailNotifications">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        (change)="onCheckboxChange($event)"
                        [ngModel]="email.isEnabled"
                        [ngModelOptions]="{ standalone: true }"
                        [id]="email.id" />
                      <label class="form-check-label" [for]="email.id">
                        {{ email.title }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="row d-flex justify-content-center my-5">
          <div class="col-11 d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-primary px-3"
              [disabled]="
                !configurationForm.valid ||
                (!configurationForm.dirty &&
                  notificationsUpdated.length == 0 &&
                  yearSelected == false)
              ">
              Save
            </button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-template #loadingContent>
      <div class="app-loader-container" style="height: 400px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
</div>
