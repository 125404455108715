import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */

export function negativeNumberValidator(control: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = Number(control.value);
    if (value === null || value === undefined || Number.isNaN(value)) {
      return { invalidValue: true } as ValidationErrors;
    }
    if (value < 0) {
      return { outOfRange: true };
    }
    return null;
  };
}

export function percentageValidator(control: FormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = Number(control.value);
    if (value === null || value === undefined || Number.isNaN(value)) {
      return { invalidValue: true };
    }
    if (value < 0 || value > 100) {
      return { outOfRange: true };
    }
    return null;
  };
}
