<app-modal
  #createActionItemModal
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    titleText: 'New ' + displayName,
    customFooter: true,
    showFooter: false
  }"
  (isClosing)="clearForm()"
  (isDismissing)="clearForm()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="createActionItemModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <label for="action-item-title" class="form-label">Title:</label>
      <div class="input-group mb-2">
        <input
          formControlName="title"
          type="text"
          class="form-control"
          id="action-item-title"
          aria-label="Action Item Title"
          [ngClass]="{
            'is-invalid': formSubmitted && f['title'].errors
          }" />
      </div>
      <label for="action-item-description" class="form-label"
        >Description:</label
      >
      <div class="input-group mb-2">
        <textarea
          formControlName="description"
          type="text"
          class="form-control"
          id="action-item-description"
          aria-label="action-item Description"
          [ngClass]="{
            'is-invalid': formSubmitted && f['description'].errors
          }"></textarea>
      </div>
      <div class="row">
        <label for="user-select" class="form-label">Assignee(s):</label>
        <div class="col mb-2">
          <app-user-select
            #userSelect
            [districtIds]="
              isE2L && user.district
                ? [districtId, user.district.id]
                : [districtId]
            "
            [clearAfterSelection]="true"
            (selectedUser)="updateAssignees($event)" />
        </div>
        <ng-container *ngFor="let assignee of assignees">
          <app-new-coaching-attendee
            [user]="assignee"
            (removedAttendee)="removeAssignee(assignee)" />
        </ng-container>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label" for="action-item-due-date">Due Date:</label>
          <app-datepicker
            #actionItemDueDatePicker
            id="action-item-due-date"
            [initialDateUnixTimestamp]="defaultDueDate / 1000"
            (dateSelectedUnixTimestamp)="updateDueDate($event)"
            [minDate]="datePickerMinDate"
            [maxDate]="datePickerMaxDate" />
        </div>
        <div *ngIf="serverError" class="mt-3">
          <ngb-alert [dismissible]="false" [type]="'danger'">
            <strong>Error</strong> {{ serverError }}
          </ngb-alert>
        </div>
        <div class="mt-5 d-flex justify-content-between">
          <button class="btn" (click)="createActionItemModal.close()">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="isLoading"
            (click)="createActionItem()">
            <ng-container *ngIf="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </ng-container>
            Create
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</app-modal>
