<div
  class="container"
  id="coach-dialog-single-container"
  *ngIf="coachProfile && message">
  <app-avatar
    class="d-flex justify-content-center"
    [profile]="coachProfile"
    [avatarSize]="'large'"></app-avatar>
  <div class="dialog-container">
    <p class="mb-0" id="coach-dialog-single-message">{{ message }}</p>
  </div>
</div>
