<div class="container">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card border-0 p-3 roster-container">
    <app-settings-nav [districtCode]="districtCode" />
    <ng-container *ngIf="district">
      <ng-container *ngIf="competencies; else loadingTemplate">
        <div class="topics-wrapper my-3">
          <ng-container *ngIf="topics.length > 0">
            <app-topic-select
              [topics]="topics"
              (selectedTopic)="filterByTopic($event)" />
          </ng-container>
        </div>
        <div style="width: 100%; overflow: auto">
          <table class="table">
            <thead>
              <tr>
                <th
                  (sort)="onSort($event)"
                  scope="col"
                  sortable="title"
                  class="competencies">
                  Competency Name
                  <ng-container *ngIf="!loadingIcons">
                    <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
                  </ng-container>
                </th>
                <th (sort)="onSort($event)" scope="col" sortable="topic">
                  Topic
                  <ng-container *ngIf="!loadingIcons">
                    <i class="bi bi-caret-down {{ sortIconTemp.topic }}"></i>
                  </ng-container>
                </th>
                <th>Audience</th>
                <th
                  (sort)="onSort($event)"
                  scope="col"
                  sortable="is_smart"
                  class="isSmart">
                  mySmartCoach
                  <ng-container *ngIf="!loadingIcons">
                    <i class="bi bi-caret-down {{ sortIconTemp.is_smart }}"></i>
                  </ng-container>
                </th>
                <th
                  (sort)="onSort($event)"
                  scope="col"
                  sortable="is_visible"
                  class="isVisible">
                  Visibility
                  <ng-container *ngIf="!loadingIcons">
                    <i
                      class="bi bi-caret-down {{
                        sortIconTemp.is_visible
                      }}"></i>
                  </ng-container>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="competencies; else loadingTemplate">
                <ng-container
                  *ngIf="competencies.length > 0; else noCompetencies">
                  <tr
                    *ngFor="
                      let competency of competencies;
                      index as currentIndex
                    "
                    style="vertical-align: middle">
                    <td
                      (click)="previewCompetency(competency)"
                      style="text-align: left">
                      <button
                        aria-label="preview-competency-button"
                        class="btn btn-link fw-bold"
                        style="text-align: left; color: #1272a6">
                        {{ competency.title }}
                      </button>
                    </td>
                    <td>
                      {{ competency.rubric_name }}
                    </td>
                    <td>
                      <ng-container
                        *ngFor="let audience of competency.audience">
                        <span class="badge badge-item mb-2 me-1">{{
                          audience.title
                        }}</span>
                      </ng-container>
                    </td>
                    <td class="text-center">
                      <ng-container
                        *ngIf="
                          competency.is_smart;
                          then checked;
                          else unchecked
                        "></ng-container>
                      <ng-template #checked
                        ><i class="bi bi-check2-circle text-info"></i
                      ></ng-template>
                      <ng-template #unchecked
                        ><i class="bi bi-circle-fill"></i
                      ></ng-template>
                    </td>
                    <td class="text-center">
                      <button
                        aria-label="competency-visibility-button"
                        class="form-switch btn-reset">
                        <input
                          class="form-check-input cursor-pointer"
                          type="checkbox"
                          role="switch"
                          [checked]="competency.is_visible"
                          (change)="toggleCompetency(competency)" />
                      </button>
                    </td>
                  </tr>
                </ng-container>
                <ng-template #noCompetencies>
                  <tr>
                    <td colspan="5">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center w-100"
                        style="min-height: 400px">
                        <h2>No Competencies Found</h2>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </ng-container>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<app-competency-preview
  #competencyPreviewModal
  [competencyData]="previewData" />
<ng-template #loadingTemplate>
  <div class="w-100" style="background-color: #fff; height: 700px">
    <app-loader></app-loader>
  </div>
</ng-template>
