<div class="container container-main">
  <app-general-header
    [title]="
      ((selectedSchool && selectedSchool.title) ||
        (selectedDistrict && selectedDistrict.title) ||
        'District') + ' Reporting'
    " />
  <div class="card border-0 p-3 mb-3">
    <app-reporting-subnav></app-reporting-subnav>
    <div class="card-body">
      <h2 class="fw-bolder text-center mb-4 w-100">Support Insights Report</h2>
      <div>
        <ngb-alert
          *ngIf="noResults"
          [type]="'danger'"
          (closed)="closeErrorMsg()">
          Something went wrong generating your report download.
        </ngb-alert>
      </div>
      <div class="d-flex justify-content-end">
        <button
          ngbTooltip="Download CSV"
          aria-label="Download CSV"
          class="btn"
          container="body"
          placement="bottom"
          [autoClose]="false"
          [ngbPopover]="csvDownloadingPopover"
          [disabled]="disableDownload"
          (click)="downloadCSV()"
          #p="ngbPopover">
          <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
        </button>
        <ng-template #csvDownloadingPopover>
          <div class="p-2">
            Your report is generating. The download will begin shortly.
          </div>
          <div class="p-2 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="p.close()">
              OK
            </button>
          </div>
        </ng-template>
      </div>
      <app-report-filter-bar
        [canFilterByDistrict]="canFilterByDistrict"
        [reportName]="'support_insights'"></app-report-filter-bar>
      <div id="total-sessions-count-chart" class="position-relative">
        <h3 class="fs-5 fw-800 mt-5 text-center">Total Sessions</h3>
        <app-report-controller
          chartId="total-sessions-count-chart"
          cardHeight="50rem"
          [chartType]="chartTypes.HorizontalStackedBar"
          yAxisLabel="Session Count"
          [seriesSort]="'reverse-alpha'"
          [drilldownDimensions]="['user_name']"
          [requestParams]="totalSessionsParams"
          [colorPalette]="['#27004B', '#D95195']" />
      </div>
      <div id="duration-of-sessions-chart" class="position-relative">
        <h3 class="fs-5 fw-800 mt-5 mb-1 text-center">Duration of Sessions</h3>
        <app-report-controller
          chartId="duration-of-sessions-chart"
          cardHeight="50rem"
          [chartType]="chartTypes.HorizontalBar"
          yAxisLabel="Total Session Duration (hours)"
          [drilldownDimensions]="['user_name']"
          [requestParams]="sessionDurationParams"
          chartColor="#267DAD" />
      </div>
    </div>
    <div class="mt-5 text-muted small text-end">
      Data last refreshed at {{ lastUpdatedDate }}
    </div>
  </div>
</div>
