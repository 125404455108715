import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { EditUserPayload } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { FetchUser } from 'src/app/common/state/user/user.state';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { AlertService } from '../../shared/services/alert/alert.service';
import { BasicInputValue as BasicInputData } from './basic-input/basic-input.component';

@Component({
  selector: 'app-user-settings-page',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsPageComponent {
  isLoading = false;

  user: User;

  newUserData: User;

  disableSave = true;

  constructor(
    private store: Store,
    private userService: UserService,
    private alertService: AlertService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.newUserData = deepCopy(this.user);
  }

  updateValue(labelData: BasicInputData) {
    switch (labelData.label) {
      case 'First Name':
        this.newUserData.profile.first_name = labelData.value;
        break;
      case 'Last Name':
        this.newUserData.profile.last_name = labelData.value;
        break;
      default:
        break;
    }

    if (
      (this.newUserData.profile.first_name !== this.user.profile.first_name ||
        this.newUserData.profile.last_name !== this.user.profile.last_name) &&
      this.newUserData.profile.first_name.length > 0 &&
      this.newUserData.profile.last_name.length > 0
    ) {
      this.disableSave = false;
    } else {
      this.disableSave = true;
    }
  }

  updateUser() {
    const newUserDataPayload: EditUserPayload = {
      profile: {
        first_name: this.newUserData.profile.first_name,
        last_name: this.newUserData.profile.last_name,
      },
    };
    this.userService
      .updateUser(this.user.id, newUserDataPayload)
      .subscribe(() => {
        this.store.dispatch(FetchUser).subscribe((res) => {
          if (res.user.user) {
            this.user = res.user.user;
            this.alertService.showAlert('User Settings Saved');
            this.disableSave = true;
          }
        });
      });
  }
}
