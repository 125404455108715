import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Color, ECharts, EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { HeatmapRequestParams } from 'src/app/common/dtos/reports.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { HeatmapData } from 'src/app/private/shared/dtos/heatmap.dto';
import { ReportPageFiltersService } from '../../../services/report/report-page-filters.service';
import { FetchLastUpdatedDate } from '../../../state/reporting/reporting.actions';
import { getBlankChartOptions } from '../report-controller/chart-options-helper';

@Component({
  selector: 'app-heatmap-controller',
  templateUrl: './heatmap-controller.component.html',
  styleUrls: ['./heatmap-controller.component.scss'],
})
export class HeatmapControllerComponent implements OnDestroy {
  heatmapOptions: EChartsOption;

  user: User;

  districtData: HeatmapData;

  eChartsInstance: ECharts;

  schoolData: HeatmapData;

  params: HeatmapRequestParams;

  innerWidth: number;

  isMobile: boolean;

  isLoading = true;

  isDrillDown = false;

  schoolLabel: string;

  _sub: Subscription;

  drillDownType = 'competency';

  @Output() readonly changeDrilldownType: EventEmitter<string> =
    new EventEmitter();

  @Output() readonly selectSchool: EventEmitter<number> = new EventEmitter();

  spinnerOptions = {
    text: 'Loading...',
    textColor: '#27004b',
    maskColor: 'rgba(255, 255, 255)',
    zlevel: 10,
    fontSize: 24,
    fontFamily: 'greycliff-cf',
    showSpinner: false,
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    let firstMobile;

    if (this.innerWidth <= 1000) {
      firstMobile = true;
    } else {
      firstMobile = false;
    }

    if (firstMobile !== this.isMobile && !this.isDrillDown) {
      this.handleResponse(this.districtData);
    } else if (
      firstMobile !== this.isMobile &&
      this.isDrillDown &&
      this.schoolData
    ) {
      this.handleResponse(this.schoolData);
    }
  }

  constructor(
    private apiService: APICoreService,
    private store: Store,
    private reportPageFiltersService: ReportPageFiltersService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this._sub = this.reportPageFiltersService.filters.subscribe((filters) => {
      if (filters.schoolYearFilter && filters.reportName === 'badge_progress') {
        const filterParams = translateKpiReportFilterFromFilterDTO(filters);

        if (
          !this.params?.filters ||
          JSON.stringify(this.params.filters) !== JSON.stringify(filterParams)
        ) {
          this.changeDrilldownType.emit('competency');

          this.params = {
            report: 'microCredentialProgressBySchools',
            filters: filterParams,
          };
          this.getDistrictData();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  onChartInit(e: ECharts) {
    this.eChartsInstance = e;
    this.getDistrictData();
  }

  getDistrictData(): void {
    this.store.dispatch(FetchLastUpdatedDate);
    this.isLoading = true;
    this.apiService
      .getRequest('reporting/load-report', this.params)
      .subscribe((value) => {
        this.districtData = {
          xLabels: value.xAxisLabels,
          yLabels: value.yAxisLabels,
          yAxisIds: value.yAxisIds,
          chartData: value.datasets[0].dataRaw,
        };

        this.innerWidth = window.innerWidth;

        this.handleResponse(this.districtData);
        this.isLoading = false;
      });
  }

  getSchoolData(schoolId: number): void {
    this.changeDrilldownType.emit('learner');

    this.params = {
      report: 'microCredentialProgressByLearners',
      filters: [
        ...this.params.filters,
        {
          codename: 'school',
          title: 'School',
          value: [schoolId.toString()],
          displayName: 'School',
        },
      ],
    };

    this.selectSchool.emit(schoolId);

    this.apiService
      .getRequest('reporting/load-report', this.params)
      .subscribe((value) => {
        value.yAxisLabels.reverse();
        value.yAxisIds?.reverse();
        value.datasets[0].dataRaw.reverse();
        this.schoolData = {
          xLabels: value.xAxisLabels,
          yLabels: value.yAxisLabels,
          yAxisIds: value.yAxisIds,
          chartData: value.datasets[0].dataRaw,
        };

        this.innerWidth = window.innerWidth;

        this.handleResponse(this.schoolData, false);
        this.isLoading = false;
      });
  }

  handleResponse(responseData: HeatmapData, originalData = true) {
    if (!responseData.chartData.length) {
      this.eChartsInstance.setOption(getBlankChartOptions({}), true);
    } else {
      // Zoom gets too hard to read on small screens
      this.isMobile = this.innerWidth <= 992;
      let xZoomEnd: number;

      let dataZoomY = false;
      let dataZoomX = false;
      let yAxisWidth: number;
      let leftGridWidth: string;

      const tooltipYLabels = responseData.yLabels;
      const tooltipXLabels = responseData.xLabels;

      if (this.isMobile) {
        xZoomEnd = 2;
        yAxisWidth = 100;
        leftGridWidth = '130px';
      } else {
        xZoomEnd = 7;
        yAxisWidth = 230;
        leftGridWidth = '250px';
      }

      const dataZoomArray: EChartsOption['dataZoom'] = [];

      if (responseData.xLabels.length > 8 && !dataZoomX) {
        dataZoomArray.push({
          type: 'slider',
          showDetail: false,
          start: 0,
          endValue: xZoomEnd,
          xAxisIndex: 0,
          zoomLock: true,
          brushSelect: false,
          height: '20',
          backgroundColor: '#dfd0f3',
          borderColor: '#dfd0f3',
          fillerColor: 'white',
          handleSize: 33,
          handleStyle: {
            borderColor: '#dfd0f3',
          },
          emphasis: {
            handleStyle: {
              borderColor: '#dfd0f3',
            },
          },
        });

        dataZoomX = true;
      } else {
        dataZoomArray.push({
          show: false,
          xAxisIndex: 0,
        });
      }

      if (responseData.yLabels.length > 8 && !dataZoomY) {
        dataZoomArray.push({
          type: 'slider',
          showDetail: false,
          startValue: responseData.yLabels.length - 10,
          endValue: responseData.yLabels.length - 1,
          yAxisIndex: 0,
          zoomLock: true,
          brushSelect: false,
          width: '20',
          backgroundColor: '#dfd0f3',
          borderColor: '#dfd0f3',
          fillerColor: 'white',
          handleSize: 33,
          handleStyle: {
            borderColor: '#dfd0f3',
          },
          emphasis: {
            handleStyle: {
              borderColor: '#dfd0f3',
            },
          },
        });

        dataZoomY = true;
      } else {
        dataZoomArray.push({
          show: false,
          yAxisIndex: 0,
        });
      }

      let gridWidth;
      if (this.isMobile) {
        gridWidth = 'auto';
      } else if (responseData.xLabels.length > 5) {
        if (this.innerWidth > 1400) {
          gridWidth = '76%';
        } else if (this.innerWidth > 1200) {
          gridWidth = '70%';
        } else if (this.innerWidth > 900) {
          gridWidth = '65%';
        }
      } else {
        gridWidth = `${responseData.xLabels.length * 150}px`;
      }

      const heatmapOptions = {
        tooltip: {
          backgroundColor: '#f0cedf',
          borderColor: '#f0cedf',
          textStyle: { color: 'black', fontFamily: 'greycliff-cf' },
          position: 'top',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter(params: any): string {
            return `
            <strong>
              ${tooltipYLabels[params.data[1]]}: ${tooltipXLabels[
              params.data[0]
            ].replace(/\(id: .*\)/, '')}
            </strong>
            <br/>
            ${params.data[2]}/${params.data[3]} (${params.data[4].toFixed(2)}%)
            `;
          },
        },
        dataZoom: dataZoomArray,
        grid: {
          height:
            responseData.yLabels.length * 75 > 620
              ? 620
              : responseData.yLabels.length * 75,
          top: '75px',
          left: leftGridWidth,
          width: gridWidth,
        },
        title: {
          textStyle: {
            // color: 'grey',
            fontSize: 18,
            fontWeight: 'lighter',
          },
          text: this.isDrillDown ? this.schoolLabel : '',
          show: this.isDrillDown,
        },
        graphic: {
          type: 'group',
          top: 5,
          right: 0,
          tooltip: {
            show: false,
          },
          ignore: !this.isDrillDown,
          onclick: this.onBackClick.bind(this),
          children: [
            {
              // needs blank tooltips to prevent showing empty pink bubbles on mouseover
              tooltip: {
                backgroundColor: '',
                borderColor: '',
                show: false,
              },
              type: 'image',
              style: {
                image: 'assets/report/back-arrow.svg',
                fill: 'red',
                width: 20,
                height: 20,
              },
            },
            {
              tooltip: {
                backgroundColor: '',
                borderColor: '',
                show: false,
              },
              type: 'text',
              top: 3,
              right: 8,
              style: {
                text: 'Back',
                fontSize: 18,
                fontWeight: 'lighter',
              },
              textConfig: {
                position: 'right',
                offset: [50, 50],
              },
            },
          ],
        },
        xAxis: {
          type: 'category',
          data: responseData.xLabels,
          splitArea: {
            show: true,
            areaStyle: {
              color: [
                'rgba(255,255,255,1)' as Color,
                'rgba(255,255,255,1)' as Color,
              ],
            },
          },
          position: 'top',
          axisLabel: {
            fontFamily: 'greycliff-cf',
            interval: 0,
            width: 80,
            overflow: 'break',
            formatter(value: string) {
              const maxHeight = 35;
              const label =
                value.length > maxHeight
                  ? `${value.slice(0, maxHeight)}...`
                  : value;
              return label;
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'category',
          data: responseData.yLabels,
          splitArea: {
            show: true,
          },
          axisLabel: {
            fontFamily: 'greycliff-cf',
            interval: 0,
            width: yAxisWidth,
            fontSize: 15,
            color: this.isDrillDown ? 'gray' : '#1272a6',
            overflow: 'break',
            rich: {
              title: {
                width: 100,
                emphasis: {
                  itemStyle: {
                    color: 'green',
                  },
                },
              },
            },
          },
          triggerEvent: originalData,
          axisTick: {
            show: false,
          },
        },
        visualMap: {
          show: false,
          type: 'piecewise',
          min: 0,
          max: 100,
          calculable: true,
          pieces: [
            { min: 0, max: 0.009, color: 'rgb(250, 249, 246, .75)' },
            { min: 0.01, max: 25, color: '#f0eee9' },
            { min: 25.01, max: 50, color: '#C6DEEC' },
            { min: 50.01, max: 74.99, color: '#6FADCF' },
            { min: 75, max: 99.99, color: '#267DAD' },
            { min: 100, max: 100, color: '#21B0FF' },
          ],
        },
        series: [
          {
            name: '',
            type: 'heatmap',
            data: responseData.chartData,
            label: {
              show: false,
            },
            emphasis: {
              disabled: true,
              // itemStyle: {
              //   shadowBlur: 10,
              //   shadowColor: 'rgba(0, 0, 0, 0.5)',
              // },
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: 'white',
              borderRadius: 5,
            },
          },
        ],
      };
      if (this.eChartsInstance) {
        this.eChartsInstance.setOption(heatmapOptions, true);
      }
    }
  }

  onBackClick() {
    this.isDrillDown = false;
    this.handleResponse(this.districtData);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickEvent(event: any) {
    if (event.componentType === 'yAxis') {
      this.isLoading = true;
      this.schoolLabel = event.value;
      this.getSchoolData(this.districtData.yAxisIds[event.dataIndex]);
      this.isDrillDown = true;
    }
  }
}
