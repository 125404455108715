import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { TemplatePageTitleService } from 'src/app/common/services/template-page-title/template-page-title.service';
import { GrowelabLayoutComponent } from 'src/app/private/layout/growelab-layout/growelab-layout.component';
import { privateRoutes } from 'src/app/private/routes/private.routes';
import { sidebarRoutes } from 'src/app/private/routes/sidebar.routes';
import { GuestLayoutComponent } from 'src/app/public/layouts/guest-layout/guest-layout.component';

import { publicRoutes } from './public/routes/public.routes';

const routes: Routes = [
  {
    path: '',
    component: GrowelabLayoutComponent,
    children: [...privateRoutes, ...sidebarRoutes],
  },
  {
    path: '',
    component: GuestLayoutComponent,
    children: [
      ...publicRoutes,
      {
        path: '**',
        redirectTo: 'login',
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: TemplatePageTitleService }],
})
export class AppRoutingModule {}
