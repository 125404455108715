import { Component, Input } from '@angular/core';
import { CoacheeDTO } from 'src/app/private/shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-coachee-list-item',
  templateUrl: './coachee-list-item.component.html',
  styleUrls: ['./coachee-list-item.component.scss'],
})
export class CoacheeListItemComponent {
  @Input() coachee: CoacheeDTO;
}
