<ng-container *ngIf="canManage; else readOnlyTemplate">
  <section>
    <div class="sharing-title mb-1">Targeted Sharing</div>
    <div class="user-message mb-4">
      Use Targeted Sharing to set access for specifc groups and individuals.
    </div>

    <div class="share-item">
      <div class="share-item-title">District's Users</div>
      <button
        aria-label="add-district-users"
        class="btn"
        (click)="accessModal.open()"
        (click)="startAddAccess('district')">
        <i class="bi bi-plus"></i>
      </button>
    </div>

    <ng-container *ngFor="let access of resourceAccessList">
      <ng-container *ngIf="access.access_scope == 'district'">
        <div class="share-item">
          <div class="selected-text">
            {{ access.scopeItemTitle }}
          </div>
          <select
            class="form-select form-select-sm ms-auto access-select"
            [(ngModel)]="access.access_rule"
            (ngModelChange)="updateAccess(access)">
            <option value="view">Can View</option>
            <option value="edit">Can Edit</option>
          </select>
          <button class="btn" (click)="removeAccess(access)">
            <i class="bi bi-dash"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <div class="share-item d-flex align-items-center justify-content-between">
      <div class="share-item-title">Schools Users</div>
      <button
        aria-label="add-school"
        class="btn"
        (click)="accessModal.open()"
        (click)="startAddAccess('school')">
        <i class="bi bi-plus"></i>
      </button>
    </div>

    <ng-container *ngFor="let access of resourceAccessList">
      <ng-container *ngIf="access.access_scope == 'school'">
        <div class="share-item">
          <div class="selected-text">
            {{ access.scopeItemTitle }}
          </div>
          <select
            class="form-select form-select-sm ms-auto access-select"
            [(ngModel)]="access.access_rule"
            (ngModelChange)="updateAccess(access)">
            <option value="view">Can View</option>
            <option value="edit">Can Edit</option>
          </select>
          <button class="btn" (click)="removeAccess(access)">
            <i class="bi bi-dash"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <div class="share-item d-flex align-items-center justify-content-between">
      <div class="share-item-title">District's Coaches</div>
      <button
        aria-label="add-district-coaches"
        class="btn"
        (click)="accessModal.open()"
        (click)="startAddAccess('districtCoaches')">
        <i class="bi bi-plus"></i>
      </button>
    </div>
    <ng-container *ngFor="let access of resourceAccessList">
      <ng-container *ngIf="access.access_scope == 'districtCoaches'">
        <div class="share-item">
          <div class="selected-text">
            {{ access.scopeItemTitle }}
          </div>
          <select
            class="form-select form-select-sm ms-auto access-select"
            [(ngModel)]="access.access_rule"
            (ngModelChange)="updateAccess(access)">
            <option value="view">Can View</option>
            <option value="edit">Can Edit</option>
          </select>
          <button class="btn" (click)="removeAccess(access)">
            <i class="bi bi-dash"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <div class="share-item d-flex align-items-center justify-content-between">
      <div class="share-item-title">District's Admins</div>
      <button
        aria-label="add-district-admins"
        class="btn"
        (click)="accessModal.open()"
        (click)="startAddAccess('districtAdmins')">
        <i class="bi bi-plus"></i>
      </button>
    </div>
    <ng-container *ngFor="let access of resourceAccessList">
      <ng-container *ngIf="access.access_scope == 'districtAdmins'">
        <div class="share-item">
          <div class="selected-text">
            {{ access.scopeItemTitle }}
          </div>
          <select
            class="form-select form-select-sm ms-auto access-select"
            [(ngModel)]="access.access_rule"
            (ngModelChange)="updateAccess(access)">
            <option value="view">Can View</option>
            <option value="edit">Can Edit</option>
          </select>
          <button class="btn" (click)="removeAccess(access)">
            <i class="bi bi-dash"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <div class="share-item d-flex align-items-center justify-content-between">
      <div class="share-item-title">Individual Users</div>
      <button
        aria-label="add-users"
        class="btn"
        (click)="accessModal.open()"
        (click)="startAddAccess('user')">
        <i class="bi bi-plus"></i>
      </button>
    </div>
    <ng-container *ngFor="let access of resourceAccessList">
      <ng-container *ngIf="access.access_scope == 'user'">
        <div class="share-item">
          <div class="selected-text">
            {{ access.scopeItemTitle }}
          </div>
          <select
            class="form-select form-select-sm ms-auto access-select"
            [(ngModel)]="access.access_rule"
            (ngModelChange)="updateAccess(access)">
            <option value="view">Can View</option>
            <option value="edit">Can Edit</option>
          </select>
          <button class="btn" (click)="removeAccess(access)">
            <i class="bi bi-dash"></i>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </section>

  <section>
    <div class="sharing-title mb-1">Everyone Else</div>
    <div class="user-message mb-4">
      These are the default settings for all other users.
    </div>
    <ng-container *ngFor="let access of resourceAccessList">
      <ng-container *ngIf="access.access_scope == 'everyone'">
        <div name="everyone-access" class="radio-group">
          <div class="radio-item">
            <input
              [(ngModel)]="access.access_rule"
              (ngModelChange)="updateAccess(access)"
              id="everyone-no-access-input"
              class="form-check-input"
              name="everyone-access"
              type="radio"
              value="private" />
            <label for="everyone-no-access-input" class="form-check-label ms-1">
              Doesn't Have Access
            </label>
          </div>
          <div class="radio-item">
            <input
              [(ngModel)]="access.access_rule"
              (ngModelChange)="updateAccess(access)"
              id="everyone-view-input"
              class="form-check-input"
              name="everyone-access"
              type="radio"
              value="view" />
            <label for="everyone-view-input" class="form-check-label ms-1">
              Can View
            </label>
          </div>
          <div class="radio-item">
            <input
              [(ngModel)]="access.access_rule"
              (ngModelChange)="updateAccess(access)"
              id="everyone-edit-input"
              class="form-check-input"
              name="everyone-access"
              type="radio"
              value="edit" />
            <label for="everyone-edit-input" class="form-check-label ms-1">
              Can Edit
            </label>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </section>

  <app-modal
    #accessModal
    [modalConfig]="{
      titleText: 'Adding Access',
      headerClass: ['modal-header', 'bg-primary', 'text-white'],
      customFooter: true
    }">
    <button
      class="bg-transparent border-0"
      title="Close"
      aria-label="close"
      headerContent
      (click)="accessModal.close()">
      <i class="bi bi-x-lg text-white"></i>
    </button>
    <ng-container>
      <div *ngIf="showAddAccessModal">
        <div [ngSwitch]="newAccessItem.access_scope">
          <div *ngSwitchCase="'district'">
            <div class="mb-1">Adding all users in district</div>
            <app-district-select
              (selectedDistrict)="setScopeId($event)"></app-district-select>
          </div>

          <div *ngSwitchCase="'districtAdmins'">
            <div class="mb-1">Adding District Admin users in district</div>
            <app-district-select
              (selectedDistrict)="setScopeId($event)"></app-district-select>
          </div>

          <div *ngSwitchCase="'districtCoaches'">
            <div class="mb-1">Adding District Coaches in district</div>
            <app-district-select
              (selectedDistrict)="setScopeId($event)"></app-district-select>
          </div>

          <div *ngSwitchCase="'school'">
            <div class="mb-1">Adding all users in school</div>
            <app-school-select
              (selectedSchool)="setScopeId($event)"></app-school-select>
          </div>

          <div *ngSwitchCase="'user'">
            <div class="mb-1">Adding user</div>
            <app-user-select
              (selectedUser)="setScopeId($event)"></app-user-select>
          </div>

          <div *ngSwitchDefault>[unknown]</div>
        </div>
        <div class="mt-3">
          <div>Access</div>
          <div name="access-rule" class="radio-group mt-1">
            <div class="radio-item">
              <input
                [(ngModel)]="newAccessItem.access_rule"
                class="form-check-input"
                name="access-rule"
                type="radio"
                value="view" />
              <label class="form-check-label ms-1"> Can View </label>
            </div>
            <div class="radio-item">
              <input
                [(ngModel)]="newAccessItem.access_rule"
                class="form-check-input"
                name="access-rule"
                type="radio"
                value="edit" />
              <label class="form-check-label ms-1"> Can Edit </label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container footerContent>
      <div class="footer-btn-row">
        <button
          class="btn btn-primary ms-3"
          (click)="accessModal.close()"
          (click)="addAccess()">
          Add Access
        </button>
      </div>
    </ng-container>
  </app-modal>
</ng-container>
<ng-template #readOnlyTemplate>
  <ng-container *ngFor="let access of resourceAccessList">
    <div>
      &nbsp;&nbsp;<strong>{{ access.scopeItemTitle }}</strong> can
      {{ access.access_rule }}
    </div>
  </ng-container>
</ng-template>
