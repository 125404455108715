import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

interface StoredMeMode {
  page: string;
  enabled: boolean;
}

const key = 'meMode';

@Injectable({ providedIn: 'root' })
export class MeModeStorageService {
  cards: StoredMeMode[] = [];

  constructor(private router: Router) {
    this.getStoredMeModes();
  }

  getStoredMeModes() {
    const cards = window.localStorage.getItem(key);
    if (cards) {
      this.cards = JSON.parse(cards);
    }
  }

  storeEnabledState(page: string, isEnabled: boolean) {
    const item: StoredMeMode = {
      page,
      enabled: isEnabled,
    };

    const matchedCard = this.cards.filter(
      (card: StoredMeMode) => card.page === page
    )[0];

    if (!matchedCard || !this.cards) {
      this.cards.push(item);
    } else {
      this.cards = this.cards.map((card) =>
        card === matchedCard ? item : card
      );
    }

    window.localStorage.setItem(key, JSON.stringify(this.cards));
  }

  getEnabledState(page: string) {
    if (this.cards) {
      const matchedCard = this.cards.filter(
        (card: StoredMeMode) => card.page === page
      )[0];
      if (matchedCard) {
        return matchedCard.enabled;
      }
    }
    return false;
  }
}
