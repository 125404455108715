import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  PusherMessageActionItemCreated,
  PusherMessageActionItemDeleted,
  PusherMessageActionItemUpdated,
  PusherMessageAssignmentUpdated,
  PusherMessageDeliverableCreated,
  PusherMessageDeliverableDeleted,
  PusherMessageDeliverableUpdated,
  PusherMessagePhaseCreated,
  PusherMessagePhaseDeleted,
  PusherMessagePhaseUpdated,
  PusherMessagePlanUpdated,
} from 'src/app/private/shared/services/plans/plans.service.dto';
import {
  AddAssignment,
  AddPhase,
  CreateActionItem,
  CreateDeliverable,
  DeleteActionItem,
  DeleteAssignment,
  DeleteDeliverable,
  DeletePhase,
  EditActionItem,
  EditDeliverable,
  EditPhase,
  EditPlan,
} from '../../state/implementation-plan/implementation-plan.actions';
import { PusherGuide } from '../pusher/pusher-guide.service';
import { PusherService } from '../pusher/pusher.service';

@Injectable({
  providedIn: 'root',
})
export class PlansPusherService extends PusherGuide {
  public phaseUpdatedStream = new Subject<number>();

  public phaseNotifier = this.phaseUpdatedStream.asObservable();

  // DELIVERABLES
  // Comments
  public deliverableCommentUpdatedStream = new Subject<boolean>();

  public deliverableCommentNotifier =
    this.deliverableCommentUpdatedStream.asObservable();

  // Attachments
  public deliverableAttachmentUpdatedStream = new Subject<boolean>();

  public deliverableAttachmentNotifier =
    this.deliverableAttachmentUpdatedStream.asObservable();

  // ACTION ITEMS
  // Comments
  public actionItemCommentUpdatedStream = new Subject<boolean>();

  public actionItemCommentNotifier =
    this.actionItemCommentUpdatedStream.asObservable();

  // Attachments
  public actionItemAttachmentUpdatedStream = new Subject<boolean>();

  public actionItemAttachmentNotifier =
    this.actionItemAttachmentUpdatedStream.asObservable();

  public user: User | null;

  constructor(pusherService: PusherService, private store: Store) {
    super(pusherService, 'presence');
  }

  protected override addDefaultBindings(channelId: string): void {
    this.user = this.store.selectSnapshot(UserState.getUser);
    const channel = this.getChannel(channelId);
    if (channel) {
      channel.bind('plan-updated', (message: PusherMessagePlanUpdated) => {
        if (message.user_id !== this.user?.id) {
          this.store.dispatch(new EditPlan(message.changes));
        }
      });
      channel.bind('phase-created', (message: PusherMessagePhaseCreated) => {
        if (message.user_id !== this.user?.id) {
          this.store.dispatch(new AddPhase({ phase: message.changes }));
        }
      });
      channel.bind('phase-updated', (message: PusherMessagePhaseUpdated) => {
        if (message.user_id !== this.user?.id) {
          this.store.dispatch(new EditPhase({ phase: message.changes }));
        }
      });
      channel.bind('phase-deleted', (message: PusherMessagePhaseDeleted) => {
        if (message.user_id !== this.user?.id) {
          this.store.dispatch(new DeletePhase({ phaseId: message.changes.id }));
        }
      });
      channel.bind(
        'deliverable-created',
        (message: PusherMessageDeliverableCreated) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new CreateDeliverable(message.changes));
          }
        }
      );
      channel.bind(
        'deliverable-updated',
        (message: PusherMessageDeliverableUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new EditDeliverable(message.changes));
          }
        }
      );
      channel.bind(
        'deliverable-comments-updated',
        (message: PusherMessageDeliverableUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.deliverableCommentUpdatedStream.next(true);
          }
        }
      );
      channel.bind(
        'deliverable-attachments-updated',
        (message: PusherMessageDeliverableUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.deliverableAttachmentUpdatedStream.next(true);
          }
        }
      );
      channel.bind(
        'deliverable-deleted',
        (message: PusherMessageDeliverableDeleted) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new DeleteDeliverable(message.changes));
          }
        }
      );
      channel.bind(
        'action-item-created',
        (message: PusherMessageActionItemCreated) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new CreateActionItem(message.changes));
          }
        }
      );
      channel.bind(
        'action-item-updated',
        (message: PusherMessageActionItemUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new EditActionItem(message.changes));
          }
        }
      );
      channel.bind(
        'action-item-comments-updated',
        (message: PusherMessageActionItemUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.actionItemCommentUpdatedStream.next(true);
          }
        }
      );
      channel.bind(
        'action-item-attachments-updated',
        (message: PusherMessageActionItemUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.actionItemAttachmentUpdatedStream.next(true);
          }
        }
      );
      channel.bind(
        'action-item-deleted',
        (message: PusherMessageActionItemDeleted) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new DeleteActionItem(message.changes));
          }
        }
      );
      channel.bind(
        'assignment-added',
        (message: PusherMessageAssignmentUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new AddAssignment(message.changes));
          }
        }
      );
      channel.bind(
        'assignment-deleted',
        (message: PusherMessageAssignmentUpdated) => {
          if (message.user_id !== this.user?.id) {
            this.store.dispatch(new DeleteAssignment(message.changes));
          }
        }
      );
    }
  }
}
