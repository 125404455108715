import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { CoachingSessionDTO } from '../../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { IntroSessionService } from '../../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-card-mysmartcoach',
  templateUrl: './card-mysmartcoach.component.html',
  styleUrls: ['./card-mysmartcoach.component.scss'],
})
export class CardMysmartcoachComponent implements OnInit {
  @Input() user: User | null;

  currentCoach: UserDTO;

  coachOptions: UserDTO[] = [];

  upcomingSessions: CoachingSessionDTO[] = [];

  upcomingSession: CoachingSessionDTO;

  scheduleSession = false;

  isCollapsed = this.cardStorageService.getCollapsedState('mysmartcoach');

  isLoading = true;

  constructor(
    private cardStorageService: CardStorageService,
    private introSessionService: IntroSessionService,
    private coachingLogService: CoachingLogService,
    private router: Router
  ) {}

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'mysmartcoach',
      this.isCollapsed
    );
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.user?.smartCoach) {
      this.currentCoach = this.user.smartCoach;
      this.checkForScheduledSession();
    } else {
      this.introSessionService.getCoaches().subscribe((res) => {
        this.coachOptions = res.items;
        this.isLoading = false;
      });
    }
  }

  checkForScheduledSession() {
    // 90 = 90 days, defaults to 7 with no argument, optional argument 1 = msc sessions only
    this.coachingLogService.getUpcomingSessions(90, 1).subscribe((res) => {
      if (res) {
        this.upcomingSessions = res[0];
        // eslint-disable-next-line no-restricted-syntax
        for (const currentSession of this.upcomingSessions) {
          if (currentSession.type.isSmart) {
            this.upcomingSession = currentSession;
            break;
          }
        }
        if (!this.upcomingSession) {
          this.scheduleSession = true;
        }
      }
      this.isLoading = false;
    });
  }

  goToNextScheduledSession() {
    this.router.navigate([`/smart-coach/log/${this.upcomingSession.id}/info`]);
  }
}
