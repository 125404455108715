import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { CreateEditTopicModalComponent } from '../../shared/components/modals/create-edit-topic-modal/create-edit-topic-modal.component';
import {
  SortEvent,
  SortableHeader,
} from '../../shared/directives/sortable-header.directive';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { TopicBuilderService } from '../../shared/services/topic-builder/topic-builder.service';

import { FilterType } from '../../shared/components/applied-filters/applied-filters.dto';
import { CompetencyBuilderStandardAPIResponse } from '../../shared/types/responses/competency-builder.responses';
import { TopicFilters, TopicSearchParams } from './topics-page-helpers';

@Component({
  selector: 'app-topics-page',
  templateUrl: './topics-page.component.html',
  styleUrl: './topics-page.component.scss',
})
export class TopicsPageComponent implements OnInit {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('createEditTopicModal')
  createEditTopicModal: CreateEditTopicModalComponent;

  subs: Subscription[] = [];

  districtCode: string;

  district: DistrictDTO;

  topicsTableIsLoading: boolean;

  loadingIcons = true;

  tableData: CompetencyBuilderStandardAPIResponse[];

  searchParams: TopicSearchParams = {
    search: '',
    page: 1,
    sort_by: '',
    sort_by_desc: false,
    districts: [],
  };

  searchTerm: string;

  searchTimeout: ReturnType<typeof setTimeout>;

  topicsListMeta: PaginationDTO;

  // eslint-disable-next-line class-methods-use-this
  columnDataMap = (
    topic: CompetencyBuilderStandardAPIResponse,
    column: string
  ): string | number => {
    switch (column) {
      case 'title':
        return topic.title || '';
      case 'districts':
        return topic.totalDistricts || '';
      default:
        return topic.title || '';
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    title: 'gray',
    totalDistricts: 'gray',
    is_smart: 'gray',
  };

  sortInfo = {
    column: '',
    direction: '',
  };

  displayFilterModal = false;

  currentFilters: TopicFilters = {
    districts: [],
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private districtList: DistrictListService,
    private topicBuilderService: TopicBuilderService
  ) {}

  ngOnInit(): void {
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtList
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
            this.getTopics();
          }
        }
      });
  }

  getTopics() {
    this.topicsTableIsLoading = true;
    this.searchParams.search = this.searchTerm ? this.searchTerm : '';
    this.topicBuilderService
      .getTopicBuilderTopics(this.searchParams)
      .subscribe((res) => {
        if (res.items) {
          this.tableData = res.items;
          this.topicsListMeta = res._meta;
          this.topicsTableIsLoading = false;
          this.updateColumns();
        }
      });
  }

  openCreateTopicModal() {
    this.createEditTopicModal.isEditMode = false;
    this.createEditTopicModal.modal.config.titleText = 'Create a Topic';
    this.createEditTopicModal.topicToEdit = undefined;
    this.createEditTopicModal.modal.open();
  }

  openEditTopicModal(topic: CompetencyBuilderStandardAPIResponse) {
    this.createEditTopicModal.isEditMode = true;
    this.createEditTopicModal.modal.config.titleText = 'Edit Topic';
    this.createEditTopicModal.topicToEdit = topic;
    this.createEditTopicModal.modal.open();
  }

  searchTermChanged(event: Event) {
    this.searchTerm = (<HTMLInputElement>event.target).value;
    this.searchTopicsDebounce();
  }

  searchTopicsDebounce() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchParams.page = 1;
      this.getTopics();
    }, 300);
  }

  onSort({ column, direction }: SortEvent) {
    if (direction === '') {
      this.searchParams.sort_by = '';
      this.searchParams.sort_by_desc = '';
    } else {
      this.searchParams.sort_by = column;
      if (direction === 'asc') {
        this.searchParams.sort_by_desc = true;
      } else if (direction === 'desc') {
        this.searchParams.sort_by_desc = false;
      }
    }
    this.sortInfo.column = column;
    this.sortInfo.direction = direction;

    this.loadingIcons = true;
    this.searchParams.page = 1;
    this.getTopics();
  }

  outputChanges(pageNumber?: number) {
    this.searchParams = pageNumber
      ? {
          ...this.searchParams,
          page: pageNumber,
        }
      : this.searchParams;

    this.getTopics();
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.sortInfo.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (this.sortInfo.direction === '' || this.sortInfo.column === '') {
      this.sortIconTemp[this.sortInfo.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  removeFilterFromList(filterList: { keyName: string; value: FilterType }) {
    switch (filterList.keyName) {
      case 'districts':
        this.filterDistricts(filterList);
        break;
      default:
        this.filterDistricts(filterList);
    }
    this.getTopics();
  }

  filterDistricts(filterList: { keyName: string; value: FilterType }) {
    if (this.searchParams.districts) {
      this.searchParams.districts = this.searchParams.districts.filter(
        (district) => {
          const value = filterList.value as number;
          return district !== value;
        }
      );
    }
  }

  setFilters(params: TopicSearchParams) {
    this.closeFilterModal();
    this.searchParams = params;
    this.getTopics();
  }

  showFilterModal() {
    this.displayFilterModal = true;
  }

  closeFilterModal() {
    this.displayFilterModal = false;
  }
}
