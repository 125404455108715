<ng-container *ngIf="coach">
  <ng-container *ngIf="coach.video">
    <button
      (click)="openVideoModal()"
      aria-label="Play video"
      ngbTooltip="Play video"
      class="btn btn-reset play-video">
      <i class="bi bi-play-circle-fill"></i>
    </button>
    <app-video-modal
      #videoModal
      [video]="coach.video"
      [showVideoInfo]="false" />
  </ng-container>
  <button class="btn btn-reset toggle-coach">
    <i
      [ngClass]="{
        'bi bi-toggle-off': !coach.isSelected,
        'bi bi-toggle-on': coach.isSelected
      }"
      (click)="toggleCoach()"></i>
  </button>
  <div class="d-flex justify-content-center">
    <app-avatar
      avatarSize="xx-large"
      [profile]="coach.coach.profile"></app-avatar>
  </div>
  <div class="fw-800 fs-19 mt-3">
    Coach {{ coach.coach.profile.first_name }}
  </div>
  <div class="fw-200 fs-14">
    {{ coach.coach.profile.bio }}
  </div>
</ng-container>
