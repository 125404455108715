import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { videoDTOFromAPIResponse } from '../../helpers/translators/video.translators';
import { IntroSessionService } from '../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-intro-overview',
  templateUrl: './intro-overview.component.html',
  styleUrls: ['./intro-overview.component.scss'],
})
export class IntroOverviewComponent implements OnInit, OnDestroy {
  @Input() whyComplete: boolean;

  @Input() egroweComplete: boolean;

  @Input() bpComplete: boolean;

  @Input() coachComplete: boolean;

  @Input() planComplete: boolean;

  subs: Subscription[] = [];

  overviewComplete = false;

  overviewVideo: VideoDTO;

  constructor(public introSessionService: IntroSessionService) {}

  ngOnInit() {
    this.introSessionService.getOverviewVideo().subscribe((res) => {
      if (res.item) {
        this.overviewVideo = videoDTOFromAPIResponse(res.item);
      }
    });

    this.subs.push(
      this.introSessionService.overviewComplete.subscribe((bool) => {
        this.overviewComplete = bool;
      })
    );
  }

  completeOverview() {
    if (!this.overviewComplete) {
      this.introSessionService.setOverviewComplete(true, true);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }
}
