import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import {
  CompetencyDTO,
  LevelDTO,
  StrandDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { SmartSessionService } from 'src/app/private/shared/services/smart-coach/smart-session.service';

@Component({
  selector: 'app-portfolio-leveled-competency-strand-level',
  templateUrl: './portfolio-leveled-competency-strand-level.component.html',
  styleUrls: [
    '../../../../competency/leveled-competency-strand/level/leveled-competency-strand-level.component.scss',
  ],
})
export class PortfolioLeveledCompetencyStrandLevelComponent
  implements OnInit, OnChanges
{
  @Input() competency: CompetencyDTO;

  @Input() competencyTitle: string;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() level: number;

  @Input() levelName: string;

  @Input() strand: StrandDTO;

  @Input() video: VideoDTO | null | undefined;

  @Input() showOptions = true;

  giDisabled = false;

  levelData: LevelDTO;

  isDrawerCollapsed = true;

  isOptionCollapsed = true;

  videoPlaying = false;

  constructor(
    public smartlogService: SmartSessionService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.strand) {
      switch (this.level) {
        case 1:
          this.levelData = this.strand.level1;
          break;
        case 2:
          this.levelData = this.strand.level2;
          break;
        case 3:
          this.levelData = this.strand.level3;
          break;
        default:
          this.levelData = this.strand.level4;
          break;
      }
    }
    this.disableGI();
  }

  ngOnChanges() {
    this.disableGI();
  }

  disableGI() {
    if (this.strand && this.levelData) {
      if (
        (this.levelData.levelCompleted &&
          this.strand.completedLevel > this.level) ||
        (!this.levelData.levelCompleted &&
          this.strand.completedLevel < this.level - 1)
      ) {
        this.giDisabled = true;
      } else {
        this.giDisabled = false;
      }
    } else {
      this.giDisabled = false;
    }
  }

  startVideo() {
    this.videoPlaying = true;
    this.changeDetector.detectChanges();
  }

  stopVideo() {
    this.videoPlaying = false;
    this.changeDetector.detectChanges();
  }
}
