<ul class="nav nav-tabs justify-content-center">
  <li class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/reporting'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page"
      >Reports Dashboard</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/reporting/support-insights'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page"
      >Support Insights</a
    >
  </li>
  <li *ngIf="evidenceReportEnabled" class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/reporting/evidence'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page"
      >Evidence</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/reporting/badge-progress'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page"
      >Growth</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/reporting/observations'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page"
      >Observation Report</a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/reporting/session-details'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page"
      >Session Details</a
    >
  </li>
</ul>
