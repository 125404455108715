export interface SessionCountDTO {
  month_of_school_year: number;
  count: number;
}

export interface SessionMonthDTO {
  monthString: string;
  monthNumber: number;
}

export const monthNames: SessionMonthDTO[] = [
  { monthString: 'January', monthNumber: 7 },
  { monthString: 'February', monthNumber: 8 },
  { monthString: 'March', monthNumber: 9 },
  { monthString: 'April', monthNumber: 10 },
  { monthString: 'May', monthNumber: 11 },
  { monthString: 'June', monthNumber: 12 },
  { monthString: 'July', monthNumber: 1 },
  { monthString: 'August', monthNumber: 2 },
  { monthString: 'September', monthNumber: 3 },
  { monthString: 'October', monthNumber: 4 },
  { monthString: 'November', monthNumber: 5 },
  { monthString: 'December', monthNumber: 6 },
];

export const abbreviatedMonthNames: SessionMonthDTO[] = [
  { monthString: 'Jan', monthNumber: 7 },
  { monthString: 'Feb', monthNumber: 8 },
  { monthString: 'Mar', monthNumber: 9 },
  { monthString: 'Apr', monthNumber: 10 },
  { monthString: 'May', monthNumber: 11 },
  { monthString: 'Jun', monthNumber: 12 },
  { monthString: 'Jul', monthNumber: 1 },
  { monthString: 'Aug', monthNumber: 2 },
  { monthString: 'Sep', monthNumber: 3 },
  { monthString: 'Oct', monthNumber: 4 },
  { monthString: 'Nov', monthNumber: 5 },
  { monthString: 'Dec', monthNumber: 6 },
];

export interface StackedBarDataSetDTO {
  data: number[];
  name: string;
}
