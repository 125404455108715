/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { dateTimeFromNgbDate } from '../utilities/date-helpers';

@Pipe({
  name: 'formatNgbDate',
})
export class FormatNgbDatePipe implements PipeTransform {
  /* eslint-disable class-methods-use-this */
  // skipcq: JS-0105
  transform(value: NgbDate): string {
    return dateTimeFromNgbDate(value).toFormat('MMM d, yyyy');
  }
}
