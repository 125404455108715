<div #target></div>
<ng-template #deleteVideoConfirm>
  Permanently delete this item?
  <div class="text-center mt-2">
    <button
      type="button"
      class="btn btn-secondary btn-sm px-3 m-1"
      (click)="t.close()">
      No
    </button>
    <button
      type="button"
      class="btn btn-primary btn-sm px-3 m-1"
      (click)="deleteVideo(); t.close()">
      Yes
    </button>
  </div>
</ng-template>
<div ngbDropdown>
  <a href="javascript:void(0)" id="share-dropdown" ngbDropdownToggle>
    <i class="bi bi-three-dots-vertical fs-5"></i>
  </a>
  <ul
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-end"
    aria-labelledby="share-dropdown">
    <li
      ngbDropdownItem
      ngbTooltip="Link copied to clipboard!"
      container="body"
      [positionTarget]="target"
      #t="ngbTooltip"
      triggers="manual"
      (click)="shareVideo(); t.open()">
      <i class="bi bi-share me-2"></i>Share Video
    </li>
    <li ngbDropdownItem *ngIf="canEdit" (click)="this.editVideo()">
      <i class="bi bi-pencil me-2"></i>Edit Video
    </li>
    <li
      ngbDropdownItem
      *ngIf="canEdit"
      ngbTooltip="Delete Video"
      container="body"
      [positionTarget]="target"
      [ngbTooltip]="deleteVideoConfirm"
      triggers="manual"
      #t="ngbTooltip"
      (click)="t.open()">
      <i class="bi bi-trash me-2"></i>Delete Video
    </li>
  </ul>
</div>
