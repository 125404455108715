<div class="form-group checkbox form-box mt-2 card border-0 shadow-sm">
  <label [id]="id" class="form-label p-2">{{ fieldDefinition.label }}</label>
  <div class="content px-2 pt-2 px-md-4 pt-md-4">
    <label
      *ngFor="let choice of fieldDefinition.choices"
      class="form-input d-block mb-4 item rounded-top"
      [class.cursor]="!inputDisabled">
      <div class="d-flex">
        <div class="position-relative toggle-container">
          <i
            class="fs-5 bi bi-square position-absolute"
            [ngClass]="readOnly ? 'opacity-0' : 'color-tertiary'"></i>
          <i
            *ngIf="selectionArray.includes(choice.value)"
            class="fs-3 bi bi-check color-black position-absolute"></i>
        </div>
        <input
          (change)="changeSelection(choice.value)"
          class="visually-hidden"
          type="checkbox"
          [id]="id"
          [value]="choice.value"
          [checked]="selectionArray.includes(choice.value)"
          [disabled]="inputDisabled" />
        <span class="d-block px-2" style="line-height: 1">{{
          choice.label
        }}</span>
      </div>
    </label>
  </div>
</div>
