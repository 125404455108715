import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Duration } from 'luxon';
import { KpiReportFilter } from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { DetailedPieData } from '../../../../shared/components/reports/report-controller/series-helper';

type DisplayData = DetailedPieData & {
  sessionDuration: Duration;
  color: string;
};

@Component({
  selector: 'app-frequency-table',
  templateUrl: './frequency-table.component.html',
  styleUrls: ['./frequency-table.component.scss'],
})
export class FrequencyTableComponent implements OnChanges {
  @Input() externalData: DetailedPieData[];

  @Input() filters: KpiReportFilter[];

  @Input() colors: string[];

  hasData = false;

  primaryData: DetailedPieData[];

  displayData?: DisplayData[];

  constructor(private apiService: APICoreService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filters']) {
      this.getPrimaryData();
    }
    if (changes['colors']) {
      // For very large sets of data
      this.colors = [
        ...changes['colors'].currentValue,
        ...changes['colors'].currentValue,
      ];
    }
    if (changes['externalData']) {
      this.clearDisplayData();
      if (this.primaryData) {
        this.mergeData();
      }
    }
  }

  clearDisplayData() {
    this.hasData = false;
    if (this.displayData) {
      delete this.displayData;
    }
  }

  getPrimaryData() {
    this.clearDisplayData();
    const params = {
      id: 'duration-by-session-type',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'sum',
        field: 'duration_min',
      },
      filters: this.filters,
      dimension: 'session_type_name',
    };
    this.apiService
      .getRequest('reporting/custom', params)
      .subscribe((value) => {
        if (value.datasets.length === 1) {
          this.primaryData = (value.datasets[0].data as number[]).map(
            (dataPoint, idx) => ({
              name: value.labels[idx],
              groupId: value.labels[idx],
              value: dataPoint,
              labelId: value.label_ids[idx],
            })
          );

          if (this.externalData) {
            this.mergeData();
          }
        }
      });
  }

  mergeData() {
    this.displayData = this.externalData.map(
      (dataPoint: DetailedPieData, idx: number) => ({
        ...dataPoint,
        color: this.colors[idx],
        sessionDuration: Duration.fromObject({
          minutes: this.primaryData.find(
            (primaryDataPoint) => primaryDataPoint.labelId === dataPoint.labelId
          )?.value as number,
        }).shiftTo('hours', 'minutes'),
      })
    );
    this.hasData = true;
  }
}
