/* eslint-disable @typescript-eslint/no-explicit-any */
export const buildParamsFromObject = (obj: any) => {
  if (typeof obj !== 'object') {
    return obj == null || typeof obj === 'undefined' ? '' : obj.toString();
  }
  let query = '';
  let value: any;
  let fullSubName;
  let subValue;
  let innerObj: any = {};
  Object.keys(obj).forEach((name) => {
    value = obj[name];
    if (value instanceof Array) {
      Object.keys(value).forEach((i) => {
        subValue = value[i];
        fullSubName = `${name}[${i}]`;
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += `${buildParamsFromObject(innerObj)}&`;
      });
    } else if (value instanceof Object) {
      Object.keys(value).forEach((subName) => {
        subValue = value[subName];
        fullSubName = `${name}[${subName}]`;
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += `${buildParamsFromObject(innerObj)}&`;
      });
    } else if (value !== undefined && value !== null)
      query += `${encodeURIComponent(name)}=${encodeURIComponent(value)}&`;
  });

  return query.length ? query.substr(0, query.length - 1) : query;
};
