import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { SessionTimeoutService } from '../../services/session-timeout/session-timeout.service';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss'],
})
export class SessionTimeoutModalComponent implements OnDestroy, OnInit {
  subs: Subscription[] = [];

  @ViewChild('modal')
  modal: ModalComponent;

  @Input() value: boolean;

  timeRemaining: number;

  constructor(
    public sessionTimeoutService: SessionTimeoutService,
    private authService: AuthService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.sessionTimeoutService.logoutTimeRemaining.subscribe((time) => {
        this.timeRemaining = time;
        this.changeDetector.detectChanges();
      })
    );
  }

  close() {
    this.modal.close();
    this.sessionTimeoutService.stopAutoLogoutProcess();
    this.sessionTimeoutService.resetTimer();
  }

  open() {
    this.modal.open();
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.close();
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
