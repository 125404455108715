import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { VideoCategoryDTO, VideoDTO } from 'src/app/common/dtos/video.dto';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  VideoLibraryFiltersDTO,
  VideoLimit,
  VideoLimitLength,
  VideoRole,
} from 'src/app/private/shared/dtos/video-library.dto';
import { VideoLibraryService } from '../../shared/services/video-library/video-library.service';

@Component({
  selector: 'app-video-library-page',
  templateUrl: './resources-video-library.component.html',
  styleUrls: ['./resources-video-library.component.scss'],
})
export class ResourcesVideoLibraryComponent implements OnInit, OnDestroy {
  search: string;

  videoCategory = '0';

  videoList: VideoDTO[] | null;

  videoToShow?: VideoDTO;

  searchMetaData: PaginationDTO | null;

  searchFilters: VideoLibraryFiltersDTO | null = null;

  categoriesList: VideoCategoryDTO[];

  selectedVideoCategory: VideoCategoryDTO | null;

  trackByFn = ResourcesVideoLibraryComponent.trackByFn;

  rolesList: VideoRole[] = [VideoRole.COACH, VideoRole.TEACHER];

  selectedRole: VideoRole | null;

  selectedVideoLength: VideoLimitLength | null;

  subs: Subscription[] = [];

  videoLength: VideoLimit[] = [
    { number: 5, label: 'Under five minutes' },
    { number: 10, label: 'Under ten minutes' },
    { number: 15, label: 'Under fifteen minutes' },
    { number: 20, label: 'Under twenty minutes' },
  ];

  selectedUser: string | undefined;

  usersSelected: UserLiteDTO[] | null = [];

  filtersApplied = false;

  numberOfFilters = 0;

  userCanEditVideos = false;

  newVideoSubject = new EventEmitter<boolean>();

  constructor(
    public videoLibraryService: VideoLibraryService,
    private store: Store
  ) {}

  ngOnInit() {
    this.getVideoList();
    this.getCategoriesList();

    const user = this.store.selectSnapshot(UserState.getUser) as User;
    if (compareRoles(user.roles, [UserRole.E2L_EMPLOYEE])) {
      this.userCanEditVideos = true;
    }

    this.subs.push(
      this.videoLibraryService.searchResultsMetaObs.subscribe((metaData) => {
        this.searchMetaData = metaData;
      })
    );

    this.subs.push(
      this.videoLibraryService.searchResultsObs.subscribe((videoList) => {
        this.videoList = videoList;
      })
    );

    this.subs.push(
      this.videoLibraryService.searchFiltersObs.subscribe((searchFilters) => {
        this.searchFilters = searchFilters;
        this.updateFilterCount();
      })
    );

    const params = new URLSearchParams(window.location.search);
    if (params.get('video')) {
      this.videoLibraryService
        .getVideo(parseInt(params.get('video') as string))
        .subscribe((videoDto) => {
          this.videoToShow = videoDto;
        });
    }
  }

  getCategoriesList() {
    this.categoriesList = [];
    this.videoLibraryService.getCategories().subscribe((result) => {
      if (result) {
        this.categoriesList = result;
      }
    });
  }

  getVideoList() {
    this.videoLibraryService.fetchVideos();
  }

  pageChanged() {
    if (this.searchMetaData) {
      this.videoLibraryService.setFilters({
        ...this.searchFilters,
        page: this.searchMetaData.currentPage,
      });
      this.getVideoList();
    }
  }

  public static trackByFn(item: VideoCategoryDTO) {
    return item.id;
  }

  onSearchFieldKeydown() {
    this.videoLibraryService.setFilters({
      search: this.search,
    });
    setTimeout(() => {
      this.getVideoList();
    }, 500);
  }

  updateVideoCategory(catId: number) {
    this.videoLibraryService.setFilters({
      category: catId,
    });
    this.getVideoList();
  }

  updateRole(newRole: string) {
    if (newRole) {
      this.videoLibraryService.setFilters({
        ...this.searchFilters,
        role: newRole,
      });
    } else {
      delete this.searchFilters?.role;
      this.videoLibraryService.setFilters({
        ...this.searchFilters,
      });
    }
    this.getVideoList();
  }

  updateVideoLength(newLength: VideoLimit) {
    if (newLength) {
      this.videoLibraryService.setFilters({
        ...this.searchFilters,
        length: newLength.number,
      });
    } else {
      delete this.searchFilters?.length;
      this.videoLibraryService.setFilters({
        ...this.searchFilters,
      });
    }
    this.getVideoList();
  }

  updateUser(e: string) {
    if (e !== 'custom') {
      this.selectedUser = e;
      this.videoLibraryService.setFilters({
        ...this.searchFilters,
        user: this.selectedUser,
      });
      this.getVideoList();
    }
  }

  updateUsersList(updatedList: UserLiteDTO[] | null) {
    this.usersSelected = updatedList;
    if (this.selectedUser === 'custom') {
      if (this.usersSelected && this.usersSelected.length > 0) {
        this.updateFilterCount();
        this.videoLibraryService.setFilters({
          ...this.searchFilters,
          user: JSON.stringify(this.usersSelected.map((a) => a.id)),
        });
      } else {
        delete this.searchFilters?.user;
        this.videoLibraryService.setFilters({
          ...this.searchFilters,
        });
      }
      this.getVideoList();
    }
  }

  updateFilterCount() {
    this.numberOfFilters = 0;
    if (this.searchFilters?.role) {
      this.numberOfFilters += 1;
    }
    if (this.searchFilters?.length) {
      this.numberOfFilters += 1;
    }
    if (this.searchFilters?.user) {
      this.numberOfFilters += 1;
    }
    if (this.numberOfFilters > 0) {
      this.filtersApplied = true;
    } else {
      this.filtersApplied = false;
    }
  }

  clearFilters() {
    this.selectedRole = null;
    this.selectedVideoLength = null;
    this.selectedUser = undefined;
    this.filtersApplied = false;
    this.videoLibraryService.clearFilters();
    this.videoLibraryService.fetchVideos();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
