import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CustomerDTO,
  DistrictPayloadDTO,
} from 'src/app/common/dtos/district.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  convertTimeZone,
  timezones,
} from 'src/app/common/utilities/time-helpers';
import { states } from 'src/app/common/utilities/physical-location-helpers';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';

@Component({
  selector: 'app-add-district-page',
  templateUrl: './add-district.component.html',
  styleUrls: ['./add-district.component.scss'],
})
export class AddDistrictComponent implements OnInit {
  newDistricts: CustomerDTO[];

  selectedDistrict: CustomerDTO | undefined;

  selectedDistrictName: string | undefined;

  form: FormGroup;

  submitted = false;

  loading = false;

  errorMessage: string;

  states = states;

  timezones = timezones;

  constructor(
    private router: Router,
    private districtListService: DistrictListService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.getDistricts();
    this.form = this.formBuilder.group({
      districts: ['', Validators.required],
      crmId: ['', Validators.required],
      districtName: ['', Validators.required],
      districtCode: ['', Validators.required],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      timezone: [''],
      domain: ['', Validators.required],
      description: [''],
      isB2b: [''],
      isInternal: [''],
    });
  }

  getDistricts(): void {
    this.districtListService.fetchNewDistricts().subscribe((newDistricts) => {
      if (newDistricts) {
        this.newDistricts = newDistricts;
      }
    });
  }

  onDistrictSelected(districtTitle: string) {
    this.selectedDistrict = this.newDistricts.find(
      (district) => district.name === districtTitle
    );
    this.selectedDistrictName = this.selectedDistrict?.name;
    if (this.selectedDistrict?.timezone) {
      this.selectedDistrict.timezone = convertTimeZone(
        this.selectedDistrict?.timezone
      );
    }
    this.populateForm();
  }

  populateForm() {
    this.form = this.formBuilder.group({
      districts: [this.selectedDistrict?.name, Validators.required],
      crmId: [this.selectedDistrict?.hubspotCompanyId, Validators.required],
      districtName: [this.selectedDistrict?.name, Validators.required],
      districtCode: [
        this.selectedDistrict?.name.split(' ')[0].toLowerCase(),
        Validators.required,
      ],
      address1: [this.selectedDistrict?.address],
      address2: [''],
      city: [this.selectedDistrict?.city],
      state: [
        this.states.find((state) =>
          state.title.includes(
            this.selectedDistrict ? this.selectedDistrict?.state : ''
          )
        )?.value,
      ],
      zipCode: [this.selectedDistrict?.zip],
      timezone: [this.selectedDistrict?.timezone],
      domain: [this.selectedDistrict?.domain, Validators.required],
      description: [this.selectedDistrict?.description],
      isB2b: [''],
      isInternal: [''],
    });
    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.controls[key].value === null) {
        this.form.controls[key].setValue('');
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  onCancel() {
    this.router.navigate(['settings']);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    const payload: DistrictPayloadDTO = {
      title: this.f['districtName'].value,
      domain: this.f['domain'].value,
      is_internal: this.f['isInternal'].value ? 1 : 0,
      is_b2b: this.f['isB2b'].value ? 1 : 0,
      address: this.f['address1'].value,
      address2: this.f['address2'].value,
      city: this.f['city'].value,
      state: this.f['state'].value,
      zip: this.f['zipCode'].value,
      phone: '',
      logo: '',
      timezone: this.f['timezone'].value,
      hubspot_company_id: this.f['crmId'].value,
      district_code: this.f['districtCode'].value
        .toLowerCase()
        .replace(/\s/g, ''),
      data_contact_email: '',
      why: '',
      reporting_school_year: '',
    };

    this.districtListService.addDistrict(payload).subscribe({
      error: (err) => {
        this.loading = false;
        this.errorMessage = err.error.message;
      },
      complete: () => this.router.navigate(['settings']),
    });
  }
}
