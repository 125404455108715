import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';

export const unleashStorageKey = 'unleashCache';

export const cacheUnleashData = (featureName: string, data: boolean): void => {
  const cacheData = StorageService.getItem(
    unleashStorageKey,
    EnvironmentService.sessionTimeoutMinutes * 1000
  );
  let cache: { [key: string]: boolean } = {};
  if (cacheData) {
    cache = JSON.parse(cacheData);
  }
  cache[featureName] = data;
  StorageService.storeItem(unleashStorageKey, JSON.stringify(cache));
};

export const FEATURE_FLAGS = {
  evidenceReporting: 'evidenceReport',
  mySmartCoach: 'MySmartCoach',
  studentOutcomes: 'studentOutcomes',
  giGrowthReport: 'GIGrowthReport',
  activityFeed: 'ActivityFeed',
  emailNotificationSettings: 'EmailNotificationSettings',
  copyEvidence: 'CopyEvidence',
  competencyBuilder: 'CompetencyBuilder',
  implementationPlan: 'ImplementationPlan',
};
