<div #container class="container competency-card shadow">
  <div
    (click)="toggleCollapse()"
    class="fw-800 fs-14 py-2 d-flex justify-content-between align-items-center cursor-pointer">
    <div class="d-flex align-items-center option-title-container">
      <i class="bi bi-list-check color-tertiary me-1 pt-1 fs-25"></i>
      <div class="overlap-text">Option: {{ details.content }}</div>
    </div>
    <i
      class="fs-19 color-tertiary bi bi-chevron-{{
        isDrawerCollapsed ? 'down' : 'up'
      }}"></i>
  </div>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isDrawerCollapsed">
    <ng-container *ngIf="logIsSmart; else nonSmart">
      <div class="fs-15 mt-2 mb-3">{{ details.content }}</div>
    </ng-container>
    <ng-template #nonSmart>
      <app-codox-textarea
        [initialContent]="initialOptionContent"
        [isSaving]="optionSaving"
        [disabled]="!canEdit"
        (autosave)="updateOptionContent($event)"
        [editId]="'growelabOption' + details.id" />
    </ng-template>
    <div class="row datePickerRow" *ngIf="showDatePicker">
      <ngb-datepicker
        [(ngModel)]="selectedDate"
        [firstDayOfWeek]="0"
        (dateSelect)="onDateSelect($event)">
      </ngb-datepicker>
    </div>
    <button
      [disabled]="!canEdit"
      class="btn btn-primary btn-sm ms-1 mt-1"
      (click)="showDatePicker = !showDatePicker">
      <ng-container *ngIf="this.will; else noDate">
        {{ this.will.dueDate | formatDate }}
      </ng-container>
      <ng-template #noDate> Commit </ng-template>
      <i class="bi bi-calendar3"></i>
    </button>
    <!--EVIDENCE-->
    <ng-container *ngIf="this.will">
      <div class="d-flex justify-content-between align-items-center mt-3">
        <h3 class="fs-14 fw-bold mt-2">
          <i class="bi bi-pencil color-tertiary me-1 fs-21"></i>
          Evidence Plan
        </h3>
        <button
          *ngIf="canEdit"
          aria-label="Delete planned evidence"
          class="btn-delete px-0">
          <i
            (click)="deleteWill()"
            ngbTooltip="Delete planned evidence"
            container="body"
            class="bi bi-trash fs-6"></i>
        </button>
      </div>
      <ng-container *ngIf="!this.logIsSmart; else smartLog">
        <app-codox-textarea
          [disabled]="!canEdit"
          id="will-content-wysiwyg"
          [initialContent]="initialWillContent"
          [isSaving]="willSaving"
          (autosave)="updateWillContent($event)"
          [editId]="'growelabOption' + this.will.id" />
      </ng-container>
      <ng-template #smartLog>
        <div class="radio-button-group" id="will-content-radio-group">
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              name="{{ this.will.id }}-btnradio"
              id="{{ this.will.id }}-link"
              autocomplete="off"
              [disabled]="!canEdit"
              [checked]="evidenceType == smartCoachEvidenceTypes.LINK"
              (click)="changeEvidenceType(smartCoachEvidenceTypes.LINK)" />
            <label for="{{ this.will.id }}-link" class="form-check-label">
              {{ smartCoachEvidenceTypes.LINK }}
            </label>
          </div>
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              name="{{ this.will.id }}-btnradio"
              id="{{ this.will.id }}-photo"
              autocomplete="off"
              [disabled]="!canEdit"
              [checked]="evidenceType == smartCoachEvidenceTypes.PHOTO"
              (click)="changeEvidenceType(smartCoachEvidenceTypes.PHOTO)" />
            <label for="{{ this.will.id }}-photo" class="form-check-label">
              {{ smartCoachEvidenceTypes.PHOTO }}
            </label>
          </div>
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              name="{{ this.will.id }}-btnradio"
              id="{{ this.will.id }}-video"
              autocomplete="off"
              [disabled]="!canEdit"
              [checked]="evidenceType == smartCoachEvidenceTypes.VIDEO"
              (click)="changeEvidenceType(smartCoachEvidenceTypes.VIDEO)" />
            <label for="{{ this.will.id }}-video" class="form-check-label">
              {{ smartCoachEvidenceTypes.VIDEO }}
            </label>
          </div>
          <div class="form-check">
            <input
              type="radio"
              class="form-check-input"
              name="{{ this.will.id }}-btnradio"
              id="{{ this.will.id }}-description"
              autocomplete="off"
              [disabled]="!canEdit"
              [checked]="evidenceType == smartCoachEvidenceTypes.DESCRIPTION"
              (click)="
                changeEvidenceType(smartCoachEvidenceTypes.DESCRIPTION)
              " />
            <label
              for="{{ this.will.id }}-description"
              class="form-check-label">
              {{ smartCoachEvidenceTypes.DESCRIPTION }}
            </label>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <!--RESOURCES-->
    <div class="d-flex justify-content-between align-items-center mt-3">
      <h3 class="fs-14 fw-bold mt-2 d-flex align-items-center">
        <i class="bi bi-tools color-tertiary fs-23 me-2"></i> Resources
      </h3>
      <button
        *ngIf="!logIsSmart && canEdit"
        class="btn btn-reset px-0"
        aria-label="Search for resources"
        ngbTooltip="Search for resources"
        container="body"
        (click)="resourceSearch()">
        <i class="bi bi-search color-tertiary resource-search-btn"></i>
      </button>
    </div>
    <ul *ngIf="resources">
      <li
        class="d-flex justify-content-start"
        *ngFor="let resource of resources">
        <a target="_blank" href="/resources/{{ resource.slug }}">{{
          resource.title
        }}</a>
        <button
          *ngIf="canEdit"
          attr.aria-label="Remove {{ resource.title }}"
          class="btn-delete pe-0">
          <i
            ngbTooltip="Remove resource"
            container="body"
            container="body"
            class="bi bi-x-circle-fill"
            (click)="removeResource(resource.id)"></i>
        </button>
      </li>
    </ul>
    <div
      *ngIf="canEdit"
      class="d-flex justify-content-between align-items-center my-2 fs-14">
      <div>
        <button
          *ngIf="viewingPrevSession"
          [disabled]="
            optionCopied ||
            ((optionCopyText ? false : true) && resources.length === 0)
          "
          ngbTooltip="Option has already been copied"
          [disableTooltip]="!optionCopied"
          style="pointer-events: auto"
          aria-label="copy-to-current-session-button"
          id="copy-option-button"
          (click)="copyOptionToCurrentSession()"
          class="btn btn-secondary btn-sm fs-12 fw-800">
          Copy to Current Session
        </button>
      </div>
      <div ngbTooltip="Delete option" container="body">
        <button
          aria-label="delete option button"
          class="btn btn-delete px-0"
          type="button"
          container="body"
          [autoClose]="'outside'"
          [ngbPopover]="popoverContentTemplate"
          [popoverTitle]="'Delete Option'"
          #popover="ngbPopover">
          <i class="bi bi-trash fs-16"></i>
        </button>
      </div>
      <ng-template #popoverContentTemplate>
        Are you sure you want to delete this option?
        <div class="text-end mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="popover.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="deleteOption(); popover.close()">
            Yes
          </button>
        </div>
      </ng-template>
    </div>
  </div>
  <app-modal
    #optionCopyModal
    [modalConfig]="{
      titleText: 'Option Copied',
      headerClass: ['modal-header', 'bg-primary', 'text-white'],
      windowClass: 'text-center',
      customFooter: true
    }">
    <button
      class="bg-transparent border-0"
      title="Ok"
      aria-label="option-copy-modal-close-button"
      headerContent
      (click)="optionCopyModal.close()">
      <i class="bi bi-x-lg text-white"></i>
    </button>
    <div class="check-icon color-active" style="font-size: 80px">
      <i class="bi bi-check2-circle"></i>
    </div>
    This option and its resources have been copied to the current session
    <div footerContent>
      <button
        (click)="optionCopyModal.close()"
        type="button"
        class="btn btn-md btn-primary">
        OK
      </button>
    </div>
  </app-modal>
</div>
