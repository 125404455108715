import {
  ReportApiResponse,
  ReportDataSeries,
} from 'src/app/common/dtos/reports.dto';
import { ChartType } from '../../../enums/chart-type.enum';

export type DetailedPieData = {
  name: string;
  groupId: string;
  value: number;
  labelId: string;
  color?: string;
};

export const ChartColorsFour = ['#F68888', '#CD87C0', '#6FADCF', '#EDA9CB'];

export const ChartColorsAll = [
  '#430289',
  '#FD1191',
  '#22B0FF',
  '#FCB638',
  '#27004B',
  '#E14F4F',
  '#297CAC',
  '#D95095',
  ...ChartColorsFour,
];

const emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: 'rgba(252, 183, 56, 0.5)',
    shadowOffsetX: 1,
  },
};

const noEmphasisStyle = {
  itemStyle: {
    shadowBlur: 0,
    shadowOffsetX: 0,
  },
};

/**
 *
 * @param dataSet
 * @param response
 * @param chartType
 * @param eChartType
 * @param isAtDeepestDrilldown
 * @param chartColor
 * @param dataId
 * @param emptyDataSet
 * @returns {object}
 * returns a series object
 *
 * Standardizes a series object based on the information passed in.
 *
 * Numeric an alpha-numeric sorting is also applied here
 */
export const generateSeriesOptions = (
  dataSet: ReportDataSeries,
  response: ReportApiResponse,
  chartType: ChartType,
  eChartType: ChartType,
  isAtDeepestDrilldown: boolean,
  chartColor: string,
  dataId: string,
  emptyDataSet = false
) => {
  const series = {
    universalTransition: {
      enabled: true,
      divideShape: 'clone',
    },
    chartType: eChartType,
    emphasis: !isAtDeepestDrilldown ? emphasisStyle : noEmphasisStyle,
    dataGroupId: dataId,
    data: response.labels.map((label: string, index: number) => ({
      groupId: label,
      name: label,
      value: emptyDataSet ? 0 : dataSet.data[index],
      labelId: response.label_ids[index],
    })),
    ...(chartColor && {
      itemStyle: {
        color: chartColor,
      },
    }),
  };
  if (eChartType === ChartType.Pie) {
    series.data = series.data.sort(
      (a, b) => (b.value as number) - (a.value as number)
    );
  }
  return series;
};

/**
 *
 * @param response
 * @param chartType
 * @param eChartType
 * @param isAtDeepestDrilldown
 * @param chartColor
 * @param dataId
 * @param previousDatasetNames
 * @param sortType
 * @returns {object}
 * returns an array of series objects
 *
 * Creates a series array to be consumed by the series
 * attribute in eChart's chartOptions
 */

export const ReportSeriesHelper = (
  response: ReportApiResponse,
  chartType: ChartType,
  eChartType: ChartType,
  isAtDeepestDrilldown: boolean,
  chartColor: string,
  dataId: string,
  previousDatasetNames: string[],
  sortType: '' | 'alpha' | 'reverse-alpha' | 'numeric' = ''
) => {
  const stackedBarSeries =
    chartType === ChartType.HorizontalStackedBar
      ? {
          type: 'bar',
          stack: 'total',
        }
      : {};

  if ((response.datasets[0].data[0] as ReportDataSeries).data) {
    // eslint-disable-next-line
    const seriesReturn: any[] = [];
    (response.datasets[0].data as ReportDataSeries[]).forEach(
      (dataSet: ReportDataSeries) => {
        seriesReturn.push({
          ...stackedBarSeries,
          name: dataSet.name,
          ...generateSeriesOptions(
            dataSet,
            response,
            chartType,
            eChartType,
            isAtDeepestDrilldown,
            chartColor,
            dataId
          ),
        });
      }
    );

    // Checks for missing datasets in stacked bar charts
    if (
      previousDatasetNames &&
      seriesReturn.length < previousDatasetNames.length
    ) {
      previousDatasetNames.forEach((name) => {
        if (seriesReturn.findIndex((series) => name === series.name) < 0) {
          seriesReturn.push({
            ...stackedBarSeries,
            name,
            ...generateSeriesOptions(
              {} as ReportDataSeries,
              response,
              chartType,
              eChartType,
              isAtDeepestDrilldown,
              chartColor,
              dataId,
              true
            ),
          });
        }
      });
    }

    // Order Series Objects by name
    switch (sortType) {
      case 'numeric':
        seriesReturn.sort((a, b) => a.name - b.name);
        break;

      case 'alpha':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        seriesReturn.sort((a: any, b: any) => {
          const nameA = a.name.toString().toLowerCase();
          const nameB = b.name.toString().toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        break;

      case 'reverse-alpha':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        seriesReturn.sort((a: any, b: any) => {
          const nameA = a.name.toString().toLowerCase();
          const nameB = b.name.toString().toLowerCase();

          if (nameB < nameA) {
            return -1;
          }
          if (nameB > nameA) {
            return 1;
          }
          return 0;
        });

        break;

      default:
        break;
    }
    return seriesReturn;
  }
  return [
    {
      ...generateSeriesOptions(
        response.datasets[0] as ReportDataSeries,
        response,
        chartType,
        eChartType,
        isAtDeepestDrilldown,
        chartColor,
        dataId
      ),
      type: eChartType,
      ...(chartType === ChartType.Area && { areaStyle: {} }),
      ...(chartType === ChartType.Donut && {
        radius: ['40%', '70%'],
      }),
    },
  ];
};
