<div class="card border-0 celebrations-card">
  <div class="celebrations-header">
    <h2 class="celebrations-title float-start">Celebrations</h2>
  </div>
  <div class="text-end mb-3">
    <button
      (click)="handleOpenCelebrationModal()"
      class="btn btn-primary btn-sm fs-15">
      <i class="bi bi-plus-lg"></i>
      Create A Celebration
    </button>
  </div>
  <ng-container *ngIf="celebrations; else loading">
    <ng-container *ngIf="celebrations.length > 0; else noCelebrations">
      <div *ngFor="let celebration of celebrations" class="mb-4">
        <ng-container
          *ngIf="celebration.endorserUserId === user.id; else otherUser">
          <div
            *ngIf="celebration.content"
            class="d-flex justify-content-end align-items-center">
            <div
              class="celebrations-container celebrations-text text-bubble me-3 py-2 px-3">
              <app-safehtml [html]="celebration.content"></app-safehtml>
            </div>
            <app-avatar
              avatarSize="small"
              [profile]="celebration.endorser.profile"></app-avatar>
          </div>
        </ng-container>
        <ng-template #otherUser>
          <div
            *ngIf="celebration.content"
            class="d-flex justify-content-start align-items-center">
            <app-avatar
              avatarSize="small"
              [profile]="celebration.endorser.profile"></app-avatar>
            <div
              class="celebrations-container celebrations-text other-text-bubble ms-3 py-2 px-3">
              <app-safehtml [html]="celebration.content"></app-safehtml>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #noCelebrations>
      <div class="text-center py-5 celebrations-text">
        No Recent Celebrations
      </div>
    </ng-template>
    <div routerLink="/celebrations" class="fw-600 celebration-page-link mt-2">
      Go to Celebrations Page
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="w-100 text-center" style="height: 200px">
      <app-loader />
    </div>
  </ng-template>
</div>
