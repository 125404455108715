<div class="container">
  <div class="d-flex justify-content-between mb-3">
    <h1>User Settings</h1>
  </div>
  <div class="card border-0 p-2 table-container">
    <div
      style="max-width: 600px; padding: 30px 0; margin: 0 auto; display: flex">
      <app-avatar
        [avatarSize]="'large'"
        class="mx-3 my-1"
        [profile]="user.profile"></app-avatar>
      <div>
        <h2 class="mb-1">{{ user.fullName }}</h2>
        <div *ngIf="user.district">{{ user.district.title }}</div>
      </div>
    </div>
    <div class="card-body mb-5">
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <div class="container mb-5" style="max-width: 800px; margin: 0 auto">
          <div class="row">
            <div class="col-lg">
              <app-basic-input
                label="First Name"
                [value]="user.profile.first_name"
                (valueChange)="updateValue($event)" />
            </div>
            <div class="col-lg">
              <app-basic-input
                label="Last Name"
                [value]="user.profile.last_name"
                (valueChange)="updateValue($event)" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                label="Email Address"
                [disabled]="true"
                [value]="user.email" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                label="Title"
                [disabled]="true"
                [value]="user.profile.title" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                *ngIf="user.school"
                label="School"
                [disabled]="true"
                [value]="user.school.title" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-basic-input
                *ngIf="user.coach"
                label="Coach"
                [disabled]="true"
                [value]="
                  user.coach.profile.first_name +
                  ' ' +
                  user.coach.profile.last_name
                " />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-end">
              <button
                [disabled]="disableSave"
                class="btn btn-primary"
                (click)="updateUser()">
                Save
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader />
      </ng-template>
    </div>
  </div>
</div>
