import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import { AbstractViewFieldChoicesComponent } from '../base/abstract-view-field-choices';

@Component({
  selector: 'app-form-view-select',
  templateUrl: './view-select.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
})
export class FormViewSelectComponent
  extends AbstractViewFieldChoicesComponent
  implements OnInit
{
  @ViewChild('select') select: NgSelectComponent;

  @Input() initialValue: string | undefined;

  ngOnInit(): void {
    if (this.initialValue) {
      this.selection = this.initialValue;
    }
  }

  changeModel() {
    if (this.inputDisabled) {
      if (this.initialValue) {
        this.selection = this.initialValue;
      } else {
        this.select.handleClearClick();
      }
    } else {
      this.value.emit(this.selection);
    }
  }
}
