<app-report-controller
  #pieChart
  chartId="session-time-frequency"
  [chartType]="donutChartType"
  yAxisLabel="Session frequency"
  [colorPalette]="chartColors"
  [tooltipTrigger]="'item'"
  [limitDataPresentation]="5"
  [requestParams]="{
        dataset: 'fct_coaching_session',
        dimension: 'session_type_name',
        measure: {
            type: 'count',
            field: '*',
        },
        filters: timeFrequencyFilters
    }"
  (chartOptionOutput)="setSessionCounts($event)">
  <app-frequency-table
    [filters]="defaultFilters"
    [colors]="chartColors"
    [externalData]="sessionCounts"></app-frequency-table>
</app-report-controller>
