import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { VideoLibraryService } from '../../services/video-library/video-library.service';

@Component({
  selector: 'app-video-menu-actions',
  templateUrl: './video-menu-actions.component.html',
  styleUrls: ['./video-menu-actions.component.scss'],
})
export class VideoMenuActionsComponent implements OnInit {
  @Input() video: VideoDTO;

  @Input() rectangularVariation: boolean;

  @Output() readonly closeContainingVideo: EventEmitter<void> =
    new EventEmitter();

  iseSuiteAdmin: boolean;

  text: string;

  canEdit = false;

  constructor(
    private clipboard: Clipboard,
    private store: Store,
    private videoService: VideoLibraryService
  ) {}

  ngOnInit(): void {
    const user = this.store.selectSnapshot(UserState.getUser) as User;
    if (compareRoles(user.roles, [UserRole.ESUITE_ADMIN])) {
      this.canEdit = true;
    } else if (user.id === this.video.userId) {
      this.canEdit = true;
    }
  }

  editVideo() {
    this.videoService.triggerVideoEditModal(this.video.id);
    this.closeContainingVideo.emit();
  }

  deleteVideo() {
    this.videoService.deleteVideo(this.video.id).subscribe({
      error: (error) => {
        /* eslint-disable no-console */
        console.log(error);
      },
      next: () => {
        this.closeContainingVideo.emit();
      },
    });
  }

  shareVideo() {
    const text = `${window.location.protocol}//${window.location.host}/resources/video-library?video=${this.video.id}`;
    this.clipboard.copy(text);
  }
}
