import { Injectable, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { StringDictionary } from 'src/app/common/types/types';
import {
  FIVE_MINUTES_IN_SECONDS,
  HOUR_IN_SECONDS,
} from 'src/app/common/utilities/time.definitions';
import { UnleashClient } from 'unleash-proxy-client';
import {
  FEATURE_FLAGS,
  cacheUnleashData,
  unleashStorageKey,
} from './unleash.helpers';

/* eslint-disable @typescript-eslint/no-explicit-any, max-classes-per-file */
export interface UnleashConfig {
  url: string;
  clientKey: string;
  appName: string;
  disableRefresh?: boolean;
  disableMetrics?: boolean;
  refreshInterval?: number; // in seconds (default 30)
  metricsInterval?: number; // in seconds (default 60)
}

const defaultConfig: UnleashConfig = {
  url: EnvironmentService.unleashUrl(),
  clientKey: EnvironmentService.unleashClientKey(),
  appName: 'GroweLabWeb',
  refreshInterval: HOUR_IN_SECONDS,
  metricsInterval: FIVE_MINUTES_IN_SECONDS,
};

@Injectable({ providedIn: 'root' })
export class UnleashService implements OnDestroy {
  cacheData: { [key: string]: boolean } = {};

  user: User;

  instance?: UnleashClient;

  @Output() readonly loaded: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(private readonly store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user) {
      this.loadCacheData();
      this.initialize();
    }
  }

  loadCacheData(): void {
    const cacheData = StorageService.getItem(unleashStorageKey);
    if (cacheData) {
      this.cacheData = JSON.parse(cacheData);
    }
  }

  initialize() {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    if (!this.instance && this.user) {
      this.instance = new UnleashClient(defaultConfig);
      // Set the tenantId context field to the district id
      if (this.user?.district) {
        this.instance.setContextField(
          'tenantId',
          this.user.district.tenantId.toString()
        );
        this.instance.setContextField(
          'districtId',
          this.user.district.id.toString()
        );
      }

      // Set the userType context field to the coachee type
      if (this.user?.coacheeType) {
        this.instance?.setContextField('userType', this.user.coacheeType);
      }

      // start the Unleash client
      this.instance?.start();
      // log available feature flags when ready
      this.instance.on('ready', () => {
        Object.keys(FEATURE_FLAGS).forEach((flagName) => {
          const flagKey = (FEATURE_FLAGS as StringDictionary)[flagName];
          const value = this.instance?.isEnabled(flagKey) || false;
          cacheUnleashData(flagKey, value);
          if (!EnvironmentService.isProduction()) {
            /* eslint-disable-next-line no-console */
            console.log(
              `Unleash flag ${flagName} is ${
                value ? 'enabled for user' : 'disabled for user'
              }`
            );
          }
        });
        if (!this.loaded.getValue()) {
          this.loaded.next(true);
        }
      });
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  isEnabled(featureName: string): boolean {
    this.loadCacheData();
    if (this.cacheData) {
      return typeof this.cacheData[featureName] !== undefined
        ? this.cacheData[featureName]
        : false;
    }

    return false;
  }

  deinitialize(): void {
    if (this.instance) {
      this.instance.stop();
      delete this.instance;
    }
    StorageService.removeItem(unleashStorageKey);
  }

  ngOnDestroy(): void {
    this.deinitialize();
  }
}
