import { StorageService } from 'src/app/common/services/storage/storage.service';

export const sessionTimeoutStorageKey = 'sessionTimeout';

export const sessionAutoLogoutMillis = 25000;

export const shouldTimeoutTrigger = (): boolean => {
  const timeoutTime = StorageService.getItem(sessionTimeoutStorageKey);
  const now = Date.now();
  return timeoutTime && now > timeoutTime - sessionAutoLogoutMillis;
};

export const shouldForceLogout = (): boolean => {
  const timeoutTime = StorageService.getItem(sessionTimeoutStorageKey);
  const now = Date.now();
  return timeoutTime && now > timeoutTime;
};
