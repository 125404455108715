import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CompetencyRequestDTO } from 'src/app/private/shared/dtos/competencies.dto';

@Injectable({
  providedIn: 'root',
})
export class CompetenciesSummaryService {
  constructor(private apiService: APICoreService) {}

  getCurrentCompetencies() {
    return this.apiService.getRequest(
      'growelab/competencies/overview?fromLastLog=true'
    );
  }

  getSingleCompetency(competencyData: CompetencyRequestDTO) {
    return this.apiService.getRequest(
      `egrowe-rubric-builder/${competencyData.rubricId}/standard/${competencyData.standardId}/indicator-set`
    );
  }
}
