import {
  NgbDate,
  NgbDateStruct,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Duration } from 'luxon';
import {
  TimezoneLinkBack,
  getTzRegionFromLinkBack,
  guessTimezone,
} from './time-helpers';

export const dateFromNgbDate = (
  dateStruct: NgbDateStruct | NgbDate,
  endOfDay = false
): Date => {
  const timeStruct = endOfDay
    ? { hour: 23, minute: 59, second: 59 }
    : { hour: 0, minute: 0, second: 0 };
  return DateTime.fromFormat(
    `${dateStruct.month}/${dateStruct.day}/${dateStruct.year}`,
    'M/d/yy'
  )
    .set(timeStruct)
    .toJSDate();
};

export const dateTimeFromNgbDate = (
  dateStruct: NgbDateStruct | NgbDate,
  endOfDay = false
): DateTime => {
  const timeStruct = endOfDay
    ? { hour: 23, minute: 59, second: 59 }
    : { hour: 0, minute: 0, second: 0 };
  return DateTime.fromFormat(
    `${dateStruct.month}/${dateStruct.day}/${dateStruct.year}`,
    'M/d/yy'
  ).set(timeStruct);
};

export const ngbDateStructFromDate = (date: Date): NgbDateStruct => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  day: date.getDate(),
});

export const ngbDateFromDate = (date: Date): NgbDate =>
  new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());

export const ngbDateFromISOString = (isoString: string): NgbDate => {
  const [year, month, day] = isoString.split('T')[0].split('-').map(Number);
  return new NgbDate(year, month, day);
};

export const ngbDateStructFromDateTime = (
  date: DateTime,
  timezone?: TimezoneLinkBack
): NgbDateStruct => {
  if (timezone) {
    const localDate = date.setZone(getTzRegionFromLinkBack(timezone));
    return {
      year: localDate.year,
      month: localDate.month,
      day: localDate.day,
    };
  }
  return ngbDateStructFromDate(date.toJSDate());
};

export const NgbTimeStructFromDateTime = (date: DateTime): NgbTimeStruct => ({
  hour: date.hour,
  minute: date.minute,
  second: date.second,
});

export const ngbDateStructFromUnixTimestamp = (
  timestamp: number
): NgbDateStruct => {
  const date = DateTime.fromSeconds(timestamp).toJSDate();
  return ngbDateStructFromDate(date);
};

export const dateTimeFromNgbDateAndTime = (
  ngbDate: NgbDate | NgbDateStruct,
  time: string,
  timezone: TimezoneLinkBack
): DateTime => {
  const hourMin = time.split(':');
  const dateString = `${ngbDate.year.toString()} ${ngbDate.month.toString()} ${ngbDate.day.toString()} ${
    hourMin[0]
  }:${hourMin[1]}`;
  return DateTime.fromFormat(dateString, 'yyyy L d h:mm', {
    zone: getTzRegionFromLinkBack(timezone),
  });
};
export const fullDurationUnits = [
  'years',
  'months',
  'days',
  'hours',
  'minutes',
  'seconds',
];

export const formatDurationAsString = (
  duration: Duration,
  abbreviated = false
): string => {
  let returnString = '';
  let count = 0;
  while (count !== fullDurationUnits.length) {
    /* eslint-disable-next-line @typescript-eslint/no-loop-func */
    fullDurationUnits.forEach((unit) => {
      /* eslint-disable-next-line no-prototype-builtins */
      if (returnString === '' && duration[unit as keyof Duration]) {
        returnString =
          `${Math.abs(duration[unit as keyof Duration] as number)?.toString()}${
            abbreviated ? unit.charAt(0) : ` ${unit}`
          }` || '';
      }
      count += 1;
    });
  }
  return returnString || 'not very long';
};

export const changeToLocalTimezone = (date: number, logTimezone: string) => {
  const localTimeZone = guessTimezone(new Date());
  const logDate = DateTime.fromSeconds(date);
  const month = logDate.month;
  const day = logDate.day;
  const hour = logDate.hour;
  const minute = logDate.minute;
  const updatedZone = DateTime.fromObject(
    { month, day, hour, minute },
    { zone: logTimezone }
  );
  const convertedDate = updatedZone.setZone(localTimeZone);
  return convertedDate;
};

export const dateFromUnixTimestamp = (date: number) => {
  const selectedDate = new Date(date);

  const year = selectedDate.getFullYear();
  let month: string | number = selectedDate.getMonth() + 1;
  let day: string | number = selectedDate.getDate();

  // add zero to month and day if single digit
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};
