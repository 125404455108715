<ul
  *ngIf="districtCode"
  class="nav nav-tabs justify-content-center d-none d-md-flex mb-3">
  <li class="nav-item">
    <a
      *ngIf="
        user.roles.includes(Roles.E2L_EMPLOYEE) ||
        user.roles.includes(Roles.OPERATOR) ||
        user.roles.includes(Roles.REMOTE_OPERATOR)
      "
      class="nav-link"
      title="Districts"
      href="javascript:void(0)"
      [routerLink]="'/settings'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Districts
    </a>
  </li>
  <li class="nav-item">
    <a
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      class="nav-link"
      title="Properties"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Properties
    </a>
  </li>
  <li class="nav-item">
    <a
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      class="nav-link"
      title="Rosters"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/rosters'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Rosters
    </a>
  </li>
  <li class="nav-item">
    <a
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      class="nav-link"
      title="Cohorts"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/cohorts'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Cohorts
    </a>
  </li>
  <li class="nav-item">
    <a
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      class="nav-link"
      title="Configuration"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/configuration'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Configuration
    </a>
  </li>
  <li class="nav-item" *ngIf="competencyBuilderEnabled">
    <a
      *ngIf="
        user.roles.includes(Roles.E2L_EMPLOYEE) &&
        user.roles.includes(Roles.OPERATOR)
      "
      class="nav-link"
      title="Topics"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/topics'"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Topics
    </a>
  </li>
  <li class="nav-item" *ngIf="competencyBuilderEnabled">
    <a
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      class="nav-link"
      title="Competencies"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/competencies'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Competencies
    </a>
  </li>
  <li class="nav-item">
    <a
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      class="nav-link"
      title="Form Templates"
      href="javascript:void(0)"
      [routerLink]="'/settings/districts/' + districtCode + '/forms'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Forms
    </a>
  </li>
</ul>

<!-- MOBILE NAV -->
<div
  *ngIf="districtCode"
  class="custom-select d-md-none"
  [class.open]="customCollapsed"
  (click)="toggleCustomCollapsed()">
  <div class="custom-select__trigger">
    <div>{{ selectedPath }}</div>
    <i *ngIf="!customCollapsed" class="bi bi-caret-up-fill"></i>
    <i *ngIf="customCollapsed" class="bi bi-caret-down-fill"></i>
  </div>
  <ul class="custom-options">
    <li
      *ngIf="
        user.roles.includes(Roles.E2L_EMPLOYEE) ||
        user.roles.includes(Roles.OPERATOR)
      "
      [routerLink]="'/settings'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option">
      Districts
    </li>
    <li
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      [routerLink]="'/settings/districts/' + districtCode"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Properties">
      Properties
    </li>
    <li
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      [routerLink]="'/settings/districts/' + districtCode + '/rosters'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Rosters">
      Rosters
    </li>
    <li
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      [routerLink]="'/settings/districts/' + districtCode + '/cohorts'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Cohorts">
      Cohorts
    </li>
    <li
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      [routerLink]="'/settings/districts/' + districtCode + '/configuration'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Configuration">
      Configuration
    </li>
    <li
      *ngIf="
        (user.roles.includes(Roles.OPERATOR) && competencyBuilderEnabled) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn' &&
          competencyBuilderEnabled)
      "
      [routerLink]="'/settings/districts/' + districtCode + '/topics'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Topics">
      Topics
    </li>
    <li
      *ngIf="
        (user.roles.includes(Roles.OPERATOR) && competencyBuilderEnabled) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn' &&
          competencyBuilderEnabled)
      "
      [routerLink]="'/settings/districts/' + districtCode + '/competencies'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Competencies">
      Competencies
    </li>
    <li
      *ngIf="
        user.roles.includes(Roles.OPERATOR) ||
        (user.roles.includes(Roles.REMOTE_OPERATOR) &&
          districtCode != 'engage2learn')
      "
      [routerLink]="'/settings/districts/' + districtCode + '/forms'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Forms">
      Forms
    </li>
  </ul>
</div>
