<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<div style="clear: both; height: 4px"></div>
<input
  type="text"
  class="form-control"
  id="fromDateInput"
  aria-label="From Date"
  [disabled]="disableTextInput"
  [style.width]="'125px'"
  [style.display]="'inline-block'"
  (click)="dateOpen = !dateOpen"
  [value]="displayDateFrom"
  [ngModel]="displayDateFrom"
  (ngModelChange)="onDateFromChange($event)" />
&nbsp; - &nbsp;
<input
  type="text"
  class="form-control"
  id="toDateInput"
  aria-label="To Date"
  [disabled]="disableTextInput"
  [style.width]="'125px'"
  [style.display]="'inline-block'"
  (click)="dateOpen = !dateOpen"
  [value]="displayDateTo"
  [ngModel]="displayDateTo"
  (ngModelChange)="onDateToChange($event)" />
<a
  href="javascript:void(0)"
  title="Clear dates"
  class="color-primary m-2"
  *ngIf="disableTextInput && (this.displayDateFrom || this.displayDateTo)"
  (click)="clearDates()">
  <i class="bi bi-x-circle" aria-hidden="true"></i
></a>
<div>
  <ng-container *ngIf="dateOpen || disableTextInput">
    <div style="clear: both; height: 8px"></div>
    <ngb-datepicker
      #dp
      [minDate]="minStruct"
      [maxDate]="maxStruct"
      [firstDayOfWeek]="0"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="2"
      [dayTemplate]="t"
      [style.width]="'100%'"
      outsideDays="hidden">
    </ngb-datepicker>
  </ng-container>
</div>

<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
