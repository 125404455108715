<ng-container *ngIf="session">
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-3">
        <h3>Session Coach</h3>
        <div class="d-flex flex-nowrap align-items-center mt-3 mb-4">
          <app-avatar [profile]="session.user.profile" [avatarSize]="'medium'">
          </app-avatar>
          <div class="fs-6 fw-light ms-2">
            {{ session.user.profile.first_name }}
            {{ session.user.profile.last_name }}
          </div>
        </div>
        <h3>Coachee(s)</h3>
        <ng-container *ngFor="let attendee of session.attendees">
          <div class="d-flex flex-nowrap align-items-center my-3">
            <app-avatar
              [profile]="attendee.user.profile"
              [avatarSize]="'medium'">
            </app-avatar>
            <div class="fs-6 fw-light ms-2">
              {{ attendee.user.profile.first_name }}
              {{ attendee.user.profile.last_name }}
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-md-6">
        <h3>Session Summary</h3>
        <ng-container *ngFor="let attendee of session.attendees">
          <ng-container *ngIf="attendee.present">
            <div class="mt-3 mb-5">
              <h4
                *ngIf="session.attendees.length > 1"
                class="fs-5 fw-bolder text-primary bg-light rounded p-2 mt-3">
                {{ attendee.user.profile.first_name }}
                {{ attendee.user.profile.last_name }}
              </h4>
              <h5>Badges Earned</h5>
              <ng-container
                *ngIf="
                  getAttendeeBadgeUser(attendee.user) as badgeUser;
                  else noBadges
                ">
                <div class="badge-wrapper mb-3 d-flex flex-wrap">
                  <ng-container *ngFor="let badge of badgeUser.badges">
                    <app-badge-graphic [badge]="badge" size="sm" />
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #noBadges>
                <p class="fw-light text-body-secondary">
                  No badges were earned for this session.
                </p>
              </ng-template>
              <h5>Competencies</h5>
              <ng-container
                *ngFor="let rubric of getAttendeeRubrics(attendee.user.id)">
                <ng-container
                  *ngFor="let competencyGroup of rubric.competencyGroups">
                  <ng-container
                    *ngFor="let competency of competencyGroup.competencies">
                    <div class="my-2">
                      <div ngbAccordion>
                        <div
                          ngbAccordionItem
                          [collapsed]="false"
                          class="accordion">
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>
                              {{ competency.title }}
                            </button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>
                                <div class="accordion-content">
                                  <hr
                                    class="border border-secondary opacity-50 my-1" />
                                  <ng-container
                                    *ngFor="
                                      let strand of competency.indicatorSet
                                        .strands
                                    ">
                                    <div class="my-2">
                                      <p class="fw-bold mb-1">
                                        {{ strand.title }}:
                                        <span
                                          class="text-level-{{
                                            strand.goalLevel
                                          }}"
                                          >Level {{ strand.goalLevel }}</span
                                        >
                                      </p>
                                      <div class="w-75">
                                        <app-quartile-progress-bar
                                          description="{{
                                            attendee.user.profile.first_name
                                          }} {{
                                            attendee.user.profile.last_name
                                          }}'s progress on {{ strand.title }}"
                                          [progress]="getStrandProgress(strand)"
                                          [goalLevel]="
                                            strand.goalLevel
                                          "></app-quartile-progress-bar>
                                      </div>
                                      <ng-container
                                        *ngFor="let level of getLevels(strand)">
                                        <ng-container
                                          *ngFor="let option of level.options">
                                          <ul>
                                            <li class="d-flex">
                                              <span
                                                class="fw-lighter w-75 pe-2">
                                                {{ option.content }}
                                              </span>
                                              <ng-container *ngIf="option.will">
                                                <span
                                                  class="fw-bold float-end"
                                                  >{{
                                                    option.will.dueDate
                                                      | formatDateMMDDYY
                                                  }}</span
                                                >
                                              </ng-container>
                                            </li>
                                          </ul>
                                        </ng-container>
                                      </ng-container>
                                    </div>
                                  </ng-container>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-md-3">
        <h3>Next Session</h3>
        <ng-container *ngIf="!scheduledSession; else scheduledSessionInfo">
          <ng-container>
            <form
              (ngSubmit)="scheduleNextSession()"
              [formGroup]="nextSessionForm"
              id="schedule-next-session-form">
              <div class="mb-3">
                <label class="form-label fw-bolder">Date & Time:</label>
                <app-datepicker
                  (dateSelectedUnixTimestamp)="updateDateTime($event)"
                  [initialDateUnixTimestamp]="nextDateTime"
                  [minDate]="nextDate"></app-datepicker>
              </div>
              <span
                [ngbTooltip]="
                  disableNextSessionButton ? 'Please select a valid date' : ''
                ">
                <button
                  type="submit"
                  class="btn btn-primary px-4 fw-bold"
                  [disabled]="
                    logPermissions !== Permissions.EDIT ||
                    nextSessionFormSubmitLoading ||
                    disableNextSessionButton
                  ">
                  <span
                    *ngIf="nextSessionFormSubmitLoading"
                    class="spinner-border spinner-border-sm me-1"></span>
                  Submit
                </button>
              </span>
            </form>
          </ng-container>
        </ng-container>
        <ng-template #scheduledSessionInfo>
          <div id="smart-next-session-title" class="d-flex">
            <a
              title="{{ scheduledSession.title }}"
              routerLink="/smart-coach/log/{{ scheduledSession.logId }}/info"
              aria-current="page"
              target="_blank">
              <p class="fw-bold mb-2">
                {{ scheduledSession.title }}&nbsp;
                <i class="bi bi-box-arrow-up-right"></i>
              </p>
            </a>
          </div>
          <p class="mb-1">{{ scheduledSession.coachlogType }}</p>
          <p class="mb-1">
            {{ scheduledSession.startDatetime | formatDate }}
          </p>
        </ng-template>
        <h3 class="mt-3">Feedback</h3>
        <ng-container
          *ngIf="
            session.currentUsersRole != 'coach' &&
              session.currentUsersRole != 'editor';
            else coachFeedbackView
          ">
          <ng-container *ngIf="feedback.length > 0; else addFeedback">
            <ng-container *ngFor="let feedbackItem of feedback">
              <div class="mb-3">
                <div class="d-flex flex-nowrap align-items-center mb-2">
                  <app-avatar
                    [profile]="feedbackItem.user.profile"
                    [avatarSize]="'small'">
                  </app-avatar>
                  <p class="ms-2 mb-0">
                    {{ feedbackItem.user.profile.first_name }}
                    {{ feedbackItem.user.profile.last_name }}
                  </p>
                  <div class="ms-auto">
                    <button
                      class="bg-transparent border-0 p-0 me-3"
                      title="Edit Feedback"
                      aria-label="Edit feedback"
                      (click)="toggleEditMode(feedbackItem.content)">
                      <i class="bi bi-pencil-fill"></i>
                    </button>
                    <button
                      class="bg-transparent border-0 p-0"
                      title="Delete Feedback"
                      aria-label="Delete feedback"
                      (click)="deleteFeedbackModal.open()">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
                <app-modal
                  #deleteFeedbackModal
                  [modalConfig]="{
                    titleText: 'Delete Feedback',
                    headerClass: ['modal-header'],
                  }"
                  (isClosing)="deleteFeedback(feedbackItem.id)">
                  <button
                    class="bg-transparent border-0"
                    title="Close"
                    aria-label="close"
                    headerContent
                    (click)="deleteFeedbackModal.dismiss()">
                    <i class="bi bi-x-lg"></i>
                  </button>
                  Are you sure you want to delete this feedback item?
                </app-modal>
                <ng-container *ngIf="isEditingFeedback; else feedbackContent">
                  <form
                    (ngSubmit)="editFeedback(feedbackItem.id)"
                    [formGroup]="editFeedbackForm">
                    <textarea
                      formControlName="feedback"
                      class="form-control mb-3"
                      [ngClass]="{
                        'is-invalid':
                          editFeedbackFormSubmitted &&
                          this.editFeedbackForm.controls['feedback'].errors
                      }"
                      id="feedback"
                      aria-label="feedback"
                      rows="8">
                    </textarea>
                    <button
                      type="submit"
                      class="btn btn-primary px-4 fw-bold"
                      [disabled]="editFeedbackFormSubmitLoading">
                      <span
                        *ngIf="editFeedbackFormSubmitLoading"
                        class="spinner-border spinner-border-sm me-1"></span>
                      Submit
                    </button>
                  </form>
                </ng-container>
                <ng-template #feedbackContent>
                  {{ feedbackItem.content }}
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #addFeedback>
            <p>
              What feedback do you have for your coach and/or what did you find
              most valuable about your session today?
            </p>
            <form (ngSubmit)="submitFeedback()" [formGroup]="feedbackForm">
              <textarea
                formControlName="feedback"
                class="form-control mb-3"
                [ngClass]="{
                  'is-invalid':
                    feedbackFormSubmitted &&
                    this.feedbackForm.controls['feedback'].errors
                }"
                id="feedback"
                aria-label="feedback"
                placeholder="Leave feedback for your coach"
                rows="4"></textarea>
              <button
                type="submit"
                class="btn btn-primary px-4 fw-bold"
                [disabled]="feedbackFormSubmitLoading">
                <span
                  *ngIf="feedbackFormSubmitLoading"
                  class="spinner-border spinner-border-sm me-1"></span>
                Submit
              </button>
            </form>
          </ng-template>
        </ng-container>
        <ng-template #coachFeedbackView>
          <ng-container *ngIf="feedback.length > 0; else noFeedback">
            <ng-container *ngFor="let feedbackItem of feedback">
              <div class="mb-3">
                <div class="d-flex flex-nowrap align-items-center mb-2">
                  <app-avatar
                    [profile]="feedbackItem.user.profile"
                    [avatarSize]="'small'">
                  </app-avatar>
                  <p class="ms-2 mb-0">
                    {{ feedbackItem.user.profile.first_name }}
                    {{ feedbackItem.user.profile.last_name }}
                  </p>
                </div>
                {{ feedbackItem.content }}
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noFeedback>
            <p class="fw-light text-body-secondary">
              No feedback has been added for this session.
            </p>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
