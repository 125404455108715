<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="row datePickerRow noTop gy-3">
    <label class="form-label">Date Range</label>
    <app-daterange-picker
      [fromDate]="currentFilters.fromDate"
      [toDate]="currentFilters.toDate"
      (selectedFromDate)="this.currentFilters.fromDate = $event"
      (selectedToDate)="
        this.currentFilters.toDate = $event
      "></app-daterange-picker>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">District</label>
      <app-district-select
        #districtSelect
        [clearAfterSelection]="false"
        (selectedDistrict)="updateDistrict($event)" />
    </div>
  </div>
  <div *ngIf="currentFilters.district" class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">School</label>
      <app-school-select
        #schoolSelect
        [clearAfterSelection]="false"
        [assignedCoacheesSchoolsOnly]="!canSeeAllSchools"
        [districtId]="[currentFilters.district.id]"
        (selectedSchool)="updateSchool($event)" />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Coachees</label>
      <app-user-select
        *ngIf="currentFilters.district"
        #coacheeSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [districtId]="currentFilters.district.id"
        [defaultUsers]="currentFilters.coacheeList"
        (selectedUserList)="updateCoachees($event)" />
      <app-user-select
        *ngIf="!currentFilters.district"
        #coacheeSelect
        [clearAfterSelection]="false"
        [isMulti]="true"
        [defaultUsers]="currentFilters.coacheeList"
        (selectedUserList)="updateCoachees($event)" />
    </div>
  </div>
  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">Has Attachments</label>
      <div class="form-check">
        <input
          [(ngModel)]="currentFilters.hasAttachment"
          type="checkbox"
          class="form-check-input"
          name="hasAttachmentsCheckbox"
          id="celebration-attachment-checkbox" />
        <label class="form-check-label" for="hasAttachmentsCheckbox">
          Yes
        </label>
      </div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn btn-reset" (click)="clearFilters()">
        Clear All
      </button>
      <button type="button" class="btn btn-primary" (click)="closeModal(true)">
        Apply
      </button>
    </div>
  </div>
</app-modal>
