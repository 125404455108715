import { Injectable } from '@angular/core';
import {
  catchError,
  debounceTime,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
} from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CompetencySearchResponse } from 'src/app/common/types/responses/responses';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import {
  CompetencyLeveledResourcesDto,
  ResourceLeveledCompetenciesDto,
} from '../../dtos/leveled-resource.dto';

@Injectable({
  providedIn: 'root',
})
export class CompetenciesSelectService {
  items: Observable<CompetencyDTO[]>;

  currentResponse: CompetencySearchResponse;

  districtIds: number[];

  public competencyLeveledResources: ReplaySubject<CompetencyLeveledResourcesDto> =
    new ReplaySubject();

  public resourceLeveledCompetencies: ReplaySubject<
    ResourceLeveledCompetenciesDto[]
  > = new ReplaySubject();

  private term: Subject<string> = new Subject<string>();

  constructor(private apiService: APICoreService) {
    this.items = this.term.pipe(
      debounceTime(250),

      switchMap((term: string) =>
        this.findCompetencies(term, undefined, undefined, this.districtIds)
      ),
      shareReplay(1)
    );
  }

  search(term: string, districtIds?: number[]) {
    /**
     * Set the value which the search stream will work.
     */
    this.districtIds = districtIds || [0];
    return this.term.next(term);
  }

  complete(): void {
    this.term.complete();
  }

  public findCompetencies(
    searchTerm = '',
    per_page = 20,
    page = 1,
    district_ids = [0]
  ): Observable<CompetencyDTO[]> {
    const searchQuery = searchTerm ? `&search=${searchTerm}` : '';
    let districtQuery = '';
    if (district_ids.length > 1) {
      district_ids.forEach((id) => {
        districtQuery += `district_id=${id}&`;
      });
    } else {
      districtQuery = `district_id=${district_ids[0]}&`;
    }
    return this.apiService
      .getRequest(
        `egrowe/standards?${districtQuery}page=${page}&per_page=${per_page}${searchQuery}`
      )
      .pipe(
        map((response) => {
          this.currentResponse = {
            competencies: response.items,
            _meta: response._meta,
            _links: response._links,
          };
          return response.items;
        })
      );
  }

  getCompetency(id: number): Observable<CompetencyDTO | null> {
    return this.apiService
      .getRequest(`egrowe/standards/${id}`)
      .pipe(map((response) => response.item));
  }

  getLeveledCompetenciesOnResource(resourceId: number, districtId = 0) {
    return this.apiService
      .getRequest(
        `elearn/leveled-competencies/resource/${resourceId}?district_id=${districtId}`
      )
      .pipe(
        map((response) => {
          this.resourceLeveledCompetencies.next(response.items);
        })
      );
  }

  getLeveledResourcesOnCompetency(competencyId: number) {
    return this.apiService
      .getRequest(`elearn/leveled-resources/standard/${competencyId}`)
      .pipe(
        map((response) => {
          this.competencyLeveledResources.next(response.item);
          return response.item;
        })
      );
  }

  addCompetencyLevelToResource(
    resourceId: number,
    competencyId: number,
    level: number
  ) {
    const params: { level: number } = {
      level,
    };
    return this.apiService
      .postRequest(
        `elearn/leveled-resources/standard/${competencyId}/resource/${resourceId}`,
        params
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((res: any) => res),
        catchError((err) => {
          throw err;
        })
      );
  }

  removeCompetencyFromResource(resourceId: number, competencyId: number) {
    return this.apiService
      .deleteRequest(
        `elearn/leveled-resources/standard/${competencyId}/resource/${resourceId}`
      )
      .pipe(
        map((res) => res),
        catchError((err) => {
          throw err;
        })
      );
  }
}
