<app-modal
  #schoolEditModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'Edit School',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="schoolForm">
    <form (ngSubmit)="editSchool()" [formGroup]="schoolForm">
      <div class="row">
        <div class="col">
          <label for="school-name" class="form-label">School Name</label>
          <div class="input-group">
            <input
              formControlName="title"
              type="text"
              class="form-control"
              id="school-name"
              aria-label="School Name"
              [ngClass]="{
                'is-invalid': formSubmitted && f['title'].errors
              }" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="address-1" class="form-label">Address 1</label>
          <div class="input-group">
            <input
              formControlName="address"
              type="text"
              class="form-control"
              id="address-1"
              aria-label="Address 1" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="address-2" class="form-label">Address 2</label>
          <div class="input-group">
            <input
              formControlName="address2"
              type="text"
              class="form-control"
              id="address-2"
              aria-label="Address 2" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="city" class="form-label">City</label>
          <div class="input-group">
            <input
              formControlName="city"
              type="text"
              class="form-control"
              id="city"
              aria-label="City" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="state" class="form-label">State</label>
          <div class="input-group">
            <input
              formControlName="state"
              type="text"
              class="form-control"
              id="state"
              aria-label="State" />
          </div>
        </div>
        <div class="col">
          <label for="zip" class="form-label">Zip Code</label>
          <div class="input-group">
            <input
              formControlName="zip"
              type="text"
              class="form-control"
              id="zip"
              aria-label="Zip" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="phone" class="form-label">Phone</label>
          <div class="input-group">
            <input
              formControlName="phone"
              type="text"
              class="form-control"
              id="phone"
              aria-label="Phone" />
          </div>
        </div>
      </div>

      <div footerContent>
        <div *ngIf="serverError" class="mt-3">
          <ngb-alert [dismissible]="false" [type]="'danger'">
            <strong>Error</strong> {{ serverError }}
          </ngb-alert>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4">
          <button
            aria-label="delete button"
            class="btn btn-delete"
            type="button"
            [autoClose]="'outside'"
            [ngbPopover]="deletePopoverContent"
            [popoverTitle]="'Delete School'"
            #popover="ngbPopover">
            Delete
          </button>
          <ng-template #deletePopoverContent>
            Permanently delete this school?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="popover.close(); deleteSchool(schoolId)">
                Yes
              </button>
            </div>
          </ng-template>
          <button
            [disabled]="isSubmitting"
            type="submit"
            class="btn btn-md btn-primary">
            <ng-container *ngIf="isSubmitting">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </ng-container>
            Save
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</app-modal>
