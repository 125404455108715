import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfettiService {
  public confettiType: BehaviorSubject<string> = new BehaviorSubject('');

  public showConfettiNotifier: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  showConfetti(type = '') {
    this.confettiType.next(type);
    this.showConfettiNotifier.next(true);
    setTimeout(() => {
      this.hideConfetti();
    }, 5000);
  }

  hideConfetti() {
    this.showConfettiNotifier.next(false);
  }
}
