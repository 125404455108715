<div class="container">
  <app-general-header title="Resources" />
  <div class="row my-4 justify-content-center">
    <div class="col-xl-8">
      <div
        class="fw-600 fs-18 rounded p-3 bg-secondary text-center justify-content-center align-items-center d-flex flex-column flex-md-row">
        <div class="mb-2 mb-md-0">You are currently viewing&nbsp;</div>
        <div class="d-flex align-items-center">
          <span class="mx-2">
            <app-avatar
              [hideTooltip]="true"
              [profile]="user.profile"
              [avatarSize]="'small'"
              [profileLinkActive]="false"
          /></span>
          {{ user.profile.first_name }}
          {{ user.profile.last_name }}'s Folder
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0 p-3">
    <app-resources-subnav />
    <div class="card-body">
      <div class="d-flex mt-5 mb-4 align-items-center">
        <app-avatar
          [hideTooltip]="true"
          [profile]="user.profile"
          [avatarSize]="'x-large'"
          [profileLinkActive]="false" />
        <h2 class="fs-25 fw-800 ms-2">
          {{ user.profile.first_name }} {{ user.profile.last_name }}'s Saved
          Resources
        </h2>
      </div>

      <ng-container *ngIf="folders; else loadingTemplate">
        <ng-container *ngIf="folders && folders.length > 0">
          @for (folder of folders; track folder) {
          <app-folder-item
            [folder]="folder"
            [sharedFolder]="true"
            [multiFolders]="false"
            (folderClicked)="selectFolder(folder.id)"></app-folder-item>
          }
        </ng-container>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100 text-center" style="height: 400px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
