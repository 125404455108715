<ng-container *ngIf="folder">
  <div
    class="folder-item d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
    <div class="detail ps-2 pe-4">
      @if(sharedFolder) {
      <div
        (click)="folderClickedHandler()"
        class="d-flex align-items-center folder-title">
        <i class="bi bi-folder me-2"></i>
        {{ folder.title }}
      </div>
      } @else {
      <div
        (click)="folderClickedHandler()"
        routerLink="/resources/my/folder/{{ folder.id }}"
        class="d-flex align-items-center folder-title">
        <i class="bi bi-folder me-2"></i>
        {{ folder.title }}
      </div>
      }

      <div *ngIf="folder.description" class="folder-description py-1 fs-13">
        {{ folder.description }}
      </div>
    </div>
    <div class="resources ms-2 ms-md-auto pe-2 fs-14">
      <ng-container *ngIf="folder.numbShelves && folder.numbShelves > 0">
        {{ folder.numbShelves }}
        <ng-container [ngPlural]="folder.numbShelves">
          <ng-template ngPluralCase="=1">Folder</ng-template>
          <ng-template ngPluralCase="other">Folders</ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="folder.numbShelves > 0 && folder.numbItems > 0">
        ,<br class="break" />
      </ng-container>
      <ng-container *ngIf="folder.numbItems && folder.numbItems > 0">
        {{ folder.numbItems }}
        <ng-container [ngPlural]="folder.numbItems">
          <ng-template ngPluralCase="=1">Resource</ng-template>
          <ng-template ngPluralCase="other">Resources</ng-template>
        </ng-container>
      </ng-container>
    </div>
    <div class="actions d-flex">
      @if(!sharedFolder) {
      <div aria-label="favorite" role="button" class="p-1 mx-2">
        <i
          [ngClass]="folder.usersFavorite ? 'bi bi-star-fill' : 'bi bi-star'"
          (click)="starClickedHandler()"></i>
      </div>
      <div aria-label="share" role="button" ngbTooltip="Share" class="p-1">
        <i class="bi bi-share mx-2" (click)="copyClickedHandler()"></i>
      </div>
      }
    </div>
  </div>
</ng-container>
