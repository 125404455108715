import { Component, Input } from '@angular/core';
import { EvidenceProgressDTO } from '../../../dtos/portfolio.dto';

@Component({
  selector: 'app-evidence-progress-bar',
  templateUrl: './evidence-progress-bar.component.html',
  styleUrls: ['./evidence-progress-bar.component.scss'],
})
export class EvidenceProgressBarComponent {
  @Input() progressData: EvidenceProgressDTO;
}
