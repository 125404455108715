<div class="container">
  <ngb-alert
    class="copied-success"
    #copiedAlert
    *ngIf="copiedStatus"
    type="success"
    >Copied to Clipboard
  </ngb-alert>
  <div class="my-4 d-flex justify-content-between">
    <div class="fs-17">Resources you have created or saved appear here.</div>
    <button
      aria-label="share"
      class="btn btn-link d-flex p-1"
      style="white-space: nowrap"
      (click)="copyPageToClipboard()">
      <i class="bi bi-share me-2"></i>Share Resources
    </button>
  </div>
  <ng-container *ngIf="canCreate">
    <ng-container *ngIf="createdByYouFolder">
      <div class="folder-section mb-3">
        <div class="folder-item d-flex align-items-center">
          <div
            class="d-flex align-items-center folder-title ps-2"
            routerLink="/resources/my/folder/created-by-you">
            <i class="bi bi-folder me-1"></i>
            {{ createdByYouFolder.title }}
          </div>
          <div class="resources">
            <ng-container
              *ngIf="
                createdByYouFolder.numbItems && createdByYouFolder.numbItems > 0
              ">
              {{ createdByYouFolder.numbItems }} Resources
            </ng-container>
          </div>
          <div class="actions"></div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div
    class="resource-header d-flex align-items-bottom justify-content-between mt-5 mb-3">
    <div class="section-head">Saved Resources</div>
    <button class="btn btn-link p-1" (click)="createFolderModal.open()">
      <i class="bi bi-folder-plus me-2"></i>Create a New Folder
    </button>
  </div>
  <ng-container
    *ngIf="myStarredFolders?.length === 0 && myFolders?.length === 0">
    No folders have been created
  </ng-container>
  <!-- STARRED SHELVES -->
  <ng-container *ngIf="myStarredFolders">
    <div class="shelves">
      <div *ngFor="let folder of myStarredFolders">
        <app-folder-item
          [folder]="folder"
          [multiFolders]="myStarredFolders.length > 1"
          (copyClicked)="copyToClipboard(folder.id)"
          (starClicked)="
            starSelect(folder.id, folder.usersFavorite, myStarredFolders)
          "></app-folder-item>
      </div>
    </div>
    <div
      *ngIf="myStarredFolders.length > 0"
      class="app-hr-thin"
      style="margin: 25px 0"></div>
  </ng-container>

  <!-- NON-STARRED SHELVES -->
  <ng-container *ngIf="myFolders; else loading">
    <div cdkDropList class="shelves">
      <div *ngFor="let folder of myFolders">
        <app-folder-item
          [folder]="folder"
          [multiFolders]="myFolders.length > 1"
          (copyClicked)="copyToClipboard(folder.id)"
          (starClicked)="
            starSelect(folder.id, folder.usersFavorite, myFolders)
          "></app-folder-item>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="w-100 text-center" style="height: 60vh">
      <app-loader />
    </div>
  </ng-template>

  <app-modal
    #createFolderModal
    [modalConfig]="{
      titleText: 'Create a New Folder',
      headerClass: ['modal-header', 'bg-primary', 'text-white'],
      customFooter: true
    }"
    (isClosing)="clearFields()"
    (isDismissing)="clearFields()">
    <button
      class="bg-transparent border-0"
      title="Close"
      aria-label="close"
      headerContent
      (click)="createFolderModal.close()">
      <i class="bi bi-x-lg text-white"></i>
    </button>
    <label for="folder-title-input" class="form-label">Title</label>
    <input
      class="form-control mb-3"
      aria-label="title"
      id="folder-title-input"
      placeholder="Enter Folder Title"
      [(ngModel)]="newFolder.title"
      required />
    <label for="folder-description-input" class="form-label">Description</label>
    <textarea
      class="form-control"
      id="folder-description-input"
      aria-label="description"
      [(ngModel)]="newFolder.description"
      rows="4"
      placeholder="Enter a description for this folder"></textarea>
    <div footerContent>
      <div class="d-flex justify-content-between">
        <button class="btn" (click)="createFolderModal.close()">Cancel</button>
        <button
          class="btn btn-primary"
          [disabled]="newFolder.title.length < 1"
          (click)="createFolder(); createFolderModal.close()">
          Create
        </button>
      </div>
    </div>
  </app-modal>
</div>
