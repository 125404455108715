import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {
  DistrictGoalItemDTO,
  DistrictSimpleDto,
} from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolDTO, SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { v4 as uuidv4 } from 'uuid';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { GoalType } from '../../shared/enums/goal-type.enum';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { UnleashService } from '../../shared/services/unleash/unleash.service';
import { ReportingState } from '../../shared/state/reporting/reporting.state';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss'],
})
export class ReportingPageComponent implements OnInit, OnDestroy {
  user: User;

  isE2L = false;

  canFilterByDistrict = false;

  labelId = uuidv4();

  availableSchoolYears: string[] = [];

  schoolYearName: string;

  schoolYearItem: SchoolYearDTO;

  schoolYearId = 0;

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictSimpleDto | null;

  districtMissingYear = false;

  loadedDistrictInfo = false;

  isMultiDistrict = false;

  isLoadingGoals = false;

  isCollapsed = false;

  sessionsPerCoacheeGoal: number | null;

  attendancePercentageGoal: number | null;

  observationsPerCoacheeGoal: number | null;

  celebrationsPerUserGoal: number | null;

  badgesPerCoacheePerSessionGoal: number | null;

  competencyBadgesPercentageGoal: number | null;

  schoolFilter: KpiReportFilter = {
    codename: 'school',
    title: 'School',
    value: [''],
    displayName: 'School',
  };

  strandFilter: KpiReportFilter = {
    codename: 'is_strand',
    value: ['1'],
  };

  microcredentialFilter: KpiReportFilter = {
    codename: 'is_competency',
    value: ['1'],
  };

  yearToDateSessionParams: ReportRequestParams;

  yearToDateObservationParams: ReportRequestParams;

  yearToDateStrandBadgeParams: ReportRequestParams;

  yearToDateMicrocredentialParams: ReportRequestParams;

  yearToDateGIParams: ReportRequestParams;

  yearToDateCelebrationParams: ReportRequestParams;

  yearToDateCampusParams: ReportRequestParams;

  peopleCoachedParams: ReportRequestParams;

  otherSupportSessionsParams: ReportRequestParams;

  defaultGoals = [
    { goal: GoalType.CoachingSessionsPerCoacheePerYear, value: 7 },
    { goal: GoalType.AttendeesPresent, value: 90 },
    { goal: GoalType.ObservationsPerCoacheePerYear, value: 5 },
    { goal: GoalType.CelebrationsPerUserPerYear, value: 2 },
    { goal: GoalType.BadgesPerCoacheePerSession, value: 1 },
    { goal: GoalType.CoacheesEarnCompetencyBadges, value: 90 },
  ];

  chartTypes = ChartType;

  defaultFilters: KpiReportFilter[];

  filtersWithSessionType: KpiReportFilter[];

  filtersWithCompetency: KpiReportFilter[];

  filtersSet = false;

  _subs: Subscription[] = [];

  lastUpdatedDate: string;

  constructor(
    private store: Store,
    private reportFiltersService: ReportPageFiltersService,
    private apiCoreService: APICoreService,
    protected featureFlagService: UnleashService
  ) {
    const updatedDate$ = this.store.select(ReportingState.getUpdatedDate);
    this._subs.push(
      updatedDate$.subscribe((date) => {
        this.lastUpdatedDate = date;
      })
    );
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
    this.canFilterByDistrict = this.isE2L || checkIfB2b(this.user);
  }

  ngOnInit(): void {
    this._subs.push(
      this.reportFiltersService.filters.subscribe((filters) => {
        if (filters.schoolYearFilter) {
          this.filtersSet = false;
          this.schoolYearId = filters.schoolYearFilter.id;
          this.defaultFilters = translateKpiReportFilterFromFilterDTO(filters);
          this.filtersWithSessionType = [
            ...this.defaultFilters,
            {
              codename: 'is_coaching',
              value: ['1'],
            },
            {
              codename: 'is_present',
              value: ['1'],
            },
          ];
          this.filtersWithCompetency = [
            ...this.defaultFilters,
            {
              codename: 'is_competency',
              value: ['1'],
            },
          ];

          const districtFilter = this.filtersWithSessionType.find(
            (obj) => obj.codename === 'district'
          ) as KpiReportFilter;

          if (districtFilter.value.length > 1) {
            this.isMultiDistrict = true;
            this.unsetDistrictGoals();
          } else {
            this.isMultiDistrict = false;
            this.setDistrictGoals(Number(districtFilter.value[0]));
          }

          this.setQuickStatRequests();

          setTimeout(() => {
            this.filtersSet = true;
          }, 0);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  unsetDistrictGoals() {
    this.attendancePercentageGoal = null;
    this.sessionsPerCoacheeGoal = null;
    this.observationsPerCoacheeGoal = null;
    this.celebrationsPerUserGoal = null;
    this.badgesPerCoacheePerSessionGoal = null;
    this.competencyBadgesPercentageGoal = null;
  }

  setDistrictGoals(districtId: number) {
    this.isLoadingGoals = true;
    this.apiCoreService
      .getRequest(`growelab/district-goals/for-district/${districtId}`)
      .subscribe((results) => {
        this.sessionsPerCoacheeGoal = this.findDistrictGoal(
          GoalType.CoachingSessionsPerCoacheePerYear,
          results.items
        );

        this.attendancePercentageGoal =
          this.findDistrictGoal(GoalType.AttendeesPresent, results.items) * 100;

        this.observationsPerCoacheeGoal = this.findDistrictGoal(
          GoalType.ObservationsPerCoacheePerYear,
          results.items
        );

        this.celebrationsPerUserGoal = this.findDistrictGoal(
          GoalType.CelebrationsPerUserPerYear,
          results.items
        );

        this.badgesPerCoacheePerSessionGoal = this.findDistrictGoal(
          GoalType.BadgesPerCoacheePerSession,
          results.items
        );

        this.competencyBadgesPercentageGoal =
          this.findDistrictGoal(
            GoalType.CoacheesEarnCompetencyBadges,
            results.items
          ) * 100;

        this.isLoadingGoals = false;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  findDistrictGoal(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[]
  ): number {
    const goal = goalItems.find(
      (goalItem) => goalItem.goal.title === goalTitle
    );
    if (goal) {
      return Number(goal.value);
    }
    return this.defaultGoals.find((goalObj) => goalObj.goal === goalTitle)
      ?.value as number;
  }

  setQuickStatRequests() {
    this.yearToDateSessionParams = {
      id: 'glance-coaching-conversations',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters],
    };

    this.yearToDateObservationParams = {
      id: 'glance-observations',
      dataset: 'fct_form_submission',
      measure: {
        type: 'count',
        field: '',
      },
      filters: [...this.defaultFilters],
    };

    this.yearToDateStrandBadgeParams = {
      id: 'glance-strand-badges-earned',
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters, this.strandFilter],
    };

    this.yearToDateMicrocredentialParams = {
      id: 'glance-microcredentials-earned',
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters, this.microcredentialFilter],
    };

    this.yearToDateGIParams = {
      id: 'glance-gis-earned',
      dataset: 'fct_indicator_assessment',
      measure: {
        type: 'count',
        field: '',
      },
      filters: [
        ...this.defaultFilters,
        { codename: 'assessment_type_id', value: ['9'] },
      ],
    };

    this.yearToDateCelebrationParams = {
      id: 'glance-celebrations',
      dataset: 'fct_user_celebration',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [...this.defaultFilters],
    };

    this.yearToDateCampusParams = {
      id: 'glance-schools-coached',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'school_id',
      },
      filters: [...this.defaultFilters],
    };

    this.peopleCoachedParams = {
      id: 'glance-staff-coached',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'user_id',
      },
      filters: [...this.defaultFilters],
    };

    this.otherSupportSessionsParams = {
      id: 'glance-other-support-sessions',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [
        ...this.defaultFilters,
        {
          codename: 'is_coaching',
          value: ['0'],
        },
        {
          codename: 'is_observation',
          value: ['0'],
        },
        {
          codename: 'is_present',
          value: ['1'],
        },
      ],
    };
  }
}
