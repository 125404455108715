import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-safehtml',
  templateUrl: './safehtml.component.html',
  styleUrls: ['./safehtml.component.scss'],
})
export class SafehtmlComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() removeMargin: boolean;

  @Input() html: string;

  safehtml: SafeHtml;

  @Input() classList: string[];

  parsedClass = '';

  @Input() openLinksInSameTab: boolean;

  @ViewChild('section') section: ElementRef;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.makeSafe();
  }

  ngOnChanges() {
    this.makeSafe();
  }

  ngAfterViewInit() {
    if (!this.openLinksInSameTab) {
      const links = this.section.nativeElement.querySelectorAll('a');
      if (links) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        links.forEach((link: any) => {
          link.setAttribute('target', '_blank');
        });
      }
    }
  }

  makeSafe() {
    if (this.html == null) {
      this.html = '';
    }
    this.safehtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
    if (this.classList) {
      this.parsedClass = [...new Set(this.classList)].join(' ');
    }
  }
}
