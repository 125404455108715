<div class="login">
  <div class="login__form">
    <main role="main" class="form-new-password w-100">
      <img
        id="groweLabLogo"
        src="assets/logo_horiz_dark.png"
        class="mb-4 w-100"
        alt="GroweLab Logo"
        @delayfadeInOut />
      <h1 class="h2 mb-0 fw-bold" @fadeInOut>Change Password</h1>

      <ng-container *ngIf="resetToken; else invalidToken">
        <form [formGroup]="newPasswordForm" (ngSubmit)="changePassword()">
          <div class="row mt-2">
            <div class="col">
              <app-new-password
                formControlName="password"
                [formSubmitted]="submitted"></app-new-password>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <label for="password" class="form-label">Confirm Password</label>
              <div class="input-group">
                <input
                  formControlName="confirmPassword"
                  [type]="showPassword ? 'text' : 'password'"
                  class="form-control"
                  id="confirm-password"
                  aria-label="Confirm Password"
                  [ngClass]="{
                    'is-invalid': submitted && newPasswordForm.errors
                  }" />
                <span class="input-group-text">
                  <i
                    class="cursor-pointer bi"
                    [ngClass]="{
                      'bi-eye': showPassword,
                      'bi-eye-slash': !showPassword
                    }"
                    (click)="showPassword = !showPassword"></i>
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="submitted && newPasswordForm.errors" class="row mt-2">
            <div class="col">
              <p style="margin: 0">
                <i class="bi bi-x-circle color-danger"></i>
                Passwords do not match
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <button
                [disabled]="loading"
                type="submit"
                class="btn btn-lg btn-primary mt-3">
                <ng-container *ngIf="loading">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </ng-container>
                Save Password & Login
              </button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-template #invalidToken>
        <h5 class="color-danger mt-3">
          The password reset token is missing or invalid
        </h5>
      </ng-template>
    </main>
  </div>
</div>
