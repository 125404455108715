<button
  ngbTooltip="View fullscreen"
  aria-label="View fullscreen"
  (click)="showFullscreen()"
  class="btn btn-link color-tertiary p-2 position-absolute top-0 end-0"
  style="z-index: 2">
  <i class="bi bi-fullscreen"></i>
</button>

<div *ngIf="!isFullscreen; else hidden">
  <ng-container
    *ngTemplateOutlet="report; context: { $implicit: cardHeight }" />
</div>

<app-modal
  #fullscreenModal
  (isDismissing)="closeFullscreen()"
  [modalConfig]="{
    headerClass: ['visually-hidden'],
    xCloseButton: true,
    showFooter: false,
    options: { size: 'xl', centered: true }
  }">
  <div *ngIf="isFullscreen">
    <ng-container
      *ngTemplateOutlet="
        report;
        context: { $implicit: fullScreenCardHeight }
      " />
    <ng-content></ng-content>
  </div>
</app-modal>

<ng-template #report let-cardHeight>
  <div
    echarts
    [title]="reportTitle"
    role="figure"
    class="dashboard-chart has-data-{{ hasData }}"
    [ngStyle]="{ height: cardHeight, width: '100%' }"
    [options]="chartOptions"
    (chartInit)="onChartInit($event)"
    [loading]="isLoading"
    [loadingOpts]="spinnerOptions"
    (chartClick)="handleClick($event)"></div>
  <ng-content></ng-content>
</ng-template>

<ng-template #hidden>
  <div
    class="text-body-secondary bg-light d-flex justify-content-center align-items-center rounded"
    [ngStyle]="{ height: cardHeight, width: '100%' }">
    Chart is fullscreen
  </div>
</ng-template>
