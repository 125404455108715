<div class="competency-level">
  <div class="card h-100 border-0 shadow" *ngIf="levelData">
    <div
      class="d-flex"
      [ngClass]="[
        'bg-level-' + level,
        'card-header',
        'text-light',
        'text-end',
        'text-nowrap',
        'align-middle'
      ]">
      <ng-container *ngIf="video">
        <button
          [attr.data-testid]="
            'strand-' + strand.id + '-level-' + level + '-play-button'
          "
          (click)="startVideo()"
          class="btn btn-reset text-white p-0">
          <i class="bi bi-play-circle-fill"></i>
        </button>
        <app-video-modal
          *ngIf="videoPlaying"
          (videoClosing)="stopVideo()"
          [video]="video"
          [autoOpen]="true"
          [showVideoInfo]="false" />
      </ng-container>
      <div class="ms-auto">
        Level
        <i class="bi bi-{{ level }}-circle"></i>
      </div>
    </div>
    <div class="card-body p-2" tabindex="0">
      <ng-container *ngFor="let indicator of levelData.indicators">
        <app-growth-indicator
          [disabled]="giDisabled"
          [indicatorId]="indicator.id"
          [userId]="userId"
          [logId]="logId"
          [rubricId]="rubricId"
          [viewingPrevSession]="viewingPrevSession"></app-growth-indicator>
      </ng-container>
    </div>
    <div *ngIf="showOptions" class="card-footer d-flex justify-content-end">
      <ng-container *ngIf="!isSmart; else smartTemplate">
        <button (click)="createOption()" class="btn btn-primary btn-sm fw-800">
          <i class="bi bi-plus"></i>
          Create An Option
        </button>
      </ng-container>
      <ng-template #smartTemplate>
        <button
          *ngIf="canEdit === Permissions.EDIT"
          (click)="addOption()"
          style="border-color: transparent"
          class="btn btn-primary btn-sm fw-800"
          [class.bg-level-1]="level == 1"
          [class.bg-level-2]="level == 2"
          [class.bg-level-3]="level == 3"
          [class.bg-level-4]="level == 4">
          <i class="bi bi-plus"></i>
          Add an Option
        </button>
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="optionsForChildComponents.length > 0; else noOptions">
    <ng-container
      *ngFor="
        let option of optionsForChildComponents;
        let isLast = last;
        let i = index
      ">
      <div [ngStyle]="{ 'z-index': -i, position: 'relative' }">
        <app-leveled-competency-strand-level-options
          [userId]="userId"
          [canEdit]="canEdit === Permissions.EDIT"
          [rubricId]="rubricId"
          [competencyId]="competencyId"
          [coachlogId]="logId"
          [level]="level"
          [levelData]="levelData"
          [strandId]="strand.id"
          [details]="option"
          [viewingPrevSession]="viewingPrevSession"
          [isDrawerCollapsed]="isLast ? isOptionCollapsed : true">
        </app-leveled-competency-strand-level-options>
      </div>
      <ng-container *ngIf="isLast">
        <div [ngStyle]="{ 'z-index': -i - 1, position: 'relative' }">
          <app-leveled-competency-strand-level-evidence
            [isSmart]="isSmart"
            [coachUser]="isSmart && sessionData ? sessionData.user : undefined"
            [userId]="userId"
            [rubricId]="rubricId"
            [canCreateEvidence]="canEdit === Permissions.EDIT"
            [viewOnly]="canEdit === Permissions.VIEW"
            [competencyId]="competencyId"
            [coachlogId]="logId"
            [competencyTitle]="competencyTitle"
            [level]="level"
            [levelData]="levelData"
            [strandId]="strand.id"
            [strandTitle]="strand.title"
            [viewingPrevSession]="
              viewingPrevSession
            "></app-leveled-competency-strand-level-evidence>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noOptions>
    <div [ngStyle]="{ 'z-index': -1, position: 'relative' }">
      <app-leveled-competency-strand-level-evidence
        [isSmart]="isSmart"
        [coachUser]="isSmart && sessionData ? sessionData.user : undefined"
        [userId]="userId"
        [rubricId]="rubricId"
        [canCreateEvidence]="canEdit === Permissions.EDIT"
        [viewOnly]="canEdit === Permissions.VIEW"
        [competencyId]="competencyId"
        [competencyTitle]="competencyTitle"
        [coachlogId]="logId"
        [level]="level"
        [levelData]="levelData"
        [strandId]="strand.id"
        [strandTitle]="strand.title"
        [viewingPrevSession]="
          viewingPrevSession
        "></app-leveled-competency-strand-level-evidence>
    </div>
  </ng-template>
</div>
