<ng-container *ngIf="evidence">
  <div
    class="d-flex justify-content-between align-items-center py-1 evidence-item">
    <div>
      <app-evidence-summary
        [evidenceId]="evidence.id"
        (evidenceAssesed)="handleAssessment($event)"
        ><span class="fw-bold evidence-title">{{
          evidence.title
        }}</span></app-evidence-summary
      >
      <div class="evidence-date">{{ dateAdded | formatDate }}</div>
    </div>
    <div class="ms-auto px-1">
      <app-avatar [profile]="profile" avatarSize="small"></app-avatar>
    </div>
    <div class="user-name text-nowrap">
      {{ profile.first_name + " " + profile.last_name }}
    </div>
  </div>
</ng-container>
