import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { CompetencyListItemDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { CompetencyFiltersDTO } from '../../dtos/competencies.dto';

@Injectable({
  providedIn: 'root',
})
export class CompetenciesSearchService {
  // search filters
  private searchFiltersData: CompetencyFiltersDTO | null = null;

  private searchFiltersSource = new BehaviorSubject(this.searchFiltersData);

  public searchFiltersObs = this.searchFiltersSource.asObservable();

  // search results
  private searchResultsData: CompetencyListItemDTO[] | null = null;

  private searchResultsSource = new BehaviorSubject(this.searchResultsData);

  public searchResultsObs = this.searchResultsSource.asObservable();

  // search results meta
  private searchResultsMetaData: PaginationDTO | null = null;

  private searchResultsMetaSource = new BehaviorSubject(
    this.searchResultsMetaData
  );

  public searchResultsMetaObs = this.searchResultsMetaSource.asObservable();

  constructor(private apicoreService: APICoreService) {}

  performSearch() {
    let filterFreeze: CompetencyFiltersDTO = {};

    if (this.searchFiltersSource?.value) {
      filterFreeze = JSON.parse(JSON.stringify(this.searchFiltersSource.value));
    }
    return this.apicoreService
      .getRequest('growelab/competencies', filterFreeze)
      .pipe(
        map((results) => {
          let competencyList: CompetencyListItemDTO[] = [];
          if (results.items) {
            competencyList = results.items;
          } else {
            competencyList = [];
          }
          // we populate observables, as well as return the competencyList.
          this.searchResultsSource.next(competencyList);
          this.searchResultsMetaSource.next(results._meta);
          return competencyList;
        })
      );
  }

  getCompetencyOverview() {
    let filterFreeze: CompetencyFiltersDTO = {};

    if (this.searchFiltersSource?.value) {
      filterFreeze = JSON.parse(JSON.stringify(this.searchFiltersSource.value));
    }
    return this.apicoreService
      .getRequest('growelab/competencies/overview', filterFreeze)
      .pipe(
        map((results) => {
          let competencyList: [];
          if (results.items.competencies) {
            competencyList = results.items.competencies;
          } else {
            competencyList = [];
          }
          // we populate observables, as well as return the competencyList.
          this.searchResultsSource.next(competencyList);
          this.searchResultsMetaSource.next(results._meta);
          return competencyList;
        })
      );
  }

  setFilters(filters: CompetencyFiltersDTO) {
    this.searchFiltersSource.next(filters);
  }

  resetFilters() {
    const emptyFilters = {};
    this.searchFiltersSource.next(emptyFilters);
  }

  getLast5() {
    return this.apicoreService
      .getRequest('growelab/competencies/last5')
      .pipe(map((results) => results));
  }
}
