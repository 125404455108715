<div class="competency-level">
  <div class="card h-100 border-0 shadow" *ngIf="levelData">
    <div
      class="d-flex"
      [ngClass]="[
        'bg-level-' + level,
        'card-header',
        'text-light',
        'text-end',
        'text-nowrap',
        'align-middle'
      ]">
      <ng-container *ngIf="video">
        <button
          [attr.data-testid]="
            'strand-' + strand.id + '-level-' + level + '-play-button'
          "
          (click)="videoModal.open()"
          class="btn btn-reset text-white p-0">
          <i class="bi bi-play-circle-fill"></i>
        </button>
        <app-video-modal #videoModal [video]="video" [showVideoInfo]="false" />
      </ng-container>
      <div class="ms-auto">
        Level
        <i class="bi bi-{{ level }}-circle"></i>
      </div>
    </div>
    <div class="card-body p-2" tabindex="0">
      <ng-container *ngFor="let indicator of levelData.indicators">
        <app-growth-indicator
          [portfolioIndicator]="indicator"
          [userId]="userId"
          [rubricId]="rubricId"
          [disabled]="giDisabled"></app-growth-indicator>
      </ng-container>
    </div>
  </div>
  <div [ngStyle]="{ 'z-index': -1, position: 'relative' }">
    <app-portfolio-leveled-competency-strand-level-evidence
      [userId]="userId"
      [rubricId]="rubricId"
      [competency]="competency"
      [competencyTitle]="competencyTitle"
      [level]="level"
      [levelData]="levelData"
      [strand]="strand"
      [evidenceList]="
        levelData.evidence
      "></app-portfolio-leveled-competency-strand-level-evidence>
  </div>
</div>
