import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { Form } from 'src/app/private/shared/dtos/forms.dto';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

import { ModalComponent } from '../../../modals/modal/modal.component';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
})
export class FormModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  @Input() form: Form;

  @Input() formId: number;

  constructor(private formService: FormsService) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formService.getForm(this.formId).subscribe((form) => {
        take(1);
        if (form) {
          this.form = form;
        }
      });
    }
  }
}
