import { Component, Input, OnInit } from '@angular/core';
import { DefaultGenerics } from 'getstream';
import { DateTime } from 'luxon';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import {
  ActivityFeedService,
  FeedName,
} from '../../../services/activity-feed/activity-feed.service';

export type CustomActivity<
  StreamFeedGenerics extends DefaultGenerics = DefaultGenerics
> = StreamFeedGenerics['activityType'] & {
  actor_profile: {
    color: string;
    first_name: string;
    last_name: string;
    photo: string;
    title: string;
    user_id: number;
  };
  icon: string;
  message: string;
  time: number;
};

@Component({
  selector: 'app-card-activity-feed',
  templateUrl: './card-activity-feed.component.html',
  styleUrls: ['./card-activity-feed.component.scss'],
})
export class CardActivityFeedComponent implements OnInit {
  @Input() activityStreamToken: string | undefined;

  @Input() districtId: number | undefined;

  @Input() planId: number | undefined;

  @Input() districtTimezone: string | undefined;

  isCollapsed = this.cardStorageService.getCollapsedState('activityfeed');

  isLoaded = false;

  errorMessage: string;

  activityFeedItemsThisWeek: CustomActivity<DefaultGenerics>[] = [];

  activityFeedItemsEarlier: CustomActivity<DefaultGenerics>[] = [];

  constructor(
    private cardStorageService: CardStorageService,
    private activityFeedService: ActivityFeedService
  ) {}

  ngOnInit(): void {
    if (this.activityStreamToken && this.districtTimezone) {
      let feedType = FeedName.DISTRICT;
      let feedId = 0;

      if (this.districtId) {
        feedType = FeedName.DISTRICT;
        feedId = this.districtId;
      }
      if (this.planId) {
        feedType = FeedName.PLAN;
        feedId = this.planId;
      }

      const feed = this.activityFeedService.getActivityFeed(
        this.activityStreamToken,
        feedType,
        feedId.toString()
      );

      feed
        .get({ limit: 20, enrich: false })
        .then((response) => {
          const currentTime = DateTime.now().setZone(this.districtTimezone);
          const beginningOfWeek = currentTime
            .startOf('week')
            .startOf('day')
            .toMillis();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          response.results.forEach((activity: any) => {
            const activityTime = DateTime.fromISO(activity.time, {
              zone: 'UTC',
            })
              .setZone(this.districtTimezone)
              .toMillis();
            if (activityTime >= beginningOfWeek) {
              this.activityFeedItemsThisWeek.push(
                this.formatActivity(activity)
              );
            } else {
              this.activityFeedItemsEarlier.push(this.formatActivity(activity));
            }
          });
          this.isLoaded = true;
        })
        .catch(() => {
          this.errorMessage = 'There was an error loading activities';
          this.isLoaded = true;
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatActivity(activity: any): CustomActivity<DefaultGenerics> {
    return {
      ...activity,
      actor_profile: {
        color: activity.actor_profile?.color || '',
        first_name: activity.actor_profile?.first_name || '',
        last_name: activity.actor_profile?.last_name || '',
        photo: activity.actor_profile?.photo || '',
        title: activity.actor_profile?.title || '',
        user_id: parseInt(activity.actor_profile?.user_id) || '',
      },
      time: DateTime.fromISO(activity.time, { zone: 'UTC' })
        .setZone(this.districtTimezone)
        .toSeconds(),
      icon: activity.icon || '',
      message: activity.message || '',
    };
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'activityfeed',
      this.isCollapsed
    );
  }
}
