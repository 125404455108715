import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { v4 as uuidv4 } from 'uuid';
import { SchoolYearService } from '../../services/school-year/school-year.service';
import { CURRENT_SCHOOL_YEAR } from '../../services/school-year/school-year.utilities';

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
})
export class YearSelectComponent implements OnInit {
  @ViewChild('select') select: NgSelectComponent;

  @Input() isMulti = false;

  @Input() clearAfterSelection = false;

  @Input() defaultYear: SchoolYearDTO | string | null;

  @Input() defaultYears: SchoolYearDTO[] | null;

  @Input() placeholder = 'Select a year for reporting district...';

  @Output() readonly selectedYearList: EventEmitter<SchoolYearDTO[]> =
    new EventEmitter();

  @Output() readonly selectedYear: EventEmitter<SchoolYearDTO | null> =
    new EventEmitter();

  @Input() label = 'Select a year';

  labelId = uuidv4();

  yearList: SchoolYearDTO[] = [];

  internalSelectedYearList: SchoolYearDTO[] = [];

  internalSelectedYear: SchoolYearDTO | null;

  constructor(private schoolYearService: SchoolYearService) {}

  ngOnInit(): void {
    this.schoolYearService.schoolYears.subscribe((years: SchoolYearDTO[]) => {
      this.yearList = years;
      const currentYear = years.find(
        (year) => year.id === CURRENT_SCHOOL_YEAR.id
      ) as SchoolYearDTO;

      if (this.isMulti) {
        if (this.defaultYears) {
          this.internalSelectedYearList = this.defaultYears;
        } else {
          this.internalSelectedYearList = [currentYear];
        }
      } else if (typeof this.defaultYear === 'string') {
        this.internalSelectedYear = years.find(
          (year) => year.name === this.defaultYear
        ) as SchoolYearDTO;
      } else if (this.defaultYear) {
        this.internalSelectedYear = years.find(
          (year) => year.id === (this.defaultYear as SchoolYearDTO).id
        ) as SchoolYearDTO;
      } else {
        this.internalSelectedYear = currentYear;
      }
    });
  }

  yearSelected(year: SchoolYearDTO) {
    if (this.isMulti && year !== undefined) {
      if (!this.internalSelectedYearList.some((item) => item.id === year.id)) {
        this.internalSelectedYearList.push(year);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedYear = year;
    }
    this.outputSchoolYears();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputSchoolYears() {
    if (this.isMulti) {
      this.selectedYearList.emit(this.internalSelectedYearList);
    } else {
      this.selectedYear.emit(this.internalSelectedYear);
    }
  }

  removeYearFromList(year: SchoolYearDTO) {
    this.internalSelectedYearList = this.internalSelectedYearList.filter(
      (item) => item.id !== year.id
    );
    this.outputSchoolYears();
  }
}
