<div class="container">
  <app-general-header title="District Settings" />
  <div class="card border-0 p-3 table-container">
    <app-settings-nav
      *ngIf="user && user.district"
      [districtCode]="user.district.districtCode"></app-settings-nav>

    <div class="text-end mt-3">
      <button
        *ngIf="user.roles.includes(Roles.E2L_EMPLOYEE)"
        routerLink="new-district"
        type="button"
        class="btn btn-primary">
        <i class="bi bi-plus me-1"></i>Add a District
      </button>
    </div>
    <div class="card-body">
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <table *ngIf="tableData" class="table">
          <thead>
            <tr>
              <th scope="col" (sort)="onSort($event)" sortable="id">
                ID
                <i class="bi bi-caret-down {{ sortIconTemp.id }}"></i>
              </th>
              <th scope="col" (sort)="onSort($event)" sortable="title">
                District Name
                <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
              </th>
              <th scope="col" (sort)="onSort($event)" sortable="districtCode">
                Slug
                <i class="bi bi-caret-down {{ sortIconTemp.districtCode }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="state"
                class="hide-on-mobile">
                State
                <i class="bi bi-caret-down {{ sortIconTemp.state }}"></i>
              </th>
              <th
                scope="col"
                (sort)="onSort($event)"
                sortable="timezone"
                class="hide-on-mobile">
                Timezone
                <i class="bi bi-caret-down {{ sortIconTemp.timezone }}"></i>
              </th>
              <!-- <th
                scope="col"
                (sort)="onSort($event)"
                sortable="domain"
                class="hide-on-mobile">
                Domain
                <i class="bi bi-caret-down {{ sortIconTemp.domain }}"></i>
              </th> -->
              <th
                *ngIf="user.roles.includes(Roles.E2L_EMPLOYEE)"
                scope="col"
                (sort)="onSort($event)"
                sortable="hubspotCompanyId"
                class="hide-on-mobile">
                CRM ID
                <i
                  class="bi bi-caret-down {{
                    sortIconTemp.hubspotCompanyId
                  }}"></i>
              </th>
              <th class="hide-on-mobile">B2B</th>
              <th
                *ngIf="user.roles.includes(Roles.E2L_EMPLOYEE)"
                class="hide-on-mobile">
                e2L
              </th>
            </tr>
          </thead>
          <tbody
            *ngIf="
              user.roles.includes(Roles.E2L_EMPLOYEE) ||
                user.roles.includes(Roles.REMOTE_OPERATOR);
              else operator
            ">
            <tr
              *ngFor="let district of tableData"
              routerLink="districts/{{ district.districtCode }}"
              class="cursor-pointer">
              <td>{{ district.id }}</td>
              <td>{{ district.title }}</td>
              <td>{{ district.districtCode }}</td>
              <td class="hide-on-mobile">{{ district.state || "-" }}</td>
              <td class="hide-on-mobile">{{ district.timezone || "-" }}</td>
              <!-- <td class="hide-on-mobile">{{ district.domain }}</td> -->
              <td
                class="hide-on-mobile"
                *ngIf="user.roles.includes(Roles.E2L_EMPLOYEE)">
                {{ district.hubspotCompanyId || "-" }}
              </td>
              <td class="hide-on-mobile">
                <ng-container
                  *ngIf="
                    district.isB2b === 1;
                    then check;
                    else x
                  "></ng-container>
              </td>
              <td
                class="hide-on-mobile"
                *ngIf="user.roles.includes(Roles.E2L_EMPLOYEE)">
                <ng-container
                  *ngIf="
                    district.isInternal === 1;
                    then check;
                    else x
                  "></ng-container>
              </td>
            </tr>
          </tbody>
          <ng-template #operator>
            <tbody>
              <tr *ngFor="let district of tableData">
                <td>{{ district.id }}</td>
                <td>{{ district.title }}</td>
                <td>{{ district.districtCode }}</td>
                <td class="hide-on-mobile">{{ district.state || "-" }}</td>
                <td class="hide-on-mobile">{{ district.timezone || "-" }}</td>
                <!-- <td class="hide-on-mobile">{{ district.domain }}</td> -->
                <td class="hide-on-mobile">
                  <ng-container
                    *ngIf="
                      district.isB2b === 1;
                      then check;
                      else x
                    "></ng-container>
                </td>
              </tr>
            </tbody>
          </ng-template>
        </table>
      </ng-container>
      <ng-template #loadingTemplate>
        <app-loader />
      </ng-template>
    </div>
  </div>
</div>

<ng-template #check><i class="bi bi-check"></i></ng-template>
<ng-template #x><i class="bi bi-dash color-secondary"></i></ng-template>
