<app-modal #internalModal [modalConfig]="modalConfig">
  <button
    (click)="this.internalModal.dismiss()"
    class="btn-exit btn tertiary fs-19">
    X
  </button>
  <ng-container *ngIf="badgeData">
    <div class="modal-body">
      <div class="d-flex align-items-center justify-content-center mb-1">
        <img src="/assets/celebrate.png" alt="Celebrate" width="60" />
        <h2 class="fw-bold fs-1 ms-4">Cue the Confetti!</h2>
      </div>
      <p class="fw-lighter fs-4 text-center">
        Shine the spotlight, toot your horn, drop the mic. <br />
        {{ badgeData.userName }} is growing!
      </p>
    </div>
    <div class="row mb-4 px-5">
      <ng-container *ngFor="let badge of badgeData.badges">
        <div class="col" *ngIf="badge.type">
          <app-badge-graphic [badge]="badge" />
          <div class="text-center mt-3">
            <!-- <button aria-label="Download badge" class="btn-share me-4">
              <i class="bi bi-download fs-3"></i>
            </button> -->
            <button
              title="Share on Twitter"
              class="btn-share"
              (click)="shareOnTwitter(badgeData.userName, badge.title)">
              <i class="bi bi-twitter fs-3"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-modal>
