<div class="container container-main">
  <app-dashboard-header
    *ngIf="user"
    [dashboardType]="user.type"
    [showSwitcher]="false"></app-dashboard-header>
  <div class="card border-0 p-3 mt-3 overflow-y">
    <div
      class="tool position-absolute top-0 start-100 translate-middle rounded-circle fs-3"
      style="background-color: white; line-height: 1rem">
      <i
        [style.cursor]="'pointer'"
        routerLink="/dashboard"
        ngbTooltip="Close"
        class="bi bi-x-circle color-primary"></i>
    </div>
    <div
      class="table table-toolbar d-flex justify-content-between align-items-center">
      <div class="coachee-search table-search">
        <input
          type="search"
          class="form-control ms-1"
          placeholder="Filter evidence"
          aria-label="Search"
          (input)="search($event)" />
      </div>
    </div>
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th
              style="width: 200px"
              scope="col"
              (sort)="onSort($event)"
              sortable="sort_user_name">
              Coachee
              <i class="bi bi-caret-down {{ sortIconTemp.sort_user_name }}"></i>
            </th>
            <th
              style="width: 200px"
              scope="col"
              (sort)="onSort($event)"
              sortable="sort_evidence_title">
              Evidence
              <i
                class="bi bi-caret-down {{
                  sortIconTemp.sort_evidence_title
                }}"></i>
            </th>
            <th
              style="width: 250px"
              scope="col"
              (sort)="onSort($event)"
              sortable="sort_competency_name"
              class="hide-on-mobile">
              Competency
              <i
                class="bi bi-caret-down {{
                  sortIconTemp.sort_competency_name
                }}"></i>
            </th>
            <th
              style="width: 100px"
              scope="col"
              (sort)="onSort($event)"
              sortable="sort_level"
              class="text-center text-nowrap hide-on-mobile">
              Level
              <i class="bi bi-caret-down {{ sortIconTemp.sort_level }}"></i>
            </th>
            <th
              style="width: 100px"
              scope="col"
              (sort)="onSort($event)"
              sortable="sort_date"
              class="text-center text-nowrap">
              Date
              <i class="bi bi-caret-down {{ sortIconTemp.sort_date }}"></i>
            </th>
            <th
              style="width: 100px"
              scope="col"
              (sort)="onSort($event)"
              sortable="sort_is_reviewed"
              class="text-center text-nowrap hide-on-mobile">
              Reviewed
              <i
                class="bi bi-caret-down {{
                  sortIconTemp.sort_is_reviewed
                }}"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="!isLoading; else loadingTemplate">
            <ng-container *ngIf="tableData.length > 0; else noEvidence">
              <tr *ngFor="let evidence of tableData">
                <td>
                  <div class="d-flex flex-nowrap align-items-center my-2">
                    <app-avatar
                      [profile]="evidence.coachee.profile"
                      [avatarSize]="'small'">
                    </app-avatar>
                    <div class="ms-2">
                      {{ evidence.coachee.profile.first_name }}
                      {{ evidence.coachee.profile.last_name }}
                    </div>
                  </div>
                </td>
                <td class="align-middle fw-bold">
                  <app-evidence-summary
                    [evidenceId]="evidence.id"
                    [autoOpenModal]="evidence.autoOpenModal"
                    (evidenceAssesed)="updateReviewedStatus($event)"
                    >{{ evidence.evidence }}</app-evidence-summary
                  >
                </td>
                <td class="align-middle fw-light hide-on-mobile">
                  {{ evidence.competency }}
                </td>
                <td
                  class="align-middle text-center fw-bold fs-5 hide-on-mobile">
                  {{ evidence.level }}
                </td>
                <td class="align-middle text-center fw-bold fs-5">
                  {{ evidence.dateUploaded | formatDateMMDDYY }}
                </td>
                <td class="align-middle fs-5 text-center hide-on-mobile">
                  <ng-container
                    *ngIf="
                      evidence.reviewed;
                      then checked;
                      else unchecked
                    "></ng-container>
                </td>
              </tr>
            </ng-container>
            <ng-template #noEvidence>
              <tr class="border-bottom-0">
                <td></td>
                <td></td>
                <td>
                  <p class="text-body-secondary my-4">No evidence to review.</p>
                </td>
              </tr>
            </ng-template>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="listMeta && listMeta.totalCount > 12" class="row mt-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination
          [(page)]="listMeta.currentPage"
          aria-label="coachee-evidence-pagination"
          [pageSize]="listMeta.perPage"
          [collectionSize]="listMeta.totalCount"
          (pageChange)="
            getAllCoacheeEvidenceList(this.searchTerm, listMeta.currentPage)
          "
          [maxSize]="5"
          [ellipses]="true"></ngb-pagination>
      </div>
    </div>

    <ng-template #loadingTemplate>
      <app-loader></app-loader>
    </ng-template>
  </div>
</div>

<ng-template #checked
  ><i class="bi bi-check2-circle text-info"></i
></ng-template>
<ng-template #unchecked><i class="bi bi-circle-fill"></i></ng-template>
