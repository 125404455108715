import { Injectable } from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  userIdProfiles: {
    [user_id: number]: ProfileDTO;
  } = {};

  setUserIdProfile(user_id: number, profile: ProfileDTO) {
    this.userIdProfiles[user_id] = profile;
  }

  getUserIdProfile(id: number) {
    if (this.userIdProfiles[id]) {
      return this.userIdProfiles[id];
    }
    return null;
  }
}
