import { HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  ErrorHandler,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { catchError, of } from 'rxjs';
import {
  FileUploadResponse,
  MicrocredentialBadgeUploadItemResponse,
  ResourceUploadResponse,
} from 'src/app/common/types/responses/responses';
import { v4 as uuid } from 'uuid';
import { FileDTO } from '../../../dtos/file.dto';
import { UploadType } from '../../../enums/upload-type.enum';
import {
  fileUploadItemResponseToFileDTO,
  fileUploadToFileDTO,
  microUploadToFileDTO,
  resourceUploadToFileDTO,
  videoDTOToFileDTO,
} from '../../../helpers/translators/files.translators';
import { videoDTOFromAPIResponse } from '../../../helpers/translators/video.translators';
import { FileServiceFactory } from '../../../services/file/file-service.factory';
import { FileService } from '../../../services/file/file.service';
import {
  VideoFileService,
  VideoUploadResult,
} from '../../../services/file/video.file.service';

interface UploadingItemDTO {
  id: string;
  name: string;
  progress: number;
}

export enum UploadStyles {
  FANCY = 'fancy',
  EVIDENCE = 'evidence',
  SIMPLE = 'simple',
  BADGE = 'badge',
  NONE = 'none',
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() uploadType: UploadType = UploadType.USER_UPLOAD;

  // This only controls whether you can upload multiple files at once; if you want to limit the total number of files, you need to do that in the parent component by removing this input field after uploading.
  @Input() multiple = false;

  @Output() readonly outputFiles: EventEmitter<FileDTO> =
    new EventEmitter<FileDTO>();

  @Input() style = UploadStyles.FANCY;

  @Input() customMessageAddOn = '';

  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  @Input() tooltipMessage = '';

  @ViewChild('fileUpload') public fileUpload: ElementRef<HTMLInputElement>;

  dropzoneActive = false;

  fileService: FileService;

  isUploading = false;

  styles = UploadStyles;

  uploadingItems: { [id: string]: UploadingItemDTO } = {};

  errorMsg = '';

  constructor(
    private injector: Injector,
    private errorHandlerService: ErrorHandler
  ) {}

  ngOnInit(): void {
    this.setFileService();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['uploadType']) {
      this.setFileService();
    }
  }

  setFileService() {
    this.fileService = this.injector.get(FileServiceFactory[this.uploadType]);
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      Array.from(input.files).forEach((file: File) => {
        this.uploadFile(file);
      });
      this.fileUpload.nativeElement.value = '';
    }
  }

  onUploadButtonClick() {
    this.fileUpload.nativeElement.click();
  }

  setUploadingStatus(status: boolean) {
    this.isUploading = status;
    if (status) {
      this.fileUpload.nativeElement.setAttribute('disabled', 'disabled');
    } else {
      this.fileUpload.nativeElement.removeAttribute('disabled');
    }
  }

  toggleDropZone(status: boolean) {
    this.dropzoneActive = status;
  }

  handleDrop(event: DragEvent) {
    this.toggleDropZone(false);
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      if (this.multiple) {
        Array.from(event.dataTransfer.files).forEach((file: File) => {
          this.uploadFile(file);
        });
      } else {
        this.uploadFile(event.dataTransfer.files[0]);
      }
    }
  }

  uploadFile(file: File) {
    // Remove spaces from file name to prevent error on file download, and retain file type
    const modifiedFile = new File([file], file.name.split(' ').join(''), {
      type: file.type,
    });
    this.setUploadingStatus(true);
    const id = uuid();
    const uploadingItem: UploadingItemDTO = {
      id,
      name: modifiedFile.name,
      progress: 0,
    };
    this.uploadingItems[id] = uploadingItem;
    const sub = this.fileService
      .uploadFile(modifiedFile)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError((err: any) => {
          sub.unsubscribe();
          this.errorMsg =
            'There was an issue uploading your file. Make sure you are uploading a video file type.';
          this.errorHandlerService.handleError(this.errorMsg);
          this.dropzoneActive = true;
          this.setUploadingStatus(false);
          this.uploadingItems = {};
          sub.unsubscribe();
          return of({ type: 'error', message: err });
        })
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          uploadingItem.progress = Math.round(
            100 * (event.loaded / event.total)
          );
        }
        if (event.type === HttpEventType.Response) {
          delete this.uploadingItems[id];
          if (Object.keys(this.uploadingItems).length === 0) {
            this.setUploadingStatus(false);
          }
          if (this.uploadType === UploadType.VIDEO) {
            (this.fileService as VideoFileService)
              .finishVideo(event.body as VideoUploadResult)
              .subscribe((response) => {
                this.outputFiles.emit(
                  videoDTOToFileDTO({
                    ...(event.body as VideoUploadResult),
                    ...videoDTOFromAPIResponse(response.video),
                    title: response.video.name || modifiedFile.name,
                  })
                );
              });
          } else if (this.uploadType === UploadType.RESOURCE_ATTACHMENT) {
            this.outputFiles.emit(
              resourceUploadToFileDTO(event.body as ResourceUploadResponse)
            );
          } else if (
            this.uploadType === UploadType.DELIVERABLE_ATTACHMENT ||
            this.uploadType === UploadType.ACTION_ITEM_ATTACHMENT
          ) {
            this.outputFiles.emit(
              fileUploadItemResponseToFileDTO(event.body.item)
            );
          } else if (this.uploadType === UploadType.MICROCREDENTIAL_BADGE) {
            this.outputFiles.emit(
              microUploadToFileDTO(
                event.body.item as MicrocredentialBadgeUploadItemResponse
              )
            );
          } else {
            this.outputFiles.emit(
              fileUploadToFileDTO(event.body as FileUploadResponse)
            );
          }
          if (this.dropzoneActive) {
            this.dropzoneActive = false;
          }
          sub.unsubscribe();
        }
      });
  }
}
