import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { PresenceChannel } from 'pusher-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { DatepickerOutput } from 'src/app/private/shared/components/datepicker/datepicker.component';
import {
  AttendeeRubricDTO,
  RubricDTO,
  StrandDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { BadgeUserDTO } from 'src/app/private/shared/dtos/badge.dto';
import {
  CoachingSessionDTO,
  NewCoachingSessionItemsPayloadDTO,
  NextSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import { FeedbackDTO } from 'src/app/private/shared/dtos/feedback.dto';
import {
  SessionPermissions,
  checkSmartCoachAuthorization,
} from 'src/app/private/shared/helpers/coachee-log.utilities';
import { feedbackDTOFromAPIResponse } from 'src/app/private/shared/helpers/translators/coaching-session-dto.translator';
import { PusherFeedback } from 'src/app/private/shared/services/coaching-log/coaching-log-service.dto';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { LogTypeSearchService } from 'src/app/private/shared/services/log-type-search/log-type-search.service';
import { SmartCoachSessionService } from 'src/app/private/shared/services/smart-coach/smart-coach-session';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import { FeedbackPayload } from 'src/app/private/shared/types/payloads/coaching-log.payloads';
import { FeedbackAPIResponse } from 'src/app/private/shared/types/responses/coaching-log.responses';

import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { CoachDialogService } from '../../../shared/services/smart-coach/coach-dialog/coach-dialog.service';

@Component({
  selector: 'app-smart-coach-summary-page',
  templateUrl: './smart-coach-summary-page.component.html',
  styleUrls: ['./smart-coach-summary-page.component.scss'],
})
export class SmartCoachSummaryPageComponent implements OnInit, OnDestroy {
  @Input() session: CoachingSessionDTO;

  @Input() rubricData: AttendeeRubricDTO[];

  logPermissions: SessionPermissions;

  Permissions = SessionPermissions;

  subs: Subscription[] = [];

  user: User;

  badgeUsers: BadgeUserDTO[] = [];

  feedback: FeedbackDTO[] = [];

  feedbackForm: FormGroup;

  feedbackFormSubmitted = false;

  feedbackFormSubmitLoading = false;

  isEditingFeedback = false;

  editFeedbackForm: FormGroup;

  editFeedbackFormSubmitted = false;

  editFeedbackFormSubmitLoading = false;

  nextSessionForm: FormGroup;

  nextSessionFormSubmitted = false;

  nextSessionFormSubmitLoading = false;

  currentDate = new Date();

  currentDateTime = new Date().getTime() / 1000;

  nextDate = new Date();

  nextDateTime: number = this.currentDateTime + (24 * 60 * 60 * 1000) / 1000;

  selectedDate = new BehaviorSubject<number>(this.nextDateTime);

  selectedDate$ = this.selectedDate.asObservable();

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  currentIcon: string;

  pusherChannel: PresenceChannel;

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO;

  defaultLogType: CoachingSessionDTO;

  formValid = true;

  scheduledSession: NextSessionDTO;

  disableNextSessionButton = false;

  constructor(
    private coachingSessionTypeService: LogTypeSearchService,
    private coachingLogService: CoachingLogService,
    private formBuilder: FormBuilder,
    private smartCoachSessionService: SmartCoachSessionService,
    private smartCoachDialogService: CoachDialogService,
    private store: Store,
    private router: Router,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    if (!this.featureFlagService.isEnabled(FEATURE_FLAGS.mySmartCoach)) {
      this.router.navigate(['/dashboard']);
    }
  }

  getAttendeeBadgeUser(attendeeUser: UserDTO): BadgeUserDTO | undefined {
    if (this.badgeUsers) {
      return this.badgeUsers.filter(
        (badgeUser) => badgeUser.id === attendeeUser.id
      )[0];
    }
    return undefined;
  }

  getAttendeeRubrics(userId: number): RubricDTO[] | undefined {
    if (this.rubricData) {
      const filteredRubricData = this.rubricData.filter(
        (rubricData) => rubricData.userId === userId
      );
      if (filteredRubricData.length > 0) {
        return filteredRubricData[0].rubrics;
      }
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getStrandProgress(strand: StrandDTO): number {
    let levelsCompleted = 0;
    if (strand.level1.levelCompleted) {
      levelsCompleted = 1;
    }
    if (strand.level2.levelCompleted) {
      levelsCompleted = 2;
    }
    if (strand.level3.levelCompleted) {
      levelsCompleted = 3;
    }
    if (strand.level4.levelCompleted) {
      levelsCompleted = 4;
    }
    return levelsCompleted;
  }

  // eslint-disable-next-line class-methods-use-this
  getLevels(strand: StrandDTO) {
    return [strand.level1, strand.level2, strand.level3, strand.level4];
  }

  ngOnInit() {
    if (this.session) {
      this.logPermissions = checkSmartCoachAuthorization(
        this.session,
        this.user
      );
      this.getBadgeUsers();
      this.getFeedback();
      this.getNextSession();
      if (this.logPermissions === SessionPermissions.EDIT) {
        this.smartCoachDialogService.getDialogMessage(
          'nextSteps-start-',
          'msc-session-summary'
        );
      }
    }

    this.pusherChannel = this.coachingLogService.getChannel(
      this.session?.id
    ) as PresenceChannel;
    this.pusherChannel?.bind(
      'coachlog:feedback_created',
      (message: PusherFeedback) => {
        this.feedback.push(
          feedbackDTOFromAPIResponse(message.item as FeedbackAPIResponse)
        );
      }
    );
    this.pusherChannel?.bind(
      'coachlog:feedback_updated',
      (message: PusherFeedback) => {
        const index = this.feedback.findIndex(
          (item) => item.id === message.item.id
        );
        this.feedback[index] = feedbackDTOFromAPIResponse(
          message.item as FeedbackAPIResponse
        );
      }
    );
    this.pusherChannel?.bind(
      'coachlog:feedback_deleted',
      (message: PusherFeedback) => {
        this.feedback = this.feedback.filter(
          (feedbackItem) => feedbackItem.id !== message.item.id
        );
      }
    );

    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required],
    });
    this.nextSessionForm = this.formBuilder.group({
      title: [this.session?.title, Validators.required],
    });
    this.coachingSessionTypeService.getLogType(27).subscribe((res) => {
      this.coachlogItemsPayload = {
        title: '',
        logType: res,
        startDatetime: 0,
        endDatetime: 0,
        timezone: TimezoneLinkBack.Central,
        coachUser: null,
        creatorUserId: 0,
        coachees: [],
        shadowers: [],
        is_private: false,
      };
      this.subs.push(
        this.selectedDate.subscribe((timestamp) => {
          this.coachlogItemsPayload.startDatetime = timestamp;
        })
      );
      this.subs.push(
        this.selectedTimezone.subscribe((timezone) => {
          this.coachlogItemsPayload.timezone = timezone;
        })
      );
    });

    // update possible next date selections to start one day after current session date
    this.nextDateTime = this.session.endDatetime + (24 * 60 * 60 * 1000) / 1000;
    this.nextDate = new Date(this.nextDateTime * 1000);
  }

  updateDateTime(params: DatepickerOutput[]) {
    if (params[0].time > this.nextDate.getTime() - 24 * 60 * 60 * 1000) {
      this.selectedDate.next(params[0].time / 1000);
      this.selectedTimezone.next(params[0].timezone);
      this.disableNextSessionButton = false;
    } else {
      this.disableNextSessionButton = true;
    }
  }

  getFeedback() {
    this.subs.push(
      this.coachingLogService.getFeedback(this.session?.id).subscribe((res) => {
        this.feedback = res;
      })
    );
  }

  getBadgeUsers() {
    this.subs.push(
      this.coachingLogService
        .getBadgeUsers(this.session?.id)
        .subscribe((res) => {
          this.badgeUsers = res;
        })
    );
  }

  getNextSession() {
    this.subs.push(
      this.coachingLogService
        .getAttendeesNeedNextLog(this.session?.id)
        .subscribe((res) => {
          if (res.attendeesScheduled[0]) {
            this.scheduledSession = res.attendeesScheduled[0].nextLog;
          }
        })
    );
  }

  submitFeedback() {
    this.feedbackFormSubmitted = true;

    this.isEditingFeedback = false;

    if (this.feedbackForm.invalid) {
      return;
    }

    this.feedbackFormSubmitLoading = true;

    const payload: FeedbackPayload = {
      content: this.feedbackForm.controls['feedback'].value,
      egrowe_coachlog_id: this.session.id,
      user_id: this.user?.id || 0,
    };

    this.subs.push(
      this.coachingLogService.submitFeedback(payload).subscribe((res) => {
        if (res) {
          this.getFeedback();
          this.feedbackFormSubmitLoading = false;
        }
      })
    );
  }

  editFeedback(itemId: number) {
    this.editFeedbackFormSubmitted = true;

    if (this.editFeedbackForm.invalid) {
      return;
    }

    this.editFeedbackFormSubmitLoading = true;

    this.subs.push(
      this.coachingLogService
        .editFeedback(itemId, this.editFeedbackForm.controls['feedback'].value)
        .subscribe((res) => {
          if (res) {
            this.getFeedback();
            setTimeout(() => {
              this.editFeedbackFormSubmitLoading = false;
              this.isEditingFeedback = false;
            }, 250);
          }
        })
    );
  }

  deleteFeedback(itemId: number) {
    this.subs.push(
      this.coachingLogService.deleteFeedback(itemId).subscribe((res) => {
        if (res) {
          this.getFeedback();
          this.feedbackForm = this.formBuilder.group({
            feedback: ['', Validators.required],
          });
          this.feedbackFormSubmitted = false;
        }
      })
    );
  }

  toggleEditMode(content: string) {
    this.isEditingFeedback = !this.isEditingFeedback;
    if (this.isEditingFeedback) {
      this.editFeedbackForm = this.formBuilder.group({
        feedback: [content, Validators.required],
      });
    }
  }

  scheduleNextSession() {
    this.coachlogItemsPayload = {
      ...this.coachlogItemsPayload,
      title: this.nextSessionForm.controls['title'].value,
      coachUser: this.user,
      creatorUserId: this.session.attendees[0].id,
      coachees: this.session.attendees.map((attendee) => ({
        id: attendee.user.id,
        profile: attendee.user.profile,
      })),
      shadowers: this.session.shadowers.map((shadower) => ({
        id: shadower.user.id,
        profile: shadower.user.profile,
      })),
    };

    this.formValid = true;

    if (!this.coachlogItemsPayload.logType) {
      this.formValid = false;
    }

    this.nextSessionFormSubmitted = true;
    this.nextSessionFormSubmitLoading = true;

    if (this.nextSessionForm.invalid || !this.formValid) {
      this.nextSessionFormSubmitLoading = false;
    } else {
      this.coachingLogService.createSmartlog(this.coachlogItemsPayload);
      this.subs.push(
        this.coachingLogService.smartCoachlogCreated.subscribe(() => {
          this.getNextSession();
          this.nextSessionFormSubmitted = true;
          this.nextSessionFormSubmitLoading = false;
        })
      );
    }
  }

  ngOnDestroy(): void {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
