<div class="form-group">
  <label class="form-label ms-2" [for]="id">{{ fieldDefinition.label }}</label>
  <ng-container *ngIf="!readOnly; else readonlyTemplate">
    <input
      *ngIf="fieldDefinition.type === fieldTypes.TEXT"
      type="text"
      [disabled]="inputDisabled"
      class="form-control"
      [(ngModel)]="userInput"
      (input)="value.emit(userInput)"
      [id]="id" />
    <textarea
      *ngIf="fieldDefinition.type === fieldTypes.TEXTBLOCK"
      class="form-control"
      [disabled]="inputDisabled"
      [(ngModel)]="userInput"
      (input)="value.emit(userInput)"
      [id]="id"></textarea>
  </ng-container>
  <ng-template #readonlyTemplate>
    <div class="ms-3">
      {{ userInput }}
    </div>
  </ng-template>
</div>
