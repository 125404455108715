import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  SmartCoachDialogMessage,
  SmartCoachDialogMessageResponse,
} from '../../../dtos/smartcoach.dto';

@Injectable({
  providedIn: 'any',
})
export class CoachDialogService {
  outputMessages = new BehaviorSubject<SmartCoachDialogMessage[]>([]);

  messagesReceivedCount = 0;

  expectedMessageCount = 0;

  messageQueue: SmartCoachDialogMessage[] = [];

  constructor(private apiService: APICoreService) {}

  clearMessages() {
    this.outputMessages.next([]);
  }

  setQueue(messageCount: number) {
    this.expectedMessageCount = messageCount;
    this.messagesReceivedCount = 0;
    this.messageQueue = [];
  }

  getDialogMessage(key: string, group: string, queuePosition?: number) {
    this.apiService
      .getRequest(`section-dialog-coach-message/view-by-key/${key}`)
      .subscribe((res: SmartCoachDialogMessageResponse) => {
        take(1);
        if (queuePosition !== undefined) {
          this.queueMessage(
            {
              message: res.item.message,
              group,
            },
            queuePosition
          );
        } else {
          this.outputMessages.next([
            {
              message: res.item.message,
              group,
            },
          ]);
        }
      });
  }

  queueMessage(message: SmartCoachDialogMessage, queuePosition: number) {
    this.messageQueue[queuePosition - 1] = message;
    this.messagesReceivedCount += 1;
    if (this.messagesReceivedCount === this.expectedMessageCount) {
      /* eslint-disable no-restricted-syntax */
      for (const queuedMessage of this.messageQueue) {
        if (queuedMessage) {
          this.outputMessages.next([queuedMessage]);
        }
      }
    }
  }
}
