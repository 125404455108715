import {
  CoachlogSearchPayloadDTO,
  CoachlogSearchPayloadItemsDTO,
} from 'src/app/common/dtos/coachlog-search-payload.dto';
import {
  BadgeEarnedPusherResponse,
  PusherAttachmentsResponse,
} from 'src/app/common/types/responses/responses';
import { booleanFromBooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import {
  BadgeUserAPIResponse,
  CoachingSessionAttendeesAPIResponse,
  CoachingSessionDataAPIResponse,
  CoachingSessionShadowerAPIResponse,
  CoachingSessionTypeAPIResponse,
  FeedbackAPIResponse,
  IndividualCoachingSessionDataAPIResponse,
  NextSessionAPIResponse,
  UpcomingCoachingSessionsDataAPIResponse,
} from 'src/app/private/shared/types/responses/coaching-log.responses';

import { EvidenceAttachmentDTO } from '../../dtos/attendee-rubric.dto';
import { BadgeEarnedDTO, BadgeUserDTO } from '../../dtos/badge.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
  CoachingSessionShadowerDTO,
  CoachingSessionTypeDTO,
  NextSessionDTO,
} from '../../dtos/coaching-session.dto';
import { FeedbackDTO } from '../../dtos/feedback.dto';
import { evidenceAttachmentDTOFromAPIResponse } from './evidence.translators';
import { userDTOFromAPIResponse } from './user.translators';

export const coachingSessionAttendeDTOFromAPIResponse = (
  attendee: CoachingSessionAttendeesAPIResponse
): CoachingSessionAttendeeDTO => ({
  id: attendee.id,
  userId: attendee.user_id,
  present: attendee.present,
  absentReason: attendee.absent_reason,
  notes: attendee.notes,
  rating: attendee.rating,
  self_rating: attendee.self_rating,
  egroweCoachlogId: attendee.egrowe_coachlog_id,
  user: attendee.user,
});

export const coachingSessionShadowerDTOFromAPIResponse = (
  attendee: CoachingSessionShadowerAPIResponse
): CoachingSessionShadowerDTO => ({
  id: attendee.id,
  userId: attendee.user_id,
  present: attendee.present,
  egroweCoachlogId: attendee.egrowe_coachlog_id,
  user: attendee.user,
});

export const coachingSessionTypeDTOFromAPIResponse = (
  response: CoachingSessionTypeAPIResponse
): CoachingSessionTypeDTO => ({
  id: response.id,
  description: response.description,
  includeEgroweProcess: booleanFromBooleanNumber(
    response.include_egrowe_process
  ),
  includeRubricForm: booleanFromBooleanNumber(response.include_rubric_form),
  isClassroomVisit: booleanFromBooleanNumber(response.is_classroomvisit),
  isCoaching: booleanFromBooleanNumber(response.is_coaching),
  isSmart: booleanFromBooleanNumber(response.is_smart),
  optionRequiresStandard: booleanFromBooleanNumber(
    response.option_requires_standard
  ),
  title: response.title,
  view: response.view,
  updatedAt: response.updated_at,
  createdAt: response.created_at,
});

export const evidenceAttachmentDTOfromPusherAttachmentsResponse = (
  item: PusherAttachmentsResponse
): EvidenceAttachmentDTO => ({
  id: item.id,
  type: item.type,
  relationalId: parseInt(item.relational_id),
  title: item.title,
  description: item.description,
  filePath: item.file_path,
  fileType: item.file_type,
  createdAt: item.created_at,
  updatedAt: item.updated_at,
  userId: item.user_id,
});

export const coachingSessionDTOFromAPIResponse = (
  item: CoachingSessionDataAPIResponse
): CoachingSessionDTO => ({
  id: item.id,
  title: item.title,
  sessionNotes: item.content,
  egroweCoachlogTypeId: item.egrowe_coachlog_type_id,
  startDatetime: item.start_datetime,
  endDatetime: item.end_datetime,
  timezone: item.timezone as TimezoneLinkBack,
  type: coachingSessionTypeDTOFromAPIResponse(item.type),
  district: item.district,
  user: item.user,
  attendees: item.attendees?.map((attendee) =>
    coachingSessionAttendeDTOFromAPIResponse(attendee)
  ),
  shadowers: item.shadowers?.map((shadower) =>
    coachingSessionShadowerDTOFromAPIResponse(shadower)
  ),
  isCompleted: item.isCompleted,
  currentUsersRole: item.current_users_role,
  isPractice: item.is_practice,
  isPrivate: item.is_private,
  version: item.version,
  attachments: item.attachments?.map((attachment) =>
    evidenceAttachmentDTOFromAPIResponse(attachment)
  ),
  percentComplete: item.percent_complete,
});

export const coachlogSearchPayloadItemsFromComponent = (
  item: CoachlogSearchPayloadItemsDTO
): CoachlogSearchPayloadDTO => ({
  from_date: item.from_date,
  to_date: item.to_date,
  keywords: item.keywords,
  per_page: item.per_page,
  sort_order: item.sort_order,
  own_only: item.own_only,
  own_only_user_id: item.own_only_user_id ? item.own_only_user_id : undefined,
  district_id: item.district_item?.id ? item.district_item.id : null,
  competencies: item.competency_items.map(
    (eachCompetency) => eachCompetency.id
  ),
  schools: item.school_items.map((eachSchool) => eachSchool.id),
  coach_user_id: item.coach_items.map((eachCoach) => eachCoach.id),
  attendees: item.coachee_items.map((eachCoachee) => eachCoachee.id),
  logtype: item.logtype_item?.id ? item.logtype_item.id : 0,
  is_smart: item.is_smart ? item.is_smart : 0,
  page: item.page ? item.page : 1,
});

export const individualCoachingSessionDTOFromAPIResponse = (
  response: IndividualCoachingSessionDataAPIResponse
): CoachingSessionDTO => coachingSessionDTOFromAPIResponse(response.item);

export const upcomingCoachingSessionsDTOFromAPIResponse = (
  response: UpcomingCoachingSessionsDataAPIResponse
): CoachingSessionDTO[] => {
  const sessions: CoachingSessionDTO[] = [];
  response.items.forEach((item) => {
    sessions.push(coachingSessionDTOFromAPIResponse(item));
  });
  return sessions;
};

export const feedbackDTOFromAPIResponse = (
  item: FeedbackAPIResponse
): FeedbackDTO => ({
  content: item.content,
  createdAt: item.created_at,
  egroweCoachlogId: item.egrowe_coachlog_id,
  id: item.id,
  isDeleted: item.is_deleted,
  updatedAt: item.updated_at,
  user: userDTOFromAPIResponse(item.user),
  userId: item.user_id,
});

export const feedbackListDTOFromAPIResponse = (response: {
  items: FeedbackAPIResponse[];
}): FeedbackDTO[] =>
  response.items.map((item) => feedbackDTOFromAPIResponse(item));

const badgeUserDTOFromAPIResponse = (
  item: BadgeUserAPIResponse
): BadgeUserDTO => ({
  badges: item.badges,
  id: item.id,
  username: item.username,
  status: item.status,
  email: item.email,
  is_deleted: item.is_deleted,
  years_coached: item.years_coached,
  is_smart: item.is_smart,
  is_smart_default: item.is_smart_default,
  smartCoach: null,
  profile: item.profile,
  coachee_type: item.coachee_type,
});

export const badgeUserListDTOFromAPIResponse = (response: {
  items: BadgeUserAPIResponse[];
}): BadgeUserDTO[] =>
  response.items.map((item) => badgeUserDTOFromAPIResponse(item));

export const nextSessionDTOFromAPIResponse = (
  item: NextSessionAPIResponse
): NextSessionDTO => ({
  logId: item.log_id,
  coachId: item.coach_id,
  title: item.title,
  startDatetime: item.start_datetime,
  endDatetime: item.end_datetime,
  coacheeId: item.coachee_id,
  coachlogType: item.coachlog_type,
  timezone: item.timezone as TimezoneLinkBack,
  egroweTimelineId: item.egrowe_timeline_id,
  coacheeName: item.coachee_name,
});

export const badgeEarnedDTOFromPusherResponse = (
  item: BadgeEarnedPusherResponse
): BadgeEarnedDTO => ({
  userId: item.user_id,
  userName: item.user_name,
  badges: item.badges,
});
