import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { IntroSessionStatusResponse } from 'src/app/common/types/responses/responses';
import { videoDTOFromAPIResponse } from '../../helpers/translators/video.translators';
import { VideoAPIResponse } from '../../types/responses/video.responses';

@Injectable({
  providedIn: 'root',
})
export class IntroSessionService {
  subs: Subscription[] = [];

  private overviewCompleteData = false;

  private overviewCompleteSource = new BehaviorSubject(
    this.overviewCompleteData
  );

  public overviewComplete = this.overviewCompleteSource.asObservable();

  private whyCompleteData = false;

  private whyCompleteSource = new BehaviorSubject(this.whyCompleteData);

  public whyComplete = this.whyCompleteSource.asObservable();

  private profileCompleteData = false;

  private profileCompleteSource = new BehaviorSubject(this.profileCompleteData);

  public profileComplete = this.profileCompleteSource.asObservable();

  private egroweCompleteData = false;

  private egroweCompleteSource = new BehaviorSubject(this.egroweCompleteData);

  public egroweComplete = this.egroweCompleteSource.asObservable();

  private bpCompleteData = false;

  private bpCompleteSource = new BehaviorSubject(this.bpCompleteData);

  public bpComplete = this.bpCompleteSource.asObservable();

  private coachCompleteData = false;

  private coachCompleteSource = new BehaviorSubject(this.coachCompleteData);

  public coachComplete = this.coachCompleteSource.asObservable();

  private planCompleteData = false;

  private planCompleteSource = new BehaviorSubject(this.planCompleteData);

  public planComplete = this.planCompleteSource.asObservable();

  private currentUserId: number;

  // private currentUser: UserModel;

  constructor(private apiService: APICoreService) {}

  getIntroSessionStatus(userId: number) {
    this.currentUserId = userId;
    return this.apiService
      .getRequest('intro-session-status', { user_id: userId })
      .subscribe((res) => {
        if (res.items) {
          res.items.forEach((section: IntroSessionStatusResponse) => {
            switch (section.section) {
              case 'overview':
                this.setOverviewComplete();
                break;
              case 'why':
                this.setWhyComplete();
                break;
              case 'profile':
                this.setProfileComplete();
                break;
              case 'egrowe':
                this.setEgroweComplete();
                break;
              case 'bp':
                this.setBpComplete();
                break;
              case 'coach':
                this.setCoachComplete();
                break;
              case 'plan':
                this.setPlanComplete();
                break;
              default:
                // eslint-disable-next-line
                console.error(
                  `getIntroSessionStatus() switchcase case not found: ${section.section}`
                );
            }
          });
        }
      });
  }

  setOverviewComplete(value = true, sendReq?: boolean) {
    this.overviewCompleteSource.next(value);
    if (sendReq) {
      this.apiService
        .postRequest('intro-session-status', {
          section: 'overview',
          user_id: this.currentUserId,
        })
        .subscribe();
    }
  }

  setWhyComplete(value = true, sendReq?: boolean) {
    this.whyCompleteSource.next(value);
    if (sendReq) {
      this.apiService
        .postRequest('intro-session-status', {
          section: 'why',
          user_id: this.currentUserId,
        })
        .subscribe();
    }
  }

  setProfileComplete(value = true, sendReq?: boolean) {
    this.profileCompleteSource.next(value);
    if (sendReq) {
      this.apiService
        .postRequest('intro-session-status', {
          section: 'profile',
          user_id: this.currentUserId,
        })
        .subscribe();
    }
  }

  setEgroweComplete(value = true, sendReq?: boolean) {
    this.egroweCompleteSource.next(value);
    if (sendReq) {
      this.apiService
        .postRequest('intro-session-status', {
          section: 'egrowe',
          user_id: this.currentUserId,
        })
        .subscribe();
    }
  }

  setBpComplete(value = true, sendReq?: boolean) {
    this.bpCompleteSource.next(value);
    if (sendReq) {
      this.apiService
        .postRequest('intro-session-status', {
          section: 'bp',
          user_id: this.currentUserId,
        })
        .subscribe();
    }
  }

  setCoachComplete(value = true, sendReq?: boolean) {
    this.coachCompleteSource.next(value);
    if (sendReq) {
      this.apiService
        .postRequest('intro-session-status', {
          section: 'coach',
          user_id: this.currentUserId,
        })
        .subscribe();
    }
  }

  setPlanComplete(value = true, sendReq?: boolean) {
    this.planCompleteSource.next(value);
    if (sendReq) {
      if (value)
        this.apiService
          .postRequest('intro-session-status', {
            section: 'plan',
            user_id: this.currentUserId,
          })
          .subscribe();
      else
        this.apiService
          .deleteRequest(`intro-session-status/plan/${this.currentUserId}`)
          .subscribe();
    }
  }

  getGroweProcessVideo() {
    return this.apiService.getRequest('video?subject=egroweCoachingProcess');
  }

  getOverviewVideo() {
    return this.apiService.getRequest('video?subject=introSessionWelcomePage');
  }

  getWhyVideo() {
    return this.apiService.getRequest('video?subject=e2lWhy');
  }

  getStandardVideo(compId: number) {
    return this.apiService.getRequest(
      `video?ref_table=egrowe_standard&ref_id=${compId}&subject=standardOverview`
    );
  }

  getSmartRubrics() {
    return this.apiService.getRequest('egrowe/rubrics/smart');
  }

  getCoaches() {
    return this.apiService.getRequest('users?is_smart=true');
  }

  chooseCoach(payload: { smart_coach_user_id: number; user_id: number }) {
    return this.apiService.postRequest('user-smart-coach', payload);
  }

  getCoachingStyleVideo(coachId: number) {
    return this.apiService
      .getRequest(
        `video?ref_table=user&ref_id=${coachId}&subject=coachingStyle`
      )
      .pipe(
        map((res) => videoDTOFromAPIResponse(res.item as VideoAPIResponse))
      );
  }
}
