<div class="container">
  <app-general-header title="Coaching Sessions" />
  <div class="card border-0 p-3 min-vh-100">
    <ng-container *ngIf="sessionLoaded; else loadingTemplate">
      <ng-container *ngIf="sessionData; else sessionErrorMessage">
        <h2 class="text-center mt-3">{{ sessionData.title }}</h2>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <ng-template
            [ngIf]="
              coachingService.logPusherService.attendeeRosters[sessionId]
                .length > 0
            ">
            <div class="d-flex flex-row align-items-center">
              <div class="me-3 text-body-secondary">Attendees</div>
              <app-avatar
                *ngFor="let attendee of pusherAttendees"
                style="margin-left: -5px"
                avatarSize="small"
                [userId]="attendee.id"
                [avatarData]="attendee.avatar"
                [grayScale]="!attendee.samePage"
                data-testid="attendee-avatar"></app-avatar>
            </div>
          </ng-template>
          <div class="col text-end">
            <button
              (click)="navigateNextScreen()"
              class="btn btn-primary btn-next"
              [disabled]="
                !hasPresentAttendee || !isTimeValid || competenciesLoading
              "
              ngbTooltip="At least 1 coachee must be marked present to begin."
              [disableTooltip]="hasPresentAttendee"
              style="pointer-events: auto">
              Let's Begin <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
        <div class="container mt-3">
          <form>
            <div class="row">
              <div class="col-lg-4 p-3">
                <h3>Session Coach</h3>
                <div class="d-flex flex-nowrap align-items-center my-3">
                  <app-avatar
                    [profile]="sessionData.user.profile"
                    [avatarSize]="'medium'">
                  </app-avatar>
                  <div class="username ms-2">
                    {{ sessionData.user.profile.first_name }}
                    {{ sessionData.user.profile.last_name }}
                  </div>
                </div>
                <h3 class="mt-4">Coachee(s)</h3>
                <ng-container
                  *ngIf="sessionData.attendees.length > 0; else noCoachees">
                  <app-coaching-attendee
                    *ngFor="let attendee of sessionData.attendees"
                    [attendee]="attendee"
                    [isCoach]="isCoach"
                    [absentReasonsList]="absentReasonsList"
                    (removedAttendee)="removeCoachee($event)" />
                </ng-container>
                <ng-template #noCoachees>
                  <p class="text-body-secondary fw-light">
                    No coachees have been added to this session.
                  </p>
                </ng-template>
                <div *ngIf="isCoach" class="mt-4">
                  <label class="form-label">Add Coachee</label>

                  <ng-container
                    *ngIf="
                      sessionData.attendees.length +
                        sessionData.shadowers.length <=
                        8;
                      else maxAttendees
                    ">
                    <app-user-select
                      [districtId]="districtId"
                      [includeAllB2bDistricts]="includeAllB2bDistricts"
                      [clearAfterSelection]="true"
                      (selectedUser)="addCoachee($event)" />
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-4 p-3">
                <h3>Session Info</h3>
                <ng-container *ngIf="isCoach">
                  <label class="w-100 form-label" for="title">Title</label>
                  <input
                    class="form-control"
                    type="text"
                    id="title"
                    aria-label="Title"
                    appAutosave
                    [(ngModel)]="sessionData.title"
                    [ngModelOptions]="{ standalone: true }"
                    (autosave)="saveTitle()"
                    class="form-control" />
                </ng-container>
                <label class="form-label">Type</label>
                <div class="mb-3 d-flex align-items-center">
                  <div style="flex-grow: 1">{{ sessionData.type.title }}</div>
                  <div class="d-flex">
                    <button
                      *ngIf="isCoach"
                      class="bg-transparent fs-12 border-0 p-0 ms-2"
                      title="Edit sesson type"
                      aria-label="Edit session type"
                      (click)="openSessionTypeModal()">
                      <i class="bi bi-pencil-fill"></i>
                    </button>
                  </div>
                </div>

                <ng-container *ngIf="isCoach">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      (change)="onCheckboxChange($event)"
                      [(ngModel)]="sessionData.isPrivate"
                      [ngModelOptions]="{ standalone: true }"
                      type="checkbox"
                      id="is-private" />
                    <label class="form-check-label" for="is-private">
                      Private Session
                      <div class="form-text">
                        Private sessions can only be viewed by the coach,
                        coachee(s), and shadower of the session.
                      </div></label
                    >
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="sessionData.currentUsersRole === 'coachee'">
                  <div class="form-check">
                    <input
                      disabled
                      class="form-check-input"
                      (change)="onCheckboxChange($event)"
                      [(ngModel)]="sessionData.isPrivate"
                      [ngModelOptions]="{ standalone: true }"
                      type="checkbox"
                      id="is-private" />
                    <label class="form-check-label" for="is-private">
                      Private Session
                      <div class="form-text">
                        Private sessions can only be viewed by the coach,
                        coachee(s), and shadower of the session.
                      </div></label
                    >
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="
                    sessionData.currentUsersRole === 'shadower' &&
                    sessionData.isPrivate
                  ">
                  <div class="mt-1 mb-3">
                    <label class="w-100 form-label">Private Session</label>

                    <div class="form-text">
                      Private sessions can only be viewed by the coach,
                      coachee(s), and shadower of the session.
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="isCoach; else coacheeDatetimeView">
                  <label class="w-100 form-label">Date & Time</label>
                  <app-datepicker
                    #datePicker
                    (validTime)="isTimeValid = $event"
                    [initialDateUnixTimestamp]="sessionData.startDatetime"
                    (dateSelectedUnixTimestamp)="updateDateTime($event)"
                    [defaultStartTime]="logStart"
                    [defaultEndTime]="logEnd"
                    [timezone]="sessionData.timezone"
                    [timepicker]="true"></app-datepicker>
                </ng-container>
                <ng-template #coacheeDatetimeView>
                  <div class="form-label">Date & Time</div>
                  {{
                    sessionData.startDatetime
                      | formatDate : "MMM. d, yyyy" : sessionData.timezone
                  }},
                  {{
                    sessionData.startDatetime
                      | formatDate : "h:mm a" : sessionData.timezone
                  }}<br />
                  {{ sessionData.timezone }}
                </ng-template>
              </div>
              <div class="col-lg-4 p-3">
                <div class="d-flex flex-column h-100">
                  <div style="flex-grow: 1">
                    <h3>Shadower</h3>
                    <p
                      class="fw-light text-body-secondary"
                      *ngIf="sessionData.shadowers.length == 0">
                      No shadower has been added to this session.
                    </p>
                    <ng-container
                      *ngFor="let shadower of sessionData.shadowers">
                      <div class="my-3">
                        <app-coaching-shadower
                          [shadower]="shadower"
                          [logId]="sessionData.id"
                          [isCoach]="isCoach" />
                      </div>
                    </ng-container>
                    <div *ngIf="isCoach">
                      <label class="form-label">Add Shadower</label>
                      <ng-container
                        *ngIf="
                          sessionData.attendees.length +
                            sessionData.shadowers.length <=
                            8 && sessionData.shadowers.length < 8;
                          else maxAttendees
                        ">
                        <app-user-select
                          [clearAfterSelection]="true"
                          [includeE2l]="true"
                          (selectedUser)="addShadower($event)" />
                      </ng-container>
                    </div>
                  </div>
                  <div
                    *ngIf="isCoach"
                    class="d-flex justify-content-center justify-content-md-end">
                    <button
                      aria-label="delete session button"
                      class="btn btn-secondary fs-14 fw-800 mt-5 mb-2"
                      type="button"
                      [autoClose]="'outside'"
                      [ngbPopover]="deleteSessionTemplate"
                      [popoverTitle]="'Delete Session'"
                      #popover="ngbPopover">
                      Delete Session <i class="bi bi-trash"></i>
                    </button>
                    <ng-template #deleteSessionTemplate>
                      Are you sure you want to delete this Session?
                      <div class="text-end mt-2">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm px-3 m-1"
                          (click)="popover.close()">
                          No
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm px-3 m-1"
                          (click)="deleteSession(); popover.close()">
                          Yes
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row d-flex flex-column align-items-center mt-3">
          <ng-container *ngIf="selectedAttendee">
            <div class="row text-center w-75">
              <h3 class="header-row-header">Last Session</h3>
              <div class="row justify-content-center my-2">
                <ng-container
                  *ngIf="
                    (isCoach || isShadower) && sessionAttendees.length > 1
                  ">
                  <div class="col-md-6">
                    <label
                      class="visually-hidden"
                      [attr.for]="'log-top-attendee-select'"
                      >Select Session Attendee</label
                    >
                    <ng-select
                      [labelForId]="'log-top-attendee-select'"
                      [items]="sessionAttendees"
                      bindLabel="id"
                      [placeholder]="'Select Coachee'"
                      [searchable]="false"
                      [clearable]="false"
                      [(ngModel)]="selectedAttendee"
                      (ngModelChange)="onLastSessionAttendeeChange($event)"
                      #selectCoachee
                      ><ng-template ng-label-tmp let-item="item"
                        ><span class="fw-bold">Coachee: </span>
                        &nbsp;<app-users-name [userProfile]="item.profile" />
                      </ng-template>
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                        let-search="term">
                        <app-users-name [userProfile]="item.profile" />
                      </ng-template>
                    </ng-select>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="previousSessions && previousSessions.length > 0">
                  <div class="col-md-6">
                    <label
                      class="visually-hidden"
                      [attr.for]="'log-top-session-select'"
                      >Select Previous Session</label
                    >
                    <ng-select
                      [labelForId]="'log-top-session-select'"
                      [items]="previousSessions"
                      bindLabel="id"
                      [placeholder]="'Select Session'"
                      [searchable]="false"
                      [clearable]="false"
                      [(ngModel)]="selectedSession"
                      (ngModelChange)="onLastSessionChange($event)"
                      #selectSession
                      ><ng-template ng-label-tmp let-item="item"
                        ><span class="fw-bold">Previous Session: </span>
                        &nbsp;{{ item.title }} ({{
                          item.start_datetime | formatDate : "MM/dd/yy"
                        }})
                      </ng-template>
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                        let-search="term">
                        {{ item.title }}
                        ({{ item.start_datetime | formatDate : "MM/dd/yy" }})
                      </ng-template>
                    </ng-select>
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="!competenciesLoading; else lastSessionLoader">
              <ng-container
                *ngIf="
                  lastSessionCompetencies[0]?.competencies &&
                    lastSessionCompetencies[0].competencies.length > 0;
                  else noCompetenciesFound
                ">
                <div class="row row-cols-lg-3 d-flex align-items-stretch">
                  <ng-container
                    *ngFor="let userCompetencyList of lastSessionCompetencies">
                    <div
                      *ngFor="
                        let competency of userCompetencyList.competencies
                      ">
                      <div class="col-lg mt-3">
                        <div
                          class="card cursor-pointer"
                          (click)="
                            toggleCardModal(
                              userCompetencyList.userId,
                              competency.id
                            )
                          ">
                          <app-competencies-summary
                            [isSelectable]="false"
                            [userId]="userCompetencyList.userId"
                            [competencyId]="
                              competency.id
                            "></app-competencies-summary>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #noCompetenciesFound>
                <div class="row my-5 d-flex">
                  <p
                    class="text-center fw-400 fs-4"
                    *ngIf="
                      this.previousSessions && this.previousSessions.length > 0;
                      else noPreviousSession
                    ">
                    No Competencies from Previous Session
                  </p>
                  <ng-template #noPreviousSession>
                    <p class="text-center fw-400 fs-4">
                      No Previous Session Found
                    </p>
                  </ng-template>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #lastSessionLoader>
              <div class="last-session-loader-container">
                <app-loader />
              </div>
            </ng-template>
          </ng-container>
          <app-leveled-competency-modal
            [viewingPrevSession]="true"
            [currentSessionId]="sessionId"
            #competencyModal />
        </div>
      </ng-container>
    </ng-container>
    <ng-template #sessionErrorMessage>
      <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
        <app-error-message [message]="coachLogStatus.errors[0]" />
      </ng-container>
    </ng-template>
    <ng-template #loadingTemplate>
      <app-loader />
    </ng-template>
    <ng-template #maxAttendees>
      <div>Maximum number of attendees reached</div>
    </ng-template>
  </div>
  <div
    role="button"
    (click)="toggleNotes()"
    class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start notes-fade-out notes-tab {{
      notesTabActive ? '' : 'hide'
    }} ">
    <div class="d-flex align-items-center" @fadeInOut>
      <i class="bi bi-journal journal-icon"></i>
      <p class="notes-title">Notes</p>
    </div>
  </div>
  <div
    *ngIf="sessionData"
    role="button"
    (click)="toggleCompletion()"
    class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start completion-fade-out completion {{
      completionTabActive ? '' : 'hide'
    }} ">
    <div class="d-flex align-items-center pt-1">
      <i class="bi bi-shield-check journal-icon mx-2" @fadeInOut></i>
      <div @delayfadeInOut>
        <div class="fs-16 fw-800">{{ percentComplete }}% Complete</div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="sessionData">
    <app-coaching-log-notes
      (hideNotesEvent)="toggleNotes()"
      [user]="user"
      [sessionId]="sessionId"
      [notesActive]="notesActive" />
    <app-coaching-log-completion
      (hideCompletionEvent)="toggleCompletion()"
      [completionActive]="completionActive"
      [sessionId]="sessionId"
      (percentageCalculationEvent)="updatePercentComplete($event)" />
    <app-session-type-modal #sessionTypeModal [currentSession]="sessionData" />
  </ng-container>
</div>
