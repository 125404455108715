import { StorageService } from 'src/app/common/services/storage/storage.service';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import {
  FieldData,
  FieldDataFlexible,
  FileFieldData,
  FormIndicatorDTO,
  FormIndicatorFieldData,
  ObservationFormSubmissionRawData,
  StringArrayFieldData,
} from 'src/app/private/shared/dtos/forms.dto';

export const fieldDataCaster = (data: FieldDataFlexible): FieldData => ({
  fieldId: data.fieldId,
  value: data.value as string,
});

export const stringArrayFieldDataCaster = (
  data: FieldDataFlexible
): StringArrayFieldData => ({
  fieldId: data.fieldId,
  value: data.value as string[],
});

export const formIndicatorFieldDataCaster = (
  data: FieldDataFlexible
): FormIndicatorFieldData => ({
  fieldId: data.fieldId,
  value: data.value as FormIndicatorDTO[],
});

export const fileFieldDataCaster = (
  data: FieldDataFlexible
): FileFieldData => ({
  fieldId: data.fieldId,
  value: data.value as FileDTO[],
});

export const hasSavedFormData = (storageKey: string): boolean => {
  const latestData = StorageService.getItem(
    storageKey,
    Number.MAX_SAFE_INTEGER
  );
  return !!latestData?.submissionData;
};

export const getSavedFormData = (
  storageKey: string
): ObservationFormSubmissionRawData | false => {
  const latestData = StorageService.getItem(
    storageKey,
    Number.MAX_SAFE_INTEGER
  );
  return latestData?.submissionData;
};

export const getPreviousFormId = (storageKey: string): number => {
  const latestData = StorageService.getItem(
    storageKey,
    Number.MAX_SAFE_INTEGER
  );
  return latestData?.formId;
};
