<app-modal
  (isClosing)="modalClosing.emit(true)"
  (isDismissing)="modalClosing.emit(true)"
  *ngIf="showModal"
  #modal
  [modalConfig]="{
    customFooter: true,
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    openOnLoad: true,
    options: {
      size: 'md',
    },
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="container">
    <div class="row">
      <div *ngIf="canFilterByDistrict" class="form-group">
        <h5>District(s):</h5>
        <app-district-select
          [isMulti]="true"
          (selectedDistrictList)="updateDistricts($event)"
          [defaultDistricts]="
            editedFilters.districtFilter ? editedFilters.districtFilter : null
          "></app-district-select>
      </div>
      <div *ngIf="reportName !== 'badge_progress'" class="form-group mt-3">
        <div class="form-group mt-3">
          <h5>School Year:</h5>
          <!-- Multi School Year Select -->
          <ng-container
            *ngIf="
              reportName === 'badges_earned_growth';
              else singleYearSelect
            ">
            <app-year-select
              [isMulti]="true"
              [clearAfterSelection]="true"
              [defaultYears]="editedFilters.schoolYearsFilter || null"
              (selectedYearList)="updateSchoolYears($event)"></app-year-select>
          </ng-container>
          <!-- Single School Year Select -->
          <ng-template #singleYearSelect>
            <app-year-select
              [defaultYear]="editedFilters.schoolYearFilter || null"
              (selectedYear)="updateSchoolYear($event)"></app-year-select>
          </ng-template>
        </div>
      </div>
      <div class="form-group mt-3" *ngIf="initialFilters.formFilter">
        <h5>Filter by form answers</h5>
        <app-form-answer-select
          [form]="initialFilters.formFilter"
          [defaultAnswers]="initialFilters.formAnswersFilter || []"
          (selectedAnswerList)="updateFormAnswers($event)">
        </app-form-answer-select>
      </div>
      <div *ngIf="districtsComponentValue.length > 0">
        <div
          *ngIf="
            reportName === 'badge_progress' ||
            reportName === 'evidence_report' ||
            reportName === 'badges_earned_growth'
          "
          class="form-group mt-3">
          <h5>Topic:</h5>
          <app-topic-select
            [label]="'Topic'"
            [defaultTopic]="editedFilters.topicFilter || null"
            [topics]="topics"
            [districtIds]="districtsComponentValue"
            [placeholder]="'Search for a topic...'"
            (selectedTopic)="updateTopic($event)"></app-topic-select>
        </div>
        <div class="form-group mt-3">
          <h5>School:</h5>
          <app-school-select
            [defaultSchools]="editedFilters.schoolFilter || null"
            [assignedCoacheesSchoolsOnly]="!canSeeAllSchools"
            [districtId]="districtsComponentValue"
            [isMulti]="true"
            (selectedSchoolList)="updateSchools($event)">
          </app-school-select>
        </div>
        <div class="form-group mt-3">
          <h5>Cohort:</h5>
          <app-cohort-select
            [districtIds]="districtsComponentValue"
            [defaultCohorts]="
              editedFilters.cohortFilter ? [editedFilters.cohortFilter] : null
            "
            (selectedCohort)="updateCohort($event)"></app-cohort-select>
        </div>
        <div class="form-group mt-3">
          <h5>Assigned Coach:</h5>
          <app-coach-select
            [defaultCoaches]="
              editedFilters.assignedCoachFilter
                ? [editedFilters.assignedCoachFilter]
                : null
            "
            (selectedCoach)="updateCoach($event)"
            [districtIds]="districtsComponentValue"></app-coach-select>
        </div>
        <div
          *ngIf="
            reportName === 'session_details' ||
            reportName === 'observation_reports'
          "
          class="form-group mt-3">
          <h5>Coachee(s):</h5>
          <app-user-select
            [clearAfterSelection]="false"
            [isMulti]="true"
            [defaultUsers]="
              editedFilters.coacheesFilter ? editedFilters.coacheesFilter : null
            "
            (selectedUserList)="updateCoachees($event)" />
        </div>
        <div
          class="form-group mt-3"
          *ngIf="
            reportName === 'support_insights' ||
            reportName === 'session_details'
          ">
          <h5>Session Type:</h5>
          <app-log-type-select
            [isMulti]="true"
            [districtId]="districtsComponentValue"
            [defaultLogTypes]="editedFilters.logTypeFilter || null"
            (selectedLogTypeList)="updateLogTypes($event)" />
        </div>
        <div *ngIf="reportName === 'evidence_report'" class="form-group mt-3">
          <h5>Attached to Option:</h5>
          <app-has-option-select
            (emittedOption)="updateHasOption($event)"
            [defaultOption]="
              editedFilters.hasOption !== null &&
              editedFilters.hasOption !== undefined
                ? editedFilters.hasOption
                : null
            "></app-has-option-select>
        </div>
        <div *ngIf="reportName === 'evidence_report'" class="form-group mt-3">
          <h5>Reviewed Status:</h5>
          <app-is-reviewed-select
            (emittedOption)="updateIsReviewed($event)"
            [defaultOption]="
              editedFilters.isReviewed !== null &&
              editedFilters.isReviewed !== undefined
                ? editedFilters.isReviewed
                : null
            "></app-is-reviewed-select>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        schoolYearStartTimestamp &&
        schoolYearEndTimestamp &&
        reportName !== 'support_insights' &&
        reportName !== 'badge_progress' &&
        !hideDatepicker
      "
      class="datePickerRow unconstrained noTop row">
      <div class="form-group mt-3">
        <h5>Dates:</h5>
        <app-daterange-picker
          [disableTextInput]="true"
          [minDate]="schoolYearStartTimestamp"
          [maxDate]="schoolYearEndTimestamp"
          [fromDate]="
            editedFilters.dateRangeFilter
              ? editedFilters.dateRangeFilter[0]
              : null
          "
          [toDate]="
            editedFilters.dateRangeFilter
              ? editedFilters.dateRangeFilter[1]
              : null
          "
          (selectedFromDate)="updateDateRange('from', $event)"
          (selectedToDate)="
            updateDateRange('to', $event)
          "></app-daterange-picker>
      </div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="modal.close()">Cancel</button>
      <button
        type="button"
        class="btn btn-primary"
        id="apply-button"
        (click)="applyFilters()">
        Apply
      </button>
    </div>
  </div>
</app-modal>
