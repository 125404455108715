export enum UserRole {
  DISTRICT_ADMIN = 'DISTRICT_ADMIN',
  E2L_FINANCE = 'E2L_FINANCE',
  E2L_EMPLOYEE = 'E2L_EMPLOYEE',
  REPORTS_USER = 'REPORTS_USER',
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
  SMART_COACH_USER = 'SMART_COACH_USER',
  ESUITE_ADMIN = 'eSUITEAdmin',
  USER = 'USER',
  OPERATOR = 'OPERATOR',
  REMOTE_OPERATOR = 'REMOTE_OPERATOR',
  COACH = 'COACH',
  ELEARN_RESOURCE_CREATOR = 'ELEARN_RESOURCE_CREATOR',
  SMART_COACH_ADMIN = 'SMART_COACH_ADMIN',
  PLAN_EDITOR = 'PLAN_EDITOR',
}

export type UserRolesDTO = {
  name: string;
  type: number;
  description: string;
  rule_name: string | null;
  data: string | null;
  created_at: number;
  updated_at: number;
};

export const UserNameToRolesMap = new Map<string, UserRole>([
  ['districtAdmin', UserRole.DISTRICT_ADMIN],
  ['e2L-Finance', UserRole.E2L_FINANCE],
  ['e2LEmployee', UserRole.E2L_EMPLOYEE],
  ['reportsUser', UserRole.REPORTS_USER],
  ['schoolAdmin', UserRole.SCHOOL_ADMIN],
  ['smartCoachUser', UserRole.SMART_COACH_USER],
  ['smartCoachAdmin', UserRole.SMART_COACH_ADMIN],
  ['user', UserRole.USER],
  ['eSUITEAdmin', UserRole.ESUITE_ADMIN],
  ['operator', UserRole.OPERATOR],
  ['remoteOperator', UserRole.REMOTE_OPERATOR],
  ['egroweCoach', UserRole.COACH],
  ['elearnResourceCreator', UserRole.ELEARN_RESOURCE_CREATOR],
  ['planEditor', UserRole.PLAN_EDITOR],
]);

export const getRolesFromDTO = (roles: UserRolesDTO[]): UserRole[] => {
  const userRoles: UserRole[] = [];
  roles.forEach((role) => {
    const userRole = UserNameToRolesMap.get(role.name);
    if (userRole) {
      userRoles.push(userRole);
    }
  });
  return userRoles;
};

/* eslint-disable-next-line max-len */
export const compareRoles = (roles1: UserRole[], roles2: UserRole[]): boolean =>
  roles1.some((role: UserRole) => roles2.includes(role));
