import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export interface BasicInputValue {
  label: string;
  value: string;
}
@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
})
export class BasicInputComponent {
  @Input() disabled = false;

  @Input() label: string;

  @Input() value: string;

  @Output() readonly valueChange: EventEmitter<BasicInputValue> =
    new EventEmitter<BasicInputValue>();

  uuid: string;

  constructor() {
    this.uuid = uuidv4();
  }

  outputValue() {
    this.valueChange.emit({
      label: this.label,
      value: this.value,
    });
  }
}
