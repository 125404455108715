<div class="container container-main">
  <app-dashboard-header :dashboardType="leader" />
  <div class="row gy-3">
    <div class="col-xl-8">
      <div class="row gy-3 mb-3">
        <div class="col">
          <div class="shadow h-100">
            <app-card-leader-glance
              *ngIf="reportingYear"
              [sessionParams]="yearToDateSessionParams"
              [obvservationParams]="yearToDateObservationParams"
              [strandBadgeParams]="yearToDateStrandBadgeParams"
              [microcredentialParams]="yearToDateMicrocredentialParams"
              [giParams]="yearToDateGIParams"
              [celebrationParams]="yearToDateCelebrationParams"
              [coachedParams]="peopleCoachedParams"
              [campusParams]="yearToDateCampusParams"
              [schoolYearId]="reportingYear.id"
              [otherSupportSessionsParams]="otherSupportSessionsParams"
              [user]="user" />
          </div>
        </div>
      </div>
      <div class="row gy-3 mb-3">
        <div class="col">
          <div class="shadow h-100">
            <app-card-coach-quick-stats />
          </div>
        </div>
      </div>
      <div *ngIf="activityFeedEnabled" class="row gy-3 mb-3">
        <div class="col">
          <app-card-ideas />
        </div>
        <div class="col">
          <app-card-todos />
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <ng-container *ngIf="!activityFeedEnabled; else activityFeedTemplate">
        <div class="row gy-3 mb-3">
          <div class="col"><app-card-todos /></div>
        </div>
        <div class="row gy-3">
          <div class="col"><app-card-ideas /></div>
        </div>
      </ng-container>
      <ng-template #activityFeedTemplate>
        <app-card-activity-feed
          [activityStreamToken]="user?.activityStreamToken"
          [districtId]="user?.district?.id"
          [districtTimezone]="user?.district?.timezone" />
      </ng-template>
    </div>
  </div>
</div>
