<div>
  <div class="row d-flex flex-row-reverse justify-content-between mb-1">
    <div
      class="d-flex flex-row-reverse"
      style="width: 300px; margin-right: 2px">
      <div
        style="margin-left: 3px"
        class="d-flex flex-column justify-content-center text-center">
        <div>Proficient</div>
        <div
          class="rounded"
          style="background-color: #21b0ff; height: 12px; width: 80px"></div>
      </div>
      <div
        style="margin-left: 3px"
        class="d-flex flex-column justify-content-center text-center">
        <div>75 +</div>
        <div
          class="rounded"
          style="background-color: #267dad; height: 12px; width: 80px"></div>
      </div>
      <div
        style="margin-left: 3px"
        class="d-flex flex-column justify-content-center text-center">
        <div>50-75</div>
        <div
          class="rounded"
          style="background-color: #6fadcf; height: 12px; width: 80px"></div>
      </div>
      <div
        style="margin-left: 3px"
        class="d-flex flex-column justify-content-center text-center">
        <div>25-50</div>
        <div
          class="rounded"
          style="background-color: #c6deec; height: 12px; width: 80px"></div>
      </div>
      <div
        style="margin-left: 3px"
        class="d-flex flex-column justify-content-center text-center">
        <div>&lt; 25</div>
        <div
          class="rounded"
          style="background-color: #f0eee9; height: 12px; width: 80px"></div>
      </div>
      <div class="d-flex flex-column justify-content-center text-end">
        <div>%</div>
        <div class="rounded" style="height: 12px"></div>
      </div>
    </div>
  </div>
  <div class="heatmap-border my-2">
    <div
      id="heatmap"
      echarts
      class="heatmap mt-3"
      [options]="{}"
      [loading]="isLoading"
      (chartInit)="onChartInit($event)"
      [loadingOpts]="spinnerOptions"
      (chartClick)="onClickEvent($event)"
      style="width: 100%; height: 750px"></div>
  </div>
</div>
