<div
  *ngIf="showForm && form"
  class="container-fluid form-view position-relative">
  <div *ngIf="showTitle" class="row">
    <div class="col-xs-12 my-4">
      <h1 class="text-center border-tertiary">
        {{ form.title }}
      </h1>
    </div>
  </div>
  <div [class.content-padding]="inputDisabled">
    <div class="row">
      <ng-container *ngIf="form.fields.length < 1">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 300px">
          <h2>No Fields have been created for this form</h2>
        </div>
      </ng-container>
      <div *ngFor="let field of form.fields" class="col-xs-12 mb-3">
        <div *ngIf="field.type === fieldTypes.SECTION" class="section-header">
          <h2 class="text-center">{{ field.label }}</h2>
          <div class="text-center" *ngIf="field.helperText">
            {{ field.helperText }}
          </div>
        </div>
        <div *ngIf="field.type == fieldTypes.FREETEXT" class="col-xs-12">
          {{ field.label }}
        </div>
        <app-form-view-field
          *ngIf="
            field.type !== fieldTypes.SECTION &&
            field.type !== fieldTypes.FREETEXT
          "
          [inputData]="getInputData(field)"
          [readOnly]="readOnly"
          [inputDisabled]="inputDisabled"
          (data)="updateData($event)"
          [fieldDefinition]="field"></app-form-view-field>
      </div>
    </div>
  </div>
</div>
