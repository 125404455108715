import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { schoolYearList } from './school-year.utilities';

@Injectable({
  providedIn: 'root',
})
export class SchoolYearService {
  schoolYears: ReplaySubject<SchoolYearDTO[]> = new ReplaySubject<
    SchoolYearDTO[]
  >();

  constructor(private apiCoreService: APICoreService) {
    this.getSchoolYears();
  }

  getSchoolYears() {
    /* this.apiCoreService
      .getRequest('reporting/school-years')
      .subscribe((yearItems) => {
        this.schoolYears.next(yearItems.items.reverse());
      }); */
    this.schoolYears.next(schoolYearList.reverse());
  }
}
