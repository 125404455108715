<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Create Video',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    options: { size: 'xl' },
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.dismiss()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="modal-body">
    <div class="form container" *ngIf="videoData">
      <ng-container *ngIf="!fullVideoData">
        <app-file-upload
          *ngIf="!hasVideo"
          (outputFiles)="videoUploaded($event)"
          [uploadType]="uploadTypeVideo"
          [style]="simpleUploadStyle"></app-file-upload>
        <app-file-list
          [uploadList]="uploadData"
          (deletedFile)="hasVideo = false"
          [style]="simpleUploadStyle"></app-file-list>
      </ng-container>
      <ng-container *ngIf="fullVideoData">
        <app-video-player [video]="fullVideoData"></app-video-player>
      </ng-container>
      <div class="row my-3">
        <label class="form-label" for="video-title">Video Title</label>
        <div class="input-group">
          <input
            type="text"
            id="title"
            class="form-control"
            (ngModelChange)="canVideoSave()"
            [(ngModel)]="videoData.title"
            aria-label="Video title"
            name="video-title" />
        </div>
      </div>
      <div class="row my-3">
        <div class="col-6">
          <label class="form-label" for="video-category">Category</label>
          <ng-select
            [items]="categories"
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="false"
            [clearable]="false"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            (ngModelChange)="canVideoSave()"
            [(ngModel)]="videoData.categoryId"
            name="video-category" />
        </div>
        <div class="col-6">
          <label class="form-label" for="video-role">Role</label>
          <ng-select
            [items]="roles"
            [searchable]="false"
            [clearable]="false"
            (ngModelChange)="canVideoSave()"
            [(ngModel)]="videoData.role"
            name="video-role" />
        </div>
      </div>
      <div class="row my-3">
        <label class="form-label" for="video-description">Description</label>
        <div class="input-group">
          <textarea
            [(ngModel)]="videoData.description"
            (ngModelChange)="canVideoSave()"
            name="video-description"
            class="form-control"
            id="video-description"
            aria-label="Video description"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button class="btn" (click)="this.modal.close()">Cancel</button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveVideo(); this.modal.dismiss()"
        [disabled]="!canSave">
        Create
      </button>
    </div>
  </div>
</app-modal>
