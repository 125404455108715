import { SessionCountDTO } from '../../../dtos/reports.dto';

export const coachingSessionsData = {
  datasets: [
    {
      data: [3, 5, 4, 2, 6],
    },
  ],
  labels: [62352, 49024, 25964, 69483, 98521],
};

export const coachingSessionsDataCount: SessionCountDTO[] = [
  { month_of_school_year: 1, count: 8 },
  { month_of_school_year: 2, count: 26 },
  { month_of_school_year: 3, count: 1356 },
  { month_of_school_year: 4, count: 1545 },
  { month_of_school_year: 5, count: 1497 },
  { month_of_school_year: 6, count: 83 },
  { month_of_school_year: 7, count: 58 },
  { month_of_school_year: 8, count: 75 },
  { month_of_school_year: 9, count: 102 },
  { month_of_school_year: 10, count: 940 },
  { month_of_school_year: 11, count: 50 },
  { month_of_school_year: 12, count: 66 },
];
