export const confettiOptionsDefault = {
  particles: {
    shape: {
      type: ['square', 'circle'],
    },
  },
  emitters: {
    name: 'confetti',
    startCount: 1000,
    position: {
      x: 50,
      y: 50,
    },
    size: {
      width: 70,
      height: 50,
    },
    rate: {
      delay: 0,
      quantity: 0,
    },
    life: {
      duration: 0.1,
      count: 2,
    },
  },
  preset: 'confetti',
};

export const confettiOptionsSides = {
  emitters: [
    {
      name: 'left-cannon',
      startCount: 600,
      life: {
        duration: 4,
        count: 1,
      },
      position: {
        x: 0,
        y: 30,
      },
      size: {
        width: 50,
        height: 10,
      },
      particles: {
        move: {
          speed: 85,
          direction: 'top-right',
        },
      },
    },
    {
      name: 'right-cannon',
      startCount: 800,
      rate: {
        delay: 3,
        quantity: 10,
      },
      life: {
        duration: 4,
        count: 1,
      },
      position: {
        x: 100,
        y: 30,
      },
      size: {
        width: 65,
        height: 10,
      },
      particles: {
        move: {
          speed: 100,
          direction: 'top-left',
        },
        shape: {
          type: ['circle', 'square'],
        },
      },
    },
  ],
  preset: 'confetti',
};

export const confettiOptionsBottom = {
  emitters: [
    {
      name: 'left-cannon',
      startCount: 1000,
      rate: {
        delay: 0,
        quantity: 2,
      },
      life: {
        duration: 2,
        count: 2,
      },
      position: {
        x: 20,
        y: 80,
      },
      size: {
        width: 10,
        height: 100,
      },
      particles: {
        move: {
          speed: 55,
          direction: 'top-right',
        },
      },
    },
    {
      name: 'right-cannon',
      startCount: 1200,
      rate: {
        delay: 0,
        quantity: 2,
      },
      life: {
        duration: 2,
        count: 2,
      },
      position: {
        x: 80,
        y: 80,
      },
      size: {
        width: 10,
        height: 130,
      },
      particles: {
        move: {
          speed: 45,
          direction: 'top-left',
        },
      },
    },
  ],
  preset: 'confetti',
};

export const confettiOptionsTop = {
  emitters: [
    {
      name: 'left-cannon',
      startCount: 600,
      rate: {
        delay: 0,
        quantity: 2,
      },
      life: {
        duration: 1,
        count: 2,
      },
      position: {
        x: 15,
        y: 0,
      },
      size: {
        width: 20,
        height: 70,
      },
      particles: {
        move: {
          speed: 75,
          direction: 'bottom-right',
        },
      },
    },
    {
      name: 'right-cannon',
      startCount: 600,
      rate: {
        delay: 0,
        quantity: 2,
      },
      life: {
        duration: 1,
        count: 2,
      },
      position: {
        x: 85,
        y: 0,
      },
      size: {
        width: 20,
        height: 80,
      },
      particles: {
        move: {
          speed: 75,
          direction: 'bottom-left',
        },
      },
    },
  ],
  preset: 'confetti',
};
