<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  class="year-select"
  [labelForId]="labelId"
  [placeholder]="placeholder"
  [items]="yearList"
  bindLabel="name"
  [searchable]="false"
  [clearable]="false"
  [(ngModel)]="internalSelectedYear"
  (change)="yearSelected($event); select.blur()"
  #select></ng-select>
<ng-container *ngIf="internalSelectedYearList">
  <ng-container *ngFor="let year of internalSelectedYearList">
    <span class="badge badge-item mt-2" (click)="removeYearFromList(year)"
      >{{ year.name }}<i class="bi bi-x ms-2"></i
    ></span>
  </ng-container>
</ng-container>
