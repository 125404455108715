<div
  class="card border border-secondary border-3 text-center p-3 h-100"
  [class.selected]="option.id === detailedOptionId">
  <div class="card-body d-flex flex-column justify-content-center">
    <i
      class="btn-switch bi-toggle-off"
      role="button"
      [ngClass]="{
        'bi bi-toggle-off': !option.isSelected,
        'bi bi-toggle-on': option.isSelected
      }"
      (click)="updateOption()"></i>
    <div (click)="showDetail()" class="py-4 fw-800 fs-16 title">
      {{ option.title }}
    </div>
  </div>
</div>
