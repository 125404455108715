import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, map } from 'rxjs';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { FetchLastUpdatedDate } from '../../state/reporting/reporting.actions';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public generateCSVNoRecordsReturned = new BehaviorSubject(true);

  public generateCSVNoRecordsReturnedObs =
    this.generateCSVNoRecordsReturned.asObservable();

  constructor(private apiService: APICoreService, private store: Store) {}

  getLastUpdateDate() {
    return this.apiService.getRequest('reporting/last-sync-started');
  }

  getLeaderGlanceData(
    requestParams: ReportRequestParams,
    districtId: number,
    schoolYearId: number
  ) {
    const districtFilter = requestParams.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'district'
    );

    if (!districtFilter) {
      requestParams.filters.push({
        codename: 'district',
        title: 'District',
        value: [districtId.toString()],
        displayName: 'District',
      });
    }

    const schoolYearFilter = requestParams.filters.find(
      (filter: KpiReportFilter) => filter.codename === 'school_year'
    );

    if (!schoolYearFilter) {
      requestParams.filters.push({
        codename: 'school_year',
        title: 'School Year',
        value: [schoolYearId.toString()],
        displayName: 'School Year',
      });
    }

    if (
      requestParams.dataset === 'fct_coaching_session' &&
      requestParams.id !== 'glance-other-support-sessions'
    ) {
      requestParams.filters.push({
        codename: 'is_present',
        value: ['1'],
      });
      requestParams.filters.push({
        codename: 'is_coaching',
        value: ['1'],
      });
    }
    this.store.dispatch(new FetchLastUpdatedDate());
    return this.apiService.getRequest('reporting/custom', requestParams);
  }

  getReportData(
    filters: KpiReportFilter[],
    report = 'giGrowth',
    page = 1,
    per_page = 10,
    sort_by_month = 0,
    sort_by_name = 0,
    sort_by_desc = 0
  ) {
    const params = {
      report,
      filters,
      page,
      per_page,
      sort_by_month,
      sort_by_name,
      sort_by_desc,
    };
    this.store.dispatch(new FetchLastUpdatedDate());
    return this.apiService
      .getRequest('reporting/load-report', params)
      .pipe(map((res) => res));
  }

  generateReportCSV(report: string, filters: KpiReportFilter[]) {
    const params = {
      report,
      filters,
    };
    this.store.dispatch(new FetchLastUpdatedDate());
    return this.apiService
      .getRequest('reporting/load-report', params)
      .pipe(map((res) => res));
  }
}
