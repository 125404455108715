import {
  ResourceListItemDTO,
  ResourceLeveledListItemDTO,
} from '../../dtos/resources.dto';

export const resourceLeveledListItemDTOToResourceListItemDTO = (
  resourceDto: ResourceLeveledListItemDTO
): ResourceListItemDTO => ({
  id: resourceDto.id,
  title: resourceDto.title,
  description: resourceDto.description,
  type: resourceDto.type,
  slug: resourceDto.slug,
  userFavoriteShelvs: resourceDto.userFavoriteShelves,
  updated_at: resourceDto.updated_at,
  url: resourceDto.url,
  isOnUsersShelf: resourceDto.isOnUsersShelf,
  global_resource: resourceDto.global_resource,
  flag: resourceDto.flag,
  content: resourceDto.content,
});
