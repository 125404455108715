import { Component, Input } from '@angular/core';
import { ItemNeedAttentionAPIResponse } from '../../../types/responses/plan.responses';

@Component({
  selector: 'app-phase-risk-delays-card',
  templateUrl: './phase-risk-delays-card.component.html',
  styleUrl: './phase-risk-delays-card.component.scss',
})
export class PhaseRiskDelaysCardComponent {
  @Input() items: ItemNeedAttentionAPIResponse[];
}
