<div *ngIf="celebration" class="row celebration-item mb-3 p-3">
  <div class="col-sm-12 col-xl-5 d-flex flex-column pt-3 px-4">
    <div class="col-12 fw-800 celebration-name mb-3">
      <ng-container *ngIf="celebration.users">
        <ng-container
          *ngIf="
            celebration.users.length < 2 && celebration.users.length > 0;
            else multipleUsers
          ">
          <span>{{
            celebration.users[0].profile.first_name +
              " " +
              celebration.users[0].profile.last_name
          }}</span>
        </ng-container>
        <ng-template #multipleUsers>
          <span *ngFor="let user of celebration.users; last as isLast"
            >{{ user.profile.first_name + " " + user.profile.last_name
            }}<span *ngIf="!isLast">, </span>
          </span>
        </ng-template>
      </ng-container>
    </div>
    <div class="col-12 fs-14 celebration-content pb-2">
      <app-safehtml [html]="celebration.content" />
    </div>
    <div
      class="col-12 d-flex justify-content-between pt-2 fs-12 mt-auto celebration-footer">
      <div>
        <span class="fw-800"> Date: </span>
        {{ celebration.updatedAt * 1000 | date }}
      </div>
      <div>
        <span class="fw-800"> Created By: </span>
        {{
          celebration.endorser.profile.first_name +
            " " +
            celebration.endorser.profile.last_name
        }}
      </div>
    </div>
  </div>
  <div
    class="col-sm-12 col-md-6 col-xl-3 col-sm-12 p-4 d-flex align-items-center justify-content-center celebration-image-container">
    <ng-container *ngIf="images.length > 0; else noImage">
      <ng-container *ngIf="images.length === 1; else carousel">
        <div
          class="celebration-image"
          [style.backgroundImage]="images[0]"></div>
      </ng-container>
      <ng-template #carousel>
        <ngb-carousel
          [animation]="carouselAnimation"
          [showNavigationIndicators]="showNavigationIndicators"
          [interval]="carouselInterval">
          <ng-template *ngFor="let image of images" ngbSlide>
            <div
              class="celebration-image"
              [style.backgroundImage]="image"></div>
          </ng-template>
        </ngb-carousel>
      </ng-template>
    </ng-container>
    <ng-template #noImage>
      <div
        class="celebration-image no-image"
        [style.backgroundImage]="'url(/assets/celebrations.svg)'"></div>
    </ng-template>
  </div>
  <div class="col-sm-12 col-md-6 col-xl-4 p-4 fs-12">
    <div class="fw-800">Districts:</div>
    <div class="ps-3">
      <div *ngFor="let district of districts">
        <app-district-name [includeDash]="true" [districtId]="district" />
      </div>
    </div>
    <div class="my-2">
      <div class="fw-800">Schools:</div>
      <div class="ps-3">
        <div *ngFor="let school of schools">
          <app-school-name [includeDash]="true" [schoolId]="school" />
        </div>
      </div>
    </div>
    <div *ngIf="celebration.competencies.length > 0" class="mb-2">
      <div class="fw-800">Competencies:</div>
      <div class="ps-3">
        <div *ngFor="let competency of celebration.competencies">
          - {{ competency.title }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="celebration.files.length > 0">
      <div class="fw-800">Files:</div>
      <div class="ps-3">
        <div
          *ngFor="let file of celebration.files"
          (click)="viewFile(file.file_path)"
          class="view-file-link">
          - {{ file.title }}
        </div>
      </div>
    </ng-container>
  </div>
  <div class="icon-group d-flex justify-content-end align-items-center">
    <button
      *ngIf="canEdit"
      (click)="handleEditCelebrationEvent()"
      aria-label="edit-celebration-button"
      class="btn btn-reset">
      <i class="bi bi-pencil-square"></i>
    </button>
    <ng-container *ngIf="canDelete">
      <button
        aria-label="delete celebration button"
        class="btn btn-reset"
        type="button"
        [autoClose]="'outside'"
        [ngbPopover]="contentTemplate"
        [popoverTitle]="'Delete Celebration'"
        #popover="ngbPopover">
        <i class="bi bi-trash3"></i>
      </button>
      <ng-template #contentTemplate>
        Are you sure you want to delete this celebration?
        <div class="text-end mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="popover.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="handleDeleteCelebrationEvent(); popover.close()"
            href="javascript:void(0)">
            Yes
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div
    *ngIf="canShare"
    (click)="shareCelebration()"
    class="share-icon d-flex justify-content-center align-items-center">
    <button aria-label="share-button-twitter" class="btn btn-reset">
      <i class="bi bi-twitter"></i>
    </button>
  </div>
</div>
