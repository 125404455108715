<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  [items]="tagList$ | async"
  bindLabel="tag"
  [addTag]="false"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [minTermLength]="2"
  [loading]="isLoading"
  typeToSearchText="Please enter 2 or more characters"
  [typeahead]="searchInput$"
  [(ngModel)]="selectedTag"
  (change)="changedSelection($event)"
  [placeholder]="placeholder"
  [labelForId]="labelId">
</ng-select>
