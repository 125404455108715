import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  KpiReportFilter,
  QuickStatsReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { QuickStatsAPIResponse } from '../../types/responses/coachee-quick-stats.responses';

@Injectable({
  providedIn: 'root',
})
export class CoachStatsService {
  constructor(private apiService: APICoreService) {}

  getAvgFeedbackRating(districtId: number): Observable<QuickStatsAPIResponse> {
    const params: QuickStatsReportRequestParams = {
      report: 'quickStats',
      action: 'average_feedback_rating',
      filters: [],
    };

    const filter: KpiReportFilter = {
      codename: 'district',
      value: [districtId.toString()],
    };
    params.filters.push(filter);

    return this.apiService.getRequest('reporting/load-report', params);
  }

  getAvgCoacheeConfidenceRating(
    districtId: number
  ): Observable<QuickStatsAPIResponse> {
    const params: QuickStatsReportRequestParams = {
      report: 'quickStats',
      action: 'average_coachee_confidence_rating',
      filters: [],
    };

    const filter: KpiReportFilter = {
      codename: 'district',
      value: [districtId.toString()],
    };
    params.filters.push(filter);

    return this.apiService.getRequest('reporting/load-report', params);
  }

  getAvgBadgesPerSession(
    districtId: number
  ): Observable<QuickStatsAPIResponse> {
    const params: QuickStatsReportRequestParams = {
      report: 'quickStats',
      action: 'average_badges_per_session',
      filters: [],
    };

    const filter: KpiReportFilter = {
      codename: 'district',
      value: [districtId.toString()],
    };
    params.filters.push(filter);

    return this.apiService.getRequest('reporting/load-report', params);
  }

  getAvgSessionsPerCoach(
    districtId: number
  ): Observable<QuickStatsAPIResponse> {
    const params: QuickStatsReportRequestParams = {
      report: 'quickStats',
      action: 'average_sessions_per_coachee',
      filters: [],
    };

    const filter: KpiReportFilter = {
      codename: 'district',
      value: [districtId.toString()],
    };
    params.filters.push(filter);

    return this.apiService.getRequest('reporting/load-report', params);
  }
}
