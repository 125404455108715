import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { CompetenciesSearchService } from '../../services/competencies/competencies-search.service';
import { LeveledCompetencyModalComponent } from '../competency/leved-competency-modal/leveled-competency-modal.component';

@Component({
  selector: 'app-growth-snapshot',
  templateUrl: './growth-snapshot.component.html',
  styleUrls: ['./growth-snapshot.component.scss'],
})
export class GrowthSnapshotComponent implements OnInit {
  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  isLoading = true;

  last5: number[];

  isCollapsed = this.cardStorageService.getCollapsedState('growthsnapshot');

  user: User;

  constructor(
    private cardStorageService: CardStorageService,
    private competencyService: CompetenciesSearchService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getLast5Competencies();
  }

  getLast5Competencies() {
    this.competencyService.getLast5().subscribe((res) => {
      if (res) {
        this.last5 = res;
      }
      this.isLoading = false;
    });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'growthsnapshot',
      this.isCollapsed
    );
  }

  getFourLevelRubric(competencyId: number) {
    this.competencyModal.portfolio = true;
    this.competencyModal.competencyId = competencyId;
    this.competencyModal.userId = this.user.id;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }
}
