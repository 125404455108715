import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { TagDTO } from '../../dtos/tags.dto';

@Injectable({
  providedIn: 'root',
})
export class TagSearchService {
  // search results
  private searchResultsData: TagDTO[] | null = null;

  private searchResultsSource = new BehaviorSubject(this.searchResultsData);

  public searchResultsObs = this.searchResultsSource.asObservable();

  // search results meta
  private searchResultsMetaData: PaginationDTO | null = null;

  private searchResultsMetaSource = new BehaviorSubject(
    this.searchResultsMetaData
  );

  public searchResultsMetaObs = this.searchResultsMetaSource.asObservable();

  constructor(private apicoreService: APICoreService) {}

  performSearch(searchTerm: string) {
    return this.apicoreService
      .getRequest('tags/search', { search: searchTerm })
      .pipe(
        map((results) => {
          let TagList: TagDTO[] = [];
          if (results.items) {
            TagList = results.items;
          } else {
            TagList = [];
          }
          // we populate observables, as well as return the Taglist.
          this.searchResultsSource.next(TagList);
          this.searchResultsMetaSource.next(results._meta);
          return TagList;
        })
      );
  }
}
