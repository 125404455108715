import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { connect, StreamClient, UR } from 'getstream';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { User } from 'src/app/common/state/user/user.model';

export enum FeedName {
  DISTRICT = 'district',
  USER = 'user',
  PLAN = 'plan',
}

type User1Type = { name: string; username: string; image?: string };
type User2Type = { name: string; avatar?: string };
type ActivityType = { attachments: string[]; text: string };
type Collection1Type = { cid: string; rating?: number };
type Collection2Type = { branch: number; location: string };

type ReactionType = { text: string };
type ChildReactionType = { text?: string };

export type StreamType = {
  userType: User1Type | User2Type;
  activityType: ActivityType;
  collectionType: Collection1Type | Collection2Type;
  reactionType: ReactionType;
  childReactionType: ChildReactionType;
  personalizationType: UR;
};

@Injectable({
  providedIn: 'root',
})
export class ActivityFeedService {
  user: User;

  client: StreamClient<StreamType>;

  constructor(private readonly store: Store) {}

  // eslint-disable-next-line class-methods-use-this
  getActivityFeed(userToken: string, feedName: FeedName, feedId: string) {
    const client = connect<StreamType>(
      EnvironmentService.getStreamApiKey(),
      userToken,
      EnvironmentService.getStreamAppId()
    );
    return client.feed(feedName, feedId);
  }
}
