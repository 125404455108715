import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ResourceLeveledListItemDTO } from '../../../dtos/resources.dto';
import { CoachingLogResourceService } from '../../../services/coaching-log/coaching-log-resource.service';

@Component({
  selector: 'app-resource-leveled-list',
  templateUrl: './resource-leveled-list.component.html',
  styleUrls: ['./resource-leveled-list.component.scss'],
})
export class ResourceLeveledListComponent implements OnInit, OnDestroy {
  @Input() resources: ResourceLeveledListItemDTO[];

  @Input() levelNumber: number;

  limitResources = true;

  limit = 3;

  subs: Subscription[] = [];

  constructor(public coachlogResourceService: CoachingLogResourceService) {}

  ngOnInit(): void {
    this.subs.push(
      this.coachlogResourceService.resourceRestored.subscribe((resourceId) => {
        if (resourceId) {
          this.restoreResource(resourceId);
        }
      })
    );
  }

  removeResource(resId: number) {
    this.resources = this.resources.filter((res) => res.id !== resId);
  }

  restoreResource(id: number) {
    const resource = this.coachlogResourceService.leveledResources.find(
      (item) => id === item.id
    );
    if (resource) {
      this.resources.push(resource);
    }
  }

  toggleLimit() {
    if (this.limitResources) {
      this.limit = 50;
    } else {
      this.limit = 3;
    }
    this.limitResources = !this.limitResources;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
