<app-modal
  #createQWSessionModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'New Quick Walk Session',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="form-label">Participant:</div>
  <ng-container *ngIf="selectedAttendee">
    <app-new-coaching-attendee
      [user]="selectedAttendee"
      (removedAttendee)="removeSelectedAttendee()" />
  </ng-container>
  <ng-container *ngIf="!this.selectedAttendee">
    <div *ngIf="formSubmitted" class="color-danger p-2">
      A coachee must be selected
    </div>
    <app-user-select
      [includeAllB2bDistricts]="includeAllB2bDistricts"
      [clearAfterSelection]="true"
      (selectedUser)="addSelectedAttendee($event)" />
  </ng-container>
  <div>
    <label class="form-label mt-3" for="coachlog-type">Session Type</label>
    <ng-select
      id="coachlog-type"
      (change)="logTypeSelected($event); select.blur()"
      [items]="defaultLogList"
      bindLabel="title"
      [searchable]="false"
      [clearable]="false"
      [(ngModel)]="selectedLogType"
      [placeholder]="'Select a session type'"
      #select>
    </ng-select>
  </div>
  <ng-container *ngIf="this.selectedLogType">
    <div>
      <label class="form-label" for="coachlog-title">Title</label>
      <input
        type="text"
        class="form-control mb-3"
        id="coachlog-title"
        aria-label="Session Title"
        placeholder="Add Session Title"
        [(ngModel)]="coachlogItemsPayload.title"
        [style.border]="
          formSubmitted && !coachlogItemsPayload.title ? '1px solid red' : ''
        "
        [style.display]="'inline-block'" />
    </div>
  </ng-container>
  <div footerContent>
    <ng-container *ngIf="serverError.length > 0">
      <ngb-alert [dismissible]="false" [type]="'danger'">
        <strong>Error</strong> {{ serverError }}
      </ngb-alert>
    </ng-container>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="closeModal()">Cancel</button>
      <button
        [disabled]="isLoading"
        type="button"
        class="btn btn-md btn-primary"
        (click)="createCoachingLog()">
        <ng-container *ngIf="isLoading">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        Create
      </button>
    </div>
  </div>
</app-modal>
