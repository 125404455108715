<div class="container rosters-page">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card border-0 p-3 roster-container">
    <app-settings-nav [districtCode]="districtCode" />
    <div class="d-flex mt-3 justify-content-center">
      <div
        class="btn-group btn-group-md roster-btn-group"
        role="group"
        aria-label="Basic radio toggle button group">
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="currentView"
          autocomplete="off"
          [checked]="activeSection == 'schools'"
          (click)="changeSection('schools')" />
        <label class="btn btn-outline-primary" for="currentView">Schools</label>
        <input
          type="radio"
          class="btn-check"
          name="btnradio"
          id="archivedView"
          autocomplete="off"
          [checked]="activeSection == 'users'"
          (click)="changeSection('users')" />
        <label class="btn btn-outline-primary" for="archivedView">Users</label>
      </div>
    </div>

    <div class="row d-flex justify-content-between my-3">
      <div class="col-md-4 order-2 order-md-1 table-search">
        <ng-container *ngIf="activeSection == 'users'">
          <div>
            <input
              #searchText
              type="search"
              class="form-control ms-1 table-search"
              placeholder="Search Users"
              aria-label="Search"
              (input)="searchTermChanged($event)" />
          </div>
        </ng-container>
        <ng-container *ngIf="activeSection == 'schools'">
          <input
            #searchText
            type="search"
            class="form-control ms-1"
            placeholder="Search Schools"
            aria-label="Search Schools"
            (input)="searchSchools($event)" />
        </ng-container>
      </div>
      <div
        class="col-md-8 order-1 order-md-2 d-flex justify-content-center justify-content-md-end my-3 my-md-0">
        <ng-container *ngIf="district && activeSection === 'users'">
          <button
            class="btn btn-primary mx-2"
            (click)="showCreateUserModal = true">
            <i class="bi bi-plus me-1"></i>Create New User
          </button>
          <app-create-user-modal
            [(showModal)]="showCreateUserModal"
            [districtId]="district.id" />
          <app-edit-user-modal
            [(showModal)]="showEditUserModal"
            (showModalChange)="searchUsers()"
            [districtId]="district.id"
            [user]="editUser" />
        </ng-container>
        <ng-container *ngIf="district && activeSection === 'schools'">
          <button
            class="btn btn-primary me-2"
            (click)="showRostersSchoolModal = true">
            <i class="bi bi-plus me1"></i> Create New School
          </button>
          <app-roster-school-create-modal
            [(showModal)]="showRostersSchoolModal"
            (schoolCreated)="handleCreateSchool($event)"
            [districtId]="district.id"></app-roster-school-create-modal>
          <app-roster-school-edit-modal
            [(showModal)]="showEditSchoolModal"
            (editSchoolEvent)="handleSchoolUpdate($event)"
            (deleteSchoolEvent)="handleDeleteSchool($event)"
            [districtId]="district.id"
            [schoolId]="editSchoolId"></app-roster-school-edit-modal>
          <button
            class="btn btn-primary"
            (click)="
              uploadModal.config.titleText = 'Upload Schools CSV';
              uploadModal.open()
            ">
            <i class="bi bi-upload me-2"></i>
            Upload Schools
          </button>
        </ng-container>
        <ng-container *ngIf="district && activeSection == 'users'">
          <div class="d-flex align-items-center">
            <div ngbDropdown placement="bottom">
              <button
                type="button"
                class="btn btn-primary ms-auto"
                id="userUploadDropdown"
                ngbDropdownToggle>
                <i class="bi bi-upload me-2"></i>
                Upload Users
              </button>
              <div
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="userUploadDropdown">
                <button
                  ngbDropdownItem
                  class="py-2"
                  (click)="
                    uploadModal.config.titleText =
                      'Upload Users: OneRoster CSV';
                    uploadModal.open()
                  ">
                  OneRoster CSV
                </button>
                <button
                  ngbDropdownItem
                  class="py-2"
                  (click)="groweLabUserUploadModal.modal.open()">
                  GroweLab CSV
                </button>
              </div>
            </div>
            <i
              class="bi bi-info-circle-fill ms-2 color-primary"
              ngbTooltip
              #t="ngbTooltip"
              [ngbTooltip]="userUploadTooltip"
              triggers="manual"
              (mouseenter)="t.open()">
            </i>
            <ng-template #userUploadTooltip>
              <div
                class="p-2 pb-4"
                (mouseenter)="t.open()"
                (mouseleave)="t.close()">
                GroweLab supports two CSV formats for uploading multiple users
                at a time:
                <br />
                OneRoster CSV and GroweLab CSV.
                <br />
                <br />
                <a
                  href="https://learn.growelab.app/en/articles/8063054-how-to-add-new-users"
                  target="_blank">
                  Click here
                </a>
                to learn more.
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- BEGIN BULK SELECTION -->
    <div
      *ngIf="activeSection == 'users' && selectedUsers && selectedUsers.length"
      class="d-flex align-items-center my-2 bulk-bar">
      <div *ngIf="!allSelected" class="me-5" style="white-space: nowrap">
        {{ selectedUsers.length }} selected
      </div>
      <ng-container *ngIf="showSelectAll && !allSelected">
        <button
          class="btn-link btn-reset me-5"
          style="white-space: nowrap"
          (click)="selectAllUsers()">
          Select all {{ userSearchResults._meta.totalCount }} users
          <span
            *ngIf="allUsersLoading"
            class="spinner-border spinner-border-sm me-1"></span>
        </button>
      </ng-container>
      <ng-container *ngIf="allSelected">
        <div class="me-3" style="white-space: nowrap">
          All {{ userSearchResults._meta.totalCount }} users are selected.
        </div>
      </ng-container>
      <ng-container *ngIf="selectedUsers && selectedUsers.length">
        <button
          class="btn-link btn-reset me-5"
          style="white-space: nowrap"
          (click)="clearAllUsers()">
          Clear Selection
        </button>
      </ng-container>
      <div class="input-group w-100 d-flex">
        <ng-select
          aria-label="Bulk Action "
          placeholder="Select a Bulk Action"
          [(ngModel)]="selectedBulkAction"
          [clearable]="true"
          class="bulk-action"
          style="min-width: 230px">
          <ng-option value="" disabled>Select a Bulk Action</ng-option>
          <ng-option value="deactivate">Deactivate</ng-option>
          <ng-option value="home-school">Change Home School</ng-option>
          <ng-option value="user-type">Change User Type</ng-option>
          <ng-option value="assigned-coach">Change Assigned Coach</ng-option>
          <ng-option value="exclude">Exclude from reports</ng-option>
          <ng-option value="include">Include in reports</ng-option>
        </ng-select>
        <ng-container
          *ngIf="
            selectedBulkAction === 'deactivate' ||
            selectedBulkAction === 'exclude' ||
            selectedBulkAction === 'include'
          ">
          <button
            aria-label="Apply"
            class="btn btn-group-apply fw-600"
            type="button"
            [autoClose]="'outside'"
            [ngbPopover]="updateGeneralTemplate"
            [popoverTitle]="'Update Users'"
            #popover="ngbPopover">
            Apply
            <span
              *ngIf="bulkSubmitting"
              class="spinner-border spinner-border-sm me-1"></span>
          </button>
          <ng-template #updateGeneralTemplate>
            Are you sure you want to update
            <strong
              >{{ selectedUsers.length }} user<span
                *ngIf="selectedUsers.length > 1"
                >s</span
              ></strong
            >?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="applyBulkChanges(); popover.close()">
                Yes
              </button>
            </div>
          </ng-template>
        </ng-container>

        <!-- UPDATE SCHOOL -->
        <div
          *ngIf="selectedBulkAction === 'home-school' && schoolData"
          style="min-width: 250px">
          <ng-select
            aria-label="School"
            placeholder="Select a school"
            class="secondary-action"
            [clearable]="true"
            [(ngModel)]="selectedSchoolId">
            <ng-container *ngFor="let school of schoolData">
              <ng-option [value]="school.id">{{ school.title }}</ng-option>
            </ng-container>
          </ng-select>
        </div>
        <ng-container *ngIf="selectedBulkAction === 'home-school'">
          <button
            aria-label="Apply"
            [disabled]="!selectedSchoolId"
            class="btn btn-group-apply fw-600"
            type="button"
            [ngbPopover]="updateSchoolTemplate"
            [popoverTitle]="'Update Users'"
            #popover="ngbPopover">
            Apply
            <span
              *ngIf="bulkSubmitting"
              class="spinner-border spinner-border-sm me-1"></span>
          </button>
          <ng-template #updateSchoolTemplate>
            Are you sure you want to change the home school for
            <strong
              >{{ selectedUsers.length }} user<span
                *ngIf="selectedUsers.length > 1"
                >s</span
              ></strong
            >?
            <div class="text-end mt-2">
              <button
                [disabled]="bulkSubmitting"
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="applyBulkChanges(); popover.close()">
                Yes
              </button>
            </div>
          </ng-template>
        </ng-container>
        <!-- UPDATE USER TYPE -->
        <div
          *ngIf="selectedBulkAction === 'user-type'"
          style="min-width: 250px">
          <ng-select
            aria-label="User Type"
            placeholder="Select a user type"
            class="secondary-action"
            [(ngModel)]="selectedUserType"
            [clearable]="false">
            <ng-option value="Teacher">Teacher</ng-option>
            <ng-option value="Coach">Coach</ng-option>
            <ng-option value="District Admin">District Admin</ng-option>
            <ng-option value="School Admin">School Admin</ng-option>
          </ng-select>
        </div>
        <ng-container *ngIf="selectedBulkAction === 'user-type'">
          <button
            aria-label="Apply"
            [disabled]="!selectedUserType"
            class="btn btn-group-apply fw-600"
            type="button"
            [ngbPopover]="updateTypeTemplate"
            [popoverTitle]="'Update Users'"
            #popover="ngbPopover">
            Apply
            <span
              *ngIf="bulkSubmitting"
              class="spinner-border spinner-border-sm me-1"></span>
          </button>
          <ng-template #updateTypeTemplate>
            Are you sure you want to change the user type for
            <strong
              >{{ selectedUsers.length }} user<span
                *ngIf="selectedUsers.length > 1"
                >s</span
              ></strong
            >?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="applyBulkChanges(); popover.close()">
                Yes
              </button>
            </div>
          </ng-template>
        </ng-container>

        <!-- UPDATE ASSIGNED COACH -->
        <div
          *ngIf="selectedBulkAction === 'assigned-coach'"
          class="assigned-coach"
          style="min-width: 250px">
          <app-user-select
            (selectedUser)="handleSelectedCoach($event)"
            [defaultUsers]="[]" />
        </div>
        <ng-container *ngIf="selectedBulkAction === 'assigned-coach'">
          <button
            aria-label="Apply"
            [disabled]="!selectedCoach"
            class="btn btn-group-apply fw-600"
            type="button"
            [autoClose]="'outside'"
            [ngbPopover]="updateUsersTemplate"
            [popoverTitle]="'Update Users'"
            #popover="ngbPopover">
            Apply
            <span
              *ngIf="bulkSubmitting"
              class="spinner-border spinner-border-sm me-1"></span>
          </button>
          <ng-template #updateUsersTemplate>
            Are you sure you want to change the assigned coach for
            <strong
              >{{ selectedUsers.length }} user<span
                *ngIf="selectedUsers.length > 1"
                >s</span
              ></strong
            >?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="applyBulkChanges(); popover.close()">
                Yes
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <!-- END BULK SELECTION -->
    <ng-container *ngIf="district">
      <ng-container *ngIf="activeSection == 'schools'">
        <ng-container *ngIf="schoolData; else loadingTemplate">
          <app-roster-schools
            [schoolData]="schoolData"
            (editSchoolEvent)="handleClickEditSchool($event)" />
        </ng-container>
      </ng-container>
      <ng-container *ngIf="activeSection == 'users'">
        <app-roster-users
          [isLoading]="usersTableIsLoading"
          [clearSelectionsSubject]="clearSelectionsSubject"
          [selectAllSubject]="selectAllBSubject"
          [searchResults]="userSearchResults"
          (editUserEvent)="handleEditUser($event)"
          (usersSelected)="handleSelectedUsers($event)"
          (searchChange)="handleUserSearchChange($event)" />
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loadingTemplate>
  <div style="clear: both; height: 300px"></div>
  <app-loader [top]="200" />
</ng-template>

<app-modal
  #uploadModal
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: false
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="uploadModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <div class="fs-18 my-4">
      Upload your
      {{ activeSection === "schools" ? "Schools" : "Users OneRoster" }} CSV
      file.
    </div>
    <app-csv-upload
      *ngIf="uploadModal.open"
      [districtId]="districtId"
      [type]="activeSection"></app-csv-upload>
  </div>
</app-modal>

<app-growelab-user-csv-upload-modal
  #groweLabUserUploadModal
  [districtId]="districtId"></app-growelab-user-csv-upload-modal>
