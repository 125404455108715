<app-modal
  #editDeliverableModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'Edit Deliverable',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    options: {
      size: 'xl',
    },
    customFooter: true,
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="form">
    <form *ngIf="form" [formGroup]="form">
      <div class="d-flex mt-3 justify-content-center">
        <div
          class="btn-group btn-group-md roster-btn-group"
          role="group"
          aria-label="Basic radio toggle button group">
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="currentView"
            autocomplete="off"
            [checked]="activeSection == 'details'"
            (click)="changeSection('details')" />
          <label class="btn btn-outline-primary" for="currentView"
            >Details</label
          >
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="archivedView"
            autocomplete="off"
            [checked]="activeSection == 'content'"
            (click)="changeSection('content')" />
          <label class="btn btn-outline-primary" for="archivedView"
            >Content</label
          >
        </div>
      </div>

      <div class="p-md-5">
        <ng-container *ngIf="activeSection == 'details'">
          <div class="row">
            <div class="col-12 col-md-6">
              <label for="title" class="form-label">Deliverable Name:</label>
              <div class="input-group">
                <input
                  formControlName="title"
                  type="text"
                  class="form-control"
                  id="title"
                  aria-label="Title"
                  [ngClass]="{
                    'is-invalid': f['title'].errors
                  }" />
              </div>
              <label for="description" class="form-label">Description:</label>
              <div class="input-group">
                <textarea
                  formControlName="description"
                  class="form-control"
                  id="description"
                  aria-label="Description"
                  style="height: 200px"
                  [ngClass]="{
                    'is-invalid': formSubmitted && f['description'].errors
                  }"></textarea>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <label class="form-label">Status:</label>
              <app-plans-status-select
                #statusSelect
                [status]="selectedStatus"
                (selectedStatus)="
                  onDeliverableStatusChange($event)
                "></app-plans-status-select>

              <label for="department" class="form-label mt-3"
                >Department:</label
              >
              <div class="input-group">
                <input
                  formControlName="department"
                  type="text"
                  class="form-control"
                  id="department"
                  aria-label="Department"
                  [ngClass]="{
                    'is-invalid': formSubmitted && f['department'].errors
                  }" />
              </div>

              <div class="row mt-3">
                <div class="col">
                  <label class="form-label mt-0" for="plan-start-date"
                    >Start Date:</label
                  >
                  <app-datepicker
                    #startDatePicker
                    id="plan-start-date"
                    [initialDateUnixTimestamp]="defaultStartDate"
                    (dateSelectedUnixTimestamp)="updateStartDate($event)" />
                </div>
                <div class="col">
                  <label class="form-label mt-0" for="plan-end-date"
                    >Due Date:</label
                  >
                  <app-datepicker
                    #dueDatePicker
                    id="plan-end-date"
                    [initialDateUnixTimestamp]="defaultDueDate"
                    (dateSelectedUnixTimestamp)="updateDueDate($event)" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <label for="assigneesSelect" class="form-label mt-0"
                    >Assignee(s):</label
                  >
                  <app-user-select
                    #assigneesSelect
                    [districtIds]="
                      isE2L && user.district
                        ? [districtId, user.district.id]
                        : [districtId]
                    "
                    [clearAfterSelection]="true"
                    (selectedUser)="addAssignee($event)"></app-user-select>
                  <div class="p-3" *ngIf="assignees && assignees.length > 0">
                    <div
                      class="d-flex flex-nowrap align-items-center justify-content-start my-2"
                      *ngFor="let assignee of assignees">
                      <app-avatar
                        style="margin-left: -5px"
                        avatarSize="small"
                        [profile]="assignee.profile"
                        data-testid="attendee-avatar"></app-avatar>
                      <div class="user-name ms-2">
                        {{ assignee.profile.first_name }}
                        {{ assignee.profile.last_name }}
                      </div>
                      <button
                        (click)="deleteAssignee(assignee.id)"
                        aria-label="remove assignee button"
                        class="btn btn-delete btn-sm"
                        type="button"
                        ngbTooltip="Remove Assignee"
                        [autoClose]="'outside'">
                        <i class="bi bi-x-circle-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="activeSection == 'content'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <label for="wysiwyg" class="form-label">Notes:</label>
              <app-wysiwyg-editor
                #wysiwyg
                [version]="'mini'"
                (editorContentEvent)="updateNotes($event)"
                [config]="editorConfig" />

              <div class="mt-5 mb-3">
                <label class="form-label">Attachment(s):</label>
                <app-file-list
                  #fileListComponent
                  [uploadType]="uploadType"
                  [fileList]="attachments"
                  (deletedFile)="deleteAttachment($event)" />
                <app-file-upload
                  #uploadComponent
                  [uploadType]="uploadType"
                  [style]="uploadStyle"
                  [multiple]="true"
                  (outputFiles)="updateAttachments($event)" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <label class="form-label">Comments:</label>
              <app-plans-comments
                [commentList]="commentList"
                (commentDeleted)="getComments()"
                [refTable]="'implementation_deliverable'"
                [refTableId]="deliverable.id" />
            </div>
          </div>
        </ng-container>
      </div>
      <div class="custom-footer">
        <div class="d-flex justify-content-between">
          <button
            type="button"
            class="btn"
            [ngbPopover]="deleteConfirmTemplate"
            [popoverTitle]="'Delete Session'"
            #popover="ngbPopover">
            Delete
          </button>
          <ng-template #deleteConfirmTemplate>
            Are you sure you want to delete this Session?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="deleteDeliverable(deliverable.id); popover.close()">
                Yes
              </button>
            </div>
          </ng-template>

          <button
            [disabled]="isLoading || form.invalid"
            type="button"
            class="btn btn-md btn-primary"
            (click)="submitDeliverableData()">
            <ng-container *ngIf="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </ng-container>
            Save
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</app-modal>
