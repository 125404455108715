<app-modal #modal [modalConfig]="modalConfig">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.dismiss()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <p class="fw-bold text-center">Evidence Self-Assessment</p>
  <ng-container *ngIf="coachUser">
    <app-smart-coach-dialog-single
      [coachProfile]="coachUser.profile"
      [message]="
        'Use the checklist to guide your self-assessment of the evidence you are adding to your portfolio.'
      "></app-smart-coach-dialog-single>
  </ng-container>
  <ng-container *ngIf="evidence">
    <div ngbAccordion class="mt-3">
      <div ngbAccordionItem [collapsed]="false" class="accordion">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>
            <p class="mb-1">
              <span class="fw-bold">Title: </span>{{ evidence.title }}
            </p>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="accordion-content px-3">
                <p class="fw-bold mt-2 mb-1">Description:</p>
                <app-safehtml [html]="evidence.narrative"></app-safehtml>
                <ng-container *ngIf="uploadedFiles">
                  <app-file-list
                    [fileList]="uploadedFiles"
                    [canDelete]="false"></app-file-list>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div ngb-accordion>
      <div ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="d-flex justify-content-between w-100 border-0 text-start p-0 mb-1 mt-4 bg-transparent"
            [class.collapsed]="!opened">
            <p class="mb-1">
              <span class="fw-bold">Title: </span>{{ evidence.title }}
            </p>
            <i class="bi bi-caret-{{ opened ? 'down' : 'up' }}-fill"></i>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="accordion-content">
            <p class="fw-bold mt-2 mb-1">Description:</p>
            <app-safehtml [html]="evidence.narrative"></app-safehtml>
            <ng-container *ngIf="uploadedFiles">
              <app-file-list
                [fileList]="uploadedFiles"
                [canDelete]="false"></app-file-list>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <hr class="border border-primary border-1 my-4" />
  <p class="text-center fw-bold mb-0">{{ strandTitle }} - Level {{ level }}</p>
  <div class="text-center mb-4">
    <app-safehtml
      *ngIf="levelData"
      [html]="levelData.indicators[0].content"></app-safehtml>
  </div>
  <ng-container
    *ngIf="requirements && requirements.length > 0; else noRequirementsFound">
    <ng-container *ngFor="let requirement of requirements">
      <div class="mb-4 form-check">
        <input
          class="form-check-input me-1"
          type="checkbox"
          role="checkbox"
          [attr.id]="'requirement-checkbox-' + requirement.id"
          [(ngModel)]="requirement.checked" />
        <label
          class="form-check-label ms-1"
          [attr.for]="'requirement-checkbox-' + requirement.id"
          >{{ requirement.requirementText }}</label
        >
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noRequirementsFound>
    <p class="fw-light text-body-secondary text-center">
      No requirements found.
    </p>
  </ng-template>
  <div footerContent>
    <button
      (click)="submitSelfAssessment()"
      type="button"
      class="btn btn-md btn-primary">
      Done
    </button>
  </div>
</app-modal>
