import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { IntroSessionService } from '../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-intro-session-summary',
  templateUrl: './intro-session-summary.component.html',
  styleUrls: ['./intro-session-summary.component.scss'],
})
export class IntroSessionSummaryComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  user: User;

  selectedCoach: UserDTO;

  userWhy: string;

  goalsForLearners: string;

  goalsForCoaching: string;

  userStrength: string;

  areaOfGrowth: string;

  constructor(
    public introSessionService: IntroSessionService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.getCoach();
    this.populateAnswers();
  }

  getCoach() {
    this.subs.push(
      this.introSessionService.getCoaches().subscribe((res) => {
        if (res) {
          res.items.forEach((item: UserDTO) => {
            if (this.user.smartCoach && this.user.smartCoach.id === item.id) {
              this.selectedCoach = item;
            }
          });
        }
      })
    );
  }

  populateAnswers() {
    this.userWhy = this.user?.profile.why || '';
    this.goalsForLearners = this.user?.profile.goals_for_your_learners || '';
    this.goalsForCoaching = this.user?.profile.goals_for_coaching || '';
    this.userStrength = this.user?.profile.strongest_egrowe_standard_why || '';
    this.areaOfGrowth = this.user?.profile.weakest_egrowe_standard_why || '';
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }
}
