<div>
  <app-wysiwyg-editor
    #editor
    (editorContentEvent)="emitAutosave($event)"
    [version]="'none'"
    [disabled]="disabled"
    [config]="config"
    [editorContent]="initialContent"
    [isSaving]="isSaving" />
</div>
