import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionChoiceDTO } from '../../../dtos/smartcoach.dto';

@Component({
  selector: 'app-smart-option-select-card',
  templateUrl: './smart-option-select-card.component.html',
  styleUrls: ['./smart-option-select-card.component.scss'],
})
export class SmartOptionSelectCardComponent {
  @Input() option: OptionChoiceDTO;

  @Input() detailedOptionId: number | undefined;

  @Output() readonly handleInfoClick: EventEmitter<number> = new EventEmitter();

  @Output() readonly toggleOption: EventEmitter<number> = new EventEmitter();

  showDetail() {
    this.handleInfoClick.next(this.option.id);
  }

  updateOption() {
    this.toggleOption.next(this.option.id);
  }
}
