<div
  class="progress-bar level-{{ progress }} "
  [attr.aria-description]="description">
  <div
    class="progress-bar-block"
    [ngClass]="{ highlighted: highlightedBlockIndex.includes(1) }"></div>
  <div
    class="progress-bar-block"
    [ngClass]="{ highlighted: highlightedBlockIndex.includes(2) }"></div>
  <div
    class="progress-bar-block"
    [ngClass]="{ highlighted: highlightedBlockIndex.includes(3) }"></div>
  <div
    class="progress-bar-block"
    [ngClass]="{ highlighted: highlightedBlockIndex.includes(4) }"></div>
  <ng-container *ngIf="goalLevel">
    <img
      src="/assets/bullseye.svg"
      class="bullseye level-{{ goalLevel }}"
      alt="Bullseye"
      role="img" />
  </ng-container>
</div>
