import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/common/state/user/user.service';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { StrandDTO } from '../../../dtos/attendee-rubric.dto';
import { AlertService } from '../../../services/alert/alert.service';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { EvidenceService } from '../../../services/evidence/evidence.service';
import { PortfolioService } from '../../../services/portfolio/portfolio.service';
import {
  CopyEvidencePayload,
  CopyEvidencePayloadItem,
} from '../../../types/payloads/coaching-log.payloads';
import { CompetencySelectComponent } from '../../competency/competency-select/competency-select.component';
import { TopicDTO } from '../../topic-select/topic-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-copy-evidence-modal',
  templateUrl: './copy-evidence-modal.component.html',
  styleUrls: ['./copy-evidence-modal.component.scss'],
})
export class CopyEvidenceModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal')
  modal: ModalComponent;

  @ViewChild('competencySelect')
  competencySelect: CompetencySelectComponent;

  @Input() evidenceId: number;

  @Input() userId: number;

  districtId: number;

  topics: TopicDTO[];

  strands: StrandDTO[][] = [];

  levels: number[] = [1, 2, 3, 4];

  payload: CopyEvidencePayload;

  formList: CopyEvidencePayloadItem[] = [
    {
      rubric_id: 0,
      standard_id: 0,
      strand_id: 0,
      levels: [],
    },
  ];

  canSubmit = false;

  submitLoading = false;

  showError = false;

  subs: Subscription[] = [];

  constructor(
    private portfolioService: PortfolioService,
    private userService: UserService,
    private evidenceService: EvidenceService,
    private coachingLogService: CoachingLogService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.getUserDistrictId();
    this.getTopics();
  }

  /**
   * Fetches districtId of user based on userId of coachee
   */
  getUserDistrictId() {
    if (this.userId) {
      this.subs.push(
        this.userService.fetchUserById(this.userId).subscribe((res) => {
          if (res?.district) {
            this.districtId = res.district.id;
          }
        })
      );
    }
  }

  /**
   * Fetches list of topics for user to select from in
   * topic dropdown, based on userId of coachee
   */
  getTopics() {
    if (this.userId) {
      this.subs.push(
        this.portfolioService.getUserRubrics(this.userId).subscribe((res) => {
          this.topics = res.items;
        })
      );
    }
    this.validateForms();
  }

  /**
   * When topic is selected, sets the payload's rubric_id
   * for the corresponding form, which gets passed as an input to
   * the competency-select component, which internally
   * filters competencies for just that rubric
   *
   * @param {TopicDTO} topic - Topic selected by user
   * @param {number} formIndex - Index of the form to save value to
   */
  updateTopic(topic: TopicDTO, formIndex: number) {
    if (topic) {
      this.formList[formIndex].rubric_id = topic.id;
    } else {
      this.formList[formIndex].rubric_id = 0;
    }
    this.validateForms();
  }

  /**
   * When competency is selected, sets the payload's standard_id
   * for the corresponding form
   *
   * Then, fetches strands for that competency
   *
   * @param {CompetencyDTO} competency - Competency selected by user
   * @param {number} formIndex - Index of the form to save value to
   */
  updateCompetency(competency: CompetencyDTO | null, formIndex: number) {
    if (competency) {
      this.formList[formIndex].standard_id = competency.id;
      this.subs.push(
        this.coachingLogService
          .getCompetencyRubric(this.userId, competency.id)
          .subscribe((res) => {
            if (res) {
              this.strands[formIndex] =
                res.decomposedRubrics.indicatorSets[0].strands;
            }
          })
      );
    } else {
      this.formList[formIndex].standard_id = 0;
    }
    this.validateForms();
  }

  /**
   * When strand is selected, sets the payload's strand_id
   * for the corresponding form
   *
   * @param {StrandDTO} strand - Strand selected by user
   * @param {number} formIndex - Index of the form to save value to
   */
  updateStrand(strand: StrandDTO, formIndex: number) {
    if (strand) {
      this.formList[formIndex].strand_id = strand.id;
    } else {
      this.formList[formIndex].strand_id = 0;
    }
    this.validateForms();
  }

  /**
   * Once level checkbox is checked, adds level number to
   * payload's levels array for the corresponding form
   * (if array doesn't already contain that level)
   *
   * Else, remove level from array when user unchecks
   *
   * @param {Event} event - DOM click event for each checkbox
   * @param {number} level - Level checked by user
   * @param {number} formIndex - Index of the form to save values to
   */
  onCheckboxChange(event: Event, level: number, formIndex: number): void {
    const target = event.target as HTMLInputElement | null;
    const levels = this.formList[formIndex].levels;

    if (target?.checked) {
      if (!levels.includes(level)) {
        levels.push(level);
      }
    } else {
      const index = levels.indexOf(level);
      if (index !== -1) {
        levels.splice(index, 1);
      }
    }
    this.validateForms();
  }

  /**
   * Instantiates another form object in formList,
   * thereby creating another form in the DOM
   */
  addForm() {
    this.formList.push({
      rubric_id: 0,
      standard_id: 0,
      strand_id: 0,
      levels: [],
    });
    this.validateForms();
  }

  /**
   * Deletes form from formList, thereby
   * deleting form from the DOM as well
   *
   * @param {number} formIndex - Index of the form to delete
   */
  deleteForm(formIndex: number) {
    this.formList.splice(formIndex, 1);
    this.validateForms();
  }

  /**
   * Creates payload for post request using user's selected values
   * Makes post request then closes modal
   */
  onSubmit() {
    this.submitLoading = true;
    this.payload = {
      copyTo: this.formList,
    };

    this.evidenceService
      .copyEvidenceToGIs(this.evidenceId, this.payload)
      .subscribe({
        error: () => {
          this.submitLoading = false;
          this.showError = true;
        },
        next: (res) => {
          if (res) {
            this.modal.close();
            this.submitLoading = false;
            this.alertService.showAlert(
              "Evidence successfully copied to user's portfolio"
            );
          }
        },
      });
  }

  /**
   * Checks that form values are all filled out before enabling submit button
   */
  validateForms() {
    this.canSubmit = this.formList.every(
      (form) =>
        form.rubric_id !== 0 &&
        form.standard_id !== 0 &&
        form.strand_id !== 0 &&
        form.levels.length !== 0
    );
  }

  /**
   * Clears form values, resets formList to single empty form
   */
  clearFields() {
    this.formList = [
      {
        rubric_id: 0,
        standard_id: 0,
        strand_id: 0,
        levels: [],
      },
    ];
    this.showError = false;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
