import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { take } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { Form } from 'src/app/private/shared/dtos/forms.dto';
import { v4 as uuidv4 } from 'uuid';
import {
  ObservationFormAnswerDTO,
  ReportAnswerDTO,
  ReportQuestionDTO,
} from '../../reporting-page/filters/report-filters.dto';

@Component({
  selector: 'app-form-answer-select',
  templateUrl: './form-answer-select.component.html',
  styleUrls: ['./form-answer-select.component.scss'],
})
export class FormAnswerSelectComponent implements OnChanges {
  questions: ReportQuestionDTO[];

  answers: ObservationFormAnswerDTO[];

  searchLoading = false;

  labelId = uuidv4();

  model: ObservationFormAnswerDTO;

  internalSelectedAnswerList: ObservationFormAnswerDTO[] = [];

  internalForm: Form;

  @ViewChild('select') select: NgSelectComponent;

  @Input() label = 'Select answers to filter';

  @Input() form: Form;

  @Input() defaultAnswers: ObservationFormAnswerDTO[];

  @Output() readonly selectedAnswerList: EventEmitter<
    ObservationFormAnswerDTO[]
  > = new EventEmitter();

  constructor(private apiService: APICoreService, private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'form':
          this.loadAnswers();
          break;
        default:
      }
    });
  }

  loadAnswers() {
    if (this.form?.id) {
      this.searchLoading = true;
      this.apiService
        .getRequest('reporting/dimension', {
          dimensionName: 'form_field',
          idName: 'form_id',
          idValues: this.form.id,
        })
        .subscribe((res: { items: ReportQuestionDTO[] }) => {
          take(1);
          if (res.items.length > 0) {
            this.questions = res.items.filter((question) =>
              ['checkbox', 'select', 'radio'].includes(question.field_type)
            );
            if (this.questions.length > 0) {
              this.apiService
                .getRequest('reporting/dimension', {
                  dimensionName: 'form_field_option',
                  idName: 'field_id',
                  idValues: this.questions.map((question) => question.id),
                })
                .subscribe((ansRes: { items: ReportAnswerDTO[] }) => {
                  take(1);
                  this.answers = [];
                  this.searchLoading = false;
                  if (ansRes.items.length > 0) {
                    ansRes.items.forEach((answer) => {
                      const question = this.questions.find(
                        (item) => item.id === answer.field_id
                      ) as ReportQuestionDTO;
                      this.answers.push({
                        id: answer.id,
                        question: question.label,
                        questionId: question.id,
                        formId: answer.form_id,
                        title: answer.label,
                        value: answer.value,
                      });
                    });
                  }
                  if (this.defaultAnswers) {
                    this.internalSelectedAnswerList = this.answers.filter(
                      (answer) =>
                        this.defaultAnswers.find(
                          (defaultAnswer) => defaultAnswer.id === answer.id
                        )
                    );
                  }
                });
            } else {
              this.resetQuestionsAndAnswers();
              this.searchLoading = false;
            }
          } else {
            this.searchLoading = false;
          }
        });
    } else {
      this.resetQuestionsAndAnswers();
    }
  }

  resetQuestionsAndAnswers() {
    this.questions = [];
    this.answers = [];
  }

  reset() {
    this.internalSelectedAnswerList = [];
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }

  answerSelected(answer: ObservationFormAnswerDTO) {
    if (answer) {
      if (!this.internalSelectedAnswerList.includes(answer)) {
        this.internalSelectedAnswerList.push(answer);
      }
      this.select.handleClearClick();
      this.outputAnswers();
    }
  }

  outputAnswers() {
    this.selectedAnswerList.emit(this.internalSelectedAnswerList);
  }

  removeAnswerFromList(answer: ObservationFormAnswerDTO) {
    this.internalSelectedAnswerList = this.internalSelectedAnswerList.filter(
      (a) => a.id !== answer.id
    );
    this.outputAnswers();
  }
}
