<app-modal
  #smartOptionModal
  [ngClass]="['mw800']"
  (isDismissing)="hideModal()"
  [modalConfig]="{
    titleText: 'Options',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    options: { size: 'lg' },
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="hideModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="addOptionsData && !dataLoading; else loading">
    <div class="gi-language w-100 mb-4 text-center">
      <div class="fw-800 mb-2">
        {{ addOptionsData.strandTitle }}, Level:
        {{ smartCoachService.addOptionLevel }}
      </div>
      <app-safehtml [html]="addOptionsData.indicator.content"></app-safehtml>
    </div>

    <ng-container *ngIf="addOptionsData.optionChoices">
      <ng-container
        *ngIf="addOptionsData.optionChoices.length === 0; else hasData">
        <div
          class="d-flex justify-content-center align-items-center"
          style="height: 125px">
          <div class="fw-800 fs-19">
            <span *ngIf="noCuratedOptionsMessage">
              {{ noCuratedOptionsMessage }}
            </span>
            <span *ngIf="!noCuratedOptionsMessage">
              NO CURATED OPTIONS FOUND
            </span>
          </div>
        </div>
      </ng-container>

      <ng-template #hasData>
        <div class="row g-3 card-group">
          <div
            class="col col-xs-1 col-sm-6 col-xl-4"
            *ngFor="let optionChoice of addOptionsData.optionChoices">
            <app-smart-option-select-card
              (handleInfoClick)="showOptionDetails($event)"
              (toggleOption)="toggleOption($event)"
              [detailedOptionId]="detailedOption?.id || 0"
              [option]="optionChoice"></app-smart-option-select-card>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="detailedOption">
      <div class="resources mb-3 p-4">
        <h5>Details</h5>
        <div class="mb-4">
          <app-safehtml [html]="detailedOption.description"></app-safehtml>
        </div>

        <h5>Resources</h5>
        <ng-container
          *ngIf="detailedOption.resources.length > 0; else noResources">
          <div class="mb-3">
            <div class="row g-3 card-group">
              <div
                class="col col-xs-1 col-sm-6 col-xl-4"
                *ngFor="
                  let resource of detailedOption.resources | slice : 0 : limit;
                  let i = index
                ">
                <div
                  class="card resource-card border border-secondary border-3 text-center p-3 h-100">
                  <div
                    class="card-body d-flex flex-column justify-content-center">
                    <i class="bi bi-tools fs-35px"></i>
                    <a
                      target="_blank"
                      [href]="'resources/' + resource.resource.slug"
                      class="h5 fs-16 fw-bold title">
                      {{ resource.resource.title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="detailedOption.resources.length > 3"
              class="d-flex justify-content-end">
              <button class="btn d-flex" (click)="toggleLimit()">
                <span *ngIf="limitResources"
                  >Show More<i class="bi bi-caret-up-fill ms-1"></i></span
                ><span *ngIf="!limitResources"
                  >Hide<i class="bi bi-caret-down-fill ms-1"></i
                ></span>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #noResources>
          <div
            class="d-flex w-100 align-items-center justify-content-center text-body-secondary"
            style="height: 70px">
            No leveled resources available.
          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="w-100" style="min-height: 200px">
      <app-loader></app-loader>
    </div>
  </ng-template>

  <div footerContent>
    <button
      *ngIf="addOptionsData"
      (click)="submitOptions()"
      [disabled]="
        !hasSelectedOptions ||
        isProcessing ||
        addOptionsData.optionChoices.length === 0
      "
      [class.bg-level-1]="smartCoachService.addOptionLevel == 1"
      [class.bg-level-2]="smartCoachService.addOptionLevel == 2"
      [class.bg-level-3]="smartCoachService.addOptionLevel == 3"
      [class.bg-level-4]="smartCoachService.addOptionLevel == 4"
      type="button"
      class="btn btn-primary submit">
      <span
        *ngIf="isProcessing"
        class="spinner-border spinner-border-sm me-1"></span>
      Add Options
    </button>
  </div>
</app-modal>
