import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subject, take } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import {
  UserBulkPayload,
  UserDTO,
  UserLiteDTO,
} from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { UserDtoApiResponse } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { SchoolSearchService } from 'src/app/private/shared/services/school-search/school-search.service';
import { PartialUserSearchParams } from '../../shared/components/roster-users/roster-users.helper';
import { AlertService } from '../../shared/services/alert/alert.service';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { UserSearchService } from '../../shared/services/user-search/user-search.service';

@Component({
  selector: 'app-rosters-page',
  templateUrl: './rosters-page.component.html',
  styleUrls: ['./rosters-page.component.scss'],
  providers: [UserSearchService],
})
export class RostersPageComponent implements OnInit {
  @ViewChild('searchText') input: ElementRef;

  districtId: number;

  districtCode: string;

  district: DistrictDTO;

  user: User;

  usersTableIsLoading = true;

  schoolData: SchoolDTO[];

  searchTerm = '';

  numPerPage = 20;

  activeSection: 'users' | 'schools' = 'schools';

  showCreateUserModal = false;

  showEditUserModal = false;

  showRostersSchoolModal = false;

  showEditSchoolModal = false;

  searchTimeout: ReturnType<typeof setTimeout>;

  userSearchResults: UserDtoApiResponse;

  editUser: UserDTO;

  editSchoolId: number;

  defaultParams: PartialUserSearchParams = {
    page: 1,
    per_page: 20,
    sort_by: '',
    sort_by_desc: '',
    district_ids: [],
    include_inactive_users: true,
  };

  totalUsers: number;

  selectedUsers: number[];

  selectedBulkAction?: string;

  defaultSchool = 0;

  selectedSchoolId?: number;

  selectedUserType?: string;

  selectedCoach: UserLiteDTO | null;

  clearSelectionsSubject: Subject<boolean> = new Subject();

  masterPayload: UserBulkPayload = {
    district_id: 0,
  };

  showSelectAll = false;

  allSelected = false;

  allUsersLoading = false;

  selectAllBSubject = new BehaviorSubject<number[]>([]);

  bulkSubmitting = false;

  constructor(
    private districtList: DistrictListService,
    private route: ActivatedRoute,
    public router: Router,
    private schoolSearchService: SchoolSearchService,
    private store: Store,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtList
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
            this.districtId = this.district.id;
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictDTO;
            this.districtCode = this.district.districtCode;
            this.districtId = this.district.id;
          }
          this.searchUsers();
          this.schoolSearchService
            .getDistrictSchools(this.districtId)
            .subscribe((data) => {
              if (data) this.schoolData = data;
            });
        }
      });
  }

  searchTermChanged(event: Event) {
    this.searchTerm = (<HTMLInputElement>event.target).value;
    this.searchUsersDebounce();
  }

  handleUserSearchChange(event: [PaginationDTO, PartialUserSearchParams]) {
    this.searchUsers(
      {
        search: this.searchTerm,
        ...event[1],
      },
      event[0]
    );
  }

  searchUsersDebounce() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchUsers();
    }, 300);
  }

  searchUsers(
    searchParams?: PartialUserSearchParams,
    listMeta?: PaginationDTO
  ) {
    this.usersTableIsLoading = true;
    const districtIds: number[] = [];
    if (this.district) {
      districtIds.push(this.district.id);
    }
    let params = { ...this.defaultParams, district_ids: districtIds };
    if (this.searchTerm) {
      params = {
        ...params,
        search: this.searchTerm,
      };
    }
    if (searchParams) {
      params = {
        ...params,
        ...searchParams,
      };
    }
    if (listMeta) {
      params = {
        ...params,
        page: listMeta.currentPage,
      };
    }
    this.userService.fetchUsers(params).subscribe((results) => {
      take(1);
      this.userSearchResults = results;
      this.usersTableIsLoading = false;
      this.totalUsers = this.userSearchResults._meta.totalCount;
      this.checkIfAllUsers();
    });
  }

  searchSchools(event: Event) {
    const term = (<HTMLInputElement>event.target).value;
    this.schoolSearchService.search(term, this.districtId);
  }

  changeSection(sectionName: 'users' | 'schools') {
    this.activeSection = sectionName;
    if (this.activeSection === 'users') {
      this.schoolSearchService.search('', this.districtId);
    } else {
      this.searchTerm = '';
      this.searchUsersDebounce();
    }
  }

  handleEditUser(user: UserDTO) {
    this.editUser = user;
    this.showEditUserModal = true;
  }

  handleCreateSchool(newSchool: SchoolDTO) {
    this.schoolData.push(newSchool);
  }

  handleClickEditSchool(schoolId: number) {
    this.editSchoolId = schoolId;
    this.showEditSchoolModal = true;
  }

  handleSchoolUpdate(school: SchoolDTO) {
    const foundIndex = this.schoolData.findIndex((x) => x.id === school.id);
    this.schoolData[foundIndex] = school;
  }

  handleDeleteSchool(schoolId: number) {
    this.schoolData = this.schoolData.filter((item) => item.id !== schoolId);
  }

  // BULK UPDATE

  handleSelectedUsers(totalUsers: number[]) {
    this.selectedUsers = totalUsers;
    if (this.selectedUsers.length >= 1) {
      this.showSelectAll = true;
    } else {
      this.showSelectAll = false;
    }
    this.checkIfAllUsers();
  }

  handleSelectedCoach(user: UserLiteDTO | null) {
    this.selectedCoach = user;
  }

  selectAllUsers() {
    this.allUsersLoading = true;

    const districtIds: number[] = [];
    if (this.district) {
      districtIds.push(this.district.id);
    }
    const params = { ...this.defaultParams, district_ids: districtIds };

    params.search = this.searchTerm;
    params.per_page = 2000;

    this.userService.fetchUsers(params).subscribe((results) => {
      take(1);
      this.selectedUsers = [];
      const allUsers: number[] = [];

      results.items.forEach((user) => {
        this.selectedUsers.push(user.id);
        allUsers.push(user.id);
      });
      this.selectAllBSubject.next(allUsers);
      this.checkIfAllUsers();
      this.allUsersLoading = false;
    });
  }

  checkIfAllUsers() {
    if (this.selectedUsers && this.selectedUsers.length === this.totalUsers) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  clearAllUsers() {
    this.resetSelectedUsers();
    this.showSelectAll = false;
    this.allSelected = false;
    this.searchUsers(); // used to reload items
  }

  resetAll(message: string) {
    this.selectedBulkAction = undefined;
    this.showSelectAll = false;
    this.allSelected = false;
    this.resetSelected();
    this.resetSelectedUsers();
    this.masterPayload = {
      district_id: 0,
    };
    this.searchUsers(); // used to reload items
    setTimeout(() => {
      this.alertService.showAlert(message);
    }, 700);
  }

  resetSelected() {
    this.selectedSchoolId = undefined;
    this.selectedUserType = undefined;
    this.selectedCoach = null;
  }

  resetSelectedUsers() {
    this.selectedUsers = [];
    this.clearSelectionsSubject.next(true);
  }

  applyBulkChanges() {
    if (this.selectedBulkAction) {
      this.bulkSubmitting = true;
      if (this.selectedBulkAction === 'deactivate') {
        const payload = {
          ...this.masterPayload,
          user_ids: this.selectedUsers,
          data: { status: 3 },
          district_id: this.district.id,
        };
        this.userService.updateBulkUsers(payload).subscribe(() => {
          this.bulkSubmitting = false;
          this.resetAll('User has been deactivated');
        });
      } else if (this.selectedBulkAction === 'exclude') {
        const payload = {
          ...this.masterPayload,
          user_ids: this.selectedUsers,
          data: { exclude_from_reports: true },
          district_id: this.district.id,
        };
        this.userService.updateBulkUsers(payload).subscribe(() => {
          this.bulkSubmitting = false;
          this.resetAll('Users will be excluded from reports');
        });
      } else if (this.selectedBulkAction === 'include') {
        const payload = {
          ...this.masterPayload,
          user_ids: this.selectedUsers,
          data: { exclude_from_reports: false },
          district_id: this.district.id,
        };
        this.userService.updateBulkUsers(payload).subscribe(() => {
          this.bulkSubmitting = false;
          this.resetAll('Users will be included in reports');
        });
      } else if (
        this.selectedBulkAction === 'home-school' &&
        this.selectedSchoolId
      ) {
        const payload = {
          ...this.masterPayload,
          user_ids: this.selectedUsers,
          data: { school: { id: this.selectedSchoolId } },
          district_id: this.district.id,
        };
        this.userService.updateBulkUsers(payload).subscribe(() => {
          this.bulkSubmitting = false;
          this.resetAll('Home school has been updated');
        });
      } else if (
        this.selectedBulkAction === 'user-type' &&
        this.selectedUserType
      ) {
        const payload = {
          ...this.masterPayload,
          user_ids: this.selectedUsers,
          data: { type: this.selectedUserType },
          district_id: this.district.id,
        };
        this.userService.updateBulkUsers(payload).subscribe(() => {
          this.bulkSubmitting = false;
          this.resetAll('User type has been updated');
        });
      } else if (
        this.selectedBulkAction === 'assigned-coach' &&
        this.selectedCoach
      ) {
        const payload = {
          ...this.masterPayload,
          user_ids: this.selectedUsers,
          data: { coach: this.selectedCoach.id },
          district_id: this.district.id,
        };
        this.userService.updateBulkUsers(payload).subscribe(() => {
          this.bulkSubmitting = false;
          this.resetAll('Assigned Coach has been updated');
        });
      }
    }
  }
}
