<table *ngIf="tableData" class="table">
  <thead>
    <tr>
      <th scope="col" (sort)="onSort($event)" sortable="title">
        Name
        <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
      </th>
      <th scope="col" (sort)="onSort($event)" sortable="number">
        # of Users
        <i class="bi bi-caret-down {{ sortIconTemp.number }}"></i>
      </th>
      <th
        class="hide-on-mobile"
        scope="col"
        (sort)="onSort($event)"
        sortable="date">
        Last Updated
        <i class="bi bi-caret-down {{ sortIconTemp.date }}"></i>
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let school of tableData">
      <td>
        {{ school.title }}
      </td>
      <td>
        {{ school.counts.users }}
      </td>
      <td class="hide-on-mobile" *ngIf="school.updated_at">
        {{ school.updated_at * 1000 | date }}
      </td>
      <td>
        <i
          (click)="editSchoolEvent.emit(school.id)"
          ngbTooltip="Edit School"
          class="bi bi-pencil cursor-pointer color-tertiary"></i>
      </td>
    </tr>
  </tbody>
</table>
