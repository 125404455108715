/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getIdsFromDefaultItemsForSelect = (items: number | any[]) => {
  /* eslint-disable-next-line default-case */
  switch (typeof items) {
    case 'number':
      return [items];
    case 'object':
      if (typeof items[0] === 'number') {
        return items;
      }
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      return items.map((item: any) => item.id);
    default:
      throw new Error('Invalid item type');
  }
};
