import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { UserDTO } from 'src/app/common/state/user/user.dto';
// eslint-disable-next-line import/no-cycle
import { CompetencyStandardAPIResponse } from '../types/responses/coaching-log.responses';
import { VideoAPIResponse } from '../types/responses/video.responses';
import { ResourceListItemDTO } from './resources.dto';

export interface DecomposedRubricDTO {
  rubrics: RubricDTO[];
  competencyGroups: CompetencyGroupDTO[];
  competencies: CompetencyDTO[];
  indicatorSets: IndicatorSetDTO[];
  strands: StrandDTO[];
  indicators: IndicatorDTO[];
  levels: LevelDTO[];
  evidences: EvidenceDTO[];
  evidenceAttachments: EvidenceAttachmentDTO[];
  evidenceAssessments: EvidenceAssessmentDTO[];
  goals: GoalDTO[];
  options: OptionDTO[];
  assessments: AssessmentDTO[];
  plannedEvidences: PlannedEvidenceDTO[];
  wills: WillDTO[];
}

export interface DecomposedRubricDictionary {
  [id: string]: DecomposedRubricDTO;
}

export interface AttendeeRubricStateModelDTO {
  attendeeRubrics: AttendeeRubricDTO[];
  decomposedByUser: DecomposedRubricDictionary;
  options: OptionDTO[];
  evidence: EvidenceDTO[];
}

export interface AttendeeRubricDTO {
  egroweCoachlogId: number;
  rubrics: RubricDTO[];
  userId: number;
}

export interface RubricDTO {
  id: number;
  competencyGroups: CompetencyGroupDTO[];
  title: string;
  coachlogId: number;
}

export interface CompetencyGroupDTO {
  id: number;
  competencies: CompetencyDTO[];
  title: string;
}

export interface CompetencyDTO {
  coachlogId: number;
  completedPreAssessment: boolean;
  content: string;
  faqResourceSlug: string;
  id: number;
  icon?: string;
  indicatorSet: IndicatorSetDTO;
  title: string;
  level1video?: VideoDTO | null;
  level2video?: VideoDTO | null;
  level3video?: VideoDTO | null;
  level4video?: VideoDTO | null;
}

export interface CompetencyListItemDTO {
  content: string;
  id: number;
  loading: boolean;
  title: string;
  icon: string | null;
  selected: boolean;
  rubricId: number;
  rubricTitle: string;
  overviewVideo: VideoAPIResponse | null;
}

export interface IndicatorSetDTO {
  id: number;
  strands: StrandDTO[];
  level1Name: string;
  level2Name: string;
  level3Name: string;
  level4Name: string;
  competencyId: number;
}

export interface StrandDTO {
  id: number;
  indicatorSetId: number;
  competencyId: number;
  level1: LevelDTO;
  level2: LevelDTO;
  level3: LevelDTO;
  level4: LevelDTO;
  title: string;
  goalLevel: number;
  completedLevel: number;
}

export interface LevelDTO {
  evidence: EvidenceDTO[];
  goal: GoalDTO | null;
  highestOptionLevel: number;
  indicators: IndicatorDTO[];
  options: OptionDTO[];
  levelCompleted: boolean;
}

export enum EvidenceType {
  VIDEO = 'video',
  NARRATIVE = 'narrative',
}

export interface EvidenceDTO {
  id: number;
  title: string;
  narrative: string;
  type: EvidenceType;
  content?: string;
  level: number;
  competencyId: number;
  attachmentIds?: number[];
  rubricId: number;
  strandId: number;
  coachlogId: number;
  coachlogOptionId: number;
  addedFrom: string;
  userId: number;
  createdAt?: number;
  evidenceAttachments: EvidenceAttachmentDTO[];
  assessments: EvidenceAssessmentDTO[];
  videos?: VideoDTO[];
}

export interface EvidenceExtendedDTO extends EvidenceDTO {
  districtId: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  nearestCoachlogId: number | null;
}

export interface EvidenceExtendedWithCompetencyDTO extends EvidenceDTO {
  districtId: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  nearestCoachlogId: number | null;
  standard: CompetencyStandardAPIResponse;
}

export interface GoalDTO {
  id: number;
  userId: number;
  egroweStandardId: number;
  goalLevel: number;
  createdAt: number;
  updatedAt: number;
  egroweIndicatorGroupId: number;
  egroweCoachlogId: number;
}

export interface IndicatorDTO {
  id: number;
  content: string;
  assessments: AssessmentDTO[];
  requirements: [];
  hasCoachAssessment: boolean;
}

export interface CreatedOptionDTO {
  id: number;
  content: string;
  groweCoachlogId: number;
  groweStrandId: number;
  groweRubricId: number;
  groweCompetencyId: number;
  level: number;
}

export interface OptionDTO {
  id: number;
  content: string;
  fromBankOption: number | null;
  fromCuratedOptionId: number | null;
  fromCuratedOption: boolean | null;
  resources: ResourceListItemDTO[];
  strandId: number;
  level: number;
  coachlogId: number;
  will: WillDTO | null;
}

export interface WillDTO {
  id: number;
  coachlogId: number;
  optionId: number;
  dueDate: number;
  userId: number;
  updatedDate: number;
  createdDate: number;
  content: string;
}

export interface EvidenceAttachmentDTO {
  id: number;
  type: string;
  relationalId: number;
  title: string;
  description: string;
  filePath: string;
  fileType: string;
  createdAt: number;
  updatedAt: number;
  userId: number;
}

export interface EvidenceAssessmentDTO {
  id: number;
  evidenceId: number;
  assessorUserId: number;
  title: string;
  content: string;
  approved: boolean;
  createdAt: number;
  updatedAt: number;
  assessor: UserDTO;
}

export interface AssessmentDTO {
  id: number;
  assessmentType: string;
  egroweIndicatorId: number;
  assessorUserId: number;
  assesseeUserId: number;
  districtId: number;
  createdAt: number;
  updatedAt: number;
  egroweRubricId: number;
  egroweCoachlogId: number;
  nearestCoachlogId: number;
  mainCoachlogId: number;
  assessor: UserDTO;
}

export interface PlannedEvidenceDTO {
  id: number;
  content: string;
  dueDate: number;
}
