import { Component, Input } from '@angular/core';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { StrandDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { SessionPermissions } from 'src/app/private/shared/helpers/coachee-log.utilities';

@Component({
  selector: 'app-leveled-competency-strand',
  templateUrl: './leveled-competency-strand.component.html',
  styleUrls: ['./leveled-competency-strand.component.scss'],
})
export class LeveledCompetencyStrandComponent {
  @Input() competencyId: number;

  @Input() competencyTitle: string;

  @Input() strand: StrandDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  @Input() level1name = 'Conventional Culture';

  @Input() level2name = 'Collaborative Culture';

  @Input() level3name = 'Synergistic Culture';

  @Input() level4name = 'Innovative Culture';

  @Input() level1video: VideoDTO | null | undefined;

  @Input() level2video: VideoDTO | null | undefined;

  @Input() level3video: VideoDTO | null | undefined;

  @Input() level4video: VideoDTO | null | undefined;

  @Input() showOptions = true;

  @Input() canEdit = SessionPermissions.EDIT;

  @Input() viewingPrevSession = false;

  public isCollapsed = false;

  goalLevel = 0;

  Permissions = SessionPermissions;
}
