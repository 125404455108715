<ng-container *ngIf="commentList; else loadingComments">
  <ng-container *ngIf="commentList.length > 0; else noComments">
    <ng-container *ngFor="let comment of commentList">
      <div class="py-3 border-bottom">
        <div class="d-flex flex-column flex-md-row">
          <div class="me-md-2">
            <app-avatar
              [profile]="comment.user.profile"
              [avatarSize]="'medium'">
            </app-avatar>
          </div>
          <div class="comment-text flex-grow-1 ms-md-3 my-2 my-md-0">
            {{ comment.comment }}
            <div class="comment-date mt-1 fs-12">
              {{ comment.created_at * 1000 | date : "MMM d, y h:mm a" }}
            </div>
          </div>
          <div *ngIf="currentUser && comment.user_id === currentUser.id">
            <button
              aria-label="delete comment button"
              class="btn btn-delete btn-sm ms-md-3"
              type="button"
              [autoClose]="'outside'"
              ngbTooltip="Delete Comment"
              [ngbPopover]="contentTemplate"
              [popoverTitle]="'Delete Comment'"
              #popover="ngbPopover">
              <i class="bi bi-trash"></i>
            </button>
            <ng-template #contentTemplate>
              Are you sure you wish to delete this comment?
              <div class="text-end mt-2">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm px-3 m-1"
                  (click)="popover.close()">
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-sm px-3 m-1"
                  (click)="deleteComment(comment); popover.close()">
                  Yes
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noComments>
    <div
      class="w-100 d-flex align-items-center justify-content-center"
      style="height: 80px">
      No comments have been posted
    </div>
  </ng-template>
</ng-container>
<ng-template #loadingComments>
  <div
    class="w-100 align-items-center justify-content-center"
    style="height: 80px">
    <p>Loading comments...</p>
  </div>
</ng-template>
<div class="d-flex mt-4 mb-4">
  <div class="input-group datepicker-popup">
    <label for="leaveComment" class="visually-hidden">Leave a Comment</label>
    <input
      labelForId="leaveComment"
      class="form-control rounded-start"
      type="text"
      placeholder="Leave a comment"
      aria-label="Leave a comment"
      (keydown.enter)="saveComment()"
      [(ngModel)]="newComment.comment" />
    <button
      aria-label="Save comment"
      class="btn btn-primary"
      (click)="saveComment()"
      type="button">
      Comment
    </button>
  </div>
</div>
