import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resources-subnav',
  templateUrl: './resources-subnav.component.html',
  styleUrls: ['./resources-subnav.component.scss'],
})
export class ResourcesSubnavComponent implements OnInit {
  isRootResoucePage = false;

  customCollapsed = false;

  selectedPath = '';

  constructor(private router: Router) {}

  ngOnInit() {
    switch (this.router.url) {
      case '/resources':
        this.selectedPath = 'Discover Resources';
        this.isRootResoucePage = true;
        break;

      case '/resources/my':
        this.selectedPath = 'My Resources';
        break;

      case '/resources/courses':
        this.selectedPath = 'Online Courses';
        break;

      case '/resources/video-library':
        this.selectedPath = 'Video Library';
        break;

      default:
        this.selectedPath = 'Discover Resources';
        break;
    }
  }

  toggleCustomCollapsed(): void {
    this.customCollapsed = !this.customCollapsed;
  }
}
