<div class="progress-bar d-flex flex-row justify-content-start w-100">
  <span
    class="completed"
    [style.width.%]="progress.completed * 100"
    ngbTooltip="Completed"></span>
  <span
    class="ontrack"
    [style.width.%]="progress.on_track * 100"
    ngbTooltip="On Track"></span>
  <span
    class="offtrack"
    [style.width.%]="progress.off_track * 100"
    ngbTooltip="Off Track"></span>
  <span
    class="atrisk"
    [style.width.%]="progress.at_risk * 100"
    ngbTooltip="At Risk"></span>
  <span
    class="canceled"
    [style.width.%]="progress.canceled * 100"
    ngbTooltip="Canceled"></span>
  <span
    class="notstarted"
    [style.width.%]="progress.not_started * 100"
    ngbTooltip="Not Started"></span>
</div>
