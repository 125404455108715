import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { AuthService } from '../../services/auth/auth.service';
import { ChatBotService } from '../../services/chat-bot/chat-bot.service';
import {
  canAccessByRole,
  canAccessByUserType,
  currentDistrictOrE2lEmployee,
} from '../../utilities/check-route';

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  let user: User | null = null;

  const store = inject(Store);

  const router = inject(Router);

  const authService = inject(AuthService);

  const chatBot = inject(ChatBotService);

  user = store.selectSnapshot(UserState.getUser);
  const url = state.url;

  if (user) {
    chatBot.initialize();
  }

  if (next.url.length === 1 && next.url[0].path === 'login') {
    if (user) {
      router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
  if (user == null) {
    authService.redirectUrl = url;
    router.navigate(['/login']);
    return false;
  }
  if (next.url.length === 0) {
    router.navigate(['/dashboard']);
    return false;
  }

  if (
    next.url.length === 5 &&
    next.url[0].path === 'settings' &&
    next.url[1].path === 'districts' &&
    next.url[3].path === 'forms' &&
    !currentDistrictOrE2lEmployee(user, { code: next.url[2].path })
  ) {
    router.navigate(['/permission-denied']);
    return false;
  }
  if (!canAccessByRole(next, user)) {
    router.navigate(['/permission-denied']);
    return false;
  }
  if (!canAccessByUserType(next, user)) {
    router.navigate(['/permission-denied']);
    return false;
  }
  return true;
};
