import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ReplaySubject, catchError, take, tap } from 'rxjs';
import { PusherService } from 'src/app/private/shared/services/pusher/pusher.service';

import { sessionTimeoutStorageKey } from '../../../private/shared/services/session-timeout/session-timeout.helpers';
import { UnleashService } from '../../../private/shared/services/unleash/unleash.service';
import { User } from '../../state/user/user.model';
import { FetchUser, UserState } from '../../state/user/user.state';
import { AuthResponse } from '../../types/responses/responses-template';
import { AuthToken } from '../../types/types';
import { APICoreService } from '../api-core/api-core.service';
import { ChatBotService } from '../chat-bot/chat-bot.service';
import { StorageService } from '../storage/storage.service';

/* eslint-disable */

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl: string | null;

  public firstLogin: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private apiService: APICoreService,
    private store: Store,
    private router: Router,
    private pusherService: PusherService,
    private chatBot: ChatBotService,
    private featureFlagService: UnleashService
  ) {}

  login(username: string, password: string) {
    return this.apiService
      .postRequest('auth/login', { username, password })
      .pipe(
        tap((res) => {
          this.setSession(res);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  initializeUserServices(): void {
    this.pusherService.initialize();
    this.featureFlagService.initialize();
  }

  deinitializeUserServices(): void {
    this.pusherService.deinitialize();
    this.featureFlagService.deinitialize();
  }

  impersonateUser(emailAddress: string) {
    return (
      this.apiService
        .postRequest('auth/impersonate', { emailAddress })
        .subscribe((res) => {
          if (StorageService.getAuthToken()) {
            this.deinitializeUserServices();
            StorageService.storeItem('isImpersonatingUser', true);
            StorageService.storeItem(
              'originalAuthToken',
              StorageService.getAuthToken()
            );
            this.setSession(res, true);
          }
        }),
      catchError((err) => {
        throw err;
      })
    );
  }

  stopImpersonatingUser(navigateAfter = true) {
    StorageService.storeItem('isImpersonatingUser', false);
    this.apiService.postRequest('auth/logout').subscribe(take(1));
    this.deinitializeUserServices();
    StorageService.storeItem(
      'authToken',
      StorageService.getItem('originalAuthToken')
    );

    StorageService.removeItem('originalAuthToken');
    this.initializeUserServices();
    if (navigateAfter) {
      this.store.dispatch(FetchUser).subscribe(() => {
        const user = this.store.selectSnapshot((state) => state.user.user);
        this.router.navigate([`/dashboard/${user.type}`]);
        setTimeout(() => {
          location.reload();
        }, 0);
      });
    }
  }

  googleLogin(googleToken: string) {
    return this.apiService
      .postRequest('auth/growelab-google-login', { token: googleToken })
      .pipe(
        tap((res) => {
          this.setSession(res);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  cleverLogin(cleverCode: string) {
    return this.apiService
      .postRequest('auth/growelab-clever-login', { code: cleverCode })
      .pipe(
        tap((res) => {
          this.setSession(res);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private setSession(authResult: AuthResponse, reloadAfterDispatch = false) {
    const expiresAt = authResult.expires * 1000;
    const authToken: AuthToken = {
      token: authResult.id_token,
      expiresAt,
    };
    StorageService.storeItem('authToken', authToken);
    this.store.dispatch(FetchUser).subscribe(() => {
      const user = this.store.selectSnapshot(UserState.getUser) as User;
      this.initializeUserServices();
      this.featureFlagService.loaded.subscribe((isLoaded) => {
        take(1);
        if (isLoaded) {
          this.handleRedirects(user, reloadAfterDispatch);
        }
      });
    });
    if (!reloadAfterDispatch) {
      this.firstLogin.next(true);
    }
  }

  handleRedirects(user: User, reloadAfterDispatch: boolean) {
    if (this.redirectUrl) {
      const separatedUrl = this.redirectUrl.split('?');
      if (separatedUrl.length === 1) {
        this.router.navigate([this.redirectUrl]);
      } else {
        // If URL has query params, need to sanitize params for proper encoding
        const params = separatedUrl[1].split('&');
        const redirectQueryParams: { [key: string]: string } = {};
        params.forEach((param) => {
          const nameWithValue = param.split('=');
          redirectQueryParams[nameWithValue[0]] = nameWithValue[1];
        });
        this.router.navigate([separatedUrl[0]], {
          queryParams: redirectQueryParams,
        });
      }
      this.redirectUrl = null;
    } else {
      this.router.navigate([`/dashboard/${user.type}`]);
    }

    if (reloadAfterDispatch) {
      StorageService.storeItem('firstImpersonatingLogin', 'true');
      window.setTimeout(() => location.reload(), 0);
    }
  }

  logoutAllAuthenticatedUsers() {
    const impersonatedUser = this.store.selectSnapshot(
      (state) => state.user.impersonated
    );
    if (impersonatedUser) {
      this.stopImpersonatingUser(false);
      this.store.dispatch(FetchUser).subscribe(() => {
        this.logout();
      });
    } else {
      this.logout();
    }
  }

  logout() {
    this.apiService.postRequest('auth/logout').subscribe(take(1));

    StorageService.storeItem('isImpersonatingUser', false);
    StorageService.removeItem('originalAuthToken');
    StorageService.removeItem('authToken');
    StorageService.removeItem('user');
    StorageService.removeItem(sessionTimeoutStorageKey);
    this.deinitializeUserServices();
    this.chatBot.deinitialize();

    // this timeout gives the auth/logout time to be ran, otherwise it gets canceled
    window.setTimeout(() => {
      this.router.navigate(['/login']);
      location.reload();
    }, 300);
  }

  resetPasswordRequest(emailAddress: string) {
    const emailParam = { email: emailAddress };
    return this.apiService.getRequest(
      'growelab/auth/send-password-reset',
      emailParam
    );
  }

  resetPassword(token: string, password: string) {
    return this.apiService.postRequest('growelab/auth/reset-password', {
      token,
      password,
    });
  }
}
