import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Component({
  selector: 'app-google-admin-calendar',
  templateUrl: './google-admin-calendar.component.html',
  styleUrl: './google-admin-calendar.component.scss',
})
export class GoogleAdminCalendarComponent implements OnInit {
  private code: string;

  public response: string;

  constructor(
    private route: ActivatedRoute,
    private apiService: APICoreService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'];
      if (this.code) {
        this.apiService
          .postRequest('growelab/google-calendar/auth', { code: this.code })
          .subscribe((res) => {
            this.response = res;
          });
      } else {
        this.response = 'No code, nothing todo';
      }
    });
  }
}
