<ng-container *ngIf="competency">
  <h2 *ngIf="!viewingPrevSession" class="text-center competency-title mt-3">
    {{ competency.title }}
  </h2>
  <div class="row justify-content-center mt-3">
    <div class="col-lg-8">
      <div class="text-center">
        <app-safehtml [html]="competency.content" />
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <ng-container *ngFor="let strand of competency.indicatorSet.strands">
    <app-leveled-competency-strand
      [strand]="strand"
      [rubricId]="rubricId"
      [userId]="userId"
      [logId]="logId"
      [canEdit]="canEdit"
      [competencyId]="competency.id"
      [competencyTitle]="competency.title"
      [level1video]="competency.level1video"
      [level2video]="competency.level2video"
      [level3video]="competency.level3video"
      [level4video]="competency.level4video"
      [showOptions]="showOptions"
      [viewingPrevSession]="viewingPrevSession"></app-leveled-competency-strand>
  </ng-container>
</ng-container>
