import { DateTime } from 'luxon';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { NumberDictionary } from 'src/app/common/types/types';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';

import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { CoachingSessionDataAPIResponse } from '../types/responses/coaching-log.responses';
import { AssessmentDTO } from './attendee-rubric.dto';
import { FileDTO } from './file.dto';

export interface FormCreateDTO {
  title: string;
  districtId: number;
  description: string;
  fields: Field[];
  status: FormStatus;
  type: FormType;
  isTemplate: boolean;
  districtsSharedWith?: number[];
  districtsSharedExclusion?: number[];
  deletedFields?: Field[];
  id?: number;
}

export interface Form extends FormCreateDTO {
  id: number;
  createdAt: number;
  modifiedAt: number;
  author: User;
  submissionCount?: number;
  hasSubmission?: boolean;
  lastSubmission?: number | null;
  district?: DistrictDTO;
}

export interface FormDuplicatePayload {
  formId: number;
  districtId: number;
}

export enum FormStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  ARCHIVED = 'Archived',
  COLLECTING = 'Collecting',
}

export enum FormType {
  VISIT = 'classroom-visit',
  CALIBRATION = 'coaching-calibration',
  OTHER = 'other',
}

export enum FormSubmissionStatus {
  NEW = 'new',
  SUBMITTED = 'submitted',
}

export interface FormLearnerBehaviorCreateDTO {
  rubricId: number;
  indicatorId: number;
  assesseeUserId: number;
  coachlogId: number;
}

export const FormTypesMap: { label: string; key: FormType }[] = [
  {
    label: 'Observation and Feedback',
    key: FormType.VISIT,
  },
  {
    label: 'Coaching Calibration',
    key: FormType.CALIBRATION,
  },
  {
    label: 'Other',
    key: FormType.OTHER,
  },
];

export enum FieldType {
  TEXT = 'text',
  TEXTBLOCK = 'textarea',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  COMPETENCY = 'competency',
  SECTION = 'section',
  FREETEXT = 'freetext',
  MEDIA = 'media',
}

export const getFieldTypeKey = (field: FieldType): string =>
  Object.keys(FieldType)[Object.values(FieldType).indexOf(field)];

export const MultipleChoiceFieldMap: { label: string; value: FieldType }[] = [
  {
    label: '⦿ Single Choice',
    value: FieldType.RADIO,
  },
  {
    label: '✓ Multiple Select',
    value: FieldType.CHECKBOX,
  },
  {
    label: '▼ Dropdown',
    value: FieldType.SELECT,
  },
];

export const TextFieldTypeMap: { label: string; value: FieldType }[] = [
  {
    label: 'Single line',
    value: FieldType.TEXT,
  },
  {
    label: 'Multiple lines',
    value: FieldType.TEXTBLOCK,
  },
];

export interface FieldTypeConfig {
  name: string;
  type: FieldType;
  icon: string;
}

export interface Field {
  id: string;
  name: string;
  type: FieldType;
  helperText?: string;
  label: string;
  choices?: FieldChoice[];
  competency?: CompetencyDTO;
  indicatorGroupId?: number;
}

export interface FieldWithChoices extends Field {
  choices: FieldChoice[];
}

export interface FieldChoice {
  label: string;
  value: string;
}

export interface ObservationFormSubmissionRawData {
  formId: number;
  coachingLogId?: number;
  submissionData?: FieldDataFlexible[];
  indicatorDeltas?: CompetencyFieldOutput[];
  submitterUser: User;
  observedUserId?: number;
}

export interface SelectedObservationFormRawData {
  formId: number;
}

export interface ObservationFormSubmission {
  id: number;
  formId: number;
  coachingLogId: number;
  submissionData: FieldDataFlexible[];
  submitterDistrictId: number;
  submitterUserId: number;
  observedUserId: number;
  createdAt: number;
  modifiedAt: number;
  submitter?: UserDTO;
  observed?: UserDTO;
  coachlog?: CoachingSessionDataAPIResponse;
  saveTimestampMS?: number;
}

export enum FieldDataType {
  STRING,
  STRING_ARRAY,
  COMPETENCY,
}

export interface WithFieldData {
  value:
    | string
    | CompetencyFieldOutput
    | FormIndicatorDTO[]
    | string[]
    | FileDTO[]
    | null;
}

export interface FieldDataResponse extends WithFieldData {
  fieldId: string;
  type?: FieldDataType;
}
export interface FieldDataFlexible extends WithFieldData {
  fieldId: string;
  type: FieldDataType;
}

export interface FieldData {
  fieldId: string;
  value: string;
}

export interface StringArrayFieldData {
  fieldId: string;
  value: string[];
}

export interface FormIndicatorFieldData {
  fieldId: string;
  value: FormIndicatorDTO[] | null;
}

export interface FileFieldData {
  fieldId: string;
  value: FileDTO[];
}

export const FieldTypes: FieldTypeConfig[] = [
  {
    type: FieldType.SECTION,
    name: 'Section Header',
    icon: 'file-text-fill',
  },
  {
    type: FieldType.FREETEXT,
    name: 'Text description',
    icon: 'body-text',
  },
  {
    type: FieldType.TEXT,
    name: 'Text field',
    icon: 'input-cursor-text',
  },
  {
    type: FieldType.TEXTBLOCK,
    name: 'Text block',
    icon: 'textarea-resize',
  },
  {
    type: FieldType.RADIO,
    name: 'Single choice',
    icon: 'ui-radios',
  },
  {
    type: FieldType.CHECKBOX,
    name: 'Multiple select',
    icon: 'ui-checks',
  },
  {
    type: FieldType.SELECT,
    name: 'Dropdown',
    icon: 'menu-button-fill',
  },
  {
    type: FieldType.COMPETENCY,
    name: 'Leveled look-fors',
    icon: 'star-fill',
  },
  {
    type: FieldType.MEDIA,
    name: 'Attachment',
    icon: 'paperclip',
  },
];

export interface FormIndicatorDTO {
  id: number;
  title: string;
  level: number;
}

export interface FormIndicatorObservedDTO extends FormIndicatorDTO {
  committedToDb?: boolean;
  assesmentData?: FormLearnerBehaviorCreateDTO;
}

export interface CompetencyFieldOutput {
  assessmentsToAdd: FormIndicatorObservedDTO[];
  assessmentsToRemove: AssessmentDTO[];
  fieldId?: string;
}

export interface FormIndicatorGroupDTO {
  id: number;

  sampleQuestions?: string;
  title: string;
  indicators: FormIndicatorDTO[];
}

export interface FormIndicatorSetDTO {
  id: number;
  groups: FormIndicatorGroupDTO[];
}

export interface FormCompetencyDTO {
  competencyId: number;
  rubricId: number;
  indicatorSet: FormIndicatorSetDTO;
}

export enum ReportFieldDataType {
  QUALITATIVE,
  COMPETENCY_COUNT,
  VALUE_COUNT,
}

export const getReportFieldDataType = (
  fieldType: FieldType
): ReportFieldDataType => {
  switch (fieldType) {
    case FieldType.COMPETENCY:
      return ReportFieldDataType.COMPETENCY_COUNT;
    case FieldType.FREETEXT:
    case FieldType.TEXTBLOCK:
    case FieldType.TEXT:
    case FieldType.MEDIA:
      return ReportFieldDataType.QUALITATIVE;
    default:
      return ReportFieldDataType.VALUE_COUNT;
  }
};

export interface ReportFieldData {
  type: FieldType;
  dataType: ReportFieldDataType;
  value: string[] | NumberDictionary;
}

export const extractReportFieldData = (
  type: ReportFieldDataType,
  data: string | FormIndicatorDTO[] | string[] | FileDTO[] | null
): string[] | NumberDictionary => {
  switch (type) {
    case ReportFieldDataType.COMPETENCY_COUNT:
      return { [(data as FormIndicatorDTO[])[0].id]: 1 };
    case ReportFieldDataType.QUALITATIVE:
      return [data as string];
    case ReportFieldDataType.VALUE_COUNT:
      return { [data as string]: 1 };
    default:
      return {};
  }
};

export interface ReportFieldDataDictionary {
  [key: string]: ReportFieldData;
}

export interface FormSubmissionReportDTO {
  userId: number;
  observerId: number;
  userName: string;
  observerName: string;
  logId: number;
  formId: number;
  districtId: number;
  schoolId: number;
  schoolYearId: number;
  submissionDate: DateTime;
  id: number;
  submissionData: FieldDataFlexible[];
}

export interface FormSubmissionReportDictionary {
  [key: string]: FormSubmissionReportDTO[];
}
