<ng-container *ngIf="currentUser && profileUser; else loading">
  <ng-container *ngIf="permissionEnabled; else noPermission">
    <div class="container container-main">
      <app-general-header [title]="'Portfolio Badges'" />
      <div class="card border-0 px-3 px-lg-5 py-4">
        <div *ngIf="userSelectEnabled" class="w-25">
          <app-user-select
            [defaultUsers]="profileUser ? [profileUser] : null"
            (selectedUser)="handleUserSearchOutput($event)" />
        </div>

        <div class="card-body mt-3">
          <div
            *ngIf="currentUser && currentUser.profile"
            class="d-flex flex-column justify-content-center col-12 text-center">
            <div class="mx-auto">
              <app-avatar
                [profile]="profileUser.profile"
                [avatarSize]="'x-large'"
                [profileLinkActive]="false" />
            </div>
            <div class="fs-29 fw-800 mt-2" id="profile-full-name">
              {{ profileUser.profile.first_name }}
              {{ profileUser.profile.last_name }}'s Earned badges
            </div>
          </div>
          <div class="d-flex justify-content-between py-3">
            <div style="width: 300px">
              <div class="fw-600">All Competencies</div>
              <div *ngIf="topics && topics.length > 1" class="my-3">
                <app-topic-select
                  [label]="'Topic'"
                  [topics]="topics"
                  (selectedTopic)="filterTopic($event)"></app-topic-select>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <button
                class="btn"
                aria-label="Download Badges CSV"
                ngbTooltip="Download Badges CSV"
                container="body"
                placement="bottom"
                [autoClose]="false"
                [ngbPopover]="downloadConfirmationPopover"
                [disabled]="disableDownload"
                (click)="downloadBadgesCSV()"
                #downloadBadgePopover="ngbPopover"
                #downloadBadgeTooltip="ngbTooltip">
                <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
              </button>
              <ng-template #downloadConfirmationPopover>
                <div class="p-2">
                  Your CSV is generating. The download will begin shortly.
                </div>
                <div class="p-2 d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    (click)="downloadBadgePopover.close()">
                    OK
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
          <ng-container *ngIf="badgeData && !loadingBadges; else loading">
            <ng-container *ngIf="badgeData.length > 0; else noBadgeProgress">
              <ng-container *ngFor="let competency of badgeData">
                <div class="pb-4">
                  <button
                    type="button"
                    class="py-4 fs-25 fw-800 btn-txt"
                    (click)="competency.collapsed = !competency.collapsed">
                    {{ competency.title }}
                    <span
                      ><i
                        class="bi bi-caret-{{
                          competency.collapsed ? 'up' : 'down'
                        }}-fill fs-15"></i
                    ></span>
                  </button>
                  <div
                    #collapse="ngbCollapse"
                    [(ngbCollapse)]="competency.collapsed">
                    <div class="row">
                      <div
                        class="col-xl-8 d-flex flex-wrap justify-content-center">
                        <ng-container *ngFor="let badge of competency.badges">
                          <div
                            *ngIf="badge.type == 'strand'"
                            class="px-2 px-lg-5 py-3 badge-item"
                            [class.earned]="badge.isEarned">
                            <app-badge-graphic
                              [showDownloadButton]="true"
                              [badge]="badge"
                              [size]="'sm'"></app-badge-graphic>
                            <div
                              class="fs-12 fw-600 pt-1 text-center"
                              *ngIf="badge.isEarned && badge.earnDate">
                              {{ badge.earnDate | formatDate }}
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="col-xl-4 d-flex align-items-center justify-content-center">
                        <ng-container *ngFor="let badge of competency.badges">
                          <ng-container *ngIf="badge.type == 'competency'">
                            <div
                              class="badge-item"
                              [class.earned]="badge.isEarned">
                              <app-badge-graphic
                                [badge]="badge"
                                [size]="'md'"></app-badge-graphic>
                              <div
                                class="fs-12 fw-600 pt-1 text-center"
                                *ngIf="badge.isEarned && badge.earnDate">
                                {{ badge.earnDate | formatDate }}
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noBadgeProgress>
              <div
                class="noData fw-200 d-flex align-items-center justify-content-center px-3 pb-3">
                <div class="message">
                  Great job on your coaching journey! You haven't earned any
                  badges yet, but when you do you'll see your badges here. Keep
                  up the amazing work and watch your badge collection grow!
                </div>
                <div class="row">
                  <div
                    class="col-xl-6 d-flex align-items-center justify-content-center text-center"></div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noPermission>
    <app-permission-denied />
  </ng-template>
</ng-container>
<ng-template #loading>
  <div class="w-100" style="height: 700px">
    <app-loader></app-loader>
  </div>
</ng-template>
