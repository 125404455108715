import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/alert/alert.service';
import {
  CreateUpdateTopicPayload,
  TopicBuilderService,
} from '../../../services/topic-builder/topic-builder.service';
import { CompetencyBuilderStandardAPIResponse } from '../../../types/responses/competency-builder.responses';
import { DistrictSelectComponent } from '../../district-select/district-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-create-edit-topic-modal',
  templateUrl: './create-edit-topic-modal.component.html',
  styleUrls: ['./create-edit-topic-modal.component.scss'],
})
export class CreateEditTopicModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('districtSelect', { static: false })
  districtSelect: DistrictSelectComponent;

  @Output() readonly topicUpdated = new EventEmitter<boolean>();

  isEditMode = false;

  topicToEdit: CompetencyBuilderStandardAPIResponse | undefined;

  form: FormGroup;

  submitLoading = false;

  errorMessage: string;

  districtsLoaded = false;

  audienceTypes = [
    { id: 'teacher', title: 'Teacher', value: 1, isEnabled: false },
    { id: 'coach', title: 'Coach', value: 2, isEnabled: false },
    { id: 'schoolAdmin', title: 'School Admin', value: 4, isEnabled: false },
    {
      id: 'districtAdmin',
      title: 'District Admin',
      value: 5,
      isEnabled: false,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private topicBuilderService: TopicBuilderService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      topicTitle: ['', Validators.required],
      audienceTypes: new FormArray(
        this.audienceTypes.map(
          (audienceType) => new FormControl(audienceType.isEnabled)
        ),
        this.atLeastOneCheckboxChecked()
      ),
      isSmart: [false],
    });
  }

  ngAfterViewInit() {
    this.modal.isOpening.subscribe(() => {
      if (this.isEditMode) {
        this.setFormValuesForEdit();
      }
    });
  }

  setFormValuesForEdit() {
    if (this.topicToEdit) {
      // Set title and mSC enabled
      this.form.patchValue({
        topicTitle: this.topicToEdit.title,
        isSmart: this.topicToEdit.is_smart === 1,
      });

      // Set selected audience types
      this.topicToEdit.types?.forEach((type) => {
        this.audienceTypes.forEach((audienceType, index) => {
          if (audienceType.value === type.type.id) {
            const audienceTypesArray = this.form.get(
              'audienceTypes'
            ) as FormArray;
            audienceTypesArray.at(index).setValue(true);
          }
        });
      });

      // This is necessary before setting default districts
      // since <app-district-select> is wrapped in an *ngIf
      this.changeDetector.detectChanges();

      // Set selected districts
      if (this.topicToEdit.districts.length > 0) {
        this.districtSelect.defaultDistricts = this.topicToEdit.districts.map(
          (district) => district.id
        );
        this.districtSelect.loadDefaultDistricts();
      } else {
        this.districtsLoaded = true;
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  atLeastOneCheckboxChecked(): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: boolean } | null => {
      const totalSelected = (formArray as FormArray).controls
        .map((control) => control.value)
        .reduce((prev, next) => (next ? prev + 1 : prev), 0);

      return totalSelected > 0 ? null : { required: true };
    };
  }

  onCheckboxChange(event: Event, index: number) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement) {
      const audienceTypesArray = this.form.get('audienceTypes') as FormArray;
      audienceTypesArray.at(index).setValue(inputElement.checked);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.submitLoading = true;
      // Get number values of selected audience types
      const selectedAudienceTypes = this.form.value.audienceTypes
        .map((checked: boolean, index: number) =>
          checked ? this.audienceTypes[index].value : null
        )
        .filter((value: number) => value !== null);

      if (this.isEditMode) {
        if (this.topicToEdit) {
          const payload: CreateUpdateTopicPayload = {
            title: this.form.value.topicTitle,
            types: selectedAudienceTypes,
            is_smart: this.form.value.isSmart ? 1 : 0,
            districts: this.districtSelect.internalSelectedDistrictList.map(
              (district) => district.id
            ),
          };
          this.topicBuilderService
            .updateTopic(this.topicToEdit.id, payload)
            .subscribe(() => {
              this.modal.close();
              this.topicUpdated.emit(true);
              this.alertService.showAlert('Topic Updated');
            });
        }
      } else {
        const payload: CreateUpdateTopicPayload = {
          title: this.form.value.topicTitle,
          types: selectedAudienceTypes,
          is_smart: this.form.value.isSmart ? 1 : 0,
        };
        this.topicBuilderService.createTopic(payload).subscribe((res) => {
          this.submitLoading = false;
          this.router.navigate([
            `/settings/districts/engage2learn/topics/${res.item.id}`,
          ]);
          this.alertService.showAlert('Topic Created');
        });
      }
    }
  }

  deleteTopic() {
    if (this.topicToEdit && this.isEditMode) {
      this.topicBuilderService
        .deleteTopic(this.topicToEdit.id)
        .subscribe((res) => {
          if (res) {
            this.alertService.showAlert('Topic Deleted');
            this.modal.close();
            this.topicUpdated.emit(true);
          }
        });
    }
  }

  resetForm() {
    this.form.reset();
    if (this.isEditMode) {
      this.districtSelect.reset();
    }
    this.submitLoading = false;
    this.districtsLoaded = false;
  }
}
