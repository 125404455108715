<app-modal
  #editUserModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'Edit User',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <ng-container *ngIf="form">
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <label for="first-name" class="form-label">First Name</label>
            <div class="input-group">
              <input
                formControlName="first_name"
                type="text"
                class="form-control"
                id="first-name"
                aria-label="First Name"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['first_name'].errors
                }" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label for="last-name" class="form-label">Last Name</label>
            <div class="input-group">
              <input
                formControlName="last_name"
                type="text"
                class="form-control"
                id="last-name"
                aria-label="Last Name"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['last_name'].errors
                }" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label for="email-address" class="form-label">Email Address</label>
            <div class="input-group">
              <input
                formControlName="email"
                type="text"
                class="form-control"
                id="email-address"
                aria-label="Email Address"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['email'].errors
                }" />
            </div>
          </div>
        </div>
        <ng-container *ngIf="schoolListOptions && schoolListOptions.length > 0">
          <div
            class="row mt-2"
            *ngIf="
              schoolListOptions.length != 1 &&
              schoolListOptions[0].is_default != 1
            ">
            <div class="col">
              <label for="user-school" class="form-label">School</label>
              <ng-select
                formControlName="school"
                id="user-school"
                aria-label="School"
                [clearable]="false"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['school'].errors
                }">
                <ng-option value="" disabled>Select an option</ng-option>
                <ng-container *ngFor="let school of schoolListOptions">
                  <ng-option [value]="school.id">{{ school.title }}</ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <label for="title" class="form-label">Additional School(s)</label>
              <ng-select
                #additionalSchoolsSelect
                formControlName="additional_schools"
                id="additional-schools"
                aria-label="Additional Schools"
                [clearable]="false"
                (change)="updateMultiSelectValues($event, 'additional_schools')"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['additional_schools'].errors
                }">
                <ng-option value=" " disabled selected
                  >Select an option</ng-option
                >
                <ng-container *ngFor="let school of schoolListOptions">
                  <ng-option [value]="school.id">{{ school.title }}</ng-option>
                </ng-container>
              </ng-select>
              <div class="edit-user-badge">
                <ng-container *ngFor="let school of additionalSchoolsPayload">
                  <span
                    class="badge badge-item mt-2"
                    (click)="removeMultiSelectValue('school', school)"
                    >{{ school.title }}<i class="bi bi-x mx-2"></i
                  ></span>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row mt-2">
          <div class="col">
            <label for="title" class="form-label">Title/Role</label>
            <div class="input-group">
              <input
                formControlName="title"
                type="text"
                class="form-control"
                id="title"
                aria-label="Title"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['title'].errors
                }" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label for="assigned-coach" class="form-label"
              >Assigned Coach</label
            >
            <app-user-select
              #coachSelect
              id="assigned-coach"
              (selectedUser)="updateAssignedCoach($event)"
              [defaultUsers]="userCoach ? [userCoach] : []" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label for="user-type" class="form-label">User Type</label>
            <ng-select
              formControlName="coachee_type"
              id="user-type"
              [clearable]="false"
              aria-label="User Type">
              <ng-option value="Teacher">Teacher</ng-option>
              <ng-option value="Coach">Coach</ng-option>
              <ng-option value="District Admin">District Admin</ng-option>
              <ng-option value="School Admin">School Admin</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label for="grade-levels-served" class="form-label"
              >Grade Levels Served</label
            >
            <ng-select
              #gradeLevelsServedSelect
              formControlName="grade_levels_served"
              id="grade-levels-served"
              [clearable]="false"
              aria-label="Grade Levels Served"
              (change)="updateMultiSelectValues($event, 'grade_levels_served')"
              [ngClass]="{
                'is-invalid': formSubmitted && f['grade_levels_served'].errors
              }">
              <ng-option value=" " disabled selected
                >Select an option</ng-option
              >
              <ng-container *ngFor="let grade of gradeOptions">
                <ng-option [value]="grade.id">{{ grade.title }}</ng-option>
              </ng-container>
            </ng-select>
            <div class="edit-user-badge">
              <ng-container *ngFor="let grade of gradesPayload">
                <span
                  class="badge badge-item mt-2"
                  (click)="removeMultiSelectValue('grade', grade)"
                  >{{ grade.title }}<i class="bi bi-x mx-2"></i
                ></span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label for="content-areas" class="form-label">Content Areas</label>
            <ng-select
              #contentAreasSelect
              formControlName="content_areas"
              id="content-areas"
              aria-label="Content Areas"
              [clearable]="false"
              (change)="updateMultiSelectValues($event, 'content_areas')"
              [ngClass]="{
                'is-invalid': formSubmitted && f['content_areas'].errors
              }">
              <ng-option value=" " disabled selected
                >Select an option</ng-option
              >
              <ng-container *ngFor="let area of contentAreaOptions">
                <ng-option [value]="area.id">{{ area.title }}</ng-option>
              </ng-container>
            </ng-select>
            <div class="edit-user-badge">
              <ng-container *ngFor="let area of contentAreasPayload">
                <span
                  class="badge badge-item mt-2"
                  (click)="removeMultiSelectValue('contentArea', area)"
                  >{{ area.title }}<i class="bi bi-x mx-2"></i
                ></span>
              </ng-container>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <label for="exclude-from-reports" class="form-label"
                >Exclude From Reports</label
              >
              <div class="input-group">
                <button
                  aria-label="exclude-from-reports-button"
                  class="form-switch btn-reset">
                  <input
                    formControlName="exclude_from_reports"
                    class="form-check-input"
                    id="exclude-from-reports"
                    type="checkbox"
                    role="switch"
                    [checked]="user.exclude_from_reports" />
                </button>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <label for="status" class="form-label d-block">Status</label>
              <button aria-label="status-button" class="form-switch btn-reset">
                <input
                  formControlName="status"
                  class="form-check-input"
                  id="status"
                  type="checkbox"
                  role="switch"
                  [checked]="initialStatus" />
              </button>
              <ng-container *ngIf="this.f['status'].value === true">
                Active
              </ng-container>
              <ng-container *ngIf="this.f['status'].value === false">
                <span class="color-gray">Inactive</span>
              </ng-container>
            </div>
          </div>
        </div>
        <div footerContent>
          <div *ngIf="serverError" class="mt-2">
            <ngb-alert [dismissible]="false" [type]="'danger'">
              <strong>Error</strong> {{ serverError }}
            </ngb-alert>
          </div>
          <div class="row mt-4 justify-content-between align-items-center">
            <div class="col">
              <button
                aria-label="delete button"
                class="btn btn-delete"
                type="button"
                [autoClose]="'outside'"
                [ngbPopover]="deletePopoverContent"
                [popoverTitle]="'Delete User'"
                #popover="ngbPopover">
                Delete
              </button>
              <ng-template #deletePopoverContent>
                Permanently delete this user?
                <div class="text-end mt-2">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm px-3 m-1"
                    (click)="popover.close()">
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm px-3 m-1"
                    (click)="popover.close(); deleteUser()">
                    Yes
                  </button>
                </div>
              </ng-template>
            </div>
            <div class="col text-end">
              <button
                [disabled]="isLoading"
                type="submit"
                (click)="submitEditUserData()"
                class="btn btn-md btn-primary">
                <ng-container *ngIf="isLoading">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </ng-container>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</app-modal>
