import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { CommentModelDTO } from '../../components/evidence-comments/evidence-comments.dto';
import { EvidenceDTO, EvidenceType } from '../../dtos/attendee-rubric.dto';
import { FileDTO } from '../../dtos/file.dto';
import { VideoReferenceObjectType } from '../../enums/video-reference-object-type.enum';
import {
  createEvidencePayloadFromDTO,
  evidenceDTOFromAPIResponse,
  evidencePayloadFromDTO,
} from '../../helpers/translators/evidence.translators';
import { CopyEvidencePayload } from '../../types/payloads/coaching-log.payloads';
import { CoacheeAllEvidenceAPIResponse } from '../../types/responses/coachee-evidence';
import {
  AttachVideoPayload,
  VideoFileService,
} from '../file/video.file.service';

@Injectable({ providedIn: 'root' })
export class EvidenceService {
  constructor(
    private apiService: APICoreService,
    private videoFileService: VideoFileService
  ) {}

  addEvidence(evidence: EvidenceDTO, attachments: FileDTO[]) {
    this.apiService
      .postRequest('egrowe/evidences/many', {
        ...createEvidencePayloadFromDTO(evidence, attachments),
        attachment_file_ids: attachments.map((attachment) => attachment.id),
      })
      .subscribe((data) => {
        const newEvidence = evidenceDTOFromAPIResponse(data.items[0]);
        if (newEvidence.type === EvidenceType.VIDEO) {
          this.addVideo(
            {
              objectId: newEvidence.id,
              objectType: VideoReferenceObjectType.EVIDENCE,
              videoId: attachments[0].id,
            },
            newEvidence
          );
        }
      });
  }

  updateEvidence(evidence: EvidenceDTO) {
    const fullPayload = evidencePayloadFromDTO(evidence);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { egrowe_coachlog_id, ...payload } = fullPayload;
    const evidenceLocation = fullPayload.evidence_location_list[0];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { egrowe_coachlog_option_id, ...locationPayload } = evidenceLocation;
    payload.evidence_location_list = [locationPayload];
    return this.apiService.putRequest(
      `egrowe/evidences/${evidence.id}/v2`,
      payload
    );
  }

  getAllCoacheeEvidenceList(
    page?: number,
    sort_by?: string,
    sort_by_desc?: BooleanNumber,
    search?: string
  ): Observable<CoacheeAllEvidenceAPIResponse> {
    const params = {
      your_coachees: 1,
      page,
      sort_by,
      sort_by_desc,
      search,
    };
    return this.apiService.getRequest(
      'egrowe/dashboard/all-evidence-to-review',
      params
    );
  }

  deleteEvidence(evidenceId: number) {
    return this.apiService.deleteRequest(`egrowe/evidences/${evidenceId}`);
  }

  getEvidenceComments(evidenceId: number) {
    return this.apiService.getRequest('comments', {
      ref_table: 'egrowe_evidence',
      ref_table_id: evidenceId,
    });
  }

  saveComment(newComment: CommentModelDTO) {
    return this.apiService.postRequest('comments', newComment);
  }

  deleteComment(comment: CommentModelDTO) {
    return this.apiService.deleteRequest(`comments/${comment.id}`);
  }

  getEvidenceSummary(evidenceId: number) {
    return this.apiService.getRequest(`growelab/evidence/${evidenceId}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approveEvidence(evidenceToApprove: any) {
    return this.apiService.postFormRequest(
      `egrowe/evidences/${evidenceToApprove.id}/assessments`,
      evidenceToApprove
    );
  }

  addVideo(payload: AttachVideoPayload, evidence: EvidenceDTO): void {
    this.videoFileService.attachVideo(payload).subscribe(() => {
      // Unattaching and attaching currently do not trigger a push notification, so we fake an update
      this.updateEvidence(evidence).subscribe();
    });
  }

  removeVideo(evidence: EvidenceDTO, fromPortfolio = false): void {
    if (evidence.videos)
      this.videoFileService
        .unattachVideo(evidence.videos[0].id)
        .subscribe(() => {
          if (!fromPortfolio) {
            // Unattaching and attaching currently do not trigger a push notification, so we fake an update
            this.updateEvidence(evidence).subscribe();
          }
        });
  }

  addPortfolioEvidence(evidence: EvidenceDTO, attachments: FileDTO[]) {
    return this.apiService
      .postRequest('growelab/evidence/user', {
        ...createEvidencePayloadFromDTO(evidence, attachments),
        attachment_file_ids: attachments.map((attachment) => attachment.id),
      })
      .pipe(
        map((data) => {
          const newEvidence = evidenceDTOFromAPIResponse(data.item);
          if (newEvidence.type === EvidenceType.VIDEO) {
            this.videoFileService
              .attachVideo({
                objectId: newEvidence.id,
                objectType: VideoReferenceObjectType.EVIDENCE,
                videoId: attachments[0].id,
              })
              .subscribe(() =>
                // Unattaching and attaching currently do not trigger a push notification, so we fake an update
                this.updateEvidence(newEvidence).subscribe()
              );
          }
          return newEvidence;
        })
      );
  }

  updatePortfolioEvidence(evidence: EvidenceDTO) {
    const fullPayload = evidencePayloadFromDTO(evidence);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { egrowe_coachlog_id, ...payload } = fullPayload;
    const evidenceLocation = fullPayload.evidence_location_list[0];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { egrowe_coachlog_option_id, ...locationPayload } = evidenceLocation;
    payload.evidence_location_list = [locationPayload];
    return this.apiService.putRequest(
      `growelab/evidence/user/${evidence.id}`,
      payload
    );
  }

  copyEvidenceToGIs(evidenceId: number, payload: CopyEvidencePayload) {
    return this.apiService.postRequest(
      `growelab/evidence/${evidenceId}/copy`,
      payload
    );
  }
}
