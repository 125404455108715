import { booleanNumberFromBoolean } from 'src/app/common/utilities/enums/boolean-number.enum';
import {
  NewCoachingSessionItemsPayloadDTO,
  NewCoachingSessionPayloadDTO,
  NewSmartSessionPayloadDTO,
} from '../../dtos/coaching-session.dto';
import { CreatedCoachingLogDTO } from '../../dtos/created-coaching-log.dto';
import { CreatedCoachingLogAPIResponse } from '../../types/responses/coaching-log.responses';

export const createdCoachingLogDTOFromAPIResponse = (
  response: CreatedCoachingLogAPIResponse
): CreatedCoachingLogDTO => ({
  content: response.item.content,
  createdAt: response.item.created_at,
  creatorUserId: response.item.creator_user_id,
  districtId: response.item.district_id,
  egroweCoachlogTypeId: response.item.egrowe_coachlog_type_id,
  endDatetime: response.item.end_datetime,
  id: response.item.id,
  isCompleted: response.item.isCompleted,
  isPractice: response.item.is_practice,
  location: response.item.location,
  startDatetime: response.item.start_datetime,
  timezone: response.item.timezone,
  title: response.item.title,
  updatedAt: response.item.updated_at,
  userId: parseInt(response.item.user_id),
  version: response.item.version,
});

export const newCoachingSessionPayloadDtoFromComponent = (
  item: NewCoachingSessionItemsPayloadDTO
): NewCoachingSessionPayloadDTO => ({
  title: item.title,
  egrowe_coachlog_type_id: item.logType?.id ? item.logType.id : 0,
  start_datetime: item.startDatetime,
  end_datetime: item.endDatetime,
  timezone: item.timezone,
  user_id: item.coachUser?.id ? item.coachUser.id : 0,
  creator_user_id: item.creatorUserId,
  coachees: item.coachees?.length ? item.coachees : null,
  shadowers: item.shadowers?.length ? item.shadowers : null,
  is_private: booleanNumberFromBoolean(item.is_private),
  from_coachlog_id: item.from_coachlog_id,
});

export const newSmartSessionPayloadDtoFromComponent = (
  item: NewCoachingSessionItemsPayloadDTO
): NewSmartSessionPayloadDTO => ({
  egrowe_coachlog_type_id: item.logType?.id ? item.logType.id : 0,
  is_private: booleanNumberFromBoolean(item.is_private),
  datetimes: [{ start_datetime: item.startDatetime }],
  coachees: item.coachees,
});
