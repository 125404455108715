<div class="row justify-content-md-center">
  <div class="col-md-8 text-center">
    <h3 class="fw-800">Competencies</h3>
    <p>
      The following competencies will be the intended focus as you move through
      your coaching sessions. As you navigate your growth journey, you will
      learn and dive deep into each competency, but for now, watch these intro
      videos to get acquainted with each one.
    </p>
  </div>
</div>

<div class="row d-flex flex-wrap mb-5">
  <ng-container *ngIf="competenciesList && user">
    <div
      class="card"
      style="padding: 0"
      *ngFor="
        let competency of competenciesList | slice : 0 : limit;
        let i = index
      ">
      <app-competencies-summary
        [isSelectable]="false"
        [showVideo]="true"
        [userId]="user.id"
        [competencyId]="competency.id"></app-competencies-summary>
    </div>
  </ng-container>
  <div *ngIf="competenciesList.length > 4" class="d-flex justify-content-end">
    <button class="btn d-flex" (click)="toggleLimit()">
      <span *ngIf="limitCompetencies"
        >Show More<i class="bi bi-caret-up-fill ms-1"></i></span
      ><span *ngIf="!limitCompetencies"
        >Hide<i class="bi bi-caret-down-fill ms-1"></i
      ></span>
    </button>
  </div>
</div>

<div class="row justify-content-xxl-center">
  <div class="col-xxl-10">
    <div class="row justify-content-xxl-between">
      <div class="col-lg-6 col-xxl-5">
        <h4 class="fw-800 fs-22">
          Which Competency do you feel that you are currently strongest in?
        </h4>

        <ng-container *ngIf="competenciesList && competenciesList.length > 0">
          <ng-select
            placeholder="Select a competency"
            class="mb-4"
            [items]="competenciesList"
            [(ngModel)]="selectedStrongestId"
            (ngModelChange)="updateStrongestId()"
            [multiple]="false"
            [clearable]="false"
            [disabled]="false"
            [bindLabel]="'title'"
            [bindValue]="'id'" />
        </ng-container>

        <h4 class="fw-800 fs-22">Why</h4>
        <textarea
          aria-label="Your Strongest Why"
          #strongestWhy="ngModel"
          name="strongestWhy"
          id="strongestWhy"
          class="form-control mb-5"
          appAutosave
          [(ngModel)]="initialStrongest"
          (autosave)="saveUserProfileStrongestWhy()"
          [ngClass]="{
            'is-invalid':
              strongestWhy.invalid &&
              (strongestWhy.dirty || strongestWhy.touched)
          }"
          rows="4"
          required></textarea>
      </div>
      <div class="col-lg-6 col-xxl-5">
        <h4 class="fw-800 fs-22">
          Which Competency do you feel is an area of growth for you?
        </h4>

        <ng-container *ngIf="competenciesList && competenciesList.length > 0">
          <ng-select
            placeholder="Select a competency"
            class="mb-4"
            [items]="competenciesList"
            [(ngModel)]="selectedWeakestId"
            (ngModelChange)="updateWeakestId()"
            [multiple]="false"
            [clearable]="false"
            [disabled]="false"
            [bindLabel]="'title'"
            [bindValue]="'id'" />
        </ng-container>

        <h4 class="fw-800 fs-22">Why</h4>
        <textarea
          aria-label="Your Weakest Why"
          #weakestWhy="ngModel"
          name="weakestWhy"
          id="weakestWhy"
          class="form-control mb-5"
          appAutosave
          [(ngModel)]="initialWeakest"
          (autosave)="saveUserProfileWeakestWhy()"
          [ngClass]="{
            'is-invalid':
              weakestWhy.invalid && (weakestWhy.dirty || weakestWhy.touched)
          }"
          rows="4"
          required></textarea>
      </div>
    </div>
  </div>
</div>
