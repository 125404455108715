<div class="container">
  <app-general-header title="Implementation Plan" />
  <a routerLink="/plans" class="text-decoration-none text-body-secondary"
    >&lt; Back to Plans</a
  >
  <ng-container *ngIf="planDetails">
    <ng-container *ngIf="!planDetails.forbidden; else deniedMessage">
      <div class="row mt-2 mb-4">
        <div *ngIf="planDetails?.title; else noTitle" class="col text-center">
          <div class="d-flex justify-content-center align-items-center">
            <h1 class="fw-600">
              {{ planDetails.title }}
            </h1>
          </div>
          <div>
            {{ planDetails.start_date }} -
            {{ planDetails.end_date }}
          </div>
        </div>
        <ng-template #noTitle>
          <div style="height: 7.5rem"></div>
        </ng-template>
      </div>

      <div class="card bg-none border-0">
        <ul class="nav nav-tabs border-0">
          <li class="nav-item">
            <a
              href="javascript:void()"
              class="nav-link border-0"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLinkActive="active"
              [routerLink]="
                '/plans/implementation-plan/' + planDetails.id + '/dashboard'
              ">
              Plan Dashboard
            </a>
          </li>
          <li class="nav-item">
            <a
              href="javascript:void()"
              class="nav-link border-0"
              routerLinkActive="active"
              [routerLink]="
                '/plans/implementation-plan/' + planDetails.id + '/details'
              ">
              Plan Details
            </a>
          </li>
        </ul>
        <div class="card-body bg-white dashboard-card">
          <ng-container *ngIf="planDetails.phase_displayname; else loading">
            <div class="w-100 d-flex justify-content-end mb-4">
              <button
                *ngIf="planEditor"
                type="button"
                class="btn btn-primary"
                (click)="openCreatePhaseModal()">
                <i class="bi bi-plus"></i> Create a
                {{ planDetails.phase_displayname }}
              </button>
            </div>
            <ng-container *ngIf="planDetails.phases.length > 0; else noPhases">
              <ng-container *ngFor="let phase of planDetails.phases">
                <div ngbAccordion *ngIf="phase" class="phase mb-4">
                  <div
                    ngbAccordionItem
                    #phaseAcc="ngbAccordionItem"
                    [collapsed]="!isPhaseExpanded(phase.id)">
                    <div ngbAccordionHeader class="phase-header">
                      <div class="d-flex justify-content-between mb-2">
                        <div class="phase-title">
                          <div
                            class="d-flex align-items-center"
                            ngbAccordionToggle
                            (click)="togglePhase(phase.id)"
                            style="cursor: pointer">
                            <i
                              [class.bi-caret-down-fill]="phaseAcc.collapsed"
                              [class.bi-caret-up-fill]="
                                !phaseAcc.collapsed
                              "></i>
                            <span class="fw-bold fs-4 ms-2">{{
                              phase.title
                            }}</span>
                          </div>
                          <div class="phase-status">
                            <app-plans-status-select
                              [viewOnly]="!planEditor"
                              [status]="phase.status"
                              (selectedStatus)="
                                onPhaseStatusChange(phase.id, $event)
                              "></app-plans-status-select>
                          </div>
                        </div>
                        <i
                          *ngIf="planEditor"
                          (click)="openEditPhaseModal(phase)"
                          class="bi bi-pencil color-tertiary"
                          style="cursor: pointer"
                          ngbTooltip="Edit {{
                            planDetails.phase_displayname
                          }}"></i>
                      </div>
                      <div class="px-4">{{ phase.description }}</div>
                      <div class="phase-progress">
                        <p class="fw-bold mb-1">Progress Completed:</p>
                        <div class="position-relative">
                          <ngb-progressbar
                            [value]="formatProgress(phase.progress)"
                            [animated]="true"
                            [height]="'23px'">
                            <span class="fw-bold fs-15 text-start ms-2"
                              >{{ formatProgress(phase.progress) }}%</span
                            >
                          </ngb-progressbar>
                          <div
                            *ngIf="phase.progress === 0"
                            class="zero-percent-label">
                            0%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div ngbAccordionCollapse>
                      <div class="phase-scroll">
                        <div ngbAccordionBody class="phase-body">
                          <div class="deliverable-table-header">
                            <div class="title-column">
                              {{ planDetails.deliverable_displayname }}
                            </div>
                            <div class="status-column">Status</div>
                            <div class="progress-column">Progress</div>
                            <div class="department-column">Department</div>
                            <div class="assignee-column">Assignee</div>
                            <div class="duedate-column">Due Date</div>
                            <div class="icon-column"></div>
                          </div>
                          <ng-container
                            *ngFor="let deliverable of phase.deliverables">
                            <div *ngIf="deliverable" ngbAccordion>
                              <div
                                ngbAccordionItem
                                [collapsed]="
                                  !isDeliverableExpanded(deliverable.id)
                                "
                                #deliverableAcc="ngbAccordionItem"
                                class="deliverable-accordion-item">
                                <div ngbAccordionHeader class="row-wrapper">
                                  <div class="title-column pe-3">
                                    <div class="d-flex align-items-center mb-1">
                                      <i
                                        style="cursor: pointer"
                                        ngbAccordionToggle
                                        (click)="
                                          toggleDeliverable(deliverable.id)
                                        "
                                        class="fs-11"
                                        [class.bi-caret-down-fill]="
                                          deliverableAcc.collapsed
                                        "
                                        [class.bi-caret-up-fill]="
                                          !deliverableAcc.collapsed
                                        "></i>
                                      <span
                                        ngbAccordionToggle
                                        (click)="
                                          toggleDeliverable(deliverable.id)
                                        "
                                        class="fw-bold ms-2 text-start"
                                        style="cursor: pointer">
                                        {{ deliverable.title }}
                                      </span>
                                      <i
                                        *ngIf="deliverable.has_comments"
                                        class="bi bi-chat-text color-tertiary ms-2"
                                        ngbTooltip="Has comments"></i>
                                      <i
                                        *ngIf="deliverable.has_attachments"
                                        class="bi bi-paperclip color-tertiary ms-1"
                                        ngbTooltip="Has attachements"></i>
                                    </div>
                                    <div
                                      class="fs-14 fw-light text-start deliverable-description">
                                      {{ deliverable.description }}
                                    </div>
                                  </div>
                                  <div class="status-column">
                                    <app-plans-status-select
                                      [viewOnly]="
                                        !planEditor && !deliverable.canEdit
                                      "
                                      [status]="deliverable.status"
                                      (selectedStatus)="
                                        onDeliverableStatusChange(
                                          deliverable.id,
                                          $event
                                        )
                                      "></app-plans-status-select>
                                  </div>
                                  <div class="progress-column">
                                    {{ formatProgress(deliverable.progress) }}%
                                  </div>
                                  <div class="department-column">
                                    {{ deliverable.department }}
                                  </div>
                                  <div class="assignee-column px-4">
                                    <ng-container
                                      *ngFor="
                                        let assignment of deliverable.assignments
                                      ">
                                      <div
                                        class="d-flex flex-nowrap align-items-center my-2">
                                        <app-avatar
                                          [profile]="assignment.user.profile"
                                          [avatarSize]="'small'">
                                        </app-avatar>
                                        <div class="ms-2">
                                          {{
                                            assignment.user.profile.first_name
                                          }}
                                          {{
                                            assignment.user.profile.last_name
                                          }}
                                        </div>
                                      </div>
                                    </ng-container>
                                  </div>
                                  <div class="duedate-column">
                                    {{ deliverable.due_date | date }}
                                  </div>
                                  <div class="icon-column">
                                    <button
                                      class="btn-reset p-0"
                                      (click)="
                                        openEditDeliverableModal(
                                          deliverable.id,
                                          phase.id
                                        )
                                      "
                                      ngbTooltip="Edit Deliverable"
                                      [autoClose]="'true'">
                                      <i
                                        *ngIf="
                                          planEditor || deliverable.canEdit
                                        "
                                        class="bi bi-fullscreen color-tertiary"></i>
                                    </button>
                                  </div>
                                </div>
                                <div ngbAccordionCollapse>
                                  <div ngbAccordionBody>
                                    <ng-container
                                      *ngFor="
                                        let actionItem of deliverable.actionItems
                                      ">
                                      <div
                                        *ngIf="actionItem"
                                        class="row-wrapper action-item">
                                        <div class="title-column ps-4 pe-3">
                                          <div
                                            class="d-flex align-items-center mb-1">
                                            <span
                                              class="fw-bold ms-2 text-start">
                                              {{ actionItem.title }}
                                            </span>
                                            <i
                                              *ngIf="actionItem.has_comments"
                                              class="bi bi-chat-text color-tertiary ms-2"
                                              ngbTooltip="Has comments"></i>
                                            <i
                                              *ngIf="actionItem.has_attachments"
                                              class="bi bi-paperclip color-tertiary ms-1"
                                              ngbTooltip="Has attachements"></i>
                                          </div>
                                          <div
                                            class="ps-2 fs-14 fw-light text-start">
                                            {{ actionItem.description }}
                                          </div>
                                        </div>
                                        <div class="status-column">
                                          <app-plans-status-select
                                            [viewOnly]="
                                              !planEditor && !actionItem.canEdit
                                            "
                                            [status]="actionItem.status"
                                            (selectedStatus)="
                                              onActionItemStatusChange(
                                                actionItem.id,
                                                $event
                                              )
                                            "></app-plans-status-select>
                                        </div>
                                        <div class="progress-column"></div>
                                        <div class="department-column"></div>
                                        <div class="assignee-column px-4">
                                          <ng-container
                                            *ngFor="
                                              let assignment of actionItem.assignments
                                            ">
                                            <div
                                              class="d-flex flex-nowrap align-items-center my-2">
                                              <app-avatar
                                                [profile]="
                                                  assignment.user.profile
                                                "
                                                [avatarSize]="'small'">
                                              </app-avatar>
                                              <div class="ms-2">
                                                {{
                                                  assignment.user.profile
                                                    .first_name
                                                }}
                                                {{
                                                  assignment.user.profile
                                                    .last_name
                                                }}
                                              </div>
                                            </div>
                                          </ng-container>
                                        </div>
                                        <div class="duedate-column">
                                          {{ actionItem.due_date | date }}
                                        </div>
                                        <div class="icon-column">
                                          <button
                                            class="btn-reset p-0"
                                            (click)="
                                              openEditActionItemModal(
                                                actionItem.id,
                                                deliverable.id,
                                                phase.id
                                              )
                                            ">
                                            <i
                                              *ngIf="
                                                planEditor || actionItem.canEdit
                                              "
                                              class="bi bi-fullscreen color-tertiary"
                                              ngbTooltip="Edit Action Item"
                                              [autoClose]="'true'"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </ng-container>
                                    <div
                                      class="no-action-found"
                                      *ngIf="
                                        deliverable.actionItems.length < 1
                                      ">
                                      No
                                      {{ planDetails.actionitem_displayname }}
                                      found
                                    </div>
                                    <div
                                      *ngIf="planEditor || deliverable.canEdit"
                                      class="p-2 ps-4 action-item">
                                      <button
                                        (click)="
                                          createActionItemModal.openModal()
                                        "
                                        class="btn btn-primary ms-1">
                                        <i class="bi bi-plus"></i> Add
                                        {{ planDetails.actionitem_displayname }}
                                      </button>
                                    </div>
                                    <app-create-action-item-modal
                                      #createActionItemModal
                                      [displayName]="
                                        planDetails.actionitem_displayname
                                      "
                                      [districtId]="planDetails.district_id"
                                      [deliverable]="deliverable" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <div
                            class="no-deliverable-found"
                            *ngIf="phase.deliverables.length < 1">
                            No {{ planDetails.deliverable_displayname }} found
                          </div>
                          <div *ngIf="planEditor" class="p-2 phase-footer">
                            <button
                              class="btn btn-primary ms-2"
                              (click)="openCreateDeliverableModal(phase.id)">
                              <i class="bi bi-plus"></i> Add
                              {{ planDetails.deliverable_displayname }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <app-create-edit-phase-modal
              #createEditPhaseModal
              [displayName]="planDetails.phase_displayname"
              [planId]="planDetails.id"></app-create-edit-phase-modal>
            <app-create-deliverable-modal
              #createDeliverableParentModal
              [planDetails]="planDetails" />
            <app-edit-deliverable-modal
              #editDeliverableParentModal
              [districtId]="planDetails.district_id"
              [deliverable]="currentDeliverable"
              [phaseId]="currentPhaseId" />
            <app-edit-action-item-modal
              #editActionItemModal
              [districtId]="planDetails.district_id"
              [displayName]="planDetails.actionitem_displayname"
              [actionItem]="currentActionItem"
              [phaseId]="currentPhaseId" />
          </ng-container>
          <ng-template #noPhases
            ><div
              *ngIf="planDetails?.phase_displayname"
              class="d-flex flex-column align-items-center justify-content-center w-100"
              style="min-height: 400px">
              <h2>
                No
                {{
                  planDetails.phase_displayname === "Strategy"
                    ? "Strategies"
                    : planDetails.phase_displayname + "s"
                }}
                Found
              </h2>
              <p>
                Please create a
                {{ planDetails.phase_displayname.toLowerCase() }}
              </p>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #deniedMessage>
    <div class="mt-5">
      <app-permission-denied />
    </div>
  </ng-template>
  <ng-template #loading>
    <div
      class="w-100 position-relative mt-3"
      style="background-color: #fff; height: 600px">
      <app-loader></app-loader>
    </div>
  </ng-template>
</div>
