<app-modal
  #modal
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }"
  (isClosing)="resetForm()"
  (isDismissing)="resetForm()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <form [formGroup]="form">
    <label for="topic-title" class="form-label">Topic:</label>
    <div class="input-group mb-3">
      <input
        formControlName="topicTitle"
        type="text"
        class="form-control"
        id="topic-title"
        aria-label="Topic Title" />
    </div>
    <label for="topic-audience" class="form-label">Audience:</label>
    <div formArrayName="audienceTypes" class="input-group mb-3">
      <div
        class="col-12 col-sm-6 col-lg-4 mb-2"
        *ngFor="let audienceType of audienceTypes; let i = index">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="audienceType.id + '-checkbox'"
            [formControlName]="i"
            (change)="onCheckboxChange($event, i)" />
          <label class="form-check-label" [for]="audienceType.id">
            {{ audienceType.title }}
          </label>
        </div>
      </div>
    </div>
    <label for="is-smart" class="form-label">mySmartCoach Enabled:</label>
    <div class="form-switch mb-3">
      <input
        formControlName="isSmart"
        type="checkbox"
        class="form-check-input"
        id="is-smart" />
    </div>
    <ng-container *ngIf="isEditMode">
      <label for="district-select" class="form-label">Districts:</label>
      <div class="mb-3">
        <app-district-select
          #districtSelect
          [isMulti]="true"
          (districtsLoaded)="districtsLoaded = true"></app-district-select>
      </div>
    </ng-container>
    <div footerContent>
      <div class="row mt-5 justify-content-between align-items-center">
        <div *ngIf="isEditMode; else cancel" class="col">
          <button
            aria-label="delete button"
            class="btn btn-delete"
            type="button"
            [autoClose]="'outside'"
            [ngbPopover]="deletePopoverContent"
            [popoverTitle]="'Delete Topic'"
            #popover="ngbPopover">
            Delete
          </button>
          <ng-template #deletePopoverContent>
            Permanently delete this topic?
            <div class="text-end mt-2">
              <button
                type="button"
                class="btn btn-secondary btn-sm px-3 m-1"
                (click)="popover.close()">
                No
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm px-3 m-1"
                (click)="popover.close(); deleteTopic()">
                Yes
              </button>
            </div>
          </ng-template>
        </div>
        <ng-template #cancel>
          <div class="col">
            <button type="button" class="btn" (click)="modal.close()">
              Cancel
            </button>
          </div>
        </ng-template>
        <div class="col text-end">
          <button
            (click)="onSubmit()"
            [disabled]="
              !form.valid || submitLoading || (isEditMode && !districtsLoaded)
            "
            type="submit"
            class="btn btn-md btn-primary">
            <span
              *ngIf="submitLoading"
              class="spinner-border spinner-border-sm me-1"></span>
            {{ isEditMode ? "Save" : "Create" }}
          </button>
        </div>
      </div>
    </div>
  </form>
</app-modal>
