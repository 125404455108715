import { UserDTO } from 'src/app/common/state/user/user.dto';

export class CommentModelDTO {
  id: number;

  comment: string;

  user_id: number;

  user: UserDTO;

  created_at: number;

  ref_table: string;

  ref_table_id: number;

  feedback?: CommentFeedbackModelDTO[];

  positive_feedback_count?: number;

  negative_feedback_count?: number;

  your_feedback?: string;
}

export interface CommentFeedbackModelDTO {
  id: number;
  esuite_coment_id: number;
  user_id: number;
  type: string;
  user: UserDTO;
}
