import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { SmartCoachDTO } from '../../dtos/smartcoach.dto';
import { IntroSessionService } from '../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-intro-your-coach',
  templateUrl: './intro-your-coach.component.html',
  styleUrls: ['./intro-your-coach.component.scss'],
})
export class IntroYourCoachComponent implements OnInit {
  user: User;

  smartCoaches: SmartCoachDTO[] = [];

  videoToShow: VideoDTO | null = null;

  constructor(
    public introSessionService: IntroSessionService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.getCoaches();
  }

  getCoaches() {
    this.introSessionService.getCoaches().subscribe((res) => {
      if (res) {
        res.items.forEach((item: UserDTO) => {
          const smartCoach = {} as SmartCoachDTO;
          smartCoach.coach = item;
          // check to see if smartcoach is already assigned
          if (this.user.smartCoach && this.user.smartCoach.id === item.id) {
            smartCoach.isSelected = true;
          } else {
            smartCoach.isSelected = false;
          }
          // get video links
          this.introSessionService
            .getCoachingStyleVideo(item.id)
            .subscribe((video) => {
              if (video) {
                smartCoach.video = video;
              }
              this.smartCoaches.push(smartCoach);
            });
        });
      }
    });
  }

  completeCoach() {
    this.introSessionService.setCoachComplete(true, true);
  }

  unCompleteCoach() {
    this.introSessionService.setCoachComplete(false, false);
  }

  toggleCoach(newCoach: SmartCoachDTO) {
    this.smartCoaches.forEach((smartCoach) => {
      if (smartCoach.coach.id === newCoach.coach.id) {
        if (!smartCoach.isSelected) {
          smartCoach.isSelected = true;
          this.selectCoach(smartCoach.coach.id, this.user.id);
          this.user.smartCoach = smartCoach.coach;
          this.completeCoach();
        }
      } else {
        smartCoach.isSelected = false;
      }
    });
  }

  selectCoach(smartCoachId: number, userId: number) {
    this.introSessionService
      .chooseCoach({ smart_coach_user_id: smartCoachId, user_id: userId })
      .subscribe();
  }

  playVideo(video: VideoDTO) {
    this.videoToShow = video;
  }
}
