import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CelebrationFilterDTO } from '../../dtos/celebrations.dto';
import {
  celebrationDTOFromAPIResponse,
  celebrationFilterDTOFromComponent,
  celebrationListDTOFromAPIResponse,
} from '../../helpers/translators/celebrations.translator';
import {
  CelebrationAttachmentPayload,
  CreateCelebrationPayload,
} from '../../types/payloads/celebrations.payloads';
import {
  CelebrationAPIResponse,
  CelebrationsListAPIResponse,
  CreatedCelebrationAPIResponse,
} from '../../types/responses/celebrations.responses';

@Injectable({ providedIn: 'root' })
export class CelebrationsService {
  constructor(private apiService: APICoreService) {}

  getCelebration(id: number) {
    return this.apiService.getRequest(`egrowe/endorsements/${id}`).pipe(
      map((res) =>
        celebrationDTOFromAPIResponse(res.item as CelebrationAPIResponse)
      ),
      catchError((err) => {
        throw err;
      })
    );
  }

  getFilteredCelebrations(filters: CelebrationFilterDTO) {
    const requestOptions = celebrationFilterDTOFromComponent(filters);
    return this.apiService
      .getRequest('egrowe/endorsements', requestOptions)
      .pipe(
        map((res) => {
          const responseDTO = celebrationListDTOFromAPIResponse(
            res as CelebrationsListAPIResponse
          );
          return responseDTO;
        })
      );
  }

  createCelebration(users: number[], content: string) {
    const payload: CreateCelebrationPayload = {
      title: 'User Endorsement',
      content: content || '',
      endorse_users: users,
      is_brightspot: 0,
    };
    return this.apiService.postRequest('egrowe/endorsements', payload).pipe(
      map((res) => {
        const responseDTO = res as CreatedCelebrationAPIResponse;
        return responseDTO;
      })
    );
  }

  updateCelebrationCompetencies(celebrationId: number, standardId: number) {
    return this.apiService
      .postRequest(
        `egrowe/endorsements/${celebrationId}/set-standard/${standardId}`
      )
      .pipe(map((res) => res));
  }

  updateCelebrationAttachments(payload: CelebrationAttachmentPayload) {
    return this.apiService
      .putRequest('growelab/endorsements/attach-file', payload)
      .pipe(map((res) => res));
  }

  deleteCelebration(celebrationId: number) {
    return this.apiService
      .deleteRequest(`egrowe/endorsements/${celebrationId}`)
      .pipe(map((res) => res));
  }

  deleteCelebrationCompetencies(celebrationId: number, standardId: number) {
    return this.apiService
      .deleteRequest(
        `egrowe/endorsements/${celebrationId}/relation/standard/${standardId}`
      )
      .pipe(map((res) => res));
  }

  editCelebrationContent(payload: { celebrationId: number; content: string }) {
    return this.apiService
      .putRequest(`egrowe/endorsements/${payload.celebrationId}`, payload)
      .pipe(map((res) => res));
  }
}
