import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quick-stat',
  templateUrl: './quick-stat.component.html',
  styleUrls: ['./quick-stat.component.scss'],
})
export class QuickStatComponent {
  @Input() label: string;

  @Input() link: string;

  @Input() stat: number | string;
}
