<div class="table-wrapper w-100">
  <table *ngIf="userList" class="table">
    <thead>
      <tr>
        <th class="text-center" style="width: 40px; min-width: 40px">
          <input
            (change)="handleCheckAll()"
            [(ngModel)]="masterSelected"
            ngbTooltip="Select all users on this page"
            triggers="hover"
            type="checkbox"
            class="form-check-input"
            aria-label="Select all coachees" />
        </th>
        <th class="hide-on-mobile" scope="col" style="width: 5%">ID</th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="name"
          style="min-width: 20%">
          Name
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.name }}"></i>
          </ng-container>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="email"
          style="min-width: 150px">
          Email
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.email }}"></i>
          </ng-container>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="school"
          class="hide-on-mobile"
          style="width: 22.5%">
          School
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.school }}"></i>
          </ng-container>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="coachee_type"
          class="hide-on-mobile"
          style="min-width: 120px; width: 10%">
          User Type
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.coachee_type }}"></i>
          </ng-container>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="cohort"
          class="hide-on-mobile"
          style="width: 20%">
          Cohorts
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.cohort }}"></i>
          </ng-container>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="status"
          style="min-width: 90px"
          class="active">
          Active
          <ng-container *ngIf="!loadingIcons">
            <i class="bi bi-caret-down {{ sortIconTemp.status }}"></i>
          </ng-container>
        </th>
        <th style="width: 5%"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <ng-container *ngIf="userList.length === 0">
          <tr>
            <td colspan="8">
              <div
                class="no-sessions d-flex flex-column align-items-center justify-content-center"
                style="background-color: #fff; min-height: 400px">
                <h2>No Users Found</h2>
                <p>Please check your User search term or add users</p>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr class="v-mid" *ngFor="let user of userList">
          <td class="text-center">
            <input
              (change)="onCheckboxChange($event)"
              [(ngModel)]="user.isSelected"
              class="form-check-input"
              type="checkbox"
              id="{{ 'checkbox' + user.id }}"
              value="{{ user.id }}"
              aria-label="select" />
          </td>
          <td class="hide-on-mobile">{{ user.id }}</td>
          <td>
            <div class="d-flex justify-content-start align-items-center">
              <app-avatar [profile]="user.profile" [avatarSize]="'small'">
              </app-avatar>
              <div class="username ms-2">
                {{ user.profile.first_name }}
                {{ user.profile.last_name }}
              </div>
            </div>
          </td>
          <td style="word-break: break-all">{{ user.email }}</td>
          <td class="hide-on-mobile">{{ user.school?.title || "-" }}</td>
          <td class="hide-on-mobile">
            {{ user.coachee_type || "-" }}
          </td>
          <td class="hide-on-mobile">
            <ng-container *ngFor="let cohort of user.cohorts">
              <span class="badge badge-item">{{ cohort.tag }}</span
              >&nbsp;
            </ng-container>
          </td>
          <td class="text-center">
            <ng-container
              *ngIf="
                user.status === 10;
                then checked;
                else unchecked
              "></ng-container>
          </td>
          <td>
            <i
              (click)="editUserEvent.emit(user)"
              ngbTooltip="Edit User"
              class="bi bi-pencil cursor-pointer color-tertiary"></i>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<ng-template #checked
  ><i class="bi bi-check2-circle text-info"></i
></ng-template>
<ng-template #unchecked><i class="bi bi-circle-fill"></i></ng-template>
<section>
  <div class="d-flex justify-content-center">
    <ngb-pagination
      *ngIf="listMeta && listMeta.totalCount > 10"
      [(page)]="listMeta.currentPage"
      [pageSize]="listMeta.perPage"
      [collectionSize]="listMeta.totalCount"
      [maxSize]="5"
      (pageChange)="outputChanges($event)"></ngb-pagination>
  </div>
</section>

<ng-template #loadingTemplate>
  <div style="height: 500px">
    <app-loader />
  </div>
</ng-template>
