<div class="card border-0">
  <div class="card-header pt-3">
    <h2 class="card-title float-start" (click)="triggerCollapse()">
      {{ cardTitle }}
      <i class="bi bi-caret-{{ isCollapsed ? 'down' : 'up' }}-fill"></i>
    </h2>
  </div>
  <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <app-report-controller
      *ngIf="currentError === ''; else renderErrorMessage"
      [chartType]="chartType"
      [percentage]="percentage"
      [reportTitle]="reportTitle"
      [cardHeight]="cardHeight"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [xNameGap]="xNameGap"
      [yNameGap]="yNameGap"
      [gridDimensions]="gridDimensions"
      [chartColor]="chartColor"
      [requestParams]="requestParams"
      (clickEvent)="onClickEmit($event)"
      (errorMessage)="handleError($event)" />
    <ng-template #renderErrorMessage
      ><div class="d-flex justify-content-center align-items-center">
        {{ currentError }}
      </div></ng-template
    >
  </div>
</div>
