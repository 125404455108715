import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CreateEditPhasePayload } from '../../../dtos/plans.dto';
import { AlertService } from '../../../services/alert/alert.service';
import { PlansService } from '../../../services/plans/plans.service';
import {
  AddPhase,
  DeletePhase,
  EditPhase,
} from '../../../state/implementation-plan/implementation-plan.actions';
import { PhaseAPIResponse } from '../../../types/responses/plan.responses';
import { PlansStatusSelectComponent } from '../../plans/plans-status-select/plans-status-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-create-edit-phase-modal',
  templateUrl: './create-edit-phase-modal.component.html',
  styleUrl: './create-edit-phase-modal.component.scss',
})
export class CreateEditPhaseModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('statusSelect') statusSelect: PlansStatusSelectComponent;

  @Input() planId: number;

  @Input() displayName: string;

  @Input() isEditMode = false;

  phaseToEdit: PhaseAPIResponse | undefined;

  form: FormGroup;

  formSubmitted = false;

  submitLoading = false;

  serverError = '';

  constructor(
    private formBuilder: FormBuilder,
    private plansService: PlansService,
    private alertService: AlertService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
    });
  }

  ngAfterViewInit() {
    this.modal.isOpening.subscribe(() => {
      if (this.isEditMode) {
        this.setFormValuesForEdit();
      }
    });
  }

  setFormValuesForEdit() {
    if (this.phaseToEdit) {
      this.form.patchValue({
        title: this.phaseToEdit.title,
        description: this.phaseToEdit.description,
      });
    }
  }

  get f() {
    return this.form.controls;
  }

  clearForm() {
    this.form.reset({
      title: '',
      description: '',
    });
    this.formSubmitted = false;
    this.serverError = '';
  }

  onSubmit() {
    this.formSubmitted = true;
    this.submitLoading = true;
    if (this.form.invalid) {
      this.submitLoading = false;
      return;
    }

    if (this.isEditMode) {
      this.editPhase();
    } else {
      this.createPhase();
    }
  }

  editPhase() {
    if (this.phaseToEdit) {
      const editPhasePayload: CreateEditPhasePayload = {
        title: this.form.value.title,
        description: this.form.value.description,
        status: this.statusSelect.status,
      };
      this.plansService
        .editPhase(this.phaseToEdit.id, editPhasePayload)
        .subscribe({
          error: (error) => {
            this.submitLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: (res) => {
            if (res) {
              this.store.dispatch(new EditPhase({ phase: res.item }));
              this.alertService.showAlert(`${this.displayName} Updated`);
              this.modal.close();
              this.submitLoading = false;
            }
          },
        });
    }
  }

  deletePhase() {
    if (this.phaseToEdit) {
      const phaseId = this.phaseToEdit.id;
      this.plansService.deletePhase(phaseId).subscribe({
        error: (error) => {
          this.submitLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: () => {
          this.store.dispatch(new DeletePhase({ phaseId }));
          this.alertService.showAlert(`${this.displayName} Deleted`);
          this.modal.close();
        },
      });
    }
  }

  createPhase() {
    const createPhasePayload: CreateEditPhasePayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
    };

    this.plansService.createPhase(this.planId, createPhasePayload).subscribe({
      error: (error) => {
        this.submitLoading = false;
        this.serverError = error.error.message;
        setTimeout(() => {
          this.serverError = '';
        }, 3000);
      },
      next: (res) => {
        if (res) {
          this.store.dispatch(new AddPhase({ phase: res.item }));
          this.alertService.showAlert(`${this.displayName} Created`);
          this.modal.close();
          this.submitLoading = false;
        }
      },
    });

    return true;
  }
}
