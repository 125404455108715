import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompetencyBuilderIndicatorSetAPIResponse } from 'src/app/private/shared/types/responses/competency-builder.responses';

@Component({
  selector: 'app-builder-leveled-competency',
  templateUrl: './builder-leveled-competency.component.html',
  styleUrls: ['./builder-leveled-competency.component.scss'],
})
export class BuilderLeveledCompetencyComponent {
  @Input() indicatorSet: CompetencyBuilderIndicatorSetAPIResponse;

  @Output() readonly deleteCompetencyEvent = new EventEmitter<number>();

  @Output() readonly badgeGraphicUploadedEvent = new EventEmitter();
}
