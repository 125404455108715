import { VideoCategoryDTO, VideoDTO } from 'src/app/common/dtos/video.dto';

import { Dictionary } from 'src/app/common/types/types';
import {
  booleanFromBooleanNumber,
  booleanNumberFromBoolean,
} from '../../../../common/utilities/enums/boolean-number.enum';
import {
  VideoAPIResponse,
  VideoCategoryResponse,
  VideoCreatePayload,
  VideoEditPayload,
} from '../../types/responses/video.responses';
import { userDTOFromAPIResponse } from './user.translators';

export const videoDTOFromAPIResponse = (item: VideoAPIResponse): VideoDTO => ({
  allowComments: booleanFromBooleanNumber(item.allow_comments),
  belongsToVideoLibrary: booleanFromBooleanNumber(
    item.belongs_to_video_library
  ),
  categoryId: item.category_id,
  createdAt: item.created_at,
  description: item.description,
  esuiteLink: item.esuite_link,
  id: item.id,
  imagePath: item.image_path,
  isDeleted: booleanFromBooleanNumber(item.is_deleted),
  length: item.length,
  processedByVimeo: booleanFromBooleanNumber(item.processed_by_vimeo),
  role: item.role,
  tags: item.tags,
  title: item.title,
  updatedAt: item.updated_at,
  user: item.user ? userDTOFromAPIResponse(item.user) : undefined,
  userId: item.user_id,
  viewsCount: item.views_count,
  vimeoLink: item.vimeo_link,
  vimeoUri: item.vimeo_uri,
});

export const videoModelsFromAPIResponse = (response: {
  items: VideoAPIResponse[];
}): VideoDTO[] => response.items.map((item) => videoDTOFromAPIResponse(item));

export const videoCategoryDTOFromAPIResponse = (
  item: VideoCategoryResponse
): VideoCategoryDTO => ({
  id: item.id,
  name: item.name,
  createdAt: item.created_at,
  updatedAt: item.updated_at,
  isDeleted: item.is_deleted,
});

const videoTranslatorTuples = [
  ['categoryId', 'category_id'],
  ['description', 'description'],
  ['esuiteLink', 'esuite_link'],
  ['imagePath', 'image_path'],
  ['id', 'id'],
  ['length', 'length'],
  ['role', 'role'],
  ['title', 'title'],
  ['userId', 'user_id'],
  ['vimeoLink', 'vimeo_link'],
  ['vimeoUri', 'vimeo_uri'],
  ['belongsToVideoLibrary', 'belongs_to_video_library'],
];

export const videoCreatePayloadFromDTO = (
  video: Partial<VideoDTO>
): VideoCreatePayload => {
  const output: Dictionary = {};
  Object.keys(video).forEach((key) => {
    const tuple = videoTranslatorTuples.find((t) => t[0] === key);
    if (tuple) {
      output[tuple[1]] = video[key as keyof VideoDTO];
    }
  });
  return output as VideoCreatePayload;
};

export const videoEditPayloadFromDTO = (
  video: Partial<VideoDTO>
): VideoEditPayload => {
  const output: Dictionary = {};
  Object.keys(video).forEach((key) => {
    const tuple = videoTranslatorTuples.find((t) => t[0] === key);
    if (tuple) {
      const value =
        key === 'belongsToVideoLibrary'
          ? booleanNumberFromBoolean(video[key as keyof VideoDTO] as boolean)
          : video[key as keyof VideoDTO];
      if (value && value !== null) {
        output[tuple[1]] = value;
      }
    }
  });
  if (!output['id']) {
    throw new Error('Video ID is required');
  }
  return output as VideoEditPayload;
};
