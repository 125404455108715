export enum ChartType {
  Bar = 'bar',
  HorizontalBar = 'horizontal-bar',
  Line = 'line',
  Area = 'area',
  Donut = 'donut',
  Pie = 'pie',
  HorizontalStackedBar = 'stacked-horizontal-bar',
}

export const barChartTypes = [
  ChartType.Bar,
  ChartType.HorizontalBar,
  ChartType.HorizontalStackedBar,
];

export enum ChartDrilldownType {
  Month = 'month',
  School = 'school',
}
