<div class="resource-item" ngClass="resourcetype-{{ resourceListItem.type }}">
  <div class="resource-item__type">
    <i
      [ngClass]="{
        'bi bi-lightbulb': resourceListItem.type == 'research',
        'bi bi-tools': resourceListItem.type == 'diy',
        'bi bi-people': resourceListItem.type == 'workshop',
        'bi bi-briefcase': resourceListItem.type == 'template',
        'bi bi-camera-reels': resourceListItem.type == 'video'
      }"></i>
  </div>
  <div class="resource-item__primary small">
    <h3 class="resource-title">
      <i
        [ngClass]="{
          'bi bi-lightbulb': resourceListItem.type == 'research',
          'bi bi-tools': resourceListItem.type == 'diy',
          'bi bi-people': resourceListItem.type == 'workshop',
          'bi bi-briefcase': resourceListItem.type == 'template',
          'bi bi-camera-reels': resourceListItem.type == 'video'
        }"></i>
      <span
        *ngIf="
          resourceListItem.search_score && resourceListItem.search_score == 9999
        "
        ><i class="glyph-e2l teal-font"></i>&nbsp;!!!!</span
      ><a
        [attr.aria-label]="'resources/' + path"
        target="_blank"
        [href]="'resources/' + path"
        >{{ resourceListItem.title }}</a
      >
    </h3>

    <ng-container
      *ngIf="resourceListItem.owners && resourceListItem.owners.length > 0">
      <div *ngIf="resourceListItem.owners.length">
        <div class="owners">
          <strong
            ><span *ngIf="resourceListItem.type == 'diy'">{{
              resourceListItem.type | uppercase
            }}</span
            ><span *ngIf="resourceListItem.type !== 'diy'">{{
              resourceListItem.type | titlecase
            }}</span>
            Resource</strong
          >
          by
          <ng-container
            *ngFor="let owner of resourceListItem.owners; last as isLast">
            <app-users-name [userProfile]="owner.profile"></app-users-name
            ><span *ngIf="!isLast">, </span>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="resourceListItem.updated_at">
      <div class="updated">
        <strong>Last updated:</strong>
        {{ resourceListItem.updated_at * 1000 | date }}
      </div>
    </ng-container>

    <ng-container *ngIf="resourceListItem.flag">
      <div class="updated">
        <strong class="red-font"><i class="glyph-flag"></i> Flagged </strong>
        {{ resourceListItem.flag.created_at * 1000 | date }}
      </div>
    </ng-container>
  </div>

  <div class="resource-item__secondary">
    <ng-container *ngIf="resourceListItem.description">
      <div class="text">
        {{ resourceListItem.description }}
      </div>
    </ng-container>
    <ng-container
      *ngIf="resourceListItem.tags && resourceListItem.tags.length > 0">
      <div class="tags small">
        <strong>Tags:</strong>&nbsp;
        <ng-container *ngFor="let tag of resourceListItem.tags; last as isLast"
          ><span
            class="fs-13 resource-txt-link"
            routerLink="/eplc/search/tag/{{ tag.tag }}"
            >{{ tag.tag }}</span
          ><span *ngIf="!isLast">, </span>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="course-list-item__favorite-wrapper">
    <ng-container *ngIf="resourceListItem">
      <app-folder-bookmark
        [itemType]="'elearn_resource'"
        [itemId]="resourceListItem.id"
        [itemInAFolder]="resourceListItem.isOnUsersShelf"></app-folder-bookmark>
    </ng-container>
  </div>
</div>
