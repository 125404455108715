<div class="container">
  <app-general-header title="Coaching Sessions" />
  <div class="card border-0">
    <div class="card-body p-3">
      <ng-container *ngIf="sessionLoaded && !isLoading; else loadingTemplate">
        <ng-container *ngIf="sessionData; else sessionErrorMessage">
          <app-coaching-log-notes
            (hideNotesEvent)="toggleNotes()"
            [user]="user"
            [sessionId]="sessionId"
            [notesActive]="notesActive" />
          <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
            <div class="row mb-3">
              <div class="col-6">
                <a
                  href="javascript:void(0)"
                  (click)="navigatePreviousScreen()"
                  class="text-decoration-none text-body-secondary">
                  < Back to Observation Form
                </a>
              </div>
              <div class="col-6 text-end">
                <div *ngIf="sessionData && sessionData.attendees.length > 1">
                  <label class="mb-2" [id]="'attendee' + sessionData.id"
                    >Attendee:</label
                  >
                  <ng-select
                    [labelForId]="'attendee' + sessionData.id"
                    [items]="sessionData.attendees"
                    [searchable]="false"
                    [clearable]="false"
                    bindLabel="fullName"
                    placeholder="Choose attendee"
                    (ngModelChange)="attendeeSelected($event)"
                    [(ngModel)]="selectedAttendee">
                  </ng-select>
                </div>
              </div>
            </div>
            <h2 class="fs-1 fw-bold mb-5 text-center">
              {{ sessionData.title }}
            </h2>
            <div class="pt-5">
              <div class="d-flex justify-content-center">
                <i
                  class="mb-3 bi bi-check2-circle text-info"
                  style="font-size: 7rem"></i>
              </div>
              <div class="d-flex justify-content-center">
                <p class="fs-5 fw-800">
                  Success! The Observation and Feedback form has been submitted.
                </p>
              </div>
            </div>
            <div class="mt-2 mb-5 d-flex justify-content-center">
              <button
                (click)="navigateDashboard()"
                class="btn btn-primary btn-next"
                style="pointer-events: auto">
                Go to Your Dashboard
              </button>
            </div>

            <!-- Not using currently, commented in case we need to display to submission summary in the future -->
            <!-- <app-form-view
              #formView
              [showTitle]="false"
              [submissionStatus]="SubmissionStatuses.SUBMITTED"
              [submissionData]="currentSubmission"
              [inputDisabled]="true"
              [form]="selectedForm"></app-form-view> -->
          </ng-container>
          <ng-template #noAttendeeTemplate>
            <p>
              <ngb-alert [dismissible]="false">
                <strong>No Present Attendees</strong> At least 1 attendee must
                be marked present.
                <a
                  routerLink="/coaching/log/{{ sessionId }}/info"
                  class="text-decoration-none text-body-secondary"
                  ><br />
                  &lt; back
                </a>
              </ngb-alert>
            </p>
          </ng-template>
          <div
            role="button"
            (click)="toggleNotes()"
            class="d-none d-sm-block border border-2 border-end-0 border-secondary rounded-start notes-fade-out notes-tab {{
              notesTabActive ? '' : 'hide'
            }} ">
            <div class="d-flex align-items-center">
              <i class="bi bi-journal journal-icon"></i>
              <p class="notes-title">Notes</p>
            </div>
          </div>
        </ng-container>
        <ng-template #sessionErrorMessage>
          <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
            <app-error-message [message]="coachLogStatus.errors[0]" />
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <div style="height: 500px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
