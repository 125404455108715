<div class="card border-0">
  <div class="card-header pt-3">
    <h2
      class="card-title float-start"
      [ngClass]="{ 'no-cursor': dashboardType === 'reporting' }"
      (click)="triggerCollapse()">
      {{ cardTitle }}
      <i
        *ngIf="dashboardType !== 'reporting'"
        class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
    </h2>
  </div>
  <div
    class="card-body leader-glance"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed">
    <div class="row">
      <ng-container *ngFor="let item of getCardListInOrder()">
        <div
          class="col-lg-6 col-12 mb-3"
          [ngClass]="{ 'col-xl-4': dashboardType === 'reporting' }">
          <div class="d-flex align-items-center">
            <div
              class="icon"
              [ngStyle]="{
                'background-image':
                  'url(/assets/report/' +
                  item.title.split(' ').join('').toLowerCase() +
                  '.svg)'
              }"></div>
            <div>
              <span class="fw-800 fs-25">
                {{ item.count }}
              </span>
              <span class="fw-200 fs-17 ms-1">
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="dashboardType === 'leader-dashboard'">
      <ng-container *ngIf="user?.coacheeType === 'School Admin'">
        <div class="text-body-secondary small text-center mt-2">
          {{ user?.district?.reportingSchoolYear }} school year at a glance for
          {{ user?.school?.title }}
        </div>
      </ng-container>
      <ng-container *ngIf="user?.coacheeType != 'School Admin'">
        <div class="text-body-secondary small text-center mt-2">
          {{ user?.district?.reportingSchoolYear }} school year at a glance for
          {{ user?.district?.title }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
