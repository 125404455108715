<h5 class="fw-800">
  <i class="bi bi-pc-display-horizontal me-2" style="color: #fd1091"></i>
  S2 Resources (Leveled Resources)
</h5>
<ng-container *ngIf="resources">
  <ng-container *ngIf="resources.length > 0; else noResources">
    <div class="mb-3">
      <div class="row g-3 card-group">
        <div
          class="col col-xs-1 col-sm-6 col-xl-4"
          *ngFor="let resource of resources | slice : 0 : limit; let i = index">
          <app-resource-leveled
            [resource]="resource"
            [level]="levelNumber"
            (signalSelection)="removeResource($event)"></app-resource-leveled>
        </div>
      </div>

      <div *ngIf="resources.length > 3" class="d-flex justify-content-end">
        <button class="btn d-flex" (click)="toggleLimit()">
          <span *ngIf="limitResources"
            >Show More<i class="bi bi-caret-up-fill ms-1"></i></span
          ><span *ngIf="!limitResources"
            >Hide<i class="bi bi-caret-down-fill ms-1"></i
          ></span>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #noResources>
    <div
      class="d-flex w-100 align-items-center justify-content-center text-body-secondary"
      style="height: 70px">
      No leveled resources available.
    </div>
  </ng-template>
</ng-container>
