<div class="div my-2">
  <div class="d-flex flex-nowrap align-items-center">
    <div [class.absent-avatar]="shadower.present != 1 && !isCoach">
      <app-avatar
        [profile]="shadower.user.profile ? shadower.user.profile : null"
        [avatarSize]="avatarSize || 'medium'" />
    </div>
    <div
      class="username ms-2"
      [class.absent-username]="shadower.present != 1 && !isCoach">
      <ng-container
        *ngIf="
          shadower.user?.profile?.first_name &&
          shadower.user?.profile?.last_name
        ">
        {{ shadower.user.profile.first_name }}
        {{ shadower.user.profile.last_name }}
      </ng-container>
      <ng-container *ngIf="!isCoach">
        <span class="ms-2">
          <ng-container *ngIf="shadower.present != 1; else present">
            (Absent)
          </ng-container>
          <ng-template #present> (Present) </ng-template>
        </span>
      </ng-container>
    </div>
    <ng-container *ngIf="isCoach">
      <button
        aria-label="remove shadower button"
        class="btn btn-delete btn-sm"
        type="button"
        ngbTooltip="Remove Shadower"
        [autoClose]="'outside'"
        [ngbPopover]="popoverContent"
        [popoverTitle]="'Remove Shadower'"
        #p="ngbPopover">
        <i class="bi bi-x-circle-fill"></i>
      </button>
      <ng-template #popoverContent>
        <div>Are you sure you wish to remove this shadower?</div>
        <div class="app-hr-thin"></div>
        <div class="text-center mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="p.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="deleteShadower(); p.close()">
            Yes
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div *ngIf="!calendarDetails" class="attendance-block">
    <ng-container *ngIf="isCoach">
      <div class="form-check form-switch d-flex align-items-center mb-1">
        <input
          id="attendance-toggle"
          class="form-check-input"
          type="checkbox"
          role="switch"
          [(ngModel)]="shadower.present"
          (ngModelChange)="saveShadowerPresence()" />
        <label class="form-check-label ms-2" for="attendance-toggle"
          >Present?</label
        >
      </div>
    </ng-container>
  </div>
</div>
