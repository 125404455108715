import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import {
  CohortDTO,
  CohortListDTO,
  CreateCohortPayloadDTO,
} from 'src/app/common/dtos/cohort.dto';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';
import {
  cohortDTOFromApiResponse,
  cohortListDTOFromApiResponse,
} from '../../helpers/translators/cohort.translators';
import {
  CohortAPIResponse,
  CohortListAPIResponse,
  DWCohort,
} from '../../types/responses/cohort.responses';

@Injectable({ providedIn: 'root' })
export class CohortService {
  constructor(private apiService: APICoreService) {}

  fetchCohorts(
    page: number,
    districtId: number,
    pageSize = 12
  ): Observable<CohortListDTO> {
    const pageNumber = page || 1;
    return this.apiService
      .getRequest(
        `cohort/search?page=${pageNumber}&pageSize=${pageSize}&districtId=${districtId}`
      )
      .pipe(
        map((res) => cohortListDTOFromApiResponse(res as CohortListAPIResponse))
      );
  }

  fetchAllCohorts(): Observable<CohortDTO[]> {
    return this.apiService
      .getRequest('cohort')
      .pipe(
        map((res) =>
          res.items.map((c: CohortAPIResponse) => cohortDTOFromApiResponse(c))
        )
      );
  }

  fetchDwCohortsFromDistricts(districtIds: number[]): Observable<DWCohort[]> {
    return this.apiService
      .getRequest('reporting/dimension', {
        dimensionName: 'cohort',
        idName: 'district_id',
        idValues: districtIds,
      })
      .pipe(map((res) => res.items as DWCohort[]));
  }

  fetchCohort(id: number): Observable<CohortDTO> {
    return this.apiService
      .getRequest(`cohort/${id}`)
      .pipe(
        map((res) => cohortDTOFromApiResponse(res.item as CohortAPIResponse))
      );
  }

  fetchCohortsSearch(
    districtId: number,
    searchTerm: string,
    pageSize = 12
  ): Observable<CohortListDTO | CohortDTO[]> {
    return this.apiService
      .getRequest(
        `cohort/search?search=${searchTerm}&pageSize=${pageSize}&districtId=${districtId}`
      )
      .pipe(
        map((res) =>
          pageSize !== 1000
            ? cohortListDTOFromApiResponse(res as CohortListAPIResponse)
            : res.items.map((c: CohortAPIResponse) =>
                cohortDTOFromApiResponse(c)
              )
        )
      );
  }

  createCohort(cohort: CreateCohortPayloadDTO): Observable<CohortDTO> {
    return this.apiService.postRequest('cohort', cohort).pipe(
      map((res) => cohortDTOFromApiResponse(res.item as CohortAPIResponse)),
      catchError((err) => {
        throw err;
      })
    );
  }

  addUsersToCohort(tag: string, usersIds: number[]) {
    const params = {
      tag,
      usersIds,
    };
    return this.apiService.putRequest('cohort/add-users', params).pipe(
      map((res) => res),
      catchError((err) => {
        throw err;
      })
    );
  }

  removeUsersFromCohort(tag: string, usersIds: number[]) {
    const params = {
      tag,
      usersIds,
    };
    return this.apiService.putRequest('cohort/remove-users', params).pipe(
      map((res) => res),
      catchError((err) => {
        throw err;
      })
    );
  }

  deleteCohort(cohortId: number) {
    return this.apiService.deleteRequest(`cohort/${cohortId}`).pipe(
      map((res) => res),
      catchError((err) => {
        throw err;
      })
    );
  }
}
