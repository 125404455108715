import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { SelfAssessmentRequirementDTO } from 'src/app/common/types/responses/responses';
import {
  EvidenceDTO,
  LevelDTO,
  OptionDTO,
} from '../../../dtos/attendee-rubric.dto';
import { FileDTO } from '../../../dtos/file.dto';
import { CompetenciesSelectService } from '../../../services/competencies/competencies-select.service';
import { EvidenceSelfAssessmentService } from '../../../services/smart-coach/evidence-self-assessment/evidence-self-assessment.service';
import {
  ModalComponent,
  ModalConfig,
} from '../../modals/modal/modal.component';

@Component({
  selector: 'app-evidence-self-assessment-modal',
  templateUrl: './evidence-self-assessment-modal.component.html',
  styleUrls: ['./evidence-self-assessment-modal.component.scss'],
})
export class EvidenceSelfAssessmentModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal')
  modal: ModalComponent;

  @Input() modalConfig: ModalConfig;

  @Input() competencyId: number;

  @Input() coachUser: UserDTO | undefined;

  @Input() evidence: EvidenceDTO;

  @Input() uploadedFiles: FileDTO[];

  @Input() level: number;

  @Input() levelData: LevelDTO;

  @Input() strandId: number;

  @Input() strandTitle: string;

  @Input() option: OptionDTO;

  @Input() coachlogId: number;

  @Input() rubricId: number;

  @Input() currentLogId: number;

  user: User;

  subs: Subscription[] = [];

  requirements: SelfAssessmentRequirementDTO[];

  constructor(
    private evidenceSelfAssessmentService: EvidenceSelfAssessmentService,
    private competenciesSelectService: CompetenciesSelectService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.subs.push(
      this.evidenceSelfAssessmentService
        .getRequirements(
          this.competencyId,
          this.strandId,
          this.level,
          this.user.id,
          this.option.id,
          this.option.fromCuratedOptionId || undefined
        )
        .subscribe((res) => {
          this.requirements = res;
        })
    );
    this.modalConfig = {
      ...this.modalConfig,
      options: {
        size: 'md',
      },
    };
    this.subs.push(
      this.competenciesSelectService
        .getCompetency(this.competencyId)
        .subscribe((competency) => {
          if (competency) {
            this.modal.config.titleText = competency.title;
          }
        })
    );
  }

  submitSelfAssessment() {
    const logId = this.currentLogId || this.coachlogId;
    if (this.requirements && this.requirements.length > 0) {
      this.evidenceSelfAssessmentService
        .submitSelfAssessment(
          logId,
          this.requirements,
          this.rubricId,
          this.level,
          this.option.id
        )
        .subscribe(() => {
          this.modal.close();
        });
    } else {
      this.modal.close();
    }
  }

  ngOnDestroy(): void {
    this.modal.close();
  }
}
