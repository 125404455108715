import { EnvironmentService } from 'src/app/common/services/environment/environment.service';

export function getIcon(icon: string): string {
  let iconUrl: string;
  if (icon) {
    iconUrl = EnvironmentService.apiUrl() + icon;
  } else {
    iconUrl = '/assets/competency_icons/assessment.svg';
  }
  return iconUrl;
}

export function getIconUrl(icon: string): string {
  return getIcon(icon);
}
