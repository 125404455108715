import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class ReportFileService {
  constructor(private apiService: APICoreService) {}

  getReportFile(jobId: string) {
    return this.apiService.getRequest(`reporting/show-report/${jobId}`);
  }
}
