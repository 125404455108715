import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { map, take } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { dateFromNgbDate } from 'src/app/common/utilities/date-helpers';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { SmartCoachEvidenceType } from 'src/app/private/shared/enums/smart-coach-evidence-type.enum';

import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CreateOptionPayload } from 'src/app/private/shared/types/payloads/coaching-log.payloads';
import {
  LevelDTO,
  OptionDTO,
  WillDTO,
} from '../../../../dtos/attendee-rubric.dto';
import { ResourceListItemDTO } from '../../../../dtos/resources.dto';
import { CoachingLogResourceService } from '../../../../services/coaching-log/coaching-log-resource.service';
import { CoachingLogService } from '../../../../services/coaching-log/coaching-log.service';
import { CoachingLogState } from '../../../../state/coaching-log/coaching-log.state';
import { ModalComponent } from '../../../modals/modal/modal.component';

@Component({
  selector: 'app-leveled-competency-strand-level-options',
  templateUrl: './leveled-competency-strand-level-options.component.html',
  styleUrls: ['./leveled-competency-strand-level-options.component.scss'],
})
export class LeveledCompetencyStrandLevelOptionComponent
  implements OnInit, AfterViewInit
{
  @Input() levelData: LevelDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() level: number;

  @Input() details: OptionDTO;

  @Input() strandId: number;

  @Input() competencyId: number;

  @Input() coachlogId: number;

  @Input() isDrawerCollapsed = true;

  @Input() canEdit = true;

  @Input() viewingPrevSession = false;

  currentSessionId: number;

  dueDate: number;

  resources: ResourceListItemDTO[] = [];

  showDatePicker = false;

  selectedDate: NgbDateStruct;

  will: WillDTO | null;

  will$ = select(CoachingLogState.getWill);

  willSaving = false;

  initialWillContent: string;

  optionSaving = false;

  optionData$ = select(CoachingLogState.getOption);

  initialOptionContent: string;

  sessionData$ = select(CoachingLogState.getSessionData);

  logIsSmart: boolean;

  smartCoachEvidenceTypes = SmartCoachEvidenceType;

  evidenceType: string;

  user: User;

  optionCopied = false;

  optionCopyText: string;

  @ViewChild('container') private container: ElementRef;

  @ViewChild('optionCopyModal') private optionCopyModal: ModalComponent;

  constructor(
    private coachingLogService: CoachingLogService,
    private coachlogResourceService: CoachingLogResourceService,
    private store: Store,
    private router: Router
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  toggleCollapse() {
    this.isDrawerCollapsed = !this.isDrawerCollapsed;
  }

  deleteOption() {
    if (this.details) {
      const { id } = this.details;
      this.container.nativeElement.style.height = '0px';
      this.coachingLogService.deleteOption(id);
    }
  }

  deleteWill() {
    if (this.will) {
      this.coachingLogService.deleteWill(this.will.id);
      this.evidenceType = this.smartCoachEvidenceTypes.LINK;
    }
  }

  updateOptionContent(content: string) {
    this.optionCopyText = content;
    this.updateOption({ ...this.details, content });
  }

  updateOption(option: OptionDTO) {
    if (option.content !== this.initialOptionContent) {
      this.optionSaving = true;
      this.coachingLogService.updateOption(option);
    }
  }

  updateWillContent(content: string) {
    if (this.will) {
      this.willSaving = true;
      this.coachingLogService.updateWill({
        ...this.will,
        content,
      });
    }
  }

  changeEvidenceType(evidenceType: string) {
    this.evidenceType = evidenceType;
    this.updateWillContent(evidenceType);
  }

  ngOnInit() {
    this.initialOptionContent = this.details.content;
    this.optionCopyText = this.details.content;
    this.currentSessionId = parseInt(this.router.url.split('/')[3]);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.optionData$
        .pipe(
          take(1),
          map((filterFn) => filterFn(this.details.id))
        )
        .subscribe((option) => {
          // set initial content from option data in case it was updated since the page loaded
          this.initialOptionContent = option.content;
        });
      this.optionData$
        .pipe(map((filterFn) => filterFn(this.details.id)))
        .subscribe((option) => {
          if (option) {
            this.details = option;
            option.resources.forEach((resource) => {
              if (!this.resources.includes(resource)) {
                this.resources.push(resource);
              }
            });
            this.resources.forEach((resource) => {
              if (!option.resources.includes(resource)) {
                this.resources = this.resources.filter(
                  (resourceCheck) => resource.id !== resourceCheck.id
                );
              }
            });
          }
          this.optionSaving = false;
        });
      this.will$
        .pipe(
          take(1),
          map((filterFn) =>
            filterFn(this.userId, this.details.id, this.coachlogId)
          )
        )
        .subscribe((will) => {
          // set initial content from will data in case it was updated since the page loaded
          if (will) {
            this.initialWillContent = will.content;
            this.evidenceType = will.content;
          }
        });
      this.will$
        .pipe(
          map((filterFn) =>
            filterFn(this.userId, this.details.id, this.coachlogId)
          )
        )
        .subscribe((will) => {
          if (will) {
            const date = new Date(will.dueDate * 1000);
            this.selectedDate = new NgbDate(
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate()
            );
            this.will = will;
          } else if (this.will) {
            this.will = null;
          }
          this.willSaving = false;
        });
      this.sessionData$
        .pipe(
          take(1),
          map((filterFn) => filterFn(this.coachlogId))
        )
        .subscribe((sessionData) => {
          // initialize default evidence type if log is MSC
          if (sessionData?.type) {
            this.logIsSmart = sessionData.type.isSmart;
            if (this.logIsSmart && !this.evidenceType) {
              this.evidenceType = this.smartCoachEvidenceTypes.LINK;
            }
          }
        });
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.coachlogId)))
        .subscribe((sessionData) => {
          if (sessionData?.type) {
            this.logIsSmart = sessionData.type.isSmart;
          } else {
            this.logIsSmart = false;
          }
        });
    }, 1);
  }

  resourceSearch() {
    this.coachlogResourceService.open({
      competencyId: this.competencyId,
      optionId: this.details.id,
      coachlogId: this.coachlogId,
      userId: this.userId,
      rubricId: this.rubricId,
      levelData: {
        number: this.level,
        description: this.levelData.indicators[0].content,
      },
    });
  }

  removeResource(resourceId: number) {
    this.coachingLogService.removeResourceFromOption(
      resourceId,
      this.details.id
    );
  }

  onDateSelect(event: NgbDateStruct): void {
    // 5 p.m. local time, selected date
    const selectedDate = dateFromNgbDate(event).setHours(17, 0, 0, 0);
    if (!this.will) {
      this.coachingLogService.createWill({
        optionId: this.details.id,
        dueDate: selectedDate / 1000,
        userId: this.userId,
      });
    } else {
      this.coachingLogService.updateWill({
        ...this.will,
        dueDate: selectedDate / 1000,
      });
    }

    this.showDatePicker = false;
  }

  copyOptionToCurrentSession() {
    if (this.currentSessionId && this.currentSessionId !== this.coachlogId) {
      const payload: CreateOptionPayload = {
        content: this.optionCopyText,
        groweCoachlogId: this.currentSessionId,
        groweStrandId: this.strandId,
        groweRubricId: this.rubricId,
        groweCompetencyId: this.competencyId,
        level: this.level,
      };
      this.coachingLogService.copyOption(
        this.currentSessionId,
        payload,
        this.resources
      );
      this.optionCopyModal.open();
      this.optionCopied = true;
    }
  }
}
