<div class="col-12 goal-slider">
  <div #track class="track">
    <div #backfill class="backfill">
      <div #fill class="fill rounded-start"></div>
      <div class="stops">
        <div (click)="updateStep(1)"></div>
        <div (click)="updateStep(2)"></div>
        <div (click)="updateStep(3)"></div>
        <div (click)="updateStep(4)"></div>
      </div>
    </div>
    <div class="avatar" #avatar>
      <span class="fs-sm">My Goal</span>
      <app-avatar
        [profile]="userData"
        [avatarSize]="'medium'"
        [profileLinkActive]="false"></app-avatar>
    </div>
  </div>
</div>
