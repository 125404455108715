<app-modal
  #rostersSchoolModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'Create New School',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="container">
    <div class="row">
      <div class="col d-flex justify-content-center align-items-center"></div>
    </div>
    <div class="row">
      <form (ngSubmit)="createSchool()" [formGroup]="schoolForm">
        <div class="row">
          <div class="col">
            <label for="school-name" class="form-label">School Name</label>
            <div class="input-group">
              <input
                formControlName="title"
                type="text"
                class="form-control"
                id="school-name"
                aria-label="School   Name"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['title'].errors
                }" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="address-1" class="form-label">Address 1</label>
            <div class="input-group">
              <input
                formControlName="address"
                type="text"
                class="form-control"
                id="address-1"
                aria-label="Address 1"
                [ngClass]="{
                  'is-invalid': formSubmitted && f['address'].errors
                }" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="address-2" class="form-label">Address 2</label>
            <div class="input-group">
              <input
                formControlName="address2"
                type="text"
                class="form-control"
                id="address-2"
                aria-label="Address 2" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="city" class="form-label">City</label>
            <div class="input-group">
              <input
                formControlName="city"
                type="text"
                class="form-control"
                id="city"
                aria-label="City" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="state" class="form-label">State</label>
            <div class="input-group">
              <input
                formControlName="state"
                type="text"
                class="form-control"
                id="state"
                aria-label="State" />
            </div>
          </div>
          <div class="col">
            <label for="zip" class="form-label">Zip Code</label>
            <div class="input-group">
              <input
                formControlName="zip"
                type="text"
                class="form-control"
                id="zip"
                aria-label="Zip" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="phone" class="form-label">Phone</label>
            <div class="input-group">
              <input
                formControlName="phone"
                type="text"
                class="form-control"
                id="phone"
                aria-label="Phone" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngIf="serverError">
    <ngb-alert [dismissible]="false" class="mt-3" [type]="'danger'">
      <strong>Error</strong> {{ serverError }}
    </ngb-alert>
  </ng-container>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="closeModal()">Cancel</button>
      <button
        [disabled]="isSubmitting"
        (click)="createSchool()"
        class="btn btn-primary">
        Create
      </button>
    </div>
  </div>
</app-modal>
