import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  CohortDTO,
  CohortListDTO,
  CreateCohortPayloadDTO,
} from 'src/app/common/dtos/cohort.dto';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { ModalComponent } from '../../shared/components/modals/modal/modal.component';
import { AlertService } from '../../shared/services/alert/alert.service';
import { CohortService } from '../../shared/services/cohort/cohort.service';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';

@Component({
  selector: 'app-cohorts-page',
  templateUrl: './cohorts-page.component.html',
  styleUrls: ['./cohorts-page.component.scss'],
})
export class CohortsPageComponent {
  districtCode: string;

  district: DistrictDTO;

  cohortList: CohortDTO[];

  selectedCohort: CohortDTO | null;

  searchTerm = '';

  listMeta: PaginationDTO;

  user: User;

  form: FormGroup;

  createFormSubmitted = false;

  loading = false;

  submitLoading = false;

  errorMessage: string;

  @ViewChild('newCohortModal')
  newCohortModal: ModalComponent;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private districtList: DistrictListService,
    private cohortService: CohortService,
    private formBuilder: FormBuilder,
    private store: Store,
    private alertService: AlertService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtList
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
            // get cohorts after getting district information
            this.getCohorts();
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictDTO;
            this.districtCode = this.district.districtCode;
          }
        }
      });
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
    });
  }

  getCohorts() {
    this.cohortService.fetchCohorts(1, this.district.id).subscribe((res) => {
      this.cohortList = res.items;
      this.listMeta = res._meta;
    });
  }

  searchCohorts() {
    this.cohortService
      .fetchCohortsSearch(this.district.id, this.searchTerm)
      .subscribe((res) => {
        const result = res as CohortListDTO;
        this.cohortList = result.items;
        this.listMeta = result._meta;
      });
  }

  paginationChange() {
    this.cohortService
      .fetchCohorts(this.listMeta.currentPage, this.district.id)
      .subscribe((res) => {
        this.cohortList = res.items;
      });
  }

  get f() {
    return this.form.controls;
  }

  resetForm() {
    this.createFormSubmitted = false;
    this.f['title'].reset();
    this.errorMessage = '';
  }

  didSelectCohort(cohort: CohortDTO) {
    this.selectedCohort = null;
    this.selectedCohort = cohort;
  }

  createCohort() {
    this.createFormSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    this.submitLoading = true;

    const createCohortPayload: CreateCohortPayloadDTO = {
      tag: this.f['title'].value,
      districtId: this.district.id,
    };

    this.cohortService.createCohort(createCohortPayload).subscribe({
      error: () => {
        this.submitLoading = false;
        this.errorMessage =
          'There was an issue with your request. Please try a different cohort name.';
      },
      next: (cohort) => {
        this.submitLoading = false;
        this.resetForm();
        this.getCohorts();
        this.newCohortModal.dismiss();
        this.didSelectCohort(cohort);
        this.alertService.showAlert('Cohort Created');
      },
    });
  }

  backToCohorts() {
    this.getCohorts();
    this.selectedCohort = null;
  }

  deleteCohort() {
    if (this.selectedCohort) {
      this.cohortService.deleteCohort(this.selectedCohort.id).subscribe({
        error: () => {
          this.errorMessage =
            'There was an issue with your request. Please try again.';
        },
        next: () => {
          this.alertService.showAlert('Cohort Deleted');
          this.getCohorts();
          this.selectedCohort = null;
        },
      });
    }
  }
}
