<div class="table-container">
  <table class="table">
    <thead>
      <tr class="align-middle">
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="name"
          class="sortable-header"
          style="min-width: 225px">
          School & Coachee
          <i class="bi bi-caret-down {{ sortIconTemp.name }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="jul"
          class="sortable-header"
          style="min-width: 75px">
          Jul
          <i class="bi bi-caret-down {{ sortIconTemp.jul }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="aug"
          class="sortable-header"
          style="min-width: 75px">
          Aug
          <i class="bi bi-caret-down {{ sortIconTemp.aug }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="sep"
          class="sortable-header"
          style="min-width: 75px">
          Sep
          <i class="bi bi-caret-down {{ sortIconTemp.sep }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="oct"
          class="sortable-header"
          style="min-width: 75px">
          Oct
          <i class="bi bi-caret-down {{ sortIconTemp.oct }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="nov"
          class="sortable-header"
          style="min-width: 75px">
          Nov
          <i class="bi bi-caret-down {{ sortIconTemp.nov }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="dec"
          class="sortable-header"
          style="min-width: 75px">
          Dec
          <i class="bi bi-caret-down {{ sortIconTemp.dec }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="jan"
          class="sortable-header"
          style="min-width: 75px">
          Jan
          <i class="bi bi-caret-down {{ sortIconTemp.jan }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="feb"
          class="sortable-header"
          style="min-width: 75px">
          Feb
          <i class="bi bi-caret-down {{ sortIconTemp.feb }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="mar"
          class="sortable-header"
          style="min-width: 75px">
          Mar
          <i class="bi bi-caret-down {{ sortIconTemp.mar }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="apr"
          class="sortable-header"
          style="min-width: 75px">
          Apr
          <i class="bi bi-caret-down {{ sortIconTemp.apr }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="may"
          class="sortable-header"
          style="min-width: 75px">
          May
          <i class="bi bi-caret-down {{ sortIconTemp.may }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="jun"
          class="sortable-header"
          style="min-width: 75px">
          Jun
          <i class="bi bi-caret-down {{ sortIconTemp.jun }}"></i>
        </th>
        <th
          scope="col"
          (sort)="onSort($event)"
          sortable="total"
          class="sortable-header"
          style="min-width: 85px">
          TOTAL
          <i class="bi bi-caret-down {{ sortIconTemp.total }}"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <ng-container
          *ngIf="tableData && tableData.length > 0; else noTableData">
          <ng-container *ngFor="let school of tableData">
            <tr class="school-totals align-middle">
              <td>{{ school.school }}</td>
              <td>{{ school.month1 }}</td>
              <td>{{ school.month2 }}</td>
              <td>{{ school.month3 }}</td>
              <td>{{ school.month4 }}</td>
              <td>{{ school.month5 }}</td>
              <td>{{ school.month6 }}</td>
              <td>{{ school.month7 }}</td>
              <td>{{ school.month8 }}</td>
              <td>{{ school.month9 }}</td>
              <td>{{ school.month10 }}</td>
              <td>{{ school.month11 }}</td>
              <td>{{ school.month12 }}</td>
              <td>{{ school.total }}</td>
            </tr>
            <tr *ngFor="let coachee of school.items" class="align-middle">
              <td>{{ coachee.user_name }}</td>
              <td>{{ coachee.month1 }}</td>
              <td>{{ coachee.month2 }}</td>
              <td>{{ coachee.month3 }}</td>
              <td>{{ coachee.month4 }}</td>
              <td>{{ coachee.month5 }}</td>
              <td>{{ coachee.month6 }}</td>
              <td>{{ coachee.month7 }}</td>
              <td>{{ coachee.month8 }}</td>
              <td>{{ coachee.month9 }}</td>
              <td>{{ coachee.month10 }}</td>
              <td>{{ coachee.month11 }}</td>
              <td>{{ coachee.month12 }}</td>
              <td>{{ coachee.total }}</td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <ng-template #loadingTemplate>
      <div class="loader-container">
        <app-loader [top]="1050"></app-loader>
      </div>
    </ng-template>
  </table>
  <ng-template #noTableData>
    <tr>
      <td></td>
      <td colspan="3"></td>
      <td colspan="4">
        <p class="text-center my-5">
          {{
            loadingError
              ? "There was an error loading the results."
              : "No Results Found."
          }}
        </p>
      </td>
    </tr>
  </ng-template>
</div>
<section>
  <div class="d-flex justify-content-center mt-4">
    <ngb-pagination
      *ngIf="listMeta && listMeta.totalCount > 15"
      [(page)]="listMeta.currentPage"
      [pageSize]="listMeta.perPage"
      [collectionSize]="listMeta.totalCount"
      [maxSize]="5"
      (pageChange)="paginationChange()"></ngb-pagination>
  </div>
</section>
