import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { CoacheeListService } from '../../services/coachee-list/coachee-list.service';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-assign-coachee',
  templateUrl: './assign-coachee.component.html',
  styleUrls: ['./assign-coachee.component.scss'],
})
export class AssignCoacheeComponent {
  @Output() readonly coacheeEvent = new EventEmitter<boolean>();

  @ViewChild('assignCoacheeModal')
  assignCoacheeModal: ModalComponent;

  currentUser: User | null;

  selectedUser: UserLiteDTO | null;

  submitting = false;

  constructor(
    private coacheeListService: CoacheeListService,
    private store: Store
  ) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
  }

  cancel() {
    this.selectedUser = null;
    this.assignCoacheeModal.close();
  }

  chooseUser(user: UserLiteDTO | null) {
    if (user) {
      this.selectedUser = user;
    }
  }

  onSubmit() {
    if (this.currentUser && this.selectedUser) {
      this.submitting = true;
      this.coacheeListService
        .assignCoachee(this.selectedUser.id, this.currentUser.id)
        .subscribe((res) => {
          if (res) {
            this.selectedUser = null;
            this.coacheeEvent.next(true);
            this.submitting = false;
            this.cancel();
          }
        });
    }
  }
}
