import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { snakeCaseToTitleCase } from 'src/app/common/utilities/display-helpers';
import {
  PhasesProgressAPIResponse,
  PlanDashboardItemAPIResponse,
} from '../../../types/responses/plan.responses';

@Component({
  selector: 'app-phase-progress-card',
  templateUrl: './phase-progress-card.component.html',
  styleUrl: './phase-progress-card.component.scss',
})
export class PhaseProgressCardComponent implements OnChanges {
  @Input() dashboardInfo: PlanDashboardItemAPIResponse;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboardInfo'] && this.dashboardInfo) {
      this.dashboardInfo.phases.forEach((phase, index) => {
        const currentPhase = this.dashboardInfo.phases[index];
        currentPhase.status = snakeCaseToTitleCase(phase.status);
        Object.keys(currentPhase.deliverables_progress).forEach((key) => {
          currentPhase.deliverables_progress[
            key as keyof PhasesProgressAPIResponse
          ] = Math.round(
            currentPhase.deliverables_progress[
              key as keyof PhasesProgressAPIResponse
            ] * 100
          );
        });
      });
    }
  }
}
