<div class="container">
  <app-general-header title="mySmartCoach Sessions" />
  <div class="card border-0">
    <div class="card-body p-3">
      <ng-container *ngIf="coachLogStatus.loaded; else loadingTemplate">
        <ng-container *ngIf="sessionData; else sessionErrorMessage">
          <ng-container *ngIf="hasPresentAttendee; else noAttendeeTemplate">
            <ng-container [ngSwitch]="currentScreen">
              <ng-container *ngSwitchCase="'selectCompetencies'">
                <div class="session-nav-bar">
                  <a
                    routerLink="/smart-coach/log/{{ sessionId }}/info"
                    class="text-decoration-none text-body-secondary">
                    < Back to Session Info
                  </a>
                </div>
                <h2 class="text-center fw-800">
                  {{ sessionData.title }}
                </h2>
                <div
                  class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                  <ng-template
                    [ngIf]="
                      coachingService.logPusherService.attendeeRosters[
                        sessionId
                      ].length > 0
                    ">
                    <div class="d-flex flex-row align-items-center">
                      <div class="me-3 text-body-secondary">Attendees</div>
                      <app-avatar
                        style="margin-left: -5px"
                        avatarSize="small"
                        *ngFor="let attendee of pusherAttendees"
                        [userId]="attendee.id"
                        [avatarData]="attendee.avatar"
                        [grayScale]="!attendee.samePage"
                        data-testid="attendee-avatar"></app-avatar>
                    </div>
                  </ng-template>
                  <div class="col text-end">
                    <button
                      (click)="navigateNextScreen()"
                      [disabled]="
                        !competencySelected || competencySelectionLoading
                      "
                      ngbTooltip="At least 1 competency must be selected."
                      [disableTooltip]="competencySelected"
                      style="pointer-events: auto"
                      class="btn btn-primary btn-next">
                      Let's Growe!
                      <i
                        class="bi {{
                          !competencySelectionLoading
                            ? 'bi-arrow-right'
                            : 'bi-arrow-clockwise spin'
                        }}"></i>
                    </button>
                  </div>
                </div>
                <app-select-competencies-screen
                  [canEdit]="Permissions.EDIT === logPermissions"
                  (hasCompetencySelected)="handleCompetencySelectOutput($event)"
                  [isSmart]="true"
                  (isLoading)="handleCompetencySelectLoading($event)"
                  [attendeeIds]="attendeeIds"
                  [logId]="sessionId" />
              </ng-container>
              <ng-container *ngSwitchCase="'groweCoaching'">
                <ng-container
                  *ngIf="
                    !rubricLoadingStatus && preAssessmentQuestionGroupsLoaded;
                    else loadingTemplate
                  ">
                  <div class="session-nav-bar">
                    <a
                      routerLink="/smart-coach/log/{{ sessionId }}/competencies"
                      class="text-decoration-none text-body-secondary">
                      < Back to Select Competencies
                    </a>
                  </div>
                  <h2 class="text-center session-title">
                    {{ sessionData.title }}
                  </h2>
                  <div
                    class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                    <ng-template
                      [ngIf]="
                        coachingService.logPusherService.attendeeRosters[
                          sessionId
                        ].length > 0
                      ">
                      <div class="d-flex flex-row align-items-center">
                        <div class="me-3 text-body-secondary">Attendees</div>
                        <app-avatar
                          style="margin-left: -5px"
                          avatarSize="small"
                          *ngFor="let attendee of pusherAttendees"
                          [userId]="attendee.id"
                          [avatarData]="attendee.avatar"
                          [grayScale]="!attendee.samePage"
                          data-testid="attendee-avatar"></app-avatar>
                      </div>
                    </ng-template>
                    <div class="col text-end">
                      <button
                        routerLink="/smart-coach/log/{{ sessionId }}/summary"
                        class="btn btn-primary btn-next">
                        Complete
                        <i class="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                  <label
                    class="visually-hidden"
                    [attr.for]="'log-top-attendee-select'"
                    >Select Attendees</label
                  >
                  <div class="text-center">
                    <div class="mb-3" style="max-width: 350px; margin: 0 auto">
                      <ng-container
                        *ngIf="
                          !loggedInAsAttendee &&
                          sessionData.attendees.length > 1
                        ">
                        <ng-select
                          [labelForId]="'log-top-attendee-select'"
                          [items]="attendeeUserDTOs"
                          [searchable]="false"
                          [clearable]="false"
                          [placeholder]="'Select Coachee'"
                          [(ngModel)]="selectedAttendee"
                          #select>
                          <ng-template ng-label-tmp let-item="item">
                            <span class="fw-800">Coachee:</span>
                            &nbsp;<app-users-name
                              [userProfile]="item.profile" />
                          </ng-template>
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                            let-search="term">
                            <app-users-name [userProfile]="item.profile" />
                          </ng-template>
                        </ng-select>
                      </ng-container>
                    </div>
                  </div>
                  <ng-container *ngFor="let attendeeRubric of rubricData">
                    <ng-container
                      *ngIf="
                        (!loggedInAsAttendee &&
                          selectedAttendee &&
                          selectedAttendee.id === attendeeRubric.userId) ||
                        attendeeRubric.userId == user?.id
                      ">
                      <ng-container
                        *ngFor="let rubric of attendeeRubric.rubrics">
                        <ng-container
                          *ngFor="
                            let attendeeCompetencyGroup of rubric.competencyGroups
                          ">
                          <ng-container
                            *ngFor="
                              let attendeeCompetency of attendeeCompetencyGroup.competencies
                            ">
                            <app-leveled-competency
                              [canEdit]="logPermissions"
                              [userId]="attendeeRubric.userId"
                              [competency]="attendeeCompetency"
                              [rubricId]="rubric.id"
                              [logId]="sessionId" />
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <app-pre-assessment-modal
                    *ngIf="
                      preAssessmentQuestionGroups.length > 0 && !allGoalsSet
                    "
                    [rubricData]="rubricData"
                    [user]="user"
                    [coachlogId]="sessionId"
                    [preAssessmentQuestionGroups]="preAssessmentQuestionGroups"
                    (goalIsSet)="triggerDialog()" />
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'sessionSummary'">
                <div class="session-nav-bar">
                  <a
                    routerLink="/smart-coach/log/{{ sessionId }}/growth"
                    class="text-decoration-none text-body-secondary">
                    < Back to Coaching
                  </a>
                </div>
                <h2 class="text-center session-title">
                  {{ sessionData.title }}
                </h2>
                <div
                  class="d-flex flex-wrap justify-content-between align-items-center mb-3">
                  <ng-template
                    [ngIf]="
                      coachingService.logPusherService.attendeeRosters[
                        sessionId
                      ].length > 0
                    ">
                    <div class="d-flex flex-row align-items-center">
                      <div class="me-3 text-body-secondary">Attendees</div>
                      <app-avatar
                        style="margin-left: -5px"
                        avatarSize="small"
                        *ngFor="let attendee of pusherAttendees"
                        [userId]="attendee.id"
                        [avatarData]="attendee.avatar"
                        [grayScale]="!attendee.samePage"
                        data-testid="attendee-avatar"></app-avatar>
                    </div>
                  </ng-template>
                </div>
                <app-smart-coach-summary-page
                  [session]="sessionData"
                  [rubricData]="rubricData"></app-smart-coach-summary-page>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #noAttendeeTemplate>
            <p>
              <ngb-alert [dismissible]="false">
                <strong>No Present Attendees</strong> At least 1 attendee must
                be marked present.
                <a
                  routerLink="/smart-coach/log/{{ sessionId }}/info"
                  class="text-decoration-none text-body-secondary"
                  ><br />
                  &lt; back
                </a>
              </ngb-alert>
            </p>
          </ng-template>
          <app-smart-coach-dialog
            [coachProfile]="sessionData.user.profile"></app-smart-coach-dialog>
        </ng-container>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="loader-container">
          <app-loader></app-loader>
        </div>
      </ng-template>
      <ng-template #sessionErrorMessage>
        <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
          <app-error-message [message]="coachLogStatus.errors[0]" />
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>

<app-smart-option-modal
  #smartOptionParentModal
  [(showModal)]="showingOptionModal"
  [logId]="sessionId"
  (showModalChange)="showingOptionModalChange($event)"
  (optionsAreAdded)="triggerOptionDialog()">
</app-smart-option-modal>

<app-badge-earned-modal></app-badge-earned-modal>
