export const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;
export const WEEK_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 7;
export const MONTH_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 30;

export const FIVE_MINUTES_IN_SECONDS = 60 * 5;
export const HOUR_IN_SECONDS = 60 * 60;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const WEEK_IN_SECONDS = DAY_IN_SECONDS * 7;
export const MONTH_IN_SECONDS = DAY_IN_SECONDS * 30;
