<div class="text-center w-75 mx-auto">
  <h3 class="fw-800">Coaching Plan</h3>
  <p>
    In our decade+ of experience, we've got coaching down to a science.
    Consistent, personalized coaching is what helps educators achieve outcomes
    efficiently. Experience 7 coaching sessions in a year for regular,
    consistent support as you apply what you learn. The road to consistency
    begins with a solid plan!
  </p>
  <p>
    Schedule your first mySmartCoach session below; you can map out all 7
    sessions for success. Allow 2 weeks between sessions to implement the ideas
    you've chosen. Happy growing!
  </p>
</div>

<!-- Desktop View -->
<div
  class="path"
  [ngStyle]="{
    'background-image':
      'url(/assets/mySmartCoach/path/path-' + currentStep + '.png)'
  }">
  <!-- Preload images to avoid flickering on change -->
  <img
    src="/assets/mySmartCoach/path/path-1.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-2.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-3.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-4.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-5.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-6.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-7.png"
    style="display: none"
    alt="" />
  <img
    src="/assets/mySmartCoach/path/path-8.png"
    style="display: none"
    alt="" />
  <!--  -->

  <div class="step step-0">
    <div
      class="step-bubble rounded-circle text-white d-flex align-items-center justify-content-center fw-800 fs-4 bg-primary"
      [ngStyle]="{ 'pointer-events': 'none' }">
      0
    </div>
  </div>
  <div class="step step-1">
    <div
      (click)="toggleStep(1)"
      class="step-bubble"
      aria-label="Step 1"
      ngbTooltip="{{ selectedDates[0] }}"
      [disableTooltip]="currentStep <= 1"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 1 ? 'bg-primary' : 'bg-tertiary'
      ]">
      1
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-1-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d1="ngbDatepicker"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
  <div class="step step-2">
    <div
      (click)="toggleStep(2)"
      class="step-bubble"
      aria-label="Step 2"
      ngbTooltip="{{ selectedDates[1] }}"
      [disableTooltip]="currentStep <= 2"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 2 ? 'bg-primary' : 'bg-tertiary'
      ]">
      2
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-2-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d2="ngbDatepicker"
        [minDate]="previousSessionDate"
        [(ngModel)]="nextSessionDate"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
  <div class="step step-3">
    <div
      (click)="toggleStep(3)"
      class="step-bubble"
      aria-label="Step 3"
      ngbTooltip="{{ selectedDates[2] }}"
      [disableTooltip]="currentStep <= 3"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 3 ? 'bg-primary' : 'bg-tertiary'
      ]">
      3
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-3-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d3="ngbDatepicker"
        [minDate]="previousSessionDate"
        [(ngModel)]="nextSessionDate"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
  <div class="step step-4">
    <div
      (click)="toggleStep(4)"
      class="step-bubble"
      aria-label="Step 4"
      ngbTooltip="{{ selectedDates[3] }}"
      [disableTooltip]="currentStep <= 4"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 4 ? 'bg-primary' : 'bg-tertiary'
      ]">
      4
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-4-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d4="ngbDatepicker"
        [minDate]="previousSessionDate"
        [(ngModel)]="nextSessionDate"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
  <div class="step step-5">
    <div
      (click)="toggleStep(5)"
      class="step-bubble"
      aria-label="Step 5"
      ngbTooltip="{{ selectedDates[4] }}"
      [disableTooltip]="currentStep <= 5"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 5 ? 'bg-primary' : 'bg-tertiary'
      ]">
      5
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-5-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d5="ngbDatepicker"
        [minDate]="previousSessionDate"
        [(ngModel)]="nextSessionDate"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
  <div class="step step-6">
    <div
      (click)="toggleStep(6)"
      class="step-bubble"
      aria-label="Step 6"
      ngbTooltip="{{ selectedDates[5] }}"
      [disableTooltip]="currentStep <= 6"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 6 ? 'bg-primary' : 'bg-tertiary'
      ]">
      6
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-6-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d6="ngbDatepicker"
        [minDate]="previousSessionDate"
        [(ngModel)]="nextSessionDate"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
  <div class="step step-7">
    <div
      (click)="toggleStep(7)"
      class="step-bubble"
      aria-label="Step 7"
      ngbTooltip="{{ selectedDates[6] }}"
      [disableTooltip]="currentStep <= 7"
      [ngClass]="[
        'rounded-circle',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-white',
        'fw-800',
        'fs-4',
        currentStep >= 7 ? 'bg-primary' : 'bg-tertiary'
      ]">
      7
    </div>
    <div class="datepicker-popup">
      <input
        class="visually-hidden mt-2"
        id="step-7-datepicker"
        aria-label="Enter date"
        ngbDatepicker
        #d7="ngbDatepicker"
        [minDate]="previousSessionDate"
        [(ngModel)]="nextSessionDate"
        (dateSelect)="onDateSelect($event, currentStep)"
        placement="bottom"
        [firstDayOfWeek]="0" />
    </div>
  </div>
</div>

<!-- Mobile View -->
<div class="progress-container">
  <div
    class="progress vertical"
    role="progressbar"
    aria-label="Basic example"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100">
    <div
      class="progress-bar bg-tertiary"
      [ngStyle]="{ width: 'calc((100% / 7) * ' + currentStep + ')' }"></div>
    <div class="step step-0">
      <div
        class="step-bubble rounded-circle text-white d-flex align-items-center justify-content-center fw-800 fs-4 bg-primary"
        [ngStyle]="{ 'pointer-events': 'none' }">
        0
      </div>
    </div>
    <div class="step step-1">
      <div
        class="step-bubble"
        aria-label="Step 1"
        ngbTooltip="{{ selectedDates[0] }}"
        [disableTooltip]="currentStep <= 1"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 1 ? 'bg-primary' : 'bg-tertiary'
        ]">
        1
      </div>
    </div>
    <div class="step step-2">
      <div
        class="step-bubble"
        aria-label="Step 2"
        ngbTooltip="{{ selectedDates[1] }}"
        [disableTooltip]="currentStep <= 2"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 2 ? 'bg-primary' : 'bg-tertiary'
        ]">
        2
      </div>
    </div>
    <div class="step step-3">
      <div
        class="step-bubble"
        aria-label="Step 3"
        ngbTooltip="{{ selectedDates[2] }}"
        [disableTooltip]="currentStep <= 3"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 3 ? 'bg-primary' : 'bg-tertiary'
        ]">
        3
      </div>
    </div>
    <div class="step step-4">
      <div
        class="step-bubble"
        aria-label="Step 4"
        ngbTooltip="{{ selectedDates[3] }}"
        [disableTooltip]="currentStep <= 4"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 4 ? 'bg-primary' : 'bg-tertiary'
        ]">
        4
      </div>
    </div>
    <div class="step step-5">
      <div
        class="step-bubble"
        aria-label="Step 5"
        ngbTooltip="{{ selectedDates[4] }}"
        [disableTooltip]="currentStep <= 5"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 5 ? 'bg-primary' : 'bg-tertiary'
        ]">
        5
      </div>
    </div>
    <div class="step step-6">
      <div
        class="step-bubble"
        aria-label="Step 6"
        ngbTooltip="{{ selectedDates[5] }}"
        [disableTooltip]="currentStep <= 6"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 6 ? 'bg-primary' : 'bg-tertiary'
        ]">
        6
      </div>
    </div>
    <div class="step step-7">
      <div
        class="step-bubble"
        aria-label="Step 7"
        ngbTooltip="{{ selectedDates[6] }}"
        [disableTooltip]="currentStep <= 7"
        [ngClass]="[
          'rounded-circle',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-white',
          'fw-800',
          'fs-4',
          currentStep >= 7 ? 'bg-primary' : 'bg-tertiary'
        ]">
        7
      </div>
    </div>
  </div>
  <div class="datepicker-popup">
    <ngb-datepicker
      #mobiledp
      [disabled]="currentStep > 7"
      [firstDayOfWeek]="0"
      [minDate]="previousSessionDate"
      [(ngModel)]="nextSessionDate"
      (dateSelect)="onDateSelect($event, currentStep)"></ngb-datepicker>
  </div>
</div>
