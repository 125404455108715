<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-8 text-center">
      <a href="/">
        <img
          id="groweLabLogo"
          src="assets/logo_black.png"
          class="my-4"
          width="240"
          alt="GroweLab Logo" />
      </a>
    </div>
  </div>
  <div class="row justify-content-center bg-light rounded pb-5">
    <div class="col-lg-8">
      <h1 class="my-5">GroweLab Data Sharing Agreement</h1>
      <p><em>Effective date: July 1, 2023</em></p>
      <p>
        These data privacy and usage terms (the “Data Sharing Agreement”) apply
        to all schools, school districts, and related organizations
        (collectively, the “School”) using any of Engage’s products or services,
        including Engage’s website and are incorporated into the agreement
        between School and Engage (“Agreement”).
      </p>
      <h2>Definitions</h2>
      <p>
        “PII” means any information and metadata that, alone or in combination,
        is linked or linkable to a specific person so as to allow a reasonable
        person in the Customer community who does not have knowledge of the
        relevant circumstances, to identify the person with reasonable
        certainty. Personally identifiable information will include, but is not
        limited to, at least the following: first and last name, the first and
        last name of the student’s parent or family member, telephone number,
        staff identifiers, student identifiers, photos, videos, home address,
        email address, social security numbers, financial account numbers,
        biometric identifiers, as well as other indirect identifiers such as
        date of birth or gender. “Privacy Laws” means all federal statutes that
        govern the privacy of student information, including: the Federal Family
        Educational Rights and Privacy Act (“FERPA”), 20 U.S.C. § 1232(g);
        Children’s Online Privacy Protection Act (“COPPA”), 15 U.S.C. 6501-6502;
        Protection of Pupil Rights Amendment (“PPRA”), 20 U.S.C. 1232; and
        applicable State laws governing the protection of Personally
        Identifiable Information.
      </p>
      <p>
        “Student Data” means any data, whether gathered by Engage or provided by
        Customer or its users, students, or students’ parents/guardians during
        the course of providing the Software and Services to the Customer, that
        is Personally Identifiable Information or descriptive of the student
        including, but not limited to, information that allows physical or
        online contact, grades, evaluations, disabilities, socioeconomic
        information, food purchases, voice recordings, or geolocation
        information. To the extent that U.S. law applies, Student Data may
        include “educational records” as defined in FERPA (20 U.S.C. § 1232(g)).
        Student Data will not include De-identified Data.
      </p>
      <h2>“Data” means PII and Student Data.</h2>
      <p>
        “De-identified data” means the data from which all Personally
        Identifiable Information, including direct and indirect identifiers, has
        been permanently removed or obscured so the remaining information does
        not reasonably identify an individual and there is no reasonable basis
        to believe that the information can be used to identify an individual.
      </p>
      <h2>Ownership and Control</h2>
      <p>
        Customer expressly grants, and represents and warrants that Customer has
        all rights necessary to grant, to Engage a limited, non-exclusive,
        royalty-free, worldwide license during the term of the Agreement to use,
        host, transmit, distribute, modify, reproduce, display, and store Data
        solely for the purposes of providing the Services and access to Software
        and for such purposes as otherwise described in the Agreement.
      </p>
      <p>
        In the limited circumstances in which Engage receives Student Data,
        Engage will access and process Student Data for the purposes of
        providing an outsourced institutional function pursuant to FERPA 34 CFR
        Part 99.31(a)(1). In providing its software and services to customers,
        Engage will be considered a “Customer Official” with a legitimate
        educational interest in the Student Data, under the direction and
        control of customer as it pertains to the use of Student Data. All
        Student Data collected, generated or received by the Engage pursuant to
        the agreement between Engage and its customer is and will continue to be
        the property of and under the control of the customer, or the party who
        provided such data (such as the student or parent/guardian). The Parties
        agree that as between them, all rights, including all intellectual
        property rights, in and to Student Data shall remain the exclusive
        property of the customer or the party who provided such Student Data
        (such as the student or parent/guardian).
      </p>
      <h2>Consents and Authority</h2>
      <p>
        Customer represents and warrants: (i) Customer has the authority to
        provide Data to Engage, and to allow Engage to access, collect, process,
        and otherwise use Data as set forth in the Agreement and for the purpose
        of providing Services and access to Software, and (ii) Customer has
        provided appropriate disclosures to, and received appropriate consents
        from, Customer’s end users regarding Customer’s sharing of Data with
        Engage and/or Engage’s access, collection, processing, and other use of
        the Data as set forth in the Agreement, to the extent such disclosures
        or consents are required by applicable law or by Customer’s agreements.
        With regard to Data that Customer permits Engage to collect or access
        pursuant to the Agreement, Engage agrees to uphold its responsibilities
        under the Privacy Laws applicable to Engage, and to support Customer in
        upholding Customer’s responsibilities under the Privacy Laws applicable
        to Customer.
      </p>
      <h2>Parent Access</h2>
      <p>
        Customer will establish reasonable procedures by which a parent, legal
        guardian or eligible student may review PII in the student’s educational
        records, and/or correct erroneous information. Engage will respond in a
        timely manner to Customer’s request to access PII held by Engage to
        access or correct, as necessary. If a parent or guardian of a student or
        other individual contacts Engage to access or correct any of the PII
        held by Engage, then Engage will refer the parent or individual to
        Customer, who will follow the necessary and proper procedures regarding
        the requested information, or otherwise comply with any other procedures
        and protocols designed to provide parent access to PII in the student’s
        educational records established in the Agreement.
      </p>
      <h2>Third-Party Service Provider Access</h2>
      <p>
        Customer acknowledges and agrees that Engage may permit access to Data
        to third-party subcontractors, service providers, and agents (each a
        “Service Provider”) that have a legitimate need to access such
        information in connection with their responsibilities in providing
        services to Engage. Engage will require all Service Providers involved
        in the handling, transmittal, and processing of Data to enter into
        written agreements to protect Data in a manner no less stringent than
        the terms of this Data Sharing Agreement. Engage will provide or make
        available a list of all such Service Providers to Customer upon request.
      </p>
      <h2>Third-Party Requests for Access</h2>
      <p>
        Should a third party, including law enforcement and government entities
        but excluding Service Providers, contact Engage with a request to access
        Data held by Engage pursuant to this Agreement, Engage will redirect the
        third party to request the Data directly from Customer, unless and to
        the extent that Engage reasonably believes it must grant such access to
        the third party because the disclosure is necessary: (i) pursuant to a
        court order or legal process, (ii) to comply with statutes or
        regulations, (iii) to enforce the Agreement, or (iv) if Engage believes
        in good faith that such disclosure is necessary to protect the rights,
        property or personal safety of Engage’s users, employees or others.
        Engage will notify the Customer in advance of a compelled disclosure to
        a third party unless legally prohibited.
      </p>
      <h2>Permitted Use of Data</h2>
      <p>
        Engage may collect, use, retain, and disclose the Data shared pursuant
        to the Agreement solely for the purposes of: (i) providing the Services
        or access to Software as contemplated by the Agreement, and as otherwise
        described herein, (ii) maintaining, supporting, evaluating, diagnosing,
        improving and developing the Services, Software and applications, (iii)
        enforcing its rights under the Agreement, and (iv) as otherwise
        authorized under the applicable Privacy Laws. For clarity and without
        limitation, Engage may use Student Data for adaptive learning purposes
        or customized student learning and to provide recommendation engines to
        recommend content or services relating to Customer purposes or other
        educational or employment purposes, provided such recommendation is not
        determined in whole or in part by payment or other consideration from a
        third party.
      </p>
      <h2>Restrictions on Disclosure of Data</h2>
      <p>
        Engage will not sell, disclose, transfer, share, or rent any Data
        obtained under the Agreement in a manner that could identify an
        individual student to any entity other than the Customer except: (i) to
        the extent set forth in the Agreement, (ii) as directed by Customer, or
        (iii) as otherwise described in this Data Sharing Agreement.
      </p>
      <h2>Restrictions on Use of Student Data for Advertising</h2>
      <p>
        Engage is prohibited from using Student Data to: (i) advertise or market
        to students or to direct targeted online advertising to students, (ii)
        advertise or market educational products and services to
        parents/guardians, unless with consent of the parent/guardian and/or
        Customer, (iii) develop a profile of a student, other than for the
        purpose of providing educational services or as authorized by Customer
        or by a parent/guardian, or (iv) for any other commercial purpose unless
        authorized by Customer or permitted by applicable law. Notwithstanding
        the foregoing, nothing in this section shall be read to prohibit Engage
        from: (a) directing online advertising to a student or family/guardian
        based on the student’s current visit to that online location, provided
        that the student’s online activities are not collected over time for the
        purpose of delivering such advertisements, (b) marketing educational
        products and services directly to parents, guardians or Customer’s
        employees so long as the marketing does not result from the use of
        Student Data obtained by Engage from providing the Services or access to
        Software, (c) using Student Data to recommend educational products or
        services to parents/guardians and Customer’s employees so long as the
        recommendations are not based in whole or in part by payment or other
        consideration from a third party, (d) using Student Data with
        parent/guardian consent to direct advertising to students to identify
        higher education or scholarship providers that are seeking students who
        meet specific criteria, (e) responding to a student or parent/guardian’s
        request for information or feedback, or (f) using aggregate information
        to inform, influence or enable marketing, advertising, or other
        commercial efforts by Engage.
      </p>
      <h2>Permitted Use of De-identified Data</h2>
      <p>
        Notwithstanding anything to the contrary herein, Engage may use and
        disclose De-identified Data for the purposes of the development and
        improvement of educational sites, services, applications, or to
        demonstrate the effectiveness of Engage’s products or services.
      </p>
      <h2>Student Data Deletion or Disposition</h2>
      <p>
        Customer may request in writing that Engage delete or retrieve Student
        Data in Engage’s possession at any time, which request Engage will then
        comply within a commercially reasonable period of time not to exceed 30
        days. Engage will continue to maintain a copy of Student Data subject to
        a retrieval request unless and until Engage receives a deletion request.
        Upon termination of the Agreement, Engage will automatically delete or
        destroy all Student Data in its possession within 60 days of the end of
        the term of the Agreement, except to the extent Customer submits a data
        retrieval and transfer request and the Parties transfer and delete
        Student Data according to a schedule and procedures as the Parties may
        reasonably agree upon. Engage is not authorized to maintain Student Data
        beyond the time reasonably needed to complete the disposition. The duty
        to dispose of Student Data will not extend to De-identified Data or data
        that has been placed in a student’s personal account pursuant to the
        terms of the Agreement.
      </p>
      <h2>Transfer to a Personal Account</h2>
      <p>
        Notwithstanding anything to the contrary in the foregoing, nothing will
        impede Engage’s ability to allow students to download, export, transfer,
        or otherwise save or maintain their own Student Data, student-created
        content and documents, consistent with the functionality of Engage’s
        products.
      </p>
      <h2>Conflict</h2>
      <p>
        To the extent there is any conflict between this Data Sharing Agreement
        and the Agreement, this Agreement shall control.
      </p>
      <h2>Amendment</h2>
      <p>
        This Data Sharing Agreement may be amended from time to time at Engage’s
        discretion upon twenty days advance written notice to School.
      </p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
