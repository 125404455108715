import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SmartCoachDTO } from '../../dtos/smartcoach.dto';
import { VideoModalComponent } from '../modals/video-modal/video-modal.component';

@Component({
  selector: 'app-intro-your-coach-option',
  templateUrl: './intro-your-coach-option.component.html',
  styleUrls: ['./intro-your-coach-option.component.scss'],
})
export class IntroYourCoachOptionComponent {
  @ViewChild('videoModal')
  videoModal: VideoModalComponent;

  @Input() coach: SmartCoachDTO;

  @Output() readonly toggleCoachEvent = new EventEmitter<SmartCoachDTO>();

  toggleCoach() {
    this.toggleCoachEvent.emit(this.coach);
  }

  openVideoModal() {
    this.videoModal.open();
  }
}
