<app-modal
  #sessTypeModal
  [modalConfig]="modalConfig"
  (isDismissing)="resetForm()">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="my-3 fs-14">
          Changing the session type will keep the title date, time, attendees,
          and attendance status. Please note that this action will result in the
          deletion of any data you have generated in the session, including GIs
          earned, options created, observation submissions, notes, etc.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="form-label">New Session Type</label>
        <app-log-type-select
          #logTypeSelect
          [omitMSC]="true"
          [clearAfterSelection]="false"
          (selectedLogType)="handleLogSelect($event)"></app-log-type-select>
        <ng-container *ngIf="this.selectedType">
          <div
            *ngIf="this.selectedType.id === this.currentSessionType.id"
            class="mt-3 px-3 color-danger fs-15">
            This is same type as current session. Please choose a new session
            type.
          </div>
          <div
            *ngIf="
              this.selectedType.id === 43 &&
              this.currentSession.attendees.length > 1
            "
            class="mt-3 px-3 color-danger fs-15">
            Observation and Feedback sessions can only have 1 attendee.
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex justify-content-between" footerContent>
    <button (click)="closeModal()" class="btn btn-reset">Cancel</button>
    <button
      (click)="changeSessionType()"
      class="btn btn-primary"
      [disabled]="
        !selectedType ||
        this.selectedType.id === this.currentSessionType.id ||
        (this.selectedType.id === 43 &&
          this.currentSession.attendees.length > 1)
      ">
      Yes, Change Type
    </button>
  </div>
</app-modal>
