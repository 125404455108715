<a
  routerLink="/coaching/log/{{ competency.egroweCoachlogId }}/growth"
  target="_blank"
  title="{{ competency.title }}">
  <div
    class="competency-wrapper d-flex flex-nowrap align-items-center my-3 flex-column flex-xl-row">
    <div class="badge-title d-flex flex-nowrap align-items-center me-3">
      <div class="icon-banner d-flex align-items-center justify-content-center">
        <img
          width="40"
          alt="{{ competency.title }} badge"
          class="icon"
          src="{{ apiUrl + competency.icon }}" />
      </div>
      <div class="title fw-200 ms-3">
        {{ competency.title }}
      </div>
    </div>

    <div class="strand-data">
      <ng-container *ngFor="let strand of competency.users[0].strands">
        <div class="mb-2">
          <div class="fs-12 fw-200">
            {{ strand.title }}
          </div>
          <div class="w-100">
            <app-current-competencies-progress-bar
              [competencyIcon]="apiUrl + competency.icon"
              [progress]="strand.level_completed"
              [highlightedBlockIndex]="strand.wills" />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</a>
