import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class EvidenceFileService extends FileService {
  evidenceId: number | null = null;

  setEvidenceId(evidenceId: number | null) {
    this.evidenceId = evidenceId;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  override uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    if (this.evidenceId === null) {
      return this.uploadUnattachedFile(formData);
    }
    return this.uploadAttachedFile(formData);
  }

  uploadUnattachedFile(formData: FormData) {
    return this.apiService.postFormRequest(
      'egrowe/evidence/attachment',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  uploadAttachedFile(formData: FormData) {
    return this.apiService.postFormRequest(
      `egrowe/evidence/${this.evidenceId}/attachment`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }
}
