import { Input, Component, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-quartile-progress-strand-overview',
  templateUrl: './quartile-progress-strand-overview.component.html',
  styleUrls: ['./quartile-progress-strand-overview.component.scss'],
})
export class QuartileProgressStrandOverviewComponent implements OnChanges {
  @Input() progress = 0;

  entireBars = 0;

  percentCoverage = 0.0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['progress']) {
      this.updateBarValues();
    }
  }

  updateBarValues() {
    this.entireBars = Math.floor(this.progress / 25);

    this.percentCoverage = parseFloat(
      (((this.progress % 25) / 25) * 100).toFixed(1)
    );
  }
}
