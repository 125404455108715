<ng-select
  class="status-dropdown"
  [clearable]="false"
  [searchable]="false"
  [items]="statusTypes"
  [(ngModel)]="status"
  (change)="statusSelected($event)"
  [ngClass]="{
    'status-not-started': status === StatusType.NOT_STARTED,
    'status-on-track': status === StatusType.ON_TRACK,
    'status-complete': status === StatusType.COMPLETE,
    'status-at-risk': status === StatusType.AT_RISK,
    'status-off-track': status === StatusType.OFF_TRACK,
    'status-canceled': status === StatusType.CANCELED,
    'view-only': viewOnly
  }"
  [disabled]="viewOnly"
  class="status-dropdown">
</ng-select>
