<app-modal
  #notificationsModal
  [modalConfig]="{
    titleText: 'Notifications',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div *ngIf="notifications" class="notification-content">
    <ngb-carousel
      [animation]="false"
      [interval]="0"
      [showNavigationIndicators]="false"
      [showNavigationArrows]="notifications.length > 1"
      (slide)="handleNextNotification($event)">
      <ng-template *ngFor="let notification of notifications" ngbSlide>
        <ng-container [ngSwitch]="notification.notification_type">
          <ng-container *ngSwitchCase="'celebration'">
            <ng-container *ngIf="notification.notificationDetails">
              <div
                class="icon mx-auto mb-3"
                [ngStyle]="{
                  'background-image': 'url(/assets/celebrations.svg)'
                }"></div>
              <div class="fw-800 fs-2 mb-3 text-center">
                You've Been Celebrated!
              </div>
              <div class="mb-3 competency-row">
                <div
                  *ngFor="
                    let competency of notification.notificationDetails
                      .competencies
                  ">
                  <div class="col text-center">
                    <ng-container *ngIf="competency.icon">
                      <div class="badge-container">
                        <div class="badge-graphic-wrapper">
                          <div
                            class="badge-wrapper d-flex justify-content-center align-items-center">
                            <div
                              class="image-wrapper"
                              [ngStyle]="{
                                'background-image':
                                  'url(' + apiUrl + competency.icon + ')'
                              }"></div>
                          </div>
                        </div>
                      </div>
                      <div class="competency-title">
                        {{ competency.title }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="celebration-content-row">
                <app-safehtml
                  [html]="notification.notificationDetails.content" />
              </div>
              <div class="d-flex align-items-center justify-content-end mb-3">
                <div class="avatar-dash me-2"></div>
                <app-avatar
                  avatarSize="small"
                  [profile]="
                    notification.notificationDetails.endorser.profile
                  " />
                <span class="ms-1"
                  >{{
                    notification.notificationDetails.endorser.profile.first_name
                  }}
                  {{
                    notification.notificationDetails.endorser.profile.last_name
                  }}</span
                >
              </div>
              <div class="w-100 d-flex justify-content-end">
                <button
                  (click)="
                    navigateToFullCelebration(
                      notification.notificationDetails.id
                    )
                  "
                  class="btn btn-primary">
                  View Full Celebration
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ngb-carousel>
  </div>
</app-modal>
