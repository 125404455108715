<app-modal
  #modal
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    titleText: 'Preview Form',
    customFooter: true,
    options: { size: 'xl' }
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="w-100" style="background-color: #fff; min-height: 500px">
    <app-form-view
      [inputDisabled]="true"
      [readOnly]="false"
      [showTitle]="true"
      *ngIf="form"
      [form]="form" />
  </div>
  <div footerContent>
    <button class="btn btn-primary" (click)="modal.close()">Done</button>
  </div>
</app-modal>
