<div
  *ngIf="cohort"
  class="cohort shadow d-flex flex-column justify-content-center p-2">
  <div class="cohort-title">
    <a
      href="javascript:void(0);"
      class="cohort-link"
      (click)="didClickCohort()"
      >{{ cohort.tag }}</a
    >
  </div>
  <div class="cohort-slug">
    <a
      href="javascript:void(0);"
      class="cohort-link"
      (click)="didClickCohort()"
      >{{ cohort.slug }}</a
    >
  </div>
  <div class="cohort-content">
    <a href="javascript:void(0);" class="cohort-link" (click)="didClickCohort()"
      ><i class="bi bi-people"></i>
      {{ cohort.userCount }}
      Cohort Members</a
    >
  </div>
</div>
