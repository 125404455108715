import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { buildParamsFromObject } from '../../utilities/api-helper';
import { EnvironmentService } from '../environment/environment.service';

const API_URL = EnvironmentService.apiUrl();

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root',
})
export class APICoreService {
  constructor(private http: HttpClient) {}

  getRequest(url: string, parameters?: any): Observable<any> {
    // set params
    // let getParams = new HttpParams({fromObject: parameters});
    // return this.http.get<any>(API_URL+'/'+url,{params:getParams});

    // using our own buildGetParams, because HttParams doesnt support objects with arrays in them
    const getParams = buildParamsFromObject(parameters);
    if (getParams.length > 0) {
      return this.http.get<any>(`${API_URL}/${url}?${getParams}`);
    }
    return this.http.get<any>(`${API_URL}/${url}`);
  }

  putRequest(url: string, parameters?: any): Observable<any> {
    // put parameters into body (with Formdata)
    let formData: FormData = new FormData();
    formData = this.appendFormdata(formData, parameters, '');
    return this.http.put<FormData>(`${API_URL}/${url}`, formData);
  }

  putJSONRequest(url: string, parameters?: any): Observable<any> {
    // put parameters into body as json
    return this.http.put<any>(`${API_URL}/${url}`, parameters);
  }

  putFormRequest(url: string, formdata: FormData): Observable<any> {
    return this.http.put<FormData>(`${API_URL}/${url}`, formdata);
  }

  postRequest(url: string, parameters?: any): Observable<any> {
    // post parameters into body (with Formdata)
    let formData: FormData = new FormData();
    formData = this.appendFormdata(formData, parameters, '');
    return this.http.post<FormData>(`${API_URL}/${url}`, formData);
  }

  postJSONRequest(url: string, parameters?: any): Observable<any> {
    // put parameters into body as json
    return this.http.post<any>(`${API_URL}/${url}`, parameters);
  }

  postFormRequest(
    url: string,
    formdata: FormData,
    options: any = {}
  ): Observable<any> {
    return this.http.post<FormData>(`${API_URL}/${url}`, formdata, options);
  }

  deleteRequest(url: string, parameters?: any): Observable<any> {
    // set params
    const delParams = new HttpParams({ fromObject: parameters });
    return this.http.delete<any>(`${API_URL}/${url}`, { params: delParams });
  }

  appendFormdata(formData: FormData, data: any, name = '') {
    if (typeof data === 'object' && data !== null) {
      if (data.length === 0) {
        formData.append(`${name}[]`, data);
      }
      Object.keys(data).forEach((key) => {
        if (name === '') {
          this.appendFormdata(formData, data[key], key);
        } else {
          this.appendFormdata(formData, data[key], `${name}[${key}]`);
        }
      });
    } else {
      formData.append(name, data);
    }
    return formData;
  }
}
