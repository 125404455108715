import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { ResourceOnlineCourseDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-online-course',
  templateUrl: './online-course.component.html',
  styleUrls: ['./online-course.component.scss'],
})
export class OnlineCourseComponent implements OnInit {
  @Input() course: ResourceOnlineCourseDTO;

  public API_URL = EnvironmentService.apiUrl();

  courseLink: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.course) {
      if (this.course.url) {
        this.courseLink = `${this.course.url}&courseId=vpl-${this.course.id}`;
      } else {
        this.courseLink = `${this.course.link}?accessKey=${this.course.access_key}&courseId=vpl-${this.course.id}`;
      }
      this.course.backgroundStyle = this.sanitizer.bypassSecurityTrustStyle(
        `url(${this.API_URL}${this.course.image})`
      );
    }
  }
}
