import {
  FormIndicatorDTO,
  FormIndicatorObservedDTO,
} from '../../dtos/forms.dto';

export const formObservedToIndicatorDTO = (
  observed: FormIndicatorObservedDTO
): FormIndicatorDTO => ({
  title: observed.title,
  level: observed.level,
  id: observed.id,
});
