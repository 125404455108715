import { DateTime } from 'luxon';
import { User } from 'src/app/common/state/user/user.model';
import {
  booleanFromBooleanNumber,
  BooleanNumber,
} from 'src/app/common/utilities/enums/boolean-number.enum';

import { districtDTOFromAPIResponse } from 'src/app/common/dtos/translators/district.translator';
import {
  Field,
  FieldDataFlexible,
  FieldDataResponse,
  FieldDataType,
  Form,
  FormStatus,
  FormSubmissionReportDTO,
  FormType,
  ObservationFormSubmission,
} from '../../dtos/forms.dto';
import { userDTOFromAPIResponse } from '../../helpers/translators/user.translators';
import { CoachingSessionDataAPIResponse } from './coaching-log.responses';
import { DistrictAPIResponse } from './district-list.responses';
import { UserAPIResponse } from './user.responses';

export interface LearnerBehaviorListApiResponse {
  items: {
    abbreviation: string | null;
    content: string;
    created_at: number;
    egrowe_standard_group_id: number;
    icon: string;
    order: number;
    rubricLearnerBehaviorStrands: {
      created_at: number;
      egrowe_indicator_set_id: number;
      egrowe_standard_id: number;
      egrowe_rubric_id: number;
      id: number;
      indicatorSet: IndicatorSetAPIResponse[];
    }[];
    title: string;
    updatedAt: number;
  }[];
}

export interface IndicatorSetAPIResponse {
  content: string | null;
  created_at: number;
  district_id: number | null;
  id: number;
  indicatorGroups: [
    {
      content: string;
      created_at: number;
      district_id: number | null;
      egrowe_indicator_set_id: number;
      id: number;
      indicators: [
        {
          egrowe_indicator_id: number;
          id: number;
          indicator: {
            content: string;
            id: number;
          };
          level: number;
        }
      ];
      is_deleted: BooleanNumber;
      order: number;
      title: string;
      updated_at: number;
    }
  ];
  is_deleted: BooleanNumber;
  level1_name: string;
  level2_name: string;
  level3_name: string;
  level4_name: string;
  title: string;
  updated_at: number;
}

export interface LearnerBehaviorAPIResponse {
  item: {
    created_at: number;
    egrowe_indicator_set_id: number;
    egrowe_standard_id: number;
    egrowe_rubric_id: number;
    id: number;
    indicatorSet: IndicatorSetAPIResponse;
    is_deleted: BooleanNumber;
    sample_questions: string | null;
    updated_at: number;
  };
}

export interface DistrictShareResponse {
  created_at: number;
  district_id: number;
  id: number;
  is_shared: BooleanNumber;
  observation_form_id: number;
  updated_at: number;
}

export interface FormResponse {
  title: string;
  district_id: number;
  description: string;
  fields: Field[];
  status: string;
  type: string;
  district: DistrictAPIResponse;
  districtsSharedWith?: DistrictShareResponse[];
  districtsExcludedFrom?: DistrictShareResponse[];
  deletedFields?: Field[];
  id: number;
  created_at: number;
  modified_at: number;
  user_id: number;
  is_deleted: BooleanNumber;
  is_template: BooleanNumber;
  user: User;
  hasSubmission: boolean;
  submissionCount?: number;
  lastSubmission: number | null;
}

export const translateDistrictShareResponse = (
  response: DistrictShareResponse
): number => response.district_id;

export const translateFormResponseToFormDTO = (
  response: FormResponse
): Form => {
  const status = response.status as FormStatus;
  return {
    createdAt: response.created_at,
    districtsSharedExclusion: response.districtsExcludedFrom?.map((d) =>
      translateDistrictShareResponse(d)
    ),
    districtsSharedWith: response.districtsSharedWith?.map((d) =>
      translateDistrictShareResponse(d)
    ),
    title: response.title,
    districtId: response.district_id,
    description: response.description,
    fields: response.fields,
    district: districtDTOFromAPIResponse(response.district),
    status:
      response.submissionCount && status === FormStatus.PUBLISHED
        ? FormStatus.COLLECTING
        : status,
    type: response.type as FormType,
    deletedFields: response.deletedFields ? response.deletedFields : undefined,
    id: response.id,
    isTemplate: booleanFromBooleanNumber(response.is_template),
    modifiedAt: response.modified_at,
    author: response.user,
    hasSubmission: response.hasSubmission,
    submissionCount: response.submissionCount,
    lastSubmission: response.lastSubmission,
  };
};

export interface ObservationFormSubmissionResponse {
  created_at: number;
  updated_at: number;
  egrowe_coachlog_id: string;
  id: number;
  is_deleted: BooleanNumber;
  observation_form_id: string;
  observed_user_id: string;
  submission_data: FieldDataResponse[];
  submitter_user: UserAPIResponse;
  submitter_district_id: string;
  submitter_user_id: string;
  submitterUser?: UserAPIResponse;
  observedUser?: UserAPIResponse;
  coachlog?: CoachingSessionDataAPIResponse;
}

export const fieldDataFactory = (unknownField: FieldDataResponse) => {
  if (Array.isArray(unknownField.value)) {
    if (typeof unknownField.value[0] === 'string') {
      unknownField.type = FieldDataType.STRING_ARRAY;
    } else {
      unknownField.type = FieldDataType.COMPETENCY;
    }
  } else {
    unknownField.type = FieldDataType.STRING;
  }
  return unknownField as FieldDataFlexible;
};

export const submissionDataTranslator = (
  inputData: FieldDataResponse[]
): FieldDataFlexible[] => inputData.map((data) => fieldDataFactory(data));

export const translateObservationFormSubmissionDTOFromResponse = (
  response: ObservationFormSubmissionResponse
): ObservationFormSubmission => ({
  id: response.id,
  formId: parseInt(response.observation_form_id),
  submitterUserId: parseInt(response.submitter_user_id),
  submitterDistrictId: parseInt(response.submitter_district_id),
  submissionData: submissionDataTranslator(response.submission_data),
  createdAt: response.created_at,
  modifiedAt: response.updated_at,
  coachingLogId: parseInt(response.egrowe_coachlog_id),
  observedUserId: parseInt(response.observed_user_id),
  observed: response.observedUser
    ? userDTOFromAPIResponse(response.observedUser)
    : undefined,
  submitter: response.submitterUser
    ? userDTOFromAPIResponse(response.submitterUser)
    : undefined,
  coachlog: response.coachlog || undefined,
});

export interface FormSubmissionCountResponse {
  items: {
    form_id: number;
    count: number;
  }[];
}

export interface FormSubmissionReportResponse {
  items: FormSubmissionReportResponseItem[];
}

export interface FormSubmissionReportResponseItem {
  user_id: number;
  user_name: string;
  observer_id: number;
  observer_name: string;
  log_id: number;
  form_id: number;
  role_id: number;
  district_id: number;
  school_id: number;
  school_year_id: number;
  month_of_school_year: number;
  submission_date: string;
  submission_id: number;
  items: string;
}

export const translateFormSubmissionResponseToDTO = (
  response: FormSubmissionReportResponseItem
): FormSubmissionReportDTO => ({
  userId: response.user_id,
  observerId: response.observer_id,
  userName: response.user_name,
  observerName: response.observer_name,
  logId: response.log_id,
  formId: response.form_id,
  districtId: response.district_id,
  schoolId: response.school_id,
  schoolYearId: response.school_year_id,
  submissionDate: DateTime.fromFormat(
    response.submission_date,
    'yyyy-LL-dd hh:mm:ss'
  ),
  id: response.submission_id,
  submissionData: submissionDataTranslator(JSON.parse(response.items)),
});
