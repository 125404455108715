<app-modal #internalModal [modalConfig]="modalConfig">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="my-3 fs-14">
          <div class="fs-18 mb-2">
            Do you want to uncheck this Growth Indicator?
          </div>
          By confirming this action, any earned badges for this Growth Indicator
          will be removed. When the Growth Indicator is checked in the future,
          the badges will be re-awarded
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 d-flex justify-content-between" footerContent>
    <button (click)="closeModal()" class="btn btn-reset">Cancel</button>
    <button (click)="continue()" class="btn btn-primary">
      Yes, remove growth
    </button>
  </div>
</app-modal>
