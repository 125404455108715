import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { AlertService } from '../../services/alert/alert.service';
import { SchoolSearchService } from '../../services/school-search/school-search.service';
import { SchoolService } from '../../services/school.service';
import { CreateSchoolPayload } from '../../types/payloads/rosters.payloads';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-roster-school-edit-modal',
  templateUrl: './roster-school-edit-modal.component.html',
  styleUrl: './roster-school-edit-modal.component.scss',
})
export class RosterSchoolEditModalComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() showModal = false;

  @Input() districtId: number;

  @Input() schoolId: number;

  @Output() readonly editSchoolEvent: EventEmitter<SchoolDTO> =
    new EventEmitter();

  @Output() readonly deleteSchoolEvent: EventEmitter<number> =
    new EventEmitter();

  @Output() readonly showModalChange = new EventEmitter();

  @ViewChild('schoolEditModal') schoolEditModal: ModalComponent;

  modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  schoolForm: FormGroup;

  school: SchoolDTO | null;

  isOpen = false;

  formSubmitted = false;

  isSubmitting = false;

  serverError = '';

  constructor(
    private formBuilder: FormBuilder,
    private schoolSearchService: SchoolSearchService,
    private schoolService: SchoolService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loadSchoolData(this.schoolId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schoolId']?.currentValue) {
      this.loadSchoolData(this.schoolId);
    }
    if (changes['showModal']?.currentValue) {
      this.modalShowing.next(true);
      this.loadSchoolData(this.schoolId);
    }
  }

  ngAfterViewInit(): void {
    this.schoolEditModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.schoolEditModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.schoolEditModal.close();
        this.isOpen = false;
      }
    });
  }

  get f() {
    return this.schoolForm.controls;
  }

  loadSchoolData(schoolId: number) {
    if (schoolId) {
      this.schoolSearchService.getSchool(schoolId).subscribe((response) => {
        this.school = response;
        if (this.school) {
          this.schoolForm = this.formBuilder.group({
            title: [this.school.title, Validators.required],
            address: [this.school.address],
            address2: [this.school.address2],
            city: [this.school.city],
            state: [this.school.state],
            zip: [this.school.zip],
            phone: [this.school.phone],
          });
        }
      });
    }
  }

  // eslint-disable-next-line consistent-return
  editSchool() {
    this.formSubmitted = true;
    if (this.schoolForm.invalid) {
      return false;
    }
    this.isSubmitting = true;
    const payload: CreateSchoolPayload = {
      title: this.f['title'].value,
      district_id: this.districtId,
      address: this.f['address'].value,
      address2: this.f['address2'].value,
      city: this.f['city'].value,
      state: this.f['state'].value,
      zip: this.f['zip'].value,
      phone: this.f['phone'].value,
    };
    this.schoolService.updateSchool(this.schoolId, payload).subscribe({
      error: (error) => {
        this.isSubmitting = false;
        this.serverError = error.error.message;
      },
      next: (response) => {
        this.editSchoolEvent.next(response.item);
        this.isSubmitting = false;
        this.closeModal();
        setTimeout(() => {
          this.alertService.showAlert('School Information Updated');
        }, 300);
      },
    });
  }

  closeModal() {
    this.schoolForm.reset();
    this.serverError = '';
    this.showModal = false;
    this.formSubmitted = false;
    this.showModalChange.emit(this.showModal);
    this.modalShowing.next(false);
  }

  deleteSchool(schoolId: number) {
    if (this.school?.counts.users) {
      this.serverError =
        'This school has existing users.  Users must be re-assigned before deletion.';
      return;
    }
    this.schoolService.deleteSchool(schoolId).subscribe(() => {
      this.deleteSchoolEvent.next(schoolId);
      this.closeModal();
      setTimeout(() => {
        this.alertService.showAlert('School Deleted');
      }, 300);
    });
  }
}
