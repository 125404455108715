import { CelebrationDTO } from './celebrations.dto';

export interface NotificationDTO {
  created_at: number;
  date_shown: number | null;
  id: number;
  notification_date: number;
  notification_message: string;
  notification_ref_id: number;
  notification_type: NotificationType;
  updated_at: number;
  user_id: number;
  // add more DTOs here as notification types expand
  notificationDetails?: CelebrationDTO;
  markedRead?: boolean;
}

// add more notification types here
export enum NotificationType {
  CELEBRATION = 'celebration',
}
