import {
  RubricDTO,
  CompetencyGroupDTO,
  CompetencyDTO,
  IndicatorSetDTO,
  StrandDTO,
  LevelDTO,
  EvidenceDTO,
  EvidenceAttachmentDTO,
  EvidenceAssessmentDTO,
  GoalDTO,
  OptionDTO,
  AssessmentDTO,
  PlannedEvidenceDTO,
  IndicatorDTO,
  WillDTO,
} from '../dtos/attendee-rubric.dto';

export class DecomposedRubric {
  coachlogId: number;

  rubrics: RubricDTO[] = [];

  competencyGroups: CompetencyGroupDTO[] = [];

  competencies: CompetencyDTO[] = [];

  indicatorSets: IndicatorSetDTO[] = [];

  strands: StrandDTO[] = [];

  indicators: IndicatorDTO[] = [];

  levels: LevelDTO[] = [];

  evidences: EvidenceDTO[] = [];

  evidenceAttachments: EvidenceAttachmentDTO[] = [];

  evidenceAssessments: EvidenceAssessmentDTO[] = [];

  goals: GoalDTO[] = [];

  options: OptionDTO[] = [];

  assessments: AssessmentDTO[] = [];

  plannedEvidences: PlannedEvidenceDTO[] = [];

  wills: WillDTO[] = [];
}
