<div class="d-flex justify-content-between">
  <div class="fw-800" style="font-size: 2rem">Search Resources</div>
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckDefault"
      [(ngModel)]="isRestricted"
      (ngModelChange)="updateRestriction()" />

    <label class="form-check-label" for="flexSwitchCheckDefault"
      >My Saved Resources</label
    >
  </div>
</div>

<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  [items]="resourceList$ | async"
  bindLabel="title"
  [addTag]="false"
  [hideSelected]="true"
  [trackByFn]="trackByFn"
  [minTermLength]="2"
  [loading]="isLoading"
  typeToSearchText="Please enter 2 or more characters"
  [typeahead]="searchInput$"
  [(ngModel)]="selectedResource"
  (change)="changedSelection($event)"
  [placeholder]="placeholder"
  [labelForId]="labelId">
</ng-select>
