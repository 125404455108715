<ng-container *ngIf="video">
  <div class="card my-2">
    <div class="position-relative cursor-pointer">
      <a href="javascript:void(0)" (click)="openVideoModal()">
        <img
          src="/assets/play-button.svg"
          alt="Play video"
          class="play-button" />
        <img src="{{ video.imagePath }}" alt="" class="card-img-top" />
        <span class="position-absolute bottom-0 end-0 bg-black text-white px-1">
          {{ video.length }}
        </span>
      </a>
    </div>
    <div class="card-body">
      <span placement="bottom" ngbTooltip="{{ video.title }}">
        <p (click)="openVideoModal()" class="fs-5 card-title">
          {{ video.title }}
        </p>
      </span>
      <p *ngIf="video.user" class="mt-2 mb-0 small">
        {{ video.user.profile.first_name }}
        {{ video.user.profile.last_name }}
      </p>
      <p class="text-body-secondary small">
        {{ video.createdAt | formatDistanceToNow }} ago
      </p>
      <button href="#" class="btn btn-primary w-100" (click)="openVideoModal()">
        Watch
      </button>
    </div>
  </div>
  <app-video-modal #videoModal [video]="video"></app-video-modal>
</ng-container>
