<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Form Submissions',
    options: { size: 'xl' },
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <div
      *ngIf="
        display === modalDisplays.SUBMISSION && formData && visibleSubmission
      ">
      <div class="d-flex">
        <div class="p-1">
          <button
            *ngIf="!isLoading"
            (click)="display = modalDisplays.LIST"
            ngbTooltip="View all submissions"
            class="btn btn-link p-1">
            <i class="bi bi-card-list"></i>
          </button>
        </div>
        <div class="p-1">
          <button
            *ngIf="submissionData.length > 1"
            (click)="goToSubmission('previous')"
            ngbTooltip="Previous submission"
            [disabled]="visibleSubmissionIdx === 0"
            class="btn btn-link p-1">
            <i class="bi bi-arrow-left-circle-fill"></i>
          </button>
          <button
            *ngIf="submissionData.length > 1"
            (click)="goToSubmission('next')"
            ngbTooltip="Next submission"
            [disabled]="visibleSubmissionIdx === totalSubmissions - 1"
            class="btn btn-link p-1">
            <i class="bi bi-arrow-right-circle-fill"></i>
          </button>
        </div>
        <div class="p-2">
          <strong>Date: </strong>{{ visibleSubmission.createdAt | formatDate }}
        </div>
        <div class="p-2">
          <strong>Observer: </strong
          >{{ visibleSubmission.submitter?.profile | fullNameFromProfile }}
        </div>
        <div class="p-2">
          <strong>User: </strong
          >{{ visibleSubmission.observed?.profile | fullNameFromProfile }}
        </div>
        <button
          *ngIf="visibleSubmission"
          (click)="showCoachingLog()"
          ngbTooltip="View associated coaching session"
          class="btn btn-link">
          <i class="bi bi-card-checklist me-1"></i>Coaching session
        </button>
      </div>
    </div>
  </div>

  <div class="w-100" style="background-color: #fff; min-height: 500px">
    <ng-container *ngIf="isLoading">
      <app-loader />
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <div
        *ngIf="
          display === modalDisplays.SUBMISSION &&
          formData &&
          visibleSubmission &&
          !visibleSubmission.coachlog?.is_private
        ">
        <app-form-view
          [inputDisabled]="true"
          [readOnly]="true"
          [showTitle]="true"
          [form]="formData"
          [submissionStatus]="submissionStatuses.SUBMITTED"
          [submissionData]="visibleSubmission"></app-form-view>
      </div>
      <div
        class="text-center p-5 m-5"
        *ngIf="
          display === modalDisplays.SUBMISSION &&
          formData &&
          visibleSubmission &&
          visibleSubmission.coachlog?.is_private
        ">
        This submission is from a private coaching session.
      </div>
      <div *ngIf="formData && display === modalDisplays.LIST">
        <div *ngIf="!submissionData">No data</div>
        <h2 class="fs-3 fw-bold">All Form Submissions</h2>
        <table class="table" *ngIf="submissionData">
          <thead>
            <tr>
              <th>View</th>
              <th>Submission Date</th>
              <th>Observer</th>
              <th>User</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let submission of submissionData">
              <td class="w-auto">
                <button
                  ngbTooltip="Preview form submission"
                  (click)="showSubmission(submission)"
                  class="btn btn-link color-tertiary p-0 mx-2">
                  <i class="bi bi-eye"></i>
                </button>
              </td>
              <td>
                {{ submission.createdAt | formatDate }}
              </td>
              <td>
                {{ submission.submitter?.profile | fullNameFromProfile }}
              </td>
              <td>
                {{ submission.observed?.profile | fullNameFromProfile }}
              </td>
            </tr>
          </tbody>
        </table>
        <section>
          <div class="d-flex justify-content-center">
            <ngb-pagination
              *ngIf="totalPages > 1"
              [(page)]="currentPage"
              [pageSize]="10"
              [collectionSize]="totalSubmissions"
              [maxSize]="5"
              (pageChange)="pageChange($event)"></ngb-pagination>
          </div>
        </section>
      </div>
    </ng-container>
  </div>
  <div footerContent>
    <button
      type="button"
      class="btn btn-md btn-primary"
      (click)="modal.close()">
      Done
    </button>
  </div>
</app-modal>
