import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';
import {
  AssignedCoacheesDTOResponse,
  CoacheeAPIResponse,
  translateCoacheeAPIResponseToDTO,
} from '../../types/responses/coaching-log.responses';

interface RequestParams {
  details: number;
  per_page?: number;
}

@Injectable({ providedIn: 'root' })
export class CoacheeListService {
  constructor(private apiService: APICoreService, private router: Router) {}

  fetchCoachees(
    requestOptions: RequestParams = { details: 1 }
  ): Observable<AssignedCoacheesDTOResponse | null> {
    return this.apiService
      .getRequest('growelab/coachees/assigned', requestOptions)
      .pipe(
        map((response) => {
          if (response?.items?.length > 0) {
            response.items = response.items.map((coachee: CoacheeAPIResponse) =>
              translateCoacheeAPIResponseToDTO(coachee)
            );
          }
          return response;
        })
      );
  }

  assignCoachee(userId: number, coachId: number) {
    const requestBody = {
      coach_id: coachId,
    };
    return this.apiService.postRequest(
      `egrowe/user/${userId}/coach`,
      requestBody
    );
  }
}
