import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { ChartType } from 'src/app/private/shared/enums/chart-type.enum';
import { ActivityFeedService } from 'src/app/private/shared/services/activity-feed/activity-feed.service';
import { getDistrictSchoolYearFromUser } from 'src/app/private/shared/services/school-year/school-year.utilities';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';

@Component({
  selector: 'app-leader-dashboard-page',
  templateUrl: './leader-dashboard.component.html',
  styleUrls: ['./leader-dashboard.component.scss'],
})
export class LeaderDashboardComponent {
  chartTypes = ChartType;

  user: User | null = null;

  activityFeedEnabled = false;

  schoolFilter: KpiReportFilter = {
    codename: 'school',
    title: 'School',
    value: [''],
    displayName: 'School',
  };

  strandFilter: KpiReportFilter = {
    codename: 'is_strand',
    value: ['1'],
  };

  microcredentialFilter: KpiReportFilter = {
    codename: 'is_competency',
    value: ['1'],
  };

  reportingYear: SchoolYearDTO;

  badgesEarnedParams: ReportRequestParams;

  celebrationParams: ReportRequestParams;

  coachingSessionsParams: ReportRequestParams;

  microcredentialsParams: ReportRequestParams;

  yearToDateSessionParams: ReportRequestParams;

  yearToDateObservationParams: ReportRequestParams;

  yearToDateStrandBadgeParams: ReportRequestParams;

  yearToDateMicrocredentialParams: ReportRequestParams;

  yearToDateGIParams: ReportRequestParams;

  yearToDateCelebrationParams: ReportRequestParams;

  yearToDateCampusParams: ReportRequestParams;

  peopleCoachedParams: ReportRequestParams;

  otherSupportSessionsParams: ReportRequestParams;

  constructor(
    private store: Store,
    private featureFlagService: UnleashService,
    private activityFeedService: ActivityFeedService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    this.activityFeedEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.activityFeed
    );

    if (this.user?.school && this.user.coacheeType === 'School Admin') {
      this.schoolFilter.value = [this.user.school.id.toString()];
    }
    this.reportingYear = getDistrictSchoolYearFromUser(this.user);

    this.peopleCoachedParams = {
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'user_id',
      },
      filters: [this.schoolFilter],
    };

    this.badgesEarnedParams = {
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: 'badge_id',
      },
      filters: [this.schoolFilter],
    };

    this.celebrationParams = {
      dataset: 'fct_user_celebration',
      measure: {
        type: 'count_distinct',
        field: 'user_id',
      },
      filters: [this.schoolFilter],
    };

    this.coachingSessionsParams = {
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'log_id',
      },
      filters: [this.schoolFilter],
    };

    this.yearToDateStrandBadgeParams = {
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [this.schoolFilter, this.strandFilter],
    };

    this.yearToDateMicrocredentialParams = {
      dataset: 'fct_user_badge',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [this.schoolFilter, this.microcredentialFilter],
    };

    this.yearToDateSessionParams = {
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [this.schoolFilter],
    };

    this.yearToDateObservationParams = {
      dataset: 'fct_form_submission',
      measure: {
        type: 'count',
        field: '',
      },
      filters: [this.schoolFilter],
    };

    this.yearToDateCampusParams = {
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count_distinct',
        field: 'school_id',
      },
      filters: [this.schoolFilter],
    };

    this.yearToDateGIParams = {
      dataset: 'fct_indicator_assessment',
      measure: {
        type: 'count',
        field: '',
      },
      filters: [
        { codename: 'assessment_type_id', value: ['9'] },
        this.schoolFilter,
      ],
    };

    this.yearToDateCelebrationParams = {
      dataset: 'fct_user_celebration',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [this.schoolFilter],
    };

    this.microcredentialsParams = {
      dataset: 'fct_user_badge',
      dimension: 'user_id',
      measure: {
        type: 'count_distinct_percent',
        field: 'badge_id',
      },
      filters: [
        this.schoolFilter,
        {
          codename: 'is_competency',
          value: ['1'],
        },
        {
          codename: 'school_year',
          value: [this.reportingYear?.id.toString()],
        },
      ],
    };

    this.otherSupportSessionsParams = {
      id: 'glance-other-support-sessions',
      dataset: 'fct_coaching_session',
      measure: {
        type: 'count',
        field: '*',
      },
      filters: [
        this.schoolFilter,
        {
          codename: 'is_coaching',
          value: ['0'],
        },
        {
          codename: 'is_observation',
          value: ['0'],
        },
        {
          codename: 'is_present',
          value: ['1'],
        },
      ],
    };
  }
}
