<div class="row justify-content-md-center">
  <div class="col-lg-8 text-center">
    <div class="text-center">
      <h3 class="fw-800">Let’s Start with Why</h3>
      <p>
        What drew you to the field of education? What motivates you when it gets
        tough? What inspires you? What do you believe about learners? What is
        your personal mission? Watch the video below to learn about others’ Why.
      </p>
    </div>
    <div *ngIf="whyVideo" id="videowrapper">
      <app-video-player [video]="whyVideo"></app-video-player>
    </div>

    <div class="text-center mt-5">
      <h3 class="fw-800">What is Your Why?</h3>

      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <textarea
            aria-label="Edit Your Why"
            #yourwhycontent="ngModel"
            name="yourwhycontent"
            id="yourwhycontent"
            class="form-control mb-5"
            appAutosave
            [(ngModel)]="initialWhy"
            (autosave)="saveUserProfileWhy()"
            [ngClass]="{
              'is-invalid':
                yourwhycontent.invalid &&
                (yourwhycontent.dirty || yourwhycontent.touched)
            }"
            rows="4"
            required></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
