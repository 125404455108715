import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDTO } from '../../dtos/file.dto';
import { UploadType } from '../../enums/upload-type.enum';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class LogFileService extends FileService {
  override uploadType = UploadType.LOG;

  logId: number | null = null;

  setLogId(id: number | null) {
    this.logId = id;
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  override uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFormRequest(
      `egrowe/coachlogs-v2/${this.logId}/attachment`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  override deleteFile(file: FileDTO) {
    const basedId = window.btoa(file.id.toString());
    return this.apiService.deleteRequest(
      `uploads/delete/egrowe_coachlog/${basedId}`
    );
  }
}
