import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { SmartOptionsDataAPIResponse } from 'src/app/common/types/responses/responses';

@Injectable({
  providedIn: 'root',
})
export class SmartSessionService {
  constructor(private apiService: APICoreService) {}

  subs: Subscription[] = [];

  // OPTION MODAL
  public smartlogId: number | null;

  private showOptionModalData = false;

  private showOptionModalSource = new BehaviorSubject(this.showOptionModalData);

  public showOptionModal = this.showOptionModalSource.asObservable();

  public addOptionStandardId: number | null;

  public addOptionStrandId: number | null = null;

  public addOptionUserId: number | null = null;

  public addOptionLevel = 1;

  public addOptionProcessingSource = new BehaviorSubject(false);

  public addOptionProcessing = this.addOptionProcessingSource.asObservable();

  startAddOption(
    standard_id: number,
    strand_id: number,
    level: number,
    logId: number,
    userId: number
  ) {
    // prep data for modal...
    this.addOptionLevel = level;
    this.smartlogId = logId;
    this.addOptionStandardId = standard_id;
    this.addOptionStrandId = strand_id;
    this.addOptionUserId = userId;
    this.showOptionModalSource.next(true);
  }

  hideAddOption() {
    this.showOptionModalSource.next(false);
  }

  getAddOptionsData(): Observable<SmartOptionsDataAPIResponse> {
    const getData = {
      standard_id: this.addOptionStandardId,
      strand_id: this.addOptionStrandId,
      level: this.addOptionLevel,
      user_id: this.addOptionUserId,
    };

    return this.apiService.getRequest(
      `egrowe/smartlog/${this.smartlogId}/add-option-data`,
      getData
    );
  }

  addOptions(optionIds: number[]) {
    const postData = { curatedOptionIds: optionIds };

    return this.apiService.postRequest(
      `egrowe/smartlog/${this.smartlogId}/options`,
      postData
    );
  }
}
