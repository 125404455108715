export enum FileIcon {
  pdf = 'bi-file-pdf',
  doc = 'bi-file-word',
  sheet = 'bi-file-spreadsheet',
  audio = 'bi-file-music',
  video = 'bi-file-play',
  ppt = 'bi-file-slides',
  img = 'bi-file-image',
  file = 'bi-richtext',
}

export const getFileIconValue = (icon: FileIcon): string =>
  Object.keys(FileIcon)[Object.values(FileIcon).indexOf(icon)];
