<div class="row justify-content-md-center">
  <div class="col-lg-8">
    <div class="text-center">
      <h3 id="welcome" class="fw-800">Welcome to mySmartCoach!</h3>
      <p class="mb-4">
        This engaging experience will provide you with valuable insights into
        our coaching process, helping you understand how it works and how it can
        benefit you. Watch this video to learn more about mySmartCoach.
      </p>
    </div>
    <div *ngIf="overviewVideo" id="videowrapper">
      <app-video-player
        [video]="overviewVideo"
        (click)="completeOverview()"></app-video-player>
    </div>

    <div class="text-center">
      <h3 class="fw-800 mt-5">About the Intro Session</h3>
      <p>
        During the Intro Session, we’ll establish the basics and you will have
        the opportunity to handpick your very own SmartCoach, who will be your
        trusted companion on your journey towards growth and achievement. Get
        ready to embark on a personalized and empowering coaching adventure that
        will guide your growth independently! On the upcoming screens, here’s
        what you can expect to experience:
      </p>
    </div>
    <div class="row justify-content-md-center mt-5">
      <div class="col-lg-10">
        <ul class="grey-dot-lg mb-5">
          <li>
            <span class="status-checkbox"
              ><i *ngIf="whyComplete" class="bi bi-check"></i></span
            >Define Your Why
          </li>
          <li>
            <span class="status-checkbox"
              ><i *ngIf="egroweComplete" class="bi bi-check"></i></span
            >Learn About the Growe Process
          </li>
          <li>
            <span class="status-checkbox"
              ><i *ngIf="bpComplete" class="bi bi-check"></i></span
            >Explore Competencies
          </li>
          <li>
            <span class="status-checkbox"
              ><i *ngIf="coachComplete" class="bi bi-check"></i></span
            >Choose Your Coach
          </li>
          <li>
            <span class="status-checkbox"
              ><i *ngIf="planComplete" class="bi bi-check"></i></span
            >Create a Coaching Plan
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
