import { Component, OnDestroy, OnInit } from '@angular/core';
import { tsParticles } from 'tsparticles-engine';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { Subscription } from 'rxjs';
import { ConfettiService } from '../../services/confetti/confetti.service';
import {
  confettiOptionsDefault,
  confettiOptionsSides,
  confettiOptionsBottom,
  confettiOptionsTop,
} from './confettiOptions';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
})
export class ConfettiComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  particlesVisible = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confettiOptions: any;

  constructor(private confettiService: ConfettiService) {}

  ngOnInit(): void {
    this.subs.push(
      this.confettiService.confettiType.subscribe((val) => {
        if (val === 'sides') {
          this.confettiOptions = confettiOptionsSides;
        } else if (val === 'bottom') {
          this.confettiOptions = confettiOptionsBottom;
        } else if (val === 'top') {
          this.confettiOptions = confettiOptionsTop;
        } else {
          this.confettiOptions = confettiOptionsDefault;
        }
      })
    );

    this.subs.push(
      this.confettiService.showConfettiNotifier.subscribe((value) => {
        this.particlesVisible = value;
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  particlesLoaded(): void {}

  // eslint-disable-next-line class-methods-use-this
  async particlesInit(): Promise<void> {
    await loadConfettiPreset(tsParticles);
    await tsParticles.load('tsparticles', this.confettiOptions);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
