import { UserRolesDTO } from './user-role';

export const MOCK_USER_ROLE_DTO_REPORTS_USER: UserRolesDTO = {
  name: 'reportsUser',
  type: 1,
  description: 'User can access Reports app',
  rule_name: null,
  data: null,
  created_at: 1570505431,
  updated_at: 1570505431,
};

export const MOCK_USER_ROLE_DTO_USER: UserRolesDTO = {
  name: 'user',
  type: 1,
  description: 'Authorized user',
  rule_name: null,
  data: null,
  created_at: 1527710072,
  updated_at: 1527710072,
};

export const MOCK_USER_ROLE_DTO_OPERATOR: UserRolesDTO = {
  name: 'operator',
  type: 1,
  description: 'User is an operator',
  rule_name: null,
  data: null,
  created_at: 1573189832,
  updated_at: 1663978203,
};

export const MOCK_USER_ROLE_DTO_SCHOOL_ADMIN: UserRolesDTO = {
  name: 'schoolAdmin',
  type: 1,
  description: 'User can admin their campus',
  rule_name: null,
  data: null,
  created_at: 1573189832,
  updated_at: 1663978203,
};

export const MOCK_USER_ROLE_DTO_DISTRICT_ADMIN: UserRolesDTO = {
  name: 'districtAdmin',
  type: 1,
  description: 'Districts admin users',
  rule_name: null,
  data: null,
  created_at: 1527025650,
  updated_at: 1527710248,
};

export const MOCK_USER_ROLE_DTO_SMART_COACH_USER: UserRolesDTO = {
  name: 'smartCoachUser',
  type: 1,
  description: 'Access to smart coach',
  rule_name: null,
  data: null,
  created_at: 1656260998,
  updated_at: 1656260998,
};
