<div class="datepicker">
  <div [ngClass]="[timepicker ? 'time' : '']">
    <div class="input-group datepicker-popup">
      <label for="dateSelect" class="visually-hidden">Select Date</label>
      <input
        labelForId="dateSelect"
        class="form-control rounded-start"
        placeholder="YYYY-MM-DD"
        ngbDatepicker
        #d="ngbDatepicker"
        [firstDayOfWeek]="0"
        [(ngModel)]="selectedDate"
        (change)="setDate()"
        (dateSelect)="setDate()"
        placement="bottom-end top-end"
        [minDate]="ngbMinDate"
        [maxDate]="ngbMaxDate"
        required />
      <button
        aria-label="Select date"
        class="btn color-tertiary btn-outline-secondary bi bi-calendar3"
        (click)="d.toggle()"
        type="button"></button>
    </div>
  </div>
  <div *ngIf="timepicker" class="row mt-1">
    <label for="timezoneSelect" class="visually-hidden">Select Timezone</label>
    <ng-select
      labelForId="timezoneSelect"
      [(ngModel)]="selectedTimezone"
      [items]="timezoneList"
      [clearable]="false"
      [searchable]="false"
      (change)="setDate()"
      bindLabel="display"
      bindValue="value">
    </ng-select>
  </div>
  <div
    *ngIf="timepicker"
    [ngClass]="[
      'd-flex',
      'flex-row',
      'mt-1',
      'flex-wrap',
      'flex-xxl-nowrap',
      'align-items-center',
      'time-row'
    ]">
    <label for="startTimeSelect" class="visually-hidden"
      >Select Start Time</label
    >
    <ng-select
      class="timeSelect flex-grow-1"
      labelForId="startTimeSelect"
      [(ngModel)]="selectedStartTime"
      [items]="availableTimes"
      (change)="setDate()"
      [clearable]="false"
      [searchable]="true"
      bindLabel="display"
      bindValue="value">
    </ng-select
    ><span class="d-block mx-2">-</span>
    <label for="endTimeSelect" class="visually-hidden">Select Start Time</label>
    <ng-select
      class="timeSelect flex-grow-1"
      labelForId="endTimeSelect"
      [(ngModel)]="selectedEndTime"
      [items]="availableTimes"
      (change)="setDate()"
      [clearable]="false"
      [searchable]="true"
      bindLabel="display"
      bindValue="value">
    </ng-select>
    <div *ngIf="duration">
      <span
        [ngClass]="[
          'd-block',
          'mx-xxl-2',
          'mx-0',
          'my-1',
          isTimeValid ? '' : 'color-danger'
        ]"
        >(Duration: {{ duration }})</span
      >
    </div>
  </div>
</div>
