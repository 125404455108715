<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  placeholder="{{ placeholder }}"
  bindLabel="title"
  [labelForId]="labelId"
  [clearable]="true"
  [items]="strands"
  [loading]="searchLoading"
  (change)="strandSelected($event)"
  [(ngModel)]="internalSelectedStrand"
  [disabled]="isDisabled">
</ng-select>
