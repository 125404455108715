import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { ReportService } from '../../shared/services/report/report.service';
import { FetchLastUpdatedDate } from '../../shared/state/reporting/reporting.actions';
import { ReportingState } from '../../shared/state/reporting/reporting.state';

const BASE_URL = EnvironmentService.apiUrl();

@Component({
  selector: 'app-reporting-support-insights',
  templateUrl: './reporting-support-insights.component.html',
})
export class ReportingSupportInsightsComponent implements OnInit, OnDestroy {
  @ViewChild('p') csvDownloadingPopover: NgbPopover;

  chartTypes = ChartType;

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictSimpleDto | null;

  canFilterByDistrict = false;

  defaultFilters: KpiReportFilter[];

  absenceCountParams: ReportRequestParams;

  sessionCountParams: ReportRequestParams;

  sessionDurationParams: ReportRequestParams;

  totalSessionsParams: ReportRequestParams;

  totalSessionsChartOptions: EChartsOption;

  filtersSet = false;

  disableDownload = false;

  noResults = false;

  _subs: Subscription[] = [];

  lastUpdatedDate: string;

  constructor(
    private store: Store,
    private reportService: ReportService,
    private reportFiltersService: ReportPageFiltersService
  ) {
    const updateDate$ = this.store.select(ReportingState.getUpdatedDate);
    this._subs.push(
      updateDate$.subscribe((date) => {
        this.lastUpdatedDate = date;
      })
    );
    const user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canFilterByDistrict = checkIfE2L(user) || checkIfB2b(user);
  }

  ngOnInit(): void {
    this._subs.push(
      this.reportFiltersService.filters.subscribe((filters) => {
        if (filters.schoolYearFilter) {
          this.filtersSet = false;
          this.defaultFilters = translateKpiReportFilterFromFilterDTO(filters);
          this.sessionDurationParams = {
            dimension: 'school_name',
            dataset: 'fct_coaching_session',
            measure: {
              type: 'sum',
              field: 'duration_hours',
            },
            filters: [
              ...this.defaultFilters,
              {
                codename: 'is_present',
                value: ['1'],
              },
            ],
          };
          this.sessionCountParams = {
            dimension: 'school_name',
            dataset: 'fct_coaching_session',
            group: 'is_present',
            measure: {
              type: 'count_distinct',
              field: 'log_id',
            },
            filters: [...this.defaultFilters],
          };

          this.setTotalSessionsData();

          setTimeout(() => {
            this.filtersSet = true;
          }, 0);
        }
      })
    );
  }

  setTotalSessionsData() {
    this.store.dispatch(new FetchLastUpdatedDate());
    this.totalSessionsParams = {
      dataset: 'fct_coaching_session',
      dimension: 'school_name',
      group: 'is_present',
      measure: {
        type: 'count_distinct',
        field: 'log_id',
      },
      filters: [...this.defaultFilters],
    };
    this.totalSessionsChartOptions = {
      grid: {
        left: '150px',
      },
      legend: {
        bottom: 0,
        icon: 'circle',
      },
      tooltip: {
        formatter: '<strong>{a} - {c}</strong>',
      },
      color: ['#27004B', '#D95195'],
      barWidth: 10,
    };
  }

  downloadCSV(): void {
    this.disableDownload = true;

    this.reportService
      .generateReportCSV('supportInsightsDownload', this.defaultFilters)
      .subscribe({
        error: () => {
          this.csvDownloadingPopover.close();
          this.noResults = true;
          this.disableDownload = false;
        },
        next: (res) => {
          if (res.has_results) {
            // res.file_name should always start with a slash, but need to be sure for the link to work properly
            if (!res.file_name.startsWith('/')) {
              res.file_name = `/${res.file_name}`;
            }
            window.open(`${BASE_URL}${res.file_name}`, '_blank');
          } else {
            this.noResults = true;
          }
          this.disableDownload = false;
        },
      });
  }

  closeErrorMsg() {
    this.noResults = false;
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
