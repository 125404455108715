<div
  class="strand-overview d-flex flex-row align-items-center"
  style="width: 100%">
  <div class="d-flex flex-lg-row" style="width: 100%; flex-wrap: wrap">
    <div
      *ngIf="entireBars != 0; else firstBarAlternate"
      class="progress-bar col-9 level-{{ entireBars }} ">
      <div
        class="progress-bar-block"
        *ngFor="let barNumber of [0, 1, 2, 3]; let i = index">
        <div
          class="progress-bar-fill"
          [style.width.%]="
            i < entireBars ? '100' : i === entireBars ? percentCoverage : '0'
          "></div>
      </div>
    </div>
    <ng-template #firstBarAlternate>
      <div class="progress-bar level-{{ entireBars }}" style="flex: 1">
        <div class="progress-bar-block">
          <div
            class="progress-bar-fill"
            style="height: 100%; background-color: #6fadcf"
            [style.width.%]="percentCoverage"></div>
        </div>
        <div class="progress-bar-block"></div>
        <div class="progress-bar-block"></div>
        <div class="progress-bar-block"></div>
      </div>
    </ng-template>
    <div class="col-2 d-flex mx-auto align-items-center justify-content-center">
      <p
        class="text-center"
        style="font-size: 2.2rem; font-weight: 700; margin: 0">
        {{ progress }}%
      </p>
    </div>
  </div>
</div>
