export const getPixelValueOfRem = (): number =>
  parseFloat(getComputedStyle(document.documentElement).fontSize);

export const convertRemToPixels = (rem: number): number =>
  rem * getPixelValueOfRem();

export const slugify = (text: string): string =>
  text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const snakeCaseToTitleCase = (text: string): string =>
  text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
