export interface TopicSearchParams {
  search?: string;
  page: number;
  sort_by: string;
  sort_by_desc: string | boolean;
  districts: number[];
}

export const emptyTopicSearchParams = (): TopicSearchParams => ({
  page: 1,
  sort_by: '',
  sort_by_desc: '',
  districts: [],
});

export interface TopicFilters {
  districts: number[];
}
