import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { LeveledCompetencyModalComponent } from '../../competency/leved-competency-modal/leveled-competency-modal.component';

@Component({
  selector: 'app-portfolio-competencies',
  templateUrl: './portfolio-competencies.component.html',
  styleUrls: ['./portfolio-competencies.component.scss'],
})
export class PortfolioCompetenciesComponent implements OnChanges {
  @Input() competencyIds: number[];

  @Input() userId: number;

  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  competencyIdsPaginated: number[][];

  page = 1;

  ngOnChanges() {
    this.loadCompetencies();
    this.page = 1;
  }

  loadCompetencies() {
    if (this.competencyIds) {
      this.competencyIdsPaginated = [];
      for (let i = 0; i < this.competencyIds.length; i += 12) {
        // Added setTimeout for when a user closes a competency modal and we want the portfolio competencies to update on close
        setTimeout(() => {
          this.competencyIdsPaginated.push(this.competencyIds.slice(i, i + 12));
        }, 0);
      }
    }
  }

  getFourLevelRubric(competencyId: number) {
    this.competencyModal.portfolio = true;
    this.competencyModal.competencyId = competencyId;
    this.competencyModal.userId = this.userId;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }
}
