<ng-container *ngIf="course">
  <div class="course-list-item">
    <div
      *ngIf="course.image"
      class="course-list-item__image"
      [style.backgroundImage]="course.backgroundStyle"></div>
    <div *ngIf="!course.image" class="course-list-item__image empty"></div>
    <h3
      class="course-list-item__title"
      [class.unpublished]="course.is_published == 0">
      <div *ngIf="course.is_published == 0" class="warning">
        <i class="glyph-eye-close"></i>UNPUBLISHED
      </div>
      <a
        style="text-decoration: none"
        target="_blank"
        href="{{ courseLink }}"
        >{{ course.title }}</a
      >
    </h3>
    <div
      *ngIf="course.description"
      class="course-list-item__description-wrapper">
      <div class="description small overflow-scroll">
        {{ course.description }}
      </div>
    </div>
    <div class="course-list-item__favorite-wrapper">
      <app-folder-bookmark
        [itemType]="'elearn_vpl_course'"
        [itemId]="course.id"
        [itemInAFolder]="course.isOnUsersShelf"></app-folder-bookmark>
    </div>
  </div>
</ng-container>
