import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { APICoreService } from '../../../../common/services/api-core/api-core.service';
import { FormSubmissionReportDictionary } from '../../dtos/forms.dto';
import {
  FormSubmissionReportResponseItem,
  translateFormSubmissionResponseToDTO,
} from '../../types/responses/forms.responses';

@Injectable({
  providedIn: 'root',
})
export class FormsReportService {
  constructor(private apiService: APICoreService) {}

  currentDistrict: number;

  formSubmissions: BehaviorSubject<[number, FormSubmissionReportDictionary]> =
    new BehaviorSubject<[number, FormSubmissionReportDictionary]>([0, {}]);

  setDistrictId = (districtId: number) => {
    this.currentDistrict = districtId;
  };

  loadSubmissions = (formId: number, districtId?: number) => {
    if (districtId) {
      this.setDistrictId(districtId);
    }
    const district = this.currentDistrict;

    this.apiService
      .getRequest('reporting/observation-forms', {
        filters: [
          {
            codename: 'district',
            value: this.currentDistrict.toString(),
          },
          {
            codename: 'form',
            value: [formId.toString()],
          },
        ],
        expansions: [
          {
            assoc: 'vw_observation_form_responses',
            on: ['submission_id', 'id'],
          },
          {
            dim: 'user',
          },
          {
            dim: 'user',
            on: ['observer_id', 'id'],
          },
        ],
        data: [
          't1.*',
          'j0.items',
          'j1.name as user_name',
          'j2.name as observer_name',
        ],
      })
      .subscribe((result) => {
        const submissions: FormSubmissionReportDictionary = {};
        if (result.items.length === 0) {
          submissions[formId.toString()] = [];
        } else {
          submissions[formId] = result.items.map(
            (item: FormSubmissionReportResponseItem) =>
              translateFormSubmissionResponseToDTO(item)
          );
        }
        this.formSubmissions.next([district, submissions]);
      });
  };
}
