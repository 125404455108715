<!-- DESKTOP NAV -->
<ul class="nav nav-tabs justify-content-center d-none d-md-flex">
  <li class="nav-item">
    <a
      class="nav-link"
      [class.active]="isRootResoucePage"
      title="Discover Resources"
      routerLink="/resources"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Discover Resources
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="My Resources"
      routerLink="/resources/my"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      My Resources
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Online Courses"
      routerLink="/resources/courses"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Online Courses
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      title="Video Library"
      routerLink="/resources/video-library"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      ariaCurrentWhenActive="page"
      aria-current="page">
      Video Library
    </a>
  </li>
</ul>
<!-- MOBILE NAV -->
<div
  class="custom-select d-md-none"
  [class.open]="customCollapsed"
  (click)="toggleCustomCollapsed()">
  <div class="custom-select__trigger">
    <div>{{ selectedPath }}</div>
    <i *ngIf="!customCollapsed" class="bi bi-caret-up-fill"></i>
    <i *ngIf="customCollapsed" class="bi bi-caret-down-fill"></i>
  </div>
  <ul class="custom-options">
    <li
      [routerLink]="'/resources'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Discover Resources">
      Discover Resources
    </li>
    <li
      [routerLink]="'/resources/my'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option">
      My Resources
    </li>
    <li
      [routerLink]="'/resources/courses'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Online Courses">
      Online Courses
    </li>
    <li
      [routerLink]="'/resources/video-library'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="custom-option"
      data-value="Video Library">
      Video Library
    </li>
  </ul>
</div>
