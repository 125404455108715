<div class="login">
  <div class="login__form">
    <main role="main" class="form-forgot-password w-100">
      <img
        id="groweLabLogo"
        src="assets/logo_horiz_dark.png"
        class="mb-4 w-100"
        alt="GroweLab Logo"
        @delayfadeInOut />
      <h1 class="h2 mb-0 fw-bold" @fadeInOut>Change Password Request</h1>
      <!-- <ng-container *ngIf="errorMessage">
          <div class="alert alert-danger text-start" role="alert">
            {{ errorMessage }}
          </div>
        </ng-container> -->
      <ng-container *ngIf="!successful; else successMessage">
        <div @fadeInOut>
          <p class="mb-4 mt-3 fs-17">
            Enter your email and we'll send you a link to change your password.
          </p>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="sendEmail()">
            <div class="input-group input-group-lg">
              <input
                data-testid="change-password-request-email-input"
                formControlName="email"
                class="form-control rounded-start"
                type="text"
                aria-label="email"
                placeholder="name@example.com"
                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                (keydown.enter)="sendEmail()" />
              <button
                data-testid="change-password-request-send-button"
                aria-label="Send email address"
                class="btn btn-primary"
                (click)="sendEmail()"
                type="submit"
                [disabled]="loading">
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm me-1"></span>
                Send
              </button>
            </div>
            <div *ngIf="submitted && f['email'].errors">
              <div
                *ngIf="f['email'].errors['required']"
                class="text-danger m-2">
                Email is required
              </div>
              <div *ngIf="f['email'].errors['pattern']" class="text-danger m-2">
                Email must be a valid email address
              </div>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-template #successMessage>
        <div
          data-testid="change-password-request-confirmation-message"
          @fadeInOut>
          <p class="fs-18 mb-4 mt-3">
            Password change requested. Please allow 5 minutes for the email to
            arrive.
          </p>
          <div class="my-4 d-flex justify-content-between">
            <a href="/login" class="text-link-color fw-bold"
              ><i class="bi bi-arrow-left-circle me-1"></i> Return to login</a
            >
            <a
              class="text-link-color fw-bold"
              href="https://e2l.atlassian.net/servicedesk/customer/portal/8"
              target="_blank"
              ><i
                role="img"
                aria-label="Help"
                class="bi bi-question-circle me-1"></i>
              Need assistance?</a
            >
          </div>
        </div>
      </ng-template>
    </main>
  </div>
</div>
