import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-has-option-select',
  templateUrl: './has-option-select.component.html',
  styleUrls: ['./has-option-select.component.scss'],
})
export class HasOptionSelectComponent implements OnInit {
  @Output() readonly emittedOption = new EventEmitter<'Yes' | 'No' | null>();

  @Input() defaultOption: boolean | null;

  selectedHasOption: 'Yes' | 'No' | null;

  labelId = uuidv4();

  ngOnInit(): void {
    if (this.defaultOption !== null && this.defaultOption !== undefined) {
      if (this.defaultOption) {
        this.selectedHasOption = 'Yes';
      } else if (!this.defaultOption) {
        this.selectedHasOption = 'No';
      }
    }
  }
}
