import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { SchoolDTO } from '../../../../common/dtos/school.dto';
import { SchoolSearchService } from '../../services/school-search/school-search.service';

@Component({
  selector: 'app-school-name',
  templateUrl: './school-name.component.html',
  styleUrls: ['./school-name.component.scss'],
})
export class SchoolNameComponent implements OnInit {
  @Input() schoolId = 0;

  @Input() includeDash = false;

  selectedSchool: SchoolDTO;

  constructor(private schoolSearchService: SchoolSearchService) {}

  ngOnInit(): void {
    if (this.schoolId) {
      this.schoolSearchService
        .getSchool(this.schoolId)
        .pipe(
          map((school) => {
            if (school) {
              this.selectedSchool = school;
            }
          })
        )
        .subscribe();
    }
  }
}
