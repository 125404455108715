import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Field, FieldType } from 'src/app/private/shared/dtos/forms.dto';
import { v4 as uuidv4 } from 'uuid';

@Component({
  template: '',
})
export abstract class AbstractViewFieldComponent {
  id = uuidv4();

  fieldTypes = FieldType;

  @Output() readonly value = new EventEmitter<string>();

  @Input() fieldDefinition: Field;

  @Input() inputDisabled = false;

  @Input() readOnly = false;
}
