import { CoachingSessionUserType } from 'src/app/private/shared/enums/coaching-session-user-type.enum';
import { CoachingSessionDTO } from '../../private/shared/dtos/coaching-session.dto';

export const checkIfCoach = (sessionData: CoachingSessionDTO) =>
  sessionData.currentUsersRole === CoachingSessionUserType.COACH ||
  sessionData.currentUsersRole === CoachingSessionUserType.EDITOR;

export const checkIfShadower = (sessionData: CoachingSessionDTO) =>
  sessionData.currentUsersRole === CoachingSessionUserType.SHADOWER;

export const checkIfCoachee = (sessionData: CoachingSessionDTO) =>
  sessionData.currentUsersRole === CoachingSessionUserType.COACHEE;

export const checkPresentAttendee = (sessionData: CoachingSessionDTO) =>
  sessionData.attendees.some((attendee) => attendee.present);
