import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  GIGrowthDataResponse,
  GIGrowthSchoolData,
  KpiReportFilter,
} from 'src/app/common/dtos/reports.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  SortEvent,
  SortableHeader,
} from '../../../directives/sortable-header.directive';
import { ReportPageFiltersService } from '../../../services/report/report-page-filters.service';
import { ReportService } from '../../../services/report/report.service';

interface GrowthSortIcon {
  name: 'gray' | 'none';
  jul: 'gray' | 'none';
  aug: 'gray' | 'none';
  sep: 'gray' | 'none';
  oct: 'gray' | 'none';
  nov: 'gray' | 'none';
  dec: 'gray' | 'none';
  jan: 'gray' | 'none';
  feb: 'gray' | 'none';
  mar: 'gray' | 'none';
  apr: 'gray' | 'none';
  may: 'gray' | 'none';
  jun: 'gray' | 'none';
  total: 'gray' | 'none';
}

@Component({
  selector: 'app-growth-table',
  templateUrl: './growth-table.component.html',
  styleUrls: ['./growth-table.component.scss'],
})
export class GrowthTableComponent implements OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  _sub: Subscription;

  isLoading = false;

  loadingError = false;

  params: KpiReportFilter[];

  sortByMonth = 0;

  sortByName = 0;

  sortByDesc = 1;

  column = '';

  direction = '';

  tableData: GIGrowthSchoolData[];

  listMeta: PaginationDTO;

  sortIconTemp: GrowthSortIcon = {
    name: 'gray',
    jul: 'gray',
    aug: 'gray',
    sep: 'gray',
    oct: 'gray',
    nov: 'gray',
    dec: 'gray',
    jan: 'gray',
    feb: 'gray',
    mar: 'gray',
    apr: 'gray',
    may: 'gray',
    jun: 'gray',
    total: 'gray',
  };

  constructor(
    private reportService: ReportService,
    private reportPageFiltersService: ReportPageFiltersService
  ) {
    this._sub = this.reportPageFiltersService.filters.subscribe((filters) => {
      if (filters.schoolYearFilter && filters.reportName === 'gi_growth') {
        const filterParams = translateKpiReportFilterFromFilterDTO(filters);
        if (
          !this.params ||
          JSON.stringify(this.params) !== JSON.stringify(filterParams)
        ) {
          this.params = filterParams;
          this.getGrowthData();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  getGrowthData(page?: number) {
    this.isLoading = true;
    this.loadingError = false;
    this.reportService
      .getReportData(
        this.params,
        'giGrowth',
        page,
        15,
        this.sortByMonth,
        this.sortByName,
        this.sortByDesc
      )
      .subscribe({
        next: (res: GIGrowthDataResponse) => {
          this.tableData = res.items;
          this.setTotals(this.tableData);
          this.listMeta = res._meta;
          this.isLoading = false;
        },
        error: () => {
          this.tableData = [];
          this.loadingError = true;
          this.isLoading = false;
        },
        complete: () => {},
      });
  }

  /* eslint-disable-next-line class-methods-use-this */
  setTotals(items: GIGrowthSchoolData[]) {
    items.forEach((school) => {
      school.total =
        school.month1 +
        school.month2 +
        school.month3 +
        school.month4 +
        school.month5 +
        school.month6 +
        school.month7 +
        school.month8 +
        school.month9 +
        school.month10 +
        school.month11 +
        school.month12;

      school.items.forEach((user) => {
        user.total =
          user.month1 +
          user.month2 +
          user.month3 +
          user.month4 +
          user.month5 +
          user.month6 +
          user.month7 +
          user.month8 +
          user.month9 +
          user.month10 +
          user.month11 +
          user.month12;
      });
    });
  }

  paginationChange() {
    this.getGrowthData(this.listMeta.currentPage);
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable as keyof GrowthSortIcon] = 'gray';
      } else if (header.direction === '') {
        this.sortIconTemp[header.sortable as keyof GrowthSortIcon] = 'gray';
      } else {
        this.sortIconTemp[header.sortable as keyof GrowthSortIcon] = 'none';
      }
    });

    if (column === 'name') {
      this.sortByName = 1;
      this.sortByMonth = 0;
    } else {
      this.sortByMonth = this.getMonthNumber(column);
      this.sortByName = 0;
    }

    this.sortByDesc = 0;
    if (direction === 'asc') {
      this.sortByDesc = 1;
    }
    if (direction === '') {
      this.sortByMonth = 0;
      this.sortByName = 0;
      this.sortByDesc = 1;
    }

    this.getGrowthData();
  }

  /* eslint-disable-next-line class-methods-use-this */
  getMonthNumber(column: string): number {
    switch (column) {
      case 'jul':
        return 1;
      case 'aug':
        return 2;
      case 'sep':
        return 3;
      case 'oct':
        return 4;
      case 'nov':
        return 5;
      case 'dec':
        return 6;
      case 'jan':
        return 7;
      case 'feb':
        return 8;
      case 'mar':
        return 9;
      case 'apr':
        return 10;
      case 'may':
        return 11;
      case 'jun':
        return 12;
      case 'total':
        return 0;
      default:
        return 0;
    }
  }
}
