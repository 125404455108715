/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowercase',
})
export class ConvertToLowercasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.replace(/[\s-]/g, '').toLowerCase();
  }
}
