import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { concat, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  ResourceFiltersDTO,
  ResourceListItemDTO,
} from '../../../dtos/resources.dto';
import { ResourcesSearchService } from '../../../services/resources/resources-search.service';

@Component({
  selector: 'app-select-resource',
  templateUrl: './select-resource.component.html',
  styleUrls: ['./select-resource.component.scss'],
})
export class SelectResourceComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  filters: ResourceFiltersDTO;

  resourceList$: Observable<ResourceListItemDTO[]>;

  isLoading = false;

  searchInput$ = new Subject<string>();

  selectedResource: ResourceListItemDTO | null = null;

  labelId = uuidv4();

  isRestricted: boolean;

  @Input() placeholder = '';

  @Input() clearAfterSelect = false;

  @Input() label = 'Search Resources';

  @Output() readonly afterSelection: EventEmitter<ResourceListItemDTO> =
    new EventEmitter<ResourceListItemDTO>();

  trackByFn = SelectResourceComponent.trackByFn;

  constructor(private resourceService: ResourcesSearchService) {
    this.filters = { search: '', limit_to_shelf: 0 };
  }

  ngOnInit() {
    this.loadResources();

    this.subs.push(
      this.resourceService.restricToShelf.subscribe((isRestricted) => {
        this.isRestricted = isRestricted;
      })
    );
  }

  public static trackByFn(item: ResourceListItemDTO) {
    return item.id;
  }

  private loadResources() {
    this.resourceList$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap((term) => {
          this.filters.search = term;
          this.resourceService.setFilters(this.filters);
          this.isLoading = true;
        }),
        switchMap(() =>
          this.resourceService.performSearch().pipe(
            catchError(() => of([])), // empty list on error
            tap((results) => {
              this.isLoading = false;
              return results;
            })
          )
        )
      )
    );
  }

  changedSelection(selectedResouce: ResourceListItemDTO) {
    this.afterSelection.emit(selectedResouce);
    if (this.clearAfterSelect) {
      setTimeout(() => {
        this.selectedResource = null;
      });
    }
  }

  updateRestriction() {
    this.resourceService.updateRestriction(this.isRestricted);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
