import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { User } from 'src/app/common/state/user/user.model';
import { v4 as uuidv4 } from 'uuid';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { SchoolSearchService } from '../../services/school-search/school-search.service';

@Component({
  selector: 'app-school-select',
  templateUrl: './school-select.component.html',
  styleUrls: ['./school-select.component.scss'],
})
export class SchoolSelectComponent implements OnChanges, OnInit {
  schoolList: SchoolDTO[];

  schoolLibrary: SchoolDTO[] = [];

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedSchool: SchoolDTO | null = null;

  internalSelectedSchoolList: SchoolDTO[] = [];

  internalDistrictIds: number[] = [];

  user: User;

  @ViewChild('select') select: NgSelectComponent;

  @Input() simpleSelect = false;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a school';

  @Input() placeholder = 'Search for a school...';

  @Input() isMulti = false;

  @Input() districtId: number | number[] | DistrictDTO[] | undefined;

  @Input() defaultSchools: number | number[] | SchoolDTO[] | null;

  @Input() assignedCoacheesSchoolsOnly = false;

  @Output() readonly selectedSchoolList: EventEmitter<SchoolDTO[]> =
    new EventEmitter();

  @Output() readonly selectedSchool: EventEmitter<SchoolDTO | null> =
    new EventEmitter();

  constructor(private schoolSearch: SchoolSearchService, private store: Store) {
    this.user = this.store.selectSnapshot((state) => state.user.user);
  }

  ngOnInit(): void {
    this.loadSchools();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'districtId':
          if (!changes[propName].firstChange) {
            this.reset();
            this.loadSchools(true);
          }
          break;
        default:
      }
    });
  }

  setDistrictIds(): void {
    this.internalDistrictIds = Array.isArray(this.districtId)
      ? getIdsFromDefaultItemsForSelect(this.districtId)
      : [this.user.district?.id];
  }

  loadSchools(outputAfter = false) {
    this.searchLoading = true;
    if (this.assignedCoacheesSchoolsOnly) {
      if (this.user) {
        this.schoolSearch
          .getAssignedSchools(this.user.id)
          .subscribe((schools) => {
            if (schools.length === 0 && this.user.school) {
              this.addSchools([this.user.school], outputAfter);
            } else {
              const assignedSchools = [this.user.school, ...schools];
              this.addSchools(assignedSchools, outputAfter);
            }
          });
      }
    } else {
      if (!this.districtId) {
        this.districtId = 0;
      }
      this.setDistrictIds();
      this.schoolSearch
        .getDistrictsSchools(this.internalDistrictIds)
        .subscribe((schools) => {
          this.addSchools(schools, outputAfter);
        });
    }
  }

  addSchools(schools: SchoolDTO[], outputAfter = false) {
    this.schoolLibrary = [...this.schoolLibrary, ...schools];
    this.schoolList = schools;
    this.schoolList.sort((a, b) => {
      const aText = a.title.toLowerCase();
      const bText = b.title.toLowerCase();
      if (aText > bText) {
        return 1;
      }
      if (bText > aText) {
        return -1;
      }
      return 0;
    });

    this.searchLoading = false;
    if (this.defaultSchools) {
      const schoolIds = getIdsFromDefaultItemsForSelect(this.defaultSchools);
      if (this.isMulti && this.defaultSchools) {
        schoolIds.forEach((schoolId) => {
          const selectedSchool = this.schoolLibrary.find(
            (school) => school.id === schoolId
          ) as SchoolDTO;
          if (
            selectedSchool &&
            this.internalDistrictIds.includes(selectedSchool.district_id) &&
            !this.internalSelectedSchoolList.includes(selectedSchool)
          ) {
            this.internalSelectedSchoolList.push(selectedSchool);
          }
        });
      } else {
        const selectedSchool = this.schoolLibrary.find(
          (school) => school.id === schoolIds[0]
        );
        if (
          selectedSchool &&
          this.internalDistrictIds.includes(selectedSchool.district_id)
        ) {
          this.internalSelectedSchool = selectedSchool;
        }
      }
    }
    if (outputAfter) {
      this.outputSchools();
    }
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedSchoolList = [];
    } else {
      this.internalSelectedSchool = null;
    }
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }

  schoolSelected(school: SchoolDTO) {
    if (this.isMulti && school) {
      if (!this.internalSelectedSchoolList.includes(school)) {
        this.internalSelectedSchoolList.push(school);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedSchool = school;
    }
    this.outputSchools();
    if (this.clearAfterSelection) {
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputSchools() {
    if (this.isMulti) {
      this.selectedSchoolList.emit(this.internalSelectedSchoolList);
    } else {
      this.selectedSchool.emit(this.internalSelectedSchool);
    }
  }

  removeSchoolFromList(school: SchoolDTO) {
    this.internalSelectedSchoolList = this.internalSelectedSchoolList.filter(
      (s) => s.id !== school.id
    );
    this.schoolList.push(school);
    this.schoolList.sort();
    this.outputSchools();
  }
}
