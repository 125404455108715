<app-modal #createEditCompetencyModal [modalConfig]="modalConfig">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="currentCompetency && currentIndicatorSet">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col">
              <div class="input-group">
                <input
                  [(ngModel)]="currentCompetency.title"
                  (change)="updateCompetency('title')"
                  placeholder="Title"
                  type="text"
                  class="form-control fs-22 fw-800"
                  id="competency-name"
                  aria-label="competency-name-input" />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <app-wysiwyg-editor
                version="custom"
                [editorContent]="currentCompetency.content"
                [customOptions]="customFroalaOptions"
                (editorContentEvent)="updateCompetency('content', $event)" />
            </div>
          </div>
        </div>
        <div
          class="col-12 col-lg-3 position-relative {{
            currentCompetency.icon ? 'align-self-center' : ''
          }}">
          <ng-container *ngIf="currentCompetency.icon">
            <div
              class="competency-icon"
              [ngStyle]="{
                'background-image': 'url(' + currentCompetency.icon + ')'
              }"></div>
            <div class="fw-800 text-center">Competency Icon</div>
          </ng-container>
          <app-file-upload
            #iconUploadComponent
            tooltipMessage="Upload competency icon"
            (outputFiles)="updateCompetencyIcon($event)"
            [style]="iconUploadStyle"
            [uploadType]="iconUploadType"
            size="lg"
            customMessageAddOn="Icon" />
        </div>
        <div
          class="col-12 col-lg-3 my-3 my-lg-0 position-relative {{
            microBadgeExists ? 'align-self-center' : ''
          }}">
          <ng-container
            *ngIf="
              currentIndicatorSet.standard.competencyBadge && microBadgeExists
            ">
            <div
              class="competency-icon"
              [ngStyle]="{
                'background-image':
                  'url(' +
                  currentIndicatorSet.standard.competencyBadge.image +
                  ')'
              }"></div>
            <div class="fw-800 text-center">Microcredential Badge</div>
          </ng-container>
          <app-file-upload
            #microUploadComponent
            tooltipMessage="Upload microcredential badge"
            [style]="microUploadStyle"
            [uploadType]="microUploadType"
            (outputFiles)="updateMicroBadge($event)"
            size="lg"
            customMessageAddOn="Microcredential Badge" />
        </div>
      </div>
    </div>
    <app-builder-leveled-competency
      [indicatorSet]="currentIndicatorSet"
      (deleteCompetencyEvent)="deleteStrand($event)"
      (badgeGraphicUploadedEvent)="loadIndicatorSetData()" />
    <div class="row mt-3">
      <div class="col text-end">
        <button
          [disabled]="addStrandLoading"
          (click)="addStrand()"
          class="btn btn-primary">
          <ng-container *ngIf="addStrandLoading; else addStrandButton">
            <div class="spinner-border spinner-border-sm" role="status"></div>
            Adding Strand
          </ng-container>
          <ng-template #addStrandButton>
            <i class="bi bi-plus text-white"></i> Add Strand
          </ng-template>
        </button>
      </div>
    </div>
  </ng-container>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button
        aria-label="delete-competency-button"
        class="btn btn-delete"
        [autoClose]="'outside'"
        [ngbPopover]="deletePopoverContent"
        [popoverTitle]="'Delete Competency'"
        [disabled]="deleteLoading"
        #popover="ngbPopover">
        <ng-container *ngIf="deleteLoading; else deleteButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
          Deleting
        </ng-container>
        <ng-template #deleteButton> Delete </ng-template>
      </button>
      <ng-template #deletePopoverContent>
        Permanently delete this competency?
        <div class="text-end mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="popover.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="popover.close(); deleteCompetency()">
            Yes
          </button>
        </div>
      </ng-template>
      <button
        type="submit"
        aria-label="save-competency-button"
        (click)="saveCompetency()"
        class="btn btn-md btn-primary">
        Done
      </button>
    </div>
  </div>
</app-modal>
