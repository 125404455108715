import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { FormsSaveService } from 'src/app/private/shared/services/forms/forms-save.service';

@Injectable({ providedIn: 'root' })
export class NavGuardService {
  previousPage: UrlSegment[];

  constructor(private formsSaveService: FormsSaveService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): boolean {
    if (
      this.previousPage?.length > 3 &&
      this.previousPage[0].path === 'observation' &&
      this.previousPage[1].path === 'log' &&
      this.previousPage[3].path === 'form'
    ) {
      const sessionId = parseInt(this.previousPage[2].path);
      if (!this.formsSaveService.canNavigateAway(sessionId)) {
        this.formsSaveService.addToNavigationQueue(sessionId, [
          next.url.map((s) => s.path),
          {},
        ]);
        this.formsSaveService.promptUnsavedModal.next(sessionId);
        return false;
      }
    }
    this.previousPage = next.url;
    return true;
  }
}

export const NavGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => inject(NavGuardService).canActivate(next, state);
