<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Topic Filters',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="row gy-3">
    <div class="col-md-12 gx-5">
      <label class="form-label">District:</label>
      <app-district-select
        #districtSelect
        [isMulti]="true"
        (selectedDistrictList)="updateDistricts($event)"
        [clearAfterSelection]="false"
        [defaultDistricts]="this.currentTopicFilters.districts"
        [simpleSelect]="true" />
    </div>
  </div>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="clearFilters()">
        Clear All
      </button>
      <button
        type="button"
        class="btn btn-md btn-primary"
        (click)="applyFilters()">
        Apply
      </button>
    </div>
  </div>
</app-modal>
