<app-modal
  #competencyPreviewModal
  [modalConfig]="{
    titleText: 'Preview Competency',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    options: {
      size: 'xl',
    },
    showFooter: false,
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div>
    <ng-container *ngIf="competency && competencyData; else loading">
      <app-preview-leveled-competency
        [competency]="competency"
        [rubricId]="competencyData.rubricId" />
    </ng-container>
    <ng-template #loading>
      <div class="w-100" style="background-color: #fff; height: 700px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
</app-modal>
