import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  dateFromUnixTimestamp,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { FileDTO } from '../../../dtos/file.dto';
import { UploadType } from '../../../enums/upload-type.enum';
import { AlertService } from '../../../services/alert/alert.service';
import { PlanDeliverableFileService } from '../../../services/file/plan-deliverable.file.service';
import { PlansPusherService } from '../../../services/plans/plans-pusher.service';
import { PlansService } from '../../../services/plans/plans.service';
import { PusherAssignmentChangesDTO } from '../../../services/plans/plans.service.dto';
import {
  AddAssignment,
  DeleteAssignment,
  DeleteDeliverable,
  EditDeliverable,
} from '../../../state/implementation-plan/implementation-plan.actions';
import { EditDeliverablePayload } from '../../../types/payloads/plan.payloads';
import {
  DeliverableAPIResponse,
  StatusType,
} from '../../../types/responses/plan.responses';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../../datepicker/datepicker.component';
import { FileListComponent } from '../../file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from '../../file-management/file-upload/file-upload.component';
import { ModalComponent } from '../../modals/modal/modal.component';
import {
  WysiwygEditorComponent,
  WysiwygEditorConfig,
} from '../../wysiwyg-editor/wysiwyg-editor.component';
import { CommentModelDTO } from '../plans-comments/plans-comments.dto';

@Component({
  selector: 'app-edit-deliverable-modal',
  templateUrl: './edit-deliverable-modal.component.html',
  styleUrl: './edit-deliverable-modal.component.scss',
})
export class EditDeliverableModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('editDeliverableModal')
  editDeliverableModal: ModalComponent;

  @ViewChild('startDatePicker') startDatePicker: DatepickerComponent;

  @ViewChild('dueDatePicker') dueDatePicker: DatepickerComponent;

  @ViewChild('wysiwyg') public editor: WysiwygEditorComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @Input() deliverable: DeliverableAPIResponse;

  @Input() phaseId: number;

  @Input() districtId: number;

  user: User;

  isE2L = false;

  subs: Subscription[] = [];

  form: FormGroup;

  formSubmitted = false;

  selectedUserIds: number[] = [];

  editDeliverablePayload: EditDeliverablePayload;

  defaultStartDate: number;

  defaultDueDate: number;

  isLoading = false;

  editorConfig: WysiwygEditorConfig;

  uploadStyle = UploadStyles.SIMPLE;

  uploadType = UploadType.DELIVERABLE_ATTACHMENT;

  attachments: FileDTO[] = [];

  activeSection: 'details' | 'content' = 'details';

  commentRequest: Subscription;

  commentList: CommentModelDTO[];

  updatedContent = '';

  selectedStatus: StatusType;

  assignees: UserLiteDTO[] = [];

  constructor(
    private plansService: PlansService,
    private plansPusherService: PlansPusherService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private planDeliverableFileService: PlanDeliverableFileService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
  }

  ngOnInit(): void {
    this.plansPusherService.deliverableCommentNotifier.subscribe(() => {
      this.getComments();
    });

    this.plansPusherService.deliverableAttachmentNotifier.subscribe(() => {
      this.getAttachments();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deliverable']) {
      if (
        changes['deliverable'].currentValue !==
        changes['deliverable'].previousValue
      ) {
        if (this.deliverable.content === undefined) {
          this.editorConfig = {
            initialContent: (this.deliverable.content = ''),
            editId: '',
          };
        } else {
          this.editorConfig = {
            initialContent:
              this.deliverable.content === null ? '' : this.deliverable.content,
            editId: '',
          };
        }
        this.updateNotes(
          this.deliverable.content === null ? '' : this.deliverable.content
        );
        this.getComments();
        this.getAttachments();
        this.loadDeliverableData();
      }
    }
  }

  get f() {
    return this.form.controls;
  }

  loadDeliverableData() {
    if (this.deliverable) {
      this.planDeliverableFileService.setDeliverableId(this.deliverable.id);
      this.selectedStatus = this.setLocalStatus(this.deliverable.status);
      this.setInitialDates(
        this.deliverable.start_date,
        this.deliverable.due_date
      );
      this.updatedContent = this.deliverable.content;
      this.assignees = [];
      this.deliverable.assignments.forEach((assignment) => {
        // Check if the user is already in the assignees array
        if (
          !this.assignees.some((assignee) => assignee.id === assignment.user.id)
        ) {
          this.assignees.push(assignment.user);
        }
      });

      this.form = this.formBuilder.group({
        title: [this.deliverable.title, Validators.required],
        description: [this.deliverable.description],
        department: [this.deliverable.department],
        status: [this.deliverable.status],
        startDate: [dateFromUnixTimestamp(this.defaultStartDate * 1000)],
        dueDate: [dateFromUnixTimestamp(this.defaultDueDate * 1000)],
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setLocalStatus(status: string): StatusType {
    switch (status) {
      case 'Not Started':
        return StatusType.NOT_STARTED;

      case 'On Track':
        return StatusType.ON_TRACK;

      case 'Off Track':
        return StatusType.OFF_TRACK;

      case 'At Risk':
        return StatusType.AT_RISK;

      case 'Completed':
        return StatusType.COMPLETE;

      case 'Canceled':
        return StatusType.CANCELED;

      default:
        return StatusType.NOT_STARTED;
    }
  }

  setInitialDates(start: string, end: string) {
    this.defaultStartDate = new Date(start).getTime() / 1000 + 60 * 60 * 24;
    this.defaultDueDate = new Date(end).getTime() / 1000 + 60 * 60 * 24;

    if (
      this.startDatePicker &&
      this.dueDatePicker &&
      this.defaultDueDate &&
      this.defaultStartDate
    ) {
      this.startDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultStartDate
      );
      this.dueDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultDueDate
      );
    }
  }

  openModal() {
    this.editDeliverableModal.open();
  }

  closeModal() {
    this.loadDeliverableData();
    this.editDeliverableModal.close();
  }

  changeSection(sectionName: 'details' | 'content') {
    if (sectionName === 'content') {
      this.editorConfig.initialContent = this.updatedContent;
    }
    this.activeSection = sectionName;
  }

  deleteDeliverable(delId: number) {
    this.plansService.deleteDeliverable(delId).subscribe((resp) => {
      if (resp) {
        this.alertService.showAlert('Deliverable Deleted');
        this.store.dispatch(
          new DeleteDeliverable({
            id: delId,
            implementation_phase_id: this.phaseId,
          })
        );
        this.closeModal();
      }
    });
  }

  // ADD UPDATE ASSIGNEES
  addAssignee(newAssignee: UserLiteDTO | null) {
    if (
      newAssignee &&
      !this.assignees.some((assignee) => assignee.id === newAssignee.id)
    ) {
      this.assignees.push(newAssignee);
    }
  }

  deleteAssignee(assigneeId: number) {
    this.assignees = this.assignees.filter(
      (assignee) => assignee.id !== assigneeId
    );
  }

  onDeliverableStatusChange(status: StatusType) {
    this.selectedStatus = status;
  }

  // SET DATES
  updateStartDate(times: DatepickerOutput[]) {
    const startDate = times[0].time / 1000;
    if (this.defaultDueDate) {
      let endDate = this.defaultDueDate;
      if (startDate > endDate) {
        endDate = startDate + 60 * 60 * 24;
      }
      this.setDates(startDate, endDate);
    }
  }

  updateDueDate(times: DatepickerOutput[]) {
    const endDate = times[0].time / 1000;
    if (this.defaultStartDate) {
      let startDate = this.defaultStartDate;
      if (endDate < startDate) {
        startDate = endDate - 60 * 60 * 24;
      }
      this.setDates(startDate, endDate);
    }
  }

  setDates(startDate?: number, endDate?: number) {
    if (startDate && endDate) {
      this.defaultStartDate = startDate;
      this.defaultDueDate = endDate;
    }
    if (
      this.startDatePicker &&
      this.dueDatePicker &&
      this.defaultDueDate &&
      this.defaultStartDate
    ) {
      this.startDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultStartDate
      );
      this.dueDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultDueDate
      );
    }
    this.f['startDate'].setValue(
      dateFromUnixTimestamp(this.defaultStartDate * 1000)
    );
    this.f['dueDate'].setValue(
      dateFromUnixTimestamp(this.defaultDueDate * 1000)
    );

    this.cdr.detectChanges();
  }

  submitDeliverableData() {
    this.formSubmitted = true;
    this.isLoading = true;

    this.editDeliverablePayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      department: this.f['department'].value,
      status: this.selectedStatus,
      content: this.updatedContent,
      start_date: this.f['startDate'].value,
      due_date: this.f['dueDate'].value,
    };

    this.plansService
      .updateDeliverable(this.deliverable.id, this.editDeliverablePayload)
      .subscribe((res) => {
        this.store.dispatch(new EditDeliverable(res.item));
        this.editDeliverableModal.close();
        this.isLoading = false;
      });

    // If user was not already assigned to the deliverable, then assign the user
    this.assignees.forEach((assignee) => {
      if (
        !this.deliverable.assignments.some(
          (assignment) => assignment.user.id === assignee.id
        )
      ) {
        this.plansService
          .addDeliverableAssignee(this.deliverable.id, assignee.id)
          .subscribe((response) => {
            const assigneePusher: PusherAssignmentChangesDTO = {
              for: 'implementation_deliverable',
              for_id: this.deliverable.id,
              implementation_phase_id: this.deliverable.implementation_phase_id,
              assignment: response.item,
            };
            this.store.dispatch(new AddAssignment(assigneePusher));
          });
      }
    });

    // If user was already assigned to the deliverable, but was removed during editing, delete the assignment
    this.deliverable.assignments.forEach((assignment) => {
      if (
        !this.assignees.some((assignee) => assignee.id === assignment.user.id)
      ) {
        this.plansService
          .deleteDeliverableAssignee(this.deliverable.id, assignment.user.id)
          .subscribe((response) => {
            if (response) {
              const assigneePusher: PusherAssignmentChangesDTO = {
                for: 'implementation_deliverable',
                for_id: this.deliverable.id,
                implementation_phase_id:
                  this.deliverable.implementation_phase_id,
                assignment,
              };
              this.store.dispatch(new DeleteAssignment(assigneePusher));
            }
          });
      }
    });
  }

  updateNotes(newNotes: string) {
    this.updatedContent = newNotes;
  }

  // ATTACHMENTS
  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
    this.fileListComponent.fileListUpdate();
  }

  deleteAttachment(file: FileDTO) {
    this.attachments = this.attachments.filter(
      (attachment) => attachment.id !== file.id
    );
  }

  getAttachments() {
    this.plansService
      .getDeliverableAttachments(this.deliverable.id)
      .subscribe((response) => {
        this.attachments = response;
      });
  }

  getComments() {
    if (this.commentRequest) this.commentRequest.unsubscribe();
    this.commentRequest = this.plansService
      .getComments('implementation_deliverable', this.deliverable.id)
      .subscribe((response) => {
        if (response) {
          this.commentList = response.items;
        }
      });

    this.subs.push(this.commentRequest);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
