import { ProfileLiteDTO } from 'src/app/common/dtos/profile.dto';
import { environment } from 'src/environments/environment';
import { PusherUserDTO } from '../../dtos/pusher-user.dto';
import { AvatarDTO } from './avatar.dto';

/**
 *
 * @param profile
 * @returns {AvatarDTO}
 * returns AvatarDTO
 *
 * Takes the passed in profile and transforms it into a AvatarDTO
 *
 * Adds first name, last name, color, and profile photo to the DTO
 */
export const avatarDTOFromProfile = (profile: ProfileLiteDTO): AvatarDTO => ({
  firstName: profile.first_name,
  lastName: profile.last_name,
  color: profile.color,
  photoUrl: profile.photo ? environment.apiUrl + profile.photo : undefined,
});

/**
 *
 * @param member
 * @returns {AvatarDTO}
 * returns AvatarDTO
 *
 * Takes the passed in member from pusher and transforms it into a AvatarDTO
 *
 * Adds first name, last name, color, and profile photo to the DTO
 */
export const avatarDTOFromPusher = (member: PusherUserDTO): AvatarDTO => ({
  firstName: member.username.split(' ')[0],
  lastName: member.username.split(' ')[1],
  color: member.color,
  photoUrl: member.image,
});
