<div class="container">
  <main role="main" class="form-signin w-100 m-auto text-center">
    <h1>Permission denied</h1>
    <p>
      Looks like you tried to access a page without the necessary permissions.
      If you think this is a mistake, kindly
      <a
        href="https://e2l.atlassian.net/servicedesk/customer/portal/8"
        target="_blank">
        submit a help ticket
      </a>
      , and we'll get it sorted for you. Thanks for your understanding!
    </p>
  </main>
</div>
