import { DistrictDTO } from '../../dtos/district.dto';
import { GradeDTO } from '../../dtos/grade.dto';
import { ProfileDTO } from '../../dtos/profile.dto';
import {
  ContentAreaDTO,
  SchoolDTO,
  UserSchoolDTO,
} from '../../dtos/school.dto';
import { UserRole } from './role/user-role';
import { UserType } from './type/user-type';
import { UserDTO } from './user.dto';

export class User {
  isCoach: boolean;

  isLeader: boolean;

  fullName = '';

  secondarySchools?: SchoolDTO[];

  constructor(
    public id: number,
    public username: string,
    public profile: ProfileDTO,
    public type: UserType,
    public status: number,
    public email: string,
    public district: DistrictDTO | undefined,
    public school: SchoolDTO | undefined,
    public roles: UserRole[],
    public isDeleted: boolean,
    public isSmart: boolean,
    public isSmartDefault: boolean,
    public smartCoach: UserDTO | null,
    public yearsCoached: number,
    public coach: UserDTO | null,
    public createdAt: number,
    public coacheeType: string | null,
    public lastLoginMs: number | null,
    public excludeFromReports?: boolean,
    public intercomHash?: string,
    public activityStreamToken?: string,
    public grades?: GradeDTO[],
    public contentAreas?: ContentAreaDTO[],
    public userSchools?: UserSchoolDTO[]
  ) {
    this.isCoach = this.roles.includes(UserRole.COACH);
    this.isLeader = [
      UserRole.DISTRICT_ADMIN,
      UserRole.SCHOOL_ADMIN,
      UserRole.OPERATOR,
    ].some((role) => this.roles.includes(role));
    this.fullName = `${this.profile.first_name} ${this.profile.last_name}`;
  }
}
