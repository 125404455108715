<div *ngIf="user" class="row mb-3 align-items-center justify-content-between">
  <div class="col-lg">
    <h1 id="header-welcome-message">
      Happy <span id="header-day-of-week">{{ dayOfWeek }}</span
      >, <b>{{ user.profile.first_name }}</b
      >!
    </h1>
  </div>
  <div class="col-8 col-sm-10 col-lg-auto d-flex align-items-center flex-row">
    <ng-container *ngIf="showSwitcher && (user.isLeader || user.isCoach)">
      <div id="dashboard-switcher">
        <div class="btn-group btn-group-sm" role="group">
          <ng-container *ngIf="user.isLeader">
            <input
              type="radio"
              class="btn-check"
              routerLink="/dashboard/leader"
              [checked]="dashboardType == 'leader'"
              id="leader-view-toggle" />
            <label class="btn btn-outline-primary" for="leader-view-toggle">
              Leader View
            </label>
          </ng-container>
          <ng-container *ngIf="user.isCoach">
            <input
              type="radio"
              class="btn-check"
              routerLink="/dashboard/coach"
              [checked]="dashboardType == 'coach'"
              id="coach-view-toggle" />
            <label class="btn btn-outline-primary" for="coach-view-toggle">
              Coach View
            </label>
          </ng-container>
          <input
            type="radio"
            class="btn-check"
            routerLink="/dashboard/learner"
            [checked]="dashboardType == 'learner'"
            id="learner-view-toggle" />
          <label class="btn btn-outline-primary" for="learner-view-toggle">
            Learner View
          </label>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-4 col-sm-2 col-md-auto d-flex">
    <div
      *ngIf="canCreateLog"
      ngbTooltip="Create Quick Walk Session"
      placement="top"
      [style.display]="'inline-block'"
      [style.margin-right]="'1rem'">
      <button
        aria-label="quick-walk-session-icon"
        placement="bottom-end"
        class="icon btn-reset"
        (click)="openQuickWalkModal()"
        [ngStyle]="{
          'background-image': 'url(/assets/report/observations.svg)'
        }"></button>
    </div>
    <div
      ngbTooltip="Throw Confetti"
      placement="top"
      [style.display]="'inline-block'"
      [style.margin-right]="'1rem'">
      <button
        aria-label="dashboard-celebration-icon"
        placement="bottom-end"
        class="icon btn-reset"
        (click)="cueConfetti()"
        [ngStyle]="{
          'background-image': 'url(/assets/confetti.svg)'
        }"></button>
    </div>
    <ng-container
      *ngIf="celebrateFromCoacheeList; else celebrationPopoverTemplate">
      <div
        ngbTooltip="Celebrate Coachees"
        placement="top"
        class="icon"
        (click)="createEditCelebrationModal.openModal()"
        [ngStyle]="{
          'background-image': 'url(/assets/celebrations.svg)'
        }"></div>
    </ng-container>
    <ng-template #celebrationPopoverTemplate>
      <div
        ngbTooltip="Celebrations"
        placement="top"
        [style.display]="'inline-block'">
        <button
          aria-label="dashboard-celebration-icon"
          [autoClose]="'outside'"
          [ngbPopover]="celebrationsPopover"
          popoverClass="popover-lg"
          placement="bottom-end"
          class="icon btn-reset"
          #celebrationPopover="ngbPopover"
          [ngStyle]="{
            'background-image': 'url(/assets/celebrations.svg)'
          }"></button>
      </div>
      <ng-template #celebrationsPopover>
        <app-card-celebrations-feed
          (openCelebrationModalEvent)="openCelebrationModal()"
          (createCelebrationEvent)="handleCreateCelebrationEvent()"
          [celebrations]="celebrations"
          [user]="user" />
      </ng-template>
    </ng-template>
  </div>
  <app-create-edit-celebration-modal
    (createCelebrationEvent)="handleCreateCelebrationEvent()"
    [selectedCoachees]="selectedCoachees"
    [user]="user"
    #createEditCelebrationModal />
  <app-create-quick-walk-modal #createQuickWalkModal />
</div>
