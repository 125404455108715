import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import {
  dateFromUnixTimestamp,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { AlertService } from '../../../services/alert/alert.service';
import { PlansService } from '../../../services/plans/plans.service';
import { CURRENT_SCHOOL_YEAR } from '../../../services/school-year/school-year.utilities';
import { CreateEditPlanPayload } from '../../../types/payloads/plan.payload';
import { PlansAPIResponse } from '../../../types/responses/plan.responses';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../../datepicker/datepicker.component';
import { DistrictSelectComponent } from '../../district-select/district-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-create-edit-plan-modal',
  templateUrl: './create-edit-plan-modal.component.html',
  styleUrl: './create-edit-plan-modal.component.scss',
})
export class CreateEditPlanModalComponent implements OnInit, OnChanges {
  @ViewChild('createEditPlanModal') createEditPlanModal: ModalComponent;

  @ViewChild('districtSelect') districtSelect: DistrictSelectComponent;

  @ViewChild('startDatePicker') startDatePicker: DatepickerComponent;

  @ViewChild('endDatePicker') endDatePicker: DatepickerComponent;

  @Input() user: User;

  @Input() editPlanData: PlansAPIResponse | null;

  @Output() readonly planEvent = new EventEmitter();

  @Output() readonly resetEditPlanData = new EventEmitter();

  form: FormGroup;

  isE2l = false;

  formSubmitted = false;

  isLoading = false;

  serverError = '';

  defaultStartDate: number;

  defaultEndDate: number;

  constructor(
    private formBuilder: FormBuilder,
    private plansService: PlansService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.user?.district) {
      this.isE2l = checkIfE2L(this.user);
      this.defaultStartDate =
        new Date(CURRENT_SCHOOL_YEAR.start_date).getTime() / 1000 +
        60 * 60 * 24;
      this.defaultEndDate =
        new Date(CURRENT_SCHOOL_YEAR.end_date).getTime() / 1000 + 60 * 60 * 24;
      this.form = this.formBuilder.group({
        title: ['', Validators.required],
        description: ['', Validators.required],
        level1: ['Phase', Validators.required],
        level2: ['Deliverable', Validators.required],
        level3: ['Action Item', Validators.required],
        districtId: [this.user.district.id],
        startDate: [dateFromUnixTimestamp(this.defaultStartDate * 1000)],
        endDate: [dateFromUnixTimestamp(this.defaultEndDate * 1000)],
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPlanData'] && this.editPlanData) {
      const currentStartDate =
        new Date(this.editPlanData.start_date).getTime() / 1000 + 60 * 60 * 24;
      const currentEndDate =
        new Date(this.editPlanData.end_date).getTime() / 1000 + 60 * 60 * 24;
      this.setDates(currentStartDate, currentEndDate);
      this.form = this.formBuilder.group({
        title: [this.editPlanData.title, Validators.required],
        description: [this.editPlanData.description, Validators.required],
        level1: [this.editPlanData.phase_displayname, Validators.required],
        level2: [
          this.editPlanData.deliverable_displayname,
          Validators.required,
        ],
        level3: [this.editPlanData.actionitem_displayname, Validators.required],
        districtId: [this.editPlanData.district_id],
        startDate: [this.editPlanData.start_date],
        endDate: [this.editPlanData.end_date],
      });
    }
  }

  get f() {
    return this.form.controls;
  }

  clearForm() {
    this.form.reset({
      title: '',
      description: '',
      level1: 'Phase',
      level2: 'Deliverable',
      level3: 'Action Item',
      districtId: this.user.district?.id,
      startDate: dateFromUnixTimestamp(this.defaultStartDate * 1000),
      endDate: dateFromUnixTimestamp(this.defaultEndDate * 1000),
    });
    this.formSubmitted = false;
    this.serverError = '';
    this.editPlanData = null;
    this.resetEditPlanData.emit(true);
    this.setDates();
  }

  createPlan() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return false;
    }

    this.isLoading = true;

    const createPlanPayload: CreateEditPlanPayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      start_date: this.f['startDate'].value,
      end_date: this.f['endDate'].value,
      district_id: this.f['districtId'].value,
      phase_displayname: this.f['level1'].value,
      deliverable_displayname: this.f['level2'].value,
      actionitem_displayname: this.f['level3'].value,
    };

    this.plansService.createPlan(createPlanPayload).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.serverError = error.error.message;
        setTimeout(() => {
          this.serverError = '';
        }, 3000);
      },
      next: (res) => {
        if (res) {
          this.planEvent.emit();
          this.alertService.showAlert('Plan Created');
          this.createEditPlanModal.close();
          this.isLoading = false;
          this.router.navigate([
            `/plans/implementation-plan/${res.item.id}/details`,
          ]);
        }
      },
    });

    return true;
  }

  updatePlan() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return false;
    }

    this.isLoading = true;

    const createPlanPayload: CreateEditPlanPayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      start_date: this.f['startDate'].value,
      end_date: this.f['endDate'].value,
      district_id: this.f['districtId'].value,
      phase_displayname: this.f['level1'].value,
      deliverable_displayname: this.f['level2'].value,
      actionitem_displayname: this.f['level3'].value,
    };

    if (this.editPlanData) {
      this.plansService
        .updatePlan(this.editPlanData.id, createPlanPayload)
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: (res) => {
            if (res) {
              this.planEvent.emit();
              this.alertService.showAlert('Plan Updated');
              this.createEditPlanModal.close();
              this.editPlanData = null;
              this.isLoading = false;
            }
          },
        });
    }

    return true;
  }

  deletePlan() {
    if (this.editPlanData) {
      this.plansService.deletePlan(this.editPlanData.id).subscribe((res) => {
        if (res) {
          this.planEvent.next(true);
          this.alertService.showAlert('Plan Deleted');
          this.createEditPlanModal.close();
        }
      });
    }
  }

  updateStartDate(times: DatepickerOutput[]) {
    const startDate = times[0].time / 1000;
    let endDate = this.defaultEndDate;
    if (startDate > endDate) {
      endDate = startDate + 60 * 60 * 24;
    }
    this.setDates(startDate, endDate);
  }

  updateEndDate(times: DatepickerOutput[]) {
    const endDate = times[0].time / 1000;
    let startDate = this.defaultStartDate;
    if (endDate < startDate) {
      startDate = endDate - 60 * 60 * 24;
    }
    this.setDates(startDate, endDate);
  }

  updateDistrict(district: DistrictSimpleDto | null) {
    if (district) {
      this.f['districtId'].setValue(district.id);
    }
  }

  setDates(startDate?: number, endDate?: number) {
    if (startDate && endDate) {
      this.defaultStartDate = startDate;
      this.defaultEndDate = endDate;
    } else {
      this.defaultStartDate =
        new Date(CURRENT_SCHOOL_YEAR.start_date).getTime() / 1000 +
        60 * 60 * 24;
      this.defaultEndDate =
        new Date(CURRENT_SCHOOL_YEAR.end_date).getTime() / 1000 + 60 * 60 * 24;
    }
    if (this.startDatePicker && this.endDatePicker) {
      this.startDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultStartDate
      );
      this.endDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultEndDate
      );
    }
    this.f['startDate'].setValue(
      dateFromUnixTimestamp(this.defaultStartDate * 1000)
    );
    this.f['endDate'].setValue(
      dateFromUnixTimestamp(this.defaultEndDate * 1000)
    );
    this.cdr.detectChanges();
  }
}
