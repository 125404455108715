<ng-container *ngIf="!loadingData; else noLink">
  <a href="javascript:void(0)" (click)="showEvidenceModal()">
    <ng-content></ng-content>
  </a>
</ng-container>
<ng-template #noLink>
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>

<app-modal #evidenceSummaryModal [modalConfig]="modalConfig">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="evidenceSummaryModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="evidenceSummary">
    <div class="text-center px-2">
      <h3 class="competency-title fs-23 fw-800">
        {{ evidenceSummary.standard.title }}
      </h3>
      <div class="strand-title-level fs-21 fw-800">
        <span *ngIf="evidenceSummary.indicatorGroup">
          {{ evidenceSummary.indicatorGroup.title }} - </span
        >Level
        {{ evidenceSummary.level }}
      </div>
      <p class="gi-indicator px-5">
        <app-safehtml
          [html]="evidenceSummary.indicatorDescription"></app-safehtml>
      </p>
    </div>

    <div class="px-2">
      <hr />
      <div class="fs-23 fw-800">Context</div>
      <div class="py-3">
        <app-safehtml [html]="evidenceSummary.narrative"></app-safehtml>
      </div>
      <ng-container *ngIf="hasAttachments && uploadedFiles">
        <div class="px-3 bg-light">
          <app-file-list
            [fileList]="uploadedFiles"
            [canDelete]="false"></app-file-list>
        </div>
      </ng-container>

      <div class="py-3" *ngIf="video">
        <app-video-player [video]="video"></app-video-player>
      </div>

      <div class="py-4">
        <div class="fs-23 fw-800">Comments</div>
        <div class="card mt-3 px-4">
          <app-evidence-comments
            [refTableId]="evidenceSummary.id"
            [commentList]="commentList" />
        </div>
      </div>
      <button
        *ngIf="copyEvidenceEnabled && evidenceSummary && evidenceId"
        (click)="openCopyEvidenceModal()"
        class="btn btn-link fw-bold p-0">
        <i class="bi bi-copy me-2"></i>Copy Evidence to Another GI
      </button>
      <div class="d-flex justify-content-center mt-4 mb-2">
        <ng-container *ngIf="!evidenceAssessed">
          <ng-container
            *ngIf="
              isCoach && evidenceSummary.user_id !== currentUser?.id;
              else default
            ">
            <button
              (click)="assessEvidence()"
              class="btn btn-primary btn-next"
              [disabled]="evidenceAssessing">
              <ng-container *ngIf="evidenceAssessing">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </ng-container>
              Mark Evidence as Reviewed
            </button>
          </ng-container>
          <ng-template #default>
            <div class="fs-19">
              <i class="bi bi-exclamation-circle text-warning me-2"></i>
              This evidence has not been reviewed yet
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="evidenceAssessed">
          <div class="fs-19">
            <i class="bi bi-check2-circle text-info me-2"></i>
            This evidence has been reviewed
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div footerContent class="w-100">
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="!isShadower">
        <button
          aria-label="delete evidence button"
          class="btn btn-md btn-txt-delete gl-tertiary"
          type="button"
          container="body"
          [autoClose]="'outside'"
          [ngbPopover]="contentTemplate"
          [popoverTitle]="'Delete Evidence'"
          #popover="ngbPopover">
          Delete
        </button>
        <ng-template #contentTemplate>
          Are you sure you want to delete this evidence?
          <div class="text-end mt-2">
            <button
              type="button"
              class="btn btn-secondary btn-sm px-3 m-1"
              (click)="popover.close()">
              No
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm px-3 m-1"
              (click)="deleteEvidence(); popover.close()">
              Yes
            </button>
          </div>
        </ng-template>
      </ng-container>

      <!-- <ng-container *ngIf="(!isShadower && isCoach)>
        <button type="button" class="btn btn-md btn-primary" disabled>
          <i class="bi bi-pencil"></i> Edit
        </button>
      </ng-container> -->
    </div>
  </div>
</app-modal>

<ng-container *ngIf="copyEvidenceEnabled && evidenceSummary && evidenceId">
  <app-copy-evidence-modal
    #copyEvidenceModal
    [userId]="evidenceSummary.user_id"
    [evidenceId]="evidenceId"></app-copy-evidence-modal>
</ng-container>
