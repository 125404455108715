<div class="card border-0">
  <ng-container *ngIf="isLoaded; else placeHolder">
    <div class="card-header pt-3">
      <h2 class="card-title float-start" (click)="triggerCollapse()">
        Activity
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body overflow-auto pt-0"
        tabindex="0"
        role="group"
        style="max-height: 75rem">
        <ng-container *ngIf="!errorMessage; else errorMessageTemplate">
          <ng-container
            *ngIf="
              activityFeedItemsThisWeek.length > 0 ||
                activityFeedItemsEarlier.length > 0;
              else noActivitesTemplate
            ">
            <p class="fw-bolder">This Week</p>
            <ng-container
              *ngIf="
                activityFeedItemsThisWeek.length > 0;
                else noActivitiesThisWeekTemplate
              ">
              <div
                *ngFor="let feedItem of activityFeedItemsThisWeek"
                class="d-flex align-items-center justify-content-between flex-row mb-4 px-2 px-xxl-4">
                <app-avatar
                  [avatarSize]="'medium'"
                  [profile]="feedItem.actor_profile"></app-avatar>
                <div class="ms-2 me-4 text-break flex-grow-1">
                  <div class="d-block">
                    <span class="fw-bolder">
                      {{ feedItem.actor_profile.first_name }}
                      {{ feedItem.actor_profile.last_name }}
                    </span>
                    <span class="fs-12">
                      {{ feedItem.time | formatDistanceToNow : true }}
                    </span>
                  </div>
                  <span class="fs-15 fw-light">
                    <app-safehtml
                      [openLinksInSameTab]="true"
                      [html]="feedItem.message"></app-safehtml>
                  </span>
                </div>
                <div
                  class="icon"
                  [ngStyle]="{
                    'background-image':
                      'url(/assets/report/' + feedItem.icon + '.svg)'
                  }"></div>
              </div>
            </ng-container>
            <ng-template #noActivitiesThisWeekTemplate>
              <div class="text-center py-3">
                No activities have happened this week
              </div>
            </ng-template>
            <div *ngIf="activityFeedItemsEarlier.length > 0">
              <hr class="border border-primary border-1 my-4" />
              <p class="fw-bolder">Earlier</p>
              <div
                *ngFor="let feedItem of activityFeedItemsEarlier"
                class="d-flex align-items-center justify-content-between flex-row mb-4 px-2 px-xxl-4">
                <app-avatar
                  [avatarSize]="'medium'"
                  [profile]="feedItem.actor_profile"></app-avatar>
                <div class="ms-2 me-4 text-break flex-grow-1">
                  <div class="d-block">
                    <span class="fw-bolder">
                      {{ feedItem.actor_profile.first_name }}
                      {{ feedItem.actor_profile.last_name }}
                    </span>
                    <span class="fs-12">
                      {{ feedItem.time | formatActivityFeedDateTime }}
                    </span>
                  </div>
                  <span class="fs-15 fw-light">
                    <app-safehtml
                      [openLinksInSameTab]="true"
                      [html]="feedItem.message"></app-safehtml>
                  </span>
                </div>
                <div
                  class="icon"
                  [ngStyle]="{
                    'background-image':
                      'url(/assets/report/' + feedItem.icon + '.svg)'
                  }"></div>
              </div>
            </div>
          </ng-container>
          <ng-template #noActivitesTemplate>
            <div
              class="d-flex w-100 justify-content-center align-items-center"
              style="height: 16rem">
              No activities to display
            </div>
          </ng-template>
        </ng-container>
        <ng-template #errorMessageTemplate>
          <div
            class="d-flex w-100 justify-content-center align-items-center"
            style="height: 16rem">
            {{ errorMessage }}
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
