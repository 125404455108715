<div class="row file-list" *ngIf="internalList.length > 0">
  <div
    class="d-flex flex-row justify-content-between border-bottom border-black-subtle align-items-center"
    *ngFor="let file of internalList">
    <div data-test="file" class="file-name">
      <i
        [ngClass]="[
          'bi',
          file.iconClass,
          'color-tertiary',
          'fs-21',
          'me-1',
          'd-inline-block'
        ]"></i>
      <span title="{{ file.name }}">{{ file.name }}</span>
    </div>
    <div
      class="d-flex align-items-center"
      *ngIf="documentIcons.includes(file.iconClass)">
      <button
        ngbTooltip="Download file"
        aria-label="Download file"
        class="btn"
        (click)="viewFile(file)">
        <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
      </button>
      <button
        *ngIf="canDelete"
        ngbTooltip="Delete file"
        aria-label="Delete file"
        class="btn-delete"
        (click)="deleteFile(file)">
        <i [ngClass]="['bi', 'fs-21', 'bi-trash']"></i>
      </button>
    </div>
    <div
      class="d-flex align-items-center"
      *ngIf="!documentIcons.includes(file.iconClass)">
      <button
        ngbTooltip="View file"
        aria-label="View file"
        class="btn"
        (click)="viewFile(file)">
        <i [ngClass]="['bi', 'fs-21', 'bi-eye']"></i>
      </button>
      <button
        ngbTooltip="Download file"
        aria-label="Download file"
        class="btn"
        (click)="downloadFile(file)">
        <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
      </button>
      <button
        *ngIf="canDelete"
        ngbTooltip="Delete file"
        aria-label="Delete file"
        class="btn-delete"
        (click)="deleteFile(file)">
        <i [ngClass]="['bi', 'fs-21', 'bi-trash']"></i>
      </button>
    </div>
  </div>
</div>
