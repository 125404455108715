import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { UserDtoApiResponse } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  SortEvent,
  SortableHeader,
} from '../../directives/sortable-header.directive';

import { UserSearchService } from '../../services/user-search/user-search.service';
import { PartialUserSearchParams } from './roster-users.helper';

@Component({
  selector: 'app-roster-users',
  templateUrl: './roster-users.component.html',
  styleUrls: ['./roster-users.component.scss'],
  providers: [UserSearchService],
})
export class RosterUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchResults: UserDtoApiResponse;

  @Input() isLoading = true;

  @Input() clearSelectionsSubject: Subject<boolean>;

  @Input() selectAllSubject: BehaviorSubject<number[]>;

  @Output() readonly searchChange = new Subject<
    [PaginationDTO, PartialUserSearchParams]
  >();

  @Output() readonly editUserEvent = new EventEmitter<UserDTO>();

  @Output() readonly usersSelected = new EventEmitter<number[]>();

  @Output() readonly masterSelectedEvent = new EventEmitter<boolean>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  subs: Subscription[] = [];

  searchTermUpdate = new Subject<string>();

  loadingIcons = true;

  userList: UserDTO[];

  searchTerm = '';

  numPerPage = 20;

  listMeta: PaginationDTO;

  response: UserDtoApiResponse;

  masterSelected: boolean;

  selectedUserIds: number[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    name: 'gray',
    email: 'gray',
    school: 'gray',
    coachee_type: 'gray',
    cohort: 'gray',
    status: 'gray',
  };

  searchParams: PartialUserSearchParams = {
    per_page: this.numPerPage,
    page: 1,
    sort_by: '',
    sort_by_desc: false,
    include_inactive_users: true,
  };

  sortInfo = {
    column: '',
    direction: '',
  };

  loading = false;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.selectedUserIds = [];
    this.masterSelected = false;
    this.subs.push(
      this.clearSelectionsSubject.subscribe(() => {
        this.clearSelections();
      })
    );
    this.subs.push(
      this.selectAllSubject.subscribe((resp) => {
        this.selectedUserIds = resp;
        this.setUserList(this.userList);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchResults']) {
      const currentValue = changes['searchResults'].currentValue;
      if (currentValue?.items) {
        this.setUserList(currentValue.items);
        this.listMeta = changes['searchResults'].currentValue._meta;
        this.isLoading = false;
        this.masterSelected = false;
        this.checkIfAllSelected();
      }
    }
  }

  setUserList(userList: UserDTO[]) {
    this.userList = userList.map((item) => ({
      ...item,
      isSelected: this.selectedUserIds.includes(item.id),
    }));
    this.isLoading = false;
    setTimeout(() => {
      this.updateColumns();
    }, 350);
  }

  onSort({ column, direction }: SortEvent) {
    if (direction === '') {
      this.searchParams.sort_by = '';
      this.searchParams.sort_by_desc = '';
    } else {
      this.searchParams.sort_by = column;
      if (direction === 'asc') {
        this.searchParams.sort_by_desc = true;
      } else if (direction === 'desc') {
        this.searchParams.sort_by_desc = false;
      }
    }

    this.sortInfo.column = column;
    this.sortInfo.direction = direction;

    this.loadingIcons = true;
    this.isLoading = true;
    this.outputChanges();
  }

  outputChanges(pageNumber?: number) {
    const listMeta = pageNumber
      ? {
          ...this.listMeta,
          currentPage: pageNumber,
        }
      : this.listMeta;
    this.searchChange.next([listMeta, this.searchParams]);
  }

  showCoachFullName(user: UserDTO) {
    const searchTerm = this.searchTerm;
    this.searchTerm = searchTerm;
    const getUser = user;
    if (
      getUser.coach?.profile?.first_name &&
      getUser.coach?.profile.last_name
    ) {
      return `${getUser.coach?.profile?.first_name} ${getUser.coach?.profile?.last_name}`;
    }
    return '-';
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.sortInfo.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (this.sortInfo.direction === '' || this.sortInfo.column === '') {
      this.sortIconTemp[this.sortInfo.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      if (target.checked) {
        this.selectedUserIds.push(parseInt(target.value));
      } else {
        const index = this.selectedUserIds.findIndex(
          (value) => value === parseInt(target.value)
        );
        this.selectedUserIds.splice(index, 1);
      }
      this.checkIfAllSelected();
      this.usersSelected.next(this.selectedUserIds);
    }
  }

  handleCheckAll(): void {
    this.userList.forEach((item: UserDTO) => {
      item.isSelected = this.masterSelected;
      if (this.masterSelected) {
        if (!this.selectedUserIds.includes(item.id)) {
          this.selectedUserIds.push(item.id);
        }
      } else if (this.selectedUserIds.includes(item.id)) {
        const idIndex = this.selectedUserIds.findIndex((id) => id === item.id);
        this.selectedUserIds.splice(idIndex, 1);
      }
    });
    this.usersSelected.next(this.selectedUserIds);
  }

  checkIfAllSelected() {
    this.masterSelected = this.userList.every(
      (item: UserDTO) => item.isSelected
    );
  }

  clearSelections() {
    this.selectedUserIds = [];
    this.userList.forEach((item: UserDTO) => {
      item.isSelected = false;
    });
  }

  ngOnDestroy(): void {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
