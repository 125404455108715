import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { SessionTimeoutService } from 'src/app/private/shared/services/session-timeout/session-timeout.service';
import { StorageService } from '../services/storage/storage.service';
import { MONTH_IN_MILLISECONDS } from './time.definitions';

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  user: User;

  constructor(
    private store: Store,
    private sessionTimeoutService: SessionTimeoutService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  // eslint-disable-next-line class-methods-use-this
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // const idToken = this.user?.id_token;
    const idToken = StorageService.getItem(
      'authToken',
      MONTH_IN_MILLISECONDS
    )?.token;

    // only fire when authenticated
    if (idToken && !request.url.includes('logout')) {
      this.sessionTimeoutService.startTimer();
    }

    if (idToken) {
      const cloned = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${idToken}`)
          .set('e2LClient', 'growelab'),
      });

      return next.handle(cloned);
    }

    return next.handle(request);
  }
}
