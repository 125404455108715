<div class="card border-0 mb-4" style="height: 25rem; overflow: auto">
  <ng-container *ngIf="items; else placeHolder">
    <div class="card-header pt-3">
      <div class="card-title cursor-default mb-0 float-start">
        Risks and Delays
      </div>
    </div>
    <div class="card-body pt-0 fs-13">
      <ng-container *ngFor="let item of items">
        <div class="purple-border"></div>
        <div class="row mb-2">
          <div class="col-5 pe-0">
            <span class="fw-800">{{ item.type_displayname }} </span>
            {{ item.title }}
          </div>
          <div class="col-3 px-0">
            <span class="status-pill {{ item.status | lowercase }}">
              {{ item.status }}
            </span>
          </div>
          <div class="col-4">
            {{ item.due_date | date }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<ng-template #placeHolder>
  <div class="card-header pt-3">
    <div class="card-title placeholder-glow">
      <span class="placeholder col-5"></span>
    </div>
  </div>
  <div class="card-body pt-0" style="height: 18rem">
    <div class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
    </div>
    <div class="card-text placeholder-glow mt-2">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
      <span class="placeholder col-4"></span>
    </div>
  </div>
</ng-template>
