import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { ResourceLeveledCompetenciesDto } from '../../dtos/leveled-resource.dto';
import { CompetenciesSelectService } from '../../services/competencies/competencies-select.service';
import { LeveledCompetencyDTO } from './leveled-competency.dto';

@Component({
  selector: 'app-leveled-resource-competencies',
  templateUrl: './leveled-resource-competencies.component.html',
  styleUrls: ['./leveled-resource-competencies.component.scss'],
})
export class LeveledResourceCompetenciesComponent implements OnInit {
  @Input() resourceId: number;

  @Input() competenciesToAdd: LeveledCompetencyDTO[] | null;

  @Input() resourceCompetencies: LeveledCompetencyDTO[] | null;

  @Input() hasEditPermissions?: boolean;

  @Output() readonly sendCompetenciesToAdd: EventEmitter<
    LeveledCompetencyDTO[]
  > = new EventEmitter();

  @Output() readonly sendResourceCompetencies: EventEmitter<
    LeveledCompetencyDTO[]
  > = new EventEmitter();

  levelNums: number[] = [];

  currentUser: User;

  constructor(
    private competenciesSelectService: CompetenciesSelectService,
    private store: Store
  ) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    if (!this.competenciesToAdd) {
      this.competenciesToAdd = [];
    }
    if (!this.resourceCompetencies) {
      this.resourceCompetencies = [];
    }
    this.levelNums = [1, 2, 3, 4];

    const leveledCompetencyFromCompetencySelectDto = (
      item: ResourceLeveledCompetenciesDto
    ): LeveledCompetencyDTO => ({
      id: item.egrowe_standard_id,
      name: item.standard.title,
      level1: item.level1,
      level2: item.level2,
      level3: item.level3,
      level4: item.level4,
      levelsFormatted: item.levels_formatted,
      can_add: item.can_add,
      can_delete: item.can_delete,
    });

    this.competenciesSelectService.resourceLeveledCompetencies.subscribe(
      (competencies) => {
        this.resourceCompetencies = competencies.map((eachCompetency) =>
          leveledCompetencyFromCompetencySelectDto(eachCompetency)
        );
        this.sendResourceCompetencies.emit(this.resourceCompetencies);
      }
    );

    let districtId = 0;
    if (this.currentUser?.district) {
      if (this.currentUser.district.title !== 'engage2learn') {
        districtId = this.currentUser.district.id;
      }
    }
    if (this.resourceId) {
      this.competenciesSelectService
        .getLeveledCompetenciesOnResource(this.resourceId, districtId)
        .subscribe();
    }
  }

  checkedLevelBox(isChecked: boolean, competencyIndex: number, level: number) {
    if (this.competenciesToAdd?.length) {
      switch (level) {
        case 1:
          this.competenciesToAdd[competencyIndex].level1 = isChecked;
          break;
        case 2:
          this.competenciesToAdd[competencyIndex].level2 = isChecked;
          break;
        case 3:
          this.competenciesToAdd[competencyIndex].level3 = isChecked;
          break;
        case 4:
          this.competenciesToAdd[competencyIndex].level4 = isChecked;
          break;
        default:
      }
    }
    this.updateLevelsFormatted(competencyIndex);
  }

  updateLevelsFormatted(competencyIndex: number) {
    let levelsFormatted = '';
    if (this.competenciesToAdd?.length) {
      this.competenciesToAdd[competencyIndex].levelsFormatted = '';
      const { level1, level2, level3, level4 } =
        this.competenciesToAdd[competencyIndex];
      if (level1) {
        levelsFormatted = '1';
      }
      if (level2) {
        if (levelsFormatted) {
          levelsFormatted += ',2';
        } else {
          levelsFormatted = '2';
        }
      }
      if (level3) {
        if (levelsFormatted) {
          levelsFormatted += ',3';
        } else {
          levelsFormatted = '3';
        }
      }
      if (level4) {
        if (levelsFormatted) {
          levelsFormatted += ',4';
        } else {
          levelsFormatted = '4';
        }
      }
      this.competenciesToAdd[competencyIndex].levelsFormatted = levelsFormatted;
    }
  }

  addCompetency(competency: LeveledCompetencyDTO, competencyIndex: number) {
    if (this.competenciesToAdd?.[competencyIndex].level1) {
      this.competenciesSelectService
        .addCompetencyLevelToResource(this.resourceId, competency.id, 1)
        .subscribe();
    }
    if (this.competenciesToAdd?.[competencyIndex].level2) {
      this.competenciesSelectService
        .addCompetencyLevelToResource(this.resourceId, competency.id, 2)
        .subscribe();
    }
    if (this.competenciesToAdd?.[competencyIndex].level3) {
      this.competenciesSelectService
        .addCompetencyLevelToResource(this.resourceId, competency.id, 3)
        .subscribe();
    }
    if (this.competenciesToAdd?.[competencyIndex].level4) {
      this.competenciesSelectService
        .addCompetencyLevelToResource(this.resourceId, competency.id, 4)
        .subscribe();
    }
    if (this.competenciesToAdd?.length) {
      this.competenciesToAdd = this.competenciesToAdd?.filter(
        (eachCompetency) => eachCompetency !== competency
      );
    }
    this.resourceCompetencies?.push(competency);
    if (this.competenciesToAdd) {
      this.sendCompetenciesToAdd.emit(this.competenciesToAdd);
    }
    if (this.resourceCompetencies) {
      this.sendResourceCompetencies.emit(this.resourceCompetencies);
    }
  }

  deleteCompetency(competency: LeveledCompetencyDTO) {
    this.competenciesSelectService
      .removeCompetencyFromResource(this.resourceId, competency.id)
      .subscribe();
    if (this.resourceCompetencies?.length) {
      this.resourceCompetencies = this.resourceCompetencies?.filter(
        (eachCompetency) => eachCompetency !== competency
      );
    }
    if (this.resourceCompetencies) {
      this.sendResourceCompetencies.emit(this.resourceCompetencies);
    }
  }
}
