<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <ng-select
    [labelForId]="labelId"
    (change)="coachSelected($event); select.blur()"
    [items]="coachList"
    [searchFn]="searchCoaches"
    [loading]="searchLoading"
    [(ngModel)]="internalSelectedCoach"
    [placeholder]="placeholder"
    #select>
    <ng-template ng-label-tmp let-item="item">
      {{ item.profile.first_name }} {{ item.profile.last_name }}
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="searchTerm">
      {{ item.profile.first_name }} {{ item.profile.last_name }}
    </ng-template>
  </ng-select>
  <ng-container
    *ngIf="internalSelectedCoachList && internalSelectedCoachList.length">
    <div>
      <ng-container *ngFor="let eachCoach of internalSelectedCoachList">
        <span
          class="badge badge-item mt-2"
          (click)="removeCoachFromList(eachCoach)"
          >{{ eachCoach.profile.first_name }}&nbsp;
          {{ eachCoach.profile.last_name }}<i class="bi bi-x mx-2"></i
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
