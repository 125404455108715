import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { UserService } from '../../../../../common/state/user/user.service';
import { CelebrationDTO } from '../../../dtos/celebrations.dto';
import { NewCoachingSessionUserDto } from '../../../dtos/coaching-session.dto';
import { FileDTO } from '../../../dtos/file.dto';
import { celebrationFileDTOToFileDTO } from '../../../helpers/translators/celebrations.translator';
import { CelebrationsService } from '../../../services/celebrations/celebrations.service';
import { ConfettiService } from '../../../services/confetti/confetti.service';
import { CelebrationAttachmentPayload } from '../../../types/payloads/celebrations.payloads';
import { CompetencySelectComponent } from '../../competency/competency-select/competency-select.component';
import { UploadStyles } from '../../file-management/file-upload/file-upload.component';
import { WysiwygEditorComponent } from '../../wysiwyg-editor/wysiwyg-editor.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-create-edit-celebration-modal',
  templateUrl: './create-edit-celebration-modal.component.html',
  styleUrls: ['./create-edit-celebration-modal.component.scss'],
})
export class CreateEditCelebrationModalComponent implements OnChanges {
  @ViewChild('celebrationModal')
  celebrationModal: ModalComponent;

  @ViewChild('competencySelect') competencySelect: CompetencySelectComponent;

  @ViewChild('wysiwyg') public editor: WysiwygEditorComponent;

  @Output() readonly createCelebrationEvent = new EventEmitter();

  @Input() selectedCoachees: NewCoachingSessionUserDto[] | null;

  @Input() editCelebration: CelebrationDTO | null;

  celebrationId: number;

  users: UserLiteDTO[] = [];

  loadingUsersSubscriptions: Subscription[] = [];

  attachments: FileDTO[] = [];

  celebrationAttachmentsUploadCount = 0;

  competencyList: CompetencyDTO[] = [];

  uploadStyle = UploadStyles.SIMPLE;

  @Input() user: User | null;

  districtIds: number[] = [];

  isUserIncluded = false;

  constructor(
    private celebrationsService: CelebrationsService,
    private confettiService: ConfettiService,
    private userService: UserService
  ) {}

  ngOnChanges() {
    this.users = [];
    this.attachments = [];
    this.competencyList = [];
    if (this.editor) {
      this.editor.editorContent = '';
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const subscription of this.loadingUsersSubscriptions) {
      subscription.unsubscribe();
    }
    if (this.selectedCoachees) {
      // eslint-disable-next-line no-restricted-syntax
      for (const selectedCoachee of this.selectedCoachees) {
        this.loadingUsersSubscriptions.push(
          this.userService
            .fetchUserById(selectedCoachee.id)
            .subscribe((userDTO) => {
              if (userDTO) {
                this.users.push(userDTO);
              }
            })
        );
      }
    }
    if (this.editCelebration) {
      this.users = this.editCelebration.users;
      this.attachments = this.editCelebration.files.map((file) =>
        celebrationFileDTOToFileDTO(file)
      );
      this.competencyList = this.editCelebration.competencies;
      this.editor.editorContent = this.editCelebration.content;
    }
    if (
      this.user?.district &&
      !this.user.roles.includes(UserRole.E2L_EMPLOYEE || UserRole.ESUITE_ADMIN)
    ) {
      this.districtIds.push(this.user.district.id);
    }
  }

  closeModal() {
    this.celebrationModal.close();
  }

  openModal() {
    this.celebrationModal.open();
  }

  addChosenUser(chosenUser: UserLiteDTO | null) {
    if (chosenUser) {
      if (!this.users.some((user) => user.id === chosenUser.id)) {
        this.users.push(chosenUser);
      }
    }

    const ids = this.users.map((user) => user.id);
    if (this.user?.id) {
      this.isUserIncluded = ids.includes(this.user.id);
    }
  }

  deleteChosenUser(chosenUser: UserLiteDTO | null) {
    if (chosenUser) {
      this.users = this.users.filter((user) => user.id !== chosenUser.id);
    }

    const ids = this.users.map((user) => user.id);
    if (this.user?.id) {
      this.isUserIncluded = ids.includes(this.user.id);
    }
  }

  updateCompetency(competency: CompetencyDTO | null) {
    if (this.competencyList.length > 0 && this.editCelebration) {
      this.celebrationsService
        .deleteCelebrationCompetencies(
          this.editCelebration.id,
          this.competencyList[0].id // for now current competency list will always be 0
        )
        .subscribe((res) => {
          if (res) {
            this.competencyList = [];
          }
        });
    }
    if (competency) this.competencyList.push(competency);
  }

  updateCompetencyList(competencyList: CompetencyDTO[]) {
    this.competencyList = competencyList;
  }

  createCelebration() {
    if (this.users.length > 0 && this.editor.editorContent) {
      const ids = this.users.map((user) => user.id);
      this.celebrationsService
        .createCelebration(ids, this.editor.editorContent)
        .subscribe((res) => {
          this.celebrationId = res.item.id;
          this.uploadCompetencies();
          this.uploadAttachments();
          this.closeModal();
          this.clearFields();
          this.createCelebrationEvent.emit();
          this.confettiService.showConfetti();
        });
    }
  }

  editCelebrationHandler() {
    if (this.editCelebration) {
      this.celebrationsService
        .editCelebrationContent({
          celebrationId: this.editCelebration.id,
          content: this.editor.editorContent,
        })
        .subscribe();
      this.uploadCompetencies();
      this.celebrationAttachmentsUploadCount = 0;
      this.uploadAttachments();
    }
  }

  finishCelebrationEdit() {
    if (this.celebrationAttachmentsUploadCount === this.attachments.length) {
      this.closeModal();
      this.clearFields();
      this.createCelebrationEvent.emit();
    }
  }

  uploadCompetencies() {
    if (this.celebrationId && this.competencyList.length > 0) {
      this.competencyList.forEach((competency) => {
        this.celebrationsService
          .updateCelebrationCompetencies(this.celebrationId, competency.id)
          .subscribe();
      });
    } else if (this.editCelebration && this.competencyList.length > 0) {
      this.competencyList.forEach((competency) => {
        if (this.editCelebration) {
          this.celebrationsService
            .updateCelebrationCompetencies(
              this.editCelebration.id,
              competency.id
            )
            .subscribe();
        }
      });
    }
  }

  uploadAttachments() {
    if (this.attachments.length > 0) {
      if (this.celebrationId) {
        this.attachments.forEach((attachment) => {
          const payload: CelebrationAttachmentPayload = {
            endorsement_id: this.celebrationId,
            attachment_id: attachment.id,
          };
          this.celebrationsService
            .updateCelebrationAttachments(payload)
            .subscribe(() => {
              this.finishCelebrationEdit();
            });
        });
      }
      if (this.editCelebration) {
        this.attachments.forEach((attachment) => {
          if (this.editCelebration) {
            const payload: CelebrationAttachmentPayload = {
              endorsement_id: this.editCelebration.id,
              attachment_id: attachment.id,
            };
            this.celebrationsService
              .updateCelebrationAttachments(payload)
              .subscribe(() => {
                this.finishCelebrationEdit();
              });
          }
        });
      }
    } else {
      this.finishCelebrationEdit();
    }
  }

  deleteAttachment(file: FileDTO) {
    this.attachments = this.attachments.filter(
      (attachment) => attachment.id !== file.id
    );
  }

  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
  }

  clearFields() {
    this.users = [];
    this.editor.editorContent = '';
    this.competencyList = [];
    this.competencySelect.reset();
    this.attachments = [];
  }
}
