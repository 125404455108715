import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import {
  Field,
  FieldDataFlexible,
  FieldDataType,
  FieldType,
  FieldWithChoices,
} from 'src/app/private/shared/dtos/forms.dto';
import { isChoiceField } from 'src/app/private/shared/helpers/forms.utiltiies';

import { AbstractViewFieldComponent } from '../base/abstract-view-field';
import { AbstractViewFieldChoicesComponent } from '../base/abstract-view-field-choices';
import {
  fieldDataCaster,
  fileFieldDataCaster,
  formIndicatorFieldDataCaster,
  stringArrayFieldDataCaster,
} from './ form-view.helpers';

@Component({
  selector: 'app-form-view-field',
  templateUrl: './form-view-field.component.html',
  styleUrls: ['./form-view-field.component.scss'],
})
export class FormViewFieldComponent implements OnInit {
  @ViewChild('fieldView') fieldView:
    | AbstractViewFieldComponent
    | AbstractViewFieldChoicesComponent;

  @Input() fieldDefinition: Field;

  @Input() inputDisabled = false;

  @Input() readOnly = false;

  @Input() inputData: FieldDataFlexible | null;

  fieldDefs: Field;

  fieldTypes = FieldType;

  fieldDataTypes = FieldDataType;

  fieldWithChoicesDefs: FieldWithChoices;

  stringCaster = fieldDataCaster;

  stringArrayCaster = stringArrayFieldDataCaster;

  indicatorCaster = formIndicatorFieldDataCaster;

  fileCaster = fileFieldDataCaster;

  @Output() readonly data = new ReplaySubject<FieldDataFlexible>();

  ngOnInit(): void {
    if (isChoiceField(this.fieldDefinition.type)) {
      this.fieldWithChoicesDefs = this.fieldDefinition as FieldWithChoices;
    } else {
      this.fieldDefs = this.fieldDefinition as Field;
    }
  }
}
