import {
  BadgeDataDTO,
  BadgeEarnedDTO,
} from 'src/app/private/shared/dtos/badge.dto';

export const MOCK_STRAND_BADGE_DTO: BadgeDataDTO = {
  id: 123,
  title: 'Strand Badge Title',
  level: 3,
  type: 'strand',
  icon: '/pathtofile',
  competency: 'Differentiation, Scaffolding',
  strand:
    'Best Practices for Blended Learning - Reflection, Growth Mindset,Level 3',
  rubric: 'e2L Best Practices for Blended Learning',
};

export const MOCK_STRAND_BADGE_EARNED_DTO: BadgeEarnedDTO = {
  userId: '1232',
  userName: 'Jim',
  badges: [MOCK_STRAND_BADGE_DTO],
};

export const MOCK_COMPETENCY_BADGE_DTO: BadgeDataDTO = {
  id: 456,
  title: 'Competency Badge Title',
  level: 3,
  type: 'competency',
  icon: 'assets/competency_icons/assessment.svg',
  competency: 'Differentiation, Scaffolding',
  strand: '',
  rubric: 'Best Practices',
};

export const MOCK_COMPETENCY_BADGE_EARNED_DTO: BadgeEarnedDTO = {
  userId: '1232',
  userName: 'Bobby',
  badges: [MOCK_COMPETENCY_BADGE_DTO],
};
