import { Component } from '@angular/core';
import {
  FieldType,
  TextFieldTypeMap,
} from 'src/app/private/shared/dtos/forms.dto';
import { FormFieldAbstractComponent } from '../base/edit-field-abstract';

@Component({
  selector: 'app-form-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['../edit-field/form-edit-field.component.scss'],
})
export class FormEditTextComponent extends FormFieldAbstractComponent {
  textFieldOptions = TextFieldTypeMap;

  typeChange(type: { label: string; value: FieldType }) {
    this.data.type = type.value;
    this.pushChange();
  }

  pushChange() {
    this.dataChange.emit(this.data);
  }
}
