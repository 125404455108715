<div class="card border border-secondary border-3 text-center p-3 h-100">
  <div class="card-body d-flex flex-column justify-content-center">
    <i
      (click)="addItem()"
      class="top-icon fs-25 bi bi-plus-circle add-icon"></i>
    <div
      class="top-icon level"
      style="color: #fff"
      [ngClass]="{
        'bg-level-1': level == 1,
        'bg-level-2': level == 2,
        'bg-level-3': level == 3,
        'bg-level-4': level == 4
      }">
      {{ level }}
    </div>
    <i class="bi bi-tools fs-35px"></i>
    <a
      target="blank"
      [href]="'resources/' + resource.slug"
      class="h5 fs-16 fw-bold title">
      {{ resource.title }}
    </a>
    <p class="card-text fs-11" [innerHTML]="description"></p>
  </div>
</div>
