<button type="button" class="btn btn-link" (click)="resourceFilterModal.open()">
  Edit Filters
</button>
<app-modal
  #resourceFilterModal
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    options: {
      size: 'xl',
    },
    customFooter: true,
      }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="resourceFilterModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>Resource Type</h2>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="typeRadioFilter"
            id="typeRadioFilter1"
            value="research"
            [(ngModel)]="editedFilters.type" />
          <label class="form-check-label" for="typeRadioFilter1">
            Research
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="typeRadioFilter"
            id="typeRadioFilter2"
            value="template"
            [(ngModel)]="editedFilters.type" />
          <label class="form-check-label" for="typeRadioFilter2">
            Template
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="typeRadioFilter"
            id="typeRadioFilter3"
            value="diy"
            [(ngModel)]="editedFilters.type" />
          <label class="form-check-label" for="typeRadioFilter3"> DIY </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="typeRadioFilter"
            id="typeRadioFilter4"
            value="workshop"
            [(ngModel)]="editedFilters.type" />
          <label class="form-check-label" for="typeRadioFilter4">
            Workshop
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="typeRadioFilter"
            id="typeRadioFilter5"
            value="video"
            [(ngModel)]="editedFilters.type" />
          <label class="form-check-label" for="typeRadioFilter5"> Video </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="typeRadioFilter"
            id="typeRadioFilter6"
            value=""
            [(ngModel)]="editedFilters.type" />
          <label class="form-check-label" for="typeRadioFilter6"> All </label>
        </div>
      </div>
      <div class="col">
        <h2>Created By</h2>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="createdByRadioFilter"
            id="createdByRadioFilter1"
            value="e2l"
            [(ngModel)]="editedFilters.created_by" />
          <label
            class="form-check-label text-nowrap"
            for="createdByRadioFilter1">
            e2L Admin
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="createdByRadioFilter"
            id="createdByRadioFilter2"
            value="district"
            [(ngModel)]="editedFilters.created_by" />
          <label
            class="form-check-label text-nowrap"
            for="createdByRadioFilter2">
            My District
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="createdByRadioFilter"
            id="createdByRadioFilter3"
            value="me"
            [(ngModel)]="editedFilters.created_by" />
          <label class="form-check-label" for="createdByRadioFilter3">
            Me
          </label>
        </div>

        <div class="form-check mb-3">
          <input
            class="form-check-input"
            type="radio"
            name="createdByRadioFilter"
            id="createdByRadioFilter4"
            value=""
            [(ngModel)]="editedFilters.created_by" />
          <label class="form-check-label" for="createdByRadioFilter4">
            Anyone
          </label>
        </div>
        <div>
          <div style="min-width: 180px">
            <app-user-select
              [clearAfterSelection]="true"
              [placeholder]="'Select user'"
              (selectedUser)="addUser($event)">
            </app-user-select>
          </div>
          <ng-container *ngIf="currentAuthors.length > 0">
            <div
              class="d-inline-block mt-1"
              *ngFor="let author of currentAuthors">
              <div class="badge badge-item" (click)="removeUser(author)">
                <span class="text-nowrap">{{ author.name }}</span>
                <i class="bi bi-x pt-1 ml-1"></i>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col">
        <h2>Tags</h2>
        <div style="min-width: 180px">
          <app-select-tags
            [clearAfterSelect]="true"
            [placeholder]="'Select tag'"
            (afterSelection)="addTag($event)">
          </app-select-tags>
        </div>
        <ng-container *ngIf="currentTags.length > 0">
          <div class="d-inline-block mt-1" *ngFor="let tag of currentTags">
            <div class="badge badge-item" (click)="removeTag(tag.tag)">
              <span class="text-nowrap">{{ tag.tag }}</span>
              <i class="bi bi-x pt-1 ml-1"></i>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col">
        <h2>Competency</h2>
        <div style="max-width: 200px">
          <app-competency-select
            #selectCompetency
            [districtIds]="districtIds"
            [clearAfterSelection]="false"
            (selectedCompetency)="addCompetency($event)"
            [placeholder]="'Select competency'" />
        </div>
      </div>
      <div class="col">
        <h2>Level</h2>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="levelCheckboxFilter"
            id="levelCheckboxFilter1"
            value="1"
            [checked]="editedFilters.levels?.includes(1)"
            (change)="onCheckboxChange($event)" />
          <label class="form-check-label" for="levelCheckboxFilter1">
            Level 1
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="levelCheckboxFilter"
            id="levelCheckboxFilter2"
            value="2"
            [checked]="editedFilters.levels?.includes(2)"
            (change)="onCheckboxChange($event)" />
          <label class="form-check-label" for="levelCheckboxFilter2">
            Level 2
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="levelCheckboxFilter"
            id="levelCheckboxFilter3"
            value="3"
            [checked]="editedFilters.levels?.includes(3)"
            (change)="onCheckboxChange($event)" />
          <label class="form-check-label" for="levelCheckboxFilter3">
            Level 3
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="levelCheckboxFilter"
            id="levelCheckboxFilter4"
            value="4"
            [checked]="editedFilters.levels?.includes(4)"
            (change)="onCheckboxChange($event)" />
          <label class="form-check-label" for="levelCheckboxFilter4">
            Level 4
          </label>
        </div>
      </div>
    </div>
  </div>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="resetFilters()">
        Clear All
      </button>
      <button
        type="button"
        class="btn btn-primary"
        id="apply-button"
        (click)="applyFilters()">
        Apply
      </button>
    </div>
  </div>
</app-modal>
