<div class="container">
  <div
    class="card border-0 p-3"
    [class.edit]="editMode"
    style="min-height: 500px">
    <div class="card-body">
      <ng-container *ngIf="!resourceErrorMessage; else errorMessageTemplate">
        <ng-container *ngIf="currentResource; else loadingTemplate">
          <div class="row gx-5">
            <div class="col-lg-8 mb-4">
              <ng-container
                *ngIf="isOwnerOrEditor && editMode; else viewTitleTemplate">
                <input
                  placeholder="Resource Title"
                  class="form-control title-input"
                  id="title"
                  [(ngModel)]="currentResource.title"
                  appAutosave
                  (autosave)="saveTitle()" />
              </ng-container>
              <ng-template #viewTitleTemplate>
                <h1 class="d-flex justify-content-start align-items-center">
                  <span class="me-3"> {{ currentResource.title }}</span
                  ><app-folder-bookmark
                    [itemType]="'elearn_resource'"
                    [itemId]="currentResource.id"
                    [itemInAFolder]="
                      currentResource.isOnUsersShelf
                    "></app-folder-bookmark>
                </h1>
              </ng-template>
              <hr class="border border-2 opacity-50" />
              <div
                *ngIf="currentResource.lock_user_id && isLocked"
                class="lock-notification small m-3 text-center">
                Read only version - Resource content locked by
                <app-users-name [userId]="currentResource.lock_user_id" />
                <span *ngIf="currentResource.lock_expires">
                  (expires at
                  {{
                    currentResource.lock_expires * 1000 | date : "shortTime"
                  }})
                </span>
              </div>

              <app-resource-content
                [docId]="currentResource.id"
                [isAllowed]="isOwnerOrEditor && !isLocked"
                [editMode]="editMode"></app-resource-content>

              <ng-container
                *ngIf="isOwnerOrEditor && editMode; else viewAttachments">
                <div class="mt-3">
                  <app-file-list
                    #fileListComponent
                    (deletedFile)="deleteAttachment($event)"
                    [fileList]="attachments" />
                </div>
                <div class="mt-3">
                  <app-file-upload
                    #uploadComponent
                    [style]="uploadStyle"
                    [uploadType]="uploadType"
                    (outputFiles)="updateAttachments($event)" />
                </div>
              </ng-container>
              <ng-template #viewAttachments>
                <ng-container *ngIf="attachments.length > 0">
                  <app-file-list [canDelete]="false" [fileList]="attachments" />
                </ng-container>
              </ng-template>
            </div>
            <div class="col-lg-4">
              <section *ngIf="isOwnerOrEditor">
                <div
                  class="btn-group btn-group-sm d-block text-center mb-2"
                  role="group"
                  aria-label="Basic radio toggle button group">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="editMode"
                    autocomplete="off"
                    [checked]="editMode"
                    (click)="editMode ? '' : toggleEditMode()" />
                  <label class="btn btn-outline-primary" for="editMode"
                    ><i
                      *ngIf="
                        editMode &&
                        isLocked &&
                        user &&
                        currentResource.lock_user_id != user.id
                      "
                      class="bi bi-lock me-1"></i
                    >Edit Mode</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="viewMode"
                    autocomplete="off"
                    [checked]="!editMode"
                    (click)="!editMode ? '' : toggleEditMode()" />
                  <label class="btn btn-outline-primary" for="viewMode"
                    >View Mode</label
                  >
                </div>
              </section>

              <section>
                <h2 class="fs-25 fw-800 mt-4">Description</h2>
                <ng-container
                  *ngIf="
                    isOwnerOrEditor && editMode;
                    else viewDescriptionTemplate
                  ">
                  <textarea
                    aria-label="Resource description"
                    placeholder="Description of Resource"
                    #description="ngModel"
                    id="description"
                    name="description"
                    class="form-control"
                    rows="3"
                    appAutosave
                    [(ngModel)]="currentResource.description"
                    (autosave)="saveDescription()"
                    [ngClass]="{
                      'is-invalid':
                        description.invalid &&
                        (description.dirty || description.touched)
                    }"
                    required></textarea>
                </ng-container>
                <ng-template #viewDescriptionTemplate>
                  <p>{{ currentResource.description }}</p>
                </ng-template>
              </section>

              <section>
                <h2 class="fs-25 fw-800 mt-4">Competencies</h2>
                <ng-container *ngIf="isOwnerOrEditor && editMode">
                  <ng-container
                    *ngIf="
                      currentResource.has_elearn_manage_resource_standards_perm
                    ">
                    <app-competency-select
                      [districtIds]="districtIds"
                      [clearAfterSelection]="true"
                      [isMulti]="false"
                      [hideTags]="true"
                      [placeholder]="'Search Competencies'"
                      (selectedCompetency)="updateCompetenciesToAdd($event)" />
                    <div style="clear: both; height: 8px"></div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="invalidSelection">
                  <div class="validation-text">
                    <ng-container *ngIf="selectionNotAllowed">
                      You do not have permission to add this competency.
                    </ng-container>
                    <ng-container *ngIf="selectionAlreadyAdded">
                      This competency has already been added.
                    </ng-container>
                  </div>
                </ng-container>
                <div style="clear: both; height: 4px"></div>

                <app-leveled-resource-competencies
                  #leveledResourceCompetencies
                  [resourceId]="currentResource.id"
                  [competenciesToAdd]="competenciesToAdd"
                  [resourceCompetencies]="resourceCompetencies"
                  [hasEditPermissions]="
                    isOwnerOrEditor &&
                    editMode &&
                    currentResource.has_elearn_manage_resource_standards_perm
                  "
                  (sendCompetenciesToAdd)="refreshCompetenciesToAdd($event)"
                  (sendResourceCompetencies)="
                    refreshResourceCompetencies($event)
                  " />
              </section>

              <section>
                <h2 class="fs-25 fw-800 mt-4">Type</h2>
                <ng-container
                  *ngIf="isOwnerOrEditor && editMode; else viewTypeTemplate">
                  <section class="resource-type">
                    <div name="type" class="radio-group">
                      <div class="radio-item">
                        <input
                          type="radio"
                          [(ngModel)]="currentResource.type"
                          (ngModelChange)="saveType()"
                          id="test1"
                          name="type"
                          value="research" />
                        <label for="test1">
                          <i class="type-ico bi bi-lightbulb"></i
                          >RESEARCH</label
                        >
                      </div>
                      <div class="radio-item">
                        <input
                          type="radio"
                          [(ngModel)]="currentResource.type"
                          (ngModelChange)="saveType()"
                          id="test2"
                          name="type"
                          value="diy" />
                        <label for="test2"
                          ><i class="type-ico bi bi-tools"></i>DIY
                          ACTIVITY</label
                        >
                      </div>
                      <div class="radio-item">
                        <input
                          type="radio"
                          [(ngModel)]="currentResource.type"
                          (ngModelChange)="saveType()"
                          id="test3"
                          name="type"
                          value="workshop" />
                        <label for="test3"
                          ><i class="type-ico bi bi-people"></i>WORKSHOP</label
                        >
                      </div>
                      <div class="radio-item">
                        <input
                          type="radio"
                          [(ngModel)]="currentResource.type"
                          (ngModelChange)="saveType()"
                          id="test4"
                          name="type"
                          value="template" />
                        <label for="test4"
                          ><i class="type-ico bi bi-briefcase"></i
                          >TEMPLATE</label
                        >
                      </div>
                      <div class="radio-item">
                        <input
                          type="radio"
                          [(ngModel)]="currentResource.type"
                          (ngModelChange)="saveType()"
                          id="test5"
                          name="type"
                          value="video" />
                        <label for="test5"
                          ><i class="type-ico bi bi-camera-reels"></i
                          >VIDEO</label
                        >
                      </div>
                    </div>
                    <div>&nbsp;</div>
                  </section>
                </ng-container>
                <ng-template #viewTypeTemplate>
                  <p>{{ currentResource.type | titlecase }} Resource</p>
                </ng-template>
              </section>

              <section>
                <h2 class="fs-25 fw-800 mt-4">Created By</h2>
                <div *ngIf="currentResource?.creator_user_id">
                  <app-users-name
                    [userId]="currentResource.creator_user_id"></app-users-name>
                </div>
              </section>

              <section>
                <h2 class="fs-25 fw-800 mt-4">Last updated</h2>
                <div>
                  {{
                    currentResource.updated_at
                      | showDateTimezone : "MMM d, yyyy" : undefined : false
                  }}
                </div>
              </section>

              <h2 class="fs-25 fw-800 mt-4">Tags</h2>
              <app-resource-tags
                [readOnly]="!isOwnerOrEditor || !editMode"
                [tagList]="currentResource.tags"
                [refTableId]="currentResource.id">
              </app-resource-tags>

              <ng-container *ngIf="isOwnerOrEditor && editMode">
                <div
                  class="d-flex justify-content-between align-items-center mt-5">
                  <button
                    aria-label="delete button"
                    class="btn btn-txt-delete"
                    type="button"
                    [autoClose]="'outside'"
                    [ngbPopover]="deletePopoverContent"
                    [popoverTitle]="'Delete Resource'"
                    #popover="ngbPopover">
                    Delete
                  </button>
                  <ng-template #deletePopoverContent>
                    Permanently delete this resource?
                    <div class="text-end mt-2">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm px-3 m-1"
                        (click)="popover.close()">
                        No
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm px-3 m-1"
                        (click)="
                          popover.close(); deleteResource(currentResource.id)
                        ">
                        Yes
                      </button>
                    </div>
                  </ng-template>
                  <button
                    (click)="permissionsModal.open()"
                    class="btn btn-primary btn-next">
                    <span *ngIf="isPublished; else published"
                      >Share Settings</span
                    >
                    <ng-template #published>Publish</ng-template>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="loading-wrapper">
    <div class="w-100" style="background-color: #fff; height: 700px">
      <app-loader></app-loader>
    </div>
  </div>
</ng-template>

<ng-template #errorMessageTemplate>
  <app-error-message [message]="resourceErrorMessage" />
</ng-template>

<app-modal
  #permissionsModal
  [modalConfig]="{
    titleText: 'Share Settings',
    headerClass: [
      'modal-header',
      'bg-primary',
      'text-white',
      'share-settings-header'
    ],
    windowClass: 'resource-share-modal',
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="permissionsModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="isOwnerOrEditor && editMode">
    <div class="sharing-container">
      <resource-access-rules [canManage]="true"></resource-access-rules>
    </div>
  </ng-container>
  <div footerContent>
    <button
      (click)="shareSettings()"
      class="sharing-done-button btn btn-primary">
      Done
    </button>
  </div>
</app-modal>
