<div class="competency-level">
  <div class="card h-100 border-0 shadow" *ngIf="strand">
    <div
      class="d-flex"
      [ngClass]="[
        'bg-level-' + level,
        'card-header',
        'text-light',
        'text-end',
        'text-nowrap',
        'align-middle'
      ]">
      <div class="ms-auto">
        Level
        <i class="bi bi-{{ level }}-circle"></i>
      </div>
    </div>
    <div class="card-body p-2" tabindex="0">
      <app-safehtml [html]="strand.indicator.content" />
    </div>
  </div>
</div>
