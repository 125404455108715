import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appAutosave]',
})
export class AutosaveDirective implements OnInit, OnDestroy {
  @Input() delayTime = 500;

  @Output() readonly autosave = new EventEmitter();

  private changes = new Subject();

  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.changes
      .pipe(debounceTime(this.delayTime))
      .subscribe((e) => this.autosave.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // TODO: This type is knowable
  @HostListener('input', ['$event'])
  @HostListener('change', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.changes.next(event);
  }
}
