<ng-container *ngIf="!readOnly; else readOnlyTemplate">
  <div id="resource-tags">
    <div class="d-flex">
      <ng-select
        class="flex-grow-1"
        [items]="availTags"
        [addTag]="true"
        [hideSelected]="true"
        [multiple]="true"
        [closeOnSelect]="false"
        typeToSearchText="Please enter 2 or more characters"
        [loading]="tagsLoading"
        [(ngModel)]="selectedTags"
        placeholder="Select or create tags"
        #select>
        <ng-template let-item="item" let-index="index" let-search="term">
          {{ item }}
        </ng-template>
      </ng-select>
      <button
        class="btn-addtag btn btn-primary btn-sm"
        [disabled]="!selectedTags || selectedTags.length < 1"
        (click)="addTags()">
        <i class="bi bi-plus-circle fs-6 tool"></i>
      </button>
    </div>
    <ng-container *ngIf="!tagsLoading">
      <div class="editable-tag-list my-3">
        <ng-container *ngFor="let tag of tagList">
          <span
            (click)="deleteTag(tag.id)"
            class="badge badge-editable me-2 mb-2"
            >{{ tag.tag }} <i class="type-ico bi bi-x"></i
          ></span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #readOnlyTemplate>
  <ng-container *ngFor="let tag of tagList">
    <span class="badge me-2 mb-2">{{ tag.tag }}</span>
  </ng-container>
</ng-template>
