export interface VideoLibraryFiltersDTO {
  per_page?: number;
  page?: number;
  search?: string;
  category?: number;
  user?: string;
  role?: string | null;
  length?: VideoLimitLength;
}

export enum VideoRole {
  COACH = 'Coach',
  TEACHER = 'Teacher',
}

export enum VideoLimitLength {
  FIVE = 5,
  TEN = 10,
  FIFTEEN = 15,
  TWENTY = 20,
}

export interface VideoLimit {
  number: number;
  label: string;
}
