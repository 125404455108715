<input
  #fileUpload
  type="file"
  class="file-input"
  accept=".csv"
  (change)="onFileChange($event)" />

<div
  class="file-upload p-3 mt-5 mb-3 text-center rounded"
  [ngClass]="[isUploading ? 'disabled' : '', dropzoneActive ? 'dropzone' : '']"
  (dragleave)="($event.preventDefault); toggleDropZone(false); (false)"
  (dragover)="($event.preventDefault); toggleDropZone(true); (false)"
  (dragend)="($event.preventDefault); toggleDropZone(false); (false)"
  (drop)="handleDrop($event); (false)"
  (click)="onUploadButtonClick()">
  <app-file-list></app-file-list>

  <p class="color-dark-primary fw-bold">
    Click here or drag file here to upload
  </p>
  <div class="btn btn-primary">
    <i *ngIf="!isUploading" class="bi bi-upload me-2"></i
    ><span
      *ngIf="isUploading"
      class="spinner-border spinner-border-sm me-2"></span
    >Upload
  </div>
</div>
<ngb-alert *ngIf="errorMsg" [type]="'danger'" (closed)="closeErrorMsg()">{{
  errorMsg
}}</ngb-alert>
<ngb-alert *ngIf="successMsg" [type]="'success'" (closed)="closeSuccessMsg()">{{
  successMsg
}}</ngb-alert>
<div *ngIf="uploadingItems" class="uploadItems my-2">
  <div class="row" *ngFor="let item of uploadingItems | keyvalue">
    <div class="col-xs-12">
      {{ item.value.name }}
      <ngb-progressbar
        [value]="item.value.progress"
        [animated]="true"></ngb-progressbar>
    </div>
  </div>
</div>
