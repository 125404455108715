import { ResourceFiltersDTO } from '../../dtos/resources.dto';

export const defaultSearchFilterFreeze = (): ResourceFiltersDTO => ({
  created_by: '',
  type: '',
  order: 'search_score',
  authors: [],
  tags: [],
  competencies: [],
  levels: [],
});
