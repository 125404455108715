<div
  *ngIf="displayCoach"
  id="smartcoach-dialog-container"
  [ngClass]="isDisplaying ? 'display-messages' : ''">
  <span *ngIf="currentMessages.length > 0" class="notification"></span>
  <app-avatar
    id="coach-dialog-avatar"
    (click)="isDisplaying = !isDisplaying"
    [profile]="coachProfile"
    [avatarSize]="'large'"></app-avatar>
  <div
    *ngIf="currentMessages.length > 0"
    id="messages-container"
    class="p-3 fw-200 fs-16">
    <a
      aria-label="close-coach-dialog-button"
      href="javascript:void(0)"
      id="close-coach-dialog"
      (click)="isDisplaying = !isDisplaying">
      <i class="bi bi-x-circle collapse-icon p-1"></i>
    </a>
    <ng-template
      ngFor
      let-message
      [ngForOf]="currentMessages"
      let-index="index">
      <div
        #message
        [ngClass]="[currentMessageIdx === index ? 'current' : '', 'message']">
        <div class="message-text {{ dialogClass }}">
          <app-safehtml [html]="message.message"></app-safehtml>
        </div>
      </div>
    </ng-template>
    <!-- pagination -->
    <div
      id="messages-nav"
      class="d-flex justify-content-center align-items-baseline mt-2"
      *ngIf="currentMessages.length > 1">
      <a
        aria-label="coach-dialog-nav-left"
        href="javascript:void(0)"
        class="nav-arrow nav-el"
        (click)="previousMessage()"
        ><i class="bi bi-chevron-left"></i
      ></a>
      <a
        *ngFor="let message of currentMessages; let i = index"
        aria-label="coach-dialog-nav-direct"
        href="javascript:void(0)"
        (click)="goToMessage(i)"
        [ngClass]="[
          currentMessageIdx === i ? 'current' : '',
          'nav-el',
          'nav-dot',
          'mx-1'
        ]"
        title="Select message"></a>
      <a
        aria-label="coach-dialog-nav-right"
        href="javascript:void(0)"
        (click)="nextMessage()"
        class="nav-arrow nav-el"
        ><i class="bi bi-chevron-right"></i
      ></a>
    </div>
  </div>
</div>
