<div class="container">
  <app-general-header title="Implementation Plan" />
  <a routerLink="/plans" class="text-decoration-none text-body-secondary"
    >&lt; Back to Plans</a
  >
  <ng-container *ngIf="!forbidden; else deniedMessage">
    <div class="row mt-2 mb-4">
      <div
        *ngIf="planDashboardInfo?.title; else noTitle"
        class="col text-center">
        <div class="d-flex justify-content-center align-items-center">
          <h1 class="fw-600">
            {{ planDashboardInfo.title }}
          </h1>
        </div>
        <div>
          {{ planDashboardInfo.start_date }} -
          {{ planDashboardInfo.end_date }}
        </div>
      </div>
      <ng-template #noTitle>
        <div style="height: 7.5rem"></div>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-12 col-xl-8">
        <div class="card bg-none border-0">
          <ul *ngIf="planDashboardInfo?.title" class="nav nav-tabs border-0">
            <li class="nav-item">
              <a
                href="javascript:void()"
                class="nav-link border-0"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                [routerLink]="
                  '/plans/implementation-plan/' +
                  planDashboardInfo.id +
                  '/dashboard'
                ">
                Plan Dashboard
              </a>
            </li>
            <li class="nav-item">
              <a
                href="javascript:void()"
                class="nav-link border-0"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                [routerLink]="
                  '/plans/implementation-plan/' +
                  planDashboardInfo.id +
                  '/details'
                ">
                Plan Details
              </a>
            </li>
          </ul>
          <app-phase-plan-status-card [dashboardInfo]="planDashboardInfo" />
        </div>
        <app-phase-progress-card [dashboardInfo]="planDashboardInfo" />
      </div>
      <div class="custom-margin-top col-12 col-xl-4 mt-4">
        <app-phase-risk-delays-card
          *ngIf="
            planDashboardInfo &&
            planDashboardInfo.items_that_need_attention.length
          "
          [items]="planDashboardInfo.items_that_need_attention" />
        <app-card-activity-feed
          *ngIf="planDashboardInfo"
          [activityStreamToken]="planDashboardInfo.getstream_token"
          [planId]="planDashboardInfo.id"
          [districtTimezone]="user?.district?.timezone" />
      </div>
    </div>
  </ng-container>
  <ng-template #deniedMessage>
    <div class="mt-5">
      <app-permission-denied />
    </div>
  </ng-template>
  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
