import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import {
  compareRoles,
  UserRole,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  CelebrationDTO,
  CelebrationFilterDTO,
} from '../../dtos/celebrations.dto';
import { NewCoachingSessionUserDto } from '../../dtos/coaching-session.dto';
import { CelebrationsService } from '../../services/celebrations/celebrations.service';
import { ConfettiService } from '../../services/confetti/confetti.service';
import { CreateQuickWalkModalComponent } from '../create-quick-walk-modal/create-quick-walk-modal.component';
import { CreateEditCelebrationModalComponent } from '../modals/create-edit-celebration-modal/create-edit-celebration-modal.component';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  @Input() dashboardType: string;

  @Input() showSwitcher = true;

  @Input() celebrateFromCoacheeList = false;

  @Input() selectedCoachees: NewCoachingSessionUserDto[] | null;

  @ViewChild('createEditCelebrationModal')
  createEditCelebrationModal: CreateEditCelebrationModalComponent;

  @ViewChild('celebrationPopover') celebrationPopover: NgbPopover;

  @ViewChild('createQuickWalkModal')
  createQuickWalkModal: CreateQuickWalkModalComponent;

  user: User;

  canCreateLog = false;

  show_dashboard_type = !EnvironmentService.isProduction();

  dayOfWeek = new Date().toLocaleDateString('en-US', { weekday: 'long' });

  celebrations: CelebrationDTO[] | null = null;

  celebrationFilters: CelebrationFilterDTO = {
    fromDate: null,
    toDate: null,
    hasAttachment: 0,
    school: null,
    district: null,
    ownOnly: 1,
    coacheeList: [],
  };

  constructor(
    private celebrationService: CelebrationsService,
    private confettiService: ConfettiService,
    private store: Store
  ) {
    this.user = store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.getCelebrations();
    this.canCreateLog = compareRoles([UserRole.COACH], this.user.roles);
  }

  openCelebrationModal() {
    this.createEditCelebrationModal.openModal();
    this.celebrationPopover.close();
  }

  handleCreateCelebrationEvent() {
    this.getCelebrations();
  }

  getCelebrations() {
    this.celebrationFilters.perPage = 5;
    this.celebrationService
      .getFilteredCelebrations(this.celebrationFilters)
      .subscribe((res) => {
        if (res) {
          this.celebrations = res.items;
          this.celebrations.reverse();
        }
      });
  }

  cueConfetti() {
    this.confettiService.showConfetti('top');
  }

  openQuickWalkModal() {
    this.createQuickWalkModal.openModal();
  }
}
