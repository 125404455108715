import { CohortDTO, CohortListDTO } from 'src/app/common/dtos/cohort.dto';
import {
  CohortAPIResponse,
  CohortListAPIResponse,
} from '../../types/responses/cohort.responses';

export const cohortDTOFromApiResponse = (
  item: CohortAPIResponse
): CohortDTO => ({
  id: item.id,
  tag: item.tag,
  slug: item.slug,
  group: item.group,
  refTable: item.ref_table,
  refTableId: item.ref_table_id,
  createdAt: item.created_at,
  updatedAt: item.updated_at,
  userCount: item.userCount,
});

export const cohortListDTOFromApiResponse = (
  response: CohortListAPIResponse
): CohortListDTO => ({
  items: response.items.map((item) => cohortDTOFromApiResponse(item)),
  _meta: response._meta,
});
