<input
  *ngIf="!multiple"
  #fileUpload
  type="file"
  class="file-input"
  (change)="onFileChange($event)" />
<input
  #fileUpload
  *ngIf="multiple"
  type="file"
  class="file-input"
  (change)="onFileChange($event)"
  multiple />
<div
  [ngClass]="[
    'file-upload',
    'style-' + style,
    'size-' + size,
    'p-3',
    'text-center',
    'rounded-1',
    isUploading ? 'disabled' : '',
    dropzoneActive ? 'dropzone' : '',
    size === 'sm' ? 'fs-12' : ''
  ]"
  (dragleave)="($event.preventDefault); toggleDropZone(false); (false)"
  (dragover)="($event.preventDefault); toggleDropZone(true); (false)"
  (dragend)="($event.preventDefault); toggleDropZone(false); (false)"
  (drop)="handleDrop($event); (false)"
  (click)="onUploadButtonClick()">
  <app-file-list></app-file-list>
  <p
    *ngIf="style !== styles.NONE"
    [ngClass]="[
      style == styles.SIMPLE || style == styles.EVIDENCE
        ? 'color-dark-gray'
        : 'color-primary',
      'fw-bold'
    ]">
    Click here or drag file<span *ngIf="multiple">(s)</span> here to upload
    <span>{{ customMessageAddOn }}</span>
  </p>
  <div *ngIf="style == styles.FANCY" class="btn btn-primary">
    <i class="bi bi-upload me-1"></i>Upload
  </div>
  <div
    *ngIf="style == styles.BADGE"
    class="btn btn-reset {{ size === 'sm' ? 'fs-12' : '' }}">
    <i [ngbTooltip]="tooltipMessage" class="bi bi-upload"></i>
  </div>
  <div
    *ngIf="style == styles.SIMPLE || style == styles.EVIDENCE"
    class="btn btn-tertiary">
    <i [ngbTooltip]="tooltipMessage" class="bi bi-upload"></i>
  </div>
  <div *ngIf="style == styles.NONE" class="btn btn-reset">
    <i [ngbTooltip]="tooltipMessage" class="bi bi-upload"></i>
  </div>
</div>
<ng-container *ngIf="errorMsg">
  <ngb-alert [type]="'danger'" (closed)="this.errorMsg = ''">{{
    errorMsg
  }}</ngb-alert>
</ng-container>
<div *ngIf="uploadingItems" class="uploadItems">
  <div class="row" *ngFor="let item of uploadingItems | keyvalue">
    <div class="col-xs-12">
      {{ item.value.name }}
      <ngb-progressbar
        [value]="item.value.progress"
        [animated]="true"></ngb-progressbar>
    </div>
  </div>
</div>
