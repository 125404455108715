import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { getSessionLink } from 'src/app/private/shared/helpers/coachee-log.utilities';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { UserRole } from '../../state/user/role/user-role';
import { checkRouteEnvironment } from '../../utilities/check-route';

function navigateToUserTypeDashboard(
  user: User | null,
  router: Router
): boolean {
  if (user) {
    router.navigate([`/dashboard/${user.type}`]);
    return false;
  }
  router.navigate(['/login']);
  return false;
}

function navigateToUserDistrictSettings(
  user: User | null,
  router: Router
): boolean {
  if (user && !user?.roles.includes(UserRole.E2L_EMPLOYEE)) {
    router.navigate([`/settings/districts/${user.district?.districtCode}`]);
    return false;
  }
  return true;
}

export const RouteGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: RouterStateSnapshot
): boolean => {
  let user: User | null = null;

  const store = inject(Store);
  const router = inject(Router);
  const coachingLogService = inject(CoachingLogService);

  if (!checkRouteEnvironment(next.url[0].path)) {
    // should eventually catch the "not authorized" page
    router.navigate(['/dashboard']);
    return false;
  }
  user = store.selectSnapshot(UserState.getUser);
  if (next.url.length === 1 && next.url[0].path === 'dashboard') {
    return navigateToUserTypeDashboard(user, router);
  }
  if (next.url.length === 1 && next.url[0].path === 'settings') {
    return navigateToUserDistrictSettings(user, router);
  }
  if (
    next.url.length > 2 &&
    next.url.length === 3 &&
    next.url[1].path === 'log' &&
    (next.url[0].path === 'observation' || next.url[0].path === 'coaching')
  ) {
    router.navigate([
      ...next.url.map((section: UrlSegment) => section.path),
      'info',
    ]);
  }
  if (
    next.url.length > 1 &&
    next.url.length === 2 &&
    next.url[0].path === 'session-routing'
  ) {
    coachingLogService
      .getSession(parseInt(next.url[1].path))
      .subscribe((res) => {
        if (res.item) {
          router.navigate([getSessionLink(res.item)]);
        }
      });
  }
  return true;
};
