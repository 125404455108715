import { Component, OnInit } from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { EvidenceAssessedDTO } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { EvidenceDTO } from '../../../dtos/attendee-rubric.dto';
import { evidenceWithCompetencyDTOFromAPIResponse } from '../../../helpers/translators/evidence.translators';
import { EvidenceService } from '../../../services/evidence/evidence.service';

@Component({
  selector: 'app-card-evidence-for-review',
  templateUrl: './card-evidence-for-review.component.html',
  styleUrls: ['./card-evidence-for-review.component.scss'],
})
export class CardEvidenceForReviewComponent implements OnInit {
  isCollapsed = this.cardStorageService.getCollapsedState('evidenceforreview');

  assignedUsersEvidence: UserEvidenceDTO[];

  listMeta: PaginationDTO;

  constructor(
    private evidenceService: EvidenceService,
    private cardStorageService: CardStorageService
  ) {}

  ngOnInit(): void {
    this.evidenceService.getAllCoacheeEvidenceList().subscribe((response) => {
      this.listMeta = response._meta;

      this.assignedUsersEvidence = [];
      response.items.forEach((item) => {
        if (item.evidence.assessments.length === 0) {
          const evidenceObject = {} as UserEvidenceDTO;
          evidenceObject.profile = item.user.profile;
          evidenceObject.evidence = evidenceWithCompetencyDTOFromAPIResponse(
            item.evidence
          );
          this.assignedUsersEvidence.push(evidenceObject);
        }
      });
      this.assignedUsersEvidence.sort((a, b) => {
        if (a.evidence.createdAt && b.evidence.createdAt) {
          return b.evidence.createdAt - a.evidence.createdAt;
        }
        return 0;
      });
    });
  }

  removeEvidence(response: EvidenceAssessedDTO) {
    this.assignedUsersEvidence = this.assignedUsersEvidence.filter(
      (x) => x.evidence.id !== response.egrowe_evidence_id
    );
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'evidenceforreview',
      this.isCollapsed
    );
  }
}

interface UserEvidenceDTO {
  evidence: EvidenceDTO;
  profile: ProfileDTO;
}
