import { Injectable } from '@angular/core';
import { APICoreService } from '../../../../common/services/api-core/api-core.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private apiService: APICoreService) {}

  getNotifications(unread = true) {
    return this.apiService.getRequest(
      `growelab/notifications/${unread ? 'unread' : ''}`
    );
  }

  markNotificationAsRead(notificationIds: number[]) {
    return this.apiService.putRequest('growelab/notifications/read', {
      id: notificationIds,
    });
  }
}
