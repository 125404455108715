import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import {
  CoachingSessionTypeDTO,
  NewCoachingSessionItemsPayloadDTO,
  NewCoachingSessionUserDto,
} from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { LogTypeSearchService } from '../../services/log-type-search/log-type-search.service';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-create-quick-walk-modal',
  templateUrl: './create-quick-walk-modal.component.html',
  styleUrl: './create-quick-walk-modal.component.scss',
})
export class CreateQuickWalkModalComponent implements OnInit, OnDestroy {
  @ViewChild('createQWSessionModal')
  createQWSessionModal: ModalComponent;

  startTime: DateTime;

  endTime: DateTime;

  isPrivate? = false;

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO = {
    title: '',
    logType: null,
    startDatetime: 0,
    endDatetime: 0,
    timezone: TimezoneLinkBack.Central,
    coachUser: null,
    creatorUserId: 0,
    coachees: [],
    shadowers: [],
    is_private: false,
  };

  user: User | null = null;

  formSubmitted = false;

  formValid = true;

  selectedTimezone = new BehaviorSubject<TimezoneLinkBack>(
    TimezoneLinkBack.Central
  );

  selectedTimezone$ = this.selectedTimezone.asObservable();

  subscriptions: Subscription[] = [];

  isLoading = false;

  serverError = '';

  districtId: number | null = null;

  includeAllB2bDistricts = false;

  selectedAttendee: NewCoachingSessionUserDto | null;

  defaultLogList: CoachingSessionTypeDTO[];

  selectedLogType: CoachingSessionTypeDTO | null;

  constructor(
    private coachingLogService: CoachingLogService,
    private store: Store,
    private logTypeSearch: LogTypeSearchService,
    private router: Router
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    if (this.user) {
      if (this.user.district) {
        this.districtId = this.user.district.id;
        if (this.user.district.isB2b === 1) {
          this.includeAllB2bDistricts = true;
        }
      }
      this.isPrivate = this.user.district?.defaultPrivateLogs;
      this.getDefaultLogTypes();
      this.initializeNewCoachlogPayload();
    }

    this.subscriptions.push(
      this.selectedTimezone.subscribe((timezone) => {
        this.coachlogItemsPayload.timezone = timezone;
      })
    );
  }

  getDefaultLogTypes() {
    if (this.districtId) {
      this.logTypeSearch
        .getLogTypes(this.districtId)
        .pipe(
          map((logTypes) => {
            if (logTypes) {
              this.defaultLogList = [];
              logTypes.forEach((logType) => {
                if (logType.isClassroomVisit) {
                  this.defaultLogList.push(logType);
                }
              });
              if (this.defaultLogList.length > 0) {
                this.logTypeSelected(this.defaultLogList[0]);
              }
            }
          })
        )
        .subscribe();
    }
  }

  initializeNewCoachlogPayload() {
    let userId = 0;
    if (this.user) {
      userId = this.user.id;
    }
    this.coachlogItemsPayload = {
      title: 'Quick Walk',
      logType: null,
      startDatetime: 0,
      endDatetime: 0,
      coachUser: null,
      timezone: TimezoneLinkBack.Central,
      creatorUserId: userId,
      coachees: [],
      shadowers: [],
      is_private: false,
    };
    this.coachlogItemsPayload.coachUser = this.user;
  }

  openModal() {
    this.setQuickWalkDateTime();
    this.logTypeSelected(this.defaultLogList[0]);
    this.createQWSessionModal.open();
  }

  setQuickWalkDateTime() {
    this.startTime = DateTime.now();
    this.endTime = this.startTime.plus({ minutes: 30 });
    const time = this.startTime.toLocaleString(DateTime.TIME_SIMPLE);
    this.coachlogItemsPayload.title = `Quick Walk ${this.startTime.month}/${this.startTime.day}/${this.startTime.year} @ ${time}`;
  }

  closeModal() {
    this.clearOutNewCoachlogPayload();
    this.formSubmitted = false;
    this.isLoading = false;
    this.serverError = '';
    this.selectedAttendee = null;
    this.isPrivate = this.user?.district?.defaultPrivateLogs
      ? this.user?.district?.defaultPrivateLogs
      : false;
    if (this.createQWSessionModal) {
      this.createQWSessionModal.close();
    }
  }

  clearOutNewCoachlogPayload() {
    this.coachlogItemsPayload.title = '';
    this.coachlogItemsPayload.logType = null;
    this.coachlogItemsPayload.is_private = false;
    this.coachlogItemsPayload.coachees = [];
  }

  addSelectedAttendee(coachee: UserLiteDTO | null) {
    if (coachee) {
      const user: NewCoachingSessionUserDto = {
        id: coachee.id,
        profile: coachee.profile,
      };
      this.selectedAttendee = user;
    }
  }

  removeSelectedAttendee() {
    this.selectedAttendee = null;
  }

  validateForm() {
    this.formValid = true;
    if (!this.selectedAttendee || !this.coachlogItemsPayload.title) {
      this.formValid = false;
    }
  }

  logTypeSelected(logType: CoachingSessionTypeDTO) {
    this.selectedLogType = logType;
  }

  createCoachingLog() {
    this.formSubmitted = true;

    this.validateForm();
    if (!this.formValid) {
      return;
    }

    if (this.selectedAttendee) {
      this.coachlogItemsPayload.coachees = [this.selectedAttendee];
    }
    this.coachlogItemsPayload.startDatetime = Math.trunc(
      this.startTime.toMillis() / 1000
    );
    this.coachlogItemsPayload.endDatetime = Math.trunc(
      this.endTime.toMillis() / 1000
    );
    this.coachlogItemsPayload.logType = this.selectedLogType;

    this.isLoading = true;
    if (this.isPrivate) this.coachlogItemsPayload.is_private = this.isPrivate;
    this.coachingLogService
      .createQuickWalkSession(this.coachlogItemsPayload)
      .subscribe((res) => {
        this.isLoading = false;
        this.closeModal();
        this.router.navigate([`/observation/log/${res.item.id}/form`]);
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
