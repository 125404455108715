<div class="form-group">
  <label class="form-label" [for]="id">{{ fieldName }}</label>
  <input
    *ngIf="fieldType === fieldTypes.TEXT"
    [id]="id"
    class="form-control border-tertiary"
    (keyup)="dataChange.emit(data)"
    [(ngModel)]="data"
    type="text" />
  <textarea
    *ngIf="fieldType === fieldTypes.TEXTBLOCK"
    [id]="id"
    class="form-control border-tertiary"
    (keyup)="dataChange.emit(data)"
    [(ngModel)]="data"
    rows="3"></textarea>
</div>
