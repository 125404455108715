import { Component, OnInit } from '@angular/core';
import { CoacheeDTO } from 'src/app/private/shared/types/responses/coaching-log.responses';

import { CoacheeListService } from 'src/app/private/shared/services/coachee-list/coachee-list.service';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';

@Component({
  selector: 'app-coachee-list-card',
  templateUrl: './coachee-list-card.component.html',
  styleUrls: ['./coachee-list-card.component.scss'],
})
export class CoacheeListCardComponent implements OnInit {
  coachees: CoacheeDTO[];

  totalCoachees: number;

  isCollapsed = this.cardStorageService.getCollapsedState('coacheelist');

  isLoading = true;

  constructor(
    private coacheeListService: CoacheeListService,
    private cardStorageService: CardStorageService
  ) {}

  getCoachees(): void {
    this.isLoading = true;

    this.coacheeListService
      .fetchCoachees({ details: 1, per_page: 10 })
      .subscribe((coachees) => {
        if (coachees) {
          this.coachees = coachees?.items;
          this.totalCoachees = coachees?._meta.totalCount;
        }
      });

    this.isLoading = false;
  }

  ngOnInit(): void {
    this.getCoachees();
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState('coacheelist', this.isCollapsed);
  }
}
