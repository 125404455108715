import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { MeModeStorageService } from 'src/app/common/services/storage/me-mode-storage';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { FilterType } from '../../shared/components/applied-filters/applied-filters.dto';
import { CelebrationsFilterModalComponent } from '../../shared/components/modals/celebrations-filter-modal/celebrations-filter-modal.component';
import { CreateEditCelebrationModalComponent } from '../../shared/components/modals/create-edit-celebration-modal/create-edit-celebration-modal.component';
import {
  CelebrationDTO,
  CelebrationFilterDTO,
} from '../../shared/dtos/celebrations.dto';
import { CelebrationsService } from '../../shared/services/celebrations/celebrations.service';

@Component({
  selector: 'app-all-celebrations-page',
  templateUrl: './all-celebrations-page.component.html',
  styleUrls: ['./all-celebrations-page.component.scss'],
})
export class AllCelebrationsPageComponent implements OnInit {
  user: User | null = null;

  canSeeAllSchools = false;

  celebrations: CelebrationDTO[];

  listMeta: PaginationDTO;

  filterOptions: CelebrationFilterDTO = {
    fromDate: null,
    toDate: null,
    hasAttachment: 0,
    school: null,
    district: null,
    ownOnly: 0,
    coacheeList: [],
  };

  @ViewChild('celebrationModal')
  celebrationModal: CreateEditCelebrationModalComponent;

  @ViewChild('celebrationFilterModal')
  celebrationFilterModal: CelebrationsFilterModalComponent;

  editCelebration: CelebrationDTO | null = null;

  ownOnly = false;

  constructor(
    private store: Store,
    private celebrationsService: CelebrationsService,
    private meModeStorageService: MeModeStorageService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.canSeeAllSchools = compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.E2L_EMPLOYEE],
      this.user.roles
    );
  }

  ngOnInit(): void {
    this.ownOnly = this.meModeStorageService.getEnabledState('celebrations');
    this.getCelebrations();
  }

  getCelebrations() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.filterOptions.ownOnly = this.ownOnly ? 1 : 0;
    this.meModeStorageService.storeEnabledState('celebrations', this.ownOnly);
    this.filterOptions.currentPage = this.listMeta?.currentPage || 1;
    this.celebrationsService
      .getFilteredCelebrations(this.filterOptions)
      .subscribe((res) => {
        this.celebrations = res.items;
        this.listMeta = res._meta;
      });
  }

  deleteCelebration(celebrationId: number) {
    this.celebrationsService
      .deleteCelebration(celebrationId)
      .subscribe((res) => {
        if (res) {
          this.getCelebrations();
        }
      });
  }

  openEditModal(celebration: CelebrationDTO) {
    this.editCelebration = celebration;
    this.celebrationModal.openModal();
  }

  showFilterModal() {
    this.celebrationFilterModal.openModal();
  }

  setFilters(filters: CelebrationFilterDTO) {
    this.filterOptions = filters;
    this.getCelebrations();
  }

  removeFilter(keyName: string) {
    /* eslint-disable-next-line default-case */
    switch (keyName) {
      case 'date':
        this.filterOptions.fromDate = null;
        this.filterOptions.toDate = null;
        break;
      case 'school':
        this.filterOptions.school = null;
        this.celebrationFilterModal.schoolSelect.reset();
        break;
      case 'district':
        this.filterOptions.district = null;
        this.celebrationFilterModal.districtSelect.reset();
        break;
      case 'hasAttachment':
        this.filterOptions.hasAttachment = 0;
        break;
    }
    this.getCelebrations();
  }

  removeFilterList(filterList: { keyName: string; value: FilterType }) {
    /* eslint-disable-next-line default-case */
    switch (filterList.keyName) {
      case 'coacheeList':
        this.filterOptions.coacheeList = this.filterOptions.coacheeList.filter(
          (coachee) => {
            const value = filterList.value as UserLiteDTO;
            return coachee.id !== value.id;
          }
        );
        this.celebrationFilterModal.coacheeSelect.internalSelectedUserList =
          this.filterOptions.coacheeList;
        break;
    }
    this.getCelebrations();
  }

  openCreateCelebrationModal() {
    this.editCelebration = null;
    this.celebrationModal.openModal();
  }
}
