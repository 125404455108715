import { Component, Input, OnInit } from '@angular/core';
import { ResourceListItemDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-resource-list-item',
  templateUrl: './resource-list-item.component.html',
  styleUrls: ['./resource-list-item.component.scss'],
})
export class ResourceListItemComponent implements OnInit {
  @Input() resourceListItem: ResourceListItemDTO;

  path: string;

  ngOnInit(): void {
    if (this.resourceListItem.slug) {
      this.path = this.resourceListItem.slug;
    } else if (this.resourceListItem.url) {
      const splitUrl = this.resourceListItem.url.split('/');
      this.path = splitUrl[splitUrl.length - 1];
    } else {
      this.path = '';
    }
  }
}
