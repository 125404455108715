/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TimezoneAbbreviation, guessTimezone } from '../utilities/time-helpers';

@Pipe({
  name: 'showDateTimezone',
})
export class ShowDateTimezonePipe implements PipeTransform {
  /* eslint-disable class-methods-use-this */
  // skipcq: JS-0105
  transform(
    value: number,
    outputFormat = 'MMM d, yyyy h:mma',
    timezone?: TimezoneAbbreviation,
    showTimezone = true
  ): string {
    if (!timezone) {
      timezone = guessTimezone();
    }
    const dateString = DateTime.fromSeconds(value).toFormat(outputFormat);
    if (showTimezone) {
      return `${dateString}, ${timezone}`;
    }
    return dateString;
  }
}
