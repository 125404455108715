import { MockProvider, MockService } from 'ng-mocks';
import { Observable } from 'rxjs';
import { AvatarDTO } from '../../components/avatar/avatar.dto';
import { CoachingLogAttendancePusherService } from './coaching-log-attendance-pusher.service';
import { CoachingLogPusherService } from './coaching-log-pusher.service';

export const MOCK_COACHING_LOG_PUSHER_PROVIDER = MockProvider(
  CoachingLogPusherService,
  {
    confettiNotifier: new Observable<boolean>(),
    attendeeRostersNotifier: new Observable<{
      [channelId: string]: { avatar: AvatarDTO; id: string }[];
    }>(),
  }
);

export const MOCK_COACHING_LOG_PUSHER_SERVICE = MockService(
  CoachingLogPusherService,
  {
    confettiNotifier: new Observable<boolean>(),
    attendeeRostersNotifier: new Observable<{
      [channelId: string]: { avatar: AvatarDTO; id: string }[];
    }>(),
    attendeeRosters: { 1: [] },
  }
);

export const MOCK_COACHING_LOG_ATTENDANCE_PUSHER_SERVICE = MockService(
  CoachingLogAttendancePusherService,
  {
    attendeeRostersNotifier: new Observable<{
      [channelId: string]: { avatar: AvatarDTO; id: string }[];
    }>(),
  }
);

export const MOCK_COACHING_LOG_ATTENDANCE_PUSHER_PROVIDER = MockProvider(
  CoachingLogAttendancePusherService,
  {
    attendeeRostersNotifier: new Observable<{
      [channelId: string]: { avatar: AvatarDTO; id: string }[];
    }>(),
  }
);
