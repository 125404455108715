import { ActivatedRouteSnapshot } from '@angular/router';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import { EnvironmentService } from '../services/environment/environment.service';
import { compareRoles, UserRole } from '../state/user/role/user-role';
import { compareTypes } from '../state/user/type/user-type';
import { User } from '../state/user/user.model';
import { PrivateRoute, PublicRoute } from '../types/routes';

export const canAccessByFeatureFlag = (
  route: ActivatedRouteSnapshot | PrivateRoute,
  flagService: UnleashService
): boolean => {
  const flags = route.data ? route.data.featureFlags : false;
  if (!flags) {
    return true;
  }
  let canAccess = true;
  flags.forEach((flag: string) => {
    if (!flagService.isEnabled(flag)) {
      canAccess = false;
    }
  });
  return canAccess;
};

export const canAccessByRole = (
  route: ActivatedRouteSnapshot | PublicRoute,
  user: User | null
): boolean => {
  const roles = route.data ? route.data.roles : false;
  if (!roles) {
    return true;
  }
  return !!(user?.roles && compareRoles(roles, user.roles));
};

export const canAccessByUserType = (
  route: ActivatedRouteSnapshot | PrivateRoute,
  user: User | null
): boolean => {
  const types = route.data ? route.data.types : false;
  if (!types) {
    return true;
  }
  return !!(user?.type && compareTypes(types, user.type));
};

export const checkRouteEnvironment = (path: string): boolean =>
  !(path === 'style-library' && !EnvironmentService.showStyleLibrary());

export const currentDistrictOrE2lEmployee = (
  user: User | null,
  district: {
    code?: string;
    id?: number;
  }
): boolean => {
  if (user) {
    if (
      user.roles.includes(UserRole.E2L_EMPLOYEE) ||
      district?.code === user.district?.districtCode ||
      district?.id === user.district?.id
    ) {
      return true;
    }
  }
  return false;
};
