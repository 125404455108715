<app-modal
  #createResourceModal
  [modalConfig]="{
    titleText: 'Create a Resource',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="cancel()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="canCreate; else warning">
    <form
      class="form-wrapper"
      [formGroup]="createResourceForm"
      (ngSubmit)="onSubmit()"
      (keydown.enter)="$event.preventDefault()">
      <section class="resource-type">
        <label for="type" class="form-label">Resource Type</label>
        <select formControlName="type" class="form-select" id="type">
          <option value="research">Research</option>
          <option value="diy">DIY Activity</option>
          <option value="workshop">Workshop</option>
          <option value="template">Template</option>
          <option value="video">Video</option>
        </select>
      </section>
      <section class="resource-title">
        <label for="title" class="form-label">Title</label>
        <input
          class="form-control"
          formControlName="title"
          placeholder="Enter Resource Title"
          name="title"
          id="title"
          aria-label="title" />
      </section>

      <section class="resource-description">
        <label for="description" class="form-label">Description</label>
        <textarea
          rows="4"
          class="form-control"
          placeholder="Enter Resource Description"
          aria-label="description"
          formControlName="description"
          name="description"></textarea>
      </section>
      <section id="resource-tags" class="">
        <label for="tags" class="form-label">Tags</label>
        <ng-select
          formControlName="tags"
          bindLabel="tags"
          title="tags"
          [items]="availTags"
          [addTag]="true"
          [hideSelected]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          typeToSearchText="Please enter 2 or more characters"
          [loading]="tagsLoading"
          placeholder="Select or create tags"
          autocomplete="whut"
          #select>
          <ng-template let-item="item" let-index="index" let-search="term">
            {{ item }}
          </ng-template>
        </ng-select>
      </section>
    </form>
  </ng-container>
  <ng-template #warning>
    <div class="warning">
      Permission denied. You do not have permissions to create a resource.
    </div>
  </ng-template>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="cancel()">Cancel</button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="onSubmit()"
        [disabled]="createResourceForm.invalid">
        Create
      </button>
    </div>
  </div>
</app-modal>
