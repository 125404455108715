import { ProfileLiteDTO } from 'src/app/common/dtos/profile.dto';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { DistrictAPIResponse } from './district-list.responses';

export interface GetAllPlansAPIResponse {
  items: PlansAPIResponse[];
  _meta: PaginationDTO;
}

export interface PlansAPIResponse {
  id: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  district_id: string;
  district: DistrictAPIResponse;
  phase_displayname: string;
  deliverable_displayname: string;
  actionitem_displayname: string;
  created_at: number;
  updated_at: number;
  is_deleted: number;
  phases_progress: PhasesProgressAPIResponse;
}

export interface PhasesProgressAPIResponse {
  on_track: number;
  off_track: number;
  not_started: number;
  at_risk: number;
  canceled: number;
  completed: number;
}

export interface AssignmentUserAPIResponse {
  id: number;
  email: string;
  status: number;
  district_id: number;
  school_id: number;
  profile: ProfileLiteDTO;
}

export interface AssignmentAPIResponse {
  id: number;
  implementation_plan_id: number;
  user_id: number;
  ref_table: string;
  ref_id: number;
  created_at: number;
  updated_at: number;
  is_deleted?: number;
  user: AssignmentUserAPIResponse;
}

export interface ItemNeedAttentionAPIResponse {
  id: number;
  implementation_phase_id: number;
  implementation_deliverable_id?: number;
  title: string;
  due_date: string;
  type: 'deliverable' | 'action_item';
  type_displayname: string;
  status: string;
}

export interface ActionItemAPIResponse {
  id: number;
  implementation_plan_id: number;
  implementation_phase_id: number;
  implementation_deliverable_id: number;
  title: string;
  description: string;
  due_date: string;
  status: string;
  content: string;
  created_at: number;
  updated_at: number;
  sort_order: number;
  is_deleted: number;
  assignments: AssignmentAPIResponse[];
  has_comments: boolean;
  has_attachments: boolean;
}

export interface DeliverableAPIResponse {
  id: number;
  implementation_plan_id: number;
  implementation_phase_id: number;
  title: string;
  description: string;
  department: string;
  start_date: string;
  due_date: string;
  status: string;
  content: string;
  created_at: number;
  updated_at: number;
  sort_order: number;
  is_deleted: number;
  actionItems: ActionItemAPIResponse[];
  assignments: AssignmentAPIResponse[];
  has_comments: boolean;
  has_attachments: boolean;
  progress: number;
}

export interface PhaseAPIResponse {
  id: number;
  implementation_plan_id: number;
  title: string;
  description: string;
  status: string;
  created_at: number;
  updated_at: number;
  sort_order: number;
  is_deleted: number;
  deliverables: DeliverableAPIResponse[];
  progress: number;
  deliverables_progress: PhasesProgressAPIResponse;
}

export interface CreatePhaseAPIResponse {
  id: number;
  implementation_plan_id: number;
  title: string;
  description: string;
  created_at: number;
  updated_at: number;
}

export interface EditPhaseAPIResponse {
  created_at?: number;
  description?: string;
  id?: number;
  implementation_plan_id?: number;
  is_deleted?: number;
  sort_order?: number;
  status?: string;
  title?: string;
  updated_at?: number;
}

export interface PlanDetailsItemAPIResponse {
  id: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  district_id: number;
  phase_displayname: string;
  deliverable_displayname: string;
  actionitem_displayname: string;
  created_at: number;
  updated_at: number;
  is_deleted: number;
  phases: PhaseAPIResponse[];
}

export enum StatusType {
  NOT_STARTED = 'Not Started',
  ON_TRACK = 'On Track',
  OFF_TRACK = 'Off Track',
  COMPLETE = 'Completed',
  AT_RISK = 'At Risk',
  CANCELED = 'Canceled',
}

export interface PlanDashboardItemAPIResponse {
  id: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  district_id: number;
  phase_displayname: string;
  deliverable_displayname: string;
  actionitem_displayname: string;
  created_at: number;
  updated_at: number;
  is_deleted: number;
  phases: PhaseAPIResponse[];
  phases_progress: PhasesProgressAPIResponse;
  items_that_need_attention: ItemNeedAttentionAPIResponse[];
  getstream_token: string;
}

export interface EditDeliverableAPIResponse {
  id: number;
  title?: string;
  description?: string;
  status?: StatusType;
  progress?: number;
  start_date?: string;
  due_date?: string;
  content?: string;
  department?: string;
  has_comments?: boolean;
  has_attachments?: boolean;
}

export interface EditActionItemAPIResponse {
  id: number;
  title?: string;
  description?: string;
  status?: StatusType;
  progress?: number;
  start_date?: string;
  due_date?: string;
  content?: string;
  has_comments?: boolean;
  has_attachments?: boolean;
}

export interface EditPlanAPIResponse {
  id: number;
  title: string;
  description: string;
  phase_displayname: string;
  deliverable_displayname: string;
  actionitem_displayname: string;
  start_date: string;
  end_date: string;
  district_id: number;
  created_at: number;
  updated_at: number;
}
