<app-modal
  #createDeliverableModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    options: {
      size: 'md',
    },
    customFooter: true,
    showFooter: true
  }"
  (isClosing)="resetForm()"
  (isDismissing)="resetForm()">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <ng-container *ngIf="planDetails; else loadingTemplate">
        <label for="title" class="form-label"
          >{{ planDetails.deliverable_displayname }} Name:</label
        >
        <div class="input-group">
          <input
            formControlName="title"
            type="text"
            class="form-control"
            id="title"
            nme
            aria-label="Title"
            [ngClass]="{
              'is-invalid':
                formSubmitted &&
                f['title'].errors &&
                (f['title'].dirty || f['title'].touched)
            }" />
        </div>
        <label for="description" class="form-label">Description</label>
        <div class="input-group">
          <textarea
            formControlName="description"
            class="form-control"
            id="description"
            aria-label="Description"
            style="height: 200px"
            [ngClass]="{
              'is-invalid': formSubmitted && f['description'].errors
            }"></textarea>
        </div>

        <label for="department" class="form-label">Department:</label>
        <div class="input-group">
          <input
            formControlName="department"
            type="text"
            class="form-control"
            id="department"
            aria-label="Department"
            maxlength="255"
            [ngClass]="{
              'is-invalid': formSubmitted && f['department'].errors
            }" />
        </div>
        <div class="row">
          <div class="col">
            <label class="form-label" for="plan-start-date">Start Date:</label>
            <app-datepicker
              #deliverableStartDatePicker
              id="plan-start-date"
              [initialDateUnixTimestamp]="defaultStartDate"
              (dateSelectedUnixTimestamp)="updateStartDate($event)"
              [minDate]="datePickerMinDate"
              [maxDate]="datePickerMaxDate" />
          </div>
          <div class="col">
            <label class="form-label" for="plan-end-date">Due Date:</label>
            <app-datepicker
              #deliverabledDueDatePicker
              id="plan-end-date"
              [initialDateUnixTimestamp]="defaultDueDate"
              (dateSelectedUnixTimestamp)="updateDueDate($event)"
              [minDate]="datePickerMinDate"
              [maxDate]="datePickerMaxDate" />
          </div>
        </div>
        <div class="mt-4">
          <label for="assigneesSelect" class="form-label">Assignee(s):</label>
          <app-user-select
            #assigneesSelect
            [districtIds]="
              isE2L && user.district
                ? [planDetails.district_id, user.district.id]
                : [planDetails.district_id]
            "
            [defaultUsers]="users"
            [clearAfterSelection]="true"
            (selectedUser)="addChosenUser($event)"
            [isMulti]="false"></app-user-select>
          <ng-container *ngFor="let user of users">
            <app-new-coaching-attendee
              [user]="user"
              (removedAttendee)="deleteChosenUser(user)" />
          </ng-container>
        </div>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100" style="background-color: #fff; height: 400px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </form>
  </ng-container>

  <div footerContent class="custom-footer">
    <ng-container *ngIf="serverError.length > 0">
      <ngb-alert [dismissible]="false" [type]="'danger'">
        <strong>Error</strong> {{ serverError }}
      </ngb-alert>
    </ng-container>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn px-3 mx-1" (click)="onCancel()">
        Cancel
      </button>

      <button
        [disabled]="isLoading || form.invalid"
        type="button"
        class="btn btn-md btn-primary"
        (click)="createDeliverable()">
        <ng-container *ngIf="isLoading">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        Create
      </button>
    </div>
  </div>
</app-modal>
