import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  SortableHeader,
  SortEvent,
} from '../../directives/sortable-header.directive';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { getSessionLink } from '../../helpers/coachee-log.utilities';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';

function compare(v1: string | number, v2: string | number) {
  if (typeof v1 === 'string' && typeof v2 === 'string') {
    v1 = v1.toUpperCase();
    v2 = v2.toUpperCase();
  }
  // eslint-disable-next-line no-nested-ternary
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

const columnDataMap = (
  session: CoachingSessionDTO,
  column: string
): string | number => {
  switch (column) {
    case 'logTitle':
      return session.title || '';
    case 'logType':
      return session.type.title || '';
    case 'coachee':
      return session.attendees[0]
        ? session.attendees[0].user.profile.first_name
        : '';
    case 'coach':
      return session.user.profile.first_name || '';
    case 'school':
      return session.attendees[0]?.user.school
        ? session.attendees[0].user.school.title
        : '';
    case 'date':
    default:
      return session.startDatetime || 0;
  }
};

@Component({
  selector: 'app-coaching-session-list',
  templateUrl: './coaching-session-list.component.html',
  styleUrls: ['./coaching-session-list.component.scss'],
})
export class CoachingSessionListComponent implements OnInit, OnDestroy {
  @Input() sessions: CoachingSessionDTO[];

  @Input() pagination: PaginationDTO;

  @Output() readonly pageChanged: EventEmitter<number> = new EventEmitter();

  user: User;

  tableData: CoachingSessionDTO[];

  sessionId: number;

  getSessionLink = getSessionLink;

  subs: Subscription[] = [];

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    logTitle: 'gray',
    date: 'gray',
    logType: 'gray',
    coachee: 'gray',
    coach: 'gray',
    school: 'gray',
  };

  constructor(
    private router: Router,
    private coachingLogService: CoachingLogService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.tableData = this.sessions;

    this.subs.push(
      this.coachingLogService.coachlogDeleted.subscribe((session) => {
        this.sessions = this.sessions.filter(
          (eachSession) => session !== eachSession
        );
        this.tableData = this.sessions;
      })
    );
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (direction === '' || column === '') {
      this.tableData = this.sessions;
      this.sortIconTemp[column] = 'gray';
    } else {
      this.tableData = [...this.tableData].sort((a, b) => {
        const res = compare(
          columnDataMap(a, column as string),
          columnDataMap(b, column as string)
        );
        return direction === 'asc' ? -res : res;
      });
    }
  }

  selectLog(coachlogId: number) {
    this.router.navigate([`/coaching/log/${coachlogId}/info`]);
  }

  deleteLog(session: CoachingSessionDTO) {
    this.coachingLogService.deleteCoachlog(session);
  }

  deleteCurrentMSCLog(session: CoachingSessionDTO) {
    this.coachingLogService.deleteMSCSession(session);
  }

  pageChange() {
    if (this.pagination) {
      this.pageChanged.emit(this.pagination.currentPage);
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
