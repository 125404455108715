import { AuthGuard } from 'src/app/common/guards/auth-guard/auth-guard.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';

import { FeatureGuard } from '../guards/feature-guard/feature-guard.service';
import { NavGuard } from '../guards/nav-guard/nav-guard.service';
import { RouteGuard } from '../guards/route-guard/route-guard.service';
import { PrivateRoute, PublicRoute } from '../types/routes';

const everyRoute = [NavGuard, FeatureGuard];

export const EVERY_ROUTE: PublicRoute | PrivateRoute = {
  canActivate: [...everyRoute],
};

export const AUTH_ROUTE_GUARDED_ROUTE: PrivateRoute = {
  canActivate: [...everyRoute, AuthGuard, RouteGuard],
};

export const ROUTE_GUARDED_ROUTE: PublicRoute | PrivateRoute = {
  canActivate: [...everyRoute, RouteGuard],
};

export const AUTH_GUARDED_ROUTE: PrivateRoute = {
  canActivate: [...everyRoute, AuthGuard],
};

export const AUTH_GUARDED_ROUTE_OPERATOR: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.OPERATOR, UserRole.REMOTE_OPERATOR] },
};

/* export const AUTH_GUARDED_ROUTE_REMOTE_OPERATOR: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.REMOTE_OPERATOR] },
}; */

export const AUTH_GUARDED_ROUTE_COACH: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.COACH] },
};

export const AUTH_ROUTE_GUARDED_ROUTE_OPERATOR: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE_OPERATOR,
  canActivate: [...everyRoute, AuthGuard, RouteGuard],
};

export const AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.E2L_EMPLOYEE] },
};

export const AUTH_ROUTE_GUARDED_ROUTE_E2L_EMPLOYEE_COACHES: PrivateRoute = {
  ...AUTH_GUARDED_ROUTE,
  data: { roles: [UserRole.E2L_EMPLOYEE, UserRole.COACH] },
};
