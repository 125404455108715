<input
  mdbCheckbox
  class="form-check-input show-checkbox"
  type="checkbox"
  [ngModel]="0"
  [value]="level"
  (ngModelChange)="clickedCheckbox($event)"
  id="flexCheckDefault" />
<label class="form-check-label check-label" for="flexCheckDefault">
  &nbsp;{{ level }}
</label>
