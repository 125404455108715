<div class="container">
  <app-general-header title="Resources" />
  <div class="card border-0 p-3">
    <app-resources-subnav></app-resources-subnav>
    <div class="card-body">
      <div class="d-flex justify-content-end">
        <button
          *ngIf="userCanEditVideos"
          class="btn btn-primary"
          (click)="videoLibraryService.triggerNewVideoModal()"
          type="button">
          <i class="bi bi-plus me-2"></i>Create Video
        </button>
      </div>
      <div class="search-filter-wrapper p-3 my-4">
        <div class="search-input input-group flex-grow-1">
          <div class="input-group-text"><i class="bi bi-search"></i></div>
          <input
            type="text"
            class="form-control"
            id="search"
            [(ngModel)]="search"
            placeholder="Search"
            (input)="onSearchFieldKeydown()" />
        </div>
        <div class="category-select">
          <label class="visually-hidden" [attr.for]="'video-category'"
            >Select Category</label
          >
          <ng-select
            [items]="categoriesList"
            bindLabel="name"
            bindValue="id"
            labelForId="video-category"
            [(ngModel)]="selectedVideoCategory"
            (ngModelChange)="updateVideoCategory($event)"
            [trackByFn]="trackByFn"
            placeholder="All Categories">
          </ng-select>
        </div>
        <button
          id="filter"
          (click)="filterModal.open()"
          class="btn btn-secondary filter-button">
          <span
            *ngIf="numberOfFilters > 0"
            class="filters-number me-2 bg-primary color-white rounded-circle"
            >{{ numberOfFilters }}</span
          >
          <i class="bi bi-filter me-2"></i>
          <span class="filter-text">Filter</span>
        </button>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
        ">
        <div>
          <div
            *ngIf="filtersApplied"
            class="text-link-color"
            (click)="clearFilters()"
            class="cursor-pointer">
            <i class="bi bi-arrow-left-circle"></i> Clear Filters
          </div>
        </div>
        <div *ngIf="searchMetaData">
          <span class="pagination-display">
            Showing page {{ searchMetaData.currentPage }} of
            {{ searchMetaData.pageCount }}
          </span>
        </div>
      </div>

      <ng-container>
        <ng-container *ngIf="videoList; else loading">
          <ng-container *ngIf="videoList.length > 0; else noResults">
            <div class="row videos-list">
              <ng-container *ngFor="let video of videoList">
                <div class="col col-sm-6 col-xl-4">
                  <app-video-card [video]="video"></app-video-card>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noResults>
          <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="min-height: 500px">
            <h2>No Results Found</h2>
            <p>Please check your search term and filters and try again.</p>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loading>
        <div class="w-100 text-center mb-5" style="height: 30vh">
          <app-loader> </app-loader>
        </div>
      </ng-template>
    </div>
    <section>
      <div class="d-flex justify-content-center">
        <ngb-pagination
          *ngIf="searchMetaData && searchMetaData.totalCount > 10"
          [(page)]="searchMetaData.currentPage"
          [pageSize]="searchMetaData.perPage"
          [collectionSize]="searchMetaData.totalCount"
          [maxSize]="5"
          [ellipses]="true"
          (pageChange)="pageChanged()"></ngb-pagination>
      </div>
    </section>
  </div>
</div>

<app-video-modal
  *ngIf="videoToShow"
  [video]="videoToShow"
  [autoOpen]="true"
  #videoModal></app-video-modal>

<app-modify-video-modal #modifyVideoModal></app-modify-video-modal>

<app-modal
  #filterModal
  hiddenModal="closeModal"
  [modalConfig]="{
    titleText: 'Filter Results',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    options: { size: 'lg' },
  }">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="filterModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="p-3">
    <div class="row">
      <div class="col">
        Created By
        <div class="custom-user">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="admin"
              [checked]="selectedUser == 'admin'"
              [(ngModel)]="selectedUser"
              (ngModelChange)="updateUser($event)" />
            <label class="form-check-label" for="flexRadioDefault1">
              e2L Admin
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="district"
              [checked]="selectedUser == 'district'"
              [(ngModel)]="selectedUser"
              (ngModelChange)="updateUser($event)" />
            <label class="form-check-label" for="flexRadioDefault2">
              My District
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault3"
              value="me"
              [checked]="selectedUser == 'me'"
              [(ngModel)]="selectedUser"
              (ngModelChange)="updateUser($event)" />
            <label class="form-check-label" for="flexRadioDefault3"> Me </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault4"
              value=""
              [checked]="selectedUser == ''"
              [(ngModel)]="selectedUser"
              (ngModelChange)="updateUser($event)" />
            <label class="form-check-label" for="flexRadioDefault4">
              Anyone
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault5"
              value="custom"
              [checked]="selectedUser == 'custom'"
              [(ngModel)]="selectedUser"
              (ngModelChange)="updateUser($event)" />
            <label class="form-check-label" for="flexRadioDefault5">
              <app-user-select
                [isMulti]="true"
                [clearAfterSelection]="true"
                (selectedUserList)="updateUsersList($event)"></app-user-select>
            </label>
          </div>
        </div>
      </div>
      <div class="col">
        Video Length
        <ng-select
          [items]="videoLength"
          labelForId="video-length"
          [(ngModel)]="selectedVideoLength"
          (ngModelChange)="updateVideoLength($event)"
          placeholder="">
        </ng-select>
      </div>
      <div class="col">
        Role
        <ng-select
          [items]="rolesList"
          labelForId="roles"
          [clearable]="true"
          [(ngModel)]="selectedRole"
          (ngModelChange)="updateRole($event)">
        </ng-select>
      </div>
    </div>
  </div>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="clearFilters()">
        Clear All
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="filterModal.close()">
        Done
      </button>
    </div>
  </div>
</app-modal>
