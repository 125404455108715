import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CoachStatsUsersAPIResponse } from '../../types/responses/coach-list.responses';

interface RequestParams {
  expand?: string;
  per_page?: number;
  district_id?: number;
  coachee_type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CoachListService {
  constructor(private apiService: APICoreService, private router: Router) {}

  fetchCoaches(
    requestOptions: RequestParams = {
      expand: 'coachstats,profile',
      per_page: 1000,
    }
  ): Observable<CoachStatsUsersAPIResponse | null> {
    return this.apiService.getRequest('users', requestOptions);
  }
}
