import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs';
import { VideoCategoryDTO, VideoDTO } from 'src/app/common/dtos/video.dto';
import { FileDTO } from '../../../dtos/file.dto';
import { UploadType } from '../../../enums/upload-type.enum';
import { VideoLibraryService } from '../../../services/video-library/video-library.service';
import { UploadStyles } from '../../file-management/file-upload/file-upload.component';
import { ModalComponent } from '../modal/modal.component';

enum ModalType {
  EDIT,
  NEW,
}

@Component({
  selector: 'app-modify-video-modal',
  templateUrl: './modify-video-modal.component.html',
  styleUrls: ['./modify-video-modal.component.scss'],
})
export class ModifyVideoModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ModalComponent;

  @Input() videoId: number | null;

  videoData?: Partial<VideoDTO>;

  simpleUploadStyle = UploadStyles.SIMPLE;

  uploadTypeVideo = UploadType.VIDEO;

  action: ModalType;

  modalTypes = ModalType;

  initialContent = '';

  hasVideo = false;

  canSave = false;

  fullVideoData?: VideoDTO;

  roles = ['Teacher', 'Coach', 'Leader'];

  deleteVideoId?: number;

  categories: VideoCategoryDTO[] = [];

  uploadData: EventEmitter<FileDTO> = new EventEmitter<FileDTO>();

  constructor(
    private videoService: VideoLibraryService,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.videoService.getCategories().subscribe((categories) => {
      take(1);
      this.categories = categories;
    });
    this.videoService.editVideo.subscribe((videoData) => {
      if (!videoData) {
        this.modal.close();
        this.removeVideoData();
      } else if (typeof videoData === 'object') {
        this.editExistingVideo(videoData);
      } else if (videoData === 'new') {
        this.action = ModalType.NEW;
        this.videoData = {
          belongsToVideoLibrary: true,
        };
        this.modal.open();
      } else {
        this.videoService.getVideo(parseInt(videoData)).subscribe((video) => {
          this.editExistingVideo(video);
        });
      }
    });
  }

  editExistingVideo(video: VideoDTO) {
    this.hasVideo = true;
    this.fullVideoData = video as VideoDTO;
    this.initialContent = video.description;
    this.action = ModalType.EDIT;
    this.videoData = video;
    this.canSave = true;
    this.modal.open();
  }

  canVideoSave() {
    this.canSave = !!(
      this.hasVideo &&
      this.videoData?.title &&
      this.videoData?.description &&
      this.videoData?.categoryId
    );
    this.changeRef.detectChanges();
  }

  ngAfterViewInit() {
    this.modal.isClosing.subscribe(() => {
      this.saveVideo();
    });
    this.modal.isDismissing.subscribe(() => {
      this.removeVideoData();
    });
  }

  removeVideoData() {
    if (this.videoData) {
      delete this.videoData;
    }
    if (this.deleteVideoId) {
      delete this.deleteVideoId;
    }
    this.hasVideo = false;
    this.canSave = false;
    this.initialContent = '';
    delete this.fullVideoData;
  }

  videoUploaded(file: FileDTO) {
    this.uploadData.next(file);
    this.hasVideo = true;
    this.videoData = {
      ...file.videoData,
      categoryId: this.videoData?.categoryId || undefined,
      title: this.videoData?.title || '',
      description: this.videoData?.description || '',
      role: this.videoData?.role || '',
      belongsToVideoLibrary: true,
    } as Partial<VideoDTO>;
    this.canVideoSave();
    this.action = ModalType.EDIT;
  }

  updateDescription(content: string) {
    if (this.videoData) {
      this.videoData.description = content;
    }
    this.canVideoSave();
  }

  saveVideo() {
    if (this.videoData) {
      if (this.action === ModalType.NEW) {
        this.videoService.createVideo(this.videoData);
      } else {
        this.videoService.updateVideo(this.videoData);
      }
    }
    this.removeVideoData();
  }
}
