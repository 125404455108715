<div class="form-group media form-box">
  <label [id]="id" class="form-label ms-2">{{ fieldDefinition.label }}</label>
  <div class="mx-5">
    <app-file-list
      (deletedFile)="handleDelete($event)"
      [canDelete]="!inputDisabled"
      [uploadList]="fileUploads"
      [fileList]="filesArray" />
  </div>
  <div *ngIf="!inputDisabled" class="mx-5">
    <app-file-upload (outputFiles)="handleFileUpload($event)" />
  </div>
</div>
