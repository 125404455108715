import { Injectable } from '@angular/core';
import { catchError, map, Subject } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CompetencyBadgeRubricResponse } from 'src/app/common/types/responses/responses';
import { CompetencyDTO } from '../../dtos/attendee-rubric.dto';
import {
  assessmentsDTOFromAPIResponse,
  rubricDTOFromAPIResponse,
} from '../../helpers/translators/attendee-rubrics-dto.translator';
import { competencyBadgeRubricDTOFromAPIResponse } from '../../helpers/translators/badge.translators';
import {
  AssessmentAPIResponse,
  RubricAPIResponse,
} from '../../types/responses/coaching-log.responses';
import { IndicatorAssessmentPayload } from '../coaching-log/coaching-log-service.dto';
import { UserPusherService } from '../pusher/user.pusher.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  private userId: number;

  private competencyId: number;

  public competencySubject = new Subject<CompetencyDTO>();

  constructor(
    private apiService: APICoreService,
    public userPusherService: UserPusherService
  ) {}

  disconnectFromPusherChannel(userId: number) {
    this.userPusherService.removeChannel(`growth-${userId}`);
  }

  connectToPusherChannel(userId: number) {
    this.userPusherService.addChannel(`growth-${userId}`);
  }

  getPortfolioData(userId: number, rubricIds?: number[]) {
    if (rubricIds) {
      const rubricIdsToString = rubricIds.join('&rubric_ids[]=');
      return this.apiService.getRequest(
        `growelab/portfolio/${userId}?rubric_ids[]=${rubricIdsToString}`
      );
    }
    return this.apiService.getRequest(`growelab/portfolio/${userId}`);
  }

  getUserRubrics(userId: number) {
    return this.apiService.getRequest(`growelab/user-rubrics/${userId}`);
  }

  getCompetencyRubric(userId: number, competencyId: number) {
    this.userId = userId;
    this.competencyId = competencyId;

    return this.apiService
      .getRequest(`growelab/user/${userId}/competency/${competencyId}`)
      .pipe(
        map((res) => {
          const responseDTO = rubricDTOFromAPIResponse(
            res.rubrics as RubricAPIResponse,
            0
          );
          const competency =
            responseDTO.rubricDto.competencyGroups[0].competencies[0];
          this.competencySubject.next(competency);
          return responseDTO;
        })
      );
  }

  assessIndicator(payload: IndicatorAssessmentPayload) {
    const postData: IndicatorAssessmentPayload = {
      ...payload,
    };

    return this.apiService
      .postRequest('egrowe/indicator-assessments', postData)
      .pipe(
        map((res) => {
          const responseDTO = assessmentsDTOFromAPIResponse(
            res.item as AssessmentAPIResponse
          );

          this.getCompetencyRubric(this.userId, this.competencyId).subscribe();

          return responseDTO;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  deleteAssessment(assessmentId: number) {
    return this.apiService
      .deleteRequest(`egrowe/indicator-assessments/${assessmentId}`)
      .pipe(
        map((res) => {
          this.getCompetencyRubric(this.userId, this.competencyId).subscribe();
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getCompetencyBadges(userId: number) {
    return this.apiService.getRequest(`growelab/user/${userId}/badges`).pipe(
      map((res) => {
        if (res && res.length > 0) {
          return res.map((item: CompetencyBadgeRubricResponse) =>
            competencyBadgeRubricDTOFromAPIResponse(
              item as CompetencyBadgeRubricResponse
            )
          );
        }
        return [];
      })
    );
  }

  getCompetencyBadgesForTopic(userId: number, rubricId: number[]) {
    return this.apiService
      .getRequest(`growelab/user/${userId}/badges?rubric_ids[]=${rubricId}`)
      .pipe(
        map((res) => {
          if (res && res.length > 0) {
            return res.map((item: CompetencyBadgeRubricResponse) =>
              competencyBadgeRubricDTOFromAPIResponse(
                item as CompetencyBadgeRubricResponse
              )
            );
          }
          return [];
        })
      );
  }
}
