import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { AssessmentDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';
import {
  CompetencyFieldOutput,
  Field,
  FieldDataFlexible,
  FieldDataType,
  FieldType,
  Form,
  FormIndicatorObservedDTO,
  FormSubmissionStatus,
  ObservationFormSubmission,
  ObservationFormSubmissionRawData,
} from 'src/app/private/shared/dtos/forms.dto';
import { formObservedToIndicatorDTO } from 'src/app/private/shared/helpers/translators/forms.translators';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

@Component({
  selector: 'app-form-view',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.scss'],
})
export class ViewFormComponent implements OnChanges {
  fieldTypes = FieldType;

  fieldData: FieldDataFlexible[] = [];

  assessmentDeltas: {
    assessmentsToAdd: FormIndicatorObservedDTO[];
    assessmentsToRemove: AssessmentDTO[];
    fieldId: string;
  }[];

  showForm = true;

  @Input() inputDisabled = false;

  @Input() readOnly = false;

  @Input() form: Form;

  @Input() forSubmission = false;

  @Input() submissionStatus = FormSubmissionStatus.NEW;

  @Input() inputFieldData: FieldDataFlexible[] = [];

  @Input() submissionData?: ObservationFormSubmission;

  @Input() showTitle = false;

  @Output() readonly data =
    new EventEmitter<ObservationFormSubmissionRawData>();

  @Output() readonly indicatorData = new EventEmitter<
    CompetencyFieldOutput[]
  >();

  user: User;

  constructor(
    private formsService: FormsService,
    private store: Store,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submissionData']) {
      this.showForm = false;
      if (
        this.submissionData &&
        this.submissionStatus === FormSubmissionStatus.SUBMITTED
      ) {
        this.fieldData = this.submissionData.submissionData;
      } else if (this.inputFieldData.length > 0) {
        this.fieldData = this.inputFieldData;
      } else {
        this.fieldData = [];
      }
      setTimeout(() => {
        this.showForm = true;
        // When converting from new form to submitted, component redraws and the scroll position is lost. This is a hacky way to restore it.
        if (this.formsService.repositionFormOnSave) {
          setTimeout(
            () => window.scrollTo(0, this.formsService.scrollPosition),
            0
          );
          this.formsService.repositionFormOnSave = false;
        }
      }, 0);
    }
  }

  getInputData(field: Field) {
    if (
      // Check status so data already passed doesn't get thrown in
      this.submissionStatus === FormSubmissionStatus.SUBMITTED &&
      this.fieldData &&
      this.fieldData.length > 0
    ) {
      const data = this.fieldData.find((x) => x.fieldId === field.id);
      if (data) {
        return data;
      }
    }
    return null;
  }

  outputData() {
    this.data.emit({
      formId: this.form.id,
      submitterUser: this.user,
      submissionData: this.fieldData,
    });
  }

  updateCompetencyData(data: CompetencyFieldOutput, fieldId: string) {
    const { assessmentsToAdd, assessmentsToRemove } = data;
    if (this.assessmentDeltas) {
      const existingDelta = this.assessmentDeltas.find(
        (x) => x.fieldId === fieldId
      );
      if (existingDelta) {
        existingDelta.assessmentsToAdd = assessmentsToAdd;
        existingDelta.assessmentsToRemove = assessmentsToRemove;
      } else {
        this.assessmentDeltas.push({
          assessmentsToAdd,
          assessmentsToRemove,
          fieldId,
        });
      }
    } else {
      this.assessmentDeltas = [
        {
          assessmentsToAdd,
          assessmentsToRemove,
          fieldId,
        },
      ];
    }
    this.indicatorData.emit(this.assessmentDeltas);
  }

  updateData(data: FieldDataFlexible) {
    if (data.type === FieldDataType.COMPETENCY) {
      const values = data.value as CompetencyFieldOutput;
      this.updateCompetencyData(values, data.fieldId);
      data.value =
        values.assessmentsToAdd.length > 0
          ? values.assessmentsToAdd.map((item) =>
              formObservedToIndicatorDTO(item)
            )
          : '';
    }
    if (this.fieldData.find((x) => x.fieldId === data.fieldId)) {
      this.fieldData = this.fieldData.map((x) =>
        x.fieldId === data.fieldId ? data : x
      );
    } else {
      this.fieldData.push(data);
    }
    this.outputData();
  }
}
