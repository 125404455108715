<div class="notes" #notes>
  <ng-container *ngIf="loading; else noteTemplate">
    <div class="text-center">
      <div
        style="color: var(--gl-secondary)"
        class="spinner-border spinner-border-sm me-1"></div>
    </div>
  </ng-container>
  <ng-template #noteTemplate>
    <ng-container *ngIf="hasNotes && !editMode; else form">
      <div class="hasnote" (click)="toggleEditMode()">
        {{ coachee.personalNotes }}
      </div>
    </ng-container>
    <ng-template #form>
      <ng-container *ngIf="!hasNotes && !editMode; else editor">
        <button class="btn-add" (click)="toggleEditMode()">
          Click to add note
        </button>
      </ng-container>
      <ng-template #editor>
        <form [formGroup]="noteForm">
          <textarea
            #note
            class="form-control form-control-sm"
            placeholder="Add note"
            id="{{ coachee.id }}"
            name="note"
            formControlName="note"
            rows="2"
            (blur)="updateNote()">
          </textarea>
        </form>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
