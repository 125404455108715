import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { CompetenciesSelectService } from '../../../services/competencies/competencies-select.service';

@Component({
  selector: 'app-competency-name',
  templateUrl: './competency-name.component.html',
  styleUrls: ['./competency-name.component.scss'],
})
export class CompetencyNameComponent implements OnInit {
  @Input() competencyId = 0;

  selectedCompetency: CompetencyDTO;

  constructor(private competenciesSelectService: CompetenciesSelectService) {}

  ngOnInit(): void {
    if (this.competencyId) {
      this.competenciesSelectService
        .getCompetency(this.competencyId)
        .pipe(
          map((competency) => {
            if (competency) {
              this.selectedCompetency = competency;
            }
          })
        )
        .subscribe();
    }
  }
}
