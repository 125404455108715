import { dateTimeFromNgbDate } from 'src/app/common/utilities/date-helpers';
import {
  CelebrationDTO,
  CelebrationFileDTO,
  CelebrationFilterDTO,
  CelebrationListDTO,
} from '../../dtos/celebrations.dto';
import { FileDTO } from '../../dtos/file.dto';
import { FileIcon } from '../../enums/file-icons.enum';
import { CelebrationFilterPayload } from '../../types/payloads/celebrations.payloads';
import {
  CelebrationAPIResponse,
  CelebrationsListAPIResponse,
} from '../../types/responses/celebrations.responses';

export const celebrationDTOFromAPIResponse = (
  item: CelebrationAPIResponse
): CelebrationDTO => ({
  content: item.content,
  createdAt: item.created_at,
  districts: item.districts,
  egroweCoachlogId: item.egrowe_coachlog_id,
  endorser: item.endorser,
  endorserUserId: item.endorser_user_id,
  files: item.files,
  id: item.id,
  includesYou: item.includesYou,
  isBrightspot: item.is_brightspot,
  isDeleted: item.is_deleted,
  schools: item.schools,
  competencies: item.standards,
  textContent: item.text_content,
  title: item.title,
  updatedAt: item.updated_at,
  users: item.users,
});

export const celebrationListDTOFromAPIResponse = (
  response: CelebrationsListAPIResponse
): CelebrationListDTO => ({
  items: response.items.map((item) => celebrationDTOFromAPIResponse(item)),
  _meta: response._meta,
});

export const celebrationFileDTOToFileDTO = (
  celebrationFile: CelebrationFileDTO
): FileDTO => ({
  id: celebrationFile.id,
  name: celebrationFile.title,
  iconClass: FileIcon.file,
  location: celebrationFile.file_path,
});

export const celebrationFilterDTOFromComponent = (
  filters: CelebrationFilterDTO
): CelebrationFilterPayload => {
  const fromDate = filters.fromDate
    ? dateTimeFromNgbDate(filters.fromDate).toSeconds()
    : '';
  const toDate = filters.toDate
    ? dateTimeFromNgbDate(filters.toDate)
        .set({ hour: 23, minute: 59, second: 59 })
        .toSeconds()
    : '';
  return {
    from_date: fromDate,
    to_date: toDate,
    school_id: filters.school?.id || null,
    district: filters.district?.id || null,
    user_id: filters.coacheeList.map((coachee) => coachee.id),
    has_attachments: filters.hasAttachment || 0,
    page: filters.currentPage || 1,
    own_only: filters.ownOnly || 0,
    per_page: filters.perPage || 10,
  };
};
