import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Intercom } from '@supy-io/ngx-intercom';
import { environment } from 'src/environments/environment';
import { UserState } from '../../state/user/user.state';

@Injectable({
  providedIn: 'root',
})
export class ChatBotService {
  isInitialized = false;

  constructor(public intercom: Intercom, private store: Store) {}

  initialize() {
    const user = this.store.selectSnapshot(UserState.getUser);
    const intercomBootOptions: {
      app_id: string;
      widget: { activator: string };
      email?: string;
      user_hash?: string;
      user_id?: string;
      created_at?: number;
      name?: string;
      coach?: string;
      coach_email?: string;
      coachee_type?: string;
      district?: string;
      district_id?: number;
      school?: string;
      school_id?: number;
    } = {
      app_id: environment.intercomId,
      widget: {
        activator: '#intercom',
      },
    };

    // Initialize chatbot
    if (user && !this.isInitialized) {
      if (user.intercomHash && user.email && user.coacheeType) {
        intercomBootOptions.email = user.email;
        intercomBootOptions.user_hash = user.intercomHash;
        intercomBootOptions.user_id = user.id.toString();
        intercomBootOptions.created_at = user.createdAt;
        intercomBootOptions.name = user.fullName;
        intercomBootOptions.coach = `${user.coach?.profile.first_name} ${user.coach?.profile.last_name}`;
        intercomBootOptions.coach_email = user.coach?.email;
        intercomBootOptions.coachee_type = user.coacheeType;
        intercomBootOptions.district = user.district?.title;
        intercomBootOptions.district_id = user.district?.id;
        intercomBootOptions.school = user.school?.title;
        intercomBootOptions.school_id = user.school?.id;
      }
      this.intercom.boot(intercomBootOptions);

      this.isInitialized = true;
    }
  }

  deinitialize() {
    this.intercom.shutdown();

    this.isInitialized = false;
  }
}
