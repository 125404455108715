<div class="login">
  <div class="login__form">
    <main role="main" class="form-signin">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="text-center">
          <img
            id="groweLabLogo"
            src="assets/logo_horiz_dark.png"
            class="mb-3 w-100"
            alt="GroweLab Logo"
            @delayfadeInOut />
        </div>
        <div @fadeInOut>
          <ng-container *ngIf="forceLogout">
            <div class="mt-5 mb-3 fs-14 text-start">
              <div class="fw-800" style="color: #430089">Session Timed Out</div>
              <div>You have been logged out because of inactivity.</div>
            </div>
          </ng-container>
          <ng-container *ngIf="errorMessage">
            <div class="alert alert-danger text-start" role="alert">
              {{ errorMessage }}
            </div>
          </ng-container>
          <div class="form-group text-start mb-2">
            <label for="floatingUsername" class="form-label fw-bold"
              >Username</label
            >
            <input
              type="email"
              formControlName="username"
              class="form-control"
              id="floatingUsername"
              [ngClass]="{
                'is-invalid': submitted && f['username'].errors
              }" />
          </div>
          <div class="form-group text-start mb-2">
            <label for="floatingPassword" class="form-label fw-bold"
              >Password</label
            >
            <input
              type="password"
              formControlName="password"
              class="form-control"
              id="floatingPassword"
              [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
          </div>
          <div class="my-3">
            <a
              href="/change-password-request"
              id="firstTimeLink"
              class="text-link-color fw-bold"
              >First Time Here?</a
            >
            &nbsp;|&nbsp;
            <a
              href="/change-password-request"
              id="forgotPasswordLink"
              class="text-link-color fw-bold"
              >Forgot Password?</a
            >
          </div>
          <button
            [disabled]="loading"
            data-testid="signin-button"
            class="w-100 btn btn-lg btn-primary mb-4 fw-bold"
            type="submit">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm me-1"></span>
            Sign in
            <i class="bi bi-arrow-right"></i>
          </button>
          <div
            class="d-flex align-items-center justify-content-between text-black fw-bolder mb-2">
            <hr style="border-top: 1.5px solid; opacity: 1; width: 42%" />
            <span>or</span>
            <hr style="border-top: 1.5px solid; opacity: 1; width: 42%" />
          </div>
          <div class="mb-4">
            <div
              id="g_id_onload"
              [attr.data-client_id]="googleOAuthClientId"
              data-context="signin"
              data-ux_mode="popup"
              data-callback="handleGSIResponse"
              data-auto_prompt="false"></div>
            <div
              class="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="filled_blue"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="left"
              data-width="400"></div>
            <div class="loginWithClever mt-3">
              <a href="{{ liwcLink }}"
                ><img
                  src="/assets/liwc.png"
                  alt="Log in with Clever"
                  title="Log in with Clever"
              /></a>
            </div>
          </div>
        </div>
      </form>
      <img
        style="max-width: 210px"
        src="/assets/powered-by-e2l-logo.png"
        alt="Powered by engage2Learn"
        title="Powered by engage2Learn" />
      <div class="fw-light">
        All rights reserved.
        <a
          class=""
          href="legal/privacy-policy"
          aria-label="link to engage2learn privacy policy"
          >Privacy</a
        >
        |
        <a
          class=""
          href="legal/terms"
          aria-label="link to engage2learn terms of use"
          >Terms</a
        >
      </div>
    </main>
  </div>
</div>
