import {
  CompetencyBadgeResponse,
  CompetencyBadgeRubricResponse,
} from 'src/app/common/types/responses/responses';
import { BadgeDataDTO, CompetencyBadgeRubricDTO } from '../../dtos/badge.dto';

export const BadgeDataDTOFromAPIResponse = (
  response: CompetencyBadgeResponse
): BadgeDataDTO => ({
  id: response.id,
  title: response.title,
  type: response.type,
  level: response.level,
  icon: response.icon,
  strand: response.strand,
  rubric: response.rubric,
  competency: response.competency,
  isEarned: response.is_earned,
  earnDate: response.earn_date,
  image: response.image,
});

export const competencyBadgeRubricDTOFromAPIResponse = (
  response: CompetencyBadgeRubricResponse
): CompetencyBadgeRubricDTO => ({
  id: response.id,
  title: response.title,
  egroweRubricId: response.egrowe_rubric_id,
  badges: response.badges?.map((item) => BadgeDataDTOFromAPIResponse(item)),
  collapsed: false,
});
