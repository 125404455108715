<app-modal
  *ngIf="preAssessmentQuestionGroups.length > 0"
  #preAssessmentModal
  [modalConfig]="{
    titleText: preAssessmentQuestionGroups[currentQuestionGroupIndex].title,
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    openOnLoad: true,
    options: {
      backdrop: 'static',
      keyboard: false
    }
  }">
  <div class="container">
    <div class="fw-800 text-center mb-3">Pre-Assessment</div>
    <div *ngIf="user && user.smartCoach">
      <app-smart-coach-dialog-single
        [coachProfile]="user.smartCoach.profile"
        [message]="
          'Before we continue, please take a moment to complete the pre-assessment for the competency you have chosen. Be honest in your responses, as this is purely for growth and not evaluative. Your answers will help me provide recommendations on goals and next steps.'
        "></app-smart-coach-dialog-single>
    </div>

    <div class="mt-3">
      <div class="fw-800 mb-3">
        {{
          preAssessmentQuestionGroups[currentQuestionGroupIndex]
            .preassessment_questions[currentQuestionIndex].question
        }}
      </div>
      <form [formGroup]="formGroup" class="fw-200">
        <div class="form-check">
          <input
            (change)="this.updateAnswer($event)"
            value="Yes"
            class="form-check-input"
            type="radio"
            formControlName="pre-assessment"
            id="option1" />
          <label class="form-check-label" for="option1">
            Yes, this is a regular part of my practice.
          </label>
        </div>
        <div class="form-check my-2">
          <input
            (change)="this.updateAnswer($event)"
            value="Sometimes"
            class="form-check-input"
            type="radio"
            formControlName="pre-assessment"
            id="option2" />
          <label class="form-check-label" for="option2">
            Sometimes, but I'd like to be consistent.
          </label>
        </div>
        <div class="form-check">
          <input
            (change)="this.updateAnswer($event)"
            value="No"
            class="form-check-input"
            type="radio"
            formControlName="pre-assessment"
            id="option3" />
          <label class="form-check-label" for="option3">
            No, but I'd like to start implementing this.
          </label>
        </div>
      </form>
    </div>
  </div>
  <div class="w-100 d-flex justify-content-end" footerContent>
    <button
      [disabled]="
        preAssessmentQuestionGroups.length === 0 || this.currentAnswer === null
      "
      (click)="nextQuestion()"
      class="btn btn-primary">
      Next
    </button>
  </div>
</app-modal>
