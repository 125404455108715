<div class="competency-level">
  <div class="competency-level-card card border-0 shadow" *ngIf="strand">
    <div
      class="d-flex"
      [ngClass]="[
        'bg-level-' + level,
        'card-header',
        'text-light',
        'text-end',
        'text-nowrap',
        'align-middle'
      ]">
      <div class="ms-auto">
        Level
        <i class="bi bi-{{ level }}-circle"></i>
      </div>
    </div>
    <div class="card-body p-2" tabindex="0">
      <app-wysiwyg-editor
        version="none"
        [maxHeight]="100"
        [editorContent]="strand.indicator.content"
        (editorContentEvent)="updateContent($event)" />
      <div *ngIf="badgeData" class="position-relative">
        <div
          *ngIf="badgeExists"
          class="competency-icon"
          [ngStyle]="{
            'background-image': 'url(' + badgeData.image + ')'
          }"></div>
        <app-file-upload
          #badgeGraphicUploadComponent
          (click)="setBadgeId()"
          (outputFiles)="badgeGraphicUploadedEvent.emit()"
          tooltipMessage="Upload badge graphic"
          [style]="uploadStyle"
          [uploadType]="uploadType"
          size="sm"
          customMessageAddOn="Badge" />
      </div>
    </div>
  </div>
</div>
