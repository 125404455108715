import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Subscription,
  map,
  skip,
  take,
  takeUntil,
} from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  CompetencyDTO,
  CompetencyListItemDTO,
} from '../../../dtos/attendee-rubric.dto';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { CoachDialogService } from '../../../services/smart-coach/coach-dialog/coach-dialog.service';
import { CoachingLogState } from '../../../state/coaching-log/coaching-log.state';
import {
  CompetenciesAPIResponse,
  CompetencyStandardAPIResponse,
  SessionPercentageAPIResponse,
} from '../../../types/responses/coaching-log.responses';
import { VideoModalComponent } from '../../modals/video-modal/video-modal.component';
import { TopicDTO } from '../../topic-select/topic-select.component';
import { CompetencyStatus } from '../select-competency/select-competency.helpers';

@Component({
  selector: 'app-select-competencies-screen',
  templateUrl: './select-competencies-screen.component.html',
  styleUrls: ['./select-competencies-screen.component.scss'],
})
export class SelectCompetenciesScreenComponent implements OnInit, OnDestroy {
  @Input() logId: number;

  letsGroweActive = false;

  @Input() initialSelectedCompetencies: CompetencyDTO[];

  @Output() hasCompetencySelected: EventEmitter<boolean> = new EventEmitter();

  @Input() attendeeIds: number[];

  @Input() isSmart = false;

  @Input() canEdit = true;

  loadCount = 1;

  @ViewChild('videoModal')
  videoModal: VideoModalComponent;

  attendeeRubrics$ = select(CoachingLogState.getAttendeeRubricsData);

  // visible to users
  competencyList: CompetencyListItemDTO[] = [];

  // keeps track of selected, loading
  competencyStatusList: CompetencyStatus[] = [];

  subs: Subscription[] = [];

  selectedVideo: VideoDTO;

  topics: TopicDTO[] = [];

  filteredTopic?: TopicDTO;

  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();

  filteredCompetencyList: CompetencyListItemDTO[] = [];

  listIsLoading = true;

  constructor(
    private coachingService: CoachingLogService,
    private smartCoachDialogService: CoachDialogService
  ) {}

  ngOnInit() {
    this.coachingService.getCompetencies(this.logId).subscribe((res) => {
      this.isLoading.emit(true);
      take(1);
      this.competencyList = res.items.flatMap((item: CompetenciesAPIResponse) =>
        item.standardSet.standardGroups.flatMap((standardGroup) => {
          this.topics.push({
            title: item.title,
            id: item.id,
          });
          // this filters topics down to unique values, because sometimes rubrics have multiple standard groups
          this.topics = this.topics.filter(
            (elem, index) =>
              this.topics.findIndex((obj) => obj.id === elem.id) === index
          );
          return standardGroup.standards.flatMap((standard) => {
            const standardDTO =
              standard as unknown as CompetencyStandardAPIResponse;
            const listItem: CompetencyListItemDTO = {
              content: standardDTO.content,
              id: standardDTO.id,
              title: standardDTO.title,
              rubricId: item.id,
              rubricTitle: item.title,
              icon: standardDTO.icon,
              loading: false,
              overviewVideo: standardDTO.overviewVideo
                ? standardDTO.overviewVideo
                : null,
              selected: false,
            };
            this.competencyStatusList.push({
              competencyId: listItem.id,
              loading: false,
              selected: false,
            });
            return listItem;
          });
        })
      );

      const hasData = new BehaviorSubject<boolean>(false);
      this.subs.push(
        this.attendeeRubrics$
          .pipe(map((filterFn) => filterFn(this.logId)))
          .pipe(takeUntil(hasData.pipe(skip(1))))
          .subscribe({
            next: (data) => {
              if (!data || !data.length) {
                return;
              }
              hasData.next(true);
              let competencyIds: number[] = [];
              if (data.length > 0) {
                competencyIds = data.flatMap((attendeeRubric) =>
                  attendeeRubric.rubrics.flatMap((rubric) =>
                    rubric.competencyGroups.flatMap((competencyGroup) =>
                      competencyGroup.competencies.map(
                        (competency) => competency.id
                      )
                    )
                  )
                );
              }
              if (competencyIds.length > 0) {
                this.hasCompetencySelected.emit(true);
                competencyIds.forEach((id) => {
                  this.competencyStatusList.forEach((status) => {
                    if (status.competencyId === id) {
                      status.selected = true;
                    }
                  });
                  this.competencyList.forEach((competency) => {
                    if (competency.id === id) {
                      competency.selected = true;
                    }
                  });
                });
              } else {
                this.hasCompetencySelected.emit(false);
              }
              this.checkIfLoading();
              this.filterCompetencies();
            },
            error: (err) => {
              this.listIsLoading = false;
              // eslint-disable-next-line no-console
              console.log('rubric loading error', err);
            },
          })
      );
    });
    if (this.isSmart && this.canEdit) {
      this.triggerDialog();
    }
  }

  triggerDialog() {
    this.smartCoachDialogService.setQueue(2);
    this.smartCoachDialogService.getDialogMessage(
      'g-start-',
      'select-competencies',
      1
    );
    this.smartCoachDialogService.getDialogMessage(
      'g-chooseAStandard-',
      'select-competencies',
      2
    );
  }

  checkIfLoading() {
    const loading = this.competencyStatusList.find(
      (status) => status.loading === true
    );
    this.isLoading.emit(!!loading);
  }

  checkIfCompetencySelected() {
    const selected = this.competencyStatusList.find(
      (status) => status.selected === true
    );
    this.hasCompetencySelected.emit(!!selected);
    this.checkIfLoading();
  }

  filterCompetencies() {
    if (!this.filteredTopic) {
      this.filteredCompetencyList = this.competencyList;
    } else {
      this.filteredCompetencyList = this.competencyList.filter(
        (competency) => competency.rubricId === this.filteredTopic?.id
      );
    }
    this.listIsLoading = false;
  }

  handleCompetencyEvent(event: CompetencyStatus): void {
    if (event.competencyId) {
      this.competencyStatusList = this.competencyStatusList.filter(
        (status) => status.competencyId !== event.competencyId
      );
      this.competencyStatusList.push(event);

      /* eslint-disable @typescript-eslint/no-unused-vars */
      this.coachingService
        .getSessionPercentage(this.logId)
        .subscribe((_response: SessionPercentageAPIResponse) => {});

      this.checkIfCompetencySelected();
    }
  }

  filterTopic(topic: TopicDTO) {
    if (topic === undefined) {
      delete this.filteredTopic;
    } else {
      this.filteredTopic = topic;
    }
    this.filterCompetencies();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
