export enum UploadType {
  EVIDENCE = 'evidence',
  RESOURCE_ATTACHMENT = 'resource-attachment',
  USER_UPLOAD = 'user-upload',
  VIDEO = 'video',
  LOG = 'log',
  BADGE = 'badge',
  STANDARD_ICON = 'standard-icon',
  MICROCREDENTIAL_BADGE = 'microcredential-badge',
  DELIVERABLE_ATTACHMENT = 'deliverable-attachment',
  ACTION_ITEM_ATTACHMENT = 'action-item-attachment',
}
