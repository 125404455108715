import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';

@Component({
  selector: 'app-learner-dashboard-page',
  templateUrl: './learner-dashboard.component.html',
  styleUrls: ['./learner-dashboard.component.scss'],
})
export class LearnerDashboardComponent {
  user: User | null = null;

  mscEnabled = false;

  constructor(
    private store: Store,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
  }
}
