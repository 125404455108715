<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-select
  placeholder="{{ placeholder }}"
  bindLabel="title"
  [labelForId]="labelId"
  [clearable]="true"
  [items]="topics"
  [loading]="searchLoading"
  (change)="topicSelected($event)"
  [(ngModel)]="internalSelectedTopic">
</ng-select>
