import { EChartsOption } from 'echarts';
import { ReportFiltersDTO } from 'src/app/private/pages/reporting-page/filters/report-filters.dto';
import { KpiReportFilter } from '../../dtos/reports.dto';
import { SchoolYearDTO } from '../../dtos/school.dto';
import { MOCK_DISTRICT } from '../../state/state.mocks';

export const MOCK_SCHOOL_YEAR: SchoolYearDTO = {
  id: 1,
  name: '2022-2023',
  start_date: '2022-08-01',
  end_date: '2023-07-31',
};

export const MOCK_KPI_REPORT_FILTER: KpiReportFilter = {
  codename: 'school_year',
  title: 'School Year',
  value: [MOCK_SCHOOL_YEAR.id.toString()],
};

export const MOCK_REPORT_FILTERS_DTO: ReportFiltersDTO = {
  schoolYearFilter: MOCK_SCHOOL_YEAR,
  districtFilter: [MOCK_DISTRICT],
};

export const MOCK_STACK_BAR_OPTIONS_DTO: EChartsOption = {
  grid: {
    left: '150px',
  },
  legend: {
    bottom: 0,
    icon: 'circle',
  },
  tooltip: {
    formatter:
      '<strong>{a}</strong> <br/> {c} GIs with at least one piece of Evidence',
  },
  color: ['#f68888', '#cd87c0', '#6fadcf', '#eda9cb'],
  xAxis: {
    type: 'value',
  },
  yAxis: {
    type: 'category',
    data: [
      'Assessment Formative Feedback',
      'Collaboration',
      'Communication',
      'Critical Analysis, Inquiry, Research',
      'Culture, Environment, Professional Ethics',
      'Diferentiation, Scaffolding',
      'Goal Setting, Autonomy, Entrepreneurship',
    ],
    axisLabel: {
      overflow: 'break',
      width: 140,
    },
  },
  series: [
    {
      name: 'Level 1',
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'self',
      },
      data: [320, 302, 301, 334, 390, 330, 320],
    },
    {
      name: 'Level 2',
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'self',
      },
      data: [120, 132, 101, 134, 90, 230, 210],
    },
    {
      name: 'Level 3',
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'self',
      },
      data: [220, 182, 191, 234, 290, 330, 310],
    },
    {
      name: 'Level 4',
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'self',
      },
      data: [150, 212, 201, 154, 190, 330, 410],
    },
  ],
  barWidth: 10,
};
