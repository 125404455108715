import { Component, OnInit } from '@angular/core';
import { ResourceOnlineCourseDTO } from '../../../dtos/resources.dto';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';

@Component({
  selector: 'app-online-course-list',
  templateUrl: './online-course-list.component.html',
  styleUrls: ['./online-course-list.component.scss'],
})
export class OnlineCourseListComponent implements OnInit {
  constructor(public resourcesBookmarkService: ResourcesBookmarkService) {}

  courseList: ResourceOnlineCourseDTO[];

  ngOnInit(): void {
    this.resourcesBookmarkService.getOnlineCourses().subscribe((data) => {
      this.courseList = data.items;
    });
  }
}
