import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class BadgeService {
  constructor(protected apiService: APICoreService) {}

  downloadBadgeGraphic(badgeId: number) {
    return this.apiService.getRequest(`egrowe/badges/${badgeId}/download`);
  }

  downloadBadgesCSV(userId: number) {
    return this.apiService.getRequest(
      `egrowe/earned-badges/user/${userId}/download`
    );
  }
}
