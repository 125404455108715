import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResourceFolderModelDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-folder-item',
  templateUrl: './folder-item.component.html',
  styleUrls: ['./folder-item.component.scss'],
})
export class FolderItemComponent {
  @Input() folder: ResourceFolderModelDTO;

  @Input() multiFolders: boolean;

  @Input() sharedFolder = false;

  @Output() readonly folderClicked: EventEmitter<boolean> = new EventEmitter();

  @Output() readonly copyClicked: EventEmitter<number> = new EventEmitter();

  @Output() readonly starClicked: EventEmitter<boolean> = new EventEmitter();

  folderClickedHandler() {
    this.folderClicked.emit(true);
  }

  copyClickedHandler() {
    this.copyClicked.emit(this.folder.id);
  }

  starClickedHandler() {
    this.starClicked.emit(true);
  }
}
