import { Component, Input } from '@angular/core';
import { IndicatorPreviewDTO } from 'src/app/private/shared/dtos/competencies.dto';

@Component({
  selector: 'app-preview-leveled-competency-strand-level',
  templateUrl: './preview-leveled-competency-strand-level.component.html',
  styleUrls: ['./preview-leveled-competency-strand-level.component.scss'],
})
export class PreviewLeveledCompetencyStrandLevelComponent {
  @Input() level: number;

  @Input() levelName: string;

  @Input() strand: IndicatorPreviewDTO;

  isDrawerCollapsed = true;

  isOptionCollapsed = true;
}
