import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AlertDTO {
  message: string;
  type: AlertType;
  location: AlertLocation;
}

export enum AlertLocation {
  TOPLEFT = 'top-left',
  TOPCENTER = 'top-center',
  TOPRIGHT = 'top-right',
  CENTER = 'center',
  BOTTOMLEFT = 'bottom-left',
  BOTTOMCENTER = 'bottom-center',
  BOTTOMRIGHT = 'bottom-right',
}

export enum AlertType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  NEUTRAL = 'neutral',
  DANGER = 'danger',
  INFO = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  defaultSpecs: AlertDTO = {
    message: 'This is an alert',
    location: AlertLocation.TOPCENTER,
    type: AlertType.PRIMARY,
  };

  public alertSpecs: BehaviorSubject<AlertDTO> = new BehaviorSubject(
    this.defaultSpecs
  );

  public showAlertSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /**
   * @param message
   * @param location
   * @param type
   *
   * Adds the next message, alert location, and alert color to the next
   * alertSpecs subscription.
   *
   * The next alert subject will be set to show
   */
  showAlert(message: string, location?: AlertLocation, type?: AlertType) {
    const currentSpecs = {} as AlertDTO;
    currentSpecs.message = message;
    currentSpecs.location = location || this.defaultSpecs.location;
    currentSpecs.type = type || this.defaultSpecs.type;
    this.alertSpecs.next(currentSpecs);
    this.showAlertSubject.next(true);
  }

  /**
   * Sets the next alert subject to be hidden
   */
  hideAlert() {
    this.showAlertSubject.next(false);
  }
}
