<div class="container all-celebrations-container">
  <app-general-header title="Celebrations"></app-general-header>
  <div class="card border-0 p-3">
    <div class="row justify-content-between align-items-center">
      <div
        *ngIf="user"
        class="col-6 d-flex align-items-center justify-content-center justify-content-lg-start fw-800">
        <app-avatar [profile]="user.profile" avatarSize="small" />
        <span class="mx-2"> Me Mode </span>
        <div class="form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            aria-label="celebrations-page-me-mode"
            [(ngModel)]="ownOnly"
            (ngModelChange)="getCelebrations()" />
        </div>
      </div>
      <div class="col-6 text-center text-lg-end">
        <button (click)="openCreateCelebrationModal()" class="btn btn-primary">
          <i class="bi bi-plus"></i> Create a Celebration
        </button>
      </div>
    </div>
    <ng-container *ngIf="celebrations">
      <div class="row mt-3">
        <div class="col d-flex">
          <div class="d-flex flex-row align-items-center">
            <div class="filter-wrapper">
              <app-applied-filters
                (filterRemoved)="removeFilter($event)"
                (filterListRemoved)="removeFilterList($event)"
                filterType="celebrations"
                [canSeeAllSchools]="canSeeAllSchools"
                [dateChange]="filterOptions.fromDate"
                [dateChange]="filterOptions.toDate"
                [celebrationSearchOptions]="
                  filterOptions
                "></app-applied-filters>
            </div>
            <button
              type="button"
              class="btn btn-link"
              (click)="showFilterModal()">
              Edit Filters
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <app-create-edit-celebration-modal
      (createCelebrationEvent)="getCelebrations()"
      [editCelebration]="editCelebration"
      [user]="user"
      #celebrationModal />
    <div class="purple-border my-3"></div>
    <ng-container *ngIf="celebrations; else isLoading">
      <ng-container *ngIf="celebrations.length < 1">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 500px">
          <h2>No Results Found</h2>
          <p>Please check your filters and try again.</p>
        </div>
      </ng-container>
      <div class="card-body">
        <div *ngFor="let celebration of celebrations">
          <app-celebration-item
            [user]="user"
            (editCelebrationEvent)="openEditModal($event)"
            (deleteCelebrationEvent)="deleteCelebration($event)"
            [celebration]="celebration"></app-celebration-item>
        </div>
      </div>
      <div *ngIf="listMeta && listMeta.totalCount > 12" class="row mt-4">
        <div class="d-flex justify-content-center">
          <ngb-pagination
            [(page)]="listMeta.currentPage"
            [pageSize]="listMeta.perPage"
            [collectionSize]="listMeta.totalCount"
            (pageChange)="getCelebrations()"
            [maxSize]="5"
            [ellipses]="true"></ngb-pagination>
        </div>
      </div>
    </ng-container>
    <ng-template #isLoading>
      <div class="w-100" style="background-color: #fff; height: 700px">
        <app-loader></app-loader>
      </div>
    </ng-template>
  </div>
  <app-celebrations-filter-modal
    #celebrationFilterModal
    (setFilters)="setFilters($event)" />
</div>
