/* eslint-disable max-classes-per-file */
import { PusherAssignmentChangesDTO } from 'src/app/private/shared/services/plans/plans.service.dto';
import {
  ActionItemAPIResponse,
  CreatePhaseAPIResponse,
  DeliverableAPIResponse,
  EditActionItemAPIResponse,
  EditDeliverableAPIResponse,
  EditPhaseAPIResponse,
  EditPlanAPIResponse,
  StatusType,
} from '../../types/responses/plan.responses';

export class FetchPlanDetails {
  static readonly type = '[Implementation Plan] Fetch Plan Details';

  constructor(public payload: number) {}
}

// Accordion Toggle State
export class TogglePhaseExpandedState {
  static readonly type = '[Implementation Plan] Toggle Phase Expanded State';

  constructor(public payload: { phaseId: number }) {}
}

export class ToggleDeliverableExpandedState {
  static readonly type =
    '[Implementation Plan] Toggle Deliverable Expanded State';

  constructor(public payload: { deliverableId: number }) {}
}

// Phase CRUD

export class EditPlan {
  static readonly type = '[Implementation Plan] Edit Plan';

  constructor(public payload: EditPlanAPIResponse) {}
}
export class AddPhase {
  static readonly type = '[Implementation Plan] Add Phase';

  constructor(public payload: { phase: CreatePhaseAPIResponse }) {}
}

export class EditPhase {
  static readonly type = '[Implementation Plan] Edit Phase';

  constructor(public payload: { phase: EditPhaseAPIResponse }) {}
}

export class DeletePhase {
  static readonly type = '[Implementation Plan] Delete Phase';

  constructor(public payload: { phaseId: number }) {}
}

// Statuses
export class UpdatePhaseStatus {
  static readonly type = '[Implementation Plan] Update Phase Status';

  constructor(public payload: { phaseId: number; status: StatusType }) {}
}

export class CreateDeliverable {
  static readonly type = '[Implementation Plan] Add Deliverable';

  constructor(public payload: DeliverableAPIResponse) {}
}

export class EditDeliverable {
  static readonly type = '[Implementation Plan] Edit Deliverable';

  constructor(public payload: EditDeliverableAPIResponse) {}
}

export class DeleteDeliverable {
  static readonly type = '[Implementation Plan] Delete Deliverable';

  constructor(
    public payload: { id: number; implementation_phase_id: number }
  ) {}
}

export class CreateActionItem {
  static readonly type = '[Implementation Plan] Add Action Item';

  constructor(public payload: ActionItemAPIResponse) {}
}

export class EditActionItem {
  static readonly type = '[Implementation Plan] Edit Action Item';

  constructor(public payload: EditActionItemAPIResponse) {}
}

export class DeleteActionItem {
  static readonly type = '[Implementation Plan] Delete Action Item';

  constructor(
    public payload: {
      id: number;
      implementation_phase_id: number;
      implementation_deliverable_id: number;
    }
  ) {}
}

export class AddAssignment {
  static readonly type = '[Implementation Plan] Add Assignment';

  constructor(public payload: PusherAssignmentChangesDTO) {}
}

export class DeleteAssignment {
  static readonly type = '[Implementation Plan] Delete Assignment';

  constructor(public payload: PusherAssignmentChangesDTO) {}
}
