<div class="field">
  <label class="form-label" [for]="'type' + id">Text Type</label>
  <ng-select
    [labelForId]="'type' + id"
    [items]="textFieldOptions"
    [multiple]="false"
    [closeOnSelect]="true"
    [clearable]="false"
    [searchable]="false"
    [ngModel]="data.type"
    (change)="typeChange($event)"
    bindLabel="label"
    bindValue="value"
    class="growelab"
    placeholder="Select a type"></ng-select>
  <app-form-edit-field-label
    [fieldType]="fieldTypes.TEXT"
    #label
    [(data)]="data.label"></app-form-edit-field-label>
</div>
