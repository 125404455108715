import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

interface StoredCard {
  cardname: string;
  dashboard: string;
  collapsed: boolean;
}

const key = 'dashboardCards';

@Injectable({ providedIn: 'root' })
export class CardStorageService {
  cards: StoredCard[] = [];

  constructor(private router: Router) {
    this.getCards();
  }

  getCards() {
    const cards = window.localStorage.getItem(key);
    if (cards) {
      this.cards = JSON.parse(cards);
    }
  }

  storeCollapseState(cardName: string, isCollapsed: boolean) {
    const dashboardType = this.router.url.replace('/dashboard/', '');
    const item: StoredCard = {
      cardname: cardName,
      dashboard: dashboardType,
      collapsed: isCollapsed,
    };

    const matchedCard = this.cards.filter(
      (card: StoredCard) =>
        card.cardname === cardName && card.dashboard === dashboardType
    )[0];

    if (!matchedCard || !this.cards) {
      this.cards.push(item);
    } else {
      this.cards = this.cards.map((card) =>
        card === matchedCard ? item : card
      );
    }

    window.localStorage.setItem(key, JSON.stringify(this.cards));
  }

  getCollapsedState(cardName: string) {
    const dashboardType = this.router.url.replace('/dashboard/', '');
    if (this.cards) {
      const matchedCard = this.cards.filter(
        (card: StoredCard) =>
          card.cardname === cardName && card.dashboard === dashboardType
      )[0];
      if (matchedCard) {
        return matchedCard.collapsed;
      }
    }
    return false;
  }
}
