<div class="container">
  <app-general-header title="Coaching Sessions" />
  <div class="card border-0 p-3">
    <div class="row justify-content-between mb-2">
      <div *ngIf="canSeeMeMode" class="col d-flex align-items-center fw-800">
        <app-avatar [profile]="user.profile" avatarSize="small" />
        <span class="mx-2"> Me Mode </span>
        <div class="form-switch">
          <input
            class="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            aria-label="coaching-sessions-page-me-mode"
            [(ngModel)]="ownOnly"
            (ngModelChange)="handleMeMode()" />
        </div>
      </div>
      <div class="col">
        <button
          type="button"
          *ngIf="this.user && canCreateLog"
          class="btn btn-primary float-end"
          (click)="createSessionParentModal.openModal()">
          <i class="bi bi-plus"></i> Create a Session
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex flex-row align-items-center">
          <app-applied-filters
            *ngIf="showAppliedFilters"
            (filterRemoved)="removeFilter($event)"
            (filterListRemoved)="removeFilterFromList($event)"
            filterType="coaching-sessions"
            [canSeeAllSchools]="canSeeAllSchools"
            [coachlogSearchOptions]="
              coachlogSearchOptions
            "></app-applied-filters>
          <button
            type="button"
            class="btn btn-link"
            (click)="showFilterModal()">
            Edit Filters
          </button>
        </div>
        <hr class="border border-primary border-2" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-container *ngIf="!loading; else isLoading">
          <ng-container *ngIf="sessions.length > 0; else noSessions">
            <app-coaching-session-list
              [sessions]="sessions"
              [pagination]="sessionsMetaData"
              (pageChanged)="pageChanged($event)"></app-coaching-session-list>
          </ng-container>
          <ng-template #noSessions>
            <div
              class="no-sessions d-flex flex-column align-items-center justify-content-center"
              style="background-color: #fff; min-height: 400px">
              <h2>No Sessions Found</h2>
              <p>Please check your filters and try again.</p>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #isLoading>
          <div class="w-100" style="background-color: #fff; height: 700px">
            <app-loader></app-loader>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <app-coaching-session-filter-modal
    *ngIf="displayFilterModal"
    (setFilters)="setFilters($event)"
    (modalClose)="closeFilterModal()"
    [initialFilters]="coachingSessionFilters"
    [user]="user"
    #coachingSessionFilterModal />
  <app-create-session-modal #createSessionParentModal />
</div>
