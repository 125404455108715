import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PlansService } from '../../../services/plans/plans.service';
import { CommentModelDTO } from './plans-comments.dto';

@Component({
  selector: 'app-plans-comments',
  templateUrl: './plans-comments.component.html',
  styleUrls: ['./plans-comments.component.scss'],
})
export class PlansCommentsComponent {
  @Input() refTable: string;

  @Input() refTableId: number;

  @Input() commentList: CommentModelDTO[] = [];

  @Output() readonly commentDeleted = new EventEmitter<boolean>();

  newComment: CommentModelDTO = new CommentModelDTO();

  currentUser: User;

  constructor(private plansService: PlansService, private store: Store) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
  }

  saveComment() {
    this.newComment.ref_table = this.refTable;
    this.newComment.ref_table_id = this.refTableId;
    this.plansService.saveComment(this.newComment).subscribe((result) => {
      if (result.item) {
        this.commentList.push(result.item);
        this.newComment.comment = '';
      }
    });
  }

  deleteComment(comment: CommentModelDTO) {
    this.plansService.deleteComment(comment).subscribe((response) => {
      if (response) {
        this.commentList = this.commentList.filter(
          (item) => item.id !== comment.id
        );
        this.commentDeleted.emit(true);
      }
    });
  }
}
