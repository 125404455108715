import { Injectable } from '@angular/core';
import { ModalComponent } from '../../components/modals/modal/modal.component';
import { SelectResourceComponent } from '../../components/resources/select-resource/select-resource.component';

@Injectable({
  providedIn: 'root',
})
export class ResourceSearchModalService {
  public modalRef: ModalComponent;

  public selectResourceRef: SelectResourceComponent;

  addModalRef(modalRef: ModalComponent) {
    this.modalRef = modalRef;
  }

  addResourceSelectRef(resourceSelectRef: SelectResourceComponent) {
    this.selectResourceRef = resourceSelectRef;
  }
}
