<div class="container">
  <app-general-header title="Resources" />
  <div class="row my-4 justify-content-center">
    <div class="col-xl-8">
      <div
        class="fw-600 fs-18 rounded p-3 bg-secondary text-center justify-content-center align-items-center d-flex flex-column flex-md-row">
        <div class="mb-2 mb-md-0">You are currently viewing</div>
        <div class="d-flex align-items-center">
          <span class="mx-2">
            <app-avatar
              [hideTooltip]="true"
              [profile]="folderOwner.profile"
              [avatarSize]="'small'"
              [profileLinkActive]="false"
          /></span>
          {{ folderOwner.profile.first_name }}
          {{ folderOwner.profile.last_name }}'s Folder
        </div>
      </div>
    </div>
  </div>
  <div class="card border-0 p-3">
    <div class="card-body">
      <ng-container *ngIf="folder; else loadingTemplate">
        @if(folder.breadCrumb) {
        <app-resources-breadcrumb
          [sharedFolder]="true"
          [folderOwner]="folderOwner"
          [breadcrumbs]="folder.breadCrumb" />
        }

        <div class="mt-4 mb-5">
          <div class="folder-title">
            {{ folder.title }}
          </div>
          <p>{{ folder.description }}</p>
        </div>

        <ng-container
          *ngIf="folder.childShelves && folder.childShelves.length > 0">
          <h2 class="fs-25 fw-800">Folders</h2>
          @for (folder of folder.childShelves; track folder) {
          <app-folder-item
            [folder]="folder"
            [sharedFolder]="true"
            [multiFolders]="false"
            (folderClicked)="selectFolder(folder.id)"></app-folder-item>
          }
        </ng-container>

        <h2 class="fs-25 fw-800 mt-5 mb-3">Resources</h2>
        <ng-container *ngIf="folderItems; else loading">
          <ng-container *ngIf="folderItems.length > 0; else noResults">
            <ng-container *ngFor="let resource of folderItems">
              <ng-container *ngIf="resource.type !== 'course'">
                <app-resource-list-item
                  [resourceListItem]="resource"></app-resource-list-item>
              </ng-container>

              <ng-container *ngIf="resource.type == 'course'">
                <app-online-course [course]="resource"></app-online-course>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="d-flex justify-content-center">
            <ngb-pagination
              *ngIf="folderListMeta && folderListMeta.totalCount > 10"
              [(page)]="folderListMeta.currentPage"
              [pageSize]="folderListMeta.perPage"
              [collectionSize]="folderListMeta.totalCount"
              (pageChange)="paginationChange()"
              [maxSize]="5"
              [ellipses]="true"></ngb-pagination>
          </div>
        </ng-container>

        <ng-template #noResults>
          <div
            class="no-sessions d-flex flex-column align-items-center justify-content-center fs-18"
            style="background-color: #fff; min-height: 200px">
            <p>
              {{ folderOwner.profile.first_name }}
              {{ folderOwner.profile.last_name }} has not saved any resources to
              this folder.
            </p>
          </div>
        </ng-template>
        <ng-template #loading>
          <div
            class="w-100 text-center"
            style="height: 200px; position: relative">
            <app-loader></app-loader>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="w-100 text-center" style="height: 400px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
