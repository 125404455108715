import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requireUppercaseLetterValidator(): ValidatorFn {
  return function validate(control: AbstractControl): ValidationErrors | null {
    const hasUppercaseLetter = control.value && /[A-Z]/.test(control.value);

    return hasUppercaseLetter
      ? null
      : { requireUppercaseLetter: { value: control.value } };
  };
}

export function requireLowercaseLetterValidator(): ValidatorFn {
  return function validate(control: AbstractControl): ValidationErrors | null {
    const hasLowercaseLetter = control.value && /[a-z]/.test(control.value);

    return hasLowercaseLetter
      ? null
      : { requireLowercaseLetter: { value: control.value } };
  };
}

export function requireNumberValidator(): ValidatorFn {
  return function validate(control: AbstractControl): ValidationErrors | null {
    const hasNumber = control.value && /[0-9]/.test(control.value);

    return hasNumber ? null : { requireNumber: { value: control.value } };
  };
}

export function confirmPasswordValidator(): ValidatorFn {
  return function validate(control: AbstractControl): ValidationErrors | null {
    const passwordsMatch =
      control.value.password === control.value.confirmPassword;

    return passwordsMatch ? null : { PasswordMatchError: true };
  };
}
