import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormControlOptions,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, zip } from 'rxjs';
import {
  DistrictDTO,
  DistrictEmailNotificationDTO,
  DistrictGoalItemDTO,
  DistrictPayloadDTO,
  EmailNotificationPayload,
} from 'src/app/common/dtos/district.dto';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import {
  UpdateUserDistrict,
  UserState,
} from 'src/app/common/state/user/user.state';
import { booleanNumberFromBoolean } from 'src/app/common/utilities/enums/boolean-number.enum';
import {
  negativeNumberValidator,
  percentageValidator,
} from 'src/app/common/utilities/forms/validators.forms';
import { GoalType } from '../../shared/enums/goal-type.enum';
import { districtEmailNotificationDTOfromAPIResponse } from '../../shared/helpers/translators/districts.translators';
import { AlertService } from '../../shared/services/alert/alert.service';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';

type SessionsPerCoacheeGoal = {
  value: number;
  title: string;
  id: number | null;
};

@Component({
  selector: 'app-configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss'],
})
export class ConfigurationPageComponent implements OnInit {
  isPageLoading: boolean;

  districts: DistrictDTO[];

  selectedDistrict: DistrictDTO | undefined;

  districtCode: string;

  user: User;

  configurationForm: FormGroup;

  selectedYear: SchoolYearDTO | null = null;

  selectedDefaultPrivateLogs = false;

  emailNotifications: DistrictEmailNotificationDTO[] = [];

  notificationsUpdated: EmailNotificationPayload[];

  emailSettingsEnabled = false;

  sessionsPerCoacheeGoal: SessionsPerCoacheeGoal = {
    value: 7,
    title: GoalType.CoachingSessionsPerCoacheePerYear,
    id: null,
  };

  attendancePercentageGoal: SessionsPerCoacheeGoal = {
    value: 90,
    title: GoalType.AttendeesPresent,
    id: null,
  };

  observationsPerCoacheeGoal: SessionsPerCoacheeGoal = {
    value: 5,
    title: GoalType.ObservationsPerCoacheePerYear,
    id: null,
  };

  celebrationsPerUserGoal: SessionsPerCoacheeGoal = {
    value: 2,
    title: GoalType.CelebrationsPerUserPerYear,
    id: null,
  };

  badgesPerCoacheePerSessionGoal: SessionsPerCoacheeGoal = {
    value: 1,
    title: GoalType.BadgesPerCoacheePerSession,
    id: null,
  };

  competencyBadgesPercentageGoal: SessionsPerCoacheeGoal = {
    value: 90,
    title: GoalType.CoacheesEarnCompetencyBadges,
    id: null,
  };

  yearSelected = false;

  constructor(
    private route: ActivatedRoute,
    private districtListService: DistrictListService,
    private apiCoreService: APICoreService,
    private store: Store,
    private alertServce: AlertService,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.emailSettingsEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.emailNotificationSettings
    );
  }

  ngOnInit(): void {
    this.notificationsUpdated = [];

    // Start Page Loader
    this.isPageLoading = true;

    // Grab District Code
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;

    // Form Creation
    this.configurationForm = new FormGroup({
      sessionsPerCoacheeGoal: new FormControl(
        this.sessionsPerCoacheeGoal.value,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^-?[0-9]*\.?[0-9]+$/),
            negativeNumberValidator,
          ],
        } as FormControlOptions
      ),
      attendancePercentageGoal: new FormControl(
        this.attendancePercentageGoal.value,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^-?[0-9]*\.?[0-9]+$/),
            percentageValidator,
          ],
        } as FormControlOptions
      ),
      observationsPerCoacheeGoal: new FormControl(
        this.observationsPerCoacheeGoal.value,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^-?[0-9]*\.?[0-9]+$/),
            negativeNumberValidator,
          ],
        } as FormControlOptions
      ),
      celebrationsPerUserGoal: new FormControl(
        this.celebrationsPerUserGoal.value,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^-?[0-9]*\.?[0-9]+$/),
            negativeNumberValidator,
          ],
        } as FormControlOptions
      ),
      badgesPerCoacheePerSessionGoal: new FormControl(
        this.badgesPerCoacheePerSessionGoal.value,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^-?[0-9]*\.?[0-9]+$/),
            negativeNumberValidator,
          ],
        } as FormControlOptions
      ),
      competencyBadgesPercentageGoal: new FormControl(
        this.competencyBadgesPercentageGoal.value,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^-?[0-9]*\.?[0-9]+$/),
            percentageValidator,
          ],
        } as FormControlOptions
      ),
      selectedDefaultPrivateLogs: new FormControl(
        this.selectedDefaultPrivateLogs
      ),
    });

    // Fill out Form and close loader
    this.districtListService
      .fetchDistrictsSimple({ per_page: 10000 })
      .subscribe((districts) => {
        this.districts = districts as DistrictDTO[];
        this.selectedDistrict = this.districts.find(
          (district) => district.districtCode === this.districtCode
        );

        if (this.selectedDistrict?.defaultPrivateLogs) {
          this.configurationForm.patchValue({
            selectedDefaultPrivateLogs: true,
          });
        } else {
          this.configurationForm.patchValue({
            selectedDefaultPrivateLogs: false,
          });
        }

        if (this.selectedDistrict?.reportingSchoolYear) {
          this.apiCoreService
            .getRequest(
              `reporting/school-years/by-name/${this.selectedDistrict.reportingSchoolYear}`
            )
            .subscribe((schoolYear) => {
              this.selectedYear = schoolYear.item as SchoolYearDTO;
            });
        }

        if (this.selectedDistrict?.id) {
          this.getEmailNotifications();
          this.apiCoreService
            .getRequest(
              `growelab/district-goals/for-district/${this.selectedDistrict.id}`
            )
            .subscribe((results) => {
              if (results) {
                this.configurationForm.patchValue({
                  sessionsPerCoacheeGoal: this.queryDistrictGoal(
                    this.sessionsPerCoacheeGoal.title,
                    results.items,
                    this.sessionsPerCoacheeGoal.value
                  ),
                  attendancePercentageGoal:
                    Number(
                      this.queryDistrictGoal(
                        this.attendancePercentageGoal.title,
                        results.items,
                        this.attendancePercentageGoal.value
                      )
                    ) * 100,
                  observationsPerCoacheeGoal: this.queryDistrictGoal(
                    this.observationsPerCoacheeGoal.title,
                    results.items,
                    this.observationsPerCoacheeGoal.value
                  ),
                  celebrationsPerUserGoal: this.queryDistrictGoal(
                    this.celebrationsPerUserGoal.title,
                    results.items,
                    this.celebrationsPerUserGoal.value
                  ),
                  badgesPerCoacheePerSessionGoal: this.queryDistrictGoal(
                    this.badgesPerCoacheePerSessionGoal.title,
                    results.items,
                    this.badgesPerCoacheePerSessionGoal.value
                  ),
                  competencyBadgesPercentageGoal:
                    Number(
                      this.queryDistrictGoal(
                        this.competencyBadgesPercentageGoal.title,
                        results.items,
                        this.competencyBadgesPercentageGoal.value
                      )
                    ) * 100,
                });

                this.sessionsPerCoacheeGoal.id = this.queryDistrictID(
                  this.sessionsPerCoacheeGoal.title,
                  results.items
                );

                this.attendancePercentageGoal.id = this.queryDistrictID(
                  this.attendancePercentageGoal.title,
                  results.items
                );

                this.observationsPerCoacheeGoal.id = this.queryDistrictID(
                  this.observationsPerCoacheeGoal.title,
                  results.items
                );

                this.celebrationsPerUserGoal.id = this.queryDistrictID(
                  this.celebrationsPerUserGoal.title,
                  results.items
                );

                this.badgesPerCoacheePerSessionGoal.id = this.queryDistrictID(
                  this.badgesPerCoacheePerSessionGoal.title,
                  results.items
                );

                this.competencyBadgesPercentageGoal.id = this.queryDistrictID(
                  this.competencyBadgesPercentageGoal.title,
                  results.items
                );
              }

              this.isPageLoading = false;
            });
        }
      });
  }

  get f() {
    return this.configurationForm.controls;
  }

  onSubmit() {
    let yearPayload: DistrictPayloadDTO;
    const submitPayload = {
      districtGoals: [
        {
          id: this.sessionsPerCoacheeGoal.id,
          value: this.configurationForm.value.sessionsPerCoacheeGoal,
        },
        {
          id: this.attendancePercentageGoal.id,
          value:
            Number(this.configurationForm.value.attendancePercentageGoal) / 100,
        },
        {
          id: this.observationsPerCoacheeGoal.id,
          value: this.configurationForm.value.observationsPerCoacheeGoal,
        },
        {
          id: this.celebrationsPerUserGoal.id,
          value: this.configurationForm.value.celebrationsPerUserGoal,
        },
        {
          id: this.badgesPerCoacheePerSessionGoal.id,
          value: this.configurationForm.value.badgesPerCoacheePerSessionGoal,
        },
        {
          id: this.competencyBadgesPercentageGoal.id,
          value:
            Number(
              this.configurationForm.value.competencyBadgesPercentageGoal
            ) / 100,
        },
      ],
    };

    if (this.selectedDistrict) {
      yearPayload = {
        title: this.selectedDistrict.title,
        domain: this.selectedDistrict.domain,
        is_internal: this.selectedDistrict.isInternal,
        is_b2b: this.selectedDistrict.isB2b,
        address: this.selectedDistrict.address,
        address2: this.selectedDistrict.address2,
        city: this.selectedDistrict.city,
        state: this.selectedDistrict.state,
        zip: this.selectedDistrict.zip,
        phone: this.selectedDistrict.phone,
        logo: this.selectedDistrict.logo,
        timezone: this.selectedDistrict.timezone,
        hubspot_company_id: this.selectedDistrict.hubspotCompanyId,
        district_code: this.selectedDistrict.districtCode,
        data_contact_email: '',
        why: '',
        reporting_school_year: this.selectedYear?.name
          ? (this.selectedYear.name as string)
          : '',
        default_private_logs: this.configurationForm.value
          .selectedDefaultPrivateLogs
          ? 1
          : 0,
      };

      const goalsCall$ = this.apiCoreService
        .putRequest('growelab/district-goals', submitPayload)
        .pipe(
          catchError((error) => {
            throw error;
          })
        );

      const districtCall$ = this.districtListService
        .updateDistrict(this.selectedDistrict.id.toString(), yearPayload)
        .pipe(
          catchError((error) => {
            throw error;
          })
        );

      // Check if notification updates
      if (this.notificationsUpdated.length > 0) {
        const submitEmailNotificationPayload = {
          email_notifications: this.notificationsUpdated,
        };
        this.notificationsUpdated = [];

        const emailNotificationCall$ = this.districtListService
          .updateDistrictEmaillNotifications(
            submitEmailNotificationPayload,
            this.selectedDistrict?.id
          )
          .pipe(
            catchError((error) => {
              throw error;
            })
          );

        zip(goalsCall$, districtCall$, emailNotificationCall$).subscribe({
          next: ([apiResponse, districtResponse, emailResponse]) => {
            if (apiResponse && districtResponse && emailResponse) {
              if (
                this.user?.district &&
                this.user.district.id === districtResponse.item.id
              ) {
                this.user.district.reportingSchoolYear =
                  districtResponse.item.reporting_school_year;

                if (districtResponse.item.default_private_logs === '1') {
                  this.user.district.defaultPrivateLogs = true;
                } else {
                  this.user.district.defaultPrivateLogs = false;
                }

                this.store.dispatch(new UpdateUserDistrict(this.user.district));
              }
              this.alertServce.showAlert('Configuration Form Saved!');
            }
          },
          error: (error) => {
            if (error.apiError && error.districtError) {
              throw new Error('Both services failed.');
            } else if (error.apiError) {
              throw new Error('Goals call failed.');
            } else if (error.districtError) {
              throw new Error('District call failed.');
            } else {
              throw new Error('Unexpected error format.');
            }
          },
        });
      } else {
        zip(goalsCall$, districtCall$).subscribe({
          next: ([apiResponse, districtResponse]) => {
            if (apiResponse && districtResponse) {
              if (
                this.user?.district &&
                this.user.district.id === districtResponse.item.id
              ) {
                this.user.district.reportingSchoolYear =
                  districtResponse.item.reporting_school_year;

                if (districtResponse.item.default_private_logs === '1') {
                  this.user.district.defaultPrivateLogs = true;
                } else {
                  this.user.district.defaultPrivateLogs = false;
                }

                this.store.dispatch(new UpdateUserDistrict(this.user.district));
              }
              this.alertServce.showAlert('Configuration Form Saved!');
            }
          },
          error: (error) => {
            if (error.apiError && error.districtError) {
              throw new Error('Both services failed.');
            } else if (error.apiError) {
              throw new Error('Goals call failed.');
            } else if (error.districtError) {
              throw new Error('District call failed.');
            } else {
              throw new Error('Unexpected error format.');
            }
          },
        });
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  queryDistrictGoal(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[],
    originalValue: number
  ): number {
    let i;
    for (i = 0; i < goalItems.length; i += 1) {
      if (goalTitle === goalItems[i].goal.title) {
        return Number(goalItems[i].value);
      }
    }
    return originalValue;
  }

  // eslint-disable-next-line class-methods-use-this
  queryDistrictID(
    goalTitle: string,
    goalItems: DistrictGoalItemDTO[]
  ): number | null {
    let i;
    for (i = 0; i < goalItems.length; i += 1) {
      if (goalTitle === goalItems[i].goal.title) {
        return Number(goalItems[i].id);
      }
    }
    return null;
  }

  onYearSelected(year: SchoolYearDTO | null) {
    if (year) {
      this.yearSelected = true;
      this.selectedYear = year;
    }
  }

  getEmailNotifications() {
    if (this.selectedDistrict?.id) {
      this.districtListService
        .fetchEmailNotifications(this.selectedDistrict.id)
        .subscribe((resp) => {
          if (resp.items) {
            this.emailNotifications = resp.items.map((item) =>
              districtEmailNotificationDTOfromAPIResponse(item)
            );
          }
        });
    }
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      const notificationsIndex = this.notificationsUpdated.findIndex(
        (value) => value.email_notification_id === parseInt(target.id)
      );

      if (notificationsIndex >= 0) {
        this.notificationsUpdated[notificationsIndex].is_enabled =
          booleanNumberFromBoolean(target.checked);
      } else {
        this.notificationsUpdated.push({
          email_notification_id: parseInt(target.id, 10),
          is_enabled: booleanNumberFromBoolean(target.checked),
        });
      }
    }
  }
}
