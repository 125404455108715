<label class="form-label ms-2" [for]="id">{{ fieldDefinition.label }}</label>
<div
  *ngIf="inputDisabled"
  class="wysiwyg-editor ms-3"
  [innerHTML]="initialValue"></div>
<div *ngIf="!inputDisabled" class="form-group wysiwyg rounded mx-1">
  <app-wysiwyg-editor
    (editorContentEvent)="this.value.emit($event)"
    [config]="editorConfig" />
</div>
