import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription, map } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { APILoadingStatus } from 'src/app/common/types/types';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { FileUploadComponent } from 'src/app/private/shared/components/file-management/file-upload/file-upload.component';
import {
  FroalaEnterOutput,
  WysiwygEditorConfig,
} from 'src/app/private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { CelebrationDTO } from 'src/app/private/shared/dtos/celebrations.dto';
import { CoachingSessionDTO } from 'src/app/private/shared/dtos/coaching-session.dto';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import { PusherAttendeeDTO } from 'src/app/private/shared/dtos/pusher-user.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { fileDTOFromEvidenceAttachment } from 'src/app/private/shared/helpers/translators/evidence.translators';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { LogFileService } from 'src/app/private/shared/services/file/log.file.service';
import { GeneralLogService } from 'src/app/private/shared/services/general/general-log.service';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';

@Component({
  selector: 'app-general-log-page',
  templateUrl: './general-log-page.component.html',
  styleUrls: ['./general-log-page.component.scss'],
})
export class GeneralLogPageComponent implements OnInit, OnDestroy {
  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO;

  coachLogStatus$ = select(CoachingLogState.getCoachLogStatus);

  coachLogStatus: APILoadingStatus;

  sessionLoaded = false;

  user: User;

  hasPresentAttendee = true;

  isCoach = false;

  isShadower = false;

  initialContent: string;

  isSaving = false;

  editorConfig: WysiwygEditorConfig;

  uploadType = UploadType.LOG;

  attachments: FileDTO[] | undefined;

  celebrations: CelebrationDTO[] | null = null;

  subs: Subscription[] = [];

  pusherAttendees: PusherAttendeeDTO[];

  @Output() readonly uploads = new EventEmitter<FileDTO>();

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    public coachingService: CoachingLogService,
    private generalLogService: GeneralLogService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.coachingService.currentPage = 'general-log';
      this.store.dispatch(new FetchSessionData(this.sessionId));

      this.editorConfig = {
        initialContent: this.initialContent,
        useCodox: true,
        docId: this.sessionId.toString(),
        fontFamilySelection: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        editId: `ContentEditor-${this.sessionId}`,
        pastePlain: true,
        enter: FroalaEnterOutput.INSERT_BR,
      };

      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            if (sessionData) {
              this.sessionData = sessionData;
              this.initialContent = this.sessionData.sessionNotes;
              if (sessionData.attachments) {
                this.attachments = this.sessionData.attachments?.map(
                  (attachment) => fileDTOFromEvidenceAttachment(attachment)
                );
              }
              setTimeout(() => {
                if (this.sessionId && this.uploadComponent) {
                  (this.uploadComponent.fileService as LogFileService).setLogId(
                    this.sessionId
                  );
                }
              }, 500);
            }
          })
        )
        .subscribe();

      this.coachLogStatus$.subscribe((status) => {
        if (status.loaded) {
          this.coachLogStatus = status;
          this.sessionLoaded = true;
        }
      });
    });

    this.subs.push(
      this.coachingService.attendancePusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );

    this.subs.push(
      this.coachingService.logPusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );
  }

  getAttendees() {
    const attendeesOnPage =
      this.coachingService.attendancePusherService.attendeeRosters[
        `${this.sessionId}-general-log`
      ];

    if (attendeesOnPage && attendeesOnPage.length > 0) {
      this.pusherAttendees =
        this.coachingService.logPusherService.attendeeRosters[
          this.sessionId
        ].map((sessionAttendee) => {
          const samePage = attendeesOnPage.some(
            (pageAttendee) => pageAttendee.id === sessionAttendee.id
          );
          return {
            avatar: sessionAttendee.avatar,
            samePage,
            id: sessionAttendee.id,
          };
        });
    }
  }

  updateNotes(newNotes: string) {
    if (this.sessionData.sessionNotes !== newNotes) {
      this.isSaving = true;

      this.generalLogService
        .updateCoachingLogContent(this.sessionId, newNotes)
        .subscribe(() => {
          this.isSaving = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.coachingService.disconnectFromPusherChannel(this.sessionId);
  }
}
