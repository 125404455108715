<div class="row justify-content-center text-center">
  <h3 class="col-8 fw-800">Choose Your Coach</h3>
  <div class="col-8 fw-200 fs-14">
    Hi there, we are your virtual coaches! We want to customize the support we
    provide, so please choose the coach whose description best aligns with the
    way you prefer to learn. We've all been trained by engage2learn's elite
    group of coaches, so you'll get top-tier support no matter which coach you
    choose!
  </div>
</div>
<ng-container *ngIf="smartCoaches.length > 0; else loading">
  <div class="row py-5 justify-content-around text-center">
    <div
      *ngFor="let coach of smartCoaches"
      class="col-xs-12 col-md-6 col-xl-3 coach-card mt-3">
      <app-intro-your-coach-option
        (toggleCoachEvent)="toggleCoach($event)"
        [coach]="coach" />
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="w-100 text-center" style="height: 500px">
    <app-loader />
  </div>
</ng-template>
