import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDTO } from '../../dtos/file.dto';
import { UploadType } from '../../enums/upload-type.enum';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class PlanDeliverableFileService extends FileService {
  override uploadType = UploadType.DELIVERABLE_ATTACHMENT;

  deliverableId: number | null = null;

  setDeliverableId(id: number | null) {
    this.deliverableId = id;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.apiService.postFormRequest(
      `growelab/implementation-plan/deliverable/${this.deliverableId}/upload`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  override deleteFile(file: FileDTO) {
    const basedId = window.btoa(file.id.toString());
    return this.apiService.deleteRequest(
      `uploads/delete/implementation_deliverable/${basedId}`
    );
  }
}
