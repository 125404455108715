import { SetGoalPayload } from '../../types/payloads/set-goal.payload';

interface GoalSliderAPIPayload {
  egrowe_indicator_group_id: number;
  egrowe_coachlog_id: number;
  user_id: number;
  egrowe_standard_id: number;
  goal_level: number;
}

export const convertFromSetGoalPayload = (
  originalPayload: SetGoalPayload
): GoalSliderAPIPayload => ({
  egrowe_indicator_group_id: originalPayload.strandId,
  egrowe_coachlog_id: originalPayload.coachlogId,
  user_id: originalPayload.userId,
  egrowe_standard_id: originalPayload.competencyId,
  goal_level: originalPayload.goalLevel,
});
