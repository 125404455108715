import { Component, Input, OnInit } from '@angular/core';

import {
  AvailableTagsResponse,
  TagItemResponse,
} from 'src/app/common/types/responses/responses';
import { ResourceService } from '../../../services/resources/resource.service';
import { TagDTO } from '../../../dtos/tags.dto';
import { MultiTagPayload } from '../../../types/payloads/resources.payloads';

@Component({
  selector: 'app-resource-tags',
  templateUrl: './resource-tags.component.html',
  styleUrls: ['./resource-tags.component.scss'],
})
export class ResourceTagsComponent implements OnInit {
  @Input() tagList: TagDTO[];

  @Input() refTableId: number;

  @Input() readOnly = true;

  refTable = 'elearn_resource';

  tagsLoading = false;

  availTags: string[];

  selectedTags = [];

  newTags: MultiTagPayload;

  constructor(private resourceService: ResourceService) {}

  ngOnInit() {
    this.getAvailableTags();
    this.setNewTags();
  }

  setNewTags() {
    this.newTags = {
      tag: [],
      ref_table: this.refTable,
      ref_table_id: this.refTableId,
    };
  }

  resetTags() {
    this.tagsLoading = false;
    this.selectedTags = [];
    this.setNewTags();
  }

  getAvailableTags() {
    this.tagsLoading = true;
    this.resourceService
      .getAvailTags()
      .subscribe((response: AvailableTagsResponse) => {
        this.availTags = response.items.map((tag: TagDTO) => tag.tag);
        this.tagsLoading = false;
      });
  }

  deleteTag(tagId: number) {
    this.resourceService.deleteTag(tagId).subscribe(() => {
      this.tagList = this.tagList.filter((item) => item.id !== tagId);
    });
  }

  removeSelectedTag(selTag: string) {
    this.selectedTags = this.selectedTags.filter((tag) => tag !== selTag);
  }

  addTags() {
    this.tagsLoading = true;
    this.newTags.tag = this.selectedTags;

    this.resourceService.addNewTags(this.newTags).subscribe((result) => {
      if (result.item) {
        this.tagList.push(result.item);
        this.resetTags();
      }
      if (result.items) {
        result.items.forEach((item: TagItemResponse) => {
          this.tagList.push(item);
        });
        this.resetTags();
      }
    });
  }
}
