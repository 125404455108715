<div class="container container-main">
  <app-general-header [title]="'Reporting'" />
  <div class="card border-0 p-3 mb-3">
    <app-reporting-subnav></app-reporting-subnav>
    <div class="card-body">
      <div class="row my-4">
        <div class="col-10 offset-1">
          <h2 class="fw-bolder text-center">Microcredential Progress</h2>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <button
            class="btn mb-2"
            aria-label="Download Microcredential Progress CSV"
            ngbTooltip="Download Microcredential Progress CSV"
            container="body"
            placement="bottom"
            [autoClose]="false"
            [ngbPopover]="csvDownloadingMicrocredentialProgressPopover"
            [disabled]="disableMicrocredentialProgressDownload"
            (click)="downloadBadgeProgressCSV()"
            #giGrowthPopover="ngbPopover">
            <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
          </button>
          <ng-template #csvDownloadingMicrocredentialProgressPopover>
            <div class="p-2">
              Your report is generating. The download will begin shortly.
            </div>
            <div class="p-2 d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="giGrowthPopover.close()">
                OK
              </button>
            </div>
          </ng-template>
        </div>
      </div>
      <app-report-filter-bar
        [canFilterByDistrict]="canFilterByDistrict"
        [reportName]="'badge_progress'"></app-report-filter-bar>
      <app-heatmap-controller
        (changeDrilldownType)="updateMicrocredentialDrilldownType($event)"
        (selectSchool)="changeSchool($event)" />

      <ng-container *ngIf="giGrowthReportEnabled">
        <div class="row separator-border my-4">
          <div class="col-10 offset-1">
            <h2 class="fw-bolder text-center">GI Growth</h2>
          </div>
          <div class="col-1 d-flex justify-content-end align-items-center">
            <button
              class="btn mb-2"
              aria-label="Download GI Growth CSV"
              ngbTooltip="Download GI Growth CSV"
              container="body"
              placement="bottom"
              [autoClose]="false"
              [ngbPopover]="csvDownloadingGIGrowthPopover"
              [disabled]="disableGIGrowthDownload"
              (click)="downloadCSV('gi_growth')"
              #giGrowthPopover="ngbPopover">
              <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
            </button>
            <ng-template #csvDownloadingGIGrowthPopover>
              <div class="p-2">
                An email will be sent to you with a link to download the CSV.
              </div>
              <div class="p-2 d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="
                    disableGIGrowthDownload = false; giGrowthPopover.close()
                  ">
                  OK
                </button>
              </div>
            </ng-template>
          </div>
        </div>
        <app-report-filter-bar
          [canFilterByDistrict]="canFilterByDistrict"
          [reportName]="'gi_growth'"></app-report-filter-bar>
        <app-growth-table />
      </ng-container>

      <div class="row mt-4 separator-border">
        <div class="col-10 offset-1">
          <h2 class="fw-bolder text-center">Badges Earned</h2>
        </div>
        <div
          ngbDropdown
          #badgesEarnedDrop="ngbDropdown"
          class="col-1 d-flex justify-content-end align-items-center"
          placement="bottom-end">
          <span
            [ngbPopover]="csvDownloadingBadgesEarnedPopover"
            #badgesEarnedPopover="ngbPopover"
            container="body"
            placement="bottom"
            [positionTarget]="badgesEarnedDropdown"
            [autoClose]="false"></span>
          <button
            ngbDropdownToggle
            #badgesEarnedDropdown
            class="btn mb-2"
            aria-label="Download Badges Earned CSV"
            ngbTooltip="Download Badges Earned CSV"
            container="body"
            placement="bottom"
            [disabled]="disableBadgesEarnedDownload">
            <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="badgesEarnedDropdown">
            <li ngbDropdownItem class="px-0">
              <a
                class="dropdown-item"
                aria-label="Download Raw Data"
                (click)="
                  badgesEarnedPopover.open(); downloadCSV('badges_earned_raw')
                "
                href="javascript:void(0)">
                Download Raw Data
              </a>
            </li>
            <li ngbDropdownItem class="px-0">
              <a
                class="dropdown-item"
                aria-label="Download Microcredential Summary"
                (click)="
                  badgesEarnedPopover.open();
                  downloadCSV('microcredential_badge_summary')
                "
                href="javascript:void(0)">
                Download Microcredential Summary
              </a>
            </li>
            <li ngbDropdownItem class="px-0">
              <a
                class="dropdown-item"
                aria-label="Download Strand Badges Summary"
                (click)="
                  badgesEarnedPopover.open();
                  downloadCSV('strand_badge_summary')
                "
                href="javascript:void(0)">
                Download Strand Badges Summary
              </a>
            </li>
          </div>

          <ng-template #csvDownloadingBadgesEarnedPopover>
            <div class="p-2">
              An email will be sent to you with a link to download the CSV.
            </div>
            <div class="p-2 d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="
                  disableBadgesEarnedDownload = false;
                  badgesEarnedPopover.close()
                ">
                OK
              </button>
            </div>
          </ng-template>
        </div>
        <app-report-filter-bar
          class="mt-2 mb-1"
          [canFilterByDistrict]="canFilterByDistrict"
          [reportName]="'badges_earned_growth'"></app-report-filter-bar>
        <div class="card border-0 card-offset">
          <app-report-controller
            chartId="badges-earned-growth"
            [chartType]="chartTypes.HorizontalBar"
            yAxisLabel="Badges Earned"
            [chartColor]="'#d95195'"
            [drilldownDimensions]="['school_name', 'user_name']"
            [requestParams]="{
            dataset: 'fct_user_badge',
            dimension: 'standard_name',
            measure: {
              type: 'count',
              field: '*',
            },
            filters: defaultFilters
          }" />
        </div>
      </div>
      <div class="mt-5 text-muted small text-end">
        Data last refreshed at {{ lastUpdatedDate }}
      </div>
    </div>
  </div>
</div>
