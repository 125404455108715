<div
  tabindex="0"
  class="completion-fade completion-container {{
    completionActive ? 'show' : ''
  }}">
  <div
    class="notes-title text-center d-flex align-items-center justify-content-between">
    <div class="ps-0 text-start"></div>
    <div *ngIf="sessionData">
      <span
        *ngIf="loadingData"
        class="spinner-border spinner-border-sm me-1"></span
      ><span *ngIf="!loadingData">{{
        sessionPercentageData.percentCompleted
      }}</span>
      % Complete
    </div>
    <div class="text-end pe-0">
      <i
        (click)="toggleCompletion()"
        title="hide-icon"
        role="button"
        class="bi bi-arrow-bar-right me-2 p-2"></i>
    </div>
  </div>
  <div class="notes-body">
    <div class="p-4">
      <div class="fs-18 fw-800">Session Completion Checklist</div>
      <ul *ngIf="sessionPercentageData" class="grey-dot-lg mt-3">
        <li>
          <span class="status-checkbox"
            ><i
              *ngIf="sessionPercentageData.competencyAdded"
              class="bi bi-check"></i></span
          >At least one competency is selected
        </li>
        <li>
          <span class="status-checkbox"
            ><i
              *ngIf="sessionPercentageData.optionAdded"
              class="bi bi-check"></i></span
          >At least one Option is entered
        </li>
        <li>
          <span class="status-checkbox"
            ><i
              *ngIf="sessionPercentageData.commitment"
              class="bi bi-check"></i></span
          >Each coachee present has committed to at least one Option
        </li>
        <li>
          <span class="status-checkbox"
            ><i
              *ngIf="sessionPercentageData.plannedEvidence"
              class="bi bi-check"></i></span
          >All committed Options have an Evidence Plan
        </li>
      </ul>
    </div>
  </div>
</div>
