import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { AvatarDTO } from '../avatar/avatar.dto';

@Component({
  selector: 'app-recent-celebration',
  templateUrl: './recent-celebration.component.html',
  styleUrls: ['./recent-celebration.component.scss'],
})
export class RecentCelebrationComponent {
  user: AvatarDTO = {
    firstName: 'Shannon',
    lastName: 'Buerk',
    color: 'grey',
    photoUrl: `${EnvironmentService.apiUrl()}/uploads/demodata/shannonbuerk.jpg`,
  };

  latestCelebration = '';

  isCollapsed = this.cardStorageService.getCollapsedState('recentcelebration');

  constructor(private cardStorageService: CardStorageService) {}

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'recentcelebration',
      this.isCollapsed
    );
  }
}
