import { FileDTO } from '../../../dtos/file.dto';
import { FileIcon } from '../../../enums/file-icons.enum';

export const MOCK_FILE_LIST: FileDTO[] = [
  {
    id: 0,
    name: 'Image.jpg',
    iconClass: FileIcon.img,
    location: 'uploads/fake/image.jpg',
  },
  {
    id: 0,
    name: 'Document.pdf',
    iconClass: FileIcon.pdf,
    location: 'uploads/fake/document.pdf',
  },
  {
    id: 0,
    name: 'Document.docx',
    iconClass: FileIcon.doc,
    location: 'uploads/fake/document.docx',
  },
  {
    id: 0,
    name: 'Document.pptx',
    iconClass: FileIcon.ppt,
    location: 'uploads/fake/document.pptx',
  },
  {
    id: 0,
    name: 'Document.pdf',
    iconClass: FileIcon.pdf,
    location: 'uploads/fake/document.pdf',
  },
  {
    id: 0,
    name: 'Video.mp4',
    iconClass: FileIcon.video,
    location: 'uploads/fake/document.mp4',
  },
  {
    id: 0,
    name: 'Audio.mp3',
    iconClass: FileIcon.audio,
    location: 'uploads/fake/document.mp3',
  },
];
