import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  CoachingSessionDTO,
  CoachingSessionTypeDTO,
} from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { ChangeTypePayload } from '../../types/payloads/coaching-log.payloads';
import { LogTypeSelectComponent } from '../log-type-select/log-type-select.component';
import { ModalComponent, ModalConfig } from '../modals/modal/modal.component';

@Component({
  selector: 'app-session-type-modal',
  templateUrl: './session-type-modal.component.html',
  styleUrls: ['./session-type-modal.component.scss'],
})
export class SessionTypeModalComponent implements OnInit {
  @Input() currentSession: CoachingSessionDTO;

  @ViewChild('sessTypeModal')
  sessTypeModal: ModalComponent;

  @ViewChild('logTypeSelect') logTypeSelect: LogTypeSelectComponent;

  selectedType?: CoachingSessionTypeDTO;

  currentSessionType: CoachingSessionTypeDTO;

  modalConfig: ModalConfig = {
    titleText: 'Do you want to change the session type?',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
  };

  constructor(private coachingLogService: CoachingLogService) {}

  ngOnInit(): void {
    if (this.currentSession) {
      this.currentSessionType = this.currentSession.type;
    }
  }

  openModal() {
    this.sessTypeModal.open();
  }

  resetSelection() {
    this.selectedType = undefined;
    this.logTypeSelect.reset();
  }

  closeModal() {
    this.resetSelection();
    this.sessTypeModal.close();
  }

  resetForm() {
    this.resetSelection();
  }

  handleLogSelect(logType: CoachingSessionTypeDTO | null) {
    if (logType) {
      this.selectedType = logType;
    }
  }

  changeSessionType() {
    if (this.selectedType && this.currentSessionType) {
      if (this.selectedType.id !== this.currentSessionType.id) {
        const changeTypePayload: ChangeTypePayload = {
          logId: this.currentSession.id,
          logTypeId: this.selectedType.id,
        };
        this.coachingLogService.changeType(changeTypePayload);
      }
    }

    this.resetForm();
  }
}
