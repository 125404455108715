<div class="card border-0">
  <ng-container *ngIf="isLoaded; else placeHolder">
    <div class="card-header pt-3">
      <h2 class="card-title float-start" (click)="triggerCollapse()">
        Thoughts &amp; Ideas
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div id="ideas-title" class="card-tools float-end">
        <i
          (click)="newThought()"
          ngbTooltip="Add a new thought"
          [style.cursor]="'pointer'"
          class="bi bi-plus-circle fs-6 tool"></i>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 overflow-auto"
        tabindex="0"
        role="group"
        aria-labelledby="ideas-body"
        style="height: 18rem">
        <div *ngIf="thoughts">
          <div *ngFor="let thought of thoughts">
            <div class="d-flex flex-row mb-1">
              <a
                href="javascript:void(0)"
                (click)="editThought(thought.id)"
                title="Edit thought">
                <i class="bi bi-pencil-fill tool"></i>
              </a>
              <div class="col flex-grow">
                <app-safehtml [html]="thought.content"></app-safehtml>
              </div>
              <button
                aria-label="delete thought button"
                class="btn btn-delete"
                type="button"
                [autoClose]="'outside'"
                [ngbPopover]="contentTemplate"
                [popoverTitle]="'Delete Thought'"
                #popover="ngbPopover">
                <i class="bi bi-trash"></i>
              </button>
              <ng-template #contentTemplate>
                Are you sure you want to delete this thought?
                <div class="text-end mt-2">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm px-3 m-1"
                    (click)="popover.close()">
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm px-3 m-1"
                    (click)="deleteThought(thought.id); popover.close()">
                    Yes
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
          <app-modal #ideasModal [modalConfig]="modalConfig">
            <button
              class="bg-transparent border-0"
              title="Close"
              aria-label="close"
              headerContent
              (click)="ideasModal.close()">
              <i class="bi bi-x-lg text-white"></i>
            </button>
            <app-wysiwyg-editor
              #wysiwyg
              [version]="'mini'"
              [config]="editorConfig" />
            <div footerContent>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn" (click)="ideasModal.close()">
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-md btn-primary"
                  [disabled]="checkIfEmpty()"
                  (click)="saveThought(); ideasModal.close()">
                  Create
                </button>
              </div>
            </div>
          </app-modal>
        </div>
        <div
          *ngIf="thoughts && thoughts.length == 0"
          class="text-body-secondary d-flex h-100 w-100 justify-content-center align-items-center">
          No thoughts or ideas added yet
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
