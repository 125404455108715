<div class="table-container">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Coach</th>
        <th scope="col">Overall Averages</th>
        <th scope="col" *ngFor="let standard of standards">{{ standard }}</th>
        <th
          scope="col"
          *ngIf="isLoading || (responses && responses.length === 0)"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <ng-container
          *ngIf="responses && responses.length > 0; else noTableData">
          <tr *ngFor="let response of responses; let i = index">
            <td>
              <div class="d-flex align-items-center coachee-container">
                <app-avatar
                  class="me-2"
                  [profile]="response.profile"
                  avatarSize="small" />
                <span class="coachee-name">{{ response.user_name }}</span>
              </div>
            </td>
            <td>{{ response.total_average }}</td>
            <td *ngFor="let standard of response.standards">{{ standard }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <ng-template #loadingTemplate>
      <tr>
        <td colspan="3" class="text-center">Loading...</td>
      </tr>
    </ng-template>
  </table>
  <ng-template #noTableData>
    <tr>
      <td colspan="3" class="text-center">
        {{
          loadingError
            ? "There was an error loading the data."
            : "No Data Found."
        }}
      </td>
    </tr>
  </ng-template>
</div>
<section>
  <div class="d-flex justify-content-center mt-4">
    <ngb-pagination
      *ngIf="totalResponses > 10"
      [(page)]="currentPage"
      [pageSize]="10"
      [collectionSize]="totalResponses"
      [maxSize]="5"
      (pageChange)="paginationChange()"></ngb-pagination>
  </div>
</section>
