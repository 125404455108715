<div
  class="competency {{ loading ? 'loading ' : '' }} text-center shadow p-3 {{
    selected ? 'selected' : ''
  }}">
  <div
    *ngIf="competencyVideo || isSelectable"
    style="height: 30px; position: relative; width: 100%">
    <ng-container *ngIf="competencyVideo">
      <button
        (click)="handleVideoClick()"
        aria-label="Play video"
        ngbTooltip="Play video"
        class="btn btn-reset play-video">
        <i class="bi bi-play-circle-fill"></i>
      </button>
    </ng-container>
    <i
      *ngIf="isSelectable === true"
      class="btn-switch"
      role="button"
      [ngClass]="{
        'bi bi-toggle-off': !selected,
        'bi bi-toggle-on': selected
      }"
      (click)="updateCompetency()"
      style="line-height: 0; margin-top: -8px; font-size: 2em"></i>
  </div>

  <ng-container *ngIf="currentIcon; else noIcon">
    <div
      class="competency-icon"
      [ngStyle]="{
        'background-image': 'url(' + currentIcon + ')'
      }"></div>
  </ng-container>
  <ng-template #noIcon>
    <div class="competency-icon"></div>
  </ng-template>
  <div class="competency-title">
    {{ competency.title }}
  </div>
  <div class="competency-content">
    <app-safehtml [html]="competency.content" />
  </div>
  <div *ngIf="topicTitle" class="badge mt-3">{{ topicTitle }}</div>
</div>

<app-video-modal
  *ngIf="competencyVideo && showVideo"
  #videoModal
  [autoOpen]="true"
  (videoClosing)="handleVideoClose()"
  [video]="competencyVideo"
  [showVideoInfo]="true" />
