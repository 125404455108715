<div class="form-group radio form-box mt-2 card border-0 shadow-sm">
  <label [id]="id" class="form-label p-2">{{ fieldDefinition.label }}</label>
  <div class="content p-4">
    <label
      (click)="chooseSelection(choice.value)"
      *ngFor="let choice of fieldDefinition.choices"
      class="d-block item mb-2">
      <div class="d-flex align-items-center">
        <div class="position-relative toggle-container">
          <i
            class="fs-4 position-absolute bi bi-circle"
            [ngClass]="readOnly ? 'opacity-0' : 'color-tertiary'"></i>
          <i
            *ngIf="selection == choice.value"
            class="fs-15 position-absolute bi bi-circle-fill color-primary"></i>
        </div>
        <span class="d-block px-2 fw-normal">{{ choice.label }}</span>
      </div>
    </label>
  </div>
</div>
