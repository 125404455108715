import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { ResourceFiltersDTO } from '../../../dtos/resources.dto';
import { ResourcesSearchService } from '../../../services/resources/resources-search.service';
import { ResourceFilterModalComponent } from '../resource-filter-modal/resource-filter-modal.component';

interface AuthorId {
  name: string;
  id: number;
}

@Component({
  selector: 'app-resource-filter-container',
  templateUrl: './resource-filter-container.component.html',
  styleUrls: ['./resource-filter-container.component.scss'],
})
export class ResourceFilterContainerComponent implements OnInit, OnDestroy {
  @ViewChild('filterModal') filterModal: ResourceFilterModalComponent;

  searchFilters: ResourceFiltersDTO = {
    created_by: '',
    type: '',
    order: 'search_score',
    authors: [],
    tags: [],
    competencies: [],
    levels: [],
  };

  authors: AuthorId[] = [];

  competencies: CompetencyDTO[] = [];

  subs: Subscription[] = [];

  @Input() user: User | null;

  constructor(private resourceService: ResourcesSearchService) {}

  ngOnInit(): void {
    this.subs.push(
      this.resourceService.searchFilters.subscribe((searchFilters) => {
        if (searchFilters) {
          this.searchFilters = searchFilters;
        }
      })
    );
  }

  applyFilters() {
    this.resourceService.setFilters(this.searchFilters);
  }

  resetFilters() {
    this.filterModal.resetFilters();
    this.authors = [];
    this.competencies = [];
    this.applyFilters();
  }

  handleAuthors(authorObject: AuthorId[]) {
    this.authors = authorObject;
    this.applyFilters();
  }

  handleCompetencies(competencyObject: CompetencyDTO[]) {
    this.competencies = competencyObject;
    this.applyFilters();
  }

  handleSortBy(orderString: string) {
    this.searchFilters.order = orderString;
    this.applyFilters();
  }

  removeAuthor(author: AuthorId) {
    if (this.searchFilters) {
      this.searchFilters.authors = this.searchFilters.authors?.filter(
        (authorFilter) => authorFilter !== author.id
      );

      this.authors = this.authors.filter(
        (authorFilter) => authorFilter !== author
      );

      this.filterModal.removeUser(author);
      this.applyFilters();
    }
  }

  removeCompetency(competency: CompetencyDTO) {
    if (this.searchFilters) {
      this.searchFilters.competencies = this.searchFilters.competencies?.filter(
        (competencyFilter) => competencyFilter !== competency.id
      );

      this.competencies = this.competencies.filter(
        (competencyFilter) => competencyFilter !== competency
      );

      this.filterModal.removeCompetency();
      this.applyFilters();
    }
  }

  removeTypeFilter() {
    if (this.searchFilters) {
      this.searchFilters.type = '';
      this.applyFilters();
    }
  }

  removeCreatedByFilter() {
    if (this.searchFilters) {
      this.searchFilters.created_by = '';
      this.applyFilters();
    }
  }

  removeLevelFilter(levelNumber: number) {
    if (this.searchFilters) {
      this.searchFilters.levels = this.searchFilters.levels?.filter(
        (level) => level !== levelNumber
      );
      this.applyFilters();
    }
  }

  removeTagFilter(chosenTag: string) {
    if (this.searchFilters) {
      this.searchFilters.tags = this.searchFilters.tags?.filter(
        (tag) => chosenTag !== tag
      );
      this.filterModal.removeTag(chosenTag);
      this.applyFilters();
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
