import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription, map } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  EvidenceAssessedDTO,
  EvidenceSummaryAttachmentResponse,
  EvidenceSummaryItemResponse,
} from 'src/app/common/types/responses/responses';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfShadower,
} from 'src/app/common/utilities/session-helpers';
import { EvidenceDTO } from '../../dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { FileDTO } from '../../dtos/file.dto';
import { fileDTOFromEvidenceSummaryAttachmentResponse } from '../../helpers/translators/evidence.translators';
import { videoDTOFromAPIResponse } from '../../helpers/translators/video.translators';
import { CoachingLogEvidenceService } from '../../services/coaching-log/coaching-log-evidence.service';
import { EvidenceService } from '../../services/evidence/evidence.service';
import { FEATURE_FLAGS } from '../../services/unleash/unleash.helpers';
import { UnleashService } from '../../services/unleash/unleash.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { CommentModelDTO } from '../evidence-comments/evidence-comments.dto';
import { CopyEvidenceModalComponent } from '../modals/copy-evidence-modal/copy-evidence-modal.component';
import { ModalComponent, ModalConfig } from '../modals/modal/modal.component';

@Component({
  selector: 'app-evidence-summary',
  templateUrl: './evidence-summary.component.html',
  styleUrls: ['./evidence-summary.component.scss'],
})
export class EvidenceSummaryComponent implements OnInit, OnDestroy {
  @ViewChild('copyEvidenceModal') copyEvidenceModal: CopyEvidenceModalComponent;

  @Input() evidenceId: number;

  @Input() sessionId: number;

  @Input() userId: number;

  @Input() autoOpenModal: boolean;

  @Output() readonly evidenceAssesed: EventEmitter<EvidenceAssessedDTO> =
    new EventEmitter();

  @Output() readonly evidenceRemoved: EventEmitter<number> = new EventEmitter();

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  subs: Subscription[] = [];

  loadingData = true;

  currentUser: User | null;

  isCoach = false;

  isShadower = false;

  evidence: EvidenceDTO;

  evidenceSummary: EvidenceSummaryItemResponse;

  modalConfig: ModalConfig;

  hasAttachments = false;

  uploadedFiles: FileDTO[] = [];

  commentList: CommentModelDTO[];

  commentRequest: Subscription;

  evidenceAssessing = false;

  evidenceAssessed = false;

  video: VideoDTO;

  evidenceData$ = select(CoachingLogState.getOneEvidence);

  copyEvidenceEnabled = false;

  @ViewChild('evidenceSummaryModal') evidenceSummaryModal: ModalComponent;

  constructor(
    private evidenceService: EvidenceService,
    private coachingLogEvidenceService: CoachingLogEvidenceService,
    private featureFlagService: UnleashService,
    private store: Store
  ) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser);
  }

  ngOnInit(): void {
    this.getEvidenceSummary();

    this.copyEvidenceEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.copyEvidence
    );

    this.modalConfig = {
      headerClass: ['modal-header', 'text-white', 'text-center'],
      closeButton: {
        classes: ['btn', 'btn-md', 'text-white', 'fw-800', 'text-uppercase'],
        label: 'Submit',
      },
      customFooter: true,
      titleText: '',
      options: {
        size: 'lg',
      },
    };

    if (this.sessionId) {
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.sessionId)))
        .pipe(
          map((sessionData) => {
            if (sessionData) {
              this.sessionData = sessionData;
              this.isShadower = checkIfShadower(this.sessionData);
              this.isCoach = checkIfCoach(this.sessionData);
            }
          })
        )
        .subscribe();
    } else {
      this.checkIfCoach();
    }

    this.evidenceData$
      .pipe(map((filterFn) => filterFn(this.evidenceId)))
      .subscribe(() => {
        // Must restrict or all evidences will update
        // Do not reset evidence if autoOpen - where page content
        // does not need to update again.
        if (!this.evidenceAssessed && !this.autoOpenModal) {
          this.getEvidenceSummary();
        }
      });
  }

  checkIfCoach() {
    if (this.currentUser) {
      if (
        this.currentUser.roles.includes(UserRole.COACH) ||
        this.currentUser.roles.includes(UserRole.E2L_EMPLOYEE)
      ) {
        this.isCoach = true;
      }
    }
  }

  getEvidenceSummary() {
    this.evidenceService
      .getEvidenceSummary(this.evidenceId)
      .subscribe((res) => {
        this.evidenceSummary = res.item;
        if (
          this.evidenceSummary.assessments &&
          this.evidenceSummary.assessments.length > 0
        ) {
          this.evidenceAssessed = true;
        }
        if (this.evidenceSummary.evidenceAttachments.length > 0) {
          this.evidenceSummary.evidenceAttachments.forEach(
            (e: EvidenceSummaryAttachmentResponse) => {
              const fileExists = this.uploadedFiles.some(
                (file) => file.id === e.id
              );
              if (!fileExists) {
                this.uploadedFiles.push(
                  fileDTOFromEvidenceSummaryAttachmentResponse(e)
                );
              }
            }
          );
          this.hasAttachments = true;
        }
        if (this.evidenceSummary.videos.length > 0) {
          this.video = videoDTOFromAPIResponse(this.evidenceSummary.videos[0]);
        }
        this.getComments();

        if (this.autoOpenModal) {
          this.showEvidenceModal();
        }

        this.loadingData = false;
      });
  }

  showEvidenceModal() {
    this.evidenceSummaryModal.config.titleText = this.evidenceSummary.title;
    this.evidenceSummaryModal.config.headerClass.push(
      `bg-level-${this.evidenceSummary.level}`
    );

    this.evidenceSummaryModal.open();

    if (this.autoOpenModal && this.coachingLogEvidenceService.evidenceId) {
      this.evidenceSummaryModal.isClosing.subscribe(() => {
        this.coachingLogEvidenceService.setEvidenceId();
      });
    }
  }

  closeEvidenceModal() {
    this.evidenceSummaryModal.close();
  }

  deleteEvidence() {
    this.evidenceService
      .deleteEvidence(this.evidenceSummary.id)
      .subscribe(() => {
        this.evidenceRemoved.emit(this.evidenceSummary.id);
        this.evidenceSummaryModal.close();
      });
  }

  assessEvidence() {
    this.evidenceAssessing = true;
    const payload = {
      ...this.evidenceSummary,
      pusher_coachlog_id: this.sessionId ? this.sessionId : null,
    };

    this.evidenceService.approveEvidence(payload).subscribe((res) => {
      if (res.item) {
        this.evidenceAssesed.next(res.item);
        this.evidenceAssessing = false;
        this.evidenceAssessed = true;
        this.evidenceSummaryModal.close();
      }
    });
  }

  getComments() {
    if (this.commentRequest) this.commentRequest.unsubscribe();
    this.commentRequest = this.evidenceService
      .getEvidenceComments(this.evidenceSummary.id)
      .subscribe((response) => {
        if (response) {
          this.commentList = response.items;
        }
      });

    this.subs.push(this.commentRequest);
  }

  openCopyEvidenceModal() {
    this.evidenceSummaryModal.close();
    this.copyEvidenceModal.modal.open();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
