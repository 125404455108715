<div class="text-center mb-4 fw-200">{{ totalResponses }} Submissions</div>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Date & Time</th>
      <th scope="col">Coachee</th>
      <th scope="col">Response</th>
      <th scope="col">Session Link</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-container *ngIf="responses && responses.length > 0; else noTableData">
        <tr *ngFor="let response of responses; let i = index">
          <td class="fw-200">
            {{ responseTimes[i] | formatDate : "MMM d '@' h:mm a" : timeZone }}
          </td>
          <td>
            {{ response.name }}
          </td>
          <td class="fw-200">
            <div
              id="response-{{ fieldId }}-{{ i }}"
              class="response-container"
              [ngClass]="hasOverflow(i) ? 'overflow-shadow' : ''"
              (click)="
                hasOverflow(i)
                  ? openResponseModal(response.field_text)
                  : $event.stopPropagation()
              ">
              <app-safehtml
                html="{{ response.field_text }}"
                [removeMargin]="true"></app-safehtml>
            </div>
            <button
              *ngIf="hasOverflow(i)"
              type="button"
              class="btn link-text fw-800"
              (click)="openResponseModal(response.field_text)">
              Full Response
            </button>
          </td>
          <td>
            <a
              class="link-text fw-200"
              routerLink="{{ getSessionLink(response.coachlog_id) }}"
              target="_blank"
              title="Session">
              Session
              <i class="ms-1 bi bi-box-arrow-up-right"></i>
            </a>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
  <ng-template #loadingTemplate>
    <tr>
      <td colspan="3" class="text-center">Loading...</td>
    </tr>
  </ng-template>
</table>
<ng-template #noTableData>
  <tr>
    <td colspan="3" class="text-center">No Responses Found.</td>
  </tr>
</ng-template>
<section>
  <div class="d-flex justify-content-center mt-4">
    <ngb-pagination
      *ngIf="totalResponses > 10"
      [(page)]="currentPage"
      [pageSize]="10"
      [collectionSize]="totalResponses"
      [maxSize]="5"
      (pageChange)="paginationChange()"></ngb-pagination>
  </div>
</section>

<app-modal #responseModal>
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="responseModal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <app-safehtml class="show-all" html="{{ modalResponse }}"></app-safehtml>
</app-modal>
