export enum GLEnvironment {
  LOCAL = 'local',
  LOCALQA = 'localqa',
  LOCALSTAGING = 'localstaging',
  DEV = 'dev',
  QA = 'qa',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface GrowelabEnv {
  environment: GLEnvironment;
  production: boolean;
  codoxApiKey: string;
  froalaKey: string;
  apiUrl: string;
  pusher: {
    key: string;
    cluster: string;
  };
  unleash: {
    url: string;
    clientKey: string;
  };
  googleAnalyticsID: string;
  microsoftClarityID?: string;
  SENTRY_ORG?: string;
  SENTRY_PROJECT?: string;
  SENTRY_KEY?: string;
  googleOAuthClientId: string;
  cleverClientId: string;
  cleverRedirectURI: string;
  intercomId: string;
  sessionTimeoutMinutes: number;
  getstream_appid: string;
  getstream_key: string;
}
