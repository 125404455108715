<div class="container topics-page">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card border-0 p-3 roster-container">
    <app-settings-nav [districtCode]="districtCode" />
    <ng-container *ngIf="district">
      <ng-container *ngIf="tableData; else loadingTemplate">
        <div class="row d-flex justify-content-between my-3">
          <div class="col-12 col-md-6 order-2 order-md-1">
            <div class="table-search">
              <input
                #searchText
                type="search"
                class="form-control"
                placeholder="Search Topics"
                aria-label="Search"
                (input)="searchTermChanged($event)" />
            </div>
          </div>
          <div
            class="d-flex col-12 col-md-6 order-1 order-md-2 justify-content-center justify-content-md-end my-3 my-md-0">
            <button
              class="btn btn-primary btn-create-topic"
              (click)="openCreateTopicModal()">
              <i class="bi bi-plus"></i>
              Create a Topic
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="d-flex flex-column flex-lg-row align-items-lg-center w-100">
              <app-applied-filters
                (filterListRemoved)="removeFilterFromList($event)"
                filterType="topic-builder"
                [topicPageSearchOptions]="searchParams"></app-applied-filters>
              <button
                type="button"
                class="btn btn-link mt-2"
                style="white-space: nowrap"
                (click)="showFilterModal()">
                Edit Filters
              </button>
            </div>
            <hr class="border border-primary border-2" />
          </div>
        </div>
        <div class="table-wrapper w-100">
          <table *ngIf="tableData" class="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  (sort)="onSort($event)"
                  sortable="title"
                  class="topics">
                  Topic
                  <ng-container *ngIf="!loadingIcons">
                    <i class="bi bi-caret-down {{ sortIconTemp.title }}"></i>
                  </ng-container>
                </th>
                <th
                  scope="col"
                  (sort)="onSort($event)"
                  sortable="totalDistricts"
                  class="districts text-center">
                  Districts
                  <ng-container *ngIf="!loadingIcons">
                    <i
                      class="bi bi-caret-down {{
                        sortIconTemp.totalDistricts
                      }}"></i>
                  </ng-container>
                </th>
                <th scope="col" class="audience">Audience</th>
                <th
                  scope="col"
                  (sort)="onSort($event)"
                  sortable="is_smart"
                  class="msc">
                  <span class="d-none d-md-inline me-1">mySmartCoach</span
                  ><span class="d-md-none me-1">mSC</span>
                  <ng-container *ngIf="!loadingIcons">
                    <i class="bi bi-caret-down {{ sortIconTemp.is_smart }}"></i>
                  </ng-container>
                </th>
                <th class="edit"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="!topicsTableIsLoading; else loadingTemplate">
                <ng-container *ngIf="tableData.length > 0; else noTopics">
                  <tr *ngFor="let topic of tableData">
                    <td>
                      <a
                        routerLink="/settings/districts/{{
                          districtCode
                        }}/topics/{{ topic.id }}"
                        title="{{ topic.title }}"
                        class="fw-bold">
                        {{ topic.title }}
                      </a>
                    </td>
                    <td class="text-center">{{ topic.totalDistricts }}</td>
                    <td>
                      <ng-container *ngFor="let type of topic.types">
                        <span class="badge badge-item mb-2 me-1">{{
                          type.type.title
                        }}</span>
                      </ng-container>
                    </td>
                    <td class="text-center">
                      <ng-container
                        *ngIf="
                          topic.is_smart;
                          then checked;
                          else unchecked
                        "></ng-container>
                    </td>
                    <td class="text-center">
                      <i
                        (click)="openEditTopicModal(topic)"
                        ngbTooltip="Edit Topic"
                        class="bi bi-pencil cursor-pointer color-tertiary"></i>
                    </td>
                  </tr>
                </ng-container>
                <ng-template #noTopics>
                  <tr>
                    <td colspan="5">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center w-100"
                        style="min-height: 400px">
                        <h2>No Topics Found</h2>
                        <p>Please create a topic</p>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </ng-container>
            </tbody>
          </table>
          <section>
            <div class="d-flex justify-content-center">
              <ngb-pagination
                *ngIf="topicsListMeta && topicsListMeta.totalCount > 10"
                [(page)]="topicsListMeta.currentPage"
                [pageSize]="topicsListMeta.perPage"
                [collectionSize]="topicsListMeta.totalCount"
                [maxSize]="5"
                (pageChange)="outputChanges($event)"></ngb-pagination>
            </div>
          </section>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="w-100" style="height: 700px">
    <app-loader></app-loader>
  </div>
</ng-template>

<ng-template #checked
  ><i class="bi bi-check2-circle text-info"></i
></ng-template>
<ng-template #unchecked><i class="bi bi-circle-fill"></i></ng-template>

<app-topic-page-filter-modal
  *ngIf="displayFilterModal"
  [initialFilters]="searchParams"
  (setFilters)="setFilters($event)"
  (modalClose)="closeFilterModal()" />

<app-create-edit-topic-modal
  #createEditTopicModal
  (topicUpdated)="getTopics()"></app-create-edit-topic-modal>
