import {
  animate,
  style,
  transition,
  state,
  trigger,
} from '@angular/animations';

export const Animations = {
  fadeInOut: trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    transition(':enter', [
      style({ opacity: '0' }),
      animate(
        '.15s 150ms cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        style({ opacity: '1' })
      ),
    ]),
    transition(':leave', [
      style({ opacity: '1' }),
      animate('.15s', style({ opacity: '0' })),
    ]),
  ]),
  delayfadeInOut: trigger('delayfadeInOut', [
    state('void', style({ opacity: 0 })),
    transition(':enter', [
      style({ opacity: '0' }),
      animate(
        '.7s 150ms cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        style({ opacity: '1' })
      ),
    ]),
    transition(':leave', [
      style({ opacity: '1' }),
      animate('.3s', style({ opacity: '0' })),
    ]),
  ]),
  deelaayfadeInOut: trigger('deelaayfadeInOut', [
    state('void', style({ opacity: 0 })),
    transition(':enter', [
      style({ opacity: '0' }),
      animate('1.5s 350ms', style({ opacity: '1' })),
    ]),
    transition(':leave', [
      style({ opacity: '1' }),
      animate('1s', style({ opacity: '0' })),
    ]),
  ]),
};
