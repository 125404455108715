import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  LibraryShelfItemModelDTO,
  ResourceFiltersDTO,
  ResourceFolderModelDTO,
} from '../../dtos/resources.dto';

@Injectable({ providedIn: 'root' })
export class ResourcesBookmarkService {
  constructor(private apiService: APICoreService, private http: HttpClient) {}

  // PARAMS ADDED SO THAT THE RESPONSE INCLUDES WHICH FOLDERS THE ITEM IS BOOKMARKED
  getMyBookmarkedFolders(active_ref_table: string, active_ref_id: number) {
    return this.apiService.getRequest('elearn/favorite-shelves', {
      active_ref_table,
      active_ref_id,
    });
  }

  getChildFolder(
    active_ref_table: string,
    active_ref_id: number,
    folderId: number
  ) {
    return this.apiService.getRequest(`elearn/favorite-shelves/${folderId}`, {
      active_ref_table,
      active_ref_id,
    });
  }

  updateFolderTitle(folderId: number, title: string) {
    return this.apiService.putRequest(`elearn/favorite-shelves/${folderId}`, {
      title,
    });
  }

  addFavoriteFolder(folderId: number, folderItem: LibraryShelfItemModelDTO) {
    return this.apiService.postRequest(
      `elearn/favorite-shelves/${folderId}/items`,
      folderItem
    );
  }

  removeFavoriteFolder(folderId: number, itemType: string, itemId: number) {
    return this.apiService.deleteRequest(
      `elearn/favorite-shelves/${folderId}/${itemType}/${itemId}`
    );
  }

  updateResourceStatus(id: number) {
    return this.apiService.getRequest(`elearn/resources/${id}`);
  }

  getOnlineCourses() {
    return this.apiService.getRequest('elearn/vpl-courses');
  }

  getCreatedByYou() {
    return this.apiService.getRequest('elearn/favorite-shelves/created-by-me');
  }

  getMyFolders() {
    return this.apiService.getRequest('elearn/favorite-shelves');
  }

  getFolder(folderId: number | string) {
    return this.apiService.getRequest(`elearn/favorite-shelves/${folderId}`);
  }

  getUsersFolders(userId: number | string) {
    return this.apiService.getRequest(
      `elearn/favorite-shelves?user_id=${userId}`
    );
  }

  getUsersFolder(folderId: number | string, userId: number | string) {
    return this.apiService.getRequest(
      `elearn/favorite-shelves/${folderId}?user_id=${userId}`
    );
  }

  getUsersFolderItems(
    folderId: number | string,
    resourceFilters: ResourceFiltersDTO
  ) {
    return this.apiService.getRequest(
      `elearn/favorite-shelves/${folderId}/items`,
      resourceFilters
    );
  }

  createFolder(newFolder: ResourceFolderModelDTO) {
    return this.apiService.postRequest('elearn/favorite-shelves', newFolder);
  }

  deleteFolder(folderId: number) {
    return this.apiService.deleteRequest(`elearn/favorite-shelves/${folderId}`);
  }

  getFolderItems(
    folderId: number | string,
    resourceFilters: ResourceFiltersDTO
  ) {
    return this.apiService.getRequest(
      `elearn/favorite-shelves/${folderId}/items`,
      resourceFilters
    );
  }

  updateFolderInfo(folderId: number, newTitle: string, newDescription: string) {
    return this.apiService.putRequest(`elearn/favorite-shelves/${folderId}`, {
      title: newTitle,
      description: newDescription,
    });
  }

  postStarFolder(id: number) {
    return this.apiService.postRequest(`favorite/shelf/${id}`, {});
  }

  deleteStarFolder(id: number) {
    return this.apiService.deleteRequest(`favorite/shelf/${id}`);
  }

  sortFolders(id: number, direction: string, refId: number) {
    return this.apiService.putRequest(
      `elearn/favorite-shelves/${id}/sort/${direction}/${refId}`,
      {}
    );
  }
}
