<div class="container container-main">
  <app-general-header
    [title]="
      ((selectedSchool && selectedSchool.title) ||
        (selectedDistrict && selectedDistrict.title) ||
        'District') + ' Reporting'
    " />
  <div class="card border-0 p-3 mb-3 pb-5">
    <app-reporting-subnav></app-reporting-subnav>
    <div class="card-body">
      <div class="p-3">
        <app-report-filter-bar
          [reportName]="'observation_reports'"
          [canFilterByDistrict]="canFilterByDistrict"></app-report-filter-bar>
      </div>
      <ng-container *ngIf="!loading; else loader">
        <div class="row justify-content-center mt-3">
          <h2 class="text-center fw-800" style="font-size: 20px">
            Observation and Feedback Form:
          </h2>
          <div class="col-md-5" *ngIf="formsList.length > 0; else noForms">
            <ng-select
              [labelForId]="'formselect'"
              [items]="formsList"
              bindLabel="title"
              placeholder="Select a form to see data"
              [(ngModel)]="selectedForm"
              (change)="updateFormSelect($event)" />
          </div>
          <ng-template #noForms>
            <div
              class="pb-5 d-flex justify-content-center align-items-center"
              style="min-height: 200px">
              No forms are available for
              {{ districtDisplayName || user.district?.title || "" }}
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #loader>
        <div class="w-100" style="margin-top: 200px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
    <div *ngIf="selectedForm">
      <div id="calibration-chart" class="p-5 mx-3" *ngIf="formHasCalibration">
        <div class="position-relative">
          <h3 class="fs-21 fw-800 text-center mb-4">Calibration Averages</h3>
          <app-calibration-table [requestParams]="calibrationRequestParams" />
        </div>
      </div>
      <div class="row mt-4" [class.separator-border]="formHasCalibration">
        <div
          class="col-10 offset-1 d-flex align-itmes-center justify-content-center">
          <h3 class="fs-21 fw-800 text-center d-flex align-items-center mb-0">
            Form Responses
          </h3>
        </div>
        <div class="col-1 d-flex justify-content-end align-itmes-center">
          <button
            ngbTooltip="Download CSV"
            aria-label="Download CSV"
            class="btn download-button"
            container="body"
            placement="bottom"
            [autoClose]="false"
            [ngbPopover]="csvDownloadingPopover"
            [disabled]="disableDownload"
            (click)="downloadCSV()"
            #p="ngbPopover">
            <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
          </button>
          <ng-template #csvDownloadingPopover>
            <div class="p-2">
              An email will be sent to you with a link to download the CSV.
            </div>
            <div class="p-2 d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="disableDownload = false; p.close()">
                OK
              </button>
            </div>
          </ng-template>
        </div>
      </div>
      <div id="observed-level-chart" class="p-5 mx-3" *ngIf="formHasLookFors">
        <div class="position-relative">
          <h3 class="fs-21 fw-800 text-center">Look-Fors</h3>
          <app-report-controller
            chartId="observed-level-chart"
            [chartType]="chartTypes.HorizontalStackedBar"
            [requestParams]="lookForsRequestParams"
            [drilldownDimensions]="['school_name', 'user_name']"
            [initialDrilldownFilter]="'competency'"
            [colorPalette]="['#F68888', '#CD87C0', '#6FADCF', '#EDA9CB']"
            [replaceChartOptions]="true"
            [horizontalBarThreshold]="5"
            [legendOptions]="{
              show: true,
              bottom: 0,
              icon: 'circle',
              formatter: 'Level {name}'
            }"
            [gridDimensions]="{
            top: '30px',
            left: '23px',
            right: '15px',
            bottom: '40px',
          }" />
        </div>
      </div>
      <div
        *ngFor="let requestParams of observedAnswersParams; let i = index"
        id="observed-{{ questionTitles[i] }}"
        class="p-5 mx-3"
        [class.separator-border]="
          formHasLookFors || (!formHasLookFors && i !== 0)
        ">
        <div class="position-relative">
          <h3 class="fs-21 fw-800 text-center">{{ questionTitles[i] }}</h3>
          <app-report-controller
            chartId="observed-{{ questionTitles[i] }}"
            cardHeight="18rem"
            [percentage]="true"
            [drilldownDimensions]="['school_name', 'user_name']"
            [initialDrilldownFilter]="'field_option'"
            [tooltipFormatter]="formatter"
            [chartColor]="'#d95195'"
            [chartType]="chartTypes.HorizontalBar"
            [horizontalBarThreshold]="5"
            [yAxisLabel]="'Percent of Visits where\nthe Question was Observed'"
            [gridDimensions]="{
            top: '30px',
            left: '23px',
            right: '15px',
            bottom: '35px',
          }"
            [requestParams]="requestParams" />
        </div>
      </div>
      <div
        *ngFor="let requestParams of textAnswersParams; let i = index"
        id="observed-{{ textQuestionTitles[i] }}"
        class="p-5 mx-3"
        [class.separator-border]="
          formHasLookFors ||
          (observedAnswersParams && observedAnswersParams.length > 0) ||
          (!formHasLookFors &&
            (!observedAnswersParams || observedAnswersParams.length === 0) &&
            i !== 0)
        ">
        <h3 class="fs-21 fw-800 text-center">{{ textQuestionTitles[i] }}</h3>
        <app-table-controller
          [requestParams]="requestParams"
          [fieldId]="textQuestionIds[i]"
          [questionTitle]="textQuestionTitles[i]" />
      </div>
    </div>
    <div class="mt-5 text-muted small text-end">
      Data last refreshed at {{ lastUpdatedDate }}
    </div>
  </div>
</div>
