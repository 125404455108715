import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  dateFromUnixTimestamp,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { AlertService } from '../../../services/alert/alert.service';
import { PlansService } from '../../../services/plans/plans.service';
import { PusherAssignmentChangesDTO } from '../../../services/plans/plans.service.dto';
import {
  AddAssignment,
  CreateDeliverable,
} from '../../../state/implementation-plan/implementation-plan.actions';
import { NewDeliverablePayload } from '../../../types/payloads/plan.payload';
import { PlanDetailsItemAPIResponse } from '../../../types/responses/plan.responses';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../../datepicker/datepicker.component';
import { ModalComponent } from '../../modals/modal/modal.component';

@Component({
  selector: 'app-create-deliverable-modal',
  templateUrl: './create-deliverable-modal.component.html',
  styleUrl: './create-deliverable-modal.component.scss',
})
export class CreateDeliverableModalComponent implements OnInit {
  @Input() planDetails: PlanDetailsItemAPIResponse;

  @Input() phaseId: number; // added via ViewChild from parent component

  @ViewChild('createDeliverableModal')
  createDeliverableModal: ModalComponent;

  @ViewChild('deliverableStartDatePicker') startDatePicker: DatepickerComponent;

  @ViewChild('deliverabledDueDatePicker') dueDatePicker: DatepickerComponent;

  user: User;

  isE2L = false;

  subs: Subscription[] = [];

  form: FormGroup;

  formSubmitted = false;

  selectedUserIds: number[] = [];

  users: UserLiteDTO[] = [];

  newDeliverablePayload: NewDeliverablePayload;

  defaultStartDate: number;

  defaultDueDate: number;

  isLoading = false;

  serverError = '';

  datePickerMinDate: Date;

  datePickerMaxDate: Date;

  currentStartDate: number;

  currentDueDate: number;

  constructor(
    private plansService: PlansService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
  }

  ngOnInit(): void {
    this.setPlanDatesAsDefaultDates();

    this.form = this.formBuilder.group({
      title: [null, Validators.required],
      description: [''],
      department: [''],
      startDate: [dateFromUnixTimestamp(this.defaultStartDate * 1000)],
      dueDate: [dateFromUnixTimestamp(this.defaultDueDate * 1000)],
    });
  }

  setPlanDatesAsDefaultDates() {
    this.datePickerMinDate = new Date(this.planDetails.start_date);
    this.datePickerMaxDate = new Date(this.planDetails.end_date);

    this.defaultStartDate = this.datePickerMinDate.getTime() / 1000;
    this.currentStartDate = this.defaultStartDate;
    this.defaultDueDate = this.datePickerMaxDate.getTime() / 1000;
    this.currentDueDate = this.defaultDueDate;
  }

  get f() {
    return this.form.controls;
  }

  openModal() {
    this.createDeliverableModal.open();
  }

  closeModal() {
    this.createDeliverableModal.close();
  }

  onCancel() {
    this.resetForm();
    this.closeModal();
  }

  resetForm() {
    this.setPlanDatesAsDefaultDates();
    this.setDates(this.defaultStartDate, this.defaultDueDate);
    this.form.reset({
      title: '',
      description: '',
      department: '',
      startDate: dateFromUnixTimestamp(this.defaultStartDate * 1000),
      dueDate: dateFromUnixTimestamp(this.defaultDueDate * 1000),
    });
    this.formSubmitted = false;
    this.users = [];
    this.serverError = '';
  }

  // SET DATES
  updateStartDate(times: DatepickerOutput[]) {
    let startDate = times[0].time / 1000;
    if (startDate > this.currentDueDate) {
      this.currentDueDate = startDate;
    } else if (startDate < this.defaultStartDate) {
      startDate = this.currentStartDate;
    }
    this.setDates(startDate, this.currentDueDate);
  }

  updateDueDate(times: DatepickerOutput[]) {
    let dueDate = times[0].time / 1000;
    if (dueDate < this.currentStartDate) {
      this.currentStartDate = dueDate;
    } else if (dueDate > this.defaultDueDate) {
      dueDate = this.currentDueDate;
    }
    this.setDates(this.currentStartDate, dueDate);
  }

  setDates(startDate?: number, endDate?: number) {
    if (startDate && endDate) {
      this.currentStartDate = startDate;
      this.currentDueDate = endDate;
    } else {
      this.currentStartDate = this.defaultStartDate;
      this.currentDueDate = this.defaultDueDate;
    }
    if (this.startDatePicker && this.dueDatePicker) {
      this.startDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.currentStartDate
      );
      this.dueDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.currentDueDate
      );
    }
    this.f['startDate'].setValue(
      dateFromUnixTimestamp(this.currentStartDate * 1000)
    );
    this.f['dueDate'].setValue(
      dateFromUnixTimestamp(this.currentDueDate * 1000)
    );
    this.cdr.detectChanges();
  }

  // ADD UPDATE ASSIGNEES

  addChosenUser(chosenUser: UserLiteDTO | null) {
    if (chosenUser) {
      if (!this.users.some((user) => user.id === chosenUser.id)) {
        this.users.push(chosenUser);
      }
    }
  }

  deleteChosenUser(chosenUser: UserLiteDTO | null) {
    if (chosenUser) {
      this.users = this.users.filter((user) => user.id !== chosenUser.id);
    }
  }

  createDeliverable() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    this.newDeliverablePayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      department: this.f['department'].value,
      start_date: this.f['startDate'].value,
      due_date: this.f['dueDate'].value,
    };

    this.plansService
      .createDeliverable(this.phaseId, this.newDeliverablePayload)
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: (delResp) => {
          if (delResp.item) {
            this.store.dispatch(new CreateDeliverable(delResp.item));
            if (this.users.length > 0) {
              this.assignUsers(delResp.item.id, this.users);
            }
            this.createDeliverableModal.close();
            this.formSubmitted = false;
            this.isLoading = false;
            this.alertService.showAlert(
              `${this.planDetails.deliverable_displayname} Created`
            );
          }
        },
      });
  }

  assignUsers(delId: number, users: UserLiteDTO[]) {
    users.forEach((user: UserLiteDTO) => {
      this.plansService
        .addDeliverableAssignee(delId, user.id)
        .subscribe((userResp) => {
          const assigneePusher: PusherAssignmentChangesDTO = {
            for: 'implementation_deliverable',
            for_id: delId,
            implementation_phase_id: this.phaseId,
            assignment: userResp.item,
          };
          this.store.dispatch(new AddAssignment(assigneePusher));
        });
    });
  }
}
