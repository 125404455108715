import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { SmartCoachPreviousSessionAPIResponse } from '../../types/responses/smartcoach.responses';

@Injectable({
  providedIn: 'root',
})
export class SmartCoachSessionService {
  constructor(private apiService: APICoreService) {}

  markCoacheePresent(logId: number) {
    return this.apiService.putRequest(`egrowe/smartlog/${logId}`, {
      mark_present: true,
    });
  }

  getMessageFromYourCoachVideo(
    smartCoachUserId: number,
    sessionNumber: string
  ) {
    return this.apiService.getRequest(
      `video?ref_table=user&ref_id=${smartCoachUserId}&subject=session${sessionNumber}CoachIntroMessage`
    );
  }

  getPreviousSession(
    logId: number
  ): Observable<SmartCoachPreviousSessionAPIResponse> {
    return this.apiService
      .getRequest(`egrowe/smartlog/${logId}/previous-session`)
      .pipe(map((res) => res.item as SmartCoachPreviousSessionAPIResponse));
  }
}
