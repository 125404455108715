import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CoachlogSearchPayloadItemsDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import { dateTimeFromNgbDate } from 'src/app/common/utilities/date-helpers';
import { TopicSearchParams } from 'src/app/private/pages/topics-page/topics-page-helpers';
import { CelebrationFilterDTO } from '../../dtos/celebrations.dto';
import { FilterList, FilterType } from './applied-filters.dto';

@Component({
  selector: 'app-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
})
export class AppliedFiltersComponent implements OnChanges {
  // Add more Payload DTOs as needed, and display them separately in template
  @Input() coachlogSearchOptions: CoachlogSearchPayloadItemsDTO;

  @Input() celebrationSearchOptions: CelebrationFilterDTO;

  @Input() topicPageSearchOptions: TopicSearchParams;

  @Input() filterType: string;

  @Input() canSeeAllSchools: boolean;

  @Output() readonly filterRemoved: EventEmitter<string> = new EventEmitter();

  @Output() readonly filterListRemoved: EventEmitter<FilterList> =
    new EventEmitter();

  @Input() dateChange: NgbDate | null;

  fromDate: number;

  toDate: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dateChange'] &&
      this.celebrationSearchOptions?.fromDate &&
      this.celebrationSearchOptions?.toDate
    ) {
      this.fromDate = dateTimeFromNgbDate(
        this.celebrationSearchOptions.fromDate
      ).toSeconds();
      this.toDate = dateTimeFromNgbDate(
        this.celebrationSearchOptions.toDate
      ).toSeconds();
    }
  }

  removeFilter(keyName: string) {
    this.filterRemoved.emit(keyName);
  }

  removeFilterFromList(keyName: string, value: FilterType) {
    this.filterListRemoved.emit({
      keyName,
      value,
    });
  }
}
