import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncatePhrase',
})
export class TruncatePhrasePipe implements PipeTransform {
  /* eslint-disable-next-line class-methods-use-this */
  transform(value: string, chars = 30, breakInsideWord = false): string {
    if (!value) {
      return '';
    }
    if (value.length <= chars) {
      return value;
    }
    let hasSpaces = false;
    let newValue2 = '';
    const newValue = value.substr(0, chars);
    if (breakInsideWord) {
      return newValue;
    }
    for (let i = newValue.length - 1; i > -1; i -= 1) {
      const eachChar = newValue.substr(i, 1);
      if (eachChar === ' ') {
        hasSpaces = true;
      }
    }
    let didFindSpace = false;
    let didFindCharAfterSpace = false;
    for (let i = newValue.length - 1; i > -1; i -= 1) {
      const eachChar2 = newValue.substr(i, 1);
      if (!hasSpaces) {
        newValue2 += newValue.substr(i, 1);
      } else {
        if (didFindSpace) {
          if (eachChar2 !== ' ' || didFindCharAfterSpace) {
            newValue2 += newValue.substr(i, 1);
            didFindCharAfterSpace = true;
          }
        }
        if (eachChar2 === ' ') {
          didFindSpace = true;
        }
      }
    }
    let newValue3 = '';
    for (let i = newValue2.length; i > -1; i -= 1) {
      newValue3 += newValue2.substr(i, 1);
    }
    newValue3 += '...';
    return newValue3;
  }
}
