<app-form-edit-text
  #field
  [(data)]="fieldData"
  *ngIf="
    type === fieldTypes.TEXT || type === fieldTypes.TEXTBLOCK
  "></app-form-edit-text>
<app-form-edit-freetext
  #field
  [(data)]="fieldData"
  *ngIf="type === fieldTypes.FREETEXT"></app-form-edit-freetext>
<!-- disable ability to change if has submissions against it -->
<app-form-edit-multiple-choice
  #field
  [(data)]="fieldWithChoicesData"
  *ngIf="
    type === fieldTypes.RADIO ||
    type === fieldTypes.SELECT ||
    type === fieldTypes.CHECKBOX
  "></app-form-edit-multiple-choice>
<app-form-edit-competency
  #field
  [(data)]="fieldData"
  *ngIf="type === fieldTypes.COMPETENCY"></app-form-edit-competency>
<app-form-edit-section
  #field
  [(data)]="fieldData"
  *ngIf="type === fieldTypes.SECTION"></app-form-edit-section>
<app-form-edit-media
  #field
  [(data)]="fieldData"
  *ngIf="type === fieldTypes.MEDIA"></app-form-edit-media>
