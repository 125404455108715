<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Copy Evidence to Another GI',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    windowClass: 'custom-class',
    customFooter: true
  }"
  (isDismissing)="clearFields()"
  (isClosing)="clearFields()">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <p class="text-center mb-2">
    Copying evidence to another GI will apply this evidence's title,
    description, and attachment(s) to the selected GI(s).
  </p>
  <p class="text-center fw-light text-muted fs-14">
    Note: these changes will only apply to the user's portfolio
  </p>
  <div *ngFor="let item of formList; let formIndex = index">
    <ng-container *ngIf="formIndex > 0">
      <hr class="border border-primary border-1" />
      <button
        (click)="deleteForm(formIndex)"
        aria-label="Delete form item"
        class="btn btn-delete ms-auto d-block py-0"
        type="button">
        <i class="bi bi-trash"></i>
      </button>
    </ng-container>
    <form>
      <div class="form-group">
        <p class="fw-bold mb-1">Topic:</p>
        <app-topic-select
          [label]="'Topic'"
          [topics]="topics"
          [districtIds]="[districtId]"
          [placeholder]="'Search for a topic...'"
          (selectedTopic)="updateTopic($event, formIndex)" />
      </div>
      <div class="form-group mt-3">
        <p class="fw-bold mb-1">Competency:</p>
        <app-competency-select
          #competencySelect
          [isDisabled]="formList[formIndex].rubric_id === 0"
          [districtIds]="[districtId]"
          [rubricId]="
            formList[formIndex].rubric_id !== 0
              ? formList[formIndex].rubric_id
              : undefined
          "
          [isMulti]="false"
          [hideTags]="true"
          [placeholder]="'Search for a competency...'"
          (selectedCompetency)="updateCompetency($event, formIndex)" />
      </div>
      <div class="form-group mt-3">
        <p class="fw-bold mb-1">Strand:</p>
        <app-strand-select
          [isDisabled]="formList[formIndex].standard_id === 0"
          [label]="'Strand'"
          [strands]="strands[formIndex]"
          [placeholder]="'Select a strand...'"
          (selectedStrand)="updateStrand($event, formIndex)" />
      </div>
      <div class="row mt-3">
        <p class="fw-bold mb-2">Level:</p>
        <div class="col-2" *ngFor="let level of levels">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              (change)="onCheckboxChange($event, level, formIndex)"
              [id]="'level-' + level + '-checkbox'" />
            <label
              class="form-check-label"
              [for]="'level-' + level + '-checkbox'">
              {{ level }}
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <button (click)="addForm()" class="btn btn-link fw-bold mt-4 p-0">
    <i class="bi bi-copy me-2"></i>Copy to More GIs
  </button>
  <ng-container *ngIf="showError">
    <div
      class="alert alert-danger text-start mt-3 alert-dismissible"
      role="alert">
      There was an error copying evidence. Please try again.
      <button
        type="button"
        class="btn-close"
        (click)="showError = false"
        data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </ng-container>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="modal.close()">Cancel</button>
      <button
        (click)="onSubmit()"
        [disabled]="!canSubmit || submitLoading"
        type="button"
        class="btn btn-md btn-primary">
        <span
          *ngIf="submitLoading"
          class="spinner-border spinner-border-sm me-1"></span>
        Save
      </button>
    </div>
  </div>
</app-modal>
