import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { KpiReportFilter } from '../../../../common/dtos/reports.dto';
import { reportingLogDetailsDTOFromApiResponse } from '../../helpers/translators/reporting.translators';
import { ReportingLogDetailsItemAPIResponse } from '../../types/responses/reporting.responses';

@Injectable({
  providedIn: 'root',
})
export class ReportSessionDetailsService {
  constructor(private apiService: APICoreService) {}

  getSessionDetails(
    filters: KpiReportFilter[],
    page = 1,
    sort_by = '',
    sort_by_desc = 0,
    per_page = 10,
    report = 'logDetails'
  ) {
    const params = {
      filters,
      page,
      sort_by,
      sort_by_desc,
      per_page,
      report,
    };
    return this.apiService.getRequest('reporting/load-report', params).pipe(
      map((res) => {
        if (res.items) {
          res.items = res.items.map(
            (item: ReportingLogDetailsItemAPIResponse) =>
              reportingLogDetailsDTOFromApiResponse(item)
          );
        }
        return res;
      })
    );
  }

  queueDownloadReport(
    filters: KpiReportFilter[],
    report?: { title: string; report: string }
  ) {
    const params = {
      report_title: 'Session Details',
      report: 'logDetails',
      filters,
    };

    if (report) {
      params.report_title = report.title;
      params.report = report.report;
    }

    return this.apiService
      .postRequest('reporting/queue-report-download', params)
      .pipe(map((res: boolean) => res));
  }
}
