<ng-container>
  <ng-select
    class="has-option-select"
    [labelForId]="labelId"
    [placeholder]="'Search for data attached to options...'"
    [items]="['Yes', 'No']"
    [searchable]="false"
    [(ngModel)]="selectedHasOption"
    (change)="emittedOption.emit(selectedHasOption); hasOptionSelect.blur()"
    #hasOptionSelect>
  </ng-select>
</ng-container>
