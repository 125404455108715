import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CohortDTO } from 'src/app/common/dtos/cohort.dto';

@Component({
  selector: 'app-cohort-preview-card',
  templateUrl: './cohort-preview-card.component.html',
  styleUrls: ['./cohort-preview-card.component.scss'],
})
export class CohortPreviewCardComponent {
  @Input() cohort: CohortDTO;

  @Output() readonly clickedCohort: EventEmitter<CohortDTO> =
    new EventEmitter<CohortDTO>();

  didClickCohort() {
    if (this.cohort) {
      this.clickedCohort.emit(this.cohort);
    }
  }
}
