import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { PreAssessmentQuestionResponsePayload } from '../../../types/payloads/smart-coach-pre-assessment.payloads';

@Injectable({
  providedIn: 'root',
})
export class PreAssessmentService {
  constructor(private apiService: APICoreService) {}

  getPreAssessmentQuestions(
    rubricId: number,
    standardId: number,
    userId: number
  ) {
    return this.apiService.getRequest(
      `indicator-group-pre-assessment-question/strands/by-standard/${rubricId}/${standardId}/for-user/${userId}`
    );
  }

  submitPreAssessmentResponse(
    egroweGroupId: number,
    payload: PreAssessmentQuestionResponsePayload,
    goal: number
  ) {
    return this.apiService.postRequest(
      `indicator-group-pre-assessment-question/self-assess-strand/${egroweGroupId}/set-goal/${goal}`,
      payload
    );
  }
}
