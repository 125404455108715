import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngxs/store';

import { Subscription } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  SortableHeader,
  SortEvent,
} from '../../shared/directives/sortable-header.directive';
import { NewCoachingSessionUserDto } from '../../shared/dtos/coaching-session.dto';
import { CoachListService } from '../../shared/services/coach-list/coach-list.service';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { FilterCoachTableSearch } from './coach-search-filter.pipe';
import { SelectableCoach } from './selectable.coach';

interface CoachListSortIcon {
  name: 'gray' | 'none';
  avgfeedback: 'gray' | 'none';
  avgconfidence: 'gray' | 'none';
  avggis: 'gray' | 'none';
  avgbadges: 'gray' | 'none';
  supportsessions: 'gray' | 'none';
  celebrations: 'gray' | 'none';
}

const compare = (v1: string | number, v2: string | number) =>
  /* eslint-disable-next-line no-nested-ternary */
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

const columnDataMap = (
  coach: SelectableCoach,
  column: string
): string | number => {
  switch (column) {
    case 'avgfeedback':
      return coach.coachstats.average_feedback_rating || 0;
    case 'avgconfidence':
      return coach.coachstats.average_coachee_confidence_rating || 0;
    case 'avggis':
      return coach.coachstats.average_gis_per_session || 0;
    case 'avgbadges':
      return coach.coachstats.average_badges_per_session || 0;
    case 'supportsessions':
      return coach.coachstats.average_sessions_per_coachee || 0;
    case 'celebrations':
      return coach.coachstats.celebrations_given || 0;
    case 'name':
    default:
      return `${coach.profile.first_name} ${coach.profile.last_name}`;
  }
};

@Component({
  selector: 'app-coach-list-page',
  templateUrl: './coach-list-page.component.html',
  styleUrl: './coach-list-page.component.scss',
})
export class CoachListPageComponent implements OnInit, OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  subs: Subscription[] = [];

  user: User;

  coaches: SelectableCoach[];

  isLoading = false;

  tableData: SelectableCoach[];

  totalCoaches: number;

  selectedCoachIds: number[] = [];

  attendeeUsers: UserDTO[] | null;

  attendeeUserProfiles: NewCoachingSessionUserDto[] | null;

  sortIconTemp: CoachListSortIcon = {
    name: 'gray',
    avgfeedback: 'gray',
    avgconfidence: 'gray',
    avggis: 'gray',
    avgbadges: 'gray',
    supportsessions: 'gray',
    celebrations: 'gray',
  };

  constructor(
    private coachListService: CoachListService,
    private coachingLogService: CoachingLogService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    if (!this.attendeeUserProfiles) {
      this.attendeeUserProfiles = [];
    }

    this.subs.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.unselectAll();
      })
    );

    this.getCoaches();
  }

  getCoaches(): void {
    this.isLoading = true;
    this.selectedCoachIds = [];
    this.subs.push(
      this.coachListService
        .fetchCoaches({
          expand: 'coachstats,profile,personalNotes',
          per_page: 1000,
          district_id: this.user.district?.id,
          coachee_type: 'coach',
        })
        .subscribe((resp) => {
          if (resp) {
            this.coaches = resp.items as SelectableCoach[];
            this.tableData = this.coaches;
            this.totalCoaches = resp._meta.totalCount;
            this.isLoading = false;
          }
        })
    );
  }

  searchCoaches(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.tableData = new FilterCoachTableSearch().transform(
      this.coaches,

      searchTerm
    );
    // resetting other headers
    this.headers.forEach((header) => {
      header.direction = '';
      this.sortIconTemp[<keyof CoachListSortIcon>header.sortable] = 'gray';
    });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[<keyof CoachListSortIcon>header.sortable] = 'gray';
      } else {
        this.sortIconTemp[<keyof CoachListSortIcon>header.sortable] = 'none';
      }
    });

    if (direction === '' || column === '') {
      this.tableData = [...this.tableData].sort((a, b) =>
        compare(columnDataMap(a, 'name'), columnDataMap(b, 'name'))
      );

      this.sortIconTemp[<keyof CoachListSortIcon>column] = 'gray';
    } else {
      this.tableData = [...this.tableData].sort((a, b) => {
        const res = compare(
          columnDataMap(a, column as string),
          columnDataMap(b, column as string)
        );
        return direction === 'desc' ? res : -res;
      });
    }
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      if (target.checked) {
        this.selectedCoachIds.push(parseInt(target.value));
      } else {
        const index = this.selectedCoachIds.findIndex(
          (value) => value === parseInt(target.value)
        );
        this.selectedCoachIds.splice(index, 1);
      }
      this.updateCoachlogAttendees();
    }
  }

  unselectAll() {
    this.selectedCoachIds = [];
    this.coaches.forEach((item: SelectableCoach) => {
      item.isSelected = false;
    });
    this.updateCoachlogAttendees();
  }

  updateCoachlogAttendees() {
    this.attendeeUserProfiles = [];
    this.selectedCoachIds.forEach((coachId) => {
      let foundUser = false;
      const getUserProfile: NewCoachingSessionUserDto = {
        id: coachId,
        profile: {
          user_id: 0,
          first_name: '',
          last_name: '',
          title: '',
          color: '',
          photo: '',
        },
      };
      if (this.tableData?.length) {
        this.tableData.forEach((eachCoachee) => {
          if (coachId === eachCoachee.id) {
            foundUser = true;
            getUserProfile.profile = eachCoachee.profile;
          }
        });
      }

      if (foundUser) {
        if (this.attendeeUserProfiles) {
          if (!this.attendeeUserProfiles.includes(getUserProfile)) {
            this.attendeeUserProfiles.push(getUserProfile);
          }
        }
      }
    });
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
