<ng-container *ngIf="competenciesToAdd?.length || resourceCompetencies?.length">
  <div
    *ngIf="resourceCompetencies && resourceCompetencies.length > 0"
    class="row mb-1">
    <div class="col-6 competency-title">
      <span class="bold-text">Competency</span>
    </div>
    <div class="col-6 competency-title">
      <span class="bold-text">Level</span>&nbsp; &nbsp;
    </div>
  </div>

  <div
    *ngFor="let competency of resourceCompetencies; let i = index"
    class="row mb-1">
    <div class="col-6">
      <span class="badge badge-item">{{
        competency.name | truncatePhrase : 22
      }}</span>
    </div>
    <div class="col-6">
      &nbsp; &nbsp;<span class="levels-label">{{
        competency.levelsFormatted
      }}</span>
      <ng-container *ngIf="competency.can_delete && hasEditPermissions">
        &nbsp;
        <a>
          <i
            class="bi bi-x-circle-fill delete-comp"
            (click)="deleteCompetency(competency)"></i>
        </a>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="competenciesToAdd?.length">
    <div class="col competency-title mt-4 mb-2">
      <div class="bold-text">Add Competency</div>
      <i class="validation-label">At least one level selection required</i>
    </div>

    <ng-container *ngFor="let competency of competenciesToAdd; let i = index">
      <div class="row justify-content-between mb-1">
        <div class="col-9">
          <div>
            <span class="badge badge-item">{{
              competency.name | truncatePhrase : 22
            }}</span>
          </div>
          <div class="d-flex">
            <div *ngFor="let eachLevel of levelNums" class="me-3">
              <app-competency-level-checkbox
                [level]="eachLevel"
                (sendIsChecked)="checkedLevelBox($event, i, eachLevel)" />
            </div>
          </div>
        </div>

        <div class="col-3">
          <button
            class="btn btn-primary check-label"
            [disabled]="!competency.levelsFormatted"
            (click)="addCompetency(competency, i)">
            Done
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
