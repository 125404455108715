import { numberFromNumberOrString } from 'src/app/common/utilities/type-helpers';
import {
  ResourceListItemDTO,
  ResourceLeveledListItemDTO,
} from '../../../dtos/resources.dto';
import { ResourceType } from '../../../enums/resource-type.enum';

export interface ResourcePreviewDTO {
  id: number;
  type: ResourceType;
  title: string;
  description: string;
  isOnUsersShelf: number;
}

export const resourceListItemDTOToResourcePreviewDTO = (
  resourceDto: ResourceListItemDTO
): ResourcePreviewDTO => ({
  id: numberFromNumberOrString(resourceDto.id),
  type: ResourceType.RESOURCE,
  title: resourceDto.title,
  description: resourceDto.description,
  isOnUsersShelf: resourceDto.isOnUsersShelf,
});

export const resourceLeveledListItemDTOToResourcePreviewDTO = (
  resourceDto: ResourceLeveledListItemDTO
): ResourcePreviewDTO => ({
  id: numberFromNumberOrString(resourceDto.id),
  type: ResourceType.RESOURCE,
  title: resourceDto.title,
  description: resourceDto.description,
  isOnUsersShelf: resourceDto.isOnUsersShelf,
});
