<ng-container *ngIf="profile">
  <ng-container *ngIf="firstNameOnly; else fullName">
    <span class="user-name">{{ profile.first_name }}</span>
  </ng-container>
  <ng-template #fullName>
    <span class="user-name"
      >{{ profile.first_name }} {{ profile.last_name }}</span
    >
  </ng-template>
</ng-container>
