<app-form-view-checkbox
  #fieldView
  [inputDisabled]="inputDisabled"
  [readOnly]="readOnly"
  [initialValue]="inputData ? stringArrayCaster(inputData).value : null"
  (value)="
    data.next({
      fieldId: fieldWithChoicesDefs.id,
      type: fieldDataTypes.STRING_ARRAY,
      value: $event
    })
  "
  [fieldDefinition]="fieldWithChoicesDefs"
  *ngIf="
    fieldWithChoicesDefs && fieldWithChoicesDefs.type === fieldTypes.CHECKBOX
  "></app-form-view-checkbox>
<app-form-view-select
  #fieldView
  [inputDisabled]="inputDisabled"
  [readOnly]="readOnly"
  [initialValue]="inputData ? stringCaster(inputData).value : undefined"
  (value)="
    data.next({
      fieldId: fieldWithChoicesDefs.id,
      type: fieldDataTypes.STRING,
      value: $event
    })
  "
  [fieldDefinition]="fieldWithChoicesDefs"
  *ngIf="
    fieldWithChoicesDefs && fieldWithChoicesDefs.type === fieldTypes.SELECT
  "></app-form-view-select>
<app-form-view-radio
  #fieldView
  [inputDisabled]="inputDisabled"
  [readOnly]="readOnly"
  [initialValue]="inputData ? stringCaster(inputData).value : undefined"
  (value)="
    data.next({
      fieldId: fieldWithChoicesDefs.id,
      type: fieldDataTypes.STRING,
      value: $event
    })
  "
  [fieldDefinition]="fieldWithChoicesDefs"
  *ngIf="
    fieldWithChoicesDefs && fieldWithChoicesDefs.type === fieldTypes.RADIO
  "></app-form-view-radio>
<app-form-view-text
  #fieldView
  [inputDisabled]="inputDisabled"
  [readOnly]="readOnly"
  [initialValue]="inputData ? stringCaster(inputData).value : undefined"
  (value)="
    data.next({
      fieldId: fieldDefs.id,
      type: fieldDataTypes.STRING,
      value: $event
    })
  "
  [fieldDefinition]="fieldDefs"
  *ngIf="fieldDefs && fieldDefs.type === fieldTypes.TEXT"></app-form-view-text>
<app-form-view-wysiwyg
  #fieldView
  [inputDisabled]="inputDisabled"
  [readOnly]="readOnly"
  [initialValue]="inputData ? stringCaster(inputData).value : undefined"
  (value)="
    data.next({
      fieldId: fieldDefs.id,
      type: fieldDataTypes.STRING,
      value: $event
    })
  "
  [fieldDefinition]="fieldDefs"
  *ngIf="
    fieldDefs && fieldDefs.type === fieldTypes.TEXTBLOCK
  "></app-form-view-wysiwyg>
<app-form-view-media
  #fieldView
  [inputDisabled]="inputDisabled"
  [initialValue]="inputData ? fileCaster(inputData).value : undefined"
  (value)="
    data.next({
      fieldId: fieldDefs.id,
      type: fieldDataTypes.STRING,
      value: $event
    })
  "
  [fieldDefinition]="fieldDefs"
  *ngIf="
    fieldDefs && fieldDefs.type === fieldTypes.MEDIA
  "></app-form-view-media>
<app-form-view-competency
  #fieldView
  [inputDisabled]="inputDisabled"
  [readOnly]="readOnly"
  [initialValue]="inputData ? indicatorCaster(inputData).value : null"
  (value)="
    data.next({
      fieldId: fieldDefs.id,
      type: fieldDataTypes.COMPETENCY,
      value: $event
    })
  "
  [fieldDefinition]="fieldDefs"
  *ngIf="
    fieldDefs && fieldDefs.type === fieldTypes.COMPETENCY
  "></app-form-view-competency>
