export enum UserType {
  LEARNER = 'learner',
  LEADER = 'leader',
  COACH = 'coach',
}

export const CoacheeTypeToUserType = new Map<string, UserType>([
  ['Librarian', UserType.LEARNER],
  ['Teacher', UserType.LEARNER],
  ['District Admin', UserType.LEADER],
  ['School Admin', UserType.LEADER],
  ['Coach', UserType.COACH],
]);

// eslint-disable-next-line max-len
export const getUserTypeFromDTO = (coacheeType: string): UserType =>
  CoacheeTypeToUserType.get(coacheeType) || UserType.LEARNER;

/* eslint-disable-next-line max-len */
export const compareTypes = (types1: UserType[], type2: UserType): boolean =>
  types1.some((type: UserType) => type2.includes(type));
