<label [id]="id" class="form-label ms-2">{{ fieldDefinition.label }}</label>
<div class="competency" *ngIf="formCompetency && indicatorGroups">
  <h4 class="text-center mt-2" *ngIf="selectedCompetency">
    {{ selectedCompetency.title }} ({{ selectedCompetency.rubric_name }})
  </h4>
  <ng-container *ngFor="let indicatorGroup of indicatorGroups; let last = last">
    <div class="d-flex justify-content-between mx-1 mt-3">
      <div *ngIf="!inputDisabled">Check off the behaviors you observe:</div>
      <div class="fw-600">{{ indicatorGroup.title }}</div>
    </div>
    <div
      class="d-flex flex-column flex-md-row flex-nowrap mt-0 w-100"
      style="overflow-x: auto">
      <div
        class="level-card competency-card card d-flex flex-column"
        [class.disabled]="inputDisabled"
        *ngFor="let indicator of indicatorGroup.indicators"
        (click)="selectIndicator(indicator)">
        <div
          [ngClass]="[
            'bg-level-' + indicator.level,
            'card-header',
            'section-header',
            'text-light',
            'fs-22',
            'fw-600',
            'text-end',
            'text-nowrap',
            'align-middle'
          ]">
          Level
          <i class="bi bi-{{ indicator.level }}-circle ms-1"></i>
        </div>
        <div class="d-flex justify-content-between p-2">
          <div class="indicator-container me-1">
            <i
              [class.opacity-0]="readOnly"
              [ngClass]="[
                'bi',
                'bi-circle',
                'position-absolute',
                'fs-5',
                this.indicatorsChecked.includes(indicator.id)
                  ? 'color-active'
                  : 'color-gray'
              ]"></i>
            <i
              *ngIf="this.indicatorsChecked.includes(indicator.id)"
              class="bi bi-check-lg fs-4 color-active"></i>
          </div>
          <span class="fs-13">{{ indicator.title }}</span>
        </div>
      </div>
    </div>
    <div
      #guidanceContainer
      *ngIf="indicatorGroup.sampleQuestions && last && !inputDisabled"
      class="guidance ms-1">
      <div class="questions bg-secondary rounded">
        <div class="p-3" [innerHTML]="indicatorGroup.sampleQuestions"></div>
      </div>
      <button
        class="btn btn-sm d-inline-block"
        (click)="guidanceContainer.classList.toggle('showQuestions')">
        <i class="bi bi-arrows-expand me-1"></i>
        Guiding Questions
      </button>
    </div>
  </ng-container>
</div>
