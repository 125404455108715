import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, map } from 'rxjs';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  AttendeeRubricDTO,
  CompetencyDTO,
} from '../../../dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from '../../../dtos/coaching-session.dto';
import { PortfolioService } from '../../../services/portfolio/portfolio.service';
import {
  FetchAttendeeRubrics,
  FetchSessionData,
} from '../../../state/coaching-log/coaching-log.actions';
import { CoachingLogState } from '../../../state/coaching-log/coaching-log.state';
import {
  ModalComponent,
  ModalConfig,
} from '../../modals/modal/modal.component';

interface CompetencyDictionaryItem {
  rubricId: number;
  userId: number;
  competency: CompetencyDTO;
}

@Component({
  selector: 'app-leveled-competency-modal',
  templateUrl: './leveled-competency-modal.component.html',
  styleUrls: ['./leveled-competency-modal.component.scss'],
})
export class LeveledCompetencyModalComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('internalModal') internalModal: ModalComponent;

  @ViewChild('inner') inner: ElementRef<HTMLDivElement>;

  @ViewChild('xButton') xButton: ElementRef;

  attendeeRubrics$ = select(CoachingLogState.getAttendeeRubricsData);

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO;

  @Input() coachlogId: number;

  @Input() competencyId: number;

  @Input() userId: number;

  @Input() showOptions = true;

  @Input() portfolio = false;

  @Input() viewingPrevSession = false;

  @Input() currentSessionId: number;

  @Output() readonly modalClosed: EventEmitter<boolean> = new EventEmitter();

  rubricData: AttendeeRubricDTO[] = [];

  competencyDictionary: CompetencyDictionaryItem[] = [];

  portfolioCompetency?: CompetencyDTO;

  rubricId: number;

  private isOpen = false;

  public modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  modalConfig: ModalConfig = {
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    hideCloseButton: true,
    options: {
      size: 'xl',
    },
    showFooter: false,
  };

  constructor(
    private store: Store,
    private portfolioService: PortfolioService
  ) {}

  ngOnInit(): void {
    if (this.coachlogId && this.coachlogId !== 0) {
      this.store.dispatch(new FetchSessionData(this.coachlogId));
      this.store.dispatch(new FetchAttendeeRubrics(this.coachlogId));
      this.attendeeRubrics$
        .pipe(map((filterFn) => filterFn(this.coachlogId)))
        .subscribe((data) => {
          // This causes an infinite loop if you don't check if the data has changed
          if (data && data !== this.rubricData) {
            this.rubricData = data;
            const chosenRubrics = this.userId
              ? data.filter(
                  (attendeeRubric) => attendeeRubric.userId === this.userId
                )
              : data;
            if (this.competencyId) {
              chosenRubrics.forEach((attendeeRubric) =>
                attendeeRubric.rubrics.forEach((rubric) =>
                  rubric.competencyGroups.forEach((competencyGroup) =>
                    competencyGroup.competencies.forEach((competency) => {
                      if (competency.id === this.competencyId) {
                        this.competencyDictionary = [
                          {
                            userId: attendeeRubric.userId,
                            rubricId: rubric.id,
                            competency,
                          },
                        ];
                        this.internalModal.config.titleText = competency.title;
                      }
                    })
                  )
                )
              );
            } else {
              this.competencyDictionary = chosenRubrics.flatMap(
                (attendeeRubric) =>
                  attendeeRubric.rubrics.flatMap((rubric) =>
                    rubric.competencyGroups.flatMap((competencyGroup) =>
                      competencyGroup.competencies.map((competency) => ({
                        userId: attendeeRubric.userId,
                        rubricId: rubric.id,
                        competency,
                      }))
                    )
                  )
              );
            }
          }
        });
    } else if (this.portfolio) {
      this.portfolioService.connectToPusherChannel(this.userId);
      this.portfolioService
        .getCompetencyRubric(this.userId, this.competencyId)
        .subscribe((res) => {
          this.portfolioCompetency =
            res.rubricDto.competencyGroups[0].competencies[0];
          this.rubricId = res.rubricDto.id;
          this.internalModal.config.titleText = this.portfolioCompetency.title;
        });

      this.portfolioService.competencySubject.subscribe({
        next: (res) => {
          this.portfolioCompetency = res;
        },
      });
    }
    if (this.sessionData$) {
      this.sessionData$
        .pipe(map((filterFn) => filterFn(this.currentSessionId)))
        .subscribe((res) => {
          if (res) {
            this.sessionData = res;
          }
        });
    }
  }

  ngAfterViewInit() {
    this.internalModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
      this.competencyDictionary = [];
      if (this.currentSessionId) {
        this.store.dispatch(new FetchAttendeeRubrics(this.currentSessionId));
      }
      if (this.portfolioCompetency) {
        delete this.portfolioCompetency;
      }
      this.modalClosed.emit(true);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.internalModal.open();
          this.isOpen = true;

          if (this.xButton?.nativeElement) {
            this.xButton.nativeElement.focus();
          }
        }
      } else if (this.isOpen) {
        this.internalModal.close();
        this.isOpen = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.isOpen) {
      this.internalModal.close();
    }
    if (this.portfolio) {
      this.portfolioService.disconnectFromPusherChannel(this.userId);
    }
  }
}
