import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  SelfAssessmentRequirementAPIResponse,
  SelfAssessmentRequirementDTO,
} from 'src/app/common/types/responses/responses';
import { selfAssessmentRequirementDTOFromAPIResponse } from '../../../helpers/translators/smart-coach.translators';

@Injectable({
  providedIn: 'root',
})
export class EvidenceSelfAssessmentService {
  constructor(private apiService: APICoreService) {}

  getRequirements(
    standard_id: number,
    strand_id: number,
    level: number,
    user_id: number,
    option_id: number,
    curated_option_id?: number
  ) {
    const params = {
      standard_id,
      strand_id,
      level,
      user_id,
      option_id,
      curated_option_id,
    };
    return this.apiService
      .getRequest('egrowe/smartlog/evidence-reflection', params)
      .pipe(
        map((res) =>
          res.requirements.map(
            (requirement: SelfAssessmentRequirementAPIResponse) =>
              selfAssessmentRequirementDTOFromAPIResponse(requirement)
          )
        )
      );
  }

  submitSelfAssessment(
    logId: number,
    requirementDTOs: SelfAssessmentRequirementDTO[],
    rubric_id: number,
    level: number,
    option_id: number
  ) {
    const requirements: { id: number; checked: boolean }[] = [];

    requirementDTOs.forEach((requirement) => {
      requirements.push({ id: requirement.id, checked: requirement.checked });
    });

    const params = {
      requirements,
      rubric_id,
      level,
      option_id,
    };

    return this.apiService.postRequest(
      `egrowe/smartlog/${logId}/indicator-requirement-assessments`,
      params
    );
  }
}
