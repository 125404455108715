<div class="row justify-content-md-center">
  <div class="col-lg-9 text-center">
    <h3 class="fw-800">Growe Coaching Process</h3>
    <blockquote class="bquote mt-3 mb-5 d-flex flex-nowrap">
      <i class="bi bi-quote me-3"></i>
      <div class="flex-growe-1">
        <div>
          Our mission is to help the neighborhood public school be the first
          choice for every family.
        </div>

        <div>
          -
          <img
            src="assets/e2l-logo.png"
            class="e2l-logo"
            alt="e2l Logo"
            role="img" />
        </div>
      </div>
      <i class="bi bi-quote ms-3 right"></i>
    </blockquote>
  </div>
</div>

<div *ngIf="groweProcessVideo" class="row justify-content-md-center">
  <div class="col-lg-8">
    <app-video-player [video]="groweProcessVideo"></app-video-player>
  </div>
</div>

<div class="row mt-5 justify-content-md-between">
  <div class="col-lg-5">
    <h4 class="fw-800 fs-22">Your Goals for Your Learners or Team</h4>
    <textarea
      aria-label="Edit Your Goals for Your Learners or Team"
      #yourlearnergoals="ngModel"
      name="yourlearnergoals"
      id="yourlearnergoals"
      class="form-control mb-5"
      appAutosave
      [(ngModel)]="initialLearners"
      (autosave)="saveUserProfileLearners()"
      [ngClass]="{
        'is-invalid':
          yourlearnergoals.invalid &&
          (yourlearnergoals.dirty || yourlearnergoals.touched)
      }"
      rows="4"
      required></textarea>
  </div>
  <div class="col-lg-5">
    <h4 class="fw-800 fs-22">Your Goals for Coaching</h4>
    <textarea
      aria-label="Edit Your Why"
      #yourcoachinggoals="ngModel"
      name="yourcoachinggoals"
      id="yourcoachinggoals"
      class="form-control mb-5"
      appAutosave
      [(ngModel)]="initialCoaching"
      (autosave)="saveUserProfileCoaching()"
      [ngClass]="{
        'is-invalid':
          yourcoachinggoals.invalid &&
          (yourcoachinggoals.dirty || yourcoachinggoals.touched)
      }"
      rows="4"
      required></textarea>
  </div>
</div>
