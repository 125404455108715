import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class VideoPlayerService {
  constructor(private apiService: APICoreService) {}

  getClosedCaptions(videoId: number) {
    return this.apiService.getRequest(`video/${videoId}/closed-captions`);
  }
}
