import { Injectable } from '@angular/core';
import {
  HOUR_IN_MILLISECONDS,
  MONTH_IN_MILLISECONDS,
} from '../../utilities/time.definitions';

@Injectable({ providedIn: 'root' })
export class StorageService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static storeItem(key: string, item: any) {
    window.localStorage.setItem(
      key,
      JSON.stringify({ time: Date.now(), data: item })
    );
  }

  static removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  static getItem(key: string, timeout: number = HOUR_IN_MILLISECONDS) {
    const result = window.localStorage.getItem(key);
    if (typeof result === 'string') {
      const resultData = JSON.parse(result);
      if (Date.now() - resultData.time > timeout) {
        window.localStorage.removeItem(key);
        return null;
      }
      return resultData.data;
    }
    return null;
  }

  static getAuthToken() {
    return this.getItem('authToken', MONTH_IN_MILLISECONDS);
  }
}
