<div class="p-2 d-flex" *ngIf="latestCelebration; else defaultCelebration">
  <div class="d-flex">
    <p class="mt-2 align-self-center">
      {{ latestCelebration }}
      <span class="text-decoration-none"> &mdash;Shannon Buerk</span>
    </p>
  </div>
  <div class="d-flex justify-content-center" style="width: 70px">
    <app-avatar
      class="align-self-center ps-4"
      [avatarData]="user"
      [avatarSize]="'medium'"></app-avatar>
  </div>
</div>

<ng-template #defaultCelebration>
  <div class="d-flex align-items-center my-2">
    <i
      [style.cursor]="'pointer'"
      (click)="triggerCollapse()"
      class="bi bi-caret-{{ isCollapsed ? 'up' : 'right' }}"></i>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="d-flex flex-row p-2 my-2">
        <div class="quote align-top h-100">&ldquo;</div>
        <div class="align-self-center quote me-3">
          If we all take the opportunity to grow today, everyone has hope for a
          better tomorrow.&rdquo;
          <span class="text-decoration-none quote-author text-body-secondary">
            &mdash;Shannon Buerk</span
          >
        </div>
        <app-avatar
          class="align-self-center"
          [avatarData]="user"
          [avatarSize]="'large'"></app-avatar>
      </div>
    </div>
  </div>
</ng-template>
