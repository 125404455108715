import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { CoachingSessionDTO } from './coaching-session.dto';

export interface CoachlogPusherMessageDTO {
  start_datetime?: number;
  end_datetime?: number;
  content?: string;
  title?: string;
  is_private?: number;
  change_user_id: number;
  coachlogId: number;
  timezone: TimezoneLinkBack;
}

export const translatePusherMessageToPartialSessionDTO = (
  pusherMessage: CoachlogPusherMessageDTO
): Partial<CoachingSessionDTO> => {
  const translateTuple: [
    keyof CoachingSessionDTO,
    keyof CoachlogPusherMessageDTO
  ][] = [
    ['startDatetime', 'start_datetime'],
    ['endDatetime', 'end_datetime'],
    ['title', 'title'],
    ['content', 'content'],
    ['isPrivate', 'is_private'],
    ['timezone', 'timezone'],
  ];
  const returnObj: Partial<CoachingSessionDTO> = {};
  translateTuple.forEach(([key, value]) => {
    if (pusherMessage[value] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - this is a partial DTO
      returnObj[key] = pusherMessage[value];
    }
  });
  return returnObj;
};
