<ng-container *ngIf="competencyIds; else noCompetencies">
  <div class="row">
    <div
      *ngFor="let competencyId of competencyIdsPaginated[page - 1]"
      class="col-12 col-lg-6 col-xl-4 mt-3">
      <div
        class="card cursor-pointer"
        (click)="getFourLevelRubric(competencyId)">
        <app-competencies-summary
          [competencyId]="competencyId"
          [userId]="userId"
          [isSelectable]="false"></app-competencies-summary>
      </div>
    </div>
    <app-leveled-competency-modal
      (modalClosed)="loadCompetencies()"
      #competencyModal></app-leveled-competency-modal>
  </div>
  <div *ngIf="competencyIds && competencyIds.length > 12" class="row mt-4">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        id="portfolio-competencies-pagination"
        aria-label="portfolio-competencies-pagination"
        [(page)]="page"
        [pageSize]="12"
        [collectionSize]="competencyIds.length"
        [maxSize]="5"
        [ellipses]="true"></ngb-pagination>
    </div>
  </div>
</ng-container>
<ng-template #noCompetencies>
  <p class="text-center my-5">No Competencies Found</p>
</ng-template>
