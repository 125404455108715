export const states = [
  { title: 'AL - Alabama', value: 'AL' },
  { title: 'AK - Alaska', value: 'AK' },
  { title: 'AZ - Arizona', value: 'AZ' },
  { title: 'AR - Arkansas', value: 'AR' },
  { title: 'CA - California', value: 'CA' },
  { title: 'CO - Colorado', value: 'CO' },
  { title: 'CT - Connecticut', value: 'CT' },
  { title: 'DE - Delaware', value: 'DE' },
  { title: 'DC - District Of Columbia', value: 'DC' },
  { title: 'FL - Florida', value: 'FL' },
  { title: 'GA - Georgia', value: 'GA' },
  { title: 'HI - Hawaii', value: 'HI' },
  { title: 'ID - Idaho', value: 'ID' },
  { title: 'IL - Illinois', value: 'IL' },
  { title: 'IN - Indiana', value: 'IN' },
  { title: 'IA - Iowa', value: 'IA' },
  { title: 'KS - Kansas', value: 'KS' },
  { title: 'KY - Kentucky', value: 'KY' },
  { title: 'LA - Louisiana', value: 'LA' },
  { title: 'ME - Maine', value: 'ME' },
  { title: 'MD - Maryland', value: 'MD' },
  { title: 'MA - Massachusetts', value: 'MA' },
  { title: 'MI - Michigan', value: 'MI' },
  { title: 'MN - Minnesota', value: 'MN' },
  { title: 'MS - Mississippi', value: 'MS' },
  { title: 'MO - Missouri', value: 'MO' },
  { title: 'MT - Montana', value: 'MT' },
  { title: 'NE - Nebraska', value: 'NE' },
  { title: 'NV - Nevada', value: 'NV' },
  { title: 'NH - New Hampshire', value: 'NH' },
  { title: 'NJ - New Jersey', value: 'NJ' },
  { title: 'NM - New Mexico', value: 'NM' },
  { title: 'NY - New York', value: 'NY' },
  { title: 'NC - North Carolina', value: 'NC' },
  { title: 'ND - North Dakota', value: 'ND' },
  { title: 'OH - Ohio', value: 'OH' },
  { title: 'OK - Oklahoma', value: 'OK' },
  { title: 'OR - Oregon', value: 'OR' },
  { title: 'PA - Pennsylvania', value: 'PA' },
  { title: 'RI - Rhode Island', value: 'RI' },
  { title: 'SC - South Carolina', value: 'SC' },
  { title: 'SD - South Dakota', value: 'SD' },
  { title: 'TN - Tennessee', value: 'TN' },
  { title: 'TX - Texas', value: 'TX' },
  { title: 'UT - Utah', value: 'UT' },
  { title: 'VT - Vermont', value: 'VT' },
  { title: 'VA - Virginia', value: 'VA' },
  { title: 'WA - Washington', value: 'WA' },
  { title: 'WV - West Virginia', value: 'WV' },
  { title: 'WI - Wisconsin', value: 'WI' },
  { title: 'WY - Wyoming', value: 'WY' },
];
