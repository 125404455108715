<div class="container">
  <app-general-header title="mySmartCoach Session" />
  <div class="card border-0">
    <div class="card-body p-3 p-lg-5 pt-lg-4">
      <div class="intro-breadcrumbs mb-4">
        <span *ngIf="activeTab < 6">
          Intro Session > Welcome<span *ngIf="activeTab > 0">> Your Why</span>
          <span *ngIf="activeTab > 1">> Growe Process</span>
          <span *ngIf="activeTab > 2">> Competencies</span>
          <span *ngIf="activeTab > 3">> Your Coach</span>
          <span *ngIf="activeTab > 4">> Coaching Plan</span>
        </span>
        <span
          *ngIf="activeTab > 5"
          (click)="selectTab(5)"
          style="cursor: pointer">
          < Back to Intro Session
        </span>
      </div>
      <div
        class="d-flex flex-wrap align-items-center justify-content-between mb-4">
        <div class="action-btns left"></div>
        <div class="flex-grow-1 text-center">
          <h2 class="fs-1 fw-bold my-3">mySmartCoach Intro Session</h2>
        </div>
        <div
          class="action-btns d-flex justify-content-center justify-content-xl-end">
          <button
            *ngIf="activeTab == 0 && overviewComplete"
            id="getstarted"
            [disabled]="!overviewComplete"
            (click)="selectTab(1)"
            class="btn btn-primary btn-next">
            Get Started
            <i class="bi bi-arrow-right"></i>
          </button>
          <button
            *ngIf="activeTab == 1"
            id="whybtn"
            [disabled]="!whyComplete"
            (click)="selectTab(2)"
            class="btn btn-primary btn-next">
            Continue
            <i class="bi bi-arrow-right"></i>
          </button>
          <button
            *ngIf="activeTab == 2"
            [disabled]="!egroweComplete"
            (click)="selectTab(3)"
            class="btn btn-primary btn-next">
            Continue
            <i class="bi bi-arrow-right"></i>
          </button>
          <button
            *ngIf="activeTab == 3"
            [disabled]="!bpComplete"
            (click)="selectTab(4)"
            class="btn btn-primary btn-next">
            Continue
            <i class="bi bi-arrow-right"></i>
          </button>
          <button
            *ngIf="activeTab == 4"
            [disabled]="!coachComplete"
            (click)="selectTab(5)"
            class="btn btn-primary btn-next">
            Continue
            <i class="bi bi-arrow-right"></i>
          </button>
          <button
            *ngIf="activeTab == 5"
            [disabled]="!planComplete"
            (click)="selectTab(6)"
            class="btn btn-primary btn-next">
            Complete
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>

      <div
        [class.visually-hidden]="activeTab < 1 || activeTab > 5"
        class="col-12 intro-nav">
        <div #track class="track">
          <div #backfill class="backfill">
            <div #fill class="fill"></div>
            <div class="stops d-flex justify-content-between">
              <div ngbTooltip="Your Why" [class.complete]="whyComplete"></div>
              <div
                ngbTooltip="Growe Process"
                [class.complete]="egroweComplete"></div>
              <div
                ngbTooltip="Competencies"
                [class.complete]="bpComplete"></div>
              <div
                ngbTooltip="Your Coach"
                [class.complete]="coachComplete"></div>
              <div
                ngbTooltip="Coaching Plan"
                [class.complete]="planComplete"></div>
            </div>
            <div class="avatar" #avatar>
              <app-avatar
                [profile]="user.profile"
                [avatarSize]="'medium'"></app-avatar>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="activeTab == 0">
        <app-intro-overview
          [whyComplete]="whyComplete"
          [egroweComplete]="egroweComplete"
          [bpComplete]="bpComplete"
          [coachComplete]="coachComplete"
          [planComplete]="planComplete" />
      </ng-container>

      <ng-container *ngIf="activeTab == 1">
        <app-intro-your-why />
      </ng-container>

      <ng-container *ngIf="activeTab == 2">
        <app-intro-growe-process />
      </ng-container>

      <ng-container *ngIf="activeTab == 3">
        <app-intro-competencies />
      </ng-container>

      <ng-container *ngIf="activeTab == 4">
        <app-intro-your-coach />
      </ng-container>

      <ng-container *ngIf="activeTab == 5">
        <app-intro-coaching-plan />
      </ng-container>

      <ng-container *ngIf="activeTab == 6">
        <app-intro-session-summary />
      </ng-container>
    </div>
  </div>
  <app-smart-coach-dialog
    *ngIf="user && user.smartCoach"
    [coachProfile]="user.smartCoach.profile" />
</div>
