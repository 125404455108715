import {
  EvidenceSummaryAttachmentResponse,
  PusherEvidenceAssessmentResponse,
} from 'src/app/common/types/responses/responses';
import {
  EvidenceAssessmentDTO,
  EvidenceAttachmentDTO,
  EvidenceDTO,
  EvidenceExtendedDTO,
  EvidenceExtendedWithCompetencyDTO,
  EvidenceType,
} from '../../dtos/attendee-rubric.dto';
import { FileDTO } from '../../dtos/file.dto';
import { FileIcon } from '../../enums/file-icons.enum';
import {
  CreateEvidencePayload,
  EvidencePayload,
} from '../../types/payloads/evidence.payload';
import {
  EvidenceAPIResponse,
  EvidenceAssessmentAPIResponse,
  EvidenceAttachmentAPIResponse,
  EvidenceWithStandardAPIResponse,
} from '../../types/responses/coaching-log.responses';
import { videoDTOFromAPIResponse } from './video.translators';

export const evidencePayloadFromDTO = (
  evidence: EvidenceDTO,
  attachments?: FileDTO[]
): EvidencePayload => ({
  title: evidence.title,
  narrative: evidence.narrative,
  type: evidence.type.toString(),
  added_from: evidence.addedFrom,
  content:
    evidence.type === 'video' && attachments && attachments[0]?.videoData
      ? attachments[0]?.videoData.esuiteLink
      : '',
  user_id: evidence.userId,
  egrowe_coachlog_id: evidence.coachlogId,
  evidence_location_list: [
    {
      egrowe_rubric_id: evidence.rubricId || 0,
      egrowe_standard_id: evidence.competencyId || 0,
      egrowe_indicator_group_id: evidence.strandId || 0,
      level: evidence.level,
      egrowe_coachlog_option_id: evidence.coachlogOptionId || 0,
    },
  ],
});

export const createEvidencePayloadFromDTO = (
  evidence: EvidenceDTO,
  attachments?: FileDTO[]
): CreateEvidencePayload => ({
  ...evidencePayloadFromDTO(evidence, attachments),
  attachment_file_ids: attachments
    ? attachments.map((attachment) => attachment.id)
    : evidence.evidenceAttachments.map((attachment) => attachment.id),
});

export const evidenceAssessmentDTOFromAPIResponse = (
  response: EvidenceAssessmentAPIResponse
): EvidenceAssessmentDTO => ({
  id: response.id,
  evidenceId: response.egrowe_evidence_id,
  assessorUserId: response.assessor_user_id,
  title: response.title,
  content: response.content,
  approved: response.approved,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  assessor: response.assessor,
});

export const evidenceAttachmentDTOFromAPIResponse = (
  response: EvidenceAttachmentAPIResponse
): EvidenceAttachmentDTO => ({
  id: response.id,
  type: response.type,
  relationalId: response.relational_id,
  title: response.title,
  description: response.description,
  filePath: response.file_path,
  fileType: response.file_type,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  userId: response.user_id,
});

export const evidenceDTOFromAPIResponse = (
  response: EvidenceAPIResponse
): EvidenceExtendedDTO => ({
  id: response.id,
  title: response.title,
  content: response.content,
  type: response.type as EvidenceType,
  userId: response.user_id,
  level: response.level,
  districtId: response.district_id,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  rubricId: response.egrowe_rubric_id,
  competencyId: response.egrowe_standard_id,
  isDeleted: response.is_deleted === 1,
  narrative: response.narrative,
  strandId: response.egrowe_indicator_group_id,
  coachlogId: response.egrowe_coachlog_id,
  nearestCoachlogId: response.nearest_coachlog_id,
  coachlogOptionId: response.egrowe_coachlog_option_id,
  addedFrom: response.added_from,
  videos:
    response.videos && response.videos?.length > 0
      ? response.videos.map((video) => videoDTOFromAPIResponse(video))
      : [],
  evidenceAttachments: response.evidenceAttachments.map((evidenceAttachment) =>
    evidenceAttachmentDTOFromAPIResponse(evidenceAttachment)
  ),
  assessments: response.assessments.map((assessment) =>
    evidenceAssessmentDTOFromAPIResponse(assessment)
  ),
});

export const evidenceWithCompetencyDTOFromAPIResponse = (
  response: EvidenceWithStandardAPIResponse
): EvidenceExtendedWithCompetencyDTO => ({
  id: response.id,
  title: response.title,
  content: response.content,
  type: response.type as EvidenceType,
  userId: response.user_id,
  level: response.level,
  districtId: response.district_id,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
  rubricId: response.egrowe_rubric_id,
  competencyId: response.egrowe_standard_id,
  isDeleted: response.is_deleted === 1,
  narrative: response.narrative,
  strandId: response.egrowe_indicator_group_id,
  coachlogId: response.egrowe_coachlog_id,
  nearestCoachlogId: response.nearest_coachlog_id,
  coachlogOptionId: response.egrowe_coachlog_option_id,
  addedFrom: response.added_from,
  videos:
    response.videos && response.videos?.length > 0
      ? response.videos.map((video) => videoDTOFromAPIResponse(video))
      : [],
  evidenceAttachments: response.evidenceAttachments.map((evidenceAttachment) =>
    evidenceAttachmentDTOFromAPIResponse(evidenceAttachment)
  ),
  assessments: response.assessments.map((assessment) =>
    evidenceAssessmentDTOFromAPIResponse(assessment)
  ),
  standard: response.standard,
});

export const fileDTOFromEvidenceAttachment = (
  attachment: EvidenceAttachmentDTO
): FileDTO => ({
  id: attachment.id,
  name: attachment.title,
  iconClass: FileIcon[attachment.fileType as keyof typeof FileIcon],
  location: attachment.filePath,
});

export const fileDTOFromEvidenceSummaryAttachmentResponse = (
  attachment: EvidenceSummaryAttachmentResponse
): FileDTO => ({
  id: attachment.id,
  name: attachment.title,
  iconClass: FileIcon[attachment.file_type as keyof typeof FileIcon],
  location: attachment.file_path,
});

export const evidenceAssessmentDTOFromPusherEvidenceAssessmentResponse = (
  response: PusherEvidenceAssessmentResponse
): EvidenceAssessmentDTO => ({
  id: response.id,
  evidenceId: response.egrowe_evidence_id,
  assessorUserId: response.assessorUserId,
  title: response.title,
  content: response.content,
  approved: Boolean(response.approved),
  createdAt: response.createdAt,
  updatedAt: response.updatedAt,
  assessor: response.assessor,
});
