import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { UserState } from 'src/app/common/state/user/user.state';

import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { PaginationDTO } from '../../../../../common/types/responses/responses-template';
import { CoachingSessionDTO } from '../../../dtos/coaching-session.dto';
import { getSessionLink } from '../../../helpers/coachee-log.utilities';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';

@Component({
  selector: 'app-card-upcoming-sessions',
  templateUrl: './card-upcoming-sessions.component.html',
  styleUrls: ['./card-upcoming-sessions.component.scss'],
})
export class CardUpcomingSessionsComponent implements OnInit, OnDestroy {
  @Input() smartCoachIntro = false;

  user: User;

  isCollapsed = this.cardStorageService.getCollapsedState('upcomingsessions');

  isLoading = true;

  sessions: CoachingSessionDTO[] = [];

  getSessionLink = getSessionLink;

  subs: Subscription[] = [];

  constructor(
    private coachingLogService: CoachingLogService,
    private cardStorageService: CardStorageService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.getSessions();

    this.subs.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.getSessions();
      })
    );
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'upcomingsessions',
      this.isCollapsed
    );
  }

  getSessions(): void {
    if (!this.smartCoachIntro) {
      this.coachingLogService
        .getUpcomingSessions()
        .subscribe((res) => this.handleSessionResponse(res));
    } else {
      this.coachingLogService
        .getUpcomingSessions(100, 1)
        .subscribe((res) => this.handleSessionResponse(res));
    }
  }

  handleSessionResponse(response: [CoachingSessionDTO[], PaginationDTO]) {
    if (response) {
      this.sessions = response[0];
    }
    this.isLoading = false;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
