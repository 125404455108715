<label for="password" class="form-label">Password</label>
<div class="input-group">
  <input
    [formControl]="formControl"
    [type]="showPassword ? 'text' : 'password'"
    class="form-control"
    id="password"
    aria-label="Password"
    [ngClass]="{ 'is-invalid': formSubmitted && formControl.errors }" />
  <span class="input-group-text">
    <i
      class="cursor-pointer bi"
      [ngClass]="{ 'bi-eye': showPassword, 'bi-eye-slash': !showPassword }"
      (click)="showPassword = !showPassword"></i>
  </span>
</div>

<label class="form-label"> Password Requirements: </label>
<div>
  <ng-container
    *ngIf="
      !this.formControl.hasError('minlength') &&
        !this.formControl.hasError('required');
      then completed;
      else notCompleted
    ">
  </ng-container>
  At least 8 characters long
</div>
<div>
  <ng-container
    *ngIf="
      !this.formControl.hasError('requireUppercaseLetter');
      then completed;
      else notCompleted
    ">
  </ng-container>
  At least 1 uppercase letter
</div>
<div>
  <ng-container
    *ngIf="
      !this.formControl.hasError('requireLowercaseLetter');
      then completed;
      else notCompleted
    ">
  </ng-container>
  At least 1 lowercase letter
</div>
<div class="mb-2">
  <ng-container
    *ngIf="
      !this.formControl.hasError('requireNumber');
      then completed;
      else notCompleted
    ">
  </ng-container>
  At least 1 number
</div>

<ng-template #completed>
  <i class="bi bi-check2-circle active"></i>
</ng-template>
<ng-template #notCompleted>
  <i class="bi bi-circle-fill color-neutral"></i>
</ng-template>
