import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  requireLowercaseLetterValidator,
  requireNumberValidator,
  requireUppercaseLetterValidator,
} from './password-validators/password-validators.directive';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NewPasswordComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: NewPasswordComponent,
      multi: true,
    },
  ],
})
export class NewPasswordComponent implements ControlValueAccessor, Validator {
  public formControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    requireUppercaseLetterValidator(),
    requireLowercaseLetterValidator(),
    requireNumberValidator(),
  ]);

  @Input() formSubmitted = false;

  showPassword = false;

  writeValue(password: string): void {
    this.formControl.setValue(password);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any): void {
    this.formControl.valueChanges.subscribe((val) => onChange(val));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any): void {
    this.formControl.valueChanges.subscribe((val) => onTouched(val));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  validate(): ValidationErrors | null {
    return this.formControl.errors;
  }
}
