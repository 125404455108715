<div #container class="container competency-card shadow mb-3">
  <ng-container *ngIf="evidenceList.length > 0; else firstEvidence">
    <div
      (click)="toggleCollapse()"
      class="fs-14 fw-800 py-2 d-flex align-items-center justify-content-between cursor-pointer">
      <div class="d-flex align-items-center">
        <i class="bi bi-file-earmark-arrow-up me-2 fs-24 color-tertiary"></i>
        Evidence
      </div>
      <i
        class="fs-19 color-tertiary bi bi-chevron-{{
          isDrawerCollapsed ? 'down' : 'up'
        }}"></i>
    </div>
  </ng-container>
  <ng-template #firstEvidence>
    <h3
      class="fs-14 fw-800 mt-2 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="bi bi-file-earmark-arrow-up me-2 fs-24 color-tertiary"></i>
        Evidence
      </div>
      <button
        role="button"
        ngbTooltip="New evidence"
        container="body"
        class="evidence-btn btn-reset px-0"
        aria-label="new-evidence-button"
        (click)="addEvidence()">
        <i class="bi bi-plus-lg color-tertiary fs-19"></i>
      </button>
    </h3>
  </ng-template>

  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isDrawerCollapsed"
    class="mt-3"
    *ngIf="evidenceList">
    <ul class="list-unstyled">
      <li
        class="d-flex align-items-center justify-content-between"
        *ngFor="let evidence of evidenceList">
        <div class="me-2">
          <button
            *ngIf="!viewOnly && copyEvidenceEnabled"
            class="bg-transparent border-0 me-1 ps-0 fs-14"
            ngbTooltip="Copy evidence"
            container="body"
            (click)="openCopyEvidenceModal(evidence.id)">
            <i class="bi bi-copy color-tertiary"></i>
          </button>
          <button
            *ngIf="!viewOnly"
            class="bg-transparent border-0 me-1 ps-0 fs-14"
            ngbTooltip="Edit evidence"
            container="body"
            (click)="editEvidence(evidence.id)">
            <i class="bi bi-pencil color-tertiary"></i>
          </button>
          <app-evidence-summary
            ngbTooltip="Review evidence"
            container="body"
            [evidenceId]="evidence.id"
            [userId]="userId"
            (evidenceAssesed)="evidenceAssessed($event)"
            (evidenceRemoved)="removedEvidence($event)"
            ><span class="fs-14">{{
              evidence.title
            }}</span></app-evidence-summary
          >
        </div>
        <div>
          <i
            *ngIf="evidence.assessments.length > 0"
            ngbTooltip="Reviewed"
            container="body"
            class="bi fs-18 bi-check2-circle color-active ms-1"></i>
          <i
            *ngIf="evidence.assessments.length == 0"
            class="bi fs-15 bi-circle color-active ms-1"></i>
        </div>
      </li>
    </ul>
    <div
      *ngIf="evidenceList.length > 0"
      class="d-flex fs-14 fw-800 align-items-center color-primary">
      <div
        (click)="addEvidence()"
        class="d-flex align-items-center cursor-pointer add-more-evidence">
        <i class="bi bi-plus color-tertiary fs-22 me-1"></i>
        Add More Evidence
      </div>
    </div>
  </div>
</div>

<app-modal
  #evidenceModal
  [modalConfig]="modalConfig"
  (isDismissing)="numberOfFilesUploaded = 0">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModalOnly()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <p *ngIf="level && levelData" class="text-center">
    <app-safehtml
      *ngIf="levelData.indicators[0]"
      [html]="
        '<strong>' +
        strand.title +
        ' - Level ' +
        level +
        ':</strong><br>' +
        levelData.indicators[0].content
      "></app-safehtml>
  </p>

  <label class="form-label">Option:</label>
  <ng-select
    #optionSelect
    [disabled]="true"
    class="border-tertiary"
    [clearable]="false"
    [markFirst]="true"
    [searchable]="false"
    [items]="optionList"
    bindLabel="content"
    bindValue="id"
    [(ngModel)]="selectedOptionId"
    placeholder="Is this Evidence aligned to an Option?"></ng-select>

  <label class="form-label">Title:</label>
  <input
    class="form-control rounded"
    type="text"
    placeholder="Enter a title for this evidence"
    [(ngModel)]="currentEvidence.title"
    (keyup)="checkEvidenceRequiredFields()" />
  <label class="form-label">Context:</label>
  <app-wysiwyg-editor
    version="mini"
    #contentEditor
    [config]="{ placeholderText: 'Enter a description for this evidence' }"
    [editorContent]="initialNarrativeContent"
    (editorContentEvent)="updateNarrative($event)" />
  <label class="form-label">Evidence:</label>
  <ng-select
    placeholder="Select evidence type"
    [disabled]="numberOfFilesUploaded > 0"
    class="mb-2"
    [items]="evidenceModalTypes"
    (change)="changeEvidenceType()"
    [clearable]="false"
    bindLabel="label"
    bindValue="value"
    [(ngModel)]="uploadType"></ng-select>
  <div
    *ngIf="
      uploadType === uploadTypes.VIDEO &&
      currentEvidence.videos &&
      currentEvidence.videos[0]
    ">
    <app-video-player [video]="currentEvidence.videos[0]"></app-video-player>
    <a
      href="javascript:void(0)"
      [ngClass]="[disableVideoRemoval ? 'visually-hidden' : '']"
      (click)="removeVideo()"
      >Remove video</a
    >
  </div>
  <app-file-list
    #fileListComponent
    *ngIf="uploadType && currentEvidence.videos?.length !== 1"
    (fileCount)="numberOfFilesUploaded = $event - 1; fileUploaded()"
    [fileList]="uploadedFiles"
    (deletedFile)="fileDeleted($event)"></app-file-list>
  <app-file-upload
    *ngIf="
      (currentEvidence.videos?.length !== 1 &&
        uploadType &&
        uploadType === uploadTypes.VIDEO &&
        numberOfFilesUploaded < 1) ||
      (uploadType && uploadType === uploadTypes.EVIDENCE)
    "
    #uploadComponent
    [style]="uploadStyle"
    [multiple]="uploadType != uploadTypes.VIDEO"
    (outputFiles)="
      uploadedFiles.push($event); fileListComponent.fileListUpdate()
    "
    [uploadType]="uploadType"></app-file-upload>
  <div footerContent>
    <div class="d-flex justify-content-between">
      <button type="button" class="btn" (click)="closeModalOnly()">
        Cancel
      </button>
      <button
        [disabled]="!hasTitle || !hasNarrative"
        (click)="evidenceModal.close(); numberOfFilesUploaded = 0"
        class="btn btn-md text-white fw-800 bg-level-{{ this.level }}">
        Save
      </button>
    </div>
  </div>
</app-modal>

<ng-container *ngIf="copyEvidenceEnabled && userId">
  <app-copy-evidence-modal
    #copyEvidenceModal
    [userId]="userId"></app-copy-evidence-modal>
</ng-container>
