<div class="top-divider mt-5"></div>

<div class="row no-gutters pb-5">
  <div class="col-md-4 col-lg-3 side-divider">
    <div class="row no-gutters pt-4">
      <h3 class="fw-800">Smart Coach</h3>
    </div>
    <div *ngIf="selectedCoach" class="row no-gutters">
      <div class="d-flex align-items-center">
        <app-avatar [profile]="selectedCoach.profile" [avatarSize]="'medium'" />
        <div class="username ms-2">
          {{ selectedCoach.profile.first_name }}
          {{ selectedCoach.profile.last_name }}
        </div>
      </div>
    </div>

    <div class="row no-gutters pt-4">
      <h3 class="fw-800">Coachee</h3>
    </div>
    <div *ngIf="user" class="row no-gutters">
      <div class="d-flex align-items-center">
        <app-avatar [profile]="user.profile" [avatarSize]="'medium'" />
        <div class="username ms-2">
          {{ user.profile.first_name }}
          {{ user.profile.last_name }}
        </div>
      </div>
    </div>

    <div class="row no-gutters pt-4">
      <h3 class="fw-800">Intro Session</h3>
    </div>
    <div class="row no-gutters">
      <p class="color-active mb-2">
        <i class="bi fs-18 bi-check2-circle me-1"></i>
        Define Your Why
      </p>
    </div>
    <div class="row no-gutters">
      <p class="color-active mb-2">
        <i class="bi fs-18 bi-check2-circle me-1"></i>
        Learn About the Growe Process
      </p>
    </div>
    <div class="row no-gutters">
      <p class="color-active mb-2">
        <i class="bi fs-18 bi-check2-circle me-1"></i>
        Explore Competencies
      </p>
    </div>
    <div class="row no-gutters">
      <p class="color-active mb-2">
        <i class="bi fs-18 bi-check2-circle me-1"></i>
        Choose Your Coach
      </p>
    </div>
    <div class="row no-gutters">
      <p class="color-active mb-2">
        <i class="bi fs-18 bi-check2-circle me-1"></i>
        Create a Coaching Plan
      </p>
    </div>
  </div>

  <div class="col-md-8 col-lg-6 ps-4 pb-3">
    <div class="row no-gutters pt-4">
      <h3 class="fw-800">Session Summary</h3>
    </div>

    <div class="row no-gutters pt-2">
      <h6 class="fw-800">Your Why</h6>
    </div>
    <div class="row no-gutters pt-1">
      <ul class="summary-data">
        <li>{{ userWhy }}</li>
      </ul>
    </div>

    <div class="row no-gutters pt-3">
      <h6 class="fw-800">Goals for Learners or Team</h6>
    </div>
    <div class="row no-gutters pt-1">
      <ul class="summary-data">
        <li>{{ goalsForLearners }}</li>
      </ul>
    </div>

    <div class="row no-gutters pt-3">
      <h6 class="fw-800">Goals for Coaching</h6>
    </div>
    <div class="row no-gutters pt-1">
      <ul class="summary-data">
        <li>{{ goalsForCoaching }}</li>
      </ul>
    </div>

    <div class="row no-gutters pt-3">
      <h6 class="fw-800">Strength</h6>
    </div>
    <div class="row no-gutters pt-1">
      <ul class="summary-data">
        <li>{{ userStrength }}</li>
      </ul>
    </div>

    <div class="row no-gutters pt-3">
      <h6 class="fw-800">Area of Growth</h6>
    </div>
    <div class="row no-gutters pt-1">
      <ul class="summary-data">
        <li>{{ areaOfGrowth }}</li>
      </ul>
    </div>
  </div>

  <div class="col-lg-3">
    <div class="row no-gutters pt-4">
      <h3 class="fw-800">Scheduled Sessions</h3>
    </div>
    <div class="row no-gutters pt-2 pb-4">
      <app-card-upcoming-sessions [smartCoachIntro]="true" />
    </div>
    <div class="row no-gutters pt-2">
      <button
        routerLink="/calendar"
        class="btn btn-primary"
        style="max-width: 300px; margin: 0 auto">
        Schedule More Sessions
        <i class="bi bi-arrow-right"></i>
      </button>
    </div>
  </div>
</div>
