import { Component, Input } from '@angular/core';
import { CompetencyDTO } from '../../../dtos/attendee-rubric.dto';

@Component({
  selector: 'app-portfolio-leveled-competency',
  templateUrl: './portfolio-leveled-competency.component.html',
  styleUrls: [
    '../../competency/leveled-competency/leveled-competency.component.scss',
  ],
})
export class PortfolioLeveledCompetencyComponent {
  @Input() competency: CompetencyDTO;

  @Input() rubricId: number;

  @Input() userId: number;
}
