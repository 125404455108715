import { Router } from '@angular/router';
import {
  compareRoles,
  UserRole,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';

import { GoalDTO, StrandDTO } from '../dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from '../dtos/coaching-session.dto';

export const updateStrandWithNewGoal = (
  strand: StrandDTO,
  goal: GoalDTO
): StrandDTO => {
  strand.goalLevel = goal.goalLevel;
  strand.level1.goal = null;
  strand.level2.goal = null;
  strand.level3.goal = null;
  strand.level4.goal = null;
  /* eslint-disable-next-line default-case */
  switch (goal.goalLevel) {
    case 1:
      strand.level1.goal = goal;
      break;
    case 2:
      strand.level2.goal = goal;
      break;
    case 3:
      strand.level3.goal = goal;
      break;
    case 4:
      strand.level4.goal = goal;
      break;
  }
  return strand;
};

export const getSessionLink = (session: CoachingSessionDTO): string | null => {
  if (session.type.isSmart) {
    return `/smart-coach/log/${session.id}/info`;
  }
  switch (session.type.view) {
    case 'egrowe-coachlog-v2':
      return `/coaching/log/${session.id}/info`;
    case 'universal-log':
      return `/coaching/log/${session.id}/info`;
    case 'observation':
      return `/observation/log/${session.id}/info`;
    case 'general-log':
      return `/general/log/${session.id}/info`;
    default:
      return null;
  }
};

export enum SessionPermissions {
  EDIT = 'edit',
  EVIDENCE = 'approve_evidence',
  VIEW = 'view',
  NONE = 'none',
}

export const checkSmartCoachAuthorization = (
  sessionData: CoachingSessionDTO,
  user: User
): SessionPermissions => {
  if (sessionData.attendees[0].user.id === user.id) {
    return SessionPermissions.EDIT;
  }
  if (sessionData.attendees[0].user.coach?.id === user.id) {
    return SessionPermissions.EVIDENCE;
  }
  if (!sessionData.isPrivate) {
    if (
      sessionData.attendees[0].user.district?.id === user.district?.id &&
      compareRoles(user.roles, [UserRole.DISTRICT_ADMIN, UserRole.OPERATOR])
    ) {
      return SessionPermissions.VIEW;
    }
    let schoolIds = [];
    if (user.school?.id) {
      schoolIds.push(user.school?.id);
    }
    if (user.secondarySchools) {
      schoolIds = [
        ...schoolIds,
        ...user.secondarySchools.map((school) => school.id),
      ];
    }
    if (
      schoolIds.includes(sessionData.attendees[0].user.school?.id as number) &&
      compareRoles(user.roles, [UserRole.SCHOOL_ADMIN])
    ) {
      return SessionPermissions.VIEW;
    }
    if (compareRoles(user.roles, [UserRole.E2L_EMPLOYEE])) {
      return SessionPermissions.VIEW;
    }
  }
  return SessionPermissions.NONE;
};

export const checkSessionDataType = (
  sessionData: CoachingSessionDTO,
  type: string,
  router: Router
): void => {
  /* eslint-disable-next-line default-case */
  switch (sessionData.type.title) {
    case 'Coaching Conversation':
      if (type !== 'coaching') {
        router.navigate([`/coaching/log/${sessionData.id}`]);
      }
      break;
    case 'Observation and Feedback':
      if (type !== 'observation') {
        router.navigate([`/observation/log/${sessionData.id}`]);
      }
      break;
  }
};
