<ng-container *ngIf="competency">
  <div class="row justify-content-center mt-3">
    <div class="col-lg-8">
      <div class="text-center">
        <app-safehtml [html]="competency.content" />
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <ng-container *ngFor="let strand of competency.indicatorSet.strands">
    <app-portfolio-leveled-competency-strand
      [strand]="strand"
      [rubricId]="rubricId"
      [userId]="userId"
      [rubricId]="rubricId"
      [competency]="competency"
      [competencyTitle]="competency.title"
      [level1video]="competency.level1video"
      [level2video]="competency.level2video"
      [level3video]="competency.level3video"
      [level4video]="
        competency.level4video
      "></app-portfolio-leveled-competency-strand>
  </ng-container>
</ng-container>
