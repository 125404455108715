import { Channel } from 'pusher-js';
import { PusherService } from './pusher.service';

interface PusherChannelDictionary {
  [channelId: string]: Channel;
}

export abstract class PusherGuide {
  channels: PusherChannelDictionary = {};

  addChannel(channelId: string): Channel {
    if (this.channels[channelId] === undefined) {
      this.channels[channelId] =
        this.type === 'presence'
          ? this.pusherService.connectToPresenceChannel(channelId)
          : this.pusherService.connectToChannel(channelId);
      this.addDefaultBindings(channelId);
    }
    return this.channels[channelId];
  }

  removeChannel(id: string) {
    if (this.channels[id]) {
      this.channels[id].unbind_all();
      this.channels[id].unsubscribe();
      delete this.channels[id];
    }
  }

  getChannel(id: string): Channel {
    if (!this.channels[id]) {
      this.addChannel(id);
    }
    return this.channels[id];
  }

  constructor(
    private pusherService: PusherService,
    private type: 'presence' | 'standard' = 'standard'
  ) {}

  /* eslint-disable-next-line */
  protected addDefaultBindings(channelId: string): void {}
}
