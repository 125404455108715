import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  UserImportAppliedChangesAPIResponse,
  UserImportJobProcessAPIResponse,
  UserImportJobRecordAPIResponse,
} from '../../types/responses/roster-upload.responses';

@Injectable({
  providedIn: 'root',
})
export class RosterUploadService {
  constructor(private apiService: APICoreService) {}

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  uploadCsvFile(file: File, districtId: number, type: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('district_id', districtId.toString());
    formData.append('type', type);
    return this.apiService.postFormRequest('rosters/upload', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadGroweLabUserCsvFile(
    file: File,
    districtId: number
  ): Observable<{ item: UserImportJobRecordAPIResponse }> {
    const formData = new FormData();
    formData.append('district_id', districtId.toString());
    formData.append('file', file);
    return this.apiService
      .postFormRequest('growelab/user-import/upload', formData)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  processUserImportJob(payload: {
    user_import_job_id: number;
  }): Observable<UserImportJobProcessAPIResponse> {
    return this.apiService
      .postRequest('growelab/user-import/process', payload)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }

  applyChanges(payload: {
    user_import_job_id: number;
  }): Observable<UserImportAppliedChangesAPIResponse> {
    return this.apiService
      .postRequest('growelab/user-import/apply', payload)
      .pipe(
        catchError((err) => {
          throw err;
        })
      );
  }
}
