import { Component, Input } from '@angular/core';

import { FormFieldChoicesAbstractComponent } from '../base/edit-field-choices-abstract';

@Component({
  selector: 'app-form-edit-multiple-choice',
  templateUrl: './edit-multiple-choice.component.html',
  styleUrls: ['../edit-field/form-edit-field.component.scss'],
})
export class FormEditMultipleChoiceComponent extends FormFieldChoicesAbstractComponent {
  @Input() canChange = true;
}
