<div class="container">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex align-items-center">
        <!--div class="coachee-search table-search">
          <input
            *ngIf="forms.length > 0"
            type="search"
            class="form-control ms-1"
            style="width: 260px"
            placeholder="Filter items"
            aria-label="Search"
            (input)="searchForms($event)" />
        </div-->
        <div class="form-check form-switch mx-3">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="!currentMode"
            (click)="toggleFormView()"
            id="currentViewToggle" />
          <label class="text-body-secondary" for="currentViewToggle"
            >View archived forms</label
          >
        </div>
      </div>
      <div class="text-end">
        <button
          (click)="
            router.navigate([
              'settings',
              'districts',
              districtCode,
              'forms',
              'create'
            ])
          "
          class="btn btn-primary">
          <i class="bi bi-plus"></i> Create New Form
        </button>
      </div>
    </div>
    <ng-template #sortNeutral>
      <img
        src="/assets/no-sort.svg"
        alt="Sort"
        style="opacity: 0.5; width: 15px" />
    </ng-template>
    <ng-container *ngIf="!formsService.isLoading; else loadingTemplate">
      <table *ngIf="forms.length > 0; else noResults" class="table my-4">
        <!--TABLE HEADER-->
        <thead>
          <tr class="align-middle">
            <th></th>
            <th
              class="sortable-header"
              scope="col"
              (click)="changeSort('title')">
              Title
              <div
                class="d-inline"
                *ngIf="this.sortField === 'title'; else sortNeutral">
                <i
                  [ngClass]="[
                    'bi',
                    'bi-caret-' + this.sortDirection + '-fill'
                  ]"></i>
              </div>
            </th>
            <th class="hide-on-mobile no-wrap" scope="col">Type</th>
            <th class="hide-on-mobile no-wrap" scope="col">Status</th>
            <th
              class="sortable-header no-wrap"
              scope="col"
              (click)="changeSort('submissionCount')">
              Form activity
              <div
                class="d-inline"
                *ngIf="this.sortField === 'submissionCount'; else sortNeutral">
                <i
                  [ngClass]="[
                    'bi',
                    'bi-caret-' + this.sortDirection + '-fill'
                  ]"></i>
              </div>
            </th>
          </tr>
        </thead>
        <!--TABLE BODY-->
        <tbody>
          <tr
            *ngFor="let form of forms"
            style="border-bottom: 1px solid #78858e5a"
            class="align-middle">
            <td class="no-wrap" style="width: 120px">
              <button
                ngbTooltip="Preview"
                (click)="openPreview(form.id)"
                class="btn btn-link color-tertiary p-1">
                <i class="bi bi-eye"></i>
              </button>
              <button
                ngbTooltip="Copy"
                *ngIf="currentMode"
                (click)="duplicateForm(form.id)"
                class="btn btn-link color-tertiary p-1">
                <i class="bi bi-copy"></i>
              </button>
              <ng-container *ngIf="userCanEdit(form) && currentMode">
                <button
                  ngbTooltip="Edit"
                  (click)="
                    this.router.navigate([
                      'settings',
                      'districts',
                      districtCode,
                      'forms',
                      form.id
                    ])
                  "
                  class="btn btn-link color-tertiary p-1">
                  <i id="edit-{{ form.title }}" class="bi bi-pencil"></i>
                </button>
              </ng-container>
              <ng-container *ngIf="district.id !== 2 && form.districtId === 2">
              </ng-container>
            </td>
            <td>
              <div>
                <span class="fw-bold d-inline-block">{{ form.title }}</span>
                <div
                  class="d-inline-block ms-2 p-1 ps-2 fs-12 bg-secondary rounded cursor-pointer"
                  *ngIf="district.id !== form.districtId"
                  [ngbPopover]="formHideConfirm"
                  [popoverTitle]="'Hide Form'"
                  [autoClose]="'outside'"
                  #popover="ngbPopover">
                  <ng-template #formHideConfirm>
                    Hide this shared form from your district?
                    <div class="text-end mt-2">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm px-3 m-1"
                        (click)="popover.close()">
                        No
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm px-3 m-1"
                        (click)="hideForm(form.id); popover.close()">
                        Yes
                      </button>
                    </div>
                  </ng-template>
                  {{ form.district?.title }}
                  <i class="bi bi-eye-slash p-1"></i>
                </div>
              </div>
              <div class="small">{{ form.description }}</div>
            </td>
            <td class="hide-on-mobile">
              {{ form.type.replace("-", " ") }}
            </td>
            <td class="hide-on-mobile">
              {{ form.status.toLowerCase() }}
            </td>
            <td class="text-middle text-center small w-auto">
              <ng-container
                *ngIf="
                  form.submissionCount && form.submissionCount > 0;
                  then hasSubmissionBlock;
                  else noSubmissionBlock
                ">
              </ng-container>
              <ng-template #hasSubmissionBlock>
                <button
                  ngbTooltip="View submissions"
                  (click)="showSubmissionModal(form.id)"
                  *ngIf="form.submissionCount && form.submissionCount > 0"
                  class="btn btn-link btn-sm color-tertiary p-0">
                  {{ form.submissionCount }} submissions</button
                ><br />
                <small *ngIf="form.lastSubmission">
                  (last:
                  {{ form.lastSubmission | formatDate : "MM/dd/yyyy" }})
                </small>
              </ng-template>
              <ng-template #noSubmissionBlock> 0 submissions </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <section>
        <div class="d-flex justify-content-center">
          <ngb-pagination
            *ngIf="pagination && pagination.totalCount > 10"
            [(page)]="pagination.currentPage"
            [pageSize]="pagination.perPage"
            [collectionSize]="pagination.totalCount"
            [maxSize]="5"
            (pageChange)="pageChange($event)"></ngb-pagination>
        </div>
      </section>
      <ng-template #noResults>
        <div class="p-5 align-self-center text-body-secondary">
          <p *ngIf="district && district.id === 2">No templates created yet</p>
          <p *ngIf="!district || district.id != 2">
            There are no matching forms associated with your district.
          </p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
      <div style="clear: both; height: 300px"></div>
      <app-loader [top]="200" />
    </ng-template>
  </div>
</div>
<app-form-submission-modal #submissionModal></app-form-submission-modal>
<app-form-modal #previewModal></app-form-modal>
