import { UserDTO, UserLiteDTO } from 'src/app/common/state/user/user.dto';

import { UserAPIResponse } from '../../types/responses/user.responses';

export const userDTOFromAPIResponse = (item: UserAPIResponse): UserDTO => ({
  id: item.id,
  username: item.username,
  status: item.status,
  district: item.district,
  email: item.email,
  is_deleted: item.is_deleted,
  years_coached: item.years_coached,
  smartCoach: item.smartCoach,
  roles: item.roles || [],
  is_smart: item.is_smart,
  is_smart_default: item.is_smart_default,
  profile: item.profile,
  coachee_type: item.coachee_type,
  coach: item.coach,
  school: item.school,
  lastLoginMs: item.last_login * 1000,
  activityStreamToken: item.getstream_session_token,
  grades: item.grades,
  content_areas: item.contentAreas,
  userSchools: item.userSchools,
  exclude_from_reports: item.exclude_from_reports,
  intercom_hash: item.intercom_hash,
});

export const userDTOLiteFromUserDTO = (user: UserDTO): UserLiteDTO => ({
  id: user.id,
  status: user.status,
  email: user.email,
  profile: user.profile,
});
