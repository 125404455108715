<div class="container">
  <!-- <app-resources-header [createResourceBtn]="true" /> -->
  <app-general-header title="Resources" />
  <div class="card border-0 p-3">
    <app-resources-subnav />
    <div class="card-body">
      <div class="page-body d-flex justify-content-between align-items-center">
        <div class="input-group mb-3 mt-3 center w-25">
          <input
            #resourceSearch
            placeholder="Keyword"
            aria-label="search"
            class="form-control"
            type="text"
            value="{{ currentFilters.search }}"
            [(ngModel)]="inputValue"
            (keydown)="onSearchFieldKeydown($event, resourceSearch.value)" />
          <button class="btn btn-secondary" (click)="getResources()">
            <span class="search-btn-text">Search</span>
          </button>
        </div>
        <button
          *ngIf="canCreate"
          type="button"
          (click)="resourceCreateParentModal.openModal()"
          class="btn btn-primary align-self-center ms-auto">
          <i class="bi bi-plus me-1"></i>Create a Resource
        </button>
        <app-resource-create-modal
          #resourceCreateParentModal></app-resource-create-modal>
      </div>
      <ng-container *ngIf="resourceList && searchMetaData">
        <div class="w-100 text-end mb-2 fw-200 fs-13">
          <span>
            {{ searchMetaData.totalCount | number }}
            <span
              *ngIf="
                (!currentFilters?.search || currentFilters?.search === '') &&
                searchMetaData.totalCount === 100
              "
              style="margin-left: -4px"
              >+</span
            >
          </span>
          <span> Result</span
          ><span *ngIf="searchMetaData.totalCount !== 1">s</span>
          found
          <span *ngIf="currentFilters.search && currentFilters.search != ''"
            >for "{{ currentFilters.search }}"</span
          >
        </div>
        <app-resource-filter-container
          [user]="user"></app-resource-filter-container>
      </ng-container>
      <section>
        <div class="search-results position-relative">
          <ng-container *ngIf="!isLoading; else Loading">
            <ng-container *ngFor="let resource of resourceList">
              <app-resource-list-item [resourceListItem]="resource" />
            </ng-container>
            <ng-container *ngIf="resourceList.length < 1">
              <div
                class="d-flex flex-column justify-content-center align-items-center"
                style="min-height: 500px">
                <h2>No Results Found</h2>
                <p>Please check your search term and filters and try again.</p>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #Loading>
            <div class="w-100 text-center mb-5" style="height: 30vh">
              <app-loader> </app-loader>
            </div>
          </ng-template>
        </div>
      </section>
      <section>
        <div class="d-flex justify-content-center">
          <ngb-pagination
            *ngIf="searchMetaData && searchMetaData.totalCount > 10"
            [(page)]="searchMetaData.currentPage"
            [pageSize]="searchMetaData.perPage"
            [collectionSize]="searchMetaData.totalCount"
            [maxSize]="5"
            (pageChange)="pageChanged()"></ngb-pagination>
        </div>
      </section>
    </div>
  </div>
</div>
