<div class="container">
  <h1>Add a District</h1>
  <div class="card border-0 mb-3">
    <div class="card-body">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="mb-3">
          <label for="new-districts" class="form-label">Select District</label>
          <select
            #districtSelect
            (change)="onDistrictSelected(districtSelect.value)"
            formControlName="districts"
            class="form-select"
            id="new-districts"
            [ngClass]="{
              'is-invalid': submitted && f['districts'].errors
            }">
            <option value="">Choose a District...</option>
            <option
              *ngFor="let district of newDistricts"
              [value]="district.name">
              {{ district.name }}
            </option>
          </select>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="district-name" class="form-label">District Name</label>
            <div class="input-group">
              <input
                formControlName="districtName"
                type="text"
                class="form-control"
                id="district-name"
                aria-label="District Name"
                [ngClass]="{
                  'is-invalid': submitted && f['districtName'].errors
                }" />
            </div>
            <div class="form-text">The full name of the district</div>
          </div>
          <div class="col">
            <label for="district-code" class="form-label">District Code</label>
            <div class="input-group">
              <input
                formControlName="districtCode"
                type="text"
                class="form-control"
                id="district-code"
                aria-label="District Code"
                [ngClass]="{
                  'is-invalid': submitted && f['districtCode'].errors
                }" />
            </div>
            <div class="form-text">
              A unique, human-readable, lowercased code to identify the district
            </div>
          </div>
          <div class="col">
            <label for="crm-id" class="form-label">CRM ID</label>
            <fieldset disabled>
              <div class="input-group">
                <input
                  formControlName="crmId"
                  type="text"
                  class="form-control"
                  id="crm-id"
                  aria-label="CRM ID"
                  [ngClass]="{
                    'is-invalid': submitted && f['crmId'].errors
                  }" />
              </div>
            </fieldset>
            <div class="form-text">The HubSpot ID to link records</div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="description" class="form-label">Description</label>
            <fieldset disabled>
              <div class="input-group">
                <textarea
                  formControlName="description"
                  class="form-control"
                  id="description"
                  aria-label="Description"></textarea>
              </div>
            </fieldset>
            <div class="form-text">
              A brief internal description (only viewable to e2L users)
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="address-1" class="form-label">Address 1</label>
            <div class="input-group">
              <input
                formControlName="address1"
                type="text"
                class="form-control"
                id="address-1"
                aria-label="Address 1" />
            </div>
          </div>
          <div class="col">
            <label for="address-2" class="form-label">Address 2</label>
            <div class="input-group">
              <input
                formControlName="address2"
                type="text"
                class="form-control"
                id="address-2"
                aria-label="Address 2" />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="city" class="form-label">City</label>
            <div class="input-group">
              <input
                formControlName="city"
                type="text"
                class="form-control"
                id="city"
                aria-label="City" />
            </div>
          </div>
          <div class="col">
            <label for="state" class="form-label">State</label>
            <div class="input-group">
              <select formControlName="state" class="form-select" id="state">
                <option *ngFor="let state of states" [value]="state.value">
                  {{ state.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <label for="zip-code" class="form-label">Zip Code</label>
            <div class="input-group">
              <input
                formControlName="zipCode"
                type="text"
                class="form-control"
                id="zip-code"
                aria-label="Zip Code" />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="timezone" class="form-label">Timezone</label>
            <div class="input-group">
              <select
                formControlName="timezone"
                class="form-select"
                id="timezone">
                <option
                  *ngFor="let timezone of timezones"
                  [value]="timezone.linkBack">
                  {{ timezone.label }}
                </option>
              </select>
            </div>
            <div class="form-text">
              The timezone is needed to localize display times for district
              users.
            </div>
          </div>
          <div class="col">
            <label for="domain" class="form-label">Domain</label>
            <div class="input-group">
              <input
                formControlName="domain"
                type="text"
                class="form-control"
                id="domain"
                aria-label="Domain"
                [ngClass]="{ 'is-invalid': submitted && f['domain'].errors }" />
            </div>
            <div class="form-text">
              The domain is needed to validate email addresses
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input
                formControlName="isB2b"
                class="form-check-input"
                type="checkbox"
                id="is-b2b" />
              <label class="form-check-label" for="is-b2b"> Is B2B </label>
            </div>
            <div class="form-text">
              Whether this district (e.g. a state) has sub-districts associated
              with it
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input
                formControlName="isInternal"
                class="form-check-input"
                type="checkbox"
                id="is-internal"
                checked />
              <label class="form-check-label" for="is-internal">
                Is Internal
              </label>
            </div>
            <div class="form-text">
              Whether this is a test, demo, or similar district for e2L-only
              user
            </div>
          </div>
        </div>
        <ng-container *ngIf="errorMessage">
          <div class="alert alert-danger text-start mt-5" role="alert">
            {{ errorMessage }}
          </div>
        </ng-container>
        <div class="input-group justify-content-end mt-5">
          <button
            type="button"
            class="btn btn-secondary rounded-pill px-5 mx-1"
            (click)="onCancel()">
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary rounded-pill px-5 mx-1"
            [disabled]="loading">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm me-1"></span>
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
