<ng-container *ngIf="badge && badge.type">
  <div class="badge-container" [ngClass]="size">
    <div class="badge-graphic-wrapper" [ngClass]="size">
      <div
        class="badge-wrapper d-flex justify-content-center"
        [ngClass]="badge.type">
        <ng-container
          *ngIf="badge.isEarned && showDownloadButton && badgeImageExists">
          <button
            class="btn mb-2 badge-download-btn"
            aria-label="Download Badge"
            ngbTooltip="Download Badge"
            container="body"
            placement="bottom"
            [autoClose]="false"
            [ngbPopover]="downloadConfirmationPopover"
            (click)="downloadBadge(badge.id)"
            #downloadBadgePopover="ngbPopover"
            #downloadBadgeTooltip="ngbTooltip">
            <i [ngClass]="['bi', 'fs-21', 'bi-download']"></i>
          </button>
          <ng-template #downloadConfirmationPopover>
            <div class="p-2">Your badge has been downloaded.</div>
            <div class="p-2 d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="downloadBadgePopover.close()">
                OK
              </button>
            </div>
          </ng-template>
        </ng-container>
        <div
          *ngIf="badge.type === 'strand' && badge.level"
          class="level d-flex justify-content-center align-items-center rounded-circle">
          {{ badge.level }}
        </div>
        <ng-container *ngIf="badge.type === 'strand'">
          <div
            class="title-wrapper d-flex align-items-center text-center p-2 rounded-circle"
            [ngClass]="{
            'bg-level-1': badge.level == 1,
            'bg-level-2': badge.level == 2,
            'bg-level-3': badge.level == 3,
            'bg-level-4': badge.level == 4,
          }">
            <div class="mx-auto">{{ badge.strand }}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="badge.type === 'competency'">
          <div
            class="image-wrapper rounded-circle"
            [ngClass]="size"
            [ngStyle]="{
              'background-image': 'url(' + apiUrl + badge.icon + ')'
            }"></div>
        </ng-container>
      </div>
    </div>
    <div class="competency-title text-center fw-800 pt-2" [ngClass]="size">
      {{ badge.competency }}
    </div>
  </div>
</ng-container>
