/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (
  environment.environment !== 'dev' &&
  environment.environment !== 'localqa'
) {
  Sentry.init({
    dsn: 'https://217572764863441bbe2001735dc6fd35@o1387805.ingest.sentry.io/4504616967077888',
    environment: environment.environment,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://growelab.app',
          'https://qa.growelab.app',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  /* eslint-disable-next-line no-console */
  .catch((err) => console.error(err));
