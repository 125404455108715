import {
  LogShadower as LogShadowerDTO,
  ReportingLogDetailsItemDTO,
} from 'src/app/common/dtos/reports.dto';
import {
  LogShadowerAPIResponse,
  ReportingLogDetailsItemAPIResponse,
} from '../../types/responses/reporting.responses';

export const logShadowerDTOFromAPIResponse = (
  item: LogShadowerAPIResponse
): LogShadowerDTO => ({
  shadower: item.shadower,
  shadowerPhoto: item.shadower_photo,
  shadowerColor: item.shadower_color,
  shadowerUserId: item.shadower_user_id,
});

export const reportingLogDetailsDTOFromApiResponse = (
  item: ReportingLogDetailsItemAPIResponse
): ReportingLogDetailsItemDTO => ({
  id: item.id,
  title: item.title,
  dateAndTime: item.date_and_time,
  startDatetime: item.start_datetime,
  timezone: item.timezone,
  durationMins: item.duration_mins,
  logType: item.log_type,
  logTypeView: item.log_type_view,
  observationForm: item.observation_form,
  notes: item.notes,
  coachUserId: item.coach_user_id,
  coach: item.coach,
  coachPhoto: item.coach_photo,
  coachColor: item.coach_color,
  attendeeUserId: item.attendee_user_id,
  attendee: item.attendee,
  attendeePhoto: item.attendee_photo,
  attendeeColor: item.attendee_color,
  shadowers: item.shadowers?.map((shadower) =>
    logShadowerDTOFromAPIResponse(shadower)
  ),
  present: item.present,
  absentReason: item.absent_reason,
  school: item.school,
  attendeeDistrict: item.attendee_district,
  coachingSource: item.coaching_source,
  feedback: item.feedback,
  badges: item.badges,
  badgeCount: item.badge_count,
  competencies: item.competencies,
  giCount: item.gi_count,
});
