import {
  OptionChoiceAPIResponse,
  SelfAssessmentRequirementAPIResponse,
  SelfAssessmentRequirementDTO,
  SmartOptionsDataAPIResponse,
  TextMatchesAPIResponse,
  TextMatchesDTO,
} from 'src/app/common/types/responses/responses';
import {
  OptionChoiceDTO,
  SmartOptionsDataDTO,
} from '../../dtos/smartcoach.dto';
import { evidenceAssessmentDTOFromAPIResponse } from './evidence.translators';

export const OptionChoiceDTOFromOptionChoiceAPIResponse = (
  response: OptionChoiceAPIResponse
): OptionChoiceDTO => ({
  id: response.id,
  title: response.title,
  description: response.description,
  resources: response.resources,
  isSelected: false,
});

export const SmartOptionsDataDTOFromSmartOptionsDataApiResponse = (
  response: SmartOptionsDataAPIResponse
): SmartOptionsDataDTO => ({
  indicator: {
    content: response.indicator.content,
  },
  optionChoices:
    response.optionChoices && response.optionChoices?.length > 0
      ? response.optionChoices.map((choice) =>
          OptionChoiceDTOFromOptionChoiceAPIResponse(choice)
        )
      : [],
  standardTitle: response.standard_title,
  strandTitle: response.strand_title,
});

export const textMatchesDTOFromAPIResponse = (
  response: TextMatchesAPIResponse
): TextMatchesDTO => ({
  id: response.id,
  egroweIndicatorRequirementId: response.egrowe_indicator_requirement_id,
  egroweIndicatorId: response.egrowe_indicator_id,
  textToMatch: response.text_to_match,
  startIndex: response.start_index,
  endIndex: response.end_index,
  createdAt: response.created_at,
  updatedAt: response.updated_at,
});

export const selfAssessmentRequirementDTOFromAPIResponse = (
  response: SelfAssessmentRequirementAPIResponse
): SelfAssessmentRequirementDTO => ({
  id: response.id,
  requirementText: response.requirement_text,
  assessments: response.assessments.map((assessment) =>
    evidenceAssessmentDTOFromAPIResponse(assessment)
  ),
  textMatches: response.textMatches.map((textMatch) =>
    textMatchesDTOFromAPIResponse(textMatch)
  ),
  checked: response.checked,
});
