<label class="visually-hidden" [attr.for]="labelId">{{ label }}</label>
<ng-container>
  <ng-select
    [labelForId]="labelId"
    (change)="cohortSelected($event); select.blur()"
    [items]="cohortList"
    bindLabel="name"
    [loading]="searchLoading"
    [(ngModel)]="internalSelectedCohort"
    [placeholder]="placeholder"
    #select>
  </ng-select>
  <ng-container
    *ngIf="internalSelectedCohortList && internalSelectedCohortList.length">
    <div>
      <ng-container *ngFor="let eachCohort of internalSelectedCohortList">
        <span
          class="badge badge-item mt-2"
          (click)="removeCohortFromList(eachCohort)"
          >{{ eachCohort.name }}<i class="bi bi-x mx-2"></i
        ></span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
