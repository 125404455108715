<div class="d-flex align-items-center item">
  <app-avatar
    id="avatar"
    avatarSize="medium"
    [profile]="user.profile"></app-avatar>
  <div>
    <div class="fw-600">
      {{ user.profile.first_name }} {{ user.profile.last_name }}
    </div>
    <div class="small text-body-secondary">{{ user.profile.title }}</div>
    <div class="small">{{ user.school?.title }}</div>
  </div>
</div>
