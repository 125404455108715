<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-8 text-center">
      <a href="/">
        <img
          id="groweLabLogo"
          src="assets/logo_black.png"
          class="my-4"
          width="240"
          alt="GroweLab Logo" />
      </a>
    </div>
  </div>
  <div class="row justify-content-center bg-light rounded pb-5">
    <div class="col-lg-8">
      <h1 class="my-5">GroweLab Terms of Use</h1>
      <p><em>Effective date: July 1, 2023</em></p>
      <h2>1. User's Acknowledgment and Acceptance of Terms</h2>
      <p>
        Engage! Learning, Inc. dba engage2learn (referred to as 'e2L') provides
        the GroweLab platform (www.growelab.app) and various related services
        (together referred to as this 'platform”) subject to your compliance
        with all the terms, conditions, and notices contained or referenced
        herein (the 'Terms of Use'), as well as any other written agreement
        between us (or with your employer). In addition, when using particular
        services or materials on this platform, users shall be subject to any
        posted guidelines or rules applicable to such services or materials that
        may contain terms and conditions in addition to those in these Terms of
        Use. All such guidelines or rules are hereby incorporated by reference
        into these Terms of Use.
      </p>
      <p>
        CAREFULLY READ THE FOLLOWING AS IT CONTAINS A LICENSE AGREEMENT. THIS
        AGREEMENT CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND
        OBLIGATIONS, AS WELL AS LIMITATIONS AND EXCLUSIONS THAT MAY APPLY TO
        YOU. THIS DOCUMENT CONTAINS A DISPUTE RESOLUTION CLAUSE. BY COMPLETING
        THE GROWELAB REGISTRATION PROCESS, INDICATING YOUR AGREEMENT BELOW,
        AND/OR USING THIS PLATFORM, YOU AGREE TO BE BOUND BY THESE TERMS OF USE.
        IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, CLICK THE 'DO
        NOT ACCEPT' BUTTON OR LEAVE THE PLATFORM.
      </p>
      <p>
        YOUR REMEDY FOR DISSATISFACTION WITH THIS PLATFORM, OR ANY PRODUCTS,
        SERVICES, CONTENT, OR OTHER INFORMATION AVAILABLE ON OR THROUGH THIS
        PLATFORM, IS TO STOP USING THE PLATFORM AND/OR THOSE PARTICULAR PRODUCTS
        OR SERVICES. YOUR AGREEMENT WITH e2L REGARDING COMPLIANCE WITH THESE
        TERMS OF USE BECOMES EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF YOUR USE
        OF THIS PLATFORM.
      </p>
      <p>
        e2L reserves the right to change these Terms of Use from time to time.
        If the Terms of Use are changed you will be notified upon your next
        login to the platform. Your continued use of the platform after such
        modifications will constitute your acknowledgment of the modified Terms
        of Use and your agreement to abide by and be bound by the modified Terms
        of Use.
      </p>
      <h2>2. Definitions</h2>
      <p>
        'Affiliates' means our owners, subsidiaries, affiliated companies,
        officers, directors, suppliers, partners, sponsors, or joint venturers.
        'e2L', as used herein, includes e2L's Affiliates.
      </p>
      <p>
        "Contribution" shall mean any original work of authorship, including any
        modifications or additions to an existing work that You submit to e2L
        for inclusion in, or documentation of, any of the products owned or
        managed by e2L, including but not limited to the Platform. For the
        purposes of this definition, "submitted" means any form of electronic,
        verbal, or written communication sent or transmitted to e2L or its
        representatives, including but not limited to content edits, content
        submissions, communication on electronic mailing lists, source code
        control systems, and issue tracking systems that are managed by, or on
        behalf of, e2L.
      </p>
      <h2>3. Description of Services</h2>
      <p>
        E2L makes various services available on this platform including, but not
        limited to, GroweLab. Fees for the various services are set out in the
        agreement between e2L and your employer and may be further described
        elsewhere on this platform. You are responsible for providing, at your
        own expense, all equipment necessary to use the services, including a
        computer and modem, and your own Internet access.
      </p>
      <p>
        E2L reserves the sole right to modify or discontinue the platform,
        including any features therein, at any time with or without notice to
        you. E2L shall not be liable to you or any third party should e2L
        exercise such right. Modifications may include but are not limited to,
        changes in the pricing structure, the addition of fee-based services, or
        changes to limitations on allowable file sizes. Any new features that
        augment or enhance the then-current services on this platform shall also
        be subject to these Terms of Use. You understand and agree that
        temporary interruptions of the services available through this platform
        may occur as normal events. You further understand and agree that e2L
        has no control over third party networks you may access in the course of
        using this platform. Therefore, delays and disruption due to other
        network transmissions are completely beyond our control.
      </p>
      <p>
        You understand and agree that the services available on this platform
        are provided 'AS IS' and that e2L assumes no responsibility for the
        timeliness, accuracy of the material, deletion of material from the
        platform, or failure to store any user communications or personalization
        settings.
      </p>
      <h2>4. Registration Data and Privacy</h2>
      <p>
        In order to access some of the services on this platform, you will be
        provided with login credentials. By logging into the Platform, you agree
        that all information you provide while logging in is true and accurate
        and that you will maintain and update this information as required in
        order to keep it current, complete, and accurate. You also grant e2L the
        right to disclose to third parties certain Registration Data about you.
        The information e2L obtains through your use of this platform, including
        your Registration Data, is subject to our Privacy Policy, which is
        specifically incorporated by reference into these Terms of Use.
      </p>
      <h2>5. Conduct on Platform</h2>
      <p>
        Your use of the platform is subject to all applicable laws and
        regulations, including common netiquette, and you are solely responsible
        for the contents of your communications through the platform. By posting
        information in or otherwise using any communications service, chat room,
        message board, newsgroup, software library, or another interactive
        service, including but not limited to the GroweLab features, that may be
        available to you on or through this platform, you agree that you will
        not upload, share, post, or otherwise distribute or facilitate
        distribution of any content, including text, communications, software,
        images, sounds, data, or other information that:
      </p>
      <ul>
        <li>
          Is unlawful, threatening, abusive, harassing, defamatory, libelous,
          deceptive, fraudulent, invasive of another's privacy, tortious,
          contains explicit or graphic descriptions or accounts of sexual acts
          (including but not limited to sexual language of a violent or
          threatening nature directed at another individual or group of
          individuals), or otherwise violates our rules or policies;
        </li>
        <li>
          Victimizes, harasses, degrades, or intimidates an individual or group
          of individuals on the basis of religion, gender, sexual orientation,
          race, ethnicity, age, or disability;
        </li>
        <li>
          Infringes on any patent, trademark, trade secret, copyright, right of
          publicity, or other proprietary right of any party; Constitutes
          unauthorized advertising, junk or bulk email (also known as
          'spamming'), chain letters, any other form of unauthorized
          solicitation, or any form of lottery or gambling;
        </li>
        <li>
          Contains software viruses or any other computer code, files, or
          programs that are designed or intended to disrupt, damage, or limit
          the functioning of any software, hardware, or telecommunications
          equipment or to damage or obtain unauthorized access to any data or
          other information of any third party; or
        </li>
        <li>
          Impersonates any person or entity, including any of our employees,
          representatives, or users.
        </li>
      </ul>
      <p>
        You agree that information you upload into the platform may be
        considered student data subject to federal and state privacy regulations
        (“Student Data”). You agree to comply with laws governing the privacy of
        Student Data and to upload data only to the extent it is permitted by
        federal and state privacy regulations. To the extent student data
        pertains to students under age 13, you acknowledge and agree, to the
        extent applicable, that you and your employer as an educational
        institution provide any necessary consent or agree to act as the agent
        of the parent/guardian and are consenting on their behalf for as
        required by the COPPA “Customer Consent” provisions.
      </p>
      <p>
        E2L neither endorses nor assumes any liability for the contents of any
        material uploaded or submitted by third party users of the platform. E2L
        generally does not pre-screen, monitor, or edit the content posted by
        users of the GroweLab, chat rooms, message boards, newsgroups, or other
        interactive services that may be available on or through this platform.
        However, e2L and our agents have the right at our sole discretion to
        remove any content that, in our judgment, does not comply with these
        Terms of Use and any other rules of user conduct for our platform, or is
        otherwise harmful, objectionable, or inaccurate. E2L is not responsible
        for any failure or delay in removing such content. You hereby consent to
        such removal and waive any claim against e2L arising out of such removal
        of content. See 'Use of Your Materials' below for a description of the
        procedures to be followed in the event that any party believes that
        content posted on this platform infringes on any patent, trademark,
        trade secret, copyright, right of publicity, or other proprietary right
        of any party.
      </p>
      <p>
        In addition, you may not use your account to breach the security of
        another account or attempt to gain unauthorized access to another
        network or server. Not all areas of this platform may be available to
        you or other authorized users of this platform. You shall not interfere
        with another user's use and enjoyment of the platform or other similar
        services. Users who violate systems or network security may incur
        criminal or civil liability.
      </p>
      <p>
        You agree that e2L may at any time, and at e2L's sole discretion,
        terminate your access without prior notice to you for violating any of
        the provisions of these Terms of Use. In addition, you acknowledge that
        e2L will cooperate fully with investigations of violations of systems or
        network security at other platforms, including cooperating with law
        enforcement authorities in investigating suspected criminal violations.
      </p>
      <h2>6. Third-Party Sites and Information</h2>
      <p>
        This platform may link you to other sites on the Internet or otherwise
        include references to information, documents, software, materials,
        and/or services provided by other parties. These sites may contain
        information or material that some people may find inappropriate or
        offensive. These other sites and parties are not under e2L's control,
        and you acknowledge that e2L is not responsible for the accuracy,
        copyright compliance, legality, decency, or any other aspect of the
        content of such sites, nor is e2L responsible for errors or omissions in
        any references to other parties or their products and services. The
        inclusion of such a link or reference is provided merely as a
        convenience and does not imply endorsement of, or association with, the
        site or party by e2L, or any warranty of any kind, either express or
        implied.
      </p>
      <h2>7. Intellectual Property Information</h2>
      <p>
        For purposes of these Terms of Use, 'content' is defined as any
        information, communications, software, photos, video, graphics, music,
        sounds, and other material and services that can be viewed by users on
        e2L platforms. This includes but is in no way limited to, the GroweLab
        resources and tools, message boards, chat, and other original content.
      </p>
      <p>
        By accepting these Terms of Use, you acknowledge and agree that, unless
        otherwise provided herein, all content presented to you on this platform
        is protected by copyrights, trademarks, service marks, patents, or other
        proprietary rights and laws, and is the sole property of e2L and/or its
        Affiliates. You are only permitted to use the content as expressly
        authorized by e2L. With the exception of material you create using the
        GroweLab, except for a single copy made for personal use only, you may
        not copy, reproduce, modify, republish, upload, post, transmit, or
        distribute any documents or information from this platform in any form
        or by any means without prior written permission from e2L or the
        specific content provider, and you are solely responsible for obtaining
        permission before reusing any copyrighted material that is available on
        this platform. Any unauthorized use of the materials appearing on this
        platform may violate copyright, trademark, and other applicable laws and
        could result in criminal or civil penalties. Neither e2L nor our
        Affiliates warrant nor represent that your use of materials displayed
        on, or obtained through, this platform will not infringe the rights of
        third parties.
      </p>
      <p>
        'Engage,' 'Engage!,' 'engage!, 'e', 'e2L' and all related custom
        graphics, icons, logos, and service names are registered trademarks,
        trademarks, or service marks of e2L or its Affiliates. All other
        trademarks or service marks are the property of their respective owners.
        Nothing in these Terms of Use grants you any right to use any trademark,
        service mark, logo, and/or the name of e2L or its Affiliates.
      </p>
      <h2>8. Use of Your Materials and License to e2L</h2>
      <p>
        The intent and design of the GroweLab is the creation and provision of a
        library of education materials for GroweLab users. You would like to use
        and contribute to the GroweLab library. For good and valuable
        consideration, the receipt and legal sufficiency of which are hereby
        acknowledged, You grant the following license to e2L for Your
        Contributions.
      </p>
      <p>
        Subject to our Privacy Policy, any Contribution you transmit to this
        platform or to e2L, whether by electronic mail or other means, for any
        reason, will be treated as non-confidential. While you retain all rights
        in such communications or original material, you grant e2L a
        non-exclusive, worldwide, royalty-free, irrevocable license to
        reproduce, prepare derivative works of, modify, alter, adapt, publicly
        display, publicly perform, distribute and sublicense, internally and
        externally, the Contribution and such derivative works in any form or
        medium (now known or not currently known).
      </p>
      <p>
        You represent that you are legally entitled to grant the above license.
        If your employer(s) has rights to intellectual property that you create
        that includes your Contributions, you represent that you have received
        permission to make Contributions on behalf of that employer and that
        your employer has waived such rights for your Contributions to e2L.
      </p>
      <p>
        You represent that each of Your Contributions is Your original creation.
        You represent that Your Contribution submissions include complete
        details of any third-party license or other restriction (including, but
        not limited to, related patents and trademarks) of which you are
        personally aware and which are associated with any part of Your
        Contributions.
      </p>
      <p>
        You agree to notify e2L of any facts or circumstances of which you
        become aware that would make these representations inaccurate in any
        respect. Please do not submit confidential or proprietary information to
        e2L unless e2L has mutually agreed in writing that such material will be
        treated otherwise than as stated above.
      </p>
      <p>
        E2L respects the intellectual property of others, and e2L asks you to do
        the same. If you or any user of this platform believes its copyright,
        trademark, or other property rights have been infringed by a posting on
        this platform, you or the user should send notification to our
        Designated Agent (as identified below) immediately. To be effective, the
        notification must include:
      </p>
      <ul>
        <li>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of the exclusive right that is allegedly
          infringed.
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed;
        </li>
        <li>
          Information reasonably sufficient to permit e2L to contact the
          complaining party, such as an address, telephone number, and, if
          available, an electronic mail address at which the complaining party
          may be contacted;
        </li>
        <li>
          Identification of the material that is claimed to be infringing and
          that is to be removed and information reasonably sufficient to permit
          e2L to locate that material;
        </li>
        <li>
          A statement that the complaining party has a good faith belief that
          use of the material in a complained-of manner is not authorized by the
          copyright owner, its agent, or the law; and
        </li>
        <li>
          A statement that the information in the notification is accurate and,
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of the exclusive right that is allegedly
          infringed.
        </li>
      </ul>
      <p>
        Pursuant to the Digital Millennium Copyright Act, 17 U.S.C.A. Sec.
        512(c), our Designated Agent for Notice of claims of copyright
        infringement can be reached as indicated below. Access to the platform
        by repeat infringers of copyright or by users about whom repeat claims
        of copyright infringement are received will be terminated.
      </p>
      <p>Designated Agent for Claimed Infringement:</p>
      <p>
        Matt Bachman<br />
        Engage! Learning, Inc.<br />
        8911 North Capital of Texas Highway, Suite 4200-1065<br />
        Austin, TX 78759<br />
        matt.bachman&#64;engage2learn.org
      </p>
      <p>
        You acknowledge and agree that upon receipt of a notice of a claim of
        copyright infringement, e2L may immediately remove the identified
        materials from our platform without liability to you or any other.
      </p>
      <h2>9. Disclaimer of Warranties</h2>
      <p>
        ALL MATERIALS AND SERVICES ON THIS PLATFORM ARE PROVIDED ON AN 'AS IS'
        AND 'AS AVAILABLE' BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF
        NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, e2L MAKES NO WARRANTY
        THAT (A) THE SERVICES AND MATERIALS WILL MEET YOUR REQUIREMENTS, (B) THE
        SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
        SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D)
        THE QUALITY OF ANY PRODUCTS, SERVICES, OR INFORMATION ACCESSED OR
        OBTAINED BY YOU FROM THE PLATFORM FROM e2L OR OUR AFFILIATES WILL MEET
        YOUR EXPECTATIONS OR BE FREE FROM MISTAKES, ERRORS OR DEFECTS.
      </p>
      <p>
        THIS PLATFORM COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES,
        OR TYPOGRAPHICAL ERRORS. E2L MAY MAKE CHANGES TO THE MATERIALS AND
        SERVICES ON THIS PLATFORM, INCLUDING THE DESCRIPTIONS OF ANY SERVICES OR
        PRODUCTS LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE MATERIALS OR
        SERVICES AT THIS PLATFORM MAY BE OUT OF DATE, AND e2L MAKES NO
        COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES.
      </p>
      <p>
        THE USE OF THE SERVICES OR THE DOWNLOADING OR OTHER ACQUISITION OF ANY
        MATERIALS THROUGH THIS PLATFORM IS DONE AT YOUR OWN DISCRETION AND RISK
        AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
        DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH
        ACTIVITIES.
      </p>
      <p>
        Through your use of the platform, you may have opportunities to share
        materials with other users. TO THE FULLEST EXTENT PERMITTED BY LAW, E2L
        MAKES NO WARRANTY REGARDING ANY SHARED MATERIALS OR OTHER TRANSACTIONS
        EXECUTED THROUGH, OR IN CONNECTION WITH THIS PLATFORM; AND YOU
        UNDERSTAND AND AGREE THAT SHARING OR USE OF SHARED MATERIALS OR OTHER
        TRANSACTIONS ARE CONDUCTED ENTIRELY AT YOUR OWN RISK. ANY WARRANTY THAT
        IS PROVIDED IN CONNECTION WITH ANY PRODUCTS, SERVICES, MATERIALS OR
        INFORMATION AVAILABLE ON OR THROUGH THIS PLATFORM FROM A THIRD PARTY IS
        PROVIDED SOLELY BY SUCH THIRD PARTY, AND NOT BY e2L OR ANY OTHER OF OUR
        AFFILIATES.
      </p>
      <p>
        Content available through this platform often represents the opinions
        and judgments of another person or entity not connected with e2L. E2L
        does not endorse, nor is e2L responsible for the accuracy or reliability
        of, any opinion, advice, or statement made by anyone other than an
        authorized e2L spokesperson speaking in her/his official capacity. There
        may be other editorial policies to which you should refer posted on
        various sections of this platform for further information, which
        policies are incorporated by reference into these Terms of Use.
      </p>
      <p>
        In addition, the materials on this platform may include sample
        documents, audio/video materials, and other items ('Samples'). These
        Samples are provided solely as examples of typical materials of their
        kind, and the delivery and use of Samples do not constitute professional
        advice. Under no circumstances will e2L or its Affiliates be liable for
        any loss or damages caused by your reliance on information or advice
        obtained through this platform, including your use of any of the
        Samples. It is your responsibility to evaluate the accuracy,
        completeness, or usefulness of any information, opinions, advice,
        Samples, or other content available on or through this platform.
      </p>
      <h2>10. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, Your exclusive remedy and our
        entire liability, if any, for any claims arising out of these Terms of
        Use and your use of this platform shall be limited to the amount paid to
        e2L by your employer for the services on the platform during the
        six-month period before the act giving rise to the liability.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL e2L OR OUR
        AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL,
        PUNITIVE, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR
        ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING
        FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT e2L HAS BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY,
        ARISING OUT OF OR IN CONNECTION WITH THE USE OF THIS PLATFORM OR OF ANY
        WEB PLATFORM REFERENCED OR LINKED TO FROM THIS PLATFORM.
      </p>
      <p>
        FURTHER, e2L SHALL NOT BE LIABLE IN ANY WAY FOR THIRD PARTY MATERIALS
        OFFERED THROUGH THIS PLATFORM.
      </p>
      <h2>11. Indemnification</h2>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, UPON A REQUEST BY e2L, YOU AGREE
        TO DEFEND, INDEMNIFY, AND HOLD e2L AND OUR AFFILIATES HARMLESS FROM ALL
        LIABILITIES, CLAIMS, AND EXPENSES, INCLUDING ATTORNEY'S FEES THAT ARISE
        FROM YOUR USE OR MISUSE OF THIS PLATFORM INCLUDING BUT NOT LIMITED TO
        THE UNAUTHORIZED OR INAPPROPRIATE SHARING OF MATERIALS. E2L RESERVES THE
        RIGHT AT OUR OPTION TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY
        MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH EVENT YOU
        WILL COOPERATE WITH e2L IN ASSERTING ANY AVAILABLE DEFENSES.
      </p>
      <h2>12. Use of Platform and Storage of Material</h2>
      <p>
        You acknowledge that e2L may establish general practices and limits
        concerning the use of the services available on our platform, including
        without limitation the maximum number of days that uploaded content will
        be retained on the platform, the maximum disk space that will be
        allotted to any user's materials, and the maximum number of times (and
        the maximum duration for which) you may access the services in a given
        period of time. You agree that e2L has no responsibility or liability
        for the deletion or failure to store any content maintained or
        transmitted on or through this platform. You acknowledge that e2L
        reserves the right to log off accounts for which a subscription fee has
        not been paid or that are inactive for an extended period of time. You
        further acknowledge that e2L reserves the right to change these general
        practices and limits at any time, in our sole discretion, with or
        without notice.
      </p>
      <p>
        E2L provides access to material through our platform. For purposes of
        these Terms of Use, 'material' refers to all forms of communication that
        e2L allows, which may (but will not necessarily) include material in the
        GroweLab, narrative descriptions, graphics (including photographs,
        illustrations, images, drawings, logos), video recordings, and audio
        recordings. You may not use this platform to publish material that e2L
        determines, at our sole discretion, to be unlawful, indecent, or
        objectionable, or which violates the restrictions described in 'Conduct
        on Platform' above. E2L will not routinely monitor the contents of the
        platform. However, if complaints are received regarding language,
        content, or graphics contained in your contributions, e2L may, at our
        sole discretion, remove them. E2L may also suspend the account or
        restrict access to it.
      </p>
      <p>
        The accounts of our users operate on shared resources. Excessive use or
        abuse of these shared network resources by one user may have a negative
        impact on all other users. Misuse of network resources in a manner that
        impairs network performance, including excessive consumption of CPU
        time, memory, disk space, and session time, is prohibited and may result
        in the termination of your account or the limitation of your activities.
      </p>
      <p>
        This platform is not designed or intended to be used as a disaster
        recovery facility or as an emergency data storage facility. Although e2L
        takes reasonable precautions to preserve and protect the material you
        upload to the platform, you should not rely on the platform as your
        storage facility. You should preserve copies of any materials that you
        have uploaded. You agree not to hold e2L liable for any damage to or any
        deletion of your materials.
      </p>
      <h2>13. Security and Password</h2>
      <p>
        You are solely responsible for maintaining the confidentiality of your
        password and account name and for any and all statements made and acts
        or omissions that occur through the use of your password and account
        name. Therefore, you must take steps to ensure that others do not gain
        access to your password and account name. You may not transfer or share
        your account with anyone, and e2L reserves the right to immediately
        terminate your account in the event of any unauthorized transfer or
        sharing thereof.
      </p>
      <h2>14. International Use</h2>
      <p>
        Although this platform may be accessible worldwide, e2L makes no
        representation that materials on this platform are appropriate or
        available for use in locations outside the United States, and accessing
        them from locations where their contents are illegal is prohibited.
        Those who choose to access this platform from other locations do so on
        their own initiative and are responsible for compliance with local laws.
        Any offer for any product, service, and/or information made in
        connection with this platform is void where prohibited.
      </p>
      <h2>15. Termination of Use</h2>
      <p>
        Your right to access and use the platform pursuant to this Agreement
        shall upon the earliest of (1) e2L's termination of the Agreement for
        any reason; (2) Your breach of the Terms of Use or other stated e2L
        policies; or (3) the end of Your employment with Employer, whether by
        reason of termination or resignation.
      </p>
      <p>
        You are further advised any suspected fraudulent, abusive, or illegal
        activity may be referred to appropriate law enforcement authorities.
      </p>
      <p>
        Upon termination or suspension, regardless of the reasons therefore,
        your right to use the services available on this platform immediately
        ceases, and you acknowledge and agree that e2L may immediately
        deactivate or delete your account and all related information and files
        in your account. E2L shall not be liable to you or any third party for
        any claims or damages arising out of any termination or suspension or
        any other actions taken by e2L in connection therewith. Sections 1, 4,
        5'12, and 15'17 of these Terms of Use shall survive any termination.
      </p>
      <h2>16. Governing Law</h2>
      <p>
        This platform (excluding any linked platforms) is controlled by e2L from
        our offices within the State of Texas, United States of America. It can
        be accessed from all 50 states, as well as from other countries around
        the world. As each of these places has laws that may differ from those
        of Texas, by accessing this platform both of us agree that the statutes
        and laws of the State of Texas, without regard to the conflicts of laws
        and principles thereof will apply to all matters relating to the use of
        this platform and the purchase of products and services available
        through this platform. Each of us agrees and hereby submits to the
        exclusive personal jurisdiction and venue of the District Court of San
        Patricio County, Texas, and the United States District Court for the
        Southern District of Texas with respect to such matters.
      </p>
      <h2>17. Notices</h2>
      <p>
        All notices to a party shall be in writing and shall be made either via
        email or conventional mail. Notices to e2L must be sent to the attention
        of Matt Bachman at matt.bachman&#64;engage2learn.org, if by email, or at
        Engage Learning, Inc. dba engage2learn, 8911 North Capital of Texas
        Highway, Suite 4200-1065 Austin, TX 78759, if by conventional mail.
        Notices to you may be sent to the email address supplied for your
        account. In addition, e2L may broadcast notices or messages through the
        platform to inform you of changes to the platform or other matters of
        importance, and such broadcasts shall constitute notice to you.
      </p>
      <p>
        Any notices or communication under these Terms of Use will be deemed
        delivered to the party receiving such communication (1) on the delivery
        date if delivered personally to the party; (2) two business days after
        deposit with a commercial overnight carrier, with written verification
        of receipt; (3) five business days after the mailing date, if sent by US
        mail, return receipt requested; (4) on the delivery date if transmitted
        by confirmed facsimile; or (5) on the delivery date if transmitted by
        confirmed email.
      </p>
      <h2>18. Entire Agreement</h2>
      <p>
        This Engage Learning, Inc. dba engage2learn Terms of Use along with the
        contract between your employer and e2L, if any, constitute the entire
        agreement and understanding between us concerning the subject matter
        hereof and supersede all other agreements and understandings of the
        parties with respect thereto. To the extent that anything in or
        associated with this platform is in conflict or inconsistent with these
        Terms of Use, these Terms of Use shall take precedence.
      </p>
      <h2>19. Miscellaneous</h2>
      <p>
        In any action to enforce these Terms of Use, the prevailing party will
        be entitled to costs and attorneys' fees. Any cause of action brought by
        you against e2L or our Affiliates must be instituted within one year
        after the cause of action arises or be deemed forever waived and barred.
      </p>
      <p>
        You may not assign your rights and obligations under these Terms of Use,
        and any purported attempt to do so shall be null and void. E2L may
        freely assign our rights and obligations under these Terms of Use.
      </p>
      <p>
        In addition to any excuse provided by applicable law, e2L shall be
        excused from liability for non-delivery or delay in delivery of services
        available through our platform arising from any event beyond our
        reasonable control, whether or not foreseeable by either party,
        including but not limited to labor disturbance, war, fire, accident,
        adverse weather, inability to secure transportation, governmental act or
        regulation, cyber-attack, computer virus and other causes or events
        beyond our reasonable control, whether or not similar to those which are
        enumerated above.
      </p>
      <p>
        If any part of these Terms of Use is held invalid or unenforceable, that
        portion shall be construed in a manner consistent with applicable law to
        reflect, as nearly as possible, the original intentions of the parties,
        and the remaining portions shall remain in full force and effect.
      </p>
      <p>
        Any failure by e2L to enforce or exercise any provision of these Terms
        of Use or related rights shall not constitute a waiver of that right or
        provision.
      </p>
      <h2>20. Contact Information</h2>
      <p>
        Except as explicitly noted on this platform, the services available
        through this platform are offered by Engage! Learning, Inc., a Texas
        corporation located at 8911 North Capital of Texas Highway, Suite
        4200-1065 Austin, TX 78759. Our telephone number is 361.704.9654. If you
        notice that any user is violating these Terms of Use, please contact e2L
        at matt.bachman&#64;engage2learn.org.
      </p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
