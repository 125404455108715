<div class="form-group select">
  <label [id]="id" class="form-label ms-2">{{ fieldDefinition.label }}</label>
  <ng-container *ngIf="!readOnly; else readonlyText">
    <ng-select
      #select
      [id]="id"
      [className]="'formSelect'"
      [items]="fieldDefinition.choices"
      bindLabel="label"
      bindValue="value"
      [(ngModel)]="selection"
      (ngModelChange)="changeModel()"
      [clearable]="false"
      [disabled]="inputDisabled"></ng-select>
  </ng-container>
  <ng-template #readonlyText>
    <div class="ms-3">{{ selection }}</div>
  </ng-template>
</div>
