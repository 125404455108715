import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import {
  sessionTimeoutStorageKey,
  shouldForceLogout,
  shouldTimeoutTrigger,
} from './session-timeout.helpers';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService implements OnDestroy {
  constructor(private authService: AuthService, private router: Router) {}

  public showTimeoutModal: Subject<boolean> = new Subject();

  checkInterval?: ReturnType<typeof setInterval>;

  logoutTimeout?: ReturnType<typeof setTimeout>;

  logoutCounterInterval?: ReturnType<typeof setInterval>;

  logoutTimeRemaining: Subject<number> = new Subject();

  startAutoLogoutProcess() {
    const timeoutTime = StorageService.getItem(sessionTimeoutStorageKey);
    let internalCount = Math.ceil((timeoutTime - Date.now()) / 1000);
    this.logoutTimeRemaining.next(internalCount);
    this.logoutCounterInterval = setInterval(() => {
      internalCount -= 1;
      this.logoutTimeRemaining.next(internalCount);
      if (internalCount === 0) {
        this.expiredLogout();
      }
    }, 1000);
    this.showTimeoutModal.next(true);
  }

  clearLogoutCounterInterval() {
    if (this.logoutCounterInterval) {
      clearInterval(this.logoutCounterInterval);
      delete this.logoutCounterInterval;
    }
  }

  stopAutoLogoutProcess() {
    this.clearLogoutCounterInterval();
    this.showTimeoutModal.next(false);
  }

  resetTimer() {
    this.stopAutoLogoutProcess();
    StorageService.storeItem(
      sessionTimeoutStorageKey,
      Date.now() + EnvironmentService.sessionTimeoutMinutes * 60 * 1000
    );
    if (!this.checkInterval) {
      this.checkInterval = setInterval(() => {
        if (!StorageService.getAuthToken()) {
          this.router.navigate(['/login']);
        } else if (shouldForceLogout()) {
          this.expiredLogout();
        } else if (shouldTimeoutTrigger()) {
          if (!this.logoutCounterInterval) {
            this.startAutoLogoutProcess();
          }
        } else if (this.logoutCounterInterval) {
          this.stopAutoLogoutProcess();
        }
      }, 5000);
    }
  }

  startTimer() {
    if (shouldForceLogout()) {
      this.expiredLogout();
    } else if (shouldTimeoutTrigger()) {
      if (!this.logoutCounterInterval) {
        this.startAutoLogoutProcess();
      }
    } else {
      this.resetTimer();
    }
  }

  clearCheckInterval() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
      delete this.checkInterval;
    }
  }

  expiredLogout() {
    if (StorageService.getItem('forceLogout') || shouldTimeoutTrigger()) {
      if (!StorageService.getItem('forceLogout')) {
        StorageService.storeItem('forceLogout', JSON.stringify(true));
      }
      this.authService.logoutAllAuthenticatedUsers();
    } else {
      this.clearLogoutCounterInterval();
    }
  }

  ngOnDestroy() {
    this.clearCheckInterval();
    this.stopAutoLogoutProcess();
  }
}
