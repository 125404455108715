import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import {
  Calendar,
  CalendarOptions,
  EventApi,
  EventChangeArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Subscription, take } from 'rxjs';
import { CalendarEventDTO } from 'src/app/common/dtos/calendar.dto';
import { CoachlogSearchPayloadItemsDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { guessTimezone } from 'src/app/common/utilities/time-helpers';

import { EventImpl } from '@fullcalendar/core/internal';
import { Store } from '@ngxs/store';
import { CreateSessionModalComponent } from '../../shared/components/create-session-modal/create-session-modal.component';
import { LogViewDetailsModalComponent } from '../../shared/components/log-view-details-modal/log-view-details-modal.component';
import { CoachingSessionDTO } from '../../shared/dtos/coaching-session.dto';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';
import { UpdateLogPayload } from '../../shared/types/payloads/coaching-log.payloads';

@Component({
  selector: 'app-calendar-page',
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss'],
})
export class CalendarPageComponent implements OnInit, OnDestroy, AfterViewInit {
  pageTitle = 'Calendar';

  subs: Subscription[] = [];

  @ViewChild('eventCalendar') calendarComponent: FullCalendarComponent;

  @ViewChild('logViewDetailsModal') logModal: LogViewDetailsModalComponent;

  @ViewChild('createSessionModal')
  createSessionModal: CreateSessionModalComponent;

  calendarApi: Calendar;

  coachlogId = 0;

  loading = false;

  calendarEvents: CalendarEventDTO[] = [];

  businessHours = {
    startTime: '5:00',
    endTime: '19:00',
  };

  calendarView: string;

  calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      bootstrap5Plugin,
      listPlugin,
      interactionPlugin,
      timeGridPlugin,
      interactionPlugin,
    ],
    initialView: 'timeGridWeek',
    themeSystem: 'bootstrap5',
    headerToolbar: {
      start: 'today prev next',
      center: 'title',
      end: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek',
    },
    eventTextColor: 'black',
    height: 600,
    editable: true,
    eventResizableFromStart: true,
    selectable: true,
    businessHours: this.businessHours,
    selectConstraint: this.businessHours,
    events: this.calendarEvents,
    dateClick: this.handleDayTimeEvent.bind(this),
    datesSet: this.handleEvents.bind(this),
    eventClick: this.dateEvent.bind(this),
    eventDrop: this.handleCalendarEventChange.bind(this),
    eventResize: this.handleCalendarEventChange.bind(this),
    eventAllow: this.handleEventAllow.bind(this),
  };

  coachlogSearchOptions: CoachlogSearchPayloadItemsDTO = {
    keywords: '',
    from_date: 0,
    to_date: 0,
    competency_items: [],
    school_items: [],
    coach_items: [],
    coachee_items: [],
    per_page: 1000,
    own_only: 1,
    sort_order: 'DESC',
  };

  timeZone: string | null = null;

  sessions: CoachingSessionDTO[];

  sessionsEmpty = false;

  user: User | null = null;

  isCoachUser = false;

  canViewUserSelect = false;

  mscEnabled = false;

  selectedStartDate: number | null;

  selectedStartTime: string | null;

  selectedEndTime: string | null;

  isViewingOtherUserCalendar = false;

  key = 'calendarView';

  constructor(
    private coachingLogService: CoachingLogService,
    private cdRef: ChangeDetectorRef,
    private featureFlagService: UnleashService,
    private store: Store
  ) {
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user) {
      if (this.user.roles.includes(UserRole.COACH)) {
        this.isCoachUser = true;
      }

      if (
        this.user.roles.includes(UserRole.SCHOOL_ADMIN) ||
        this.user.roles.includes(UserRole.DISTRICT_ADMIN) ||
        this.user.roles.includes(UserRole.E2L_EMPLOYEE)
      ) {
        this.canViewUserSelect = true;
      }
    }
  }

  ngOnInit(): void {
    const view = window.localStorage.getItem(this.key);
    if (view) {
      this.calendarView = JSON.parse(view);
      this.calendarOptions = {
        ...this.calendarOptions,
        initialView: this.calendarView,
      };
      this.getSessions();
    }

    this.subs.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.getSessions();
      })
    );
  }

  ngAfterViewInit() {
    this.calendarApi = this.calendarComponent.getApi();

    const transferDate = new Date(this.calendarApi.view.activeEnd).getTime();
    const startDate = new Date(this.calendarApi.view.activeStart).getTime();

    this.coachlogSearchOptions.to_date = transferDate / 1000;
    this.coachlogSearchOptions.from_date = startDate / 1000;

    this.getSessions();

    this.cdRef.detectChanges();
  }

  getTimeZone(calendarTimeZone: string): void {
    if (calendarTimeZone === 'local') {
      this.timeZone = guessTimezone(new Date());
    } else {
      this.timeZone = calendarTimeZone;
    }
  }

  getSessions(): void {
    this.loading = true;

    const includePrivateLogs = 1;

    this.coachingLogService.setFilters(this.coachlogSearchOptions);

    this.coachingLogService.getSessions(includePrivateLogs).subscribe((res) => {
      take(1);
      if (res) {
        this.sessions = res[0];
        if (this.sessions.length === 0) {
          this.sessionsEmpty = true;
        }
      }

      this.calendarEvents = this.sessions.map((session) => {
        const startDate = new Date(session.startDatetime * 1000);
        const endDate = new Date(session.endDatetime * 1000);
        let eventColor;
        const isSmart = session.type.isSmart;
        let durationEditable = false;
        let editable = false;
        if (isSmart && this.user?.id === session.attendees[0].userId) {
          editable = true;
        } else if (
          this.isCoachUser &&
          this.user?.id === session.user.id &&
          !isSmart
        ) {
          editable = true;
          durationEditable = true;
        }
        switch (session.type.view) {
          case 'universal-log':
            eventColor = '#DFD0F3';
            break;
          case 'egrowe-coachlog-v2':
            if (isSmart) {
              eventColor = '#F1C1E8';
            } else {
              eventColor = '#DFD0F3';
            }
            break;
          case 'observation':
            eventColor = '#C6DEEC';
            break;
          default:
            eventColor = '#FFBCB4';
        }
        return {
          id: session.id,
          title: session.title,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          classNames: [session.type.view, `msc-${isSmart}`],
          backgroundColor: eventColor,
          borderColor: eventColor,
          constraint: isSmart ? null : 'businessHours',
          editable,
          durationEditable,
          allDay: isSmart,
        };
      });

      this.loading = false;

      // Accessibility
      this.calendarApi.el
        .querySelector('.fc-scroller-liquid-absolute')
        ?.setAttribute('tabindex', '0');

      this.calendarApi.el
        .querySelector('table.fc-scrollgrid.fc-scrollgrid-liquid')
        ?.setAttribute('role', '');

      this.calendarApi.el
        .querySelector('tbody[role="rowgroup"]')
        ?.setAttribute('role', '');
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEvents(event: EventApi | any): void {
    window.localStorage.setItem(this.key, JSON.stringify(event.view.type));
    const endDate = new Date(event.view.activeEnd).getTime();
    const startDate = new Date(event.view.activeStart).getTime();

    this.coachlogSearchOptions.to_date = endDate / 1000;
    this.coachlogSearchOptions.from_date = startDate / 1000;

    this.getTimeZone(event.view.dateEnv.timeZone);
    this.getSessions();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateEvent(event: EventApi | any) {
    this.coachlogId = event.event.id;
    this.logModal.openModal();
  }

  showSelectedUserCalendar(user: UserLiteDTO | null) {
    if (user) {
      this.pageTitle = `${user.profile.first_name} ${user.profile.last_name}'s Calendar`;
      this.coachlogSearchOptions.own_only_user_id = user.id;
      this.calendarOptions.dateClick = undefined;
      this.getSessions();
      this.isViewingOtherUserCalendar = true;
    }
  }

  clearSelectedUser() {
    this.pageTitle = 'Calendar';
    if (this.user) {
      this.coachlogSearchOptions.own_only_user_id = this.user.id;
    }
    this.calendarOptions.dateClick = this.handleDayTimeEvent.bind(this);
    this.getSessions();
    this.isViewingOtherUserCalendar = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDayTimeEvent(event: EventApi | any) {
    const selectedDate = new Date(event.date);
    const businessHours =
      event.jsEvent.target.classList.value !== 'fc-non-business';
    this.selectedStartDate = selectedDate.getTime() / 1000;
    if (businessHours) {
      if (!event.allDay) {
        this.selectedStartTime = `${selectedDate.getHours()}:${
          selectedDate.getMinutes() ? selectedDate.getMinutes() : '00'
        }`;
        this.selectedEndTime = `${selectedDate.getHours() + 1}:${
          selectedDate.getMinutes() ? selectedDate.getMinutes() : '00'
        }`;
      } else {
        this.selectedStartTime = '5:00';
        this.selectedEndTime = '19:00';
      }
      this.createSessionModal.openModal();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCalendarEventChange(changeEvent: EventChangeArg | any) {
    const isSmart = changeEvent.event.classNames.includes('msc-true');
    const newStartDate = new Date(changeEvent.event.start);
    const newEndDate = new Date(changeEvent.event.end);
    const coachLogId = changeEvent.event._def.publicId;
    const payload = {} as UpdateLogPayload;
    payload.start_datetime = newStartDate.getTime() / 1000;
    if (isSmart && payload.start_datetime) {
      this.coachingLogService.updateSmartLogDate(
        coachLogId,
        payload.start_datetime + 60 * 60 * 10 // change default midnight start time to 10AM local
      );
    } else {
      payload.end_datetime = newEndDate.getTime() / 1000;
      this.coachingLogService.updateCoachingLog(coachLogId, payload);
    }
  }

  // eslint-disable-next-line
  handleEventAllow(dropInfo: EventApi | any, draggedEvent: EventImpl | any) {
    return (
      (draggedEvent.classNames.includes('msc-true') && dropInfo.allDay) ||
      (!draggedEvent.classNames.includes('msc-true') && !dropInfo.allDay)
    );
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
    if (this.createSessionModal) this.createSessionModal.closeModal();
  }
}
