<app-modal #internalModal [modalConfig]="modalConfig">
  <button
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="this.internalModal.dismiss()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div *ngIf="!portfolio; else portfolioView">
    <div *ngIf="competencyDictionary.length > 0" class="competencies">
      <ng-container *ngFor="let dictionaryItem of competencyDictionary">
        <app-leveled-competency
          [userId]="dictionaryItem.userId"
          [competency]="dictionaryItem.competency"
          [rubricId]="dictionaryItem.rubricId"
          [logId]="coachlogId"
          [showOptions]="showOptions"
          [viewingPrevSession]="viewingPrevSession"></app-leveled-competency>
      </ng-container>
    </div>
    <app-loader *ngIf="competencyDictionary.length === 0"></app-loader>
    <app-resource-search-modal />
  </div>
  <ng-template #portfolioView>
    <div *ngIf="portfolioCompetency" class="competencies">
      <app-portfolio-leveled-competency
        [userId]="userId"
        [rubricId]="rubricId"
        [competency]="portfolioCompetency"></app-portfolio-leveled-competency>
    </div>
    <app-loader *ngIf="!portfolioCompetency"></app-loader>
  </ng-template>
</app-modal>

<app-badge-earned-modal
  *ngIf="sessionData"
  [currentUsersRole]="
    sessionData.currentUsersRole || null
  "></app-badge-earned-modal>
