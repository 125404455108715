import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadStyles } from 'src/app/private/shared/components/file-management/file-upload/file-upload.component';
import { BadgeDataDTO } from 'src/app/private/shared/dtos/badge.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { getIcon } from 'src/app/private/shared/helpers/icon.utilities';
import { BadgeFileService } from 'src/app/private/shared/services/file/badge.file.service';
import { TopicBuilderService } from 'src/app/private/shared/services/topic-builder/topic-builder.service';
import { CompetencyBuilderIndicatorDTO } from 'src/app/private/shared/types/responses/competency-builder.responses';

@Component({
  selector: 'app-builder-leveled-competency-strand-level',
  templateUrl: './builder-leveled-competency-strand-level.component.html',
  styleUrls: ['./builder-leveled-competency-strand-level.component.scss'],
})
export class BuilderLeveledCompetencyStrandLevelComponent implements OnInit {
  @Input() level: number;

  @Input() levelName: string;

  @Input() strand: CompetencyBuilderIndicatorDTO;

  @Input() badgeData: BadgeDataDTO;

  @Output() readonly badgeGraphicUploadedEvent = new EventEmitter();

  isDrawerCollapsed = true;

  isOptionCollapsed = true;

  uploadStyle = UploadStyles.BADGE;

  badgeExists = false;

  uploadType = UploadType.BADGE;

  constructor(
    private topicBuilderService: TopicBuilderService,
    private badgeFileService: BadgeFileService
  ) {}

  ngOnInit(): void {
    if (
      this.badgeData?.image &&
      this.badgeData?.image !== '/img/e2L.Logo.webp'
    ) {
      this.badgeExists = true;
      this.badgeData.image = getIcon(this.badgeData.image);
      this.uploadStyle = UploadStyles.NONE;
    }
  }

  updateContent(content: string) {
    this.topicBuilderService
      .updateIndicatorContent(this.strand.indicator.id, content)
      .subscribe();
  }

  setBadgeId() {
    if (this.badgeData) {
      this.badgeFileService.setBadgeId(this.badgeData.id);
    }
  }
}
